import React from 'react';
import { TextField as TextFieldMaterial } from '@material-ui/core';

const TextField = (props) => {
    const { label, onChange, name, value } = props
    return (
        <TextFieldMaterial
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={value}
            label={label}
            onChange={onChange}
            name={name}
            {...props}
        />
    );
}

export default TextField;