const noteRound = (value) => {
    if (value === 10 || value === null) {
        return value;
    } else {
        return value.toFixed(1);
    }

}

const currencyRound = (value) => {
    return value.toFixed(2);
}

export {
    noteRound,
    currencyRound
}