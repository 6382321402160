import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth, fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'mensagem/';

const findUsers = (search) => {
  const url = SERVER_URL + controller + "users/" + search;
  return fetchGetAuth(url);
}

const getUsuarioSala = (codUsuario) => {
  const url = SERVER_URL + controller + "user/" + codUsuario;
  return fetchGetAuth(url);
}

const createGroup = (sala) => {
  const url = SERVER_URL + controller + "create-group";
  return fetchPostAuth(url, sala);
}

const updateGroup = (sala) => {
  const url = SERVER_URL + controller + "update-group";
  return fetchPostAuth(url, sala);
}

const findUsersAll = () => {
  const url = SERVER_URL + controller + "users_all";
  return fetchGetAuth(url);
}

export {
  findUsers, 
  getUsuarioSala, 
  createGroup, 
  updateGroup, 
  findUsersAll
}