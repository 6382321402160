import React from "react";
import Typography from "@material-ui/core/Typography";
import TurmaComboBox from "../Turma/UI/TurmaComboBox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import DisciplinaComboBox from "../Disciplina/UI/DisciplinaComboBox";
import UsuarioPerfilComboBox from "../Usuario/UI/UsuarioPerfilComboBox";
import Loading from "../UI/Loading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginBottom: "10px",
  },
  divFiltro: {
    marginTop: "20px",
    marginBottom: "30px",
  },
});

const TDPTableSelectView = ({
  tdpsAll,
  tdps,
  setTdps,
  setTdp,
  isLoading,
  usuariosPerfil,
  usuarioPerfil,
  setUsuarioPerfil,
  turmas,
  disciplinas,
  disciplina,
  setDisciplina,
  turma,
  setTurma
}) => {
  const classes = useStyles();

  const handleLimparFiltro = () => {
    setTdps(tdpsAll);
    setTurma(null);
    setDisciplina(null);
    setUsuarioPerfil(null);
  };

  const handleUsuarioPerfilChange = (event) => {
    const usuarioPerfilSelect = usuariosPerfil.find(
      (up) => up.codigo === event.target.value
    );
    setUsuarioPerfil(usuarioPerfilSelect);
    setTdps(
      tdpsAll.filter(
        (prevState) =>
          prevState.usuario &&
          prevState.usuario.id === usuarioPerfilSelect.usuario.id
      )
    );
    setUsuarioPerfil(usuarioPerfilSelect);
    setTurma(null);
    setDisciplina(null);
  };

  const handleTurmaChange = (event) => {
    const turmaSelect = turmas.find((t) => t.codigo === event.target.value);
    setTurma(turmaSelect);
    setTdps(
      tdpsAll.filter(
        (prevState) => prevState.turma.codigo === turmaSelect.codigo
      )
    );
    setDisciplina(null);
    setUsuarioPerfil(null);
  };

  const handleDisciplinaChange = (event) => {
    const disciplinaSelect = disciplinas.find(
      (d) => d.codDisciplina === event.target.value
    );
    setDisciplina(disciplinaSelect);
    setTdps(
      tdpsAll.filter(
        (prevState) =>
          prevState.disciplina.codDisciplina === disciplinaSelect.codDisciplina
      )
    );
    setTurma(null);
    setUsuarioPerfil(null);
  };

  return (
    <>
      <Typography className={classes.title} variant="body1">
        Selecione uma turma - disciplina - professor
      </Typography>
      <div className={classes.divFiltro}>
        <Grid container direction="row" justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <TurmaComboBox
              turmas={turmas}
              turma={turma}
              handleTurmaChange={handleTurmaChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <DisciplinaComboBox
              disciplinas={disciplinas}
              disciplina={disciplina}
              handleDisciplinaChange={handleDisciplinaChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <UsuarioPerfilComboBox
              usuariosPerfil={usuariosPerfil}
              usuarioPerfil={usuarioPerfil}
              handleUsuarioPerfilChange={handleUsuarioPerfilChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleLimparFiltro}
            >
              Limpar Filtro
            </Button>
          </Grid>
        </Grid>
      </div>
      {isLoading ? (
        <Grid container justifyContent="center">
          <Grid item>
            <Loading description="Carregando Turma-Disciplina-Professor" />
          </Grid>
        </Grid>
      ) : (
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Turma (Curso)</TableCell>
              <TableCell>Disciplina</TableCell>
              <TableCell>Professor</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tdps.length > 0 &&
              tdps.map((tdp) => (
                <TableRow key={tdp.codigo}>
                  <TableCell>
                    {tdp.turma.descricao} ({tdp.turma.curso.abreviacao})
                  </TableCell>
                  <TableCell>{tdp.disciplina.descricao}</TableCell>
                  <TableCell>{tdp.usuario ? tdp.usuario.nome : ""}</TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => setTdp(tdp)}
                      className={classes.submit}
                    >
                      Selecionar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TDPTableSelectView;
