import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { countByComunicadoCodigoAndPerfil, findByComunicadoCodigo, getEngajamentoInfo } from './api-comunicado-usuario';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { ALUNO, RESPONSAVEL } from '../../Usuario/Perfil';
import Loading from '../../UI/Loading';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { TablePagination } from '@material-ui/core';
import TablePaginationActions from '../../../shared/components/Table/Actions';

const useStyles = makeStyles({
    container: {
        paddingBottom: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    tableRow: {
        cursor: 'pointer'
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontSize: 'large',
        fontWeight: '700'
    },
    loading: {
        marginTop: '20px',
    }
});

export default function EngajamentoTable({ comunicado }) {
    const classes = useStyles();
    const [comunicadoUsuarios, setComunicadoUsuarios] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [engajamento, setEngajamento] = useState(null);
    const [quantAlunos, setQuantAlunos] = useState(0);
    const [quantResponsaveis, setQuantResponsaveis] = useState(0);

    useEffect(() => {
        fetchData(0);
        countByComunicadoCodigoAndPerfil(comunicado.codigo, ALUNO).then((data) => {
            if (data) {
                setQuantAlunos(data);
            }
        })
        countByComunicadoCodigoAndPerfil(comunicado.codigo, RESPONSAVEL).then((data) => {
            if (data) {
                setQuantResponsaveis(data);
            }
        })
        // eslint-disable-next-line
    }, [comunicado.codigo]);

    useEffect(() => {
        getEngajamentoInfo(comunicado.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setEngajamento(data);
            }
        })
    }, [comunicado.codigo, comunicadoUsuarios]);

    const handleChangePage = (event, newPage) => {
        fetchData(newPage);
    };

    const fetchData = (page) => {
        setIsLoading(true);
        findByComunicadoCodigo(comunicado.codigo, page).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setComunicadoUsuarios(data);
                setIsLoading(false);
            }
        })
    }

    return (
        <Container className={classes.container} component={Paper}>
            <div >
                <Typography className={classes.title} variant="h6">
                    Engajamento
                </Typography>
                {isLoading ?
                    <Loading description="Carregando Engajamento..." />
                    :
                    <>
                        {comunicadoUsuarios.content.length > 0 ?
                            <>
                                {engajamento ?
                                    <>
                                        <p>Total Enviado: {engajamento.total}</p>
                                        <p>Visualizado: {engajamento.totalVisualizado}</p>
                                        <p>Não Visualizado: {engajamento.naoVisualizado}</p>
                                        <p>Leitura Confirmada: {engajamento.totalLeituraConfirmada}</p>
                                        <p>Leitura Não Confirmada: {engajamento.naoLeituraConfirmada}</p>
                                        <p>Quantidade Alunos: {quantAlunos}</p>
                                        <p>Quantidade Responsáveis: {quantResponsaveis}</p>
                                    </> : ''}

                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.rowHeader}>Nome</TableCell>
                                            <TableCell className={classes.rowHeader}>Perfil</TableCell>
                                            <TableCell className={classes.rowHeader}>Visualizado</TableCell>
                                            <TableCell className={classes.rowHeader}>Leitura Confirmada</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {comunicadoUsuarios.content.map((comunicadoUsuario) => (
                                            <TableRow
                                                key={comunicadoUsuario.codigo}
                                                className={classes.tableRow}
                                            >
                                                <TableCell>
                                                    {comunicadoUsuario.usuario.perfil === RESPONSAVEL ?
                                                        comunicadoUsuario.usuario.nome +
                                                        (comunicadoUsuario.aluno ? ' - ' +
                                                            comunicadoUsuario.aluno.nome : '') :
                                                        comunicadoUsuario.usuario.nome}
                                                </TableCell>
                                                <TableCell>{comunicadoUsuario.usuario.perfil}</TableCell>
                                                <TableCell>
                                                    {comunicadoUsuario.visualizado ?
                                                        <CheckCircle style={{ color: green[500] }} /> :
                                                        <Cancel style={{ color: red[500] }} />}
                                                </TableCell>
                                                <TableCell>
                                                    {comunicadoUsuario.leituraConfirmada ?
                                                        <CheckCircle style={{ color: green[500] }} /> :
                                                        <Cancel style={{ color: red[500] }} />}
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>



                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={comunicadoUsuarios.size ? comunicadoUsuarios.size : 0}
                                    component="div"
                                    count={comunicadoUsuarios.totalElements}
                                    page={comunicadoUsuarios.number}
                                    onPageChange={handleChangePage}
                                    ActionsComponent={TablePaginationActions}
                                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                                />
                            </> :
                            <p>Nenhum Comunicado Usuário Encontrado</p>
                        }

                    </>

                }
            </div>
        </Container>
    );
}