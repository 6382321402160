import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { dayOfWeekName, timeFormat } from "../../../util/DateUtil";

const CronogramaProfessorTable = ({ cronogramas }) => {
  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Dia Semana</TableCell>
          <TableCell>Ordem Aula</TableCell>
          <TableCell>Horário</TableCell>
          <TableCell>Disciplina</TableCell>
          <TableCell>Turma</TableCell>
          <TableCell>Local</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cronogramas.map((c) => (
          <TableRow key={c.codigo}>
            <TableCell>{dayOfWeekName(c.diaSemana)}</TableCell>
            <TableCell>{c.cronogramaAula.ordemAula}</TableCell>
            <TableCell>
              {timeFormat(c.cronogramaAula.horarioInicio)} -{" "}
              {timeFormat(c.cronogramaAula.horarioFim)}
            </TableCell>
            <TableCell>{c.tdp.disciplina.descricao}</TableCell>
            <TableCell>{c.tdp.turma ? c.tdp.turma.descricao : "--"}</TableCell>
            <TableCell>{c.local ? c.local.descricao : "--"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CronogramaProfessorTable;
