import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TablePagination, makeStyles } from '@material-ui/core';
import Button from '../UI/Button';
import TextField from '../UI/TextField';
import Loading from '../UI/Loading';
import TablePaginationActions from '../../shared/components/Table/Actions';
import MensagemAll from './MensagemTable/MensagemAll';
import { findAllByUsuarioNome } from './Sala/api-sala';

const useStyles = makeStyles({
    container: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    title: {
        marginTop: '20px'
    },
    divSelect: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '20px',
        marginTop: '20px',
    },
    formulario: {
        marginBottom: '20px'
    }
});

const MensagemFindAllContainer = () => {
    const classes = useStyles();
    const [salas, setSalas] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState();

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        findAllByUsuarioNome(search, 0).then((data => {
            if (data.error) {
                console.log(data);
            } else {
                setSalas(data);
            }
            setIsLoading(false);
        }))

    }

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        findAllByUsuarioNome(search, newPage).then((data => {
            if (data.error) {
                console.log(data);
            } else {
                setSalas(data);
            }
            setIsLoading(false);
        }))
    }

    const handleChange = (event) => {
        setSearch(event.target.value);
    }

    return (
        <Container className={classes.container} component={Paper}>
            <Button
                component={Link}
                to={{
                    pathname: '/mensagem'
                }}
                variant="contained"
                color="secondary"
                className={classes.voltarButton}
            >
                <ArrowBackIcon style={{ marginRight: '10px' }} />
                Voltar
            </Button>
            <Typography className={classes.title} variant="h5">
                Buscar Usuário
            </Typography>

            <form className={classes.formulario} onSubmit={clickSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    inputProps={{ maxLength: 60 }}
                    label="Nome"
                    name="nome"
                    onChange={handleChange}
                    value={search ? search : ''}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}>
                    Buscar
                </Button>
            </form>

            {isLoading ? <Loading description='Carregando Mensagens...' /> :
                <>
                    {salas &&
                        salas.content &&
                        salas.content.length > 0 &&
                        salas.content.map((sala) => (
                            <MensagemAll sala={sala} />
                        ))}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={salas && salas.size ? salas.size : 0}
                        component="div"
                        count={salas && salas.totalElements}
                        page={salas && salas.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>}

        </Container>
    );
}

export default MensagemFindAllContainer;