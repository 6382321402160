import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import TDPTableSelectRedux from '../TDP/TDPTableSelectRedux';
import Button from '@material-ui/core/Button';
import { duplicar } from './api-planejamento';
import { setTDP } from '../TDP/actions/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        marginTop: '20px'
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    },
    buttonSim: {
        marginRight: '20px',
        marginLeft: '20px'
    },
    loadingDuplicar: {
        marginRight: '20px',
        marginLeft: '20px'
    }
});

export default function PlanejamentoDuplicarForm({ tdp, setIsOpenForm, updatePlanejamentos }) {
    const classes = useStyles();
    const tdpDuplicar = useSelector(state => state.tdp);
    const dispatch = useDispatch();
    const [loadingDuplicar, setLoadingDuplicar] = useState(false);

    const handleDuplicarPlanejamento = () => {
        setLoadingDuplicar(true);
        duplicar(tdp.codigo, tdpDuplicar.codigo).then(() => {
            updatePlanejamentos();
            setIsOpenForm(false);
            dispatch(setTDP(null));
            setLoadingDuplicar(false);
        });

    }

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h5">
                    Planejamento Duplicar
                </Typography>

                {tdpDuplicar ?
                    <>
                        <Typography className={classes.title} variant="h6">
                            Deseja duplicar todos os planejamentos da disciplina {tdpDuplicar.disciplina.descricao} da turma {tdpDuplicar.turma.descricao} para a disciplina {tdp.disciplina.descricao} da turma {tdp.turma.descricao}?
                        </Typography>
                        {loadingDuplicar ?
                            <CircularProgress
                                className={classes.loadingDuplicar}
                                color="primary"
                            /> :
                            <Button
                                onClick={handleDuplicarPlanejamento}
                                className={classes.buttonSim}

                                variant="contained"
                                color="primary"
                            >
                                Sim
                            </Button>
                        }

                        <Button
                            onClick={() => {
                                setIsOpenForm(false);
                                dispatch(setTDP(null));
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Não
                        </Button>



                    </> :
                    <TDPTableSelectRedux />}


            </Container>

        </div>
    );
}