import {
    ADD_COMUNICADOS,
    UPDATE_COMUNICADOS,
    SET_COMUNICADOS,
    REMOVE_COMUNICADOS,
    SET_COMUNICADO,
    OPEN_SEARCH_COMUNICADO,
    CLOSE_SEARCH_COMUNICADO,
    SET_QUANT_APROVACAO_COMUNICADO,
    DECREASE_QUANT_APROVACAO_COMUNICADO,
    INCREASE_QUANT_APROVACAO_COMUNICADO
} from './actionTypes'

export function addComunicados(comunicado) {
    return { type: ADD_COMUNICADOS, comunicado }
}

export function updateComunicados(comunicado) {
    return { type: UPDATE_COMUNICADOS, comunicado }
}

export function setComunicados(comunicados) {
    return { type: SET_COMUNICADOS, comunicados }
}

export function removeComunicados(index) {
    return { type: REMOVE_COMUNICADOS, index }
}

export function setComunicado(comunicado) {
    return { type: SET_COMUNICADO, comunicado }
}

export function openSearchComunicado() {
    return { type: OPEN_SEARCH_COMUNICADO }
}

export function closeSearchComunicado() {
    return { type: CLOSE_SEARCH_COMUNICADO }
}

export function setQuantAprovacaoComunicado(quantAprovacao) {
    return { type: SET_QUANT_APROVACAO_COMUNICADO, quantAprovacao }
}

export function decreaseQuantAprovacaoComunicado() {
    return { type: DECREASE_QUANT_APROVACAO_COMUNICADO }
}
export function increaseQuantAprovacaoComunicado() {
    return { type: INCREASE_QUANT_APROVACAO_COMUNICADO }
}