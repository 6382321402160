import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createUpdateByCodAlunoTurma, saveAusenciaCompensada } from '../api-aluno-frequencia';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { addAlunosFrequencia, updateAlunosFrequencia, setAlunoFrequencia as setAlunoFrequenciaRedux } from '../actions/actions';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { ADMIN } from '../../Usuario/Perfil';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { Menu } from '@material-ui/core';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { confirmAlert } from 'react-confirm-alert';
import LoadingDialog from '../../UI/LoadingDialog';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: "20px",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
}));

export default function AusenciaCompensadaForm() {
    const classes = useStyles();
    const alunoFrequenciaRedux = useSelector(state => state.alunoFrequencia);
    const [alunoFrequencia, setAlunoFrequencia] = useState(null);
    const usuario = useSelector(state => state.usuario);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setAlunoFrequencia(alunoFrequenciaRedux);
    }, [alunoFrequenciaRedux])

    const dispatch = useDispatch();

    const handleChange = (event) => {
        setAlunoFrequencia({
            ...alunoFrequencia,
            [event.target.name]: event.target.value
        })
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        const codAtividade = alunoFrequencia.codigo;
        saveAusenciaCompensada(alunoFrequencia).then(data => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (codAtividade) {
                    dispatch(updateAlunosFrequencia(data));
                } else {
                    dispatch(addAlunosFrequencia(data));
                }
                dispatch(setAlunoFrequenciaRedux(data));
                setAlunoFrequencia(data);
            }

        });

    }

    const handleCreateUpdateByCodAlunoTurma = (codAlunoTurma) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja criar/atualizar os registros de aluno freq. do aluno turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        createUpdateByCodAlunoTurma(codAlunoTurma).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setOpenDialog(false);
                                toast.success("Operação realizada com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }


    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <LoadingDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    title="Aluno Frequência"
                    description='Criando/Atualizando'
                />
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <CloseIcon
                            onClick={() => dispatch(setAlunoFrequenciaRedux(null))}
                            className={classes.closeIcon}
                            fontSize='small'
                        />
                    </Grid>
                    <Grid item>
                        {usuario.perfil === ADMIN ?
                            <>
                                <MenuIcon
                                    fontSize='medium'
                                    className={classes.cursorLink}
                                    onClick={(event) => handleMenuClick(event)}
                                    color="secondary"

                                />
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem
                                        style={{
                                            fontSize: 12
                                        }}
                                        onClick={() => handleCreateUpdateByCodAlunoTurma(alunoFrequencia.alunoTurma.codigo)}
                                    >
                                        Criar/Atualizar Aluno Freq. por Aluno Turma
                                    </MenuItem>
                                </Menu>
                            </> : ''
                        }

                    </Grid>
                </Grid>
                <form onSubmit={clickSubmit}>
                    <Grid
                        container
                        direction='row'
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12} sm={12} md={6} lg={6} xl={6}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                size='small'
                                fullWidth
                                required
                                inputProps={{ readOnly: true }}
                                value={alunoFrequencia ? alunoFrequencia.alunoTurma.aluno.nome : ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        spacing={3}
                    >
                        {usuario && usuario.perfil === ADMIN ?
                            <>
                                <Grid
                                    item
                                    xs={6} sm={6} md={3} lg={2} xl={2}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size='small'
                                        inputProps={{ maxLength: 5 }}
                                        label="Quantidade Aulas"
                                        name="quantidadeAulas"
                                        onChange={handleChange}
                                        value={alunoFrequencia && alunoFrequencia.quantidadeAulas ? alunoFrequencia.quantidadeAulas : null}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6} sm={6} md={3} lg={2} xl={2}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size='small'
                                        inputProps={{ maxLength: 5 }}
                                        label="Quantidade Faltas"
                                        name="quantidadeFaltas"
                                        onChange={handleChange}
                                        value={alunoFrequencia && alunoFrequencia.quantidadeFaltas ? alunoFrequencia.quantidadeFaltas : null}
                                    />
                                </Grid>
                            </> : ''
                        }

                        <Grid
                            item
                            xs={6} sm={6} md={3} lg={2} xl={2}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size='small'
                                inputProps={{ maxLength: 5 }}
                                label="Ausência Compensada"
                                name="ausenciaCompensada"
                                onChange={handleChange}
                                value={alunoFrequencia && alunoFrequencia.ausenciaCompensada ? alunoFrequencia.ausenciaCompensada : null}
                            />
                        </Grid>
                        {usuario && usuario.perfil === ADMIN ?
                            <Grid
                                item
                                xs={6} sm={6} md={3} lg={2} xl={2}
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size='small'
                                    inputProps={{ maxLength: 5 }}
                                    label="Frequência"
                                    name="frequencia"
                                    onChange={handleChange}
                                    value={alunoFrequencia && alunoFrequencia.frequencia ? alunoFrequencia.frequencia : null}
                                />
                            </Grid> : ''
                        }

                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>

        </div>
    );
}