import {
    ADD_OBSERVACOES_TURMA, 
    UPDATE_OBSERVACOES_TURMA, 
    SET_OBSERVACOES_TURMA, 
    REMOVE_OBSERVACOES_TURMA,
    SET_OBSERVACAO_TURMA, 
    SET_OBSERVACAO_TURMA_NULL
} from './actionTypes'

export function addObservacoesTurma (observacaoTurma) {
    return { type: ADD_OBSERVACOES_TURMA, observacaoTurma }
}

export function updateObservacoesTurma(observacaoTurma){
    return {type : UPDATE_OBSERVACOES_TURMA, observacaoTurma}
}

export function setObservacoesTurma(observacoesTurma){
    return {type : SET_OBSERVACOES_TURMA, observacoesTurma}
}

export function removeObservacoesTurma(index){
    return {type : REMOVE_OBSERVACOES_TURMA, index}
}

export function setObservacaoTurma(observacaoTurma){
    return {type : SET_OBSERVACAO_TURMA, observacaoTurma}
}

export function setObservacaoTurmaNull(){
    return {type : SET_OBSERVACAO_TURMA_NULL}
}