import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, FormControl, InputLabel, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { findByAnoLetivo } from '../../Turma/api-turma';
import { findByPerfil } from '../../Usuario/api-usuario-perfil'
import { PROFESSOR } from '../../Usuario/Perfil';
import Button from '../../UI/Button';
import { toast } from 'react-toastify';
import { save, remove } from './api-integral-professor';
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    buttonSave: {
        marginTop: '20px'
    },
    buttonRemove: {
        marginTop: '20px',
        marginLeft: '20px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
});

const IntegralProfessorForm = ({ integralProfessor, setIntegralProfessor, setListaIP }) => {
    const [turmas, setTurmas] = useState([]);
    const [usuarioPerfilP, setUsuarioPerfilP] = useState([]);
    const [persist, setPersist] = useState(integralProfessor);
    const classes = useStyles();
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    useEffect(() => {
        findByAnoLetivo().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setTurmas(data);
            }
        });
        findByPerfil(PROFESSOR).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setUsuarioPerfilP(data);
            }
        })
    }, []);

    useEffect(() => {
        setPersist(integralProfessor);
    }, [integralProfessor]);


    const handleTurmaChange = (event) => {
        const turma = turmas.find((t) => {
            return t.codigo === event.target.value;
        });
        setPersist({
            ...persist,
            turma: turma
        })
    }

    const handleProfessorChange = (event) => {
        const professor = usuarioPerfilP.find((up) => {
            return up.usuario.id === event.target.value;
        });
        setPersist({
            ...persist,
            usuario: professor.usuario
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const eNovo = persist.eNovo;
        save(persist).then(data => {
            if (!data || data.error) {
                console.log(data);

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    handleAddIP(data);
                }
                setIntegralProfessor(data);
            }
            setIsLoadingSave(false);
        })

    }

    const handleAddIP = (ip) => {
        setListaIP(prevState => ([
            ...prevState,
            ip
        ]))
    }

    const handleRemove = () => {

        confirmAlert({
            title: 'Alerta',
            message: "Deseja Remover este registro?",
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        remove(persist.codigo).then((data) => {
                            if (!data || data.error) {
                                console.log(data);
                            } else {
                                setListaIP((prevState) => (
                                    prevState.filter((ps) => ps.codigo !== persist.codigo)
                                ))
                                setIntegralProfessor(null);
                            }
                        })
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });

    }


    return (
        <Container className={classes.container} component={Paper} maxWidth="sm">
            <CloseIcon
                onClick={() => setIntegralProfessor(null)}
                fontSize='small'
                className={classes.closeIcon}
            />
            <form onSubmit={clickSubmit}>
                <FormControl required fullWidth>
                    <InputLabel id="turma-label">Turma</InputLabel>
                    <Select
                        labelId="turma-label"
                        id="turma-select"
                        value={persist && persist.turma ? persist.turma.codigo : ''}
                        onChange={handleTurmaChange}
                    >
                        {turmas.map((t) => (
                            <MenuItem key={t.codigo} value={t.codigo}>{t.descricao} - ({t.curso.abreviacao})</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel id="professor-label">Professor</InputLabel>
                    <Select
                        labelId="professor-label"
                        id="professor-select"
                        value={persist && persist.usuario ? persist.usuario.id : ''}
                        onChange={handleProfessorChange}
                    >
                        {usuarioPerfilP.map((up) => (
                            <MenuItem key={up.usuario.id} value={up.usuario.id}>{up.usuario.nome}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoadingSave}
                    color="primary"
                    className={classes.buttonSave}>
                    {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                    Salvar
                </Button>
                {persist.eNovo ? '' :
                    <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        className={classes.buttonRemove}
                        onClick={handleRemove}
                    >
                        Remover
                    </Button>
                }

            </form>
        </Container>
    );
}

export default IntegralProfessorForm;