import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
} from '../../util/FetchUtil';

const controller = 'catraca-registro/';

export const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'query?page=' + pageNumber;
  return fetchGetAuth(url);
}


export const pageAndData = (pageNumber, date) => {
  const url = SERVER_URL + controller + 'query?page=' + pageNumber + "&data=" + new Date(date).getTime();
  return fetchGetAuth(url);
}

export const findByAlunoCodigo = (codAluno, pageNumber) => {
  const url = SERVER_URL + controller + 'query?codAluno=' + codAluno + '&page=' + pageNumber;
  return fetchGetAuth(url);
}

export const findByAlunoCodigoAndDate = (codAluno, data, pageNumber) => {
  const url = SERVER_URL + controller + 'query?codAluno=' + codAluno + '&data=' +
    new Date(data).getTime() + '&page=' + pageNumber;
  return fetchGetAuth(url);
}

export const findByCheck = (codTurma, data) => {
  const url = SERVER_URL + controller + 'check?codTurma=' + codTurma + '&dataLong=' +
    new Date(data).getTime();
  return fetchGetAuth(url);
}

export const save = (catracaRegistro) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, catracaRegistro);
}