import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../../Usuario/actions/actions';
import { save } from '../../Instituicao/api-instituicao';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        flex: '1 1 100%',
        marginBottom: '20px',
        marginTop: '20px'
    },
}));

const SystemColors = () => {
    const usuario = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [field, setField] = useState(null);

    const handleChangeComplete = (color) => {
        if (field === 'primaria') {
            dispatch(setUsuario({
                ...usuario,
                instituicao: {
                    ...usuario.instituicao,
                    corPrimaria: color.hex
                }
            }))
        } else if (field === 'secundaria') {
            dispatch(setUsuario({
                ...usuario,
                instituicao: {
                    ...usuario.instituicao,
                    corSecundaria: color.hex
                }
            }))
        }
    };

    const handleChangeField = (fieldSelected) => {
        setField(fieldSelected);
    }

    const handleSave = () => {
        save(usuario.instituicao).then((data) => {
            if (data) {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        })
    }

    const handleReset = (fieldSelected) => {
        if (fieldSelected === 'primaria') {
            dispatch(setUsuario({
                ...usuario,
                instituicao: {
                    ...usuario.instituicao,
                    corPrimaria: null
                }
            }))
        } else if (fieldSelected === 'secundaria') {
            dispatch(setUsuario({
                ...usuario,
                instituicao: {
                    ...usuario.instituicao,
                    corSecundaria: null
                }
            }))
        }
    }

    return (
        <Grid container spacing={3} direction='column'>
            <Grid item>
                <Typography className={classes.title} variant="h5">
                    Trocar Cores do Sistema
                </Typography>
            </Grid>

            <Grid item>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Campo</TableCell>
                            <TableCell>Cor</TableCell>
                            <TableCell>Trocar Cor</TableCell>
                            <TableCell>Resetar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Primária</TableCell>
                            <TableCell style={{
                                backgroundColor: usuario &&
                                    usuario.instituicao &&
                                    usuario.instituicao.corPrimaria ?
                                    usuario.instituicao.corPrimaria :
                                    '#FFF'
                            }}></TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleChangeField('primaria')}
                                >
                                    Trocar Cor
                                </Button>


                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleReset('primaria')}
                                >
                                    Reset
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Secundária</TableCell>
                            <TableCell style={{
                                backgroundColor: usuario &&
                                    usuario.instituicao &&
                                    usuario.instituicao.corSecundaria ?
                                    usuario.instituicao.corSecundaria :
                                    '#FFF'
                            }}></TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleChangeField('secundaria')}
                                >
                                    Trocar Cor
                                </Button>

                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleReset('secundaria')}
                                >
                                    Reset
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item>
                {field &&
                    <SketchPicker
                        color={field === 'primaria' ?
                            (usuario.instituicao.corPrimaria ? usuario.instituicao.corPrimaria : '#FFF') :
                            (usuario.instituicao.corSecundaria ? usuario.instituicao.corSecundaria : '#FFF')}
                        onChangeComplete={handleChangeComplete}
                    />
                }
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSave}
                >
                    Salvar
                </Button>
            </Grid>
        </Grid>
    );
}

export default SystemColors;