import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import React, { useEffect, useState } from 'react';
import { page } from './api-sms-pendencia';
import { dateTimeFormat } from '../../../util/DateUtil';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
});

const SmsPendenciaTable = () => {
    const classes = useStyles();
    const [smsPendencias, setSmsPendencias] = useState({
        content: []
    });

    useEffect(() => {
        page(0).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setSmsPendencias(data);
            }
        })
    }, []);

    const handleChangePage = (event, newPage) => {
        page(newPage).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setSmsPendencias(data);
            }
        });
    };


    return (
        <>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowHeader}>Responsável</TableCell>
                        <TableCell className={classes.rowHeader}>Mensagem</TableCell>
                        <TableCell className={classes.rowHeader}>Celular Enviado</TableCell>
                        <TableCell className={classes.rowHeader}>Data Criação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {smsPendencias.content.map((sp) => (
                        <TableRow key={sp.codigo}>
                            <TableCell>{sp.usuario.nome}</TableCell>
                            <TableCell>{sp.message}</TableCell>
                            <TableCell>{sp.celularEnviado}</TableCell>
                            <TableCell>{dateTimeFormat(sp.createdAt)}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={smsPendencias.size ? smsPendencias.size : 0}
                component="div"
                count={smsPendencias.totalElements}
                page={smsPendencias.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />
        </>
    );
}

export default SmsPendenciaTable;