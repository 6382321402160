import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import NBAlunoTable from './NBAlunoTable';
import NBAlunoResumo from './NBAlunoResumo';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
    paper: {

        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function NBAlunoContainer() {
    const classes = useStyles();
    const [menuShow, setMenuShow] = React.useState(0);


    const handleChange = (event, newValue) => {
        setMenuShow(newValue);
    };

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <Typography className={classes.title} variant="h5">
                            Nota Bimestre
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item xs={6}>
                        <Paper style={{
                            backgroundColor: 'rgb(146 89 167)'
                        }}>
                            <Tabs
                                value={menuShow}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab style={{ color: 'white' }} label="Resumo" />
                                <Tab style={{ color: 'white' }} label="Tabela" />
                            </Tabs>
                        </Paper>
                    </Grid>
                </Grid>

                {menuShow === 0 ? <NBAlunoResumo /> : ''}
                {menuShow === 1 ? <NBAlunoTable /> : ''}
            </Container>

        </div>
    );
}