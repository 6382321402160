import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { save } from './api-atividade-aluno';
import { updateAtividadeAlunos, setAtividadeAluno } from './actions/actions';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function PresencaAlunoForm() {
    const classes = useStyles();
    const atividadeAluno = useSelector(state => state.atividadeAluno);
    const atividadeAlunos = useSelector(state => state.atividadeAlunos);
    const dispatch = useDispatch();

    const nextAA = () => {
        const index = atividadeAlunos.map(aa => aa.codigo).indexOf(atividadeAluno.codigo);
        const size = atividadeAlunos.length;
        if (index + 1 === size) {
            dispatch(setAtividadeAluno(atividadeAlunos[0]));
        } else {
            dispatch(setAtividadeAluno(atividadeAlunos[index + 1]));
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        dispatch(setAtividadeAluno({
            ...atividadeAluno,
            [event.target.name]: event.target.value.replace(',', '.')
        }))
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        save(atividadeAluno).then((data) => {
            if (data) {
                dispatch(updateAtividadeAlunos(data));
            }
        });
        nextAA();
    }

    const handleVistarAtividade = () => {
        const persistent = {
            ...atividadeAluno,
            vistoProfessor: atividadeAluno.vistoProfessor ? false : true
        }
        save(persistent).then((data) => {
            if (data) {
                dispatch(updateAtividadeAlunos(data));
            }
        });
        nextAA();
    }


    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper} >

                <Typography variant="body1">
                    Nome: {atividadeAluno ? atividadeAluno.aluno.nome : ''}
                </Typography>
                <Typography variant="body1">
                    Número Chamada: {atividadeAluno ? atividadeAluno.alunoTurma.numeroChamada : ''}
                </Typography>

                <form onSubmit={clickSubmit}>

                    {atividadeAluno && atividadeAluno.atividade.semNota ?
                        <Button
                            variant="contained"
                            size='small'
                            color="primary"
                            className={classes.submit}
                            onClick={handleVistarAtividade}
                        >
                            {atividadeAluno.vistoProfessor ? 'Retirar Visto' : 'Vistar Atividade'}
                        </Button> :
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                size='small'
                                fullWidth
                                label="Nota"
                                name="nota"
                                autoFocus
                                onChange={handleChange}
                                value={atividadeAluno && atividadeAluno.nota ? atividadeAluno.nota : ''}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Salvar
                            </Button>
                        </>
                    }


                </form>



            </Container>
        </div>
    );
}