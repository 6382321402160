import {
  fetchRemoveAuth,
  fetchGetAuth,
  fetchPostAuth
} from '../../../util/FetchUtil';
import { SERVER_URL } from '../../../constants';


const controller = 'historico-lancamento/';


const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuth(url)
}

const findByCurrentUser = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const save = (historicoLancamento) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, historicoLancamento);
}

export {
  findByCurrentUser,
  remove,
  save
}