import React, { useState, useEffect } from "react";
import { Button, Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { findByLivroCodLivro, save } from './api-livro-instituicao';
import InstituicaoFindDialogRedux from "../../Instituicao/InstituicaoFindDialogRedux";
import { useDispatch, useSelector } from "react-redux";
import { openInstituicao } from "../../Instituicao/actions/actions";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from "react-toastify";
import { setInstituicao } from "../../Instituicao/actions/actions";
import { errorMessage } from "../../../util/ConfirmAlertUtil";

const LivroInstituicaoTableAdd = ({ livro }) => {
    const [livroInstituicoes, setLivroInstituicoes] = useState([]);
    const instituicao = useSelector(state => state.instituicao);
    const dispatch = useDispatch();

    useEffect(() => {
        if (livro.codLivro) {
            findByLivroCodLivro(livro.codLivro).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setLivroInstituicoes(data);
                }
            });
        }

    }, [livro]);

    useEffect(() => {
        if (instituicao) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja inserir ' + instituicao.descricao + ' como instituição para o livro?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            save({ livro: livro, instituicao: instituicao }).then((data) => {
                                if (data.error) {
                                    console.log(data);
                                } else {
                                    toast.success("Salvo com sucesso.", {
                                        position: toast.POSITION.BOTTOM_LEFT
                                    });
                                    setLivroInstituicoes((prevState) => ([
                                        data,
                                        ...prevState
                                    ]));
                                }
                                dispatch(setInstituicao(null));
                            })
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }

    }, [instituicao, livro, dispatch]);

    const handleSearchInstitution = () => {
        dispatch(openInstituicao());
    };

    return (
        <Container component={Paper}>
            <InstituicaoFindDialogRedux />
            <Typography variant="body1">
                Selecione as Instituições do livro.
            </Typography>
            <Button
                onClick={handleSearchInstitution}
                variant="contained"
                color="primary">
                Adicionar
            </Button>

            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Instituição</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {livroInstituicoes.map((li) => (
                        <TableRow key={li.codigo}>
                            <TableCell>{li.instituicao.descricao}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    )
}

export default LivroInstituicaoTableAdd;