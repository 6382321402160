import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../UI/Button';
import { toast } from 'react-toastify';
import { save, remove } from './api-integral-presenca';
import { confirmAlert } from 'react-confirm-alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateFormatMoment } from '../../../util/DateUtil';
import { ptBR } from "date-fns/locale";

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    buttonSave: {
        marginTop: '20px'
    },
    buttonRemove: {
        marginTop: '20px',
        marginLeft: '20px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },

    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});

const IntegralPresencaForm = ({ integralPresenca, setIntegralPresenca, setListaPresenca }) => {


    const [persist, setPersist] = useState(integralPresenca);
    const classes = useStyles();
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    useEffect(() => {
        setPersist(integralPresenca);
    }, [integralPresenca]);



    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const eNovo = persist.eNovo;
        save(persist).then(data => {
            if (!data || data.error) {
                console.log(data);

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    handleAddPresenca(data);
                }
                setIntegralPresenca(data);
            }
            setIsLoadingSave(false);
        })

    }

    const handleAddPresenca = (presenca) => {
        setListaPresenca(prevState => ({
            ...prevState,
            content: [
                presenca,
                ...prevState.content
            ]
        }))
    }

    const handleRemove = () => {

        confirmAlert({
            title: 'Alerta',
            message: "Deseja Remover este registro?",
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        remove(persist.codigo).then((data) => {
                            if (!data || data.error) {
                                console.log(data);
                            } else {
                                setListaPresenca((prevState) => ({
                                    ...prevState,
                                    content: prevState.content.filter((ps) => ps.codigo !== persist.codigo)
                                }));
                                setIntegralPresenca(null);
                            }
                        })
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleDataChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setPersist((prevState) => ({
            ...prevState,
            data: data
        }))
    }

    return (
        <>
            <Container className={classes.container} component={Paper} maxWidth="sm">
                <CloseIcon
                    onClick={() => setIntegralPresenca(null)}
                    fontSize='small'
                    className={classes.closeIcon}
                />
                <form onSubmit={clickSubmit}>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            fullWidth
                            required
                            variant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Data"
                            value={persist && persist.data ? dateFormatMoment(persist.data) : null}
                            onChange={handleDataChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoadingSave}
                        color="primary"
                        className={classes.buttonSave}>
                        {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Salvar
                    </Button>
                    {persist.eNovo ? '' :
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            className={classes.buttonRemove}
                            onClick={handleRemove}
                        >
                            Remover
                        </Button>
                    }

                </form>
            </Container>

        </>
    );
}

export default IntegralPresencaForm;