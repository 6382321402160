import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { findByTurma } from "../AlunoTurma/api-aluno-turma";
import { findByTurma as findTdpByTurma } from '../TDP/api-tdp';
import TurmaComboBox from '../Turma/UI/TurmaComboBox';
import AlunoTurmaComboBox from '../AlunoTurma/UI/AlunoTurmaComboBox';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import TDPComboBox from '../TDP/UI/TDPComboBox';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RelatorioAlunoTdpForm({ turmas }) {
    const classes = useStyles();
    const [alunoTurma, setAlunoTurma] = useState(null);
    const [tdp, setTdp] = useState(null);
    const [tdps, setTdps] = useState([]);
    const [turma, setTurma] = useState();
    const [alunosTurma, setAlunosTurma] = useState([]);


    useEffect(() => {
        if (turma) {
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunosTurma(data);
                }
            });
            findTdpByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data);
                }
            })
        }

    }, [turma]);

    const handleTurmaChange = (event) => {
        setTurma(turmas.find(t => t.codigo === event.target.value));
    }

    const handleAlunoTurmaChange = (event) => {
        setAlunoTurma(alunosTurma.find((at) => at.codigo === event.target.value));
    }

    const handleTDPChange = (event) => {
        setTdp(tdps.find((ps) => ps.codigo === event.target.value));
    }

    return (
        <Container component={Paper} >
            <Typography className={classes.title} variant="body1">
                Por Aluno e Turma/Disciplina
            </Typography>
            <form>
                <TurmaComboBox
                    turmas={turmas}
                    turma={turma}
                    handleTurmaChange={handleTurmaChange}
                />
                {turma ?
                    <>
                        <AlunoTurmaComboBox
                            alunosTurma={alunosTurma}
                            alunoTurma={alunoTurma}
                            handleAlunoTurmaChange={handleAlunoTurmaChange}
                        />
                        <TDPComboBox
                            tdps={tdps}
                            tdp={tdp}
                            handleTDPChange={handleTDPChange}
                        />
                    </> : ''
                }
                <Button
                    component={Link}
                    to={{
                        pathname: '/relatorio-frequencia-web',
                        state: {
                            codAlunoTurma: alunoTurma ? alunoTurma.codigo : null,
                            codTdp: tdp ? tdp.codigo : null
                        }
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}>
                    Frequência
                </Button>


            </form>
        </Container>
    );
}