import {
  ADD_ATIVIDADES,
  UPDATE_ATIVIDADES,
  SET_ATIVIDADES,
  REMOVE_ATIVIDADES,
  SET_ATIVIDADE,
  SET_ATIVIDADE_NULL
} from './actionTypes';
import { PURGE } from "redux-persist";

export function atividade(state = null, action) {
  switch (action.type) {
    case SET_ATIVIDADE:
      return action.atividade

    case SET_ATIVIDADE_NULL:
      return null

    case PURGE:
      return null;

    default:
      return state
  }
}

const initialState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function atividades(state = initialState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_ATIVIDADES:
      persist.content = [
        action.atividade,
        ...persist.content
      ]
      return persist

    case UPDATE_ATIVIDADES:
      let content = state.content.map((atividade) => {
        if (atividade.codigo === action.atividade.codigo) {
          return action.atividade;
        } else {
          return atividade;
        }
      });
      persist.content = content;
      return persist;

    case SET_ATIVIDADES:
      return action.atividades

    case REMOVE_ATIVIDADES:

      persist.content = persist.content.filter((a) => a.codigo !== action.codAtividade);
      return persist;

    case PURGE:
      return initialState;

    default:
      return state
  }
}
