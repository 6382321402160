import {
    SET_ALUNO_TURMA,
    SET_ALUNOS_TURMA,
    ADD_ALUNOS_TURMA,
    UPDATE_ALUNOS_TURMA,
    REMOVE_ALUNOS_TURMA,
    OPEN,
    CLOSE
} from './actionTypes'

export function setAlunoTurma(alunoTurma) {
    return { type: SET_ALUNO_TURMA, alunoTurma }
}

export function setAlunosTurma(alunosTurma) {
    return { type: SET_ALUNOS_TURMA, alunosTurma }
}

export function addAlunosTurma(alunoTurma) {
    return { type: ADD_ALUNOS_TURMA, alunoTurma }
}

export function updateAlunosTurma(alunoTurma) {
    return { type: UPDATE_ALUNOS_TURMA, alunoTurma }
}

export function removeAlunosTurma(codAlunoTurma) {
    return { type: REMOVE_ALUNOS_TURMA, codAlunoTurma }
}

export function open() {
    return { type: OPEN }
}

export function close() {
    return { type: CLOSE }
}