import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';

const controller = 'conceito-aluno/';

const findByTdpNumeroBimestre = (codTDP, numeroBimestre) => {
  const url = SERVER_URL + controller + "search" +
    "?codTDP=" +
    codTDP +
    "&numeroBimestre=" +
    numeroBimestre;
  return fetchGetAuth(url);
}

const save = (notaBimestre) => {
  const url = SERVER_URL + controller + "save";
  return fetchPostAuth(url, notaBimestre);
}

const insertByTurma = (codTurma) => {
  const url = SERVER_URL + controller + "insert-by-turma/" + codTurma;
  return fetchGetAuth(url);
}

const insertByAlunoTurma = (codAlunoTurma) => {
  const url = SERVER_URL + controller + "insert-by-aluno-turma/" + codAlunoTurma;
  return fetchGetAuth(url);
}

const insertByTurmaDisciplina = (codTDP) => {
  const url = SERVER_URL + controller + "insert-by-turma-disciplina/" + codTDP;
  return fetchGetAuth(url);
}

const deleteByAlunoTurma = (codAlunoTurma) => {
  const url = SERVER_URL + controller + "delete-by-aluno-turma/" + codAlunoTurma;
  return fetchRemoveAuth(url);
}

const findByAnoLetivoAndCurrentUser = () => {
  const url = SERVER_URL + controller + "aluno/ano-letivo/" + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const findByAnoLetivoAndCurrentUserResumo = () => {
  const url = SERVER_URL + controller + "aluno-resumo/ano-letivo/" + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const remove = (codNotaBimestre) => {
  const url = SERVER_URL + controller + codNotaBimestre;
  return fetchRemoveAuth(url);
}

const insertConceitoAlunoByAlunoTurma = (codAlunoTurma) => {
  const url = SERVER_URL + controller + "insert-student/" + codAlunoTurma;
  return fetchGetAuth(url);
}

export {
  findByTdpNumeroBimestre,
  save,
  insertByTurma,
  insertByAlunoTurma,
  deleteByAlunoTurma,
  findByAnoLetivoAndCurrentUser,
  insertByTurmaDisciplina,
  remove,
  findByAnoLetivoAndCurrentUserResumo,
  insertConceitoAlunoByAlunoTurma
}