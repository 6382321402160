import React from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import Container from "../UI/Container";
import CloseIcon from "@material-ui/icons/Close";
import Select from "../UI/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextFieldDateTime from "../UI/TextFieldDateTime";
import Button from "../UI/Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  closeIcon: {
    marginTop: "20px",
    cursor: "pointer",
  },
  loading: {
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

const CatracaRegistroForm = ({
  catracaRegistro,
  setCatracaRegistro,
  handleSave,
  isLoadingSave,
}) => {
  const classes = useStyles();
  const listEntradaSaida = [
    { description: "Entrada", value: "ENTRADA" },
    { description: "Saída", value: "SAIDA" },
  ];

  const onChangeEntradaSaida = (event) => {
    setCatracaRegistro((prevState) => ({
      ...prevState,
      entradaSaida: event.target.value,
    }));
  };

  const handleDateTimeChange = (dateTime) => {
    setCatracaRegistro((prevState) => ({
      ...prevState,
      dataHora: dateTime,
    }));
  };

  return (
    <Container className={classes.paper} component={Paper}>
      <div className={classes.closeIcon}>
        <CloseIcon onClick={() => setCatracaRegistro(null)} fontSize="small" />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <form onSubmit={handleSave}>
            <Grid container direction="column">
              <Grid item>
                <TextFieldDateTime
                  required
                  label="Data Hora"
                  value={
                    catracaRegistro && catracaRegistro.dataHora
                      ? catracaRegistro.dataHora
                      : null
                  }
                  handleDateTimeChange={handleDateTimeChange}
                />
              </Grid>
              <Grid item>
                <Select
                  label="Entrada/Saída"
                  value={
                    catracaRegistro && catracaRegistro.entradaSaida
                      ? catracaRegistro.entradaSaida
                      : ""
                  }
                  list={listEntradaSaida}
                  onChange={onChangeEntradaSaida}
                />
              </Grid>

              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoadingSave}
                  color="primary"
                  className={classes.submit}
                >
                  {isLoadingSave && (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                      size={20}
                    />
                  )}
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CatracaRegistroForm;
