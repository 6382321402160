import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { updateInstituicao } from '../Usuario/api-usuario';
import { findByCurrentUser } from './api-usuario-instituicao';
import { toast } from 'react-toastify';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../Usuario/actions/actions';
import { Grid } from '@material-ui/core';



export default function UsuarioInstituicaoTable() {
    const usuario = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const [usuarioInstituicoes, setUsuarioInstituicoes] = useState([]);


    useEffect(() => {

        findByCurrentUser().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setUsuarioInstituicoes(data);
            }
        });

    }, []);


    const handleAtivar = (codInstituicao) => {
        updateInstituicao(codInstituicao).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setUsuario(data));
                toast.success("Instituição Ativo Alterado.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                // window.location.reload(false);
            }
        });
    }

    return (
        <>
            {usuarioInstituicoes.length > 0 ?

                <Grid container direction='column' spacing={3}>
                    <Grid item>
                        <Typography variant="h5">
                            Alterar Instituição Ativo
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Instituição</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usuarioInstituicoes.map((ui) => (
                                    <TableRow key={ui.codigo}>
                                        <TableCell>{ui.instituicao.descricao}</TableCell>
                                        <TableCell>
                                            {usuario === null || usuario.instituicao.codigo !== ui.instituicao.codigo ?
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleAtivar(ui.instituicao.codigo)}
                                                >
                                                    Ativar
                                                </Button>
                                                : 'Selecionado'}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                : ''}
        </>
    );
}