import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'cardapio/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const ativar = (codCardapio) => {
  const url = SERVER_URL + controller + 'ativar/' + codCardapio;
  return fetchGetAuth(url);
}

const save = (integralPresenca) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, integralPresenca);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuthReturn(url);
}


export {
  findAll,
  ativar,
  save,
  remove
}