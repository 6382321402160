import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    divLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
});

const Loading = ({ description }) => {
    const classes = useStyles();

    return (
        <div className={classes.divLoading}>
            <CircularProgress color="primary" />
            <p>{description}</p>
        </div>

    );
}

export default Loading;