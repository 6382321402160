import { Button, Grid } from '@material-ui/core';
import React from 'react';
import Container from './Container';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';

const ContainerWithGoBack = (props) => {
    const history = useHistory();
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button
                        onClick={() => history.goBack()}
                        variant="contained"
                        color="secondary"
                    >
                        <ArrowBackIcon style={{ marginRight: '10px' }} />
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </Container >
    );
}

export default ContainerWithGoBack;