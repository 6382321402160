import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { uploadVideo, remove } from '../api-conteudo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import 'suneditor/dist/css/suneditor.min.css';
import { confirmAlert } from 'react-confirm-alert';
import { removeConteudos, addConteudos, setConteudos } from "../actions/actions";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton,
  CurrentTimeDisplay,
  TimeDivider
} from 'video-react';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 650,
    marginTop: "20px",
    padding: '30px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  remove: {
    margin: theme.spacing(3, 3, 3),
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
  buscarBtn: {
    margin: theme.spacing(3, 3, 3),
  },
  closeIcon: {
    marginBottom: '20px',
    cursor: 'pointer'
  },
  title: {
    paddingBottom: '20px',
  },
  buttonsBox: {
    margin: '20px',
    backgroundColor: 'lavender',
    padding: '30px',
    borderRadius: '25px',
    display: 'inline-block'
  },
  loading: {
    marginRight: '10px',
    marginLeft: '10px'
  }
}));

export default function VideoForm({
  conteudoSelected,
  setConteudoSelected,
  setComponent
}) {
  const classes = useStyles();
  const [conteudo] = useState({
    ...conteudoSelected
  });
  const [videoFile, setVideoFile] = useState(null);
  const [posterFile, setPosterFile] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);



  const clickSubmit = (event) => {
    event.preventDefault();

    if (videoFile === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append('posterFile', posterFile);
    fileFormData.append('videoFile', videoFile);
    setIsLoading(true);
    uploadVideo(conteudo.capitulo.codCapitulo, fileFormData).then((data) => {
      setIsLoading(false);
      if (data.error) {
        console.log(data.error);
      } else {
        toast.success("Salvo com sucesso.", {
          position: toast.POSITION.BOTTOM_LEFT
        });

        if (conteudoSelected.eNovo) {
          handleClose();
          dispatch(addConteudos(data));
        } else {
          dispatch(setConteudos(data));
        }
      }
    })

  }

  const handleVideoFile = event => {
    setVideoFile(event.target.files[0]);
  }

  const handlePosterFile = event => {
    setPosterFile(event.target.files[0]);
  }

  const handleClose = () => {
    setConteudoSelected(null);
    setComponent(null);
  }

  const handleRemove = () => {
    confirmAlert({
      title: 'Alerta',
      message: 'Deseja deletar este conteúdo?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            remove(conteudo.codigo).then((data) => {
              if (data) {
                dispatch(removeConteudos(conteudo.codigo));
                toast.success("Conteúdo deletado com sucesso.", {
                  position: toast.POSITION.BOTTOM_LEFT
                });
              }
            })
          }
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  }

  return (
    <div>
      <CssBaseline />
      <ToastContainer autoClose={3000} />

      <Container className={classes.paper} maxWidth="xl" component={Paper} >
        {conteudo.eNovo ?
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleClose}
            fontSize='small'
          /> : ''}

        <Grid item >
          <form onSubmit={clickSubmit}>

            <Typography className={classes.title} variant="h6">
              Vídeo
            </Typography>

            {conteudo.eNovo ?
              <>
                <div style={{ display: 'flex' }}>
                  Upload Vídeo <input type="file" onChange={handleVideoFile} />
                </div>

                <div style={{ display: 'flex', marginTop: '10px' }}>
                  Upload Poster <input type="file" onChange={handlePosterFile} />
                </div>
              </> :
              <Player
                playsInline
                poster={conteudo.video.posterUrl}
              >
                <BigPlayButton position="center" />
                <source
                  src={conteudo.video.videoUrl}
                  type="video/mp4"
                />
                <ControlBar>
                  <VolumeMenuButton order={1.1} vertical />
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />
                  <PlaybackRateMenuButton rates={[0.25, 0.5, 0.75, 1, 1.25, 1.75, 2]} order={7.1} />
                </ControlBar>
              </Player>
            }

            <div className={classes.buttonsBox}>
              {conteudo.eNovo ?
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  className={classes.submit}>
                  {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                  Salvar
                </Button> : ''}
              {!conteudo.eNovo ?
                <>
                  <Button
                    onClick={handleRemove}
                    variant="contained"
                    color="secondary"
                    className={classes.remove}
                  >
                    Remover
                  </Button>

                </>
                : ''}

            </div>
          </form>
        </Grid>
      </Container>
    </div>
  );
}