import React, { useState, useEffect } from 'react';


import { findByCurrentUser } from './api-tdp';
import { findByAnoLetivo as findTurmasByAnoLetivo } from '../Turma/api-turma';
import { errorMessage } from '../../util/ConfirmAlertUtil';

import { findAll as findAllDisciplinas } from '../Disciplina/api-disciplina';
import { findByPerfil } from '../Usuario/api-usuario-perfil';
import { PROFESSOR } from '../Usuario/Perfil';

import TDPTableSelectView from './TDPTableSelectView';



export default function TDPTableSelect({ setTdp }) {

    const [isLoading, setIsLoading] = useState(false);
    const [tdps, setTdps] = useState([]);
    const [tdpsAll, setTdpsAll] = useState([]);
    const [turmas, setTurmas] = useState([]);
    const [turma, setTurma] = useState(null);
    const [disciplinas, setDisciplinas] = useState([]);
    const [disciplina, setDisciplina] = useState(null);
    const [usuariosPerfil, setUsuariosPerfil] = useState([]);
    const [usuarioPerfil, setUsuarioPerfil] = useState(null);


    useEffect(() => {
        setIsLoading(true);
        findByCurrentUser().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setTdps(data);
                setTdpsAll(data);
            }
            setIsLoading(false);
        });

        findTurmasByAnoLetivo().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setTurmas(data);
            }
        })

        findAllDisciplinas().then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setDisciplinas(data);
            }
        })

        findByPerfil(PROFESSOR).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setUsuariosPerfil(data);
            }
        })

    }, []);



    return (
        <>
            <TDPTableSelectView
                tdpsAll={tdpsAll}
                tdps={tdps}
                setTdps={setTdps}
                setTdp={setTdp}
                isLoading={isLoading}
                usuariosPerfil={usuariosPerfil}
                usuarioPerfil={usuarioPerfil}
                setUsuarioPerfil={setUsuarioPerfil}
                turmas={turmas}
                disciplinas={disciplinas}
                disciplina={disciplina}
                setDisciplina={setDisciplina}
                turma={turma}
                setTurma={setTurma}
            />


        </ >
    );
}