import { ADD_TDPS, SET_TDPS, SET_TDP, UPDATE_TDPS } from './actionTypes';
import { PURGE } from "redux-persist";

export function tdps(state = [], action) {
  switch (action.type) {
    case ADD_TDPS:
      return [
        action.tdp,
        ...state

      ]
    case SET_TDPS:
      return action.tdps

    case UPDATE_TDPS:
      return state.map((tdp) => {
        if (tdp.codigo === action.tdp.codigo) {
          return action.tdp;
        } else {
          return tdp;
        }
      })

    case PURGE:
      return [];

    default:
      return state
  }
}

export function tdp(state = null, action) {
  switch (action.type) {
    case SET_TDP:
      return action.tdp

    case PURGE:
      return null;

    default:
      return state
  }
}
