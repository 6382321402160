import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchGet,
  fetchRemoveAuthReturn
} from '../../util/FetchUtil';

const controller = 'capitulo/';


const findFirstRecordByBook = (codLivro) => {
  const url = SERVER_URL + controller + 'first-record-by-book/' + codLivro;
  return fetchGetAuth(url);
}

export const findFirstRecordByBookAndIsPublic = (codLivro) => {
  const url = SERVER_URL + controller + 'public/first-record-by-book/' + codLivro;
  return fetchGet(url);
}

const findByCodLivroPage = (codLivro, page) => {
  const url = SERVER_URL + controller + 'page/livro/' + codLivro + "?page=" + page;
  return fetchGetAuth(url);
}

const findByCodLivro = (codLivro) => {
  const url = SERVER_URL + controller + 'livro/' + codLivro;
  return fetchGetAuth(url);
}

export const findByLivroCodLivroAndIsPublic = (codLivro) => {
  const url = SERVER_URL + controller + 'public/livro/' + codLivro;
  return fetchGet(url);
}

const remove = (codCapitulo) => {
  const url = SERVER_URL + controller + 'delete/' + codCapitulo;
  return fetchRemoveAuthReturn(url);
}

const save = (aluno) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, aluno);
}

export const findById = (codCapitulo) => {
  const url = SERVER_URL + controller + codCapitulo;
  return fetchGetAuth(url);
}

export const findByIdAndIsPublic = (codCapitulo) => {
  const url = SERVER_URL + controller + "public/" + codCapitulo;
  return fetchGet(url);
}

export {
  findByCodLivro,
  save,
  remove,
  findByCodLivroPage,
  findFirstRecordByBook
}