import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2';

export default function AvaliacaoAlunoResultadoGraph({ avaliacoesAluno }) {

    const [listaAA, setListaAA] = useState([]);

    useEffect(() => {
        for(let i = avaliacoesAluno.length - 1; i >=0; i-- ){
            setListaAA((prevState) => ([
                ...prevState, 
                avaliacoesAluno[i]
            ]))
        }
    }, [avaliacoesAluno]);

    return (
        <Line
            data={{
                labels: listaAA.map(aa => aa.tentativa),
                datasets: [
                    {
                        label: '% Acerto',
                        data: listaAA.map(aa => aa.nota.substring(0, aa.nota.length - 1)),
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        fill: false,
                    },
                ]
            }}
            height={200}
            width={200}
            options={{
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        }
                    }]
                }
            }}
        />
    );
}