import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import { useDispatch } from 'react-redux';
import { setLivroAutor } from './actions/actions';
import { setLivro } from '../actions/actions';
import { Link } from 'react-router-dom';
import { setInstituicao } from '../../Instituicao/actions/actions';
import Button from '../../UI/Button';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function LivroAutorTable({ livrosAutor, handleChangePage }) {
    const classes = useStyles();
    const dispatch = useDispatch();



    const handleSelectBook = (la) => {
        dispatch(setLivroAutor(la))
    }

    return (
        <div>
            <Button
                component={Link}
                to={{
                    pathname: '/livro-form',
                    state: {
                        livro: { eNovo: true }
                    }
                }}
                onClick={() => dispatch(setLivroAutor({ eNovo: true }))}
                variant="contained"
                color="primary"
                className={classes.novoRegistro}>
                Novo Livro
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Editar</TableCell>
                        <TableCell>Capítulo</TableCell>
                        <TableCell>Aluno Livro</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {console.log(livrosAutor)}
                    {livrosAutor.content.map((livroAutor) => (
                        <TableRow key={livroAutor.livro.codLivro}>
                            <TableCell>{livroAutor.livro.descricao}</TableCell>
                            <TableCell>
                                <Button
                                    component={Link}
                                    to={{
                                        pathname: '/livro-form',
                                        state: {
                                            livro: livroAutor.livro
                                        }
                                    }}
                                    onClick={() => {
                                        dispatch(setLivro(livroAutor.livro));
                                        dispatch(setInstituicao(null));
                                    }}
                                    color="secondary"
                                >
                                    Editar
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    component={Link}
                                    to={{
                                        pathname: '/livro-capitulos',
                                    }}
                                    onClick={() => handleSelectBook(livroAutor)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Abrir Capítulos
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    component={Link}
                                    to={{
                                        pathname: '/livro-aluno',
                                        state: {
                                            livroAutor: livroAutor
                                        }
                                    }}
                                    onClick={() => handleSelectBook(livroAutor)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Abrir Aluno Livro
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={livrosAutor.size ? livrosAutor.size : 0}
                component="div"
                count={livrosAutor.totalElements}
                page={livrosAutor.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}