import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import ObservacaoAlunoForm from './ObservacaoAlunoForm';
import ObservacaoAlunoTable from './ObservacaoAlunoTable';
import AlunoTurmaTableSelectByTurma from '../AlunoTurma/AlunoTurmaTableSelectByTurma';
import AlunoTurmaProfile from '../AlunoTurma/AlunoTurmaProfile';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
});

export default function ObservacaoAlunoContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const alunoTurma = useSelector(state => state.alunoTurma);
    const observacaoAluno = useSelector(state => state.observacaoAluno);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Observação Aluno
                </Typography>

                {tdp ?
                    <>
                        {alunoTurma ?
                            <>
                                <AlunoTurmaProfile />
                                {observacaoAluno ?
                                    <ObservacaoAlunoForm /> :
                                    <ObservacaoAlunoTable />
                                }
                            </> :
                            <>
                                <TurmaDisciplinaProfessorProfileRedux />
                                <AlunoTurmaTableSelectByTurma />
                            </>}

                    </> :
                    <TDPTableSelectRedux />
                }
            </Container>

        </div>
    );
}