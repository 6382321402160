import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { dateFormat } from '../../../util/DateUtil';
import Button from '../../UI/Button';
import { Grid } from '@material-ui/core';
import Loading from '../../UI/Loading';


const useStyles = makeStyles({
    container: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function CronogramaDataTable({
    listCronogramaData,
    setCronogramaData,
    handleDelete,
    handleAdicionarCronogramaData,
    setCronogramaDataSelected,
    isLoading

}) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Button
                onClick={handleAdicionarCronogramaData}
                variant="contained"
                color="primary">
                Adicionar Cronograma Data
            </Button>

            {isLoading ? <Loading description="Carregando Cronograma Data..." /> :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data Início</TableCell>
                            <TableCell>Data Fim</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listCronogramaData.map((cronogramaData) => (
                            <TableRow key={cronogramaData.codigo}>
                                <TableCell>{dateFormat(cronogramaData.dataInicio)}</TableCell>
                                <TableCell>{cronogramaData.dataFim && dateFormat(cronogramaData.dataFim)}</TableCell>
                                <TableCell>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <EditIcon
                                                className={classes.cursorLink}
                                                fontSize='small'
                                                onClick={() => {
                                                    setCronogramaData({
                                                        ...cronogramaData,
                                                        eEdit: true
                                                    })
                                                }}
                                                color="secondary"
                                                style={{ marginRight: '10px' }} />
                                        </Grid>
                                        <Grid item>
                                            <DeleteIcon
                                                className={classes.cursorLink}
                                                fontSize='small'
                                                onClick={() => { handleDelete(cronogramaData.codigo) }} color="secondary" />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" onClick={() => setCronogramaDataSelected(cronogramaData)}>Selecionar</Button>
                                        </Grid>
                                    </Grid>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }


        </div >
    );
}