import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { remove, ordenar } from './api-aluno-turma';
import { dateFormat } from '../../util/DateUtil';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { insertByAlunoTurma, deleteByAlunoTurma } from '../NotaBimestre/api-nota-bimestre';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSelector, useDispatch } from 'react-redux';
import { setAlunosTurma, removeAlunosTurma, setAlunoTurma } from "./actions/actions";
import { findByTurma } from './api-aluno-turma';
import { insertByStudent as insertPresencaAlunoByAlunoTurma } from '../PresencaAluno/api-presenca-aluno';
import { insertConceitoAlunoByAlunoTurma } from '../ConceitoAluno/api-conceito-aluno';
import { insertAlunoFrequenciaByAlunoTurma } from '../AlunoFrequencia/api-aluno-frequencia';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Loading from '../UI/Loading';


const useStyles = makeStyles({
    container: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AlunoTurmaTable() {
    const classes = useStyles();
    const user = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const alunosTurma = useSelector(state => state.alunosTurma);
    const turma = useSelector(state => state.turma);
    const [anchorEl, setAnchorEl] = useState(null);
    const [codAlunoTurmaSelected, setCodAlunoTurmaSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (turma) {
            setIsLoading(true);
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setAlunosTurma(data));
                }
                setIsLoading(false);
            });
        }
    }, [turma, dispatch]);

    const handleMenuClick = (event, codAlunoTurma) => {
        setAnchorEl(event.currentTarget);
        setCodAlunoTurmaSelected(codAlunoTurma);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAdicionarNB = () => {
        handleMenuClose();
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja adicionar todas as Notas Bimestre para este Aluno? Após confirmado esta ação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        insertByAlunoTurma(codAlunoTurmaSelected).then((data) => {
                            if (data) {
                                toast.success("Salvo com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleDeleteNB = () => {
        handleMenuClose();
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja remover todas as Notas Bimestre para este Aluno? Após confirmado esta ação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleteByAlunoTurma(codAlunoTurmaSelected).then((data) => {
                            if (data) {
                                toast.success("Todos as notas bimestre do aluno foram removidas.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleAdicionarPresencaAluno = () => {
        handleMenuClose();
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja adicionar todas as Presenças para este Aluno? Após confirmado esta ação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        insertPresencaAlunoByAlunoTurma(codAlunoTurmaSelected).then((data) => {
                            if (data) {
                                toast.success("Salvo com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleAdicionarConceitoAluno = () => {
        handleMenuClose();
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja adicionar todas os Conceitos para este Aluno? Após confirmado esta ação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        insertConceitoAlunoByAlunoTurma(codAlunoTurmaSelected).then((data) => {
                            if (data) {
                                toast.success("Salvo com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleAdicionarAlunoFrequencia = () => {
        handleMenuClose();

        confirmAlert({
            title: 'Alerta',
            message: 'Deseja adicionar todas os Conceitos para este Aluno? Após confirmado esta ação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        insertAlunoFrequenciaByAlunoTurma(codAlunoTurmaSelected).then((data) => {
                            if (data) {
                                toast.success("Salvo com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'ATENÇÃO. Ao deletar o aluno da turma todas as presenças, atividades e notas de todos os bimestres do aluno serão perdidas. Deseja deletar este aluno da turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(alunosTurma[index].codigo).then((data) => {
                            if (data) {
                                dispatch(removeAlunosTurma(alunosTurma[index].codigo));
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const ordernarNumChamada = () => {

        confirmAlert({
            title: 'Alerta',
            message: 'Deseja ordenar todos os alunos por ordem alfabética?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        ordenar(alunosTurma[0].turma.codigo).then((data) => {
                            if (data) {
                                dispatch(setAlunosTurma(data));
                                toast.success("Ordenado com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    return (
        <div className={classes.container}>
            <Button
                onClick={() => dispatch(setAlunoTurma({
                    turma: turma,
                    eNovo: true
                }))}
                variant="contained"
                color="primary">
                Adicionar Aluno
            </Button>
            {alunosTurma.length > 0 ?
                <>
                    <Button
                        onClick={ordernarNumChamada}
                        style={{ marginLeft: '10px' }}
                        variant="contained"
                        color="primary">
                        Ordenar Número Chamada
                    </Button>
                </>
                : ''}

            {isLoading ?
                <Loading description="Carregando Alunos Turma" /> :
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Número Chamada</TableCell>
                            <TableCell>Data Entrada</TableCell>
                            <TableCell>Data Saída</TableCell>
                            <TableCell>Situação</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alunosTurma.map((at, index) => (
                            <TableRow key={at.codigo}>
                                <TableCell>{at.aluno.nome}</TableCell>
                                <TableCell>{at.numeroChamada}</TableCell>
                                <TableCell>{dateFormat(at.dataEntrada)}</TableCell>
                                <TableCell>{at.dataSaida ? dateFormat(at.dataSaida) : ''}</TableCell>
                                <TableCell>{at.ativo ? "Ativo" : 'Desativado'}</TableCell>
                                <TableCell>
                                    <EditIcon
                                        className={classes.cursorLink}
                                        fontSize='small'
                                        onClick={() => {
                                            dispatch(setAlunoTurma(at));
                                        }}
                                        color="secondary"
                                        style={{ marginRight: '10px' }} />
                                    <DeleteIcon
                                        className={classes.cursorLink}
                                        fontSize='small'
                                        onClick={() => { handleDelete(index) }} color="secondary" />
                                    {user.perfil === "ADMIN" || user.perfil === "COORDERNADOR" ?
                                        <>
                                            <MenuIcon
                                                fontSize='small'
                                                className={classes.cursorLink}
                                                onClick={(event) => handleMenuClick(event, at.codigo)}
                                                color="secondary"

                                            />
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem
                                                    onClick={handleAdicionarNB}
                                                >
                                                    <AddBoxIcon style={{ marginRight: '5px', color: '#009900' }} />Adicionar Nota Bimestre por Aluno
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleDeleteNB}
                                                >

                                                    <DeleteForeverIcon style={{ marginRight: '5px', color: '#b20202' }} />Remover Nota Bimestre por Aluno
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleAdicionarPresencaAluno}
                                                >
                                                    <AddBoxIcon style={{ marginRight: '5px', color: '#009900' }} />Adicionar Presença por Aluno
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleAdicionarConceitoAluno}
                                                >
                                                    <AddBoxIcon style={{ marginRight: '5px', color: '#009900' }} />Adicionar Conceito por Aluno
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleAdicionarAlunoFrequencia}
                                                >
                                                    <AddBoxIcon style={{ marginRight: '5px', color: '#009900' }} />Adicionar Aluno Frequência
                                                </MenuItem>
                                            </Menu>
                                        </> : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }


        </div>
    );
}