import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import NotaBimestreTipoTable from '../NotaBimestreTipo/NotaBimestreTipoTable';
import NotaBimestreTable from './NotaBimestreTable';
import Grid from '@material-ui/core/Grid';
import NotaBimestreForm from './NotaBimestreForm';
import ConceitoAlunoTable from '../ConceitoAluno/ConceitoAlunoTable';
import ConceitoAlunoForm from '../ConceitoAluno/ConceitoAlunoForm';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        marginBottom: '20px'
    },
    title: {
        marginBottom: '20px'
    },
});

export default function NotaBimestreContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const notaBimestreTipo = useSelector(state => state.notaBimestreTipo);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        {tdp ?
                            <Typography className={classes.title} variant="h6">
                                {tdp.eEletiva || tdp.eProjeto ? 'Conceito' : 'Nota Bimestre'}
                            </Typography> : ''}

                    </Grid>

                </Grid>
                {tdp ?
                    <>
                        <TurmaDisciplinaProfessorProfileRedux />
                        {tdp.eEletiva || tdp.eProjeto ?
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <ConceitoAlunoTable />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ConceitoAlunoForm />
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {notaBimestreTipo ? <NotaBimestreTable /> : <NotaBimestreTipoTable />}
                                </Grid>
                                <Grid item xs={6}>
                                    {notaBimestreTipo ? <NotaBimestreForm /> : ''}
                                </Grid>
                            </Grid>
                        }

                    </> :
                    <TDPTableSelectRedux />
                }
            </Container>

        </div>
    );
}