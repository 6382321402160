import {
  Button,
  Divider,
  Grid,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import React from "react";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { dateTimeFormat } from "../../util/DateUtil";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Loading from "../UI/Loading";
import TablePaginationActions from "../../shared/components/Table/Actions";

const useStyles = makeStyles({
  fotoComponent: {
    borderRadius: "20px",
    maxHeight: "80px",
  },
  accountCircleIcon: {
    fontSize: 80,
    color: "black",
  },
});

const UsuarioList = ({ usuarios, isLoading, handleChangePage, setUsuario }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      {isLoading ? (
        <Loading description="Carregando Usuários..." />
      ) : (
        <>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => setUsuario({ eNovo: true })}
            >
              Novo Usuário
            </Button>
          </Grid>
          {usuarios.content.map((u) => (
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={2}>
                      <Grid
                        container
                        spacing={3}
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          {u.fileName ? (
                            <img
                              className={classes.fotoComponent}
                              src={u.fotoUrl}
                              alt=""
                            />
                          ) : (
                            <AccountCircle
                              className={classes.accountCircleIcon}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        container
                        direction="column"
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item>
                          <span style={{ fontWeight: "bold" }}>Nome:</span>{" "}
                          {u.nome}
                        </Grid>
                        <Grid item>
                          <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                          {u.email}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid
                        container
                        direction="column"
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item>
                          <span style={{ fontWeight: "bold" }}>Perfil:</span>{" "}
                          {u.perfil}
                        </Grid>
                        <Grid item>
                          <span style={{ fontWeight: "bold" }}>Ativo:</span>{" "}
                        </Grid>
                        <Grid item>
                          {u.ativo ? (
                            <CheckCircle style={{ color: green[500] }} />
                          ) : (
                            <Cancel style={{ color: red[500] }} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid
                        container
                        spacing={3}
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => setUsuario(u)}
                          >
                            Selecionar
                          </Button>
                        </Grid>
                        <Grid item>
                          <span style={{ fontStyle: "italic" }}>
                            Último Acesso:{" "}
                            {u.dataUltimoAcesso
                              ? dateTimeFormat(u.dataUltimoAcesso)
                              : "--"}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      )}
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage={usuarios.size ? usuarios.size : 0}
          component="div"
          count={usuarios.totalElements}
          page={usuarios.number}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            from +
            "-" +
            to +
            " de " +
            (count !== -1 ? count + " registros" : +" mais de " + to)
          }
        />
      </Grid>
    </Grid>
  );
};

export default UsuarioList;
