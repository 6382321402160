import { SERVER_URL } from '../../../constants';
import { fetchGetAuth } from '../../../util/FetchUtil';

const controller = 'mural-usuario';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + '?page=' + pageNumber;
  return fetchGetAuth(url);
}


export {
  page,
}