import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  card: {
    margin: "auto",
    width: "100%",
    flexGrow: 1,
  },
});

const AlunoTurmaProfileView = ({ alunoTurma, handleChangeAlunoTurma }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Turma
            </Typography>
            <Typography variant="body1">
              {alunoTurma ? alunoTurma.turma.descricao : ""}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Nº Chamada
            </Typography>
            <Typography variant="body1">
              {alunoTurma ? alunoTurma.numeroChamada : ""}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Nome
            </Typography>
            <Typography variant="body1">
              {alunoTurma ? alunoTurma.aluno.nome : ""}
            </Typography>
          </Grid>
        </Grid>
        <CardActions>
          <Button
            color="secondary"
            onClick={handleChangeAlunoTurma}
            size="small"
            variant="contained"
          >
            Trocar Aluno Turma
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default AlunoTurmaProfileView;
