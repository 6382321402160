import {
  ADD_OBSERVACOES_TURMA,
  UPDATE_OBSERVACOES_TURMA,
  SET_OBSERVACOES_TURMA,
  REMOVE_OBSERVACOES_TURMA,
  SET_OBSERVACAO_TURMA,
  SET_OBSERVACAO_TURMA_NULL
} from './actionTypes';
import { PURGE } from "redux-persist";

export function observacaoTurma(state = null, action) {
  switch (action.type) {
    case SET_OBSERVACAO_TURMA:
      return action.observacaoTurma

    case SET_OBSERVACAO_TURMA_NULL:
      return null

    case PURGE:
      return null;

    default:
      return state
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function observacoesTurma(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_OBSERVACOES_TURMA:
      persist.content = [
        action.observacaoTurma,
        ...persist.content
      ]
      return persist

    case UPDATE_OBSERVACOES_TURMA:
      let content = state.content.map((observacaoTurma) => {
        if (observacaoTurma.codigo === action.observacaoTurma.codigo) {
          return action.observacaoTurma;
        } else {
          return observacaoTurma;
        }
      });
      persist.content = content;
      return persist;

    case SET_OBSERVACOES_TURMA:
      return action.observacoesTurma

    case REMOVE_OBSERVACOES_TURMA:
      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}
