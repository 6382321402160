import {
  SET_LANCAMENTO,
  SET_LANCAMENTOS,
  ADD_LANCAMENTOS,
  UPDATE_LANCAMENTOS,
  REMOVE_LANCAMENTOS,
  OPEN_LANCAMENTO,
  CLOSE_LANCAMENTO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function lancamento(state = null, action) {
  switch (action.type) {
    case SET_LANCAMENTO:
      return action.lancamento

    case PURGE:
      return null;

    default:
      return state
  }
}

export function lancamentos(state = [], action) {
  switch (action.type) {
    case SET_LANCAMENTOS:
      return action.lancamentos

    case ADD_LANCAMENTOS:

      return [
        ...state,
        action.historicoLancamento
      ]

    case UPDATE_LANCAMENTOS:
      let content = state.map((l) => {
        if (l.codigo === action.lancamento.codigo) {
          return action.lancamento;
        } else {
          return l;
        }
      });
      return content;

    case REMOVE_LANCAMENTOS:
      return state.filter((hl) => hl.codigo !== action.codigo);

    case PURGE:
      return [];

    default:
      return state
  }
}

export function openSearchLancamento(state = false, action) {
  switch (action.type) {
    case OPEN_LANCAMENTO:
      return true;
    case CLOSE_LANCAMENTO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}