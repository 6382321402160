import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth
} from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';

const controller = 'cronograma/';

export const findByTdpCodigoAndData = (codTdp, data) => {
  const url = SERVER_URL + controller + 'query?codTdp=' + codTdp + "&data=" + new Date(data).getTime();
  return fetchGetAuth(url);
}

export const findByCodTurmaAndCodCronogramaData = (codTurma, codCronogramaData) => {
  const url = SERVER_URL + controller + 'query?codTurma=' + codTurma + '&codCronogramaData=' + codCronogramaData;
  return fetchGetAuth(url);
}

export const findByCurrentUserAndSelectedYearAndDiaSemana = (diaSemana) => {
  const url = SERVER_URL + controller + 'query?current-user=' + true + '&ano-letivo=' + session.getAnoLetivo() + '&dia-semana=' + diaSemana;
  return fetchGetAuth(url);
}

export const findByCurrentUserAndSelectedYear = () => {
  const url = SERVER_URL + controller + 'query?current-user=' + true + '&ano-letivo=' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

export const findBySelectedYearAndTdpUsuarioId = (codUsuario) => {
  const url = SERVER_URL + controller + 'query?ano-letivo=' + session.getAnoLetivo() + '&codUsuario=' + codUsuario;
  return fetchGetAuth(url);
}

const findByCodTurmaAndDiaSemana = (codTurma, diaSemana) => {
  const url = SERVER_URL + controller + 'query?codTurma=' + codTurma + '&dia-semana=' + diaSemana;
  return fetchGetAuth(url);
}

const save = (cronograma) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, cronograma);
}

export const saveAll = (listCronograma) => {
  const url = SERVER_URL + controller + 'saveAll';
  return fetchPostAuth(url, listCronograma);
}

const remove = (codAlunoTurma) => {
  const url = SERVER_URL + controller + codAlunoTurma;
  return fetchRemoveAuth(url);
}

export {
  save,
  remove,
  findByCodTurmaAndDiaSemana

}