import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleFincanceiroError, initMessage, typeSuccess } from '../util/exceptionFinanceiro';
import { findYearsByCodAluno } from '../api-lancamento';
import Loading from '../../UI/Loading';
import { makeStyles } from '@material-ui/core';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    successView: {
        backgroundColor: '#77cee2',
        marginTop: 10,
        width: '100%'
    },
    textSuccess: {
        padding: 10,
    },
    textTitle: {
        fontWeight: 'bold',
    },
    divButtonAnoLetivo: {
        marginTop: '10px'
    }
});

const HistoricoAnoLetivoSelect = ({ setAnoLetivo }) => {
    const usuario = useSelector(state => state.usuario);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(initMessage);
    const [anosLetivo, setAnosLetivo] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        findYearsByCodAluno(usuario.arAlunoSelecionado.codigo).then((data) => {
            if (data.error) {
                handleFincanceiroError(data, setMessage);
            } else {
                setAnosLetivo(data);
                if (data.length === 0) {
                    setMessage({
                        type: typeSuccess,
                        description: 'Nenhuma parcela(s) cadastrada!'
                    })
                }
            }
            setIsLoading(false);
        })
    }, [usuario.arAlunoSelecionado.codigo]);

    const handleRefreshAnoLetivo = () => {
        setIsLoading(true);
        findYearsByCodAluno(usuario.arAlunoSelecionado.codigo).then((data) => {
            if (data.error) {
                handleFincanceiroError(data, setMessage);
            } else {
                setAnosLetivo(data);
                if (data.length === 0) {
                    setMessage({
                        type: typeSuccess,
                        description: 'Nenhuma parcela(s) cadastrada!'
                    })
                }
            }
            setIsLoading(false);
        })
    }

    return (
        <>
            {isLoading ?
                <Loading description='Carregando Ano Letivo...' />
                :
                <>
                    {message && message.type === typeSuccess ?
                        <div className={classes.successView}>
                            <p
                                className={classes.textSuccess}
                            >
                                {message.description}
                            </p>
                        </div>
                        : ''
                    }
                    <div className={classes.row}>
                        <p className={classes.textTitle}>Selecione o Ano Letivo</p>
                        <Button onClick={handleRefreshAnoLetivo}>Refresh</Button>

                    </div>
                    {anosLetivo.map((anoLetivo) => (
                        <div
                            key={anoLetivo}
                            className={classes.divButtonAnoLetivo}
                        >
                            <Button onClick={() => setAnoLetivo(anoLetivo)}>
                                {anoLetivo}
                            </Button>
                        </div>

                    ))
                    }

                </>
            }
        </>
    );
}

export default HistoricoAnoLetivoSelect