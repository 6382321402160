import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '../../UI/Container';
import IntegralProfessorTable from '../Professor/IntegralProfessorTable';
import { findByTurmaAnoLetivo } from '../Professor/api-integral-professor';
import Loading from '../../UI/Loading';
import { findByIntegralProfessorCodigo } from './api-integral-presenca';
import IntegralPresencaTable from './IntegralPresencaTable';
import Button from '../../UI/Button';
import IntegralPresencaForm from './IntegralPresencaForm';
import IntegralPresencaAlunoTable from '../PresencaAluno/IntegralPresencaAlunoTable';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    divPresencaAlunoTable: {
        marginTop: '20px'
    },
    btnMenuIntegral: {
        marginTop: '10px',
        marginBottom: '10px'
    }
})

const IntegralPresencaContainer = () => {
    const classes = useStyles();
    const [integralProfessor, setIntegralProfessor] = useState(null);
    const [integralPresenca, setIntegralPresenca] = useState(null);
    const [listaIP, setListaIP] = useState([]);
    const [isLoadingIP, setIsLoadingIP] = useState(true);
    const [isLoadingP, setIsLoadingP] = useState(true);
    const [listaPresenca, setListaPresenca] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    })
    const history = useHistory();

    useEffect(() => {
        findByTurmaAnoLetivo().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaIP(data);
            }
            setIsLoadingIP(false);
        })
    }, [])

    useEffect(() => {
        if (integralProfessor) {
            setIsLoadingP(true);
            findByIntegralProfessorCodigo(integralProfessor.codigo, 0).then((data) => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setListaPresenca(data);
                    setIsLoadingP(false);
                }
            })
        }

    }, [integralProfessor]);

    const handleNovoRegistro = () => {
        setIntegralPresenca({
            integralProfessor: integralProfessor,
            eNovo: true
        })
    }

    const handleChangePage = (event, newPage) => {
        setIsLoadingP(true);
        findByIntegralProfessorCodigo(integralProfessor.codigo, newPage).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaPresenca(data);
                setIsLoadingP(false);
            }
        })
    };

    const handleMenuIntegral = () => {
        history.push("/integral");
    }

    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Presença Integral
            </Typography>
            <div className={classes.btnMenuIntegral}>
                <Button
                    onClick={handleMenuIntegral}
                >
                    Menu Integral
                </Button>
            </div>

            {isLoadingIP ? <Loading description='Carregando Integral Professor...' /> :
                <>
                    {integralProfessor ?
                        <>
                            <Button onClick={handleNovoRegistro}>Novo Registro</Button>
                            {integralPresenca ?
                                <>
                                    <IntegralPresencaForm
                                        integralPresenca={integralPresenca}
                                        setIntegralPresenca={setIntegralPresenca}
                                        setListaPresenca={setListaPresenca}
                                    />
                                    <div className={classes.divPresencaAlunoTable}>
                                        {!integralPresenca.eNovo ?
                                            <IntegralPresencaAlunoTable
                                                integralPresenca={integralPresenca}
                                            /> :
                                            ''
                                        }
                                    </div>


                                </> :
                                <>
                                    {isLoadingP ? <Loading description="Carregando Integral Presença..." /> :
                                        <IntegralPresencaTable
                                            handleChangePage={handleChangePage}
                                            listaPresenca={listaPresenca}
                                            setIntegralPresenca={setIntegralPresenca}
                                        />
                                    }
                                </>
                            }

                        </>
                        :
                        <IntegralProfessorTable
                            listaIP={listaIP}
                            setIntegralProfessor={setIntegralProfessor}
                        />
                    }
                </>
            }



        </Container>
    )
}

export default IntegralPresencaContainer;