import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-serie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addSeries, updateSeries, setSerie as setSerieRedux } from './actions/actions'
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));

export default function SerieForm() {
    const classes = useStyles();
    const serieRedux = useSelector(state => state.serie);
    const [serie, setSerie] = useState(useSelector(state => state.serie));
    const dispatch = useDispatch();

    useEffect(() => {
        setSerie(serieRedux);
    }, [serieRedux]);

    const handleChange = (event) => {
        setSerie({
            ...serie,
            [event.target.name]: event.target.value
        });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = serie.eNovo;

        if (!serie.descricao) {
            toast.error("Preencha a Descrição.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        save(serie).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                console.log(eNovo);
                if (eNovo) {
                    dispatch(addSeries(data));
                } else {
                    dispatch(updateSeries(data));
                }
                dispatch(setSerie(data));
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <div>
            <ToastContainer autoClose={3000} />
            <CssBaseline />
            <Container component={Paper} maxWidth="sm">
                <CloseIcon
                    onClick={() => dispatch(setSerieRedux(null))}
                    className={classes.closeIcon}
                    fontSize='small'
                />
                <form onSubmit={clickSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        inputProps={{ maxLength: 45 }}
                        autoFocus
                        label="Descrição"
                        size='small'
                        name="descricao"
                        onChange={handleChange}
                        value={serie && serie.descricao ? serie.descricao : ''}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>
        </div>
    );
}