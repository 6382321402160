import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setAtividade } from './actions/actions';
import { dateFormat } from '../../util/DateUtil';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        width: 'fit-content'
    },
});

export default function AtividadeProfile() {
    const classes = useStyles();
    const atividade = useSelector(state => state.atividade);
    const dispatch = useDispatch();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Typography>
                    Atividade
                </Typography>
                <div style={{
                    display: 'flex'
                }}>

                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Turma
                        </Typography>
                        <Typography variant="body1">
                            {atividade ? atividade.tdp.turma.descricao : ''}
                        </Typography>
                    </div>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Disciplina
                        </Typography>
                        <Typography variant="body1">
                            {atividade ? atividade.tdp.disciplina.descricao : ''}
                        </Typography>
                    </div>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Data
                        </Typography>
                        <Typography variant="body1">
                            {atividade ? dateFormat(atividade.data) : ''}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Descrição
                        </Typography>
                        <Typography variant="body1">
                            {atividade ? atividade.descricao : ''}
                        </Typography>
                    </div>
                </div>
                <CardActions>
                    <Button
                        component={Link}
                        to="/atividade"
                        color="secondary"
                        onClick={() => {
                            dispatch(setAtividade(null));
                        }}
                        size="small"
                        variant="contained"
                    >
                        Trocar Atividade
                    </Button>
                </CardActions>

            </CardContent>
        </Card>
    );
}