import React from 'react';
import RespostaList from "./RespostaList";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
    voltarButton: {
        marginBottom: '20px',
    }
});

export default function RespostaContainer(props) {
    const classes = useStyles();

    const handleBack = () => {
        props.history.goBack();
    }

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                className={classes.voltarButton}
                onClick={handleBack}
            >
                <ArrowBackIcon style={{ marginRight: '10px' }} />
                Voltar
            </Button>
            <RespostaList conteudo={props.location.state.conteudo} />
        </>
    );
}