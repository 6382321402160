import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import EventoUsuarioTable from "./EventoUsuarioTable";
import EventoUsuarioForm from "./EventoUsuarioForm";

const useStyles = makeStyles({
    paper: {

        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function ComunicadoUsuarioContainer() {
    const classes = useStyles();
    const evento = useSelector(state => state.evento);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <Typography className={classes.title} variant="h5">
                            Eventos
                        </Typography>
                    </Grid>
                    
                </Grid>

                {evento ?
                    <EventoUsuarioForm />
                    : ''}

                {evento && evento.codigo ?
                    ''
                    : <EventoUsuarioTable />}
            </Container>
        </div>
    );
}