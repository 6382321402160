import React from 'react';

const BadgeQuantNaoLidas = ({ quantNaoLidas }) => {
    return (
        <>
            {quantNaoLidas || quantNaoLidas > 0 ?
                <span style={{
                    backgroundColor: '#64f25b',
                    borderRadius: '100%',
                    color: 'black',
                    marginLeft: '10px',
                    fontWeight: 'bold',
                    padding: '5px',
                    fontSize: '13px',
                }}>
                    {quantNaoLidas > 9 ? '+9' : quantNaoLidas}
                </span> : ''
            }

        </>
    );
}

export default BadgeQuantNaoLidas;