import {
  UPDATE_PRESENCA_ALUNOS,
  SET_PRESENCA_ALUNOS,
  SET_PRESENCA_ALUNO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function presencaAluno(state = null, action) {
  switch (action.type) {
    case SET_PRESENCA_ALUNO:
      return action.presencaAluno;

    case PURGE:
      return null;

    default:
      return state;
  }
}

export function presencaAlunos(state = [], action) {
  switch (action.type) {

    case UPDATE_PRESENCA_ALUNOS:
      return state.map((pa) => {
        if (pa.codigo === action.presencaAluno.codigo) {
          return action.presencaAluno;
        } else {
          return pa;
        }
      });

    case SET_PRESENCA_ALUNOS:
      return action.presencaAlunos

    case PURGE:
      return [];

    default:
      return state
  }
}
