import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Loading from '../UI/Loading';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        padding: '10px'
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    rowNotSelected: {
        cursor: 'pointer'
    },
    rowSelected: {
        cursor: 'pointer',
        backgroundColor: '#b5e7a4'
    }
});


export default function PresencaTable({ isLoading, presencaAlunos, presencaAluno, setPresencaAluno }) {
    const classes = useStyles();


    return (
        <Container className={classes.paper} component={Paper}>
            {isLoading ? <Loading description="Carregando Presença Aluno..." /> :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Número Chamada</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {presencaAlunos.map((pa) => (
                            <TableRow
                                className={presencaAluno && presencaAluno.codigo === pa.codigo ? classes.rowSelected : classes.rowNotSelected}
                                onClick={() => setPresencaAluno(pa)}
                                key={pa.codigo}
                            >
                                <TableCell>{pa.alunoTurma.numeroChamada}</TableCell>
                                <TableCell>{pa.aluno.nome}</TableCell>
                                <TableCell>{pa.legendaDiario.descricao}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }


        </Container>
    );
}