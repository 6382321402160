import { makeStyles } from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadCloud from "../../../shared/assets/img/download-cloud.png";

const useStyles = makeStyles({
    attachFile: {
        fontSize: '16px',
        alignItems: 'center',
        backgroundColor: '#ddfada',
        padding: '15px',
        borderRadius: '10px',
        marginBottom: '5px',
    },
    downloadIcon: {
        width: 40,
        height: 40,
        marginTop: 10,
        cursor: 'pointer'
    }
})

const AttachFile = ({
    sm,
    handleDownloadAttach
}) => {

    const classes = useStyles();
    return (
        <>
            {sm && sm.isAttachFile ?
                <div className={classes.attachFile}>
                    <p style={{ fontSize: 12 }}>
                        {sm.urlAttachFile}
                    </p>
                    <p style={{ fontSize: 12 }}>
                        {sm.fileName ? sm.fileName : ''}
                    </p>
                    <p style={{ fontSize: 12 }}>
                        Tipo: {sm.fileType ? sm.fileType : ''}
                    </p>
                    <img
                        onClick={() => handleDownloadAttach(sm)}
                        className={classes.downloadIcon}
                        src={DownloadCloud}
                        alt=''
                    />
                    {sm.urlAttachFile ?
                        <p style={{ fontSize: 12 }}>Anexo:
                            <span>
                                <a rel="noopener noreferrer" target="_blank" href={sm.urlAttachFile}>
                                    <GetAppIcon fontSize="large" />
                                </a>
                            </span>
                        </p> :
                        ''
                    }

                </div>
                :
                ''}
        </>
    )
}

export default AttachFile;