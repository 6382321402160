import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { updateEventos, addEventos, setEvento as setEventoRedux, increaseQuantAprovacaoEvento, decreaseQuantAprovacaoEvento } from './actions/actions';
import CloseIcon from '@material-ui/icons/Close';
import { save, send } from "./api-evento";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SendIcon from '@material-ui/icons/Send';
import { confirmAlert } from 'react-confirm-alert';
import SimpleDialogMessage from "../../../util/SimpleDialogMessage/SimpleDialogMessage";
import { open } from "../../../util/SimpleDialogMessage/actions/actions";
import { setTurmas } from "../../Turma/actions/actions";
import { setAluno } from "../../Aluno/actions/actions";
import SunEditor, { buttonList } from 'suneditor-react';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputImageByte from '../../UI/InputImageByte';
import { getBase64 } from '../../../util/Base64';
import { ptBR } from "date-fns/locale";
import { ADMIN, COORDENADOR } from '../../Usuario/Perfil';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 3),
    },
    send: {
        margin: theme.spacing(3, 0, 3),
        backgroundColor: '#4caf50'
    },
    buscarBtn: {
        margin: theme.spacing(3, 3, 3),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    title: {
        marginBottom: '20px'
    },
    criadoPorLabel: {
        fontStyle: 'bold'
    },
    criadoPorValue: {
        fontStyle: 'italic'
    },
    divCriadoPor: {
        margin: '20px',
        padding: '10px',
    }
}));

export default function EventoForm() {
    const classes = useStyles();
    const eventoRedux = useSelector(state => state.evento);
    const [evento, setEvento] = useState(eventoRedux);
    const dispatch = useDispatch();
    const [enviarPor] = useState([
        'Aluno',
        'Turma'
    ]);
    const [enviarPara] = useState([
        'Ambos',
        'Alunos',
        'Responsáveis'
    ]);
    const usuario = useSelector(state => state.usuario);
    const [message, setMessage] = useState({});



    useEffect(() => {
        setEvento(eventoRedux);
    }, [eventoRedux]);


    const handleChange = (event) => {
        setEvento({
            ...evento,
            [event.target.name]: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = evento.eNovo;
        if (eNovo) {
            dispatch(increaseQuantAprovacaoEvento());
        }
        if (!evento.enviarPor) {
            toast.error("Selecione por qual opção será enviado o evento.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        if (!evento.enviarPara) {
            toast.error("Selecione para quem será enviado o evento.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        save(evento).then(data => {
            if (data) {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addEventos(data));
                } else {
                    dispatch(updateEventos(data));
                }
                dispatch(setEventoRedux(data));
                setEvento(data);
            }
        })
        dispatch(setTurmas([]));
        dispatch(setAluno(null));
    }

    const handleEnviarPorChange = (event) => {
        const ep = enviarPor.find((ep) => {
            return ep === event.target.value
        });
        console.log(ep);
        setEvento({
            ...evento,
            enviarPor: ep
        });
    }

    const handleEnviarParaChange = (event) => {
        const ep = enviarPara.find((ep) => {
            return ep === event.target.value
        });
        console.log(ep);
        setEvento({
            ...evento,
            enviarPara: ep
        });
    }

    const handleDataHoraInicioChange = (horarioInicio) => {

        setEvento({
            ...evento,
            dataHoraInicio: horarioInicio
        })
    }

    const handleDataHoraFimChange = (horarioFim) => {

        setEvento({
            ...evento,
            dataHoraFim: horarioFim
        })
    }

    const handleAprove = () => {
        const persist = {
            ...evento,
            aprovado: !evento.aprovado
        }
        if (persist.aprovado) {
            dispatch(decreaseQuantAprovacaoEvento());
        } else {
            dispatch(increaseQuantAprovacaoEvento());
        }
        save(persist).then(data => {
            if (data) {
                let message = '';
                if (data.aprovado) {
                    message = "Evento Aprovado com Sucesso.";
                } else {
                    message = "Aprovação Removida com Sucesso.";
                }
                toast.success(message, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                dispatch(updateEventos(data));
                dispatch(setEventoRedux(data));
                setEvento(data);
            }
        });
    }

    const handleSend = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja enviar este evento? Após confirmado esta operação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        send(evento.codigo).then(data => {
                            if (data.error) {
                                if (data.message === 'No turma registered') {
                                    setMessage({
                                        title: 'Evento não foi enviado',
                                        description: 'Evento não possui turmas cadastradas para o envio.'
                                    });
                                    dispatch(open());

                                } else if (data.message === 'No aluno registered') {
                                    setMessage({
                                        title: 'Evento não foi enviado',
                                        description: 'Evento não possui alunos cadastrados para o envio.'
                                    });
                                    dispatch(open());

                                }
                            } else {
                                toast.success("Evento Enviado com Sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                                dispatch(updateEventos(data));
                                dispatch(setEventoRedux(data));
                                setEvento(data);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleChangeDescricao = (value) => {
        setEvento(prevState => ({
            ...prevState,
            descricao: value.replace(/[\u200B-\u200D\uFEFF]/g, '')
        }))
    }

    const handleChangeImage = e => {
        let file = e.target.files[0];

        getBase64(file).then(result => {
            setEvento({
                ...evento,
                banner: result
            });
        }).catch(err => {
            console.log(err);
        });
    };

    const handleRemoveBanner = () => {
        setEvento({
            ...evento,
            banner: null
        });
    }


    return (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <SimpleDialogMessage title={message.title} description={message.description} />
            <Container className={classes.paper} component={Paper} >
                <CloseIcon
                    onClick={() => dispatch(setEventoRedux(null))}
                    className={classes.closeIcon}
                    fontSize='small'
                />
                <Grid container spacing={2}>

                    <form onSubmit={clickSubmit}>

                        <FormControl fullWidth >
                            <InputLabel id="enviar-por-label">Enviar Por:</InputLabel>
                            <Select
                                disabled={evento.enviado}
                                labelId="enviar-por-label"
                                id="enviar-por-label"
                                value={
                                    evento.enviarPor ?
                                        evento.enviarPor :
                                        ''
                                }
                                onChange={handleEnviarPorChange}
                            >
                                {enviarPor.map((ep, index) => (
                                    <MenuItem key={index} value={ep}>
                                        {ep}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                        <FormControl fullWidth >
                            <InputLabel id="perfil-label">Enviar Para:</InputLabel>
                            <Select
                                disabled={evento.enviado}
                                labelId="enviar-para-label"
                                id="enviar-para-select"
                                value={
                                    evento.enviarPara ?
                                        evento.enviarPara :
                                        ''
                                }
                                onChange={handleEnviarParaChange}
                            >
                                {enviarPara.map((ep, index) => (
                                    <MenuItem key={index} value={ep}>
                                        {ep}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            label="Título"
                            name="titulo"
                            onChange={handleChange}
                            inputProps={{ maxLength: 100 }}
                            value={evento && evento.titulo ? evento.titulo : ''}
                        />

                        <DateTimePicker
                            fullWidth
                            ampm={false}
                            required
                            size='small'
                            variant="outlined"
                            margin="normal"
                            label="Data Hora Início"
                            value={evento && evento.dataHoraInicio ? evento.dataHoraInicio : null}
                            onChange={handleDataHoraInicioChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />

                        <DateTimePicker
                            fullWidth
                            required
                            ampm={false}
                            size='small'
                            variant="outlined"
                            margin="normal"
                            label="Data Hora Fim"
                            value={evento && evento.dataHoraFim ? evento.dataHoraFim : null}
                            onChange={handleDataHoraFimChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />

                        <InputImageByte
                            image={evento.banner ? evento.banner : null}
                            handleChangeImage={handleChangeImage}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            component="span"
                            style={{
                                marginTop: '20px',
                                textTransform: 'none',
                            }}
                            onClick={handleRemoveBanner}
                        >
                            Remover Banner
                        </Button>

                        <SunEditor
                            onChange={handleChangeDescricao}
                            setContents={evento && evento.descricao ? evento.descricao : ''}
                            setOptions={{
                                height: 500,
                                buttonList: buttonList.complex
                            }}
                            setDefaultStyle="font-size: 18px;"
                        />

                        {evento.usuario ? <div className={classes.divCriadoPor}>
                            <p className={classes.criadoPorLabel}>Criado por:</p>
                            <p className={classes.criadoPorValue}>{evento.usuario.nome} - {evento.usuario.perfil}</p>
                        </div> : ''}

                        <Grid container justify="space-between">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Salvar
                            </Button>
                            {evento.codigo &&
                                (usuario.perfil === ADMIN ||
                                    usuario.perfil === COORDENADOR) ?
                                <Button
                                    disabled={evento.enviado}
                                    variant="contained"
                                    onClick={handleAprove}
                                    color="secondary"
                                    className={classes.submit}>
                                    {evento.aprovado ? 'Remover Aprovação' : 'Aprovar'}
                                </Button> : null
                            }

                            <Button
                                disabled={evento.enviado || !evento.aprovado}
                                onClick={handleSend}
                                startIcon={<SendIcon />}
                                variant="contained"
                                className={classes.send}>
                                Enviar
                            </Button>
                        </Grid>
                    </form>


                </Grid>


            </Container>

        </MuiPickersUtilsProvider>
    );
}