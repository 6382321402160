import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuthReturn
} from '../../util/FetchUtil';

const controller = 'usuario-perfil';

const findUnregisteredPerfis = (codUsuario) => {
  const url = SERVER_URL + controller + '/unregistered-perfis/' + codUsuario;
  return fetchGetAuth(url);
}

const remove = (codUsuarioPerfil) => {
  const url = SERVER_URL + controller + '/delete/' + codUsuarioPerfil;
  return fetchRemoveAuthReturn(url);
}

const save = (usuario) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, usuario);
}

const findByUsuario = (codUsuario) => {
  const url = SERVER_URL + controller + "/usuario/" + codUsuario;
  return fetchGetAuth(url);
}

const findByPerfil = (perfil) => {
  const url = SERVER_URL + controller + '/' + perfil;
  return fetchGetAuth(url);
}

export {
  findUnregisteredPerfis,
  save,
  remove,
  findByUsuario,
  findByPerfil
}