import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { page, remove } from './api-observacao-turma';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setObservacoesTurma, removeObservacoesTurma, setObservacaoTurma } from './actions/actions';
import { dateFormat } from '../../util/DateUtil';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function ObservacaoTurmaTable() {
    const classes = useStyles();
    const observacoesTurma = useSelector(state => state.observacoesTurma);
    const tdp = useSelector(state => state.tdp);
    const dispatch = useDispatch();

    useEffect(() => {
        page(tdp.codigo, 0).then((data) => {
            if (data) {
                dispatch(setObservacoesTurma(data));
                dispatch(setObservacaoTurma(null));
            }
        })
    }, [dispatch, tdp]);

    const handleChangePage = (event, newPage) => {
        page(tdp.codigo, newPage).then((data) => {
            if (data) {
                console.log(data.error);
            } else {
                dispatch(setObservacoesTurma(data));
            }
        });
    };

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta observação?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(observacoesTurma.content[index].codigo).then((data) => {
                            if (data) {
                                dispatch(removeObservacoesTurma(index));
                                dispatch(setObservacaoTurma(null));
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <div>
            <Button
                className={classes.novoRegistro}
                onClick={() => dispatch(setObservacaoTurma({
                    tdp: tdp
                }))}
                variant="contained"
                color="primary">
                Novo Observação Turma
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Conteúdo</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {observacoesTurma.content.map((presenca, index) => (
                        <TableRow key={presenca.codigo}>
                            <TableCell>{presenca.conteudo}</TableCell>
                            <TableCell>{dateFormat(presenca.data)}</TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    color="secondary"
                                    style={{ marginRight: '10px' }}
                                    className={classes.cursorLink}
                                    onClick={() => dispatch(setObservacaoTurma(presenca))}
                                />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    style={{ marginRight: '10px' }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={observacoesTurma.size ? observacoesTurma.size : 0}
                component="div"
                count={observacoesTurma.totalElements}
                page={observacoesTurma.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />
        </div>
    );
}