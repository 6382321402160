import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Button from '../../UI/Button';
import { dateFormat, dayOfWeekNameByDate } from '../../../util/DateUtil';
import Loading from '../../UI/Loading';


const CronogramaQuantAulaDiaTable = ({ list, handleChangePage, setCqad, isLoading, handleNewRegister }) => {

    return (
        <div>
            <Button
                onClick={handleNewRegister}
                variant="contained"
                color="primary">
                Novo Registro
            </Button>
            {isLoading ?
                <Loading description="Carregando Cronograma Quant. Aula Dia.." /> :
                <>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell >Data</TableCell>
                                <TableCell >Quantidade</TableCell>
                                <TableCell >Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.content.map((cqad) => (
                                <TableRow key={cqad.codigo}>
                                    <TableCell>{dateFormat(cqad.data)} ({cqad.data ? dayOfWeekNameByDate(cqad.data) : ''})</TableCell>
                                    <TableCell>{cqad.quantAulas}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => setCqad(cqad)}
                                        >
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={list.size ? list.size : 0}
                        component="div"
                        count={list.totalElements}
                        page={list.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>
            }


        </div>
    );
}

export default CronogramaQuantAulaDiaTable;