import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AlunoForm from './Form/AlunoForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AlunoTable from '../Aluno/AlunoTable';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '../UI/Container';
import AlunoFindDialog from './AlunoFindDialog';
import { page, remove, save, uploadPhoto, deleteFile } from './api-aluno';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function AlunoContainer() {
    const classes = useStyles();
    const [aluno, setAluno] = useState(null);
    const [alunos, setAlunos] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSearch, setOpenSearch] = useState(null);
    const [isAttachingFile, setIsAttachingFile] = useState(false);

    useEffect(() => {
        page(0).then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setAlunos(data);
            }
        });
    }, []);

    

    const handleChangePage = (event, newPage) => {
        page(newPage).then((data) => {
            if (data.error) {
                errorMessage(data.error);
            } else {
                setAlunos(data)
            }
        });
    };

    const handleDeleteUsuario = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja remover a referência do aluno com este usuário?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setAluno({
                            ...aluno,
                            usuarioAluno: null
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleDeleteFile = () => {
        deleteFile(aluno.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                updateAlunoState(data);
                setAluno(data);
            }
        })
    }

    const handleDelete = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este aluno?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(aluno.codigo).then((data) => {
                            if (data.error) {
                                handleDeleteError();
                            } else {
                                setAlunos((prevState) => prevState.content.filter((content) => content.codigo !== aluno.codigo));
                                setAluno(null);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'O Aluno não pode ser removido pois possui Turmas cadastradas com o mesmo. Remova todas as turmas do aluno cadastrados.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurarAluno = () => {
        handleMenuClose();
        setOpenSearch(true);
    }

    const handleChangeFile = event => {
        const file = event.target.files[0];

        if (file === null) {
            return;
        }
        const eNovo = aluno.eNovo;

        const fileFormData = new FormData();
        fileFormData.append('file', file);
        setIsAttachingFile(true);
        uploadPhoto(aluno.codigo, fileFormData).then((data) => {
            if (data.error) {
                errorMessage(data.error);
            } else {
                toast.success("Upload feito com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    addAlunoState(data);
                } else {
                    updateAlunoState(data);
                }
                setAluno(data);
            }
            setIsAttachingFile(false);
        })

    };

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = aluno.eNovo;
        save(aluno).then(data => {
            if (data.error) {
                if (data.message === 'Erro: CodAlunoEscola already in use') {
                    handleRepeatedCode();
                } else if (data.message === 'User already registered for a Student') {
                    handleUserAlreadyRegisteredForStudent();
                } else {
                    errorMessage(data.message);
                }
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    addAlunoState(data);
                } else {
                    updateAlunoState(data);
                }
                setAluno(data);
            }

        })
    }

    const addAlunoState = (data) => {
        setAlunos((prevState) => ({
            ...prevState,
            content: [
                data,
                ...prevState.content
            ]
        }))
    }

    const updateAlunoState = (data) => {
        setAlunos((prevState) => ({
            ...prevState,
            content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                    return data;
                } else {
                    return content;
                }
            })
        }))
    }

    const handleRepeatedCode = () => {
        confirmAlert({
            title: 'Erro',
            message: 'Código já utilizado por outro aluno.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleUserAlreadyRegisteredForStudent = () => {
        confirmAlert({
            title: 'Erro',
            message: 'Usuário já registrado para outro aluno.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <Container>

            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Alunos
                    </Typography>
                </Grid>
                <Grid style={{ flexBasis: '5%' }} item xs={3}>


                    <MenuIcon
                        fontSize='medium'
                        className={classes.cursorLink}
                        onClick={(event) => handleMenuClick(event)}
                        color="secondary"

                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem
                            style={{
                                fontSize: 12
                            }}
                            onClick={() => handleProcurarAluno()}
                        >
                            Procurar Aluno
                        </MenuItem>
                    </Menu>

                </Grid>
            </Grid>

            <AlunoFindDialog
                openSearch={openSearch}
                setOpenSearch={setOpenSearch}
                setAluno={setAluno}
            />

            {aluno ?
                <AlunoForm
                    aluno={aluno}
                    setAluno={setAluno}
                    clickSubmit={clickSubmit}
                    handleDelete={handleDelete}
                    isAttachingFile={isAttachingFile}
                    handleDeleteUsuario={handleDeleteUsuario}
                    handleChangeFile={handleChangeFile}
                    handleDeleteFile={handleDeleteFile}
                />
                :
                <AlunoTable
                    alunos={alunos}
                    handleChangePage={handleChangePage}
                    setAluno={setAluno}
                />
            }

        </Container>
    );
}