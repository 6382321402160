import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../../../util/ConfirmAlertUtil';
import { dateFormat } from '../../../../util/DateUtil';
import BimestreProfileWithoutChange from '../../../Bimestre/BimestreProfileWithoutChange';
import TurmaDisciplinaProfessorProfileWithoutChange from '../../../TDP/TurmaDisciplinaProfessorProfileWithoutChange';
import Button from '../../../UI/Button';
import Loading from '../../../UI/Loading';
import { check, checkByNumeroBimestre } from '../api-cronograma-quant-aula-dia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { findByAnoLetivoAndSerieAndNumeroBimestre } from '../../../Bimestre/api-bimestre';
import { errorBg, errorBorder, successBg, successBorder } from '../../../UI/ColorUtil';

const ConferirComPresencaContainer = (props) => {
    const { tdp, numeroBimestre } = props.location.state;
    const [bimestre, setBimestre] = useState(props.location.state.bimestre);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (tdp && tdp.codigo && bimestre && bimestre.codigo) {
            check(tdp.codigo, bimestre.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setList(data);
                }
                setIsLoading(false);
            })
        }

    }, [tdp, bimestre]);

    useEffect(() => {
        if (tdp.codigo && numeroBimestre) {
            checkByNumeroBimestre(tdp.codigo, numeroBimestre).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setList(data);
                }
            });
            findByAnoLetivoAndSerieAndNumeroBimestre(tdp.turma.serie.codSerie, numeroBimestre).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setBimestre(data);
                }
            })
        }
    }, [tdp, numeroBimestre]);

    return (
        <Grid container spacing={3} component={Paper}
            style={{ margin: '10px' }}
        >

            <Grid item>
                <TurmaDisciplinaProfessorProfileWithoutChange tdp={tdp} />
            </Grid>
            {bimestre &&
                <Grid item>
                    <BimestreProfileWithoutChange bimestre={bimestre} />
                </Grid>
            }


            <Grid item xs={12}>
                <Button
                    onClick={() => history.goBack()}
                    variant="contained"
                    color="secondary"
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    Conferência Cronograma - Presença
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {isLoading ? <Loading /> :
                    <Grid container spacing={2} direction='row'>
                        {list.map((ccp, index) => (
                            <Grid
                                item
                                xs={3}
                                key={index}
                                style={{
                                    backgroundColor: ccp.isCorrect ? successBg : errorBg,
                                    borderRadius: '20px',
                                    borderStyle: 'solid',
                                    borderColor: ccp.isCorrect ? successBorder : errorBorder,
                                    borderWidth: '5px',
                                    margin: '10px',
                                    padding: '10px',
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    direction='column'
                                    style={{ textAlign: 'center' }}
                                >
                                    <Grid item>
                                        <Typography variant='body'>
                                            Data: {dateFormat(ccp.data)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body'>
                                            {ccp.message}
                                        </Typography>
                                    </Grid>


                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                }
            </Grid>


        </Grid>
    )
}

export default ConferirComPresencaContainer;