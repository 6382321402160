import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByCurrentUserOrderByLivroDescricao } from './api-aluno-livro';
import SimpleDialogMessage from "../../util/SimpleDialogMessage/SimpleDialogMessage";
import { open } from "../../util/SimpleDialogMessage/actions/actions";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAlunoLivro } from './actions/actions';
import { errorMessage } from '../../util/ConfirmAlertUtil';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const AlunoLivroList = () => {
    const classes = useStyles();
    const [alunoLivros, setAlunoLivros] = useState([]);
    const [message, setMessage] = useState({
        title: '',
        description: ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        findByCurrentUserOrderByLivroDescricao().then((data) => {
            if (data.error) {
                if (data.message === 'No student registered for this user') {
                    setMessage({
                        title: 'Alerta',
                        description: 'Nenhum aluno cadastrado para este usuário.'
                    });
                    dispatch(open());
                } else {
                    errorMessage(data.message);
                }
            } else {
                setAlunoLivros(data);
            }
        })
    }, [dispatch]);


    return (
        <Container component={Paper}>
            <SimpleDialogMessage title={message.title} description={message.description} />
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alunoLivros.map((alunoLivro) => (
                        <TableRow
                            className={classes.cursorLink}
                            key={alunoLivro.codigo}
                            component={Link}
                            onClick={() => dispatch(setAlunoLivro(alunoLivro))}
                            to={{
                                pathname: '/conteudos/' + alunoLivro.livro.codLivro,
                            }}
                        >
                            <TableCell>{alunoLivro.livro.descricao}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}

export default AlunoLivroList;