import {
  SET_IS_ALL,
  SET_SALAS,
} from './actionTypes';
import { PURGE } from "redux-persist";

export function isAllMensagemTable(state = false, action) {
  switch (action.type) {
    case SET_IS_ALL:
      return action.isAll
    case PURGE:
      return null;
    default:
      return state
  }
}

export function salas(state = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
}, action) {
  switch (action.type) {
    case SET_SALAS:
      return action.salas
    case PURGE:
      return [];

    default:
      return state
  }
}
