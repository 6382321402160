import React, {  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlunoTextFieldSelect from '../../Aluno/UI/AlunoTextFieldSelect';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function AlunoLivroFormAdd({ alunoLivro, setAlunoLivro, handleSaveAlunoLivro, loadingSave }) {
    const classes = useStyles();

    const handleSetAluno = (aluno) => {
        setAlunoLivro((prevState) => ({
            ...prevState,
            aluno: aluno
        }))
    }


    return (
        <Container className={classes.paper} component={Paper}>
            <CloseIcon
                fontSize='small'
                onClick={() => setAlunoLivro(null)}
                className={classes.closeIcon}
            />
            <form onSubmit={handleSaveAlunoLivro}>
                <AlunoTextFieldSelect
                    aluno={alunoLivro && alunoLivro.aluno ? alunoLivro.aluno : null}
                    setAluno={handleSetAluno}
                />


                <Button

                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loadingSave}
                >
                    {loadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                    Salvar
                </Button>
            </form>
        </Container>
    );
}