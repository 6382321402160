import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { findByTdpCodigoRegistered } from './api-cronograma-quant-aula-bimestre';
import RefreshIcon from '@material-ui/icons/Refresh';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        marginTop: '20px',
        margin: 'auto',
        width: '100%',
        flexGrow: 1
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
    refresh: {
        cursor: 'pointer',
        marginLeft: '20px'
    }
});

export default function PlanejamentoQuantAulasReg({ tdp }) {
    const classes = useStyles();
    const [list, setList] = useState([]);

    useEffect(() => {
        findByTdpCodigoRegistered(tdp.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setList(data);
            }
        });
    }, [tdp]);



    const handleUpdate = () => {
        findByTdpCodigoRegistered(tdp.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setList(data);
            }
        });
    }

    return (
        <>
            {list.length > 0 &&
                <Card className={classes.card} variant="outlined">
                    <CardContent>
                        <Typography variant='body1' >
                            Planejamento Aulas Registradas - (Quant. Aulas / Quant. Aulas Registradas)
                            <RefreshIcon
                                className={classes.refresh}
                                fontSize="small"
                                onClick={handleUpdate}
                            />
                        </Typography>

                        <Grid container spacing={3}>
                            {list.map((cqab, index) => (
                                <Grid key={index} item xs={3}>
                                    <Typography variant="body1" color="textSecondary" gutterBottom>
                                        {cqab.bimestre.numeroBimestre}º Bimestre
                                    </Typography>
                                    <Typography variant="body1">
                                        {cqab.quantAulas} / {cqab.quantAulasRegistradas}
                                    </Typography>
                                    <Link
                                        to={{
                                            pathname: "conferir-cronograma-presenca",
                                            state: {
                                                tdp: tdp,
                                                bimestre: cqab.bimestre
                                            }
                                        }}
                                    >
                                        Conferir
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            }

        </>
    );
}