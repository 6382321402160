import React, { useState } from 'react';
import LateralMenuContent from '../LateralMenuContent';
import LivroForm from '../../../../components/Livro/LivroForm';

export default function LivroFormMenu(props) {
    let livroProp = {};
    if (props.location.state) {
        livroProp = props.location.state.livro;
    }
    const [livro] = useState(livroProp);
    return (
        <LateralMenuContent child={LivroForm} livro={livro} />
    );
}