import { Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';
import { diaNaoLetivoKey, diaNaoLetivoSerieKey } from './DiaNaoLetivoMenuContainer';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        padding: '20px',

    }
})

const DiaNaoLetivoMenu = ({ menu, setMenu }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            component={Paper}
            spacing={3}
            justifyContent='center'
            className={classes.container}

        >
            <Grid item>
                <Button
                    onClick={() => setMenu(diaNaoLetivoKey)}
                    disabled={menu === diaNaoLetivoKey}
                    xs={12} sm={12} md={6} lg={6} xl={6}
                >
                    Dia não Letivo
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => setMenu(diaNaoLetivoSerieKey)}
                    disabled={menu === diaNaoLetivoSerieKey}
                    xs={12} sm={12} md={6} lg={6} xl={6}
                >
                    Vincular Série
                </Button>
            </Grid>
        </Grid>
    )
}

export default DiaNaoLetivoMenu;