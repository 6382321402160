import {
  ADD_AVALIACOES,
  UPDATE_AVALIACOES,
  SET_AVALIACOES,
  REMOVE_AVALIACOES,
  SET_AVALIACAO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function avaliacao(state = null, action) {
  switch (action.type) {
    case SET_AVALIACAO:
      return action.avaliacao

    case PURGE:
      return null;
    default:
      return state
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function avaliacoes(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_AVALIACOES:
      persist.content = [
        action.avaliacao,
        ...persist.content
      ]
      return persist

    case UPDATE_AVALIACOES:
      let content = state.content.map((avaliacao) => {
        if (avaliacao.codigo === action.avaliacao.codigo) {
          return action.avaliacao;
        } else {
          return avaliacao;
        }
      });
      persist.content = content;
      return persist;

    case SET_AVALIACOES:
      return action.avaliacoes

    case REMOVE_AVALIACOES:

      persist.content.splice(action.index, 1);
      return persist;
    case PURGE:
      return initState;
    default:
      return state
  }
}
