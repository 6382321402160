import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AvaliacaoAlunoPorAvaliacaoTableGroup from './AvaliacaoAlunoPorAvaliacaoTableGroup';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import AvaliacaoAlunoEnviar from './AvaliacaoAlunoEnviar';
import { useSelector } from 'react-redux';
import AvaliacaoAlunoForm from './AvaliacaoAlunoForm';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px',
    },
    voltarButton: {
        marginBottom: '20px',
        marginRight: '10px',
        cursor: 'pointer'
    }
});

export default function AvaliacaoAlunoContainer() {
    const classes = useStyles();
    const avaliacao = useSelector(state => state.avaliacao);
    const avaliacaoAluno = useSelector(state => state.avaliacaoAluno);


    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Link to={{
                    pathname: '/avaliacao'
                }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.voltarButton}
                    >
                        <ArrowBackIcon style={{ marginRight: '10px' }} />
                        Voltar
                    </Button>
                </Link>




                <Typography className={classes.title} variant="h6">
                    Avaliação Aluno
                </Typography>

                {avaliacaoAluno ? <AvaliacaoAlunoForm /> :
                    <>
                        {avaliacao && avaliacao.esimulado ? '' : <AvaliacaoAlunoEnviar />}
                        <AvaliacaoAlunoPorAvaliacaoTableGroup />
                    </>}

            </Container>

        </div>
    );
}