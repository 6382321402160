import React, { useState, useEffect } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import { page } from '../SalaParticipante/api-sala-participante';
import Loading from '../../UI/Loading';
import MensagemUser from '../MensagemUser';


export default function MensagemListUser() {
    const [salasParticipante, setSalasParticipante] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        page(0).then((data) => {
            if (data) {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setSalasParticipante(data);
                    setIsLoading(false);
                }
            }

        });
    }, []);

    useEffect(() => {
        const findNewMessages = setInterval(() => {
            page(salasParticipante.number).then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        setSalasParticipante(data);
                    }
                }

            })
        }, 10000);
        return () => clearInterval(findNewMessages);

    }, [salasParticipante.number]);


    const getNameRoom = (sp) => {
        if (sp.sala.isGroup) {
            return sp.sala.descricao;
        } else {
            return sp.usuario.nome;
        }
    }



    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data) {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setSalasParticipante(data);
                    setIsLoading(false);
                }
            }

        });
    };

    return (
        <>
            {isLoading ? <Loading description='Carregando Salas...' /> :
                <>

                    {salasParticipante.content.map((sp) => (
                        <MensagemUser
                            sp={sp}
                            getNameRoom={getNameRoom}
                        />
                    ))}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={salasParticipante.size ? salasParticipante.size : 0}
                        component="div"
                        count={salasParticipante.totalElements}
                        page={salasParticipante.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>
            }

        </>
    )
}