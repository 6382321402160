import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import { findAcessoByLastFiveDays, findAcessoByAnoLetivo } from "./api-engajamento-diario";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
    anoLetivoBtn: {
        marginLeft: 20
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    },
    paper: {
        padding: '20px',
    },
});

export default function Adesao() {
    const classes = useStyles();
    const [acesso, setAcesso] = useState();
    const [chartSelected, setChartSelected] = useState('fiveLastDays');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        findAcessoByLastFiveDays().then(data => {
            if (data.error) {
                console.log(data);
            } else {
                setAcesso(data);
                setIsLoading(false);
            }
        })

    }, []);

    const handleFiveLastDaysChart = () => {
        setAcesso(null);
        setChartSelected('fiveLastDays');
        setIsLoading(true);
        findAcessoByLastFiveDays().then(data => {
            if (data.error) {
                console.log(data);
            } else {
                setAcesso(data);
                setIsLoading(false);
            }
        })
    }

    const handleAnoLetivoChart = () => {
        setAcesso(null);
        setChartSelected('anoLetivo');
        setIsLoading(true);
        findAcessoByAnoLetivo().then(data => {
            if (data.error) {
                console.log(data);
            } else {
                setAcesso(data);
                setIsLoading(false);
            }
        })
    }

    return (
        <Container className={classes.paper} >
            <Typography variant="h5">
                Acesso
            </Typography>
            <Typography variant="h6">
                Buscar Por:
            </Typography>
            <Button
                variant="contained"
                color="primary"
                disabled={chartSelected === 'fiveLastDays' ? true : false}
                onClick={handleFiveLastDaysChart}
            >
                {isLoading && chartSelected === 'fiveLastDays' ? <CircularProgress className={classes.loading} color="primary" size={20} /> : ''}
                5 Últimos Dias
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.anoLetivoBtn}
                disabled={chartSelected === 'anoLetivo' ? true : false}
                onClick={handleAnoLetivoChart}
            >
                {isLoading && chartSelected === 'anoLetivo' ? <CircularProgress className={classes.loading} color="primary" size={20} /> : ''}
                Ano Letivo
            </Button>


            <Bar
                data={{
                    labels: acesso && acesso.listaAxeX ? acesso.listaAxeX : [],
                    datasets: [
                        {
                            label: 'Total Responsáveis',
                            data: acesso && acesso.listaTotalResp ? acesso.listaTotalResp : [],
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1
                        },
                        {
                            label: 'Total Alunos',
                            data: acesso && acesso.listaTotalAluno ? acesso.listaTotalAluno : [],
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1
                        }
                    ]
                }}
                height={100}
                width={600}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    }
                }}
            />
        </Container>
    );
}