import React, { useEffect } from "react";
import { Container, Paper } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import CronogramaAulaTable from './CronogramaAulaTable';
import CronogramaAulaForm from './CronogramaAulaForm';
import { setCronogramasAula } from "./actions/actions";

const CronogramaAulaContainer = () => {
    const cronogramaAula = useSelector(state => state.cronogramaAula);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCronogramasAula([]));
    }, [dispatch])

    return (
        <Container component={Paper}>
            <Typography variant="h6">
                Cronograma Aula
            </Typography>

            {cronogramaAula ?
                <CronogramaAulaForm /> :
                <CronogramaAulaTable />}

        </Container>
    );
}

export default CronogramaAulaContainer;