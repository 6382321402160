import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { dateFormat } from '../../../util/DateUtil';

const useStyles = makeStyles({
    card: {
        width: 'fit-content'
    },
});

export default function CronogramaDataProfile({ cronogramaData, setCronogramaData }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Data Início
                        </Typography>
                        <Typography variant="body1" >
                            {dateFormat(cronogramaData.dataInicio)}
                        </Typography>
                    </div>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Data Fim
                        </Typography>
                        <Typography variant="body1" >
                            {cronogramaData.dataFim && dateFormat(cronogramaData.dataFim)}
                        </Typography>
                    </div>

                </div>

                <CardActions>
                    <Button
                        onClick={() => setCronogramaData(null)}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >
                        Trocar Cronograma Data
                    </Button>
                </CardActions>

            </CardContent>
        </Card>
    );
}