import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Container from "../UI/Container";
import TurmaTableSelectView from "../Turma/TurmaTableSelectView";
import TurmaProfile from "../Turma/TurmaProfile";
import { findByAnoLetivo } from "../Turma/api-turma";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import { findByTurma } from "../AlunoTurma/api-aluno-turma";
import AlunoTurmaTableSelectView from "../AlunoTurma/AlunoTurmaTableSelectView";
import Loading from "../UI/Loading";
import AlunoTurmaProfileView from "../AlunoTurma/AlunoTurmaProfileView";
import CatracaRegistroUsuarioList from "./CatracaRegistroUsuario/CatracaRegistroUsuarioList";
import {
  findByAlunoCodigo,
  findByAlunoCodigoAndDate,
  save,
} from "./api-catraca-registro";
import CatracaRegistroForm from "./CatracaRegistroForm";

const CatracaRegistroContainer = () => {
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [isLoadingTurma, setIsLoadingTurma] = useState(false);
  const [alunosTurma, setAlunosTurma] = useState([]);
  const [alunoTurma, setAlunoTurma] = useState(null);
  const [isLoadingAlunoTurma, setIsLoadingAlunoTurma] = useState(false);
  const [listCR, setListCR] = useState({
    content: [],
    number: 0,
    size: 0,
    totalElements: 0,
  });
  const [catracaRegistro, setCatracaRegistro] = useState(null);
  const [isLoadingCatracaRegistro, setIsLoadingCatracaRegistro] =
    useState(false);
  const todosKey = "todos";
  const [filtro, setFiltro] = useState(todosKey);
  const porDataKey = "porData";
  const [dateSelected, setDateSelected] = useState(new Date());
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleTrocaTurma = () => {
    setTurma(null);
  };

  useEffect(() => {
    setIsLoadingTurma(true);
    findByAnoLetivo().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setTurmas(data);
        }
      }
      setIsLoadingTurma(false);
    });
  }, []);

  useEffect(() => {
    if (turma && turma.codigo) {
      setIsLoadingAlunoTurma(true);
      findByTurma(turma.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setAlunosTurma(data);
          }
        }
        setIsLoadingAlunoTurma(false);
      });
    }
  }, [turma]);

  useEffect(() => {
    if (alunoTurma && alunoTurma.aluno && alunoTurma.aluno.codigo) {
      fetchData(0);
    }
    // eslint-disable-next-line
  }, [alunoTurma, filtro]);

  const handleChangeFiltro = (event) => {
    setFiltro(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);
  };

  const fetchData = (newPage) => {
    setIsLoadingCatracaRegistro(true);
    if (filtro === todosKey) {
      findByAlunoCodigo(alunoTurma.aluno.codigo, newPage).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCR(data);
          }
        }
        setIsLoadingCatracaRegistro(false);
      });
    } else if (filtro === porDataKey) {
      findByAlunoCodigoAndDate(
        alunoTurma.aluno.codigo,
        dateSelected,
        newPage
      ).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCR(data);
          }
        }
        setIsLoadingCatracaRegistro(false);
      });
    }
  };

  const handleChangeAlunoTurma = () => {
    setAlunoTurma(null);
  };

  const handleDataChange = (data) => {
    if (data) data.setHours(0, 0, 0, 0);

    setDateSelected(data);
  };

  const handleNewRecord = () => {
    setCatracaRegistro({
      aluno: alunoTurma.aluno,
      enviarNotificacao: false,
    });
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (catracaRegistro.entradaSaida === null) {
      errorMessage("Preencha Entrada/Saída");
    }
    if (catracaRegistro.dataHora === null) {
      errorMessage("Preencha Data Hora");
    }
    setIsLoadingSave(true);
    save(catracaRegistro).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCatracaRegistro(data);
          setListCR((prevState) => ({
            ...prevState,
            content: [data, ...prevState.content],
          }));
        }
      }
      setIsLoadingSave(false);
    });
  };

  return (
    <Container component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h5">Monitor Acesso</Typography>
        </Grid>
        {alunoTurma ? (
          <>
            <Grid item>
              <AlunoTurmaProfileView
                alunoTurma={alunoTurma}
                handleChangeAlunoTurma={handleChangeAlunoTurma}
              />
            </Grid>
            {catracaRegistro ? (
              <Grid item>
                <CatracaRegistroForm
                  catracaRegistro={catracaRegistro}
                  setCatracaRegistro={setCatracaRegistro}
                  handleSave={handleSave}
                  isLoadingSave={isLoadingSave}
                />
              </Grid>
            ) : (
              <Grid item>
                <CatracaRegistroUsuarioList
                  listCR={listCR}
                  isLoading={isLoadingCatracaRegistro}
                  handleChangePage={handleChangePage}
                  handleNewRecord={handleNewRecord}
                  fetchData={fetchData}
                  handleDataChange={handleDataChange}
                  dateSelected={dateSelected}
                  porDataKey={porDataKey}
                  filtro={filtro}
                  handleChangeFiltro={handleChangeFiltro}
                  todosKey={todosKey}
                />
              </Grid>
            )}
          </>
        ) : (
          <>
            {turma ? (
              <>
                <Grid item>
                  <TurmaProfile
                    turma={turma}
                    handleTrocaTurma={handleTrocaTurma}
                  />
                </Grid>
                <Grid item>
                  <AlunoTurmaTableSelectView
                    alunosTurma={alunosTurma}
                    handleSelectAlunoTurma={setAlunoTurma}
                    isLoading={isLoadingAlunoTurma}
                  />
                </Grid>
              </>
            ) : (
              <Grid item>
                {isLoadingTurma ? (
                  <Loading description="Carregando Turmas..." />
                ) : (
                  <TurmaTableSelectView
                    turmas={turmas}
                    onTurmaSelected={setTurma}
                  />
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default CatracaRegistroContainer;
