import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { remove, updateOrdem } from './api-planejamento';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
        marginTop: '20px'
    },
    novoRegistro: {
        marginRight: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    container: {
        marginTop: '20px',
        paddingTop: '20px',
    }
});


export default function PlanejamentoTable({
    planejamentos,
    setPlanejamentos,
    setIsOpenForm,
    setPlanejamento,
    setInitPlanejamento,
    fetchPlanejamentos }) {
    const classes = useStyles();

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este planejamento?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        const codPlanejamento = planejamentos[index].codPlanejamento;
                        remove(codPlanejamento).then((data) => {
                            if (data) {
                                const content = planejamentos;
                                content.splice(index, 1);
                                setPlanejamentos([
                                    ...content
                                ]);
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const upOrder = (index) => {
        if (planejamentos[index].ordem === 1) {
            toast.error("Comando Inválido.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        const persist = planejamentos;
        let p1 = persist[index];
        p1.ordem = persist[index].ordem - 1
        let p2 = persist[index - 1];
        p2.ordem = persist[index - 1].ordem + 1
        updateOrdem(p1).then((data) => {
            fetchPlanejamentos();
        }).catch((err) => console.log(err));
        updateOrdem(p2).then((data) => {
            fetchPlanejamentos();
        }).catch((err) => console.log(err));
    }

    const downOrder = (index) => {
        const numeroBimestre = planejamentos[index].numeroBimestre;
        const list = planejamentos.filter(p => p.numeroBimestre === numeroBimestre);
        const lastOrdem = list[list.length - 1].ordem;
        if (planejamentos[index].ordem === lastOrdem) {
            toast.error("Comando Inválido.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        const persist = planejamentos;
        let p1 = persist[index];
        p1.ordem = persist[index].ordem + 1;
        let p2 = persist[index + 1];
        p2.ordem = persist[index + 1].ordem - 1;
        updateOrdem(p1).then((data) => {
            fetchPlanejamentos();
        }).catch((err) => console.log(err));
        updateOrdem(p2).then((data) => {
            fetchPlanejamentos();
        }).catch((err) => console.log(err));

    }

    return (
        <div>
            <Container component={Paper} className={classes.container}>
                <Button
                    onClick={() => {
                        setIsOpenForm('unique');
                        setInitPlanejamento();
                    }}
                    className={classes.novoRegistro}
                    variant="contained"
                    color="primary"
                >
                    Novo Planejamento
                </Button>
                <Button
                    onClick={() => {
                        setIsOpenForm('lote');
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.novoRegistro}
                >
                    Novo Planejamento Lote
                </Button>
                <Button
                    onClick={() => {
                        if (planejamentos.length > 0) {
                            toast.error("Excluir todos os planejamentos para poder duplicar.", {
                                position: toast.POSITION.BOTTOM_LEFT
                            });
                            return;
                        }
                        setIsOpenForm('duplicar');
                    }}
                    variant="contained"
                    color="primary"
                >
                    Duplicar Planejamento
                </Button>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Capítulo</TableCell>
                            <TableCell>Quantidade Aulas</TableCell>
                            <TableCell>Ordem</TableCell>
                            <TableCell>Bimestre</TableCell>
                            <TableCell>Ações</TableCell>
                            <TableCell>Ordem Ação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planejamentos.map((planejamento, index) => (
                            <TableRow key={planejamento.codPlanejamento}>
                                <TableCell>{planejamento.capitulo.descricao}</TableCell>
                                <TableCell>{planejamento.quantidadeAulas}</TableCell>
                                <TableCell>{planejamento.ordem}</TableCell>
                                <TableCell>{planejamento.numeroBimestre}º</TableCell>
                                <TableCell>
                                    <EditIcon
                                        fontSize='small'
                                        className={classes.cursorLink}
                                        color="secondary"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => {
                                            setIsOpenForm('unique');
                                            setPlanejamento(planejamento);
                                        }}
                                    />
                                    <DeleteIcon
                                        fontSize='small'
                                        className={classes.cursorLink}
                                        onClick={() => { handleDelete(index) }}
                                        color="secondary"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ArrowUpward
                                        fontSize='small'
                                        className={classes.cursorLink}
                                        color="secondary"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => {
                                            upOrder(index);
                                        }}
                                    />
                                    <ArrowDownwardIcon
                                        fontSize='small'
                                        className={classes.cursorLink}
                                        color="secondary"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => {
                                            downOrder(index);
                                        }}
                                    />

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>

        </div>
    );
}