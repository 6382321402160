import { TextField } from '@material-ui/core';
import React from 'react';
import ReactInputMask from 'react-input-mask';

const TextFieldCellPhone = ({ celular, handleChange }) => {
    return (
        <ReactInputMask
            mask="(99) 99999-9999"
            name="celular"
            onChange={handleChange}
            value={celular ? celular : ''}
            maskchar="_"
        >
            <TextField
                variant="outlined"
                margin="normal"
                size='small'
                fullWidth
                label="Celular"
            />
        </ReactInputMask>
    );
}

export default TextFieldCellPhone;