import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from './Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    divButtons: {
        marginTop: '20px'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});

const Form = ({
    clickSubmit,
    handleClose,
    handleRemove,
    isLoadingAction,
    children }) => {

    const classes = useStyles();

    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <div
                    className={classes.closeIcon}
                >
                    <CloseIcon
                        onClick={handleClose}
                        fontSize='small'
                    />
                </div>
                <Grid >
                    <Container  >

                        <form className={classes.paper}
                            onSubmit={clickSubmit}>
                            {children}
                            <div className={classes.divButtons}>
                                <Grid
                                    container
                                    direction='row'
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isLoadingAction}
                                            color="primary"
                                            className={classes.submit}>
                                            {isLoadingAction && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                            Salvar
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Button
                                            variant="contained"
                                            disabled={isLoadingAction}
                                            color="primary"
                                            className={classes.submit}
                                            onClick={handleRemove}
                                        >
                                            {isLoadingAction && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                            Remover
                                        </Button>
                                    </Grid>

                                </Grid>
                            </div>
                        </form>
                    </Container>
                </Grid>
            </Container >
        </div >
    );
}

export default Form;