import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-disciplina';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDisciplina as setDisciplinaRedux, updateDisciplinas, insertDisciplinas } from '../../actions';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));

export default function DisciplinaForm() {
    const classes = useStyles();
    const disciplinaRedux = useSelector(state => state.disciplina);
    const [disciplina, setDisciplina] = useState(disciplinaRedux);
    const dispatch = useDispatch();



    const handleChange = (event) => {
        setDisciplina({
            ...disciplina,
            [event.target.name]: event.target.value
        });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = disciplina.eNovo;

        save(disciplina).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(insertDisciplinas(data));
                } else {
                    dispatch(updateDisciplinas(data));

                }
                dispatch(setDisciplinaRedux(data));
            }

        }).catch(err => {
            console.log(err);
        })

    }


    return (
        <div>
            <Container className={classes.paper} component={Paper} >
                <CloseIcon
                    onClick={() => dispatch(setDisciplinaRedux(null))}
                    className={classes.closeIcon}
                    fontSize='small'
                />
                <form onSubmit={clickSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        label="Descrição"
                        name="descricao"
                        inputProps={{ maxLength: 50 }}
                        autoFocus
                        onChange={handleChange}
                        value={disciplina.descricao || ''}
                    />
                    <div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Salvar
                        </Button>
                    </div>
                </form>
            </Container>
        </div>
    );
}