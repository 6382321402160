import {
  ADD_LIVROS_INSTITUICAO,
  UPDATE_LIVROS_INSTITUICAO,
  SET_LIVROS_INSTITUICAO,
  REMOVE_LIVROS_INSTITUICAO,
  SET_LIVRO_INSTITUICAO,
  OPEN_LIVRO_INSTITUICAO,
  CLOSE_LIVRO_INSTITUICAO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchLivroInstituicao(state = false, action) {
  switch (action.type) {
    case OPEN_LIVRO_INSTITUICAO:
      return true;
    case CLOSE_LIVRO_INSTITUICAO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function livroInstituicao(state = null, action) {
  switch (action.type) {
    case SET_LIVRO_INSTITUICAO:
      return action.livroInstituicao
    case PURGE:
      return null;
    default:
      return state
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function livrosInstituicao(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_LIVROS_INSTITUICAO:
      persist.content = [
        ...persist.content,
        action.livroInstituicao
      ]
      return persist

    case UPDATE_LIVROS_INSTITUICAO:
      let content = state.content.map((livroInstituicao) => {
        if (livroInstituicao.codigo === action.livroInstituicao.codigo) {
          return action.livroInstituicao;
        } else {
          return livroInstituicao;
        }
      });
      persist.content = content;
      return persist;

    case SET_LIVROS_INSTITUICAO:
      return action.livrosInstituicao

    case REMOVE_LIVROS_INSTITUICAO:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}
