import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import RelatorioAlunoBimestreForm from './RelatorioAlunoBimestreForm';
import RltTurmaForm from './RltTurmaForm';
import RltTDPBimestreForm from './RltTDPBimestreForm';
import RltTDPForm from './RltTDPForm';
import Grid from '@material-ui/core/Grid';
import RltTurmaBimestreForm from "./RltTurmaBimestreForm";
import ProgressLog from "../../shared/components/ProgressLog/ProgressLogForm";
import { findByServico } from "../../shared/components/ProgressLog/api-progress-log";
import { useSelector, useDispatch } from 'react-redux';
import { setProgressLog } from "../../shared/components/ProgressLog/actions/actions";
import { findByAnoLetivo } from "../Turma/api-turma";
import { findByCurrentUser } from '../TDP/api-tdp';
import RltAnoLetivoForm from './RltAnoLetivoForm';
import RelatorioAlunoForm from './RelatorioAlunoForm';
import RelatorioAlunoTdpForm from './RelatorioAlunoTdpForm';
import RltAvancado from './RltAvancado';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }
});

export default function RelatorioContainer() {
    const classes = useStyles();
    const progressLog = useSelector(state => state.progressLog);
    const [turmas, setTurmas] = useState([]);
    const [tdps, setTdps] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        findByAnoLetivo().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setTurmas(data);
            }
        });
        findByCurrentUser().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setTdps(data);
            }
        })
    }, []);

    useEffect(() => {
        findByServico('relatorio').then((data) => {
            if (data) {
                dispatch(setProgressLog(data));
            }
        })
    }, [dispatch]);

    useEffect(() => {
        while (progressLog) {
            const timer = setTimeout(() => {
                findByServico('relatorio').then((data) => {
                    if (data) {
                        dispatch(setProgressLog(data));
                    }
                })
            }, 5000);
            return () => clearTimeout(timer);
        }

    }, [dispatch, progressLog]);



    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Relatório
                </Typography>
                <Grid container spacing={3}>
                    {progressLog && progressLog.servico === 'relatorio' ?
                        <Grid item xs={12}>
                            <ProgressLog />
                        </Grid> :
                        <>
                            <Grid item xs={6}>
                                <RelatorioAlunoForm turmas={turmas} />
                            </Grid>
                            <Grid item xs={6}>
                                <RelatorioAlunoBimestreForm turmas={turmas} />
                            </Grid>
                            <Grid item xs={6}>
                                <RelatorioAlunoTdpForm turmas={turmas} />
                            </Grid>
                            <Grid item xs={6}>
                                <RltTurmaForm turmas={turmas} />
                            </Grid>
                            <Grid item xs={6}>
                                <RltTurmaBimestreForm turmas={turmas} />
                            </Grid>
                            <Grid item xs={6}>
                                <RltTDPForm tdps={tdps} />
                            </Grid>
                            <Grid item xs={6}>
                                <RltTDPBimestreForm tdps={tdps} />
                            </Grid>

                            <Grid item xs={6}>
                                <RltAnoLetivoForm />
                            </Grid>
                            <Grid item xs={6}>
                                <RltAvancado />
                            </Grid>
                        </>
                    }


                </Grid>


            </Container>

        </div>
    );
}