import { SERVER_URL } from '../../../constants';
import session from '../../../SessionHandler/session-helper';

import {
    fetchGetAuth,
    fetchPostAuth,
    fetchRemoveAuthReturn
} from '../../../util/FetchUtil';

const controller = 'dia-nao-letivo-serie/';

export const findByAnoLetivoAndSerieCodSerie = (codSerie) => {
    const url = SERVER_URL + controller + session.getAnoLetivo() + "/" + codSerie;
    return fetchGetAuth(url);
}

export const findByUnregistered = (codSerie) => {
    const url = SERVER_URL + controller + "unregistered/" + session.getAnoLetivo() + "/" + codSerie;
    return fetchGetAuth(url);
}

export const save = (diaNaoLetivoSerie) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, diaNaoLetivoSerie);
}

export const deleteById = (codigo) => {
    const url = SERVER_URL + controller + codigo;
    return fetchRemoveAuthReturn(url);
}