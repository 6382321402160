import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Button from '../UI/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
});


const AlunoTable = ({ alunos, handleChangePage, setAluno }) => {
    const classes = useStyles();

    return (
        <div>
            <Button
                onClick={() => setAluno({ eNovo: true })}
                className={classes.novoRegistro}
            >
                Novo Aluno
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowHeader}>Código</TableCell>
                        <TableCell className={classes.rowHeader}>Nome</TableCell>
                        <TableCell className={classes.rowHeader}>Usuário Aluno</TableCell>
                        <TableCell className={classes.rowHeader}>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alunos.content.map((aluno) => (
                        <TableRow key={aluno.codigo}>
                            <TableCell>{aluno.codAlunoEscola}</TableCell>
                            <TableCell>{aluno.nome}</TableCell>
                            <TableCell>{aluno.usuarioAluno && aluno.usuarioAluno.email ? aluno.usuarioAluno.email : ''}</TableCell>
                            <TableCell>
                                <Button
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => setAluno(aluno)}
                                    color="secondary"
                                    style={{ marginRight: '10px' }} >
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={alunos.size ? alunos.size : 0}
                component="div"
                count={alunos.totalElements}
                page={alunos.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}

export default AlunoTable;