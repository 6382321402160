import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuthReturn
} from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';

const controller = 'aluno-turma/';

const findByTurma = (codTurma) => {
  const url = SERVER_URL + controller + 'turma/' + codTurma;
  return fetchGetAuth(url);
}

const findByAluno = (codAluno) => {
  const url = SERVER_URL + controller + 'aluno/' + codAluno;
  return fetchGetAuth(url);
}

const ordenar = (codTurma) => {
  const url = SERVER_URL + controller + 'ordenar/' + codTurma;
  return fetchGetAuth(url);
}

const remove = (codAlunoTurma) => {
  const url = SERVER_URL + controller + 'delete/' + codAlunoTurma;
  return fetchRemoveAuthReturn(url);
}

const save = (alunoTurma) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, alunoTurma);
}

const findByCurrentUserAndAnoLetivo = () => {
  const url = SERVER_URL + controller + 'current-user/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const findAlunosSemResponsavelFinanceiro = (anoLetivo) => {
  const url = SERVER_URL + controller + 'query?aluno-sem-responsavel-year=' + anoLetivo;
  return fetchGetAuth(url);
}

const findResponsavelFinanceiroSemCelular = (anoLetivo) => {
  const url = SERVER_URL + controller + 'responsavel-financeiro-sem-celular/' + anoLetivo;
  return fetchGetAuth(url);
}

const findByTurmaCodigoAndAtivo = (codTurma, ativo) => {
  const url = SERVER_URL + controller + 'query?turma-codigo=' + codTurma + '&ativo=' + ativo;
  return fetchGetAuth(url);
}

export const addList = (list, codTurma) => {
  const url = SERVER_URL + controller + 'add-list/' + codTurma;
  return fetchPostAuth(url, list);
}

export const countAlunos = (codTurma, ativo) => {
  const url = SERVER_URL + controller + 'count-alunos/' + codTurma + '/' + ativo;
  return fetchGetAuth(url);
}

export const countResponsaveis = (codTurma, ativo) => {
  const url = SERVER_URL + controller + 'count-responsaveis/' + codTurma + '/' + ativo;
  return fetchGetAuth(url);
}

export {
  findByTurma,
  save,
  remove,
  ordenar,
  findByAluno,
  findByCurrentUserAndAnoLetivo,
  findAlunosSemResponsavelFinanceiro,
  findResponsavelFinanceiroSemCelular,
  findByTurmaCodigoAndAtivo
}