import {
  ADD_ALUNOS,
  UPDATE_ALUNOS,
  SET_ALUNOS,
  REMOVE_ALUNOS,
  SET_ALUNO,
  SET_ALUNO_NULL,
  OPEN,
  CLOSE
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearch(state = false, action) {
  switch (action.type) {
    case OPEN:
      return true;
    case CLOSE:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function aluno(state = null, action) {
  switch (action.type) {
    case SET_ALUNO:
      return action.aluno

    case SET_ALUNO_NULL:
      return null
    case PURGE:
      return null;
    default:
      return state
  }
}


const initialState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function alunos(state = initialState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_ALUNOS:
      persist.content = [
        ...persist.content,
        action.aluno
      ]
      return persist

    case UPDATE_ALUNOS:
      let content = state.content.map((aluno) => {
        if (aluno.codigo === action.aluno.codigo) {
          return action.aluno;
        } else {
          return aluno;
        }
      });
      persist.content = content;
      return persist;

    case SET_ALUNOS:
      return action.alunos

    case REMOVE_ALUNOS:
      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initialState;

    default:
      return state
  }
}
