import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth,
  fetchPutAuth
} from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';

const controller = 'planejamento/';

const quantidadeBimestre = () => {
  const url = SERVER_URL + controller + 'quantidade-bimestre/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const findByTdp = (codTdp) => {
  const url = SERVER_URL + controller + 'tdp/' + codTdp;
  return fetchGetAuth(url);
}

const duplicar = (codTdp, codTdpDuplicar) => {
  const url = SERVER_URL + controller + 'duplicar/' + codTdp + "/" + codTdpDuplicar;
  return fetchGetAuth(url);
}

const remove = (codAluno) => {
  const url = SERVER_URL + controller + 'delete/' + codAluno;
  return fetchRemoveAuth(url);
}

const save = (planejamento) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, planejamento);
}

const updateOrdem = (planejamento) => {
  const url = SERVER_URL + controller + 'update-ordem';
  return fetchPutAuth(url, planejamento);
}

export {
  findByTdp,
  save,
  remove,
  updateOrdem,
  duplicar, 
  quantidadeBimestre
}