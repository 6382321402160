import { makeStyles } from '@material-ui/core';
import React from 'react';
import ButtonImageLabel from '../../UI/ButtonImageLabel';
import ParcelaPendenteIcon from '../../../shared/assets/img/financeiro/pendente.png';
import ParcelaVencerIcon from '../../../shared/assets/img/financeiro/parcela_a_vencer.png';
import HistoricoIcon from '../../../shared/assets/img/financeiro/historico.png';

const useStyles = makeStyles({
    divMenu: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px'
    },
    divButton: {
        width: '50%'
    },
    divButtonSelected: {
        backgroundColor: 'red',
        borderRadius: 200
    },

})

const FinanceiroMenu = ({
    handleParcelaPendente,
    handleParcelaVencer,
    handleHistorico,
}) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.divMenu}>
                <div className={classes.divButton}>
                    <ButtonImageLabel
                        description='Parcela(s) Pendente(s)'
                        image={ParcelaPendenteIcon}
                        onClick={handleParcelaPendente}
                    />
                </div>
                <div className={classes.divButton}>
                    <ButtonImageLabel
                        description='Parcela(s) a Vencer'
                        image={ParcelaVencerIcon}
                        onClick={handleParcelaVencer}
                    />
                </div>
                <div className={classes.divButton}>
                    <ButtonImageLabel
                        description='Histórico'
                        image={HistoricoIcon}
                        onClick={handleHistorico}
                    />
                </div>
            </div>
        </>
    )
}

export default FinanceiroMenu;