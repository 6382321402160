import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import { removeByCodigo } from './api-sala-mensagem';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        borderRadius: '20px',
        backgroundColor: '#f8e86a',
        padding: '5px',
        marginBottom: '10px',
        alignContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    deleteIcon: {
        cursor: 'pointer',
    }
});

const SMMenuOptions = ({ listSMSelect, setListSMSelect, setSalaMensagens }) => {
    const classes = useStyles();

    const handleClose = () => {
        setListSMSelect([]);
    }

    const handleDeleteMessages = () => {
        confirmAlert({
            title: 'Alerta',
            message: `Deseja Deletar ${listSMSelect.length} Mensagens Selecionadas?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        listSMSelect.map(smSelect => (
                            removeByCodigo(smSelect.codigo).then((data) => {
                                if (!data || data.error) {
                                    console.log(data);
                                } else {
                                    setSalaMensagens(prevState => ({
                                        ...prevState,
                                        content: prevState.content.filter(psc => psc.codigo !== smSelect.codigo)
                                    }))
                                }
                            })
                        ));
                        setListSMSelect([]);
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                },
            ]
        });
    }

    return (
        <>
            {listSMSelect.length > 0 &&
                <div className={classes.container}>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={handleClose}
                    />
                    <p>{listSMSelect.length}</p>
                    <DeleteIcon
                        className={classes.deleteIcon}
                        onClick={handleDeleteMessages}
                    />
                </div>
            }

        </>
    )
}

export default SMMenuOptions;