import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByAnoLetivoAndCurrentUserResumo } from '../api-nota-bimestre';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import { noteRound } from "../../../util/DoubleUtil";

const useStyles = makeStyles({
    paper: {
        marginTop: '60px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    submit: {
        marginRight: '8px',
    },
    loading: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginVertical: 20,
    },
    rowHeaderDisciplina: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontSize: 'large',
        fontWeight: '700'
    },
    rowDisciplina: {
        fontSize: 'large',
        fontWeight: '700'
    },
    rowMediaBimestral: {
        fontSize: 'large',
        fontWeight: '700'
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        textAlign: 'center',
        fontSize: 'large',
        fontWeight: '700'
    },
    rowNotas: {
        textAlign: 'center',
    }
});


export default function NBAlunoResumo() {
    const classes = useStyles();
    const [listaNBAR, setListaNBAR] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        findByAnoLetivoAndCurrentUserResumo().then((data) => {
            if (data) {
                console.log(data);
                setListaNBAR(data);
                setLoading(false);
            }
        })
    }, []);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>

                {loading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                        Carregando...
                    </div>
                    :
                    <>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell rowSpan={2} className={classes.rowHeaderDisciplina}>Disciplina</TableCell>
                                    <TableCell colSpan={4} className={classes.rowHeader}>Média Bimestral</TableCell>
                                    <TableCell rowSpan={2} className={classes.rowHeader}>Recuperação</TableCell>
                                    <TableCell rowSpan={2} className={classes.rowHeader}>Média Final</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>1º Bimestre</TableCell>
                                    <TableCell className={classes.rowHeader}>2º Bimestre</TableCell>
                                    <TableCell className={classes.rowHeader}>3º Bimestre</TableCell>
                                    <TableCell className={classes.rowHeader}>4º Bimestre</TableCell>
                                </TableRow>
                                {/* <TableRow >
                                    <TableCell className={classes.rowDisciplina}>Portugues</TableCell>
                                    <TableCell className={classes.rowNotas}>10</TableCell>
                                    <TableCell className={classes.rowNotas}>10</TableCell>
                                    <TableCell className={classes.rowNotas}>10</TableCell>
                                    <TableCell className={classes.rowNotas}>10</TableCell>
                                    <TableCell className={classes.rowNotas}>10</TableCell>
                                    <TableCell className={classes.rowNotas}>10</TableCell>
                                </TableRow> */}
                                {listaNBAR.length > 0 ? listaNBAR.map((nbat, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.rowDisciplina}>{nbat.tdp.disciplina.descricao}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaBimestral1)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaBimestral2)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaBimestral3)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaBimestral4)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.recuperacao)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaFinal)}</TableCell>
                                    </TableRow>
                                )) : ''}
                            </TableBody>
                        </Table>
                    </>
                }
            </Container>
        </div>
    );
}