import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import RelatorioAlunoForm from './RelatorioAlunoForm';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    title: {
        margin: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }
});

export default function RelatorioAlunoContainer() {
    const classes = useStyles();

    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h5">
                    Relatório
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <RelatorioAlunoForm />
                    </Grid>
                </Grid>


            </Container>

        </div>
    );
}