import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuthFile,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'comunicado-banner';

export const page = (pageNumber) => {
  const url = SERVER_URL + controller + '/page-query?page=' + pageNumber;
  return fetchGetAuth(url);
}

export const uploadImage = (formData) => {
  const url = SERVER_URL + controller + '/upload-image';
  return fetchPostAuthFile(url, formData);
}

export const remove = (codComunicado) => {
  const url = SERVER_URL + controller + "/" + codComunicado;
  return fetchRemoveAuth(url);
}

