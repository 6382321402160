import {
    REMOVE_CAPITULOS,
    SET_CAPITULOS,
    ADD_CAPITULOS,
    UPDATE_CAPITULOS,
    SET_CAPITULO
} from './actionTypes'

export function removeCapitulos(codigo) {
    return { type: REMOVE_CAPITULOS, codigo }
}

export function setCapitulos(capitulos) {
    return { type: SET_CAPITULOS, capitulos }
}

export function addCapitulos(capitulo) {
    return { type: ADD_CAPITULOS, capitulo }
}

export function updateCapitulos(capitulo) {
    return { type: UPDATE_CAPITULOS, capitulo }
}

export function setCapitulo(capitulo) {
    return { type: SET_CAPITULO, capitulo }
}