import {
    REMOVE_CONTEUDOS,
    SET_CONTEUDOS,
    ADD_CONTEUDOS,
    UPDATE_CONTEUDOS
} from './actionTypes'

export function removeConteudos(codigo) {
    return { type: REMOVE_CONTEUDOS, codigo }
}

export function setConteudos(conteudos) {
    return { type: SET_CONTEUDOS, conteudos }
}

export function addConteudos(conteudo) {
    return { type: ADD_CONTEUDOS, conteudo }
}

export function updateConteudos(conteudo) {
    return { type: UPDATE_CONTEUDOS, conteudo }
}