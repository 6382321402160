import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByDescricaoAnoLetivo, findByAnoLetivo } from './api-turma';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox } from '@material-ui/core';
import { errorMessage } from '../../util/ConfirmAlertUtil';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));

export default function TurmaSelectDialog({
    openSearchTurmas,
    setOpenSearchTurmas,
    setTurmasSelected
}) {
    const classes = useStyles();
    const [turmas, setTurmas] = useState([]);
    const [buscaDescricao, setBuscaDescricao] = useState(null);

    useEffect(() => {
        findByAnoLetivo().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setTurmas(data);
            }
        })
    }, []);

    const handleCloseSearchTurmas = () => {
        setOpenSearchTurmas(false);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (buscaDescricao === null || buscaDescricao === '') {
            findByAnoLetivo().then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTurmas(data);
                }
            })
        }
        findByDescricaoAnoLetivo(buscaDescricao).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setTurmas(data);
            }
        });
    }

    const handleBuscaChange = (event) => {
        if (event.target.value === null || event.target.value === '') {
            findByAnoLetivo().then((data) => {
                if (data.error) {
                    errorMessage(data.message)
                } else {
                    setTurmas(data);
                }
            })
        }
        setBuscaDescricao(event.target.value);
        findByDescricaoAnoLetivo(buscaDescricao).then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setTurmas(data);
            }
        });

    }

    const handleConfirm = () => {
        const turmasSelected = turmas.filter((t) => t.isSelected)
        setTurmasSelected(turmasSelected)
        handleCloseSearchTurmas()
        setTurmas(
            turmas.map(t => ({
                ...t,
                isSelected: false
            }))
        );
    }

    const handleSelectTurma = (turma) => {
        setTurmas(
            turmas.map(t => {
                if (t.codigo === turma.codigo) {
                    return {
                        ...t,
                        isSelected: !t.isSelected
                    }
                } else {
                    return t;
                }
            })
        )
    }

    return (
        <Dialog open={openSearchTurmas} onClose={handleCloseSearchTurmas} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Procurar Turmas</DialogTitle>
            <DialogContent>
                <form onSubmit={clickSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Descrição"
                        autoComplete='off'
                        name="descricao"
                        autoFocus
                        onChange={handleBuscaChange}
                        value={buscaDescricao ? buscaDescricao : ''}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Buscar
                    </Button>
                </form>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Selecionar</TableCell>
                            <TableCell>Curso</TableCell>
                            <TableCell>Descricao</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {turmas ? turmas.map((turma) => (
                            <TableRow key={turma.codigo}>
                                <TableCell>
                                    <Checkbox
                                        checked={turma.isSelected || false}
                                        onClick={() => handleSelectTurma(turma)}
                                    />

                                </TableCell>
                                <TableCell>{turma.curso.abreviacao}</TableCell>
                                <TableCell>{turma.descricao}</TableCell>
                            </TableRow>
                        )) : ''}
                    </TableBody>
                </Table>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary">
                    Confirmar
                </Button>
                <Button onClick={handleCloseSearchTurmas} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}