import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { noteRound } from "../../../util/DoubleUtil";


export default function RelatorioAtaFinalNotaBimestreWeb({ ataFinal }) {

    return (
        <>
            <Typography
                style={{
                    textAlign: 'center',
                    textDecoration: 'underline'
                }}
                variant="h6">
                Base Comum
            </Typography>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2}>Disciplinas</TableCell>
                        <TableCell colSpan={4}>Média Bimestral</TableCell>
                        <TableCell rowSpan={2}>5º Conceito</TableCell>
                        <TableCell rowSpan={2}>Recuperação Intensiva</TableCell>
                        <TableCell rowSpan={2}>Total Aulas</TableCell>
                        <TableCell rowSpan={2}>Total Faltas</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>1º</TableCell>
                        <TableCell>2º</TableCell>
                        <TableCell>3º</TableCell>
                        <TableCell>4º</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {ataFinal.map((af, index) => (
                        <TableRow key={index}>
                            <TableCell>{af.tdp.disciplina.descricao}</TableCell>
                            <TableCell>{af.mediaBimestral1 && af.mediaBimestral1.nota ? noteRound(af.mediaBimestral1.nota) : '--'}</TableCell>
                            <TableCell>{af.mediaBimestral2 && af.mediaBimestral2.nota ? noteRound(af.mediaBimestral2.nota) : '--'}</TableCell>
                            <TableCell>{af.mediaBimestral3 && af.mediaBimestral3.nota ? noteRound(af.mediaBimestral3.nota) : '--'}</TableCell>
                            <TableCell>{af.mediaBimestral4 && af.mediaBimestral4.nota ? noteRound(af.mediaBimestral4.nota) : '--'}</TableCell>
                            <TableCell>{af.quintoConceito && af.quintoConceito.nota ? noteRound(af.quintoConceito.nota) : '--'}</TableCell>
                            <TableCell>{af.recuperacaoIntensiva && af.recuperacaoIntensiva.nota ? noteRound(af.recuperacaoIntensiva.nota) : '--'}</TableCell>
                            <TableCell>{af.totalAulas ? af.totalAulas : '--'}</TableCell>
                            <TableCell>{af.totalFaltas ? af.totalFaltas : '--'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}