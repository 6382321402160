import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth,
} from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';
const controller = 'disciplina/';

const remove = (codDisciplina) => {
  const url = SERVER_URL + controller + 'delete/' + codDisciplina;
  return fetchRemoveAuth(url);
}

const save = (disciplina) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, disciplina);
}

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

export const findByAnoLetivo = () => {
  const url = SERVER_URL + controller + 'ano-letivo/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

export {
  findAll,
  save,
  remove
}