import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LivroIntituicaoTable from './LivroInstituicaoTable';
import { useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { setLivrosAutor } from '../Autor/actions/actions';
import { initState as initStateLivrosAutor } from '../Autor/actions/reducers';
import LivroInstituicaoFindDialog from './LivroIntituicaoFindDialog';
import { page } from './api-livro-instituicao';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const LivroInstituicaoContainer = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const [livrosInstituicao, setLivrosInstituicao] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    });
    const [openLivroInstituicaoSearch, setOpenlivroInstituicaoSearch] = useState(null);


    useEffect(() => {
        page(0).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setLivrosInstituicao(data);
            }
        })
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurarLivro = () => {
        setOpenlivroInstituicaoSearch(true);
        handleMenuClose();
    }

    const handleEditBook = () => {
        dispatch(setLivrosAutor(initStateLivrosAutor))
    }



    const handleChangePage = (event, newPage) => {
        page(newPage).then((data) => {
            if (data.error) {
                errorMessage(data);
            } else {
                setLivrosInstituicao(data)
            }
        });
    };

    return (
        <Container className={classes.paper} component={Paper}>
            <LivroInstituicaoFindDialog
                openDialog={openLivroInstituicaoSearch}
                setOpenDialog={setOpenlivroInstituicaoSearch}
                setLivrosInstituicao={setLivrosInstituicao}
            />
            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Livros Instituição
                    </Typography>
                </Grid>
                <Grid style={{ flexBasis: '5%' }} item xs={3}>
                    <MenuIcon
                        fontSize='small'
                        className={classes.cursorLink}
                        onClick={(event) => handleMenuClick(event)}
                        color="secondary"
                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleProcurarLivro()}>
                            <SearchIcon
                                fontSize='small'
                                color="secondary"
                                style={{ marginRight: '10px' }} />
                            Procurar Livro
                        </MenuItem>
                        <MenuItem
                            onClick={handleEditBook}
                            component={Link}
                            to={{
                                pathname: '/livro-autor',
                            }}
                        >
                            <EditIcon
                                fontSize='small'
                                color="secondary"
                                style={{ marginRight: '10px' }} />
                            Editar Livro
                        </MenuItem>
                    </Menu>
                </Grid>

            </Grid>

            <Grid >
                <LivroIntituicaoTable
                    livrosInstituicao={livrosInstituicao}
                    handleChangePage={handleChangePage}
                />
            </Grid>

        </Container>
    );
}

export default LivroInstituicaoContainer;