import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { Link } from 'react-router-dom';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


const LivroIntituicaoTable = ({ livrosInstituicao, handleChangePage }) => {
    const classes = useStyles();

    return (
        <div>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {livrosInstituicao.content.map((li) => (
                        <TableRow key={li.codigo}>
                            <TableCell>{li.livro.descricao}</TableCell>
                            <TableCell>
                                <Button
                                    component={Link}
                                    to={{
                                        pathname: '/conteudos/' + li.livro.codLivro,
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Abrir Livro
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={livrosInstituicao.size ? livrosInstituicao.size : 0}
                component="div"
                count={livrosInstituicao.totalElements}
                page={livrosInstituicao.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}

export default LivroIntituicaoTable;