import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { findByAnoLetivoESerieCodSerie } from './api-bimestre';
import { dateFormat } from '../../util/DateUtil';
import Loading from '../UI/Loading';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        marginBottom: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    divFiltro: {
        marginTop: '20px',
        marginBottom: '30px',
    }
});

export default function BimestreTableSelect({ setBimestre, serie }) {
    const classes = useStyles();
    const [bimestres, setBimestres] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        findByAnoLetivoESerieCodSerie(serie.codSerie).then((data) => {
            console.log(data);
            if (data.error) {
                errorMessage(data.message);
            } else {
                setBimestres(data);
            }
            setIsLoading(false);
        });

    }, [serie.codSerie]);



    return (
        <div>
            <Typography className={classes.title} variant="body1">
                Selecione um Bimestre
            </Typography>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ano Letivo</TableCell>
                        <TableCell>Série</TableCell>
                        <TableCell>Nº Bimestre</TableCell>
                        <TableCell>Data Início</TableCell>
                        <TableCell>Data Fim</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                {isLoading ?
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Loading description="Carregando Bimestres..." />
                        </Grid>
                    </Grid> :
                    <TableBody>
                        {bimestres.length > 0 && bimestres.map((bimestre) => (
                            <TableRow key={bimestre.codigo}>
                                <TableCell>{bimestre.anoLetivo}</TableCell>
                                <TableCell>{bimestre.serie.descricao}</TableCell>
                                <TableCell>{bimestre.numeroBimestre}</TableCell>
                                <TableCell>{dateFormat(bimestre.dataInicio)}</TableCell>
                                <TableCell>{dateFormat(bimestre.dataFim)}</TableCell>
                                <TableCell>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setBimestre(bimestre)}
                                        className={classes.submit}>
                                        Selecionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }

            </Table>

        </div >
    );
}