import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { findPCP } from './api-presenca-capitulo';
import RefreshIcon from '@material-ui/icons/Refresh';
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles({
    card: {
        marginTop: '20px',
        margin: 'auto',
        width: '100%',
        flexGrow: 1
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
    refresh: {
        cursor: 'pointer',
        marginLeft: '20px'
    }
});

export default function PresencaCapituloPlanejamento({ tdp }) {
    const classes = useStyles();
    const [pcp, setPcp] = useState({
        tdp: '',
        capitulo1: 0,
        capitulo2: 0,
        capitulo3: 0,
        capitulo4: 0,
        planejamento1: 0,
        planejamento2: 0,
        planejamento3: 0,
        planejamento4: 0,
    });

    useEffect(() => {
        findPCP(tdp.codigo).then((data) => {
            if (data.error) {
                if (data.message === 'Erro: Bimestre nao cadastrado para a serie') {
                    handleFindPCPError();
                }
            } else {
                setPcp(data);
            }
        });
    }, [tdp]);

    const handleFindPCPError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'Bimestre não cadastrado para esta série. Tabela Presença Capítulo Planejamento ficará sem data.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const fetchPCP = () => {
        findPCP(tdp.codigo).then((data) => {
            if (data) {
                console.log(data);
                setPcp(data);
            }
        });
    }

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Typography variant='body1' >
                    Planejamento Capítulo
                    <RefreshIcon
                        className={classes.refresh}
                        fontSize="small"
                        onClick={fetchPCP}
                    />
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            1º Bimestre
                        </Typography>
                        <Typography variant="body1">
                            {pcp.capitulo1} / {pcp.planejamento1}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            2º Bimestre
                        </Typography>
                        <Typography variant="body1">
                            {pcp.capitulo2} / {pcp.planejamento2}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            3º Bimestre
                        </Typography>
                        <Typography variant="body1">
                            {pcp.capitulo3} / {pcp.planejamento3}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            4º Bimestre
                        </Typography>
                        <Typography variant="body1">
                            {pcp.capitulo4} / {pcp.planejamento4}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}