import React, { useState, useEffect } from "react";
import { Button, Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { findByLivroCodLivro } from './api-livro-autor';
import UsuarioFindDialogRedux from "../../Usuario/UsuarioFindDialogRedux";
import { openUsuario } from '../../Usuario/actions/actions';
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../util/ConfirmAlertUtil";


const LivroAutorAdd = ({ livro }) => {
    const [livroAutores, setLivroAutores] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (livro.codLivro) {
            findByLivroCodLivro(livro.codLivro).then((data) => {
                if (data.error) {
                    errorMessage(data.message)
                } else {
                    setLivroAutores(data);
                }
            })
        }

    }, [livro]);

    const handleSearchUser = () => {
        dispatch(openUsuario());
    };

    return (
        <Container component={Paper}>
            <UsuarioFindDialogRedux />
            <Typography variant="body1">
                Selecione os Autores do livro.
            </Typography>
            <Button
                onClick={handleSearchUser}
                variant="contained"
                color="primary">
                Adicionar
            </Button>

            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Autor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {livroAutores.map((la) => (
                        <TableRow key={la.codigo}>
                            <TableCell>{la.usuario.nome}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    )
}

export default LivroAutorAdd;