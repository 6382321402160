import { SERVER_URL } from '../../constants';
import session from '../../SessionHandler/session-helper';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'turma/';

const findByAnoLetivo = () => {
  const url = SERVER_URL + controller + 'ano-letivo/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const findByDescricaoAnoLetivo = (descricao) => {
  const url = SERVER_URL + controller + 'descricao/' + descricao + '/ano-letivo/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const remove = (codTurma) => {
  const url = SERVER_URL + controller + 'delete/' + codTurma;
  return fetchRemoveAuth(url);
}

const save = (turma) => {
  turma.anoLetivo = session.getAnoLetivo();
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, turma);
}

const findYears = () => {
  const url = SERVER_URL + controller + 'years';
  return fetchGetAuth(url);
}

export {
  findByAnoLetivo,
  findByDescricaoAnoLetivo,
  save,
  remove,
  findYears
}