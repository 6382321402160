import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { rltBoletimUser, rltRendimentoUser } from '../api-relatorio';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { findByCurrentUserAndAnoLetivo } from '../../AlunoTurma/api-aluno-turma';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '30px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),

    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RelatorioAlunoForm() {
    const classes = useStyles();
    const [relatorioAluno, setRelatorioAluno] = useState({
        numeroBimestre: 1
    });
    const [loading, setLoading] = useState(false);
    const [alunoTurmas, setAlunoTurmas] = useState([]);

    useEffect(() => {
        findByCurrentUserAndAnoLetivo().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAlunoTurmas(data);
                if (data.length > 0) {
                    setRelatorioAluno((prevState) => ({
                        ...prevState,
                        codAlunoTurma: data[0].codigo
                    }));
                }
            }
        })
    }, []);

    const handleBoletimAluno = () => {
        setLoading(true);
        rltBoletimUser(relatorioAluno).then(data => {
            if (data) {
                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setLoading(false);
            }
        })
    }

    const handleRendimentoEscolar = () => {
        setLoading(true);
        rltRendimentoUser(relatorioAluno).then(data => {
            if (data) {
                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setLoading(false);
            }
        })
    }

    const handleBimestreChange = (event) => {
        setRelatorioAluno({
            ...relatorioAluno,
            numeroBimestre: event.target.value
        });
    }

    const handleAlunoTurmaChange = (event) => {
        setRelatorioAluno({
            ...relatorioAluno,
            codAlunoTurma: event.target.value
        });
    }


    return (
        <div>
            <Container className={classes.paper} component={Paper} >
                <form>
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="bimestre-label">Turma</InputLabel>
                        <Select
                            labelId="bimestre-label"
                            id="bimestre-select"
                            value={relatorioAluno && relatorioAluno.codAlunoTurma ? relatorioAluno.codAlunoTurma : ''}
                            onChange={handleAlunoTurmaChange}
                        >
                            {alunoTurmas.map(at => (
                                <MenuItem key={at.codigo} value={at.codigo}>{at.turma.descricao}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="bimestre-label">Bimestre</InputLabel>
                        <Select
                            labelId="bimestre-label"
                            id="bimestre-select"
                            value={relatorioAluno && relatorioAluno.numeroBimestre ? relatorioAluno.numeroBimestre : ''}
                            onChange={handleBimestreChange}
                        >
                            <MenuItem value={1}>1º Bimestre</MenuItem>
                            <MenuItem value={2}>2º Bimestre</MenuItem>
                            <MenuItem value={3}>3º Bimestre</MenuItem>
                            <MenuItem value={4}>4º Bimestre</MenuItem>
                        </Select>
                    </FormControl>
                    {loading ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/relatorio-boletim-web',
                                    state: {
                                        numeroBimestre: relatorioAluno.numeroBimestre,
                                        codAlunosTurmas: relatorioAluno.codAlunoTurma ? [relatorioAluno.codAlunoTurma] : []
                                    }
                                }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Boletim Web
                            </Button>
                            <Button
                                onClick={handleBoletimAluno}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Boletim em PDF (Habilitar Pop Up)
                            </Button>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/relatorio-rendimento-escolar-web',
                                    state: {
                                        numeroBimestre: relatorioAluno.numeroBimestre
                                    }
                                }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Rendimento Escolar Web
                            </Button>
                            <Button
                                onClick={handleRendimentoEscolar}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Rendimento Escolar em PDF (Habilitar Pop Up)
                            </Button>
                        </>
                    }

                </form>
            </Container>
        </div>
    );
}