import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { remove, findByCodTurmaAndCodCronogramaData } from './api-cronograma';
import { timeFormat, dayOfWeekName } from '../../util/DateUtil';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setCronogramas, setCronograma, removeCronogramas } from "./actions/actions";
import { setCronogramaAula } from './Aula/actions/actions';
import Loading from '../UI/Loading';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { Grid } from '@material-ui/core';


const useStyles = makeStyles({

    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function CronogramaTable({ cronogramaData, handleOpenCronogramaTableSelect }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const cronogramas = useSelector(state => state.cronogramas);
    const turma = useSelector(state => state.turma);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (turma) {
            setIsLoading(true);
            findByCodTurmaAndCodCronogramaData(turma.codigo, cronogramaData.codigo).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setCronogramas(data));
                }
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [turma, cronogramaData, dispatch]);





    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este Cronograma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(cronogramas[index].codigo).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    dispatch(removeCronogramas(cronogramas[index].codigo));
                                }
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleAdicionarCronograma = () => {
        dispatch(setCronograma({
            tdp: {
                turma: turma,
            },

            eNovo: true
        }));
        dispatch(setCronogramaAula(null));
    }


    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <Grid container direction='row' spacing={3}>
                    <Grid item>
                        <Button
                            onClick={handleAdicionarCronograma}
                            variant="contained"
                            color="primary">
                            Adicionar Cronograma
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleOpenCronogramaTableSelect}
                            variant="contained"
                            color="primary">
                            Copiar Último Cronograma Data por Cronograma Aula
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                {isLoading ?
                    <Loading description="Carregando Cronograma..." />
                    :
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Dia Semana</TableCell>
                                <TableCell>Ordem Aula</TableCell>
                                <TableCell>Horário</TableCell>
                                <TableCell>Disciplina</TableCell>
                                <TableCell>Professor</TableCell>
                                <TableCell>Local</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cronogramas.map((c, index) => (
                                <TableRow key={c.codigo}>
                                    <TableCell>{dayOfWeekName(c.diaSemana)}</TableCell>
                                    <TableCell>{c.cronogramaAula.ordemAula}</TableCell>
                                    <TableCell>{timeFormat(c.cronogramaAula.horarioInicio)} - {timeFormat(c.cronogramaAula.horarioFim)}</TableCell>
                                    <TableCell>{c.tdp.disciplina.descricao}</TableCell>
                                    <TableCell>{c.tdp.usuario ? c.tdp.usuario.nome : '--'}</TableCell>
                                    <TableCell>{c.local ? c.local.descricao : '--'}</TableCell>
                                    <TableCell>
                                        <EditIcon
                                            className={classes.cursorLink}
                                            fontSize='small'
                                            onClick={() => {
                                                dispatch(setCronograma(c));
                                                dispatch(setCronogramaAula(null));
                                            }}
                                            color="secondary"
                                            style={{ marginRight: '10px' }} />
                                        <DeleteIcon
                                            className={classes.cursorLink}
                                            fontSize='small'
                                            onClick={() => { handleDelete(index) }} color="secondary" />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                }
            </Grid>
        </Grid >
    );
}