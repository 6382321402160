import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByDescricao } from './api-instituicao';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { setInstituicao, closeInstituicao } from './actions/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));


export default function InstituicaoFindDialogRedux() {
    const classes = useStyles();
    const [instituicoes, setInstituicoes] = useState([]);
    const [opcoesBusca, setOpcoesBusca] = useState({
        por: 'descricao',
        busca: ''
    });
    const dispatch = useDispatch();
    const openSearchInstituicao = useSelector(state => state.openSearchInstituicao);

    const clickSubmit = (event) => {
        event.preventDefault();
        if (opcoesBusca.por === 'descricao') {
            findByDescricao(opcoesBusca.busca).then((data) => {
                setInstituicoes(data);
            }).catch((err) => console.log(err));
        }
    }

    const handleBuscaChange = (event) => {
        setOpcoesBusca({
            ...opcoesBusca,
            [event.target.name]: event.target.value
        });

    }

    const handleSelectedInstituicao = (instituicao) => {
        dispatch(setInstituicao(instituicao));
        dispatch(closeInstituicao());
    }

    const handleClose = () => {
        dispatch(closeInstituicao());
    };

    return (
        <div>
            <Dialog open={openSearchInstituicao} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Procurar Instituição</DialogTitle>
                <DialogContent>
                    <form onSubmit={clickSubmit}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Buscar por:</FormLabel>
                            <RadioGroup aria-label="gender" name="por" value={opcoesBusca.por} onChange={handleBuscaChange}>
                                <FormControlLabel value="descricao" control={<Radio />} label="Descrição" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Busca"
                            name="busca"
                            autoFocus
                            onChange={handleBuscaChange}
                            value={opcoesBusca.busca || ''}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Buscar
                        </Button>
                    </form>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {instituicoes.map((instituicao) => (
                                <TableRow key={instituicao.codigo}>
                                    <TableCell>{instituicao.descricao}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => handleSelectedInstituicao(instituicao)}
                                            variant="contained"
                                            color="secondary">
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}