import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changePassword } from '../auth/api-auth';
import { findByHostname } from "../components/Instituicao/api-instituicao";
import {
    useParams
} from "react-router-dom";
import { useHistory } from 'react-router';


const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    logo: {
        width: '100%',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    messageSuccess: {
        border: '1px solid darkgreen',
        padding: '30px',
        borderRadius: '30px',
        backgroundColor: 'palegreen',
    },
    messageError: {
        border: '1px solid Red',
        padding: '30px',
        borderRadius: '30px',
        backgroundColor: 'Tomato',
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function ResetPassword() {
    const classes = useStyles();
    const [message, setMessage] = useState({
        content: '',
        type: ''
    })
    const [isLoading, setIsLoading] = useState(false);
    const [usuarioPassword, setUsuarioPassword] = useState();
    const [instituicao, setInstituicao] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const { codResetPassword } = useParams();
    const history = useHistory();

    useEffect(() => {
        findByHostname(window.location.href).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setInstituicao(data);
            }
        });

    }, [codResetPassword]);

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (usuarioPassword.password1 !== usuarioPassword.password2) {
            setMessage({
                content: 'Digite a mesma senha para os dois campos de senha.',
                type: 'ERROR'
            });
            setIsLoading(false);
            return;
        }

        changePassword(usuarioPassword).then(data => {
            if (data.error) {
                if (data.message === 'Already used') {
                    setMessage({
                        content: "Link já utilizado.",
                        type: 'ERROR'
                    });
                } else if (data.message === 'Limit Time expired') {
                    setMessage({
                        content: "Tempo Limite para resetar a senha expirado. Refaça o processo para resetar a senha.",
                        type: 'ERROR'
                    });
                }
                else {
                    console.log(data.error);
                }

            } else {
                setMessage({
                    content: "Senha alterada com sucesso.",
                    type: 'SUCCESS'
                });
                setPasswordChanged(true);
            }
            setIsLoading(false);
        });

    }

    const handleChange = (event) => {
        setUsuarioPassword({
            ...usuarioPassword,
            [event.target.name]: event.target.value,
            codigoResetPassword: codResetPassword
        });
    }



    return (
        <div>
            <CssBaseline />
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    {instituicao ? <a href={instituicao.hostname}> <img src={instituicao.urlLogo}
                        className={classes.logo} alt="Logo" /></a> : ''}

                    <Typography variant="h5">
                        Resetar Senha
                    </Typography>

                    <form onSubmit={clickSubmit}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            disabled={passwordChanged}
                            fullWidth
                            name="password1"
                            label="Senha"
                            type="password"
                            id="password1"
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={usuarioPassword && usuarioPassword.password1 ? usuarioPassword.password1 : ''}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            disabled={passwordChanged}
                            name="password2"
                            label="Confirma Senha"
                            type="password"
                            id="password2"
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={usuarioPassword && usuarioPassword.password2 ? usuarioPassword.password2 : ''}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading || passwordChanged}
                            className={classes.submit}>
                            {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                            Trocar Senha
                        </Button>
                        {message.type === 'SUCCESS' ?
                            <>
                                <Typography className={classes.messageSuccess} >
                                    {message.content}
                                </Typography>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => history.push('/')}
                                >
                                    Voltar para Login
                                </Button>
                            </> :
                            ''
                        }
                        {message.type === 'ERROR' ?
                            <Typography className={classes.messageError} >
                                {message.content}
                            </Typography> :
                            ''
                        }

                    </form>

                </div>
            </Container>
        </div>
    );
}