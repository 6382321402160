import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { save } from './api-nota-bimestre';
import { setNotaBimestre, updateNotasBimestre } from './actions/actions';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function NotaBimestreForm() {
    const classes = useStyles();
    const notaBimestre = useSelector(state => state.notaBimestre);
    const notasBimestre = useSelector(state => state.notasBimestre);
    const dispatch = useDispatch();

    const nextNB = () => {
        const index = notasBimestre.map(nb => nb.codigo).indexOf(notaBimestre.codigo);
        const size = notasBimestre.length;
        if (index + 1 === size) {
            dispatch(setNotaBimestre(notasBimestre[0]));
        } else {
            dispatch(setNotaBimestre(notasBimestre[index + 1]));
        }
    }

    const handleChange = (event) => {
        dispatch(setNotaBimestre({
            ...notaBimestre,
            [event.target.name]: event.target.value.replace(',', '.')
        }))
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        save(notaBimestre).then((data) => {
            if (data) {
                dispatch(updateNotasBimestre(data));
            }
        });
        nextNB();
    }


    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper} >

                <Typography className={classes.title} variant="body1">
                    Nome: {notaBimestre ? notaBimestre.aluno.nome : ''}
                </Typography>
                <Typography className={classes.title} variant="body1">
                    Número Chamada: {notaBimestre ? notaBimestre.alunoTurma.numeroChamada : ''}
                </Typography>

                <form onSubmit={clickSubmit}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size='small'
                        label="Nota"
                        name="nota"
                        autoFocus
                        onChange={handleChange}
                        value={notaBimestre && notaBimestre.nota ? notaBimestre.nota : ''}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Salvar
                    </Button>
                </form>



            </Container>
        </div>
    );
}