import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Container from '../../UI/Container';
import SmsPendenciaTable from './SmsPendenciaTable';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
});

const SmsPendenciaContainer = () => {
    const classes = useStyles();
    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                SMS Pendencia
            </Typography>

            <SmsPendenciaTable />

        </Container>
    );
}

export default SmsPendenciaContainer;