import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { findUnregisteredCapitulos, save, remove, findByPresenca } from './api-presenca-capitulo';
import { ToastContainer, toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '10px'

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cursorLink: {
        cursor: 'pointer'
    },
    messageCapitulo: {
        background: '#a5d6a7',
        padding: '20px'
    }
}));

export default function PresencaCapituloForm({ presenca }) {
    const classes = useStyles();
    const [capitulos, setCapitulos] = useState([]);
    const [presencaCapitulo, setPresencaCapitulo] = useState({ presenca: presenca });
    const [presencaCapitulos, setPresencaCapitulos] = useState([]);


    useEffect(() => {
        findUnregisteredCapitulos(presenca.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setCapitulos(data);
            }
        })
        findByPresenca(presenca.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setPresencaCapitulos(data);
            }
        })
    }, [presenca]);

    const handleCapituloChange = (event) => {
        const capitulo = capitulos.find((capitulo) => {
            return capitulo.codCapitulo === event.target.value
        });
        setPresencaCapitulo({
            ...presencaCapitulo,
            capitulo: capitulo
        });
    }

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este capítulo?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(presencaCapitulos[index].codigo).then((data) => {
                            if (data) {
                                setCapitulos([
                                    ...capitulos,
                                    presencaCapitulos[index].capitulo
                                ])
                                setPresencaCapitulos(presencaCapitulos.filter(pc => pc.codigo !== presencaCapitulos[index].codigo))
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        save(presencaCapitulo).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setPresencaCapitulos([
                    ...presencaCapitulos,
                    data
                ]);
                setCapitulos(capitulos.filter(c => c.codCapitulo !== data.capitulo.codCapitulo));
                setPresencaCapitulo({
                    presenca: presenca
                });

            }

        }).catch(err => {
            console.log(err);
        })

    }


    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container className={classes.paper} component={Paper} maxWidth="sm">
                {capitulos.length > 0 ?
                    <form onSubmit={clickSubmit}>
                        <FormControl fullWidth className={classes.formControl}>
                            <InputLabel id="capitulo-label">Capítulo</InputLabel>
                            <Select
                                labelId="capitulo-label"
                                id="capitulo-select"
                                value={presencaCapitulo && presencaCapitulo.capitulo ?
                                    presencaCapitulo.capitulo.codCapitulo : ''}
                                onChange={handleCapituloChange}
                            >
                                {capitulos.map((capitulo) => (
                                    <MenuItem key={capitulo.codCapitulo} value={capitulo.codCapitulo}>
                                        {capitulo.descricao}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Adicionar
                        </Button>
                    </form>
                    :
                    <Typography className={classes.messageCapitulo} variant="h6">
                        Todos os capítulos já foram cadastrados.
                    </Typography>
                }

                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descricao</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {presencaCapitulos.map((pc, index) => (
                            <TableRow key={pc.codigo}>
                                <TableCell>{pc.capitulo.descricao}</TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        className={classes.cursorLink}
                                        onClick={() => { handleDelete(index) }}
                                        style={{ marginRight: '10px' }}
                                        color="secondary"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>
        </div>
    );
}