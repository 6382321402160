import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'avaliacao-aluno/';

const createSimulado = (codAvaliacao) => {
  const url = SERVER_URL + controller + 'create/' + codAvaliacao;
  return fetchGetAuth(url);
}

const createAvaliacao = (avaliacaoAluno) => {
  const url = SERVER_URL + controller + 'create';
  return fetchPostAuth(url, avaliacaoAluno);
}

const finalizar = (codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'finalizar/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}

const findProvaFinalizada = (pageNumber) => {
  const url = SERVER_URL + controller + 'finalizadas?page=' + pageNumber;
  return fetchGetAuth(url);
}
const findByAvaliacaoCodigo = (codAvaliacao, pageNumber) => {
  const url = SERVER_URL + controller + codAvaliacao + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const save = (avaliacaoAluno) => {
  const url = SERVER_URL + controller + 'save'
  return fetchPostAuth(url, avaliacaoAluno);
}

const findProvaFinalizadaPorAvaliacao = (codAvaliacao) => {
  const url = SERVER_URL + controller + 'finalizadas/' + codAvaliacao;
  return fetchGetAuth(url);
}

const findProvaFinalizadaPorAvaliacaoAluno = (codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'finalizadas-aluno/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}

const findAvaliacaoByUser = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const gerarNota = (codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'gerar/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}


export {
  createSimulado,
  createAvaliacao,
  finalizar,
  findProvaFinalizada,
  findProvaFinalizadaPorAvaliacao,
  findByAvaliacaoCodigo,
  findProvaFinalizadaPorAvaliacaoAluno,
  findAvaliacaoByUser,
  save,
  gerarNota
}