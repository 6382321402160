import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'sala-participante/';

const page = (page) => {
  const url = SERVER_URL + controller + "?page=" + page;
  return fetchGetAuth(url);
}

const findBySala = (codSala) => {
  const url = SERVER_URL + controller + "codigo-sala/" + codSala;
  return fetchGetAuth(url);
}

const addUser = (salaParticipante) => {
  const url = SERVER_URL + controller + "add-user";
  return fetchPostAuth(url, salaParticipante);
}

const getQuantNaoLidas = () => {
  const url = SERVER_URL + controller + "quant-nao-lidas";
  return fetchGetAuth(url);
}
const findSalasGroup = (descricao, page, isAll) => {
  const url = SERVER_URL + controller +
    "query-sala?is-group=true&descricao=" + descricao + 
    "&page=" + page + "&is-all=" + isAll;
  return fetchGetAuth(url);
}

const remove = (codigoSP) => {
  const url = SERVER_URL + controller + codigoSP;
  return fetchRemoveAuth(url);
}

export {
  page,
  findBySala,
  addUser,
  remove,
  getQuantNaoLidas,
  findSalasGroup
}