import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Container from '../../UI/Container';
import SmsReminderTable from './SmsReminderTable';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
});

const SmsReminderContainer = () => {
    const classes = useStyles();
    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                SMS Reminder Lançamento
            </Typography>

            <SmsReminderTable />

        </Container>
    );
}

export default SmsReminderContainer;