import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findProvaFinalizada } from './api-avaliacao-aluno'
import Button from '@material-ui/core/Button';
import { ALUNO, RESPONSAVEL } from '../../Usuario/Perfil';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setAvaliacaoAluno } from './actions/actions';
import { setAvaliacao } from '../actions/actions';


const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AvaliacaoAlunoTableGroup() {
    const classes = useStyles();
    const [avaliacoesAluno, setAvaliacoesAluno] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const usuario = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (usuario.perfil === ALUNO || usuario.perfil === RESPONSAVEL) {
            findProvaFinalizada(0).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    console.log(data);
                    setAvaliacoesAluno(data);
                }
            })
        }
    }, [usuario]);

    const handleChangePage = (event, newPage) => {
        if (usuario.perfil === ALUNO || usuario.perfil === RESPONSAVEL) {
            findProvaFinalizada(newPage).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setAvaliacoesAluno(data);
                }
            })
        }
    };

    const handleGoTo = (avaliacaoAluno) => {
        dispatch(setAvaliacaoAluno(avaliacaoAluno));
        dispatch(setAvaliacao(avaliacaoAluno.avaliacao));
        if (avaliacaoAluno.avaliacao.esimulado) {
            history.push({
                pathname: '/avaliacao-resultado',
            })
        } else {
            history.push({
                pathname: '/avaliacao-secao',
                state: {
                    mode: 'correction'
                }
            })
        }
    }

    return (
        <div>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Título</TableCell>
                        <TableCell>Conteúdo</TableCell>
                        <TableCell>Quant. Perguntas</TableCell>
                        <TableCell>Quant. Acertos</TableCell>
                        <TableCell>Nota</TableCell>
                        <TableCell>Detalhes</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {avaliacoesAluno.content.map((aa) => (
                        <TableRow key={aa.codigo}>
                            <TableCell>{aa.avaliacao.titulo}</TableCell>
                            <TableCell>{aa.avaliacao.conteudo}</TableCell>
                            <TableCell>{aa.avaliacao.quantPerguntas}</TableCell>
                            <TableCell>{aa.quantAcertos}</TableCell>
                            <TableCell>{aa.nota}</TableCell>
                            <TableCell>
                                <Button
                                    size='small'
                                    onClick={() => handleGoTo(aa)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={avaliacoesAluno.size ? avaliacoesAluno.size : 0}
                component="div"
                count={avaliacoesAluno.totalElements}
                page={avaliacoesAluno.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}