import {
    ADD_AVALIACOES, 
    UPDATE_AVALIACOES, 
    SET_AVALIACOES, 
    REMOVE_AVALIACOES,
    SET_AVALIACAO
} from './actionTypes'

export function addAvaliacoes (avaliacao) {
    return { type: ADD_AVALIACOES, avaliacao }
}


export function updateAvaliacoes(avaliacao){
    return {type : UPDATE_AVALIACOES, avaliacao}
}

export function setAvaliacoes(avaliacoes){
    return {type : SET_AVALIACOES, avaliacoes}
}

export function removeAvaliacoes(index){
    return {type : REMOVE_AVALIACOES, index}
}

export function setAvaliacao(avaliacao){
    return {type : SET_AVALIACAO, avaliacao}
}
