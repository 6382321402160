import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { page, pageUser, remove } from './api-avaliacao';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setAvaliacoes, removeAvaliacoes, setAvaliacao } from './actions/actions';
import { dateTimeFormat } from '../../util/DateUtil';
import { ADMIN, COORDENADOR, PROFESSOR } from '../Usuario/Perfil';
import { useHistory } from 'react-router-dom'
import { setUsuario } from '../Usuario/actions/actions';
import { setAluno } from '../Aluno/actions/actions';
import { setTurma } from '../Turma/actions/actions';
import { setAvaliacaoAluno } from './Aluno/actions/actions';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AvaliacaoTable() {
    const classes = useStyles();
    const avaliacoes = useSelector(state => state.avaliacoes);
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = useSelector(state => state.usuario);

    useEffect(() => {
        if (usuario && (usuario.perfil === ADMIN || usuario.perfil === COORDENADOR)) {
            page(0).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setAvaliacoes(data));
                    dispatch(setAvaliacao(null));
                }
            })
        } else if (usuario && usuario.perfil === PROFESSOR) {
            pageUser(0).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setAvaliacoes(data));
                    dispatch(setAvaliacao(null));
                }
            })
        }
    }, [dispatch, usuario]);

    const handleChangePage = (event, newPage) => {
        if (usuario.perfil === ADMIN || usuario.perfil === COORDENADOR) {
            page(newPage).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setAvaliacoes(data));
                    dispatch(setAvaliacao(null));
                }
            })
        } else if (usuario.perfil === PROFESSOR) {
            pageUser(newPage).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setAvaliacoes(data));
                    dispatch(setAvaliacao(null));
                }
            })
        }
    };

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta avaliação?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(avaliacoes.content[index].codigo).then((data) => {
                            if (data) {
                                dispatch(removeAvaliacoes(index));
                                dispatch(setAvaliacao(null));
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleSelecionarAvaliacao = (avaliacao) => {
        dispatch(setAvaliacao(avaliacao));
        dispatch(setAvaliacaoAluno(null));
        history.push({
            pathname: '/avaliacao-secao'
        })
    }

    const handleGoToAvaliacaoAluno = (avaliacao) => {
        dispatch(setAvaliacao(avaliacao));
        dispatch(setUsuario(usuario));
        dispatch(setAluno(null));
        dispatch(setTurma(null));
        history.push({
            pathname: '/avaliacao-aluno'
        })
    }

    return (
        <div>
            <Button
                className={classes.novoRegistro}
                onClick={() => dispatch(setAvaliacao({ usuario: usuario }))}
                variant="contained"
                color="primary">
                Nova Avaliação
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Título</TableCell>
                        <TableCell>Conteúdo</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Data Hora Início</TableCell>
                        <TableCell>Data Hora Fim</TableCell>
                        <TableCell>Criado Por</TableCell>
                        <TableCell>Conteúdo</TableCell>
                        <TableCell>Aluno</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {avaliacoes.content.map((avaliacao, index) => (
                        <TableRow key={avaliacao.codigo}>
                            <TableCell>{avaliacao.titulo}</TableCell>
                            <TableCell>{avaliacao.conteudo}</TableCell>
                            <TableCell>
                                {avaliacao.esimulado ?
                                    "Simulado" :
                                    "Avaliação"}
                            </TableCell>
                            <TableCell>{avaliacao.dataHoraInicio ? dateTimeFormat(avaliacao.dataHoraInicio) : '--'}</TableCell>
                            <TableCell>{avaliacao.dataHoraFim ? dateTimeFormat(avaliacao.dataHoraFim) : '--'}</TableCell>
                            <TableCell>{avaliacao.usuario.nome}</TableCell>

                            <TableCell>
                                <Button
                                    size='small'
                                    type="button"
                                    onClick={() => handleSelecionarAvaliacao(avaliacao)}
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    size='small'
                                    type="button"
                                    onClick={() => handleGoToAvaliacaoAluno(avaliacao)}
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    color="secondary"
                                    style={{ marginRight: '10px' }}
                                    className={classes.cursorLink}
                                    onClick={() => dispatch(setAvaliacao(avaliacao))}
                                />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    style={{ marginRight: '10px' }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={avaliacoes.size ? avaliacoes.size : 0}
                component="div"
                count={avaliacoes.totalElements}
                page={avaliacoes.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />
        </div>
    );
}