import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { findUnregisteredPerfis, save, remove, findByUsuario } from './api-usuario-perfil';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '10px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cursorLink: {
        cursor: 'pointer'
    },
    messageCapitulo: {
        background: '#a5d6a7',
        padding: '20px'
    }
}));

export default function UsuarioPerfilTableAdd({ usuario }) {
    const classes = useStyles();
    const [perfis, setPerfis] = useState([]);
    const [usuarioPerfil, setUsuarioPerfil] = useState({ usuario: usuario });
    const [usuarioPerfis, setUsuarioPerfis] = useState([]);


    useEffect(() => {
        if (usuario && usuario.id) {
            findUnregisteredPerfis(usuario.id).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPerfis(data);
                }
            })
            findByUsuario(usuario.id).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setUsuarioPerfis(data);
                }
            })
        }

    }, [usuario]);

    const handlePerfilChange = (event) => {
        const perfil = perfis.find((perfil) => {
            return perfil === event.target.value
        });
        setUsuarioPerfil({
            ...usuarioPerfil,
            perfil: perfil
        });
    }

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este perfil do usuário?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(usuarioPerfis[index].codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setPerfis([
                                    ...perfis,
                                    usuarioPerfis[index].perfil
                                ]);
                                setUsuarioPerfis(usuarioPerfis.filter(up => up.codigo !== usuarioPerfis[index].codigo));
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        save(usuarioPerfil).then(data => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setUsuarioPerfis([
                    ...usuarioPerfis,
                    data
                ]);
                setPerfis(perfis.filter(p => p !== data.perfil));
                setUsuarioPerfil({
                    usuario: usuario
                });
            }
        })

    }


    return (
        <Container className={classes.paper} component={Paper}>
            <Typography className={classes.title} variant="body1">
                Perfis Secundário do Usuário
            </Typography>
            {perfis.length > 0 ?
                <form onSubmit={clickSubmit}>
                    <FormControl fullWidth>
                        <InputLabel id="perfil-label">Perfil</InputLabel>
                        <Select
                            labelId="perfil-label"
                            id="perfil-select"
                            value={usuarioPerfil && usuarioPerfil.perfil ?
                                usuarioPerfil.perfil : ''}
                            onChange={handlePerfilChange}
                        >
                            {perfis.map((perfil) => (
                                <MenuItem key={perfil} value={perfil}>
                                    {perfil}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Adicionar
                    </Button>
                </form>
                :
                <Typography className={classes.messageCapitulo} variant="h6">
                    Todos os perfis já foram cadastrados.
                </Typography>
            }

            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Perfil</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usuarioPerfis.map((up, index) => (
                        <TableRow key={up.codigo}>
                            <TableCell>{up.perfil}</TableCell>
                            <TableCell>
                                <DeleteIcon
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    style={{ marginRight: '10px' }}
                                    color="secondary"
                                />


                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}