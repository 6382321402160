import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import LateralMenuList from './LateralMenuList';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import session from '../../../SessionHandler/session-helper';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../../../actions';
import SimpleModal from '../Modal/SimpleModal';
import SettingsIcon from '@material-ui/icons/Settings';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Box from '@material-ui/core/Box';
import { persistor } from '../../../configureStore';
import { changeARAluno, currentUser, changePhoto } from '../../../components/Usuario/api-usuario';
import { getQuantNaoLidas } from '../../../components/Mensagem/SalaParticipante/api-sala-participante';
import BadgeQuantNaoLidas from '../../../components/Mensagem/MensagemTable/BadgeQuantNaoLidas';
import { setQuantNaoLidas } from '../../../components/Mensagem/SalaParticipante/actions/actions';
import { setUsuario } from '../../../components/Usuario/actions/actions';
import { ADMIN, COORDENADOR, RESPONSAVEL } from '../../../components/Usuario/Perfil';
import { setQuantAprovacaoComunicado } from '../../../components/Comunicado/Comunicado/actions/actions';
import { getQuantAprovacaoComunicado } from '../../../components/Comunicado/Comunicado/api-comunicado';
import { setQuantAprovacaoEvento } from '../../../components/Evento/Evento/actions/actions';
import { getQuantAprovacaoEvento } from '../../../components/Evento/Evento/api-evento';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { findByUsuario } from '../../../components/AlunoResponsavel/api-aluno-responsavel';
import { toast } from 'react-toastify';

const drawerWidth = 150;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
    toolbarTitle: {
        flex: 1,
    },
    iconMenu: {
        marginRight: '5px',
    },
    logo: {
        marginTop: '10px',
        marginLeft: '10px',
        width: drawerWidth - 35,
    },
    fotoComponent: {
        width: '40px',
        borderRadius: '20px',
    },
    fotoComponentMenu: {
        width: '80px',
        borderRadius: '60px',
    },
    fotoAR: {
        width: '80px',
        borderRadius: '20px',
        marginRight: '10px'
    },
    userPhoto: {
        '@media print': {
            display: 'none'
        }
    }
}));

export default function LateralMenuSkeleton(props) {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);
    const { children } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElAR, setAnchorElAR] = React.useState(null);
    const isLogout = useSelector(state => state.logout);
    const dispatch = useDispatch();
    const quantNaoLidas = useSelector(state => state.quantNaoLidas);
    const quantAprovacaoComunicado = useSelector(state => state.quantAprovacaoComunicado);
    const quantAprovacaoEvento = useSelector(state => state.quantAprovacaoEvento);
    const [listaAR, setListaAR] = useState([]);
    const hiddenFileInput = useRef(null);

    const usuario = useSelector(state => state.usuario);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        description: ''
    })

    useEffect(() => {
        if (!usuario) {
            currentUser().then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);

                    } else {
                        dispatch(setUsuario(data));
                    }
                }

            })
        }

        getQuantNaoLidas().then((data) => {
            if (data) {
                if (data.error) {
                    console.log(data.message);
                } else {
                    dispatch(setQuantNaoLidas(data));
                }
            }

        });

        if (usuario &&
            (usuario.perfil === ADMIN || usuario.perfil === COORDENADOR)) {


            getQuantAprovacaoComunicado().then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.message);

                    } else {
                        dispatch(setQuantAprovacaoComunicado(data));
                    }
                }


            })

            getQuantAprovacaoEvento().then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.message);

                    } else {
                        dispatch(setQuantAprovacaoEvento(data));
                    }
                }


            })
        }

        if (usuario &&
            usuario.perfil === RESPONSAVEL) {

            findByUsuario(usuario.id).then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.message);
                    } else {
                        setListaAR(data);
                    }
                }
            });
        }
    }, [dispatch, usuario, listaAR])

    useEffect(() => {
        const findQuantNaoLidas = setInterval(() => {
            getQuantNaoLidas().then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.message);
                    } else {
                        dispatch(setQuantNaoLidas(data));
                    }
                }
            });
        }, 10000);
        return () => clearInterval(findQuantNaoLidas);
    }, [dispatch]);

    useEffect(() => {
        if (isLogout) {
            setModal({
                open: true,
                title: 'Acesso Token Expirado.',
                description: 'Seu token de acesso foi expirado. Realizar novamente o login.'
            });
            setTimeout(() => {
                setAnchorEl(null);
                localStorage.removeItem('token');
                dispatch(removeUser());
                setRedirect(true);
            }, 3000);
        }

    }, [isLogout, dispatch])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAR = event => {
        if (usuario.perfil === RESPONSAVEL) {
            setAnchorElAR(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseAR = () => {
        setAnchorElAR(null);
    };

    const handleLogout = async () => {
        setAnchorEl(null);
        localStorage.removeItem('token');
        dispatch(removeUser());
        setRedirect(true);
        await persistor.purge().then((data) => console.log(data));
    };

    const handleAtivarAR = (ar) => {
        changeARAluno(ar.aluno).then((data) => {
            if (data.error) {
                errorMessage(data.message)

            } else {
                dispatch(setUsuario(data));
                toast.success("Aluno Selecionado.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                handleCloseAR();
            }
        });
    }

    const handleChangeImage = e => {

        const file = e.target.files[0];

        if (file === null) {
            return;
        }

        const fileFormData = new FormData();
        fileFormData.append('file', file);

        changePhoto(usuario.id, fileFormData).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setUsuario(data));
            }
        })




    };

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }


    if (redirect) {
        return <Redirect to='/' />
    } else {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <SimpleModal modal={modal} setModal={setModal} />
                <AppBar
                    style={{
                        backgroundColor: usuario && usuario.instituicao && usuario.instituicao.corPrimaria ? usuario.instituicao.corPrimaria : null
                    }}
                    position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            style={{
                                color: usuario && usuario.instituicao && usuario.instituicao.corSecundaria ? usuario.instituicao.corSecundaria : null
                            }}
                            noWrap
                            align="center"
                            className={classes.toolbarTitle}>
                            {usuario && usuario.instituicao ? usuario.instituicao.descricao + ' -' : ''} {session.getAnoLetivo()}
                        </Typography>

                        <Button color="secondary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickAR}>
                            <img
                                className={classes.fotoComponent}
                                src={usuario && usuario.alunoFoto ?
                                    usuario.alunoFoto.foto : null}
                                alt=''
                            />
                        </Button>

                        <Button
                            className={classes.userPhoto}
                            color="secondary"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            {usuario && usuario.fileName ?
                                <img
                                    src={usuario.fotoUrl}
                                    alt=''
                                    className={classes.fotoComponent}
                                /> :
                                <AccountCircle style={{ fontSize: 50, color: 'white' }} />
                            }

                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                style={{ justifyContent: 'center' }}
                                onClick={handleAttachFile}
                            >
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    ref={hiddenFileInput}
                                    onChange={handleChangeImage}
                                />
                                {usuario && usuario.fileName ?
                                    <img
                                        src={usuario.fotoUrl}
                                        alt=''
                                        className={classes.fotoComponentMenu}
                                    /> :
                                    <AccountCircle style={{ fontSize: 50, color: 'black' }} />
                                }
                            </MenuItem>
                            <MenuItem>
                                {usuario && usuario.email ? usuario.email : ''}
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to={{
                                    pathname: '/usuario-senha',
                                    state: {
                                        usuario: usuario
                                    }
                                }}
                            >
                                <VpnKeyIcon className={classes.iconMenu} />Trocar Senha
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to={{
                                    pathname: '/configuracao'
                                }}
                            >
                                <SettingsIcon className={classes.iconMenu} />Configuração
                            </MenuItem>

                            <MenuItem onClick={handleLogout}>
                                <MeetingRoomIcon className={classes.iconMenu} />Logout
                            </MenuItem>




                        </Menu>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElAR}
                            keepMounted
                            open={Boolean(anchorElAR)}
                            onClose={handleCloseAR}
                        >
                            {listaAR.map((ar) => (
                                <MenuItem
                                    key={ar.codigo}
                                    onClick={() => handleAtivarAR(ar)}
                                >
                                    <img
                                        className={classes.fotoAR}
                                        src={ar.alunoFoto ? ar.alunoFoto.foto : null}
                                        alt=''
                                    />
                                    {ar.aluno.nome}
                                </MenuItem>
                            ))}

                        </Menu>


                    </Toolbar>
                </AppBar>
                <Box display="block" displayPrint="none">
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        displayprint="none"
                    >

                        <div className={classes.toolbar} />

                        <Link to='/home'>
                            <img
                                src={usuario && usuario.instituicao ? usuario.instituicao.urlLogo : ''}
                                className={classes.logo}
                                alt=''
                            />
                        </Link>
                        <List>
                            {usuario ? LateralMenuList.map((lateralMenu, index) => {
                                const access = lateralMenu.access.find(lm => lm === usuario.perfil);
                                if (access) {
                                    if (lateralMenu.descricao === 'Livro' && !usuario.instituicao.livroHabilitado) {
                                        return ''
                                    }
                                    if (lateralMenu.descricao === 'Avaliação' && !usuario.instituicao.avaliacaoHabilitado) {
                                        return ''
                                    }
                                    if (lateralMenu.descricao === 'Comunicado' &&
                                        usuario.instituicao.comunicadoAprovacao &&
                                        (usuario.perfil === ADMIN || usuario.perfil === COORDENADOR)) {
                                        return (
                                            <ListItem component={Link} to={lateralMenu.link} button key={index}>
                                                <span style={{ fontSize: 'smaller' }}>
                                                    {lateralMenu.descricao}
                                                </span>
                                                < BadgeQuantNaoLidas quantNaoLidas={quantAprovacaoComunicado} />

                                            </ListItem>
                                        )
                                    }
                                    if (lateralMenu.descricao === 'Evento' &&
                                        (usuario.perfil === ADMIN || usuario.perfil === COORDENADOR)) {
                                        return (
                                            <ListItem component={Link} to={lateralMenu.link} button key={index}>
                                                <span style={{ fontSize: 'smaller' }}>
                                                    {lateralMenu.descricao}
                                                </span>
                                                < BadgeQuantNaoLidas quantNaoLidas={quantAprovacaoEvento} />

                                            </ListItem>
                                        )
                                    }
                                    if (lateralMenu.descricao === 'Mensagem') {
                                        if (!usuario.instituicao.mensagemHabilitado) {
                                            return '';
                                        }
                                        else {
                                            return (
                                                <ListItem component={Link} to={lateralMenu.link} button key={index}>
                                                    <span style={{ fontSize: 'smaller' }}>
                                                        {lateralMenu.descricao}
                                                    </span>
                                                    <BadgeQuantNaoLidas quantNaoLidas={quantNaoLidas} />

                                                </ListItem>
                                            )
                                        }

                                    }
                                    if (lateralMenu.descricao === 'Mural' && !usuario.instituicao.muralHabilitado) {
                                        return '';
                                    }
                                    return (
                                        <ListItem component={Link} to={lateralMenu.link} button key={index}>
                                            <span style={{ fontSize: 'smaller' }}>{lateralMenu.descricao}</span>
                                        </ListItem>
                                    )


                                } else {
                                    return '';
                                }
                            }) : ''}
                        </List>
                    </Drawer>
                </Box>

                <main className={classes.content}>

                    <div className={classes.toolbar} />
                    {children}
                </main>

            </div>
        );
    }


}