export const lightList = [
    '#f7cac9',
    '#fefbd8',
    '#f4e1d2',
    '#b7d7e8',
    '#f9ccac',
    '#a2836e',
    '#b8a9c9',
    '#f2e394',
    '#f2ae72',
    '#ffe9e3',
    '#9cd3d3',
    '#f48db4',
    '#adf7d1',
    '#64c4ed',
    '#fde994',
    '#8f71ff',
    '#698474',
    '#ffb6b9',
    '#c9fdd7',
    '#f5e1da',
]

export const successBg = '#e0ffcd';
export const successBorder = '#cbf078';
export const errorBg = '#ffaaa5';
export const errorBorder = '#ff5757';