import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByTitulo, findByCategoria } from './api-comunicado';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { dateTimeFormat } from "../../../util/DateUtil";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { findAll } from "../ComunicadoCategoria/api-comunicado-categoria";
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));


export default function ComunicadoFindDialog({ openSearchComunicado, setOpenSearchComunicado, setComunicado }) {
    const classes = useStyles();
    const [comunicados, setComunicados] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const [opcoesBusca, setOpcoesBusca] = useState({
        por: 'titulo',
        busca: ''
    });
    const [categorias, setCategorias] = useState([]);


    useEffect(() => {
        findAll().then((data) => {
            setCategorias(data);
        })
    }, []);

    const closeSearchComunicado = () => {
        setOpenSearchComunicado(false);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (opcoesBusca.por === 'titulo') {
            findByTitulo(opcoesBusca.busca, 0).then((data) => {
                setComunicados(data);
            }).catch((err) => console.log(err));
        } else if (opcoesBusca.por === 'categoria') {
            findByCategoria(opcoesBusca.busca, 0).then((data) => {
                setComunicados(data);
            }).catch((err) => console.log(err));
        }
    }

    const handleCategoriaChange = (event) => {
        const categoria = categorias.find((categoria) => {
            return categoria.codigo === event.target.value
        });
        setOpcoesBusca({
            ...opcoesBusca,
            busca: categoria.codigo
        });
    }

    const handleBuscaChange = (event) => {
        setOpcoesBusca({
            ...opcoesBusca,
            [event.target.name]: event.target.value
        });

    }

    const handleSelected = (comunicado) => {
        setComunicado(comunicado)
        closeSearchComunicado()
    }

    const handleClose = () => {
        closeSearchComunicado()
    };

    const handleChangePage = (event, newPage) => {
        if (opcoesBusca.por === 'titulo') {
            findByTitulo(opcoesBusca.busca, newPage).then((data) => {
                setComunicados(data);
            }).catch((err) => console.log(err));
        } else if (opcoesBusca.por === 'categoria') {
            findByCategoria(opcoesBusca.busca, newPage).then((data) => {
                setComunicados(data);
            }).catch((err) => console.log(err));
        }
    };

    return (
        <Dialog open={openSearchComunicado} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Procurar Comunicados</DialogTitle>
            <DialogContent>
                <form onSubmit={clickSubmit}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Buscar por:</FormLabel>
                        <RadioGroup aria-label="gender" name="por" value={opcoesBusca.por} onChange={handleBuscaChange}>
                            <FormControlLabel value="titulo" control={<Radio />} label="Título" />
                            <FormControlLabel value="categoria" control={<Radio />} label="Categoria" />
                        </RadioGroup>
                    </FormControl>
                    {opcoesBusca.por === 'categoria' ?
                        <FormControl fullWidth >
                            <InputLabel id="categoria-label">Categoria:</InputLabel>
                            <Select
                                labelId="categoria-label"
                                style={{ marginBottom: '20px' }}
                                id="categoria-select"
                                value={
                                    opcoesBusca.busca ?
                                        opcoesBusca.busca :
                                        ''
                                }
                                onChange={handleCategoriaChange}
                            >
                                {categorias.map((categoria, index) => (
                                    <MenuItem key={index} value={categoria.codigo}>
                                        {categoria.descricao}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        :
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Busca"
                            name="busca"
                            autoFocus
                            onChange={handleBuscaChange}
                            value={opcoesBusca.busca || ''}
                        />}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Buscar
                    </Button>
                </form>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Título</TableCell>
                            <TableCell>Categoria</TableCell>
                            <TableCell>Criado em</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comunicados.content.map((comunicado) => (
                            <TableRow key={comunicado.codigo}>
                                <TableCell>{comunicado.titulo}</TableCell>
                                <TableCell>{comunicado.categoria.descricao}</TableCell>
                                <TableCell>{dateTimeFormat(comunicado.createdAt)}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleSelected(comunicado)}
                                        variant="contained"
                                        color="secondary">
                                        Selecionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[]}
                    rowsPerPage={comunicados.size ? comunicados.size : 0}
                    component="div"
                    count={comunicados.totalElements}
                    page={comunicados.number}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}