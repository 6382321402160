import {
    ADD_ALUNOS_LIVROS,
    UPDATE_ALUNOS_LIVROS,
    SET_ALUNOS_LIVROS,
    REMOVE_ALUNOS_LIVROS,
    SET_ALUNO_LIVRO,
    OPEN_ALUNO_LIVRO,
    CLOSE_ALUNO_LIVRO
} from './actionTypes'

export function addAlunosLivros(alunoLivro) {
    return { type: ADD_ALUNOS_LIVROS, alunoLivro }
}

export function updateAlunosLivros(alunoLivro) {
    return { type: UPDATE_ALUNOS_LIVROS, alunoLivro }
}

export function setAlunosLivros(alunosLivros) {
    return { type: SET_ALUNOS_LIVROS, alunosLivros }
}

export function removeAlunosLivros(index) {
    return { type: REMOVE_ALUNOS_LIVROS, index }
}

export function setAlunoLivro(alunoLivro) {
    return { type: SET_ALUNO_LIVRO, alunoLivro }
}

export function openSearchAlunoLivro() {
    return { type: OPEN_ALUNO_LIVRO }
}

export function closeSearchAlunoLivro() {
    return { type: CLOSE_ALUNO_LIVRO }
}