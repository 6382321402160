import React from 'react';
import LateralMenuSkeleton from './LateralMenuSkeleton';

export default function LateralMenuContent(props) {
    const { child: Child } = props;

    return (
        <div>
            <LateralMenuSkeleton>
                <Child {...props} />
            </LateralMenuSkeleton>

        </div>
    );
}