import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import { findSalasGroup } from "./SalaParticipante/api-sala-participante";
import { useHistory } from 'react-router-dom';

import { RESPONSAVEL, ALUNO } from '../Usuario/Perfil';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { TablePagination } from '@material-ui/core';
import TablePaginationActions from '../../shared/components/Table/Actions';
import { dateTimeFormat } from '../../util/DateUtil';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formulario: {
        marginBottom: '20px'
    }
});


export default function MensagemFindGroup() {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const [search, setSearch] = useState();
    const [salas, setSalas] = useState({
        content: [],
        totalElements: 0,
        size: 0,
        number: 0

    });
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const isAll = useSelector(state => state.isAllMensagemTable);

    const handleChange = (event) => {
        setSearch(event.target.value);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        fetchData(0);
    }

    const fetchData = (selectPage) => {
        setIsLoading(true);
        findSalasGroup(search, selectPage, isAll).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setSalas(data);
            }
            setIsLoading(false);
        });
    }

    const handleSelectedUser = (sala) => {
        history.push('/sala-mensagem', { sala: sala })
    }

    const handleChangePage = (event, newPage) => {
        fetchData(newPage);
    };

    return (
        <>
            {isLoading ?
                <Loading description='Carregando Grupos...' />
                :
                <>
                    {usuario && usuario.perfil !== RESPONSAVEL && usuario.perfil !== ALUNO ?
                        <form className={classes.formulario} onSubmit={clickSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                                label="Descrição"
                                name="descricao"
                                onChange={handleChange}
                                value={search ? search : ''}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Buscar
                            </Button>
                        </form> : ''}

                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Data Hora Última Mensagem</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {salas && salas.content.map((sala, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>
                                        {sala && sala.descricao ? sala.descricao : ''}
                                    </TableCell>
                                    <TableCell>
                                        {sala && sala.dataHoraUtlimaMensagem ? 
                                        dateTimeFormat(sala.dataHoraUtlimaMensagem) : ''}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.submit}
                                            onClick={() => handleSelectedUser(sala)}
                                        >
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={salas.size ? salas.size : 0}
                        component="div"
                        count={salas.totalElements}
                        page={salas.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>

            }

        </>
    );
}