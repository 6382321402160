import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { findByUsuario } from './api-aluno-responsavel';
import { changeARAluno } from '../Usuario/api-usuario';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../Usuario/actions/actions';
import { Grid } from '@material-ui/core';

export default function AlunoResponsavelAtivoTable() {
    const usuario = useSelector(state => state.usuario);
    const [alunosReponsavel, setAlunosReponsavel] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {
        if (usuario && usuario.id) {
            findByUsuario(usuario.id).then((data) => {
                if (data) {
                    setAlunosReponsavel(data);
                }
            });
        }

    }, [usuario]);


    const handleAtivar = (aluno) => {
        changeARAluno(aluno).then((data) => {
            if (data) {
                dispatch(setUsuario(data));
                toast.success("Aluno Selecionado.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        });
    }

    return (
        <div>
            {alunosReponsavel.length > 0 ?

                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <Typography variant="h5">
                            Alterar Aluno Selecionado
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Aluno</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alunosReponsavel.map((ar) => (
                                    <TableRow key={ar.codigo}>
                                        <TableCell>{ar.aluno.nome}</TableCell>
                                        <TableCell>
                                            {!usuario.arAlunoSelecionado || usuario.arAlunoSelecionado.codigo !== ar.aluno.codigo ?
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleAtivar(ar.aluno)}
                                                >
                                                    Selecionar
                                                </Button>
                                                : 'Selecionado'}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                : ''}

        </div>
    );
}