import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { updateGroup, createGroup } from "../api-mensagem";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    container: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    title: {
        margin: '20px'
    },
});

export default function GroupForm(props) {
    const classes = useStyles();
    const [sala, setSala] = useState();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {

        if (props.location.state && props.location.state.sala) {
            setSala(props.location.state.sala);
        }

    }, [props.location.state]);

    const handleChange = (event) => {
        setSala({
            ...sala,
            descricao: event.target.value
        });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);
        if (sala.codigo) {
            updateGroup(sala).then((data) => {
                if (data) {
                    setSala(data);
                    toast.success("Nome do Grupo Atualizado.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            })
        } else {
            createGroup(sala).then((data) => {
                if (data) {
                    setSala(data);
                    toast.success("Grupo Criado com Sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            })
        }
        setIsSaving(false);
    }


    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container className={classes.container} component={Paper}>
                <Button
                    component={Link}
                    to={{
                        pathname: '/mensagem'
                    }}
                    variant="contained"
                    color="secondary"
                    className={classes.voltarButton}
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
                <Grid container justify="space-between">
                    <Typography className={classes.title} variant="h5">
                        Grupo
                    </Typography>
                </Grid>
                <form
                    onSubmit={clickSubmit}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Nome do Grupo"
                        autoFocus
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        value={sala && sala.descricao ? sala.descricao : ''}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                    >
                        Salvar
                    </Button>
                </form>

            </Container>
        </div>
    );
}