import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'observacao-aluno/';

const page = (codTdp, codAluno, pageNumber) => {
  const url = SERVER_URL + controller + 
  'page?page=' + pageNumber + 
  '&codTdp=' + codTdp + 
  '&codAluno=' + codAluno;
  return fetchGetAuth(url);
}

const findByTdpCodigoAndCurrentUser = (codTDP, pageNumber) => {
  const url = SERVER_URL + controller + "aluno/tdp/" + codTDP + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const findByCodigo = (codObservacaoAluno) => {
  const url = SERVER_URL + controller + '/' + codObservacaoAluno;
  return fetchGetAuth(url);
}

const remove = (codObservacaoAluno) => {
  const url = SERVER_URL + controller + 'delete/' + codObservacaoAluno;
  return fetchRemoveAuth(url);
}

const save = (observacaoAluno) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, observacaoAluno);
}

export {
  page,
  save,
  remove, 
  findByCodigo, 
  findByTdpCodigoAndCurrentUser
}