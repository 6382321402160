import React, { useEffect, useState } from 'react';
import { findByCapituloCodCapitulo } from './api-conteudo';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ComponentsForm from "./componentsForm.js";
import { useSelector, useDispatch } from "react-redux";
import { setConteudos } from "./actions/actions";
import { CircularProgress, makeStyles } from '@material-ui/core';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    loadingDiv: {
        display: 'flex',
        justifyContent: 'center',
    }
});

export default function ConteudoTableForm() {
    const conteudos = useSelector(state => state.conteudos);
    const capitulo = useSelector(state => state.capitulo);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        if (capitulo && capitulo.codCapitulo) {
            setIsLoading(true);
            findByCapituloCodCapitulo(capitulo.codCapitulo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setConteudos(data));
                }
                setIsLoading(false);
            });
        }

    }, [capitulo, dispatch]);
    return (
        <>
            {isLoading ?
                <div className={classes.loadingDiv}>
                    <CircularProgress
                        color="primary"
                        size={80}
                    />
                </div> : conteudos.map(conteudo => ComponentsForm(conteudo))}
        </>
    );
}