import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TurmaForm from './TurmaForm';
import { useSelector } from "react-redux";
import TurmaTable from "./TurmaTable";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    container: {
        marginTop: '20px'
    },
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function CursoContainer() {
    const turma = useSelector(state => state.turma);
    const classes = useStyles();

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Turmas
                </Typography>
                {turma ?
                    <>
                        <Grid style={{ marginBottom: '20px' }} container spacing={2}>
                            <Grid item xs={4}>
                                <TurmaForm />
                            </Grid>

                        </Grid>
                    </> :
                    <TurmaTable />
                }

            </Container>
        </div>
    );
}