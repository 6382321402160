import { SERVER_URL } from '../../constants';
import session from '../../SessionHandler/session-helper';

import {
  fetchGetAuth,
} from '../../util/FetchUtil';
const controller = 'engajamento-diario/';

const findAdesaoByLastFiveDays = () => {
  const url = SERVER_URL + controller + 'last-five-days';
  return fetchGetAuth(url);
}

const findAcessoByLastFiveDays = () => {
  const url = SERVER_URL + controller + 'acesso/last-five-days';
  return fetchGetAuth(url);
}

const findAdesaoByAnoLetivo = () => {
  const url = SERVER_URL + controller + 'ano-letivo/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const findAcessoByAnoLetivo = () => {
  const url = SERVER_URL + controller + 'acesso/ano-letivo/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

export {
  findAdesaoByLastFiveDays, 
  findAdesaoByAnoLetivo, 
  findAcessoByLastFiveDays, 
  findAcessoByAnoLetivo
}