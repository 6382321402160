import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { rltBoletimEscolar } from './api-relatorio';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import TurmaComboBox from '../Turma/UI/TurmaComboBox';
import BimestreComboBox from '../Bimestre/UI/BimestreComboBox';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RltTurmaBimestreForm({ turmas }) {
    const classes = useStyles();
    const [turma, setTurma] = useState({});
    const [numeroBimestre, setNumeroBimestre] = useState(1);
    const [loading, setLoading] = useState(false);

    const clickBoletimEscolar = () => {
        setLoading(true);
        if (turma) {
            rltBoletimEscolar(turma.codigo, numeroBimestre).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading(false);
                }
            })
        }

    }


    const handleTurmaChange = (event) => {
        const turma = turmas.find((t) => t.codigo === event.target.value);
        setTurma(turma);
    }

    const handleBimestreChange = (event) => {
        setNumeroBimestre(event.target.value);
    }


    return (
        <div>
            <CssBaseline />
            <Container component={Paper} >
                <Typography className={classes.title} variant="body1">
                    Por Turma e Bimestre
                </Typography>
                <form>
                    <TurmaComboBox
                        turmas={turmas}
                        turma={turma}
                        handleTurmaChange={handleTurmaChange}
                    />
                    <BimestreComboBox
                        numeroBimestre={numeroBimestre}
                        handleBimestreChange={handleBimestreChange}
                    />
                    {loading ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/relatorio-rendimento-escolar-web',
                                    state: {
                                        numeroBimestre: numeroBimestre,
                                        turma: turma
                                    }
                                }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Rendimento Escolar Web
                            </Button>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={clickBoletimEscolar}
                            >
                                Boletim Escolar
                            </Button>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/relatorio-boletim-web',
                                    state: {
                                        numeroBimestre: numeroBimestre,
                                        codTurma: turma.codigo
                                    }
                                }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Boletim Escolar Web
                            </Button>
                        </>
                    }
                </form>
            </Container>
        </div>
    );
}