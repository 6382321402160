import { SERVER_URL } from '../../../constants';
import session from '../../../SessionHandler/session-helper';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'integral-professor/';

const findByTurmaAnoLetivo = () => {
  const url = SERVER_URL + controller + 'ano-letivo/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const save = (integralProfessor) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, integralProfessor);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuthReturn(url);
}

export {
  findByTurmaAnoLetivo,
  save,
  remove
}