import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByAnoLetivoAndCurrentUser } from '../api-nota-bimestre';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import { noteRound } from "../../../util/DoubleUtil";

const useStyles = makeStyles({
    paper: {
        marginTop: '60px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    submit: {
        marginRight: '8px',
    },
    loading: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginVertical: 20,
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontWeight: 'bolder', 
        textAlign:'center'
    },
    rowMediaBimestral: {
        fontSize: 'large',
        fontWeight: '700', 
        textAlign:'center'
    },
    rowDisciplina: {
        fontSize: 'large',
        fontWeight: '700'
    },
    rowNotas: {
        textAlign:'center'
    }
});


export default function NBAlunoTable() {
    const classes = useStyles();
    const [listaNBAT, setListaNBAT] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        findByAnoLetivoAndCurrentUser().then((data) => {
            if (data) {
                console.log(data);
                setListaNBAT(data);
                setLoading(false);
            }
        })
    }, []);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>

                {loading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                        Carregando...
                    </div>
                    :
                    <>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            {listaNBAT.length > 0 ? listaNBAT.map((nbat, index) => (
                                <TableBody key={index}>
                                    <TableRow>
                                        <TableCell className={classes.rowDisciplina} rowSpan={4}>{nbat.tdp.disciplina.descricao}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell className={classes.rowHeader}>1º Bimestre</TableCell>
                                        <TableCell className={classes.rowHeader}>2º Bimestre</TableCell>
                                        <TableCell className={classes.rowHeader}>3º Bimestre</TableCell>
                                        <TableCell className={classes.rowHeader}>4º Bimestre</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Média Mensal</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaMensal1)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaMensal2)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaMensal3)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.mediaMensal4)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Nota Bimestral</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.notaBimestral1)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.notaBimestral2)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.notaBimestral3)}</TableCell>
                                        <TableCell className={classes.rowNotas}>{noteRound(nbat.notaBimestral4)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.rowMediaBimestral}>Média Bimestral</TableCell>
                                        <TableCell className={classes.rowMediaBimestral}>{noteRound(nbat.mediaBimestral1)}</TableCell>
                                        <TableCell className={classes.rowMediaBimestral}>{noteRound(nbat.mediaBimestral2)}</TableCell>
                                        <TableCell className={classes.rowMediaBimestral}>{noteRound(nbat.mediaBimestral3)}</TableCell>
                                        <TableCell className={classes.rowMediaBimestral}>{noteRound(nbat.mediaBimestral4)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            )) : ''}

                        </Table>
                    </>
                }
            </Container>
        </div>
    );
}