import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findProvaFinalizadaPorAvaliacao, findProvaFinalizadaPorAvaliacaoAluno } from './Aluno/api-avaliacao-aluno';
import AvaliacaoAlunoResultadoGraph from './Aluno/AvaliacaoAlunoResultadoGraph';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAvaliacao } from './actions/actions';
import { setAvaliacaoAluno } from './Aluno/actions/actions';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function SimuladoResultadoTable() {
    const classes = useStyles();
    const history = useHistory();
    const avaliacaoAluno = useSelector(state => state.avaliacaoAluno);
    const [avaliacoesAluno, setAvaliacoesAluno] = useState([]);
    const avaliacao = useSelector(state => state.avaliacao);
    const dispatch = useDispatch();
    useEffect(() => {
        if (avaliacaoAluno && avaliacaoAluno.codigo) {
            findProvaFinalizadaPorAvaliacaoAluno(avaliacaoAluno.codigo).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setAvaliacoesAluno(data);
                }
            })
        }



    }, [avaliacaoAluno]);

    useEffect(() => {
        if (avaliacao && avaliacao.codigo) {
            findProvaFinalizadaPorAvaliacao(avaliacao.codigo).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setAvaliacoesAluno(data);
                }
            })
        }

    }, [avaliacao]);

    const handleSelecionarAvaliacao = (aa) => {
        dispatch(setAvaliacaoAluno(aa));
        dispatch(setAvaliacao(aa.avaliacao));
        history.push({
            pathname: '/avaliacao-secao',
            state: {
                mode: 'correction'
            }
        })
    }

    return (
        <div>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Título</TableCell>
                        <TableCell>Conteúdo</TableCell>
                        <TableCell>Tentativa</TableCell>
                        <TableCell>Quant. Perguntas</TableCell>
                        <TableCell>Quant. Acertos</TableCell>
                        <TableCell>Nota</TableCell>
                        <TableCell>Respostas</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {avaliacoesAluno.map((aa) => (
                        <TableRow key={aa.codigo}>
                            <TableCell>{aa.avaliacao.titulo}</TableCell>
                            <TableCell>{aa.avaliacao.conteudo}</TableCell>
                            <TableCell>{aa.tentativa}</TableCell>
                            <TableCell>{aa.avaliacao.quantPerguntas}</TableCell>
                            <TableCell>{aa.quantAcertos}</TableCell>
                            <TableCell>{aa.nota}</TableCell>
                            <TableCell>
                                <Button
                                    size='small'
                                    onClick={() => handleSelecionarAvaliacao(aa)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {avaliacao && avaliacao.esimulado ?
                <AvaliacaoAlunoResultadoGraph avaliacoesAluno={avaliacoesAluno} />
                : ''}

        </div>
    );
}