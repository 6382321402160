import {
  ADD_LIVROS_AUTOR,
  UPDATE_LIVROS_AUTOR,
  SET_LIVROS_AUTOR,
  REMOVE_LIVROS_AUTOR,
  SET_LIVRO_AUTOR,
  OPEN_LIVRO_AUTOR,
  CLOSE_LIVRO_AUTOR
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchLivroAutor(state = false, action) {
  switch (action.type) {
    case OPEN_LIVRO_AUTOR:
      return true;
    case CLOSE_LIVRO_AUTOR:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function livroAutor(state = null, action) {
  switch (action.type) {
    case SET_LIVRO_AUTOR:
      return action.livroAutor
    case PURGE:
      return null;
    default:
      return state
  }
}

export const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function livrosAutor(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_LIVROS_AUTOR:
      persist.content = [
        ...persist.content,
        action.livroAutor
      ]
      return persist

    case UPDATE_LIVROS_AUTOR:
      let content = state.content.map((livroAutor) => {
        if (livroAutor.codigo === action.livroAutor.codigo) {
          return action.livroAutor;
        } else {
          return livroAutor;
        }
      });
      persist.content = content;
      return persist;

    case SET_LIVROS_AUTOR:
      return action.livrosAutor

    case REMOVE_LIVROS_AUTOR:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}
