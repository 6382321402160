import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { rltAtaFinalWeb } from "../api-relatorio";
import CircularProgress from '@material-ui/core/CircularProgress';
import RelatorioAtaFinalNotaBimestreWeb from "./RelatorioAtaFinalNotaBimestreWeb";
import RelatorioCabecalhoWeb from './Cabecalho/RelatorioCabecalhoWeb';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }
});

export default function RelatorioAtaFinalWeb(props) {
    const classes = useStyles();
    const [ataFinal, setAtaFinal] = useState();
    const codAlunoTurma = props.location.state.codAlunoTurma;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (codAlunoTurma) {
            rltAtaFinalWeb(codAlunoTurma).then((data) => {
                if (data.error) {
                    console.log(data);
                    setIsLoading(false);
                } else {
                    console.log(data);
                    setAtaFinal(data);
                    setIsLoading(false);
                }
            })
        }


    }, [codAlunoTurma]);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Button
                    onClick={() => props.history.goBack()}
                    variant="contained"
                    color="secondary"
                    className={classes.voltarButton}
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
                <Typography className={classes.title} variant="h5">
                    Ata Final
                </Typography>
                {isLoading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" /> Carregando...
                    </div> :
                    <>
                        <RelatorioCabecalhoWeb alunoTurma={ataFinal[0].alunoTurma} />
                        <RelatorioAtaFinalNotaBimestreWeb ataFinal={ataFinal} />
                    </>

                }







            </Container>

        </div>
    );
}