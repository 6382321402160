import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '100%',
        flexGrow: 1
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
    listaCapitulos: {
        marginTop: '10px',
        marginBottom: '10px',
    }
});

export default function LivroCapituloProfile({ capitulo }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Livro
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {capitulo.livro.descricao}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Série
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {capitulo.livro.serie.descricao}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Disciplina
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {capitulo.livro.disciplina.descricao}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Capítulo
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {capitulo.descricao}
                        </Typography>
                    </Grid>
                </Grid>
                <CardActions>
                    <Link to={{
                        pathname: '/livro-capitulos',
                        state: {
                            livro: capitulo.livro
                        }
                    }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.listaCapitulos}>
                            Lista Capítulos
                        </Button>
                    </Link>
                </CardActions>

            </CardContent>
        </Card>
    );
}