import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { changePassword } from './api-usuario';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    voltarButton: {
        marginBottom: '20px',
    }
}));

export default function UsuarioFormSenha(props) {
    const classes = useStyles();
    let usuarioProp = {};
    if (props.location.state.usuario) {
        usuarioProp = props.location.state.usuario;
    }
    const [usuario] = useState(usuarioProp);
    const [usuarioPassword, setUsuarioPassword] = useState({
        usuario: usuario
    });

    const handleChange = (event) => {
        setUsuarioPassword({
            ...usuarioPassword,
            [event.target.name]: event.target.value
        })
    }


    const clickSubmit = (event) => {
        event.preventDefault();

        if (usuarioPassword.password1 !== usuarioPassword.password2) {
            toast.error("Digite a mesma senha para os dois campos de senha.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        changePassword(usuarioPassword).then(data => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        });

    }


    return (
        <div>
            <ToastContainer autoClose={3000} />
            <CssBaseline />
            <Container className={classes.paper} component={Paper} maxWidth="sm">
                <Container component={Paper} >
                    <Typography className={classes.title} variant="h5">
                        Usuário Senha
                    </Typography>
                    <form onSubmit={clickSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoFocus
                            label="Nome"
                            name="nome"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={usuario.nome || ''}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Email"
                            name="email"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={usuario.email || ''}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Perfil"
                            name="perfil"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={usuario.perfil || ''}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password1"
                            label="Senha"
                            type="password"
                            id="password1"
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={usuarioPassword.password1 || ''}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password2"
                            label="Confirma Senha"
                            type="password"
                            id="password2"
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={usuarioPassword.password2 || ''}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Salvar
                        </Button>
                    </form>
                </Container>
            </Container>
        </div>
    );
}