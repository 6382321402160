import {
  SET_CURSO,
  ADD_CURSOS,
  UPDATE_CURSOS,
  REMOVE_CURSOS,
  SET_CURSOS
} from "./actionTypes"
import { PURGE } from "redux-persist";

export function curso(state = null, action) {
  switch (action.type) {
    case SET_CURSO:
      return action.curso
    case PURGE:
      return null;
    default:
      return state
  }
}

export function cursos(state = [], action) {
  switch (action.type) {

    case ADD_CURSOS:

      return [
        ...state,
        action.curso
      ]

    case UPDATE_CURSOS:
      let content = state.map((curso) => {
        if (curso.codCurso === action.curso.codCurso) {
          return action.curso;
        } else {
          return curso;
        }
      });
      return content;

    case SET_CURSOS:
      return action.cursos

    case REMOVE_CURSOS:
      return state.filter((curso) => curso.codCurso !== action.codCurso);

    case PURGE:
      return [];

    default:
      return state
  }
}
