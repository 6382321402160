import React, { useRef } from 'react';
import Button from './Button';

const InputImageByte = (props) => {
    const hiddenFileInput = useRef(null);

    const { image, handleChangeImage, multiple } = props

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }

    return (
        <>
            <input
                type="file"
                accept=".jpg,.jpeg,.png"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={handleChangeImage}
                multiple={multiple ? true : false}
            />
            {image ?
                <img style={{
                    width: '200px',
                }} src={image} alt='' />
                :
                <p>Nenhuma Imagem Selecionado</p>
            }
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    style={{
                        marginTop: '20px',
                        textTransform: 'none',
                    }}
                    onClick={handleAttachFile}
                >
                    Selecionar Imagem
                </Button>
            </div>
        </>
    );
}

export default InputImageByte;