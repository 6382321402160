import React from "react";
import { useState } from "react";
import Form from "../UI/Form";
import SwitchLabel from "../UI/SwitchLabel";
import TextField from "../UI/TextField";
import TextFieldDateTime from "../UI/TextFieldDateTime";
import TextFieldUsuario from "../Usuario/UI/TextFieldUsuario";
import UsuarioFindDialog from "../Usuario/UsuarioFindDialog";

const TarefaForm = ({
  tarefa,
  setTarefa,
  clickSubmit,
  handleRemove,
  isLoadingAction,
}) => {
  const [openSearchUsuario, setOpenSearchUsuario] = useState(false);

  const handleClose = () => {
    setTarefa(null);
  };

  const onChange = (event) => {
    console.log(event);
    setTarefa({
      ...tarefa,
      [event.target.name]: event.target.value,
    });
  };

  const handleBuscarUsuario = () => {
    setOpenSearchUsuario(true);
  };

  const handleDeleteUsuario = () => {
    setTarefa({
      ...tarefa,
      usuarioPara: null,
    });
  };

  const handleSelectedUsuario = (usuarioAdd) => {
    setTarefa({
      ...tarefa,
      usuarioPara: usuarioAdd,
    });
    closeUsuarioSearch();
  };

  const closeUsuarioSearch = () => {
    setOpenSearchUsuario(false);
  };

  const handleDateTimeChange = (dateTime) => {
    setTarefa({
      ...tarefa,
      dataHoraPrazo: dateTime,
    });
  };

  const handleSwitch = (name, value) => {
    setTarefa({
      ...tarefa,
      [name]: value,
    });
  };

  return (
    <>
      <UsuarioFindDialog
        openSearchUsuario={openSearchUsuario}
        handleSelectedUsuario={handleSelectedUsuario}
        closeUsuarioSearch={closeUsuarioSearch}
      />
      <Form
        clickSubmit={clickSubmit}
        handleClose={handleClose}
        handleRemove={handleRemove}
        isLoadingAction={isLoadingAction}
      >
        <TextField
          label="Descrição"
          onChange={onChange}
          name="descricao"
          value={tarefa && tarefa.descricao ? tarefa.descricao : ""}
        />

        <TextFieldUsuario
          label="Usuário Para"
          value={tarefa.usuarioPara ? tarefa.usuarioPara.email : ""}
          handleBuscarUsuario={handleBuscarUsuario}
          handleDeleteUsuario={handleDeleteUsuario}
        />
        <SwitchLabel
          handleSwitch={handleSwitch}
          value={tarefa.possuiPrazo}
          name="possuiPrazo"
          label="Possui Prazo"
        />
        {tarefa.possuiPrazo ? (
          <TextFieldDateTime
            handleDateTimeChange={handleDateTimeChange}
            label="Data Hora Prazo"
            value={tarefa.dataHoraPrazo ? tarefa.dataHoraPrazo : null}
          />
        ) : (
          ""
        )}
      </Form>
    </>
  );
};

export default TarefaForm;
