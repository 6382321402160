import React from 'react';
import TextField from './TextField';

const TextFieldReadOnly = (props) => {
    const {
        label,
        value
    } = props;
    return (
        <TextField
            label={label}
            fullWidth
            value={value}
            {...props}

            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export default TextFieldReadOnly;