import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AvaliacaoConteudoTableForm from "./AvaliacaoConteudoTableForm";
import AvaliacaoConteudoTable from "./AvaliacaoConteudoTable";
import TextForm from "../../Capitulo/Conteudo/componentsForm/TextoForm";
import ExerEssayForm from "../../Capitulo/Conteudo/componentsForm/ExerEssayForm";
import ExerAlternativeForm from "../../Capitulo/Conteudo/componentsForm/ExerAlternativeForm";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImagemForm from '../../Capitulo/Conteudo/componentsForm/ImagemForm';
import { create as createSecao, remove } from '../Secao/api-avaliacao-secao';
import { updateCriacaoFinalizada } from '../api-avaliacao';
import { ALUNO } from '../../Usuario/Perfil';
import { useSelector, useDispatch } from 'react-redux';
import { setSecao, addSecoes, removeSecoes } from '../Secao/actions/actions'
import { setAvaliacao } from '../actions/actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { confirmAlert } from 'react-confirm-alert';


const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
    },
    panelAddComponent: {
        border: 'solid 1px',
        padding: '20px',
        borderRadius: '10px'
    },
    btnComponent: {
        marginRight: '8px'
    },
    tabMenu: {
        marginTop: '20px'
    }
});

export default function AvaliacaoConteudoContainer(props) {
    const classes = useStyles();
    const [component, setComponent] = useState();
    const [conteudo, setConteudo] = useState();
    const mode = props.location.state?.mode;
    const avaliacao = useSelector(state => state.avaliacao);
    const usuario = useSelector(state => state.usuario);
    const [menuShow, setMenuShow] = useState(
        usuario &&
            (usuario.perfil === 'ADMIN' || usuario.perfil === 'COORDENADOR') &&
            mode !== 'correction' ?
            0 :
            1
    );
    const secao = useSelector(state => state.secao)
    const dispatch = useDispatch();
    const avaliacaoAluno = useSelector(state => state.avaliacaoAluno);

    useEffect(() => {
        switch (component) {
            case 'text':
                setConteudo(
                    <TextForm
                        conteudoSelected={{
                            secao: secao,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;

            case 'exerEssay':
                setConteudo(
                    <ExerEssayForm
                        conteudoSelected={{
                            secao: secao,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;

            case 'exerAlternative':
                setConteudo(
                    <ExerAlternativeForm
                        conteudoSelected={{
                            secao: secao,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;
            case 'image':
                setConteudo(
                    <ImagemForm
                        conteudoSelected={{
                            secao: secao,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;

            default:
                setConteudo(null);
        }
    }, [component, secao]);

    const handleComponentSelected = (value) => {
        setComponent(value);
    }

    const handleChange = (event, newValue) => {
        setMenuShow(newValue);
    };

    const handleNovaSecao = () => {
        createSecao(avaliacao.codigo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(setSecao(data));
                dispatch(addSecoes(data));
            }
        })
    }

    const handleCriacaoFinalizadaChange = () => {
        updateCriacaoFinalizada(avaliacao.codigo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(setAvaliacao(data));
            }
        })
    };

    const handleExcluirSecao = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta seção?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(secao.codigo).then((data) => {
                            if (data.error) {
                                console.log(data);
                            } else {
                                dispatch(removeSecoes(secao.codigo));
                                dispatch(setSecao(null));
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });



    }

    return (
        <div>
            {usuario &&
                (usuario.perfil === 'ADMIN' || usuario.perfil === 'COORDENADOR' || usuario.perfil === 'PROFESSOR') &&
                mode !== 'correction' ?
                <Grid className={classes.tabMenu} container justify="center">
                    <Grid item xs={6}>
                        <Paper className={classes.root}>
                            <Tabs
                                value={menuShow}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Editor" />
                                <Tab label="Pré-Visualização" />
                            </Tabs>
                        </Paper>
                    </Grid>
                </Grid> : ''
            }



            {menuShow === 0 ?
                <Container component={Paper} maxWidth="xl" className={classes.paper}>
                    <div className={classes.panelAddComponent}>
                        <Grid container justify='center' style={{ marginBottom: '20px' }}>
                            <Typography className={classes.title} variant="h6">
                                Adicionar Componente
                            </Typography>
                        </Grid>
                        <Grid container justify='center' style={{ marginBottom: '20px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size='small'
                                onClick={handleNovaSecao}
                                className={classes.btnComponent}>
                                Nova Seção
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                size='small'
                                onClick={handleExcluirSecao}
                                className={classes.btnComponent}>
                                Excluir Seção
                            </Button>
                            <div>
                                <Typography variant="body1">

                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={avaliacao && avaliacao.criacaoFinalizada ? true : false}
                                            onChange={handleCriacaoFinalizadaChange}
                                            value={avaliacao && avaliacao.criacaoFinalizada ? true : false}
                                        />
                                    }
                                    label="Criação Finalizada"
                                />
                            </div>
                        </Grid>
                        <Grid container justify='center'>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('text')}
                                className={classes.btnComponent}>
                                Texto
                            </Button>
                            {avaliacao && !avaliacao.esimulado ?
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleComponentSelected('exerEssay')}
                                    className={classes.btnComponent}
                                >
                                    Exercício Dissertativo
                                </Button> : ''
                            }

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('exerAlternative')}
                                className={classes.btnComponent}
                            >
                                Exercício Alternativa
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('image')}
                                className={classes.btnComponent}
                            >
                                Imagem
                            </Button>
                        </Grid>
                    </div>
                </Container>
                : ''}



            {conteudo ? conteudo :

                <>
                    {menuShow === 0 ? <AvaliacaoConteudoTableForm /> : ''}
                    <Container className={classes.paper} maxWidth="xl" component={Paper} >
                        {menuShow === 1 ?
                            <>
                                {usuario && usuario.perfil === ALUNO ?
                                    <>
                                        {avaliacaoAluno ? <AvaliacaoConteudoTable /> : ''}
                                    </> : <AvaliacaoConteudoTable />}

                            </>


                            : ''}
                    </Container>
                </>

            }



        </div >
    );
}