import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;',
        marginBottom: '20px'
    }
}));


export default function NotFoundError() {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.title} variant="h5">
                Página não encontrada...
            </Typography>

            <Typography className={classes.title} variant="h6">
                <Link to="/home">
                    Home
                </Link>
            </Typography>

        </div>
    );
}