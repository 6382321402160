import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByNome, findByCodigo } from './api-aluno';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { setAluno, close } from './actions/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));


export default function AlunoFindDialogRedux() {
    const classes = useStyles();
    const [alunos, setAlunos] = useState([]);
    const [opcoesBusca, setOpcoesBusca] = useState({
        por: 'nome',
        busca: ''
    });
    const dispatch = useDispatch();
    const openSearch = useSelector(state => state.openSearch);

    const clickSubmit = () => {
        if (opcoesBusca.por === 'nome') {
            findByNome(opcoesBusca.busca).then((data) => {
                setAlunos(data);
            }).catch((err) => console.log(err));
        } else if (opcoesBusca.por === 'codigo') {
            findByCodigo(opcoesBusca.busca).then((data) => {
                setAlunos(data);
            }).catch((err) => console.log(err));
        }
    }

    const handleBuscaChange = (event) => {
        setOpcoesBusca({
            ...opcoesBusca,
            [event.target.name]: event.target.value
        });

    }

    const handleSelectedAluno = (aluno) => {
        dispatch(setAluno(aluno));
        dispatch(close());
    }

    const handleClose = () => {
        dispatch(close());
    };

    return (
        <div>
            <CssBaseline />

            <Dialog open={openSearch} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Procurar Aluno</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Buscar por:</FormLabel>
                        <RadioGroup aria-label="gender" name="por" value={opcoesBusca.por} onChange={handleBuscaChange}>
                            <FormControlLabel value="codigo" control={<Radio />} label="Código" />
                            <FormControlLabel value="nome" control={<Radio />} label="Nome" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Busca"
                        name="busca"
                        autoFocus
                        onChange={handleBuscaChange}
                        value={opcoesBusca.busca || ''}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={clickSubmit}
                        className={classes.submit}>
                        Buscar
                    </Button>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Código</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alunos.map((aluno) => (
                                <TableRow key={aluno.codigo}>
                                    <TableCell>{aluno.codAlunoEscola}</TableCell>
                                    <TableCell>{aluno.nome}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => handleSelectedAluno(aluno)}
                                            variant="contained"
                                            color="secondary">
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}