import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {
  save as saveInCapitulo,
  remove as removeInCapitulo,
  uploadImagemExer as uploadImagemExerInCapitulo,
  deleteFileBucket as deleteFileBucketInCapitulo
} from '../api-conteudo';
import {
  save as saveInSecao,
  remove as removeInSecao,
  deleteFileBucket as deleteFileBucketInSecao,
  uploadImagemExer as uploadImagemExerInSecao
} from '../../../Avaliacao/Conteudo/api-avaliacao-conteudo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { confirmAlert } from 'react-confirm-alert';
import { removeConteudos, addConteudos, updateConteudos } from "../actions/actions";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { setSecao, updateSecoes } from '../../../Avaliacao/Secao/actions/actions';
import { setAvaliacao } from '../../../Avaliacao/actions/actions';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 650,
    marginTop: "20px",
    padding: '30px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  remove: {
    margin: theme.spacing(3, 3, 3),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  buscarBtn: {
    margin: theme.spacing(3, 3, 3),
  },
  closeIcon: {
    marginBottom: '20px',
    cursor: 'pointer'
  },
  title: {
    paddingBottom: '20px',
  },
  buttonsBox: {
    margin: '20px',
    backgroundColor: 'lavender',
    padding: '30px',
    borderRadius: '25px',
    display: 'inline-block'
  },
  loading: {
    marginRight: '10px',
    marginLeft: '10px'
  },
}));

export default function ExerEssayForm({
  conteudoSelected,
  setConteudoSelected,
  setComponent
}) {
  const classes = useStyles();
  const [conteudo, setConteudo] = useState(conteudoSelected);
  const dispatch = useDispatch();
  const [imagemFile, setImagemFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (value) => {
    setConteudo(prevState => ({
      ...prevState,
      questao: {
        ...prevState.questao,
        descricao: value,
      },
    }))
  }

  const handleChangeLargura = (event) => {
    setConteudo({
      ...conteudo,
      questao: {
        ...conteudo.questao,
        largura: event.target.value,
      },
    })
  }

  const handleUploadImage = (event) => {
    event.preventDefault();

    if (imagemFile === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append('imagemFile', imagemFile);
    setIsLoading(true);
    if (conteudo.secao) {
      uploadImagemExerInSecao(conteudo.codigo, fileFormData).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });
          dispatch(updateConteudos(data));
          setConteudo(data);
        }
        setIsLoading(false);
      })
    } else if (conteudo.capitulo) {
      uploadImagemExerInCapitulo(conteudo.codigo, fileFormData).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });
          dispatch(updateConteudos(data));
          setConteudo(data);
        }
        setIsLoading(false);
      })
    }


  }

  const handleChangeResposta = (value) => {
    setConteudo(prevState => ({
      ...prevState,
      questao: {
        ...prevState.questao,
        resposta: value,
      },
    }))
  }

  const clickSubmit = (event) => {
    event.preventDefault();
    if (conteudo.secao) {
      saveInSecao(conteudo).then(data => {
        if (data.error) {
          toast.error(data.error, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });

          if (conteudoSelected.eNovo) {
            dispatch(addConteudos(data));
            dispatch(setSecao(data.secao));
            dispatch(updateSecoes(data.secao));
            dispatch(setAvaliacao(data.secao.avaliacao));
            handleClose();
          } else {
            dispatch(updateConteudos(data));
          }
        }
      })
    } else if (conteudo.capitulo) {
      saveInCapitulo(conteudo).then(data => {
        if (data.error) {
          toast.error(data.error, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });

          if (conteudoSelected.eNovo) {
            dispatch(addConteudos(data));
            handleClose();
          } else {
            dispatch(updateConteudos(data));
          }
        }
      })
    }

  }

  const handleClose = () => {
    setConteudoSelected(null);
    setComponent(null);
  }

  const handleRemove = () => {
    confirmAlert({
      title: 'Alerta',
      message: 'Deseja deletar este conteúdo?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            if (conteudo.secao) {
              removeInSecao(conteudo.codigo).then((data) => {
                if (data.error) {
                  console.log(data);
                } else {
                  console.log(data);
                  dispatch(removeConteudos(conteudo.codigo));
                  dispatch(setSecao(data.secao));
                  dispatch(updateSecoes(data.secao));
                  dispatch(setAvaliacao(data.secao.avaliacao));
                  toast.success("Conteúdo deletado com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                  });
                }
              })
            } else if (conteudo.capitulo) {
              removeInCapitulo(conteudo.codigo).then((data) => {
                if (data) {
                  dispatch(removeConteudos(conteudo.codigo));
                  toast.success("Conteúdo deletado com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                  });
                }
              })
            }

          }
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  }

  const handleDeleteFileBucket = () => {
    confirmAlert({
      title: 'Alerta',
      message: 'Deseja deletar esta imagem?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            setIsLoading(true);
            if (conteudo.secao) {
              deleteFileBucketInSecao(conteudo.codigo).then((data) => {
                if (data) {
                  setConteudo(data);
                  setIsLoading(false);
                  toast.success("Arquivo deletado com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                  });
                }
              })
            } else if (conteudo.capitulo) {
              deleteFileBucketInCapitulo(conteudo.codigo).then((data) => {
                if (data) {
                  setConteudo(data);
                  setIsLoading(false);
                  toast.success("Arquivo deletado com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                  });
                }
              })
            }

          }
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  }

  const handleImagemFile = event => {
    setImagemFile(event.target.files[0]);
  }

  const handleChangePontos = (event) => {
    setConteudo({
      ...conteudo,
      pontos: event.target.value,
    })
  }


  return (
    <div>
      <Container className={classes.paper} maxWidth="xl" component={Paper} >
        {conteudo.eNovo ?
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleClose}
            fontSize='small'
          /> : ''}

        <Grid item >
          <form onSubmit={clickSubmit}>

            <Typography className={classes.title} variant="h6">
              Exercício Dissertativo
            </Typography>
            <Typography className={classes.title} variant="body1">
              Conteúdo
            </Typography>
            <SunEditor
              onChange={handleChange}
              setContents={conteudo && conteudo.questao && conteudo.questao.descricao ? conteudo.questao.descricao : ''}
              setOptions={{
                height: 200,
                buttonList: buttonList.complex
              }}
              setDefaultStyle="font-size: 18px;"
            />
            {!conteudo.eNovo ?
              <div style={{ margin: '20px' }}>
                {conteudo && conteudo.questao.imagemUrl ?
                  <>
                    <img
                      alt=''
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '20px'
                      }}
                      width={conteudo.questao.largura ? conteudo.questao.largura : 300}
                      src={conteudo.questao.imagemUrl}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Largura"
                      name="largura"
                      onChange={handleChangeLargura}
                      value={conteudo && conteudo.questao.largura ? conteudo.questao.largura : ''}
                    />
                    <Button
                      onClick={handleDeleteFileBucket}
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                      className={classes.submit}>
                      {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                      Remove Imagem
                    </Button>
                  </>

                  :
                  <div style={{
                    borderColor: 'black',
                    borderStyle: 'solid',
                    borderWidth: 'thick',
                    borderRadius: '20px',
                    paddingTop: '20px',
                    paddingLeft: '20px'
                  }}>
                    <div style={{ display: 'flex' }}>
                      Upload Imagem <input type="file" onChange={handleImagemFile} />
                    </div>
                    <Button
                      onClick={handleUploadImage}
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                      className={classes.submit}>
                      {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                      Upload Imagem
                    </Button>
                  </div>
                }

              </div> : ''}


            <Typography className={classes.title} variant="body1">
              Resposta
            </Typography>
            <SunEditor
              onChange={handleChangeResposta}
              setContents={conteudo && conteudo.questao && conteudo.questao.resposta ? conteudo.questao.resposta : ''}
              setOptions={{
                height: 200,
                buttonList: buttonList.complex
              }}
              setDefaultStyle="font-size: 18px;"
            />
            {conteudo.secao ?
              <>
                <Typography className={classes.title} variant="body1">
                  Pontos
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Pontos"
                  name="pontos"
                  onChange={handleChangePontos}
                  value={conteudo && conteudo.pontos ? conteudo.pontos : ''}
                />
              </>
              : ''}
            <div className={classes.buttonsBox}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}>
                Salvar
              </Button>
              {!conteudo.eNovo ?
                <Button
                  onClick={handleRemove}
                  variant="contained"
                  color="secondary"
                  className={classes.remove}
                >
                  Remover
                </Button> : ''}
            </div>
          </form>
        </Grid>
      </Container>
    </div >
  );
}