import { SERVER_URL } from "../../../constants"
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from "../../../util/FetchUtil";

const controller = "cronograma-data/"

export const findByTurmaCodigo = (codTurma) => {
    const url = SERVER_URL + controller + "query-list?codTurma=" + codTurma;
    return fetchGetAuth(url);
}

export const save = (cronogramaData) => {
    const url = SERVER_URL + controller + "save";
    return fetchPostAuth(url, cronogramaData);
}

export const deleteById = (codCronogramaData) => {
    const url = SERVER_URL + controller + "delete?codigo=" + codCronogramaData;
    return fetchRemoveAuthReturn(url);
}
