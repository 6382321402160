import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";

const TextFieldDate = (props) => {
    const { onChange, value, label } = props;
    return (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                value={value ? value : null}
                label={label}
                onChange={onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
}

export default TextFieldDate;