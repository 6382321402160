import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { ToastContainer } from 'react-toastify';
import ListItem from '@material-ui/core/ListItem';
import { findbyAvaliacaoCodigo } from '../../../components/Avaliacao/Secao/api-avaliacao-secao'
import { setSecao, setSecoes } from '../../../components/Avaliacao/Secao/actions/actions';

const drawerWidth = 150;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    iconMenu: {
        marginRight: '5px',
    },
    logo: {
        marginLeft: '10px',
        width: drawerWidth - 35,
    },
    secaoSelected: {
        backgroundColor: '#b5e7a4'
    }
}));

export default function LateralMenuSkeletonAvaliacaoSecao(props) {
    const classes = useStyles();
    const { child: Child } = props;
    const avaliacao = useSelector(state => state.avaliacao);
    const usuario = useSelector(state => state.usuario);
    const secao = useSelector(state => state.secao);
    const secoes = useSelector(state => state.secoes);
    const dispatch = useDispatch();

    useEffect(() => {
        if (avaliacao) {
            findbyAvaliacaoCodigo(avaliacao.codigo).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    dispatch(setSecoes(data));
                }
            })
        }

    }, [avaliacao, dispatch]);

    useEffect(() => {
        if (secoes.length > 0 && secao === null) {
            dispatch(setSecao(secoes[0]));
        }
    }, [dispatch, secoes, secao]);

    const handleSelectSecao = (secaoSelected) => {
        dispatch(setSecao(secaoSelected));
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Box display="block" displayprint="none">
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    displayprint="none"
                >

                    <div className={classes.toolbar} />

                    <Link to='/home'>
                        <img
                            src={usuario && usuario.instituicao ? usuario.instituicao.urlLogo : ''}
                            className={classes.logo}
                            alt=''
                        />
                    </Link>
                    <List>
                        {secoes.map((s, index) => {
                            return (
                                <ListItem
                                    onClick={() => handleSelectSecao(s)}
                                    button
                                    key={index}
                                    className={secao && s.codigo === secao.codigo ? classes.secaoSelected : ''}
                                >
                                    <span style={{ fontSize: 'smaller' }}>Seção: {s.ordem} - Quant. Perguntas: {s.quantPerguntas}</span>
                                </ListItem>
                            )
                        })}
                    </List>
                </Drawer>
            </Box>

            <main className={classes.content}>
                <ToastContainer autoClose={3000} />
                <div className={classes.toolbar} />
                <Child {...props} />
            </main>

        </div>
    );


}