import { SERVER_URL } from "../../../../constants";
import {
    fetchGetAuth,
    fetchPostAuth
} from "../../../../util/FetchUtil";

const controller = 'aluno-resposta/'

const findByCodConteudoAndCodTdp = (codConteudo, codTdp) => {
    const url = SERVER_URL + controller + 'correction/' + codConteudo + '/' + codTdp;
    console.log(url);
    return fetchGetAuth(url);
}

const findByConteudoCodigoAndAlunoCodigo = (codConteudo) => {
    const url = SERVER_URL + controller + codConteudo;
    return fetchGetAuth(url);
}

const save = (resposta) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, resposta);
}

const saveCorrection = (resposta) => {
    const url = SERVER_URL + controller + "correction";
    return fetchPostAuth(url, resposta);

}


export {
    findByCodConteudoAndCodTdp,
    findByConteudoCodigoAndAlunoCodigo,
    save,
    saveCorrection
}