import {
    SET_CONCEITO_ALUNO, 
    SET_CONCEITO_ALUNOS, 
    UPDATE_CONCEITO_ALUNOS, 
    REMOVE_CONCEITO_ALUNOS
} from './actionTypes'

export function setConceitoAluno(conceitoAluno){
    return {type : SET_CONCEITO_ALUNO, conceitoAluno}
}

export function setConceitoAlunos(conceitoAlunos){
    return {type : SET_CONCEITO_ALUNOS, conceitoAlunos}
}

export function updateConceitoAlunos(conceitoAluno){
    return {type : UPDATE_CONCEITO_ALUNOS, conceitoAluno}
}

export function removeConceitoAlunos(codConceitoAluno){
    return {type : REMOVE_CONCEITO_ALUNOS, codConceitoAluno}
}