import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'integral-presenca/';

const findByIntegralProfessorCodigo = (codProfessorIntegral, page) => {
  const url = SERVER_URL + controller + 'page/' + codProfessorIntegral + '?page=' + page;
  return fetchGetAuth(url);
}

const save = (integralPresenca) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, integralPresenca);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuthReturn(url);
}

export {
  findByIntegralProfessorCodigo,
  save,
  remove
}