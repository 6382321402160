import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { rltConteudo, rltMediaBimestralAno } from './api-relatorio';
import Countdown from 'react-countdown';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TDPComboBox from '../TDP/UI/TDPComboBox';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RltTDPForm({ tdps }) {
    const classes = useStyles();
    const [tdp, setTdp] = useState({});
    const [loading, setLoading] = useState({
        conteudo: {
            isVisible: false,
            timeLoading: 300000
        },
        mediaBimestralAno: {
            isVisible: false,
            timeLoading: 900000
        }
    })


    const clickConteudo = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            conteudo: {
                ...loading.conteudo,
                isVisible: true
            }
        });
        if (tdp) {
            rltConteudo(tdp.codigo).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        conteudo: {
                            ...loading.conteudo,
                            isVisible: false
                        }
                    });
                }
            })
        }

    }

    const clickMediaBimestralAno = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            mediaBimestralAno: {
                ...loading.mediaBimestralAno,
                isVisible: true
            }
        });
        if (tdp) {
            rltMediaBimestralAno(tdp.codigo).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        mediaBimestralAno: {
                            ...loading.mediaBimestralAno,
                            isVisible: false
                        }
                    });
                }
            })
        }
    }

    const handleTDPChange = (event) => {
        const tdp = tdps.find((t) => t.codigo === event.target.value);
        setTdp(tdp);
    }

    return (
        <div>
            <CssBaseline />
            <Container component={Paper} >
                <Typography className={classes.title} variant="body1">
                    Por Turma/Disciplina
                </Typography>
                <form>
                    <TDPComboBox tdps={tdps} tdp={tdp} handleTDPChange={handleTDPChange} />

                    <Button
                        component={Link}
                        to={{
                            pathname: '/confere-registro-aula-falta',
                            state: {
                                tdp: tdp
                            }
                        }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Confere Registro Aulas e Faltas
                    </Button>

                    {loading.conteudo.isVisible ?
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <CircularProgress
                                        className={classes.loading}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography className={classes.title} variant="h5">
                                        <Countdown date={Date.now() + loading.conteudo.timeLoading}>
                                            Carregando Relatório
                                        </Countdown>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </> :
                        <>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={clickConteudo}
                            >
                                Conteúdo
                            </Button>

                        </>
                    }
                    {loading.mediaBimestralAno.isVisible ?
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <CircularProgress
                                        className={classes.loading}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography className={classes.title} variant="h5">
                                        <Countdown date={Date.now() + loading.mediaBimestralAno.timeLoading}>
                                            Carregando Relatório
                                        </Countdown>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickMediaBimestralAno}
                        >
                            Média Bimestral Ano
                        </Button>
                    }

                </form>
            </Container>
        </div>
    );
}