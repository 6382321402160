import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'avaliacao/';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

const updateCriacaoFinalizada = (codAvaliacao) => {
  const url = SERVER_URL + controller + 'criacao-finalizada/' + codAvaliacao;
  return fetchGetAuth(url);
}

const findSimulados = () => {
  const url = SERVER_URL + controller + 'simulados';
  return fetchGetAuth(url);
}

const pageUser = (pageNumber) => {
  const url = SERVER_URL + controller + 'page-user?page=' + pageNumber;
  return fetchGetAuth(url);
}

const remove = (codAvaliacao) => {
  const url = SERVER_URL + controller + codAvaliacao;
  return fetchRemoveAuth(url);
}

const save = (avaliacao) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, avaliacao);
}

export {
  page,
  pageUser,
  save,
  remove,
  findSimulados,
  updateCriacaoFinalizada
}