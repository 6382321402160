
import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BimestreTableSelect from '../../Bimestre/BimestreTableSelect';
import TDPTableSelect from '../../TDP/TDPTableSelect'
import CronogramaQuantAulaDiaTable from './CronogramaQuantAulaDiaTable';
import { page } from './api-cronograma-quant-aula-dia';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import CronogramaQuantAulaDiaForm from './CronogramaQuantAulaDiaForm';
import { save, deleteById } from './api-cronograma-quant-aula-dia';
import { confirmAlert } from 'react-confirm-alert';
import TurmaDisciplinaProfessorProfile from '../../TDP/TurmaDisciplinaProfessorProfile';
import BimestreProfile from '../../Bimestre/BimestreProfile';

const useStyles = makeStyles({
    container: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '10px',
    }
})

const CronogramaQuantAulaDia = () => {
    const classes = useStyles();
    const [list, setList] = useState({
        content: [],
        size: 0,
        totalElements: 0
    })
    const [tdp, setTdp] = useState(null);
    const [bimestre, setBimestre] = useState(null);
    const [cqad, setCqad] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (tdp && tdp.codigo && bimestre && bimestre.codigo) {
            setIsLoading(true);
            page(0, tdp.codigo, bimestre.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.error);
                } else {
                    setList(data);
                }
                setIsLoading(false);
            });
        }

    }, [tdp, bimestre]);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(newPage, tdp.codigo, bimestre.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.error);
            } else {
                setList(data);
            }
            setIsLoading(false);
        });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingAction(true);
        save(cqad).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                if (cqad.eNovo) {
                    setList((prevState) => ({
                        ...prevState,
                        content: [
                            data,
                            ...prevState.content

                        ]
                    }))
                } else {
                    setList((prevState) => ({
                        ...prevState,
                        content: prevState.content.map((content) => {
                            if (content.codigo === cqad.codigo) {
                                return data;
                            } else {
                                return content;
                            }
                        })
                    }))
                }
            }
            setIsLoadingAction(false);
        })
    }

    const handleRemove = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este Cronograma Quant. Aula Dia?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        deleteById(cqad.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setList((prevState) => ({
                                    ...prevState,
                                    content: prevState.content.filter((content) => content.codigo !== cqad.codigo)
                                }))
                                setCqad(null);
                            }
                            setIsLoadingAction(false);
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleNewRegister = () => {
        setCqad({
            eNovo: true,
            tdp: tdp
        })
    }

    return (
        <Container>
            <Grid className={classes.container} container spacing={3} component={Paper}>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="body1">
                        Cronograma Quantidade Aula Dia
                    </Typography>
                </Grid>
                {tdp ?
                    <>
                        <Grid item>
                            <TurmaDisciplinaProfessorProfile
                                tdp={tdp}
                                onTdpSelected={setTdp}
                            />
                        </Grid>
                        {bimestre ?
                            <>
                                <Grid item>
                                    <BimestreProfile
                                        bimestre={bimestre}
                                        setBimestre={setBimestre}
                                    />
                                </Grid>
                                {cqad ?
                                    <Grid item xs={12}>

                                        <CronogramaQuantAulaDiaForm
                                            cqad={cqad}
                                            setCqad={setCqad}
                                            clickSubmit={clickSubmit}
                                            handleRemove={handleRemove}
                                            isLoadingAction={isLoadingAction}
                                        />
                                    </Grid> :
                                    <Grid item xs={12}>
                                        <CronogramaQuantAulaDiaTable
                                            list={list}
                                            setCqad={setCqad}
                                            isLoading={isLoading}
                                            handleChangePage={handleChangePage}
                                            handleNewRegister={handleNewRegister}
                                        />
                                    </Grid>
                                }

                            </> :
                            <Grid item xs={12}>
                                <BimestreTableSelect
                                    setBimestre={setBimestre}
                                    serie={tdp.turma.serie}
                                />
                            </Grid>
                        }
                    </> :
                    <Grid item xs={12}>
                        <TDPTableSelect setTdp={setTdp} />
                    </Grid>
                }

            </Grid>
        </Container>
    )
}

export default CronogramaQuantAulaDia;