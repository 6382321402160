import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import CronogramaProfessorTablePorDiaSemana from "./CronogramaCurrentProfessorTablePorDiaSemana";
import CronogramaProfessorTableSemanaToda from "./CronogramaCurrentProfessorTableSemanaToda";

const CronogramaCurrentProfessorContainer = () => {
  const [menu, setMenu] = useState(0);

  return (
    <Container component={Paper}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">Cronograma</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row">
        <Grid item>
          <Button onClick={() => setMenu(0)}>Por Dia da Semana</Button>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Button onClick={() => setMenu(1)}>Semana Toda</Button>
        </Grid>
      </Grid>
      {menu === 0 ? (
        <CronogramaProfessorTablePorDiaSemana />
      ) : (
        <CronogramaProfessorTableSemanaToda />
      )}
    </Container>
  );
};

export default CronogramaCurrentProfessorContainer;
