import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { dateFormat } from '../../../util/DateUtil';
import { currencyRound } from '../../../util/DoubleUtil';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
});

const LancamentoTableAluno = ({ lancamentos }) => {
    const classes = useStyles();

    return (
        <>
            {lancamentos.length > 0 ?
                < Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.rowHeader}>Ano Letivo</TableCell>
                            <TableCell className={classes.rowHeader}>Cód. Doc.</TableCell>
                            <TableCell className={classes.rowHeader}>Data Emissão</TableCell>
                            <TableCell className={classes.rowHeader}>Data Vencimento</TableCell>
                            <TableCell className={classes.rowHeader}>Histórico</TableCell>
                            <TableCell className={classes.rowHeader}>Valor</TableCell>
                            <TableCell className={classes.rowHeader}>Data Desconto</TableCell>
                            <TableCell className={classes.rowHeader}>Valor Desconto</TableCell>
                            <TableCell className={classes.rowHeader}>Data Pagamento</TableCell>
                            <TableCell className={classes.rowHeader}>Valor Pago</TableCell>
                            <TableCell className={classes.rowHeader}>Código de Barras</TableCell>
                            <TableCell className={classes.rowHeader}>Forma Pago</TableCell>
                            <TableCell className={classes.rowHeader}>Quitado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lancamentos.map((lancamento) => (
                            <TableRow key={lancamento.codigo}>
                                <TableCell>{lancamento.anoLetivo}</TableCell>
                                <TableCell>{lancamento.codDoc}</TableCell>
                                <TableCell>{dateFormat(lancamento.dataEmissao)}</TableCell>
                                <TableCell>{dateFormat(lancamento.dataVencimento)}</TableCell>
                                <TableCell>{lancamento.historicoLancamento.descricao}</TableCell>
                                <TableCell>{currencyRound(lancamento.valor)}</TableCell>
                                <TableCell>{lancamento.dataDesconto ? dateFormat(lancamento.dataDesconto) : '--'}</TableCell>
                                <TableCell>{lancamento.valorDesconto ? currencyRound(lancamento.valorDesconto) : '--'}</TableCell>
                                <TableCell>{lancamento.dataPagamento ? dateFormat(lancamento.dataPagamento) : '--'}</TableCell>
                                <TableCell>{lancamento.valorPago ? currencyRound(lancamento.valorPago) : '--'}</TableCell>
                                <TableCell>{lancamento.codigoBarras ? lancamento.codigoBarras : '--'}</TableCell>
                                <TableCell>{lancamento.formaPago ? lancamento.formaPago : '--'}</TableCell>
                                <TableCell>{lancamento.quitado ? 'Sim' : 'Não'}</TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table> : ''
            }

        </>
    );
}

export default LancamentoTableAluno;