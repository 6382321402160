import React from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Button from '../UI/Button';
import { ativar } from './api-cardapio';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const CardapioTable = ({ cardapios, setCardapio, setCardapios }) => {
    const classes = useStyles();


    const handleSelecionarCardapio = (c) => {
        setCardapio(c);
    }

    const handleAtivarCardapio = (c) => {
        ativar(c.codigo).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setCardapios((prevState) => (
                    prevState.map(ps => {
                        if (ps.codigo === c.codigo) {
                            return data
                        } else {
                            return {
                                ...ps,
                                ativo: false
                            }
                        }
                    })
                ))
            }
        })

    }

    return (
        <>
            {
                cardapios.length > 0 ?
                    <>
                        < Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>Descrição</TableCell>
                                    <TableCell className={classes.rowHeader}>Ativo</TableCell>
                                    <TableCell className={classes.rowHeader}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cardapios.map((c) => (
                                    <TableRow key={c.codigo}>
                                        <TableCell>{c.descricao}</TableCell>
                                        <TableCell>
                                            {c.ativo ?
                                                'Ativo' :
                                                <Button onClick={() => handleAtivarCardapio(c)}>Ativar</Button>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSelecionarCardapio(c)}>Selecionar</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </>

                    :
                    <Grid>
                        <Typography className={classes.title} variant="h6">
                            Nenhum Registro Cadastrado
                        </Typography>
                    </Grid>
            }

        </>
    );
}

export default CardapioTable;