import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {
  save as saveInCapitulo,
  remove as removeInCapitulo
} from '../api-conteudo';
import {
  save as saveInSecao,
  remove as removeInSecao,
} from '../../../Avaliacao/Conteudo/api-avaliacao-conteudo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { confirmAlert } from 'react-confirm-alert';
import { removeConteudos, addConteudos, updateConteudos } from "../actions/actions";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { errorMessage } from '../../../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 650,
    marginTop: "20px",
    padding: '30px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  remove: {
    margin: theme.spacing(3, 3, 3),
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
  buscarBtn: {
    margin: theme.spacing(3, 3, 3),
  },
  closeIcon: {
    marginBottom: '20px',
    cursor: 'pointer'
  },
  title: {
    paddingBottom: '20px',
  },
  buttonsBox: {
    margin: '20px',
    backgroundColor: 'lavender',
    padding: '30px',
    borderRadius: '25px',
    display: 'inline-block'
  }
}));

export default function TextoForm({
  conteudoSelected,
  setConteudoSelected,
  setComponent
}) {
  const classes = useStyles();
  const [conteudo, setConteudo] = useState({
    ...conteudoSelected
  });
  const dispatch = useDispatch();
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const handleChange = (value) => {
    setConteudo((prevState) => ({
      ...prevState,
      texto: {
        ...prevState.texto,
        descricao: value
      }
    }));
  }

  const handleChangeSwitch = () => {
    let saveObject = {
      ...conteudo,
      texto: {
        ...conteudo.texto,
        orientacaoDidatica: !conteudo.texto.orientacaoDidatica
      }
    }
    if (conteudo.secao) {
      saveInSecao(saveObject).then(data => {
        if (data.error) {
          toast.error(data.error, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        } else {
          dispatch(updateConteudos(data));
          setConteudo(data)

        }
      })
    } else {
      saveInCapitulo(saveObject).then(data => {
        if (data.error) {
          toast.error(data.error, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        } else {
          dispatch(updateConteudos(data));
          setConteudo(data)

        }
      })
    }

  }

  const clickSubmit = (event) => {
    event.preventDefault();
    setIsLoadingAction(true);
    if (conteudo.secao) {
      saveInSecao(conteudo).then(data => {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });

          if (conteudoSelected.eNovo) {
            dispatch(addConteudos(data));
            handleClose();
          } else {
            dispatch(updateConteudos(data));
          }
          setIsLoadingAction(false);
        }
      })
    } else {
      saveInCapitulo(conteudo).then(data => {
        if (data.error) {
          errorMessage(data.message)
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });

          if (conteudoSelected.eNovo) {
            dispatch(addConteudos(data));
            handleClose();
          } else {
            dispatch(updateConteudos(data));
          }
          setIsLoadingAction(false);
        }
      })
    }

  }

  const handleClose = () => {
    setConteudoSelected(null);
    setComponent(null);
  }

  const handleRemove = () => {
    confirmAlert({
      title: 'Alerta',
      message: 'Deseja deletar este conteúdo?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            setIsLoadingAction(true);
            if (conteudo.secao) {
              removeInSecao(conteudo.codigo).then((data) => {
                dispatch(removeConteudos(conteudo.codigo));
                toast.success("Conteúdo deletado com sucesso.", {
                  position: toast.POSITION.BOTTOM_LEFT
                });
                setIsLoadingAction(true);
              })
            } else {
              removeInCapitulo(conteudo.codigo).then((data) => {
                dispatch(removeConteudos(conteudo.codigo));
                toast.success("Conteúdo deletado com sucesso.", {
                  position: toast.POSITION.BOTTOM_LEFT
                });
                setIsLoadingAction(true);
              })
            }

          }
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  }

  return (
    <div>
      <Container className={classes.paper} maxWidth="xl" component={Paper} >
        {conteudo.eNovo ?
          <CloseIcon
            className={classes.closeIcon}
            fontSize='small'
            onClick={handleClose} /> :
          ''
        }

        <Grid item >
          <form onSubmit={clickSubmit}>

            <Typography className={classes.title} variant="h6">
              Texto
            </Typography>
            <SunEditor
              onChange={handleChange}
              setContents={conteudo && conteudo.texto && conteudo.texto.descricao ? conteudo.texto.descricao : ''}
              setOptions={{
                height: 500,
                buttonList: buttonList.complex
              }}
              setDefaultStyle="font-size: 18px;"
            />
            <div className={classes.buttonsBox}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoadingAction}
              >
                Salvar
              </Button>
              {!conteudo.eNovo ?
                <>
                  <Button
                    onClick={handleRemove}
                    variant="contained"
                    color="secondary"
                    className={classes.remove}
                    disabled={isLoadingAction}
                  >
                    Remover
                  </Button>
                  {conteudo && !conteudo.capitulo.livro.isPublic &&
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Opção</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={<Switch checked={conteudo.texto && conteudo.texto.orientacaoDidatica ? conteudo.texto.orientacaoDidatica : false}
                            onChange={handleChangeSwitch} name="gilad" />}
                          label="Orientação Pedagógica"
                        />
                      </FormGroup>
                      <FormHelperText>Se ativado será visto apenas pelo professor</FormHelperText>
                    </FormControl>
                  }

                </>
                : ''}

            </div>
          </form>
        </Grid>
      </Container>
    </div>
  );
}