import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { noteRound } from "../../../../util/DoubleUtil";


export default function RelatorioBoletimNotaBimestreWeb({ boletimEscolar }) {

    return (
        <>
            <Typography
                style={{
                    textAlign: 'center',
                    textDecoration: 'underline'
                }}
                variant="h6">
                Base Comum
            </Typography>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2}>Disciplinas</TableCell>
                        <TableCell colSpan={3}>1º Bimestre</TableCell>
                        <TableCell colSpan={3}>2º Bimestre</TableCell>
                        <TableCell colSpan={3}>3º Bimestre</TableCell>
                        <TableCell colSpan={3}>4º Bimestre</TableCell>
                        <TableCell rowSpan={2}>Média Final </TableCell>
                        <TableCell rowSpan={2}>Recuperação Intensiva</TableCell>
                        <TableCell rowSpan={2}>Frequência(%)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Nota</TableCell>
                        <TableCell>Falta</TableCell>
                        <TableCell>AC</TableCell>
                        <TableCell>Nota</TableCell>
                        <TableCell>Falta</TableCell>
                        <TableCell>AC</TableCell>
                        <TableCell>Nota</TableCell>
                        <TableCell>Falta</TableCell>
                        <TableCell>AC</TableCell>
                        <TableCell>Nota</TableCell>
                        <TableCell>Falta</TableCell>
                        <TableCell>AC</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {boletimEscolar.bnotaBimestres.map((bnb, index) => (
                        <TableRow key={index}>
                            <TableCell>{bnb.tdp.disciplina.descricao}</TableCell>
                            <TableCell>{bnb.mediaBimestral1 && bnb.mediaBimestral1.nota ? noteRound(bnb.mediaBimestral1.nota) : '--'}</TableCell>
                            <TableCell>{bnb.totalFaltas1 ? bnb.totalFaltas1 : '--'}</TableCell>
                            <TableCell>{bnb.ausenciaCompensada1 ? bnb.ausenciaCompensada1 : '--'}</TableCell>
                            <TableCell>{bnb.mediaBimestral2 && bnb.mediaBimestral2.nota ? noteRound(bnb.mediaBimestral2.nota) : '--'}</TableCell>
                            <TableCell>{bnb.totalFaltas2 ? bnb.totalFaltas2 : '--'}</TableCell>
                            <TableCell>{bnb.ausenciaCompensada2 ? bnb.ausenciaCompensada2 : '--'}</TableCell>
                            <TableCell>{bnb.mediaBimestral3 && bnb.mediaBimestral3.nota ? noteRound(bnb.mediaBimestral3.nota) : '--'}</TableCell>
                            <TableCell>{bnb.totalFaltas3 ? bnb.totalFaltas3 : '--'}</TableCell>
                            <TableCell>{bnb.ausenciaCompensada3 ? bnb.ausenciaCompensada3 : '--'}</TableCell>
                            <TableCell>{bnb.mediaBimestral4 && bnb.mediaBimestral4.nota ? noteRound(bnb.mediaBimestral4.nota) : '--'}</TableCell>
                            <TableCell>{bnb.totalFaltas4 ? bnb.totalFaltas4 : '--'}</TableCell>
                            <TableCell>{bnb.ausenciaCompensada4 ? bnb.ausenciaCompensada4 : '--'}</TableCell>
                            <TableCell>{bnb.quintoConceito && bnb.quintoConceito.nota ? noteRound(bnb.quintoConceito.nota) : '--'}</TableCell>
                            <TableCell>{bnb.recuperacaoIntensiva && bnb.recuperacaoIntensiva.nota ? noteRound(bnb.recuperacaoIntensiva.nota) : '--'}</TableCell>
                            <TableCell>{bnb.frequencia}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}