import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import UsuarioFindDialogRedux from "./UsuarioFindDialogRedux";
import { importFile, importFileResponsaveis } from "./api-usuario";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    container: {
        padding: '20px',
    },
    title: {
        padding: '20px',
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});


export default function UsuarioImport() {
    const classes = useStyles();
    const [fileUsers, setFileUsers] = useState();
    const [message, setMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleFileSelected = event => {
        setFileUsers(event.target.files[0]);
    }

    const handleUpload = () => {
        const fileFormData = new FormData();
        fileFormData.append('file', fileUsers);
        setIsLoading(true);
        importFile(fileFormData).then((data) => {
            if (data) {
                setMessage(data.message);
                setIsLoading(false);
            }
        })
    }

    const handleUploadResponsaveis = () => {
        const fileFormData = new FormData();
        fileFormData.append('file', fileUsers);
        setIsLoading(true);
        importFileResponsaveis(fileFormData).then((data) => {
            if (data) {
                setMessage(data.message);
                setIsLoading(false);
            }
        })
    }

    return (
        <div>
            <CssBaseline />
            <UsuarioFindDialogRedux />
            <Container className={classes.container} component={Paper}>
                <Typography className={classes.title} variant="h5">
                    Importar Usuários
                </Typography>
                <Grid container justify="space-around">
                    <input type="file" onChange={handleFileSelected} />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        disabled={isLoading}
                    >
                        {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Fazer Upload
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUploadResponsaveis}
                        disabled={isLoading}
                    >
                        {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Fazer Upload Responsáveis
                    </Button>

                </Grid>
                <Typography className={classes.title} variant="h5">
                    {message}
                </Typography>
            </Container>

        </div>
    );
}