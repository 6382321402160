import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { findByAvaliacaoCodigo, save, gerarNota } from './api-avaliacao-aluno';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { setLista, update } from './actions/actions';
import { setAvaliacaoAluno } from './actions/actions';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AvaliacaoAlunoPorAvaliacaoTableGroup() {
    const classes = useStyles();
    const avaliacao = useSelector(state => state.avaliacao);
    const avaliacoesAluno = useSelector(state => state.listaAvaliacaoAluno)
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (avaliacao) {
            findByAvaliacaoCodigo(avaliacao.codigo, 0).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    dispatch(setLista(data));
                }
            })
        }

    }, [avaliacao, dispatch]);

    const handleChangePage = (event, newPage) => {
        findByAvaliacaoCodigo(avaliacao.codigo, newPage).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(setLista(data));
            }
        })
    };

    const handleGoToAvaliacaoResultado = (avaliacaoAluno) => {
        dispatch(setAvaliacaoAluno(avaliacaoAluno));
        if (avaliacao.esimulado) {
            history.push({
                pathname: '/avaliacao-resultado',
            })
        } else {
            history.push({
                pathname: '/avaliacao-secao',
                state: {
                    mode: 'correction'
                }
            })
        }

    }

    const handleGerarNota = (avaliacaoAluno) => {
        gerarNota(avaliacaoAluno.codigo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(update(data));
            }
        })

    }


    const handleProvaFinalizadaSwitchChange = avaliacaoAluno => event => {
        let persist = {
            ...avaliacaoAluno,
            provaFinalizada: event.target.checked
        }
        save(persist).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(update(data));
            }
        })
    };


    return (
        <div>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nome Aluno</TableCell>
                        <TableCell>Finalizada</TableCell>
                        <TableCell>Quant. Acertos</TableCell>
                        <TableCell>Nota</TableCell>
                        <TableCell>{avaliacao && avaliacao.esimulado ? 'Detalhes' : 'Respostas'}</TableCell>
                        <TableCell>Gerar Nota</TableCell>
                        <TableCell>Ações</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {avaliacoesAluno.content.map((aa) => (
                        <TableRow key={aa.codigo}>
                            <TableCell>{aa.aluno.nome}</TableCell>
                            <TableCell>
                                <Switch
                                    checked={aa && aa.provaFinalizada ? true : false}
                                    onChange={handleProvaFinalizadaSwitchChange(aa)}
                                    value={aa && aa.provaFinalizada ? true : false}
                                />
                            </TableCell>
                            <TableCell>{aa.quantAcertos}</TableCell>
                            <TableCell>{aa.nota}</TableCell>
                            <TableCell>
                                <Button
                                    size='small'
                                    onClick={() => handleGoToAvaliacaoResultado(aa)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    size='small'
                                    onClick={() => handleGerarNota(aa)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    color="secondary"
                                    style={{ marginRight: '10px' }}
                                    className={classes.cursorLink}
                                    onClick={() => dispatch(setAvaliacaoAluno(aa))}
                                />
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={avaliacoesAluno.size ? avaliacoesAluno.size : 0}
                component="div"
                count={avaliacoesAluno.totalElements}
                page={avaliacoesAluno.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}