import {
    ADD_LIVROS_AUTOR,
    UPDATE_LIVROS_AUTOR,
    SET_LIVROS_AUTOR,
    REMOVE_LIVROS_AUTOR,
    SET_LIVRO_AUTOR,
    OPEN_LIVRO_AUTOR,
    CLOSE_LIVRO_AUTOR
} from './actionTypes'

export function addLivrosAutor(livroAutor) {
    return { type: ADD_LIVROS_AUTOR, livroAutor }
}

export function updateLivrosAutor(livroAutor) {
    return { type: UPDATE_LIVROS_AUTOR, livroAutor }
}

export function setLivrosAutor(livrosAutor) {
    return { type: SET_LIVROS_AUTOR, livrosAutor }
}

export function removeLivrosAutor(index) {
    return { type: REMOVE_LIVROS_AUTOR, index }
}

export function setLivroAutor(livroAutor) {
    return { type: SET_LIVRO_AUTOR, livroAutor }
}

export function openLivroAutor() {
    return { type: OPEN_LIVRO_AUTOR }
}

export function closeLivroAutor() {
    return { type: CLOSE_LIVRO_AUTOR }
}