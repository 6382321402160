import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import AlunoFindDialog from '../AlunoFindDialog';

const useStyles = makeStyles({
    alunoTextField: {
        width: '100%',
        marginTop: 15
    },
    buscarAlunoButton: {
        marginTop: 15
    }
})




const AlunoTextFieldSelect = ({ aluno, setAluno }) => {
    const classes = useStyles();
    const [openSearch, setOpenSearch] = useState(false);


    const handleAdicionarAluno = () => {
        setOpenSearch(true);
    }

    return (
        <Grid container spacing={2} >
            <AlunoFindDialog
                openSearch={openSearch}
                setOpenSearch={setOpenSearch}
                setAluno={setAluno}
            />
            <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
            >
                <TextField
                    className={classes.alunoTextField}
                    variant="outlined"
                    size="small"
                    label="Aluno"
                    name="aluno"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={aluno ? aluno.nome : ''}
                />
            </Grid>
            <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
            >
                <Button
                    className={classes.buscarAlunoButton}
                    variant="contained"
                    onClick={handleAdicionarAluno}
                    color="primary">
                    Buscar Aluno
                </Button>
            </Grid>

        </Grid>
    );
}

export default AlunoTextFieldSelect;