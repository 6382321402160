import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import ObservacaoTurmaForm from './ObservacaoTurmaForm';
import ObservacaoTurmaTable from './ObservacaoTurmaTable';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
});

export default function ObservacaoTurmaContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const observacaoTurma = useSelector(state => state.observacaoTurma);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h5">
                    Observação Turma
                </Typography>

                {tdp ?
                    <>
                        <TurmaDisciplinaProfessorProfileRedux />
                        {observacaoTurma ? <ObservacaoTurmaForm /> : <ObservacaoTurmaTable />}

                    </> :
                    <TDPTableSelectRedux />
                }
            </Container>

        </div>
    );
}