import {
    ADD_OBSERVACOES_ALUNO,
    UPDATE_OBSERVACOES_ALUNO,
    SET_OBSERVACOES_ALUNO,
    REMOVE_OBSERVACOES_ALUNO,
    SET_OBSERVACAO_ALUNO,
    SET_OBSERVACAO_ALUNO_NULL
} from './actionTypes'

export function addObservacoesAluno(observacaoAluno) {
    return { type: ADD_OBSERVACOES_ALUNO, observacaoAluno }
}

export function updateObservacoesAluno(observacaoAluno) {
    return { type: UPDATE_OBSERVACOES_ALUNO, observacaoAluno }
}

export function setObservacoesAluno(observacoesAluno) {
    return { type: SET_OBSERVACOES_ALUNO, observacoesAluno }
}

export function removeObservacoesAluno(codigo) {
    return { type: REMOVE_OBSERVACOES_ALUNO, codigo }
}

export function setObservacaoAluno(observacaoAluno) {
    return { type: SET_OBSERVACAO_ALUNO, observacaoAluno }
}

export function setObservacaoAlunoNull() {
    return { type: SET_OBSERVACAO_ALUNO_NULL }
}