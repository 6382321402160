import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuthReturn
} from '../../util/FetchUtil';

const controller = 'local/';

export const page = (numberPage) => {
  const url = SERVER_URL + controller + "page?page=" + numberPage;
  return fetchGetAuth(url);
}

export const save = (local) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, local);
}

export const remove = (codLocal) => {
  const url = SERVER_URL + controller + codLocal;
  return fetchRemoveAuthReturn(url);
}

export const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}