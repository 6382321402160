import {
    ADD_SECOES,
    UPDATE_SECOES,
    SET_SECOES,
    REMOVE_SECOES,
    SET_SECAO
} from './actionTypes'

export function addSecoes(secao) {
    return { type: ADD_SECOES, secao }
}

export function updateSecoes(secao) {
    return { type: UPDATE_SECOES, secao }
}

export function setSecoes(secoes) {
    return { type: SET_SECOES, secoes }
}

export function removeSecoes(codSecao) {
    return { type: REMOVE_SECOES, codSecao }
}

export function setSecao(secao) {
    return { type: SET_SECAO, secao }
}