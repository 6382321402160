import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createUpdateByCodTdp, findByTdpCodigoAndNumeroBimestre } from '../api-aluno-frequencia';
import Button from '@material-ui/core/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setAlunosFrequencia, setAlunoFrequencia } from '../actions/actions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { ADMIN } from '../../Usuario/Perfil';
import { Menu } from '@material-ui/core';
import { createUpdateByCodTurma } from '../api-aluno-frequencia';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import LoadingDialog from '../../UI/LoadingDialog';
import Loading from '../../UI/Loading';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    container: {
        marginTop: '20px',
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AusenciaCompensadaTable() {
    const classes = useStyles();
    const alunosFrequencia = useSelector(state => state.alunosFrequencia)
    const tdp = useSelector(state => state.tdp);
    const [numeroBimestre, setNumeroBimestre] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const usuario = useSelector(state => state.usuario);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findByTdpCodigoAndNumeroBimestre(tdp.codigo, numeroBimestre).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(setAlunosFrequencia(data));
                dispatch(setAlunoFrequencia(null));
            }
            setIsLoading(false);
        })
    }, [dispatch, tdp.codigo, numeroBimestre]);

    const handleBimestreChange = (event) => {
        setNumeroBimestre(event.target.value);
    }


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCreateUpdateByCodTurma = (codTurma) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja criar/atualizar os registros de aluno freq. da turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        createUpdateByCodTurma(codTurma).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setOpenDialog(false);
                                toast.success("Operação realizada com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }
    const handleCreateUpdateByCodTdp = (codTdp) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja criar/atualizar os registros de aluno freq. da turma - disciplina?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        createUpdateByCodTdp(codTdp).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setOpenDialog(false);
                                toast.success("Operação realizada com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <div className={classes.container}>
            <LoadingDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                title="Aluno Frequência"
                description='Criando/Atualizando'
            />
            {isLoading ?
                <Loading description='Carregando Aluno Freq...' />
                :
                <>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid style={{ marginBottom: '20px' }} item >
                            <FormControl >
                                <InputLabel id="bimestre-label">Bimestre</InputLabel>
                                <Select
                                    labelId="bimestre-label"
                                    id="bimestre-select"
                                    value={numeroBimestre}
                                    onChange={handleBimestreChange}
                                >
                                    <MenuItem value={1}>1º Bimestre</MenuItem>
                                    <MenuItem value={2}>2º Bimestre</MenuItem>
                                    <MenuItem value={3}>3º Bimestre</MenuItem>
                                    <MenuItem value={4}>4º Bimestre</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {usuario.perfil === ADMIN ?
                                <>
                                    <MenuIcon
                                        fontSize='medium'
                                        className={classes.cursorLink}
                                        onClick={(event) => handleMenuClick(event)}
                                        color="secondary"

                                    />
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem
                                            style={{
                                                fontSize: 12
                                            }}
                                            onClick={() => handleCreateUpdateByCodTurma(tdp.turma.codigo)}
                                        >
                                            Criar/Atualizar Aluno Freq. por Turma
                                        </MenuItem>
                                        <MenuItem
                                            style={{
                                                fontSize: 12
                                            }}
                                            onClick={() => handleCreateUpdateByCodTdp(tdp.codigo)}
                                        >
                                            Criar/Atualizar Aluno Freq. por Turma - Disciplina
                                        </MenuItem>
                                    </Menu>
                                </> : ''
                            }

                        </Grid>
                    </Grid>

                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Quant. Aulas</TableCell>
                                <TableCell>Quant. Faltas</TableCell>
                                <TableCell>Ausência Compensada</TableCell>
                                <TableCell>Frequência</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alunosFrequencia.map((alunoFrequencia) => (
                                <TableRow key={alunoFrequencia.codigo}>
                                    <TableCell>{alunoFrequencia.alunoTurma.aluno.nome}</TableCell>
                                    <TableCell>{alunoFrequencia.quantidadeAulas}</TableCell>
                                    <TableCell>{alunoFrequencia.quantidadeFaltas}</TableCell>
                                    <TableCell>{alunoFrequencia.ausenciaCompensada}</TableCell>
                                    <TableCell>{alunoFrequencia.frequencia}%</TableCell>
                                    <TableCell>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => dispatch(setAlunoFrequencia(alunoFrequencia))}
                                            className={classes.submit}>
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            }

        </div>
    );
}