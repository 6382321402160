import React, { useState, useEffect } from 'react';
import { findByTurma } from './api-aluno-turma';
import { useSelector, useDispatch } from 'react-redux';
import { setAlunoTurma } from './actions/actions';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import AlunoTurmaTableSelectView from './AlunoTurmaTableSelectView';

export default function AlunoTurmaTableSelectByTurma() {

    const tdp = useSelector(state => state.tdp);
    const [alunosTurma, setAlunosTurma] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findByTurma(tdp.turma.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setAlunosTurma(data);
            }
            setIsLoading(false);
        })

    }, [tdp]);

    const handleSelectAlunoTurma = (at) => {
        dispatch(setAlunoTurma(at));
    }


    return (
        <AlunoTurmaTableSelectView
            alunosTurma={alunosTurma}
            handleSelectAlunoTurma={handleSelectAlunoTurma}
            isLoading={isLoading}
        />
    );
}