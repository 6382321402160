import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function RelatorioCabecalhoWeb({ rendimento }) {

    return (

        <div style={{
            border: '1px solid black',
            padding: '20px',
            margin: '20px'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
                <Typography >
                    Código: {rendimento.alunoTurma.aluno.codAlunoEscola}
                </Typography>
                <Typography >
                    Aluno: {rendimento.alunoTurma.aluno.nome}
                </Typography>
                <Typography >
                    Turma: {rendimento.alunoTurma.turma.descricao}
                </Typography>
                <Typography >
                    Ano Letivo: {rendimento.alunoTurma.turma.anoLetivo}
                </Typography>
                <Typography >
                    {rendimento.bimestre.numeroBimestre ? rendimento.bimestre.numeroBimestre + 'º Bimestre' : ''}
                </Typography>
            </div>
        </div>

    );
}