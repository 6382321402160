import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Typography } from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { dateFormat, dateTimeFormat } from '../../../util/DateUtil';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { attachFile, findByCodAtividadeAluno, deleteById } from "../../AtividadeAlunoAnexo/api-atividade-aluno-anexo";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import arquivoImg from "../../../shared/assets/img/arquivo.png";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const useStyles = makeStyles({
    paper: {
        padding: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    table: {
        marginTop: '20px'
    }

});


export default function AAAlunoForm(props) {
    const classes = useStyles();
    const atividadeAluno = props.location.state.atividadeAluno
    const [isAttachingFile, setIsAttachingFile] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [aaAnexos, setAAAnexos] = useState([]);

    const handleDelete = (codigo) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar o anexo?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleteById(codigo).then((data) => {
                            if (data.error) {
                                console.log(data);
                            } else {
                                setAAAnexos(aaAnexos.filter(aaAnexo => aaAnexo.codigo !== codigo))
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    useEffect(() => {
        findByCodAtividadeAluno(atividadeAluno.codigo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAAAnexos(data);
            }
        })
    }, [atividadeAluno.codigo])

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }

    const handleChangeFile = event => {
        const file = event.target.files[0];
        console.log(file);

        if (file === null) {
            return;
        }

        const fileFormData = new FormData();
        fileFormData.append('file', file);
        console.log(fileFormData);
        setIsAttachingFile(true);
        attachFile(atividadeAluno.codigo, fileFormData).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setAAAnexos([
                    ...aaAnexos,
                    data
                ])
            }
            setIsAttachingFile(false);
        })
    };

    return (
        <Container className={classes.paper} component={Paper}>
            <Typography style={{ marginBottom: '20px' }} variant='h6'>
                Atividade
            </Typography>
            <Typography >
                <span><strong>Disciplina:</strong></span> {atividadeAluno.atividade.tdp.disciplina.descricao}
            </Typography>
            <Typography >
                <span><strong>Data:</strong></span>
                {dateFormat(atividadeAluno.atividade.data)}
            </Typography>
            <Typography >
                <span><strong>Descricao:</strong></span>
                {atividadeAluno.atividade.descricao}
            </Typography>
            <Typography >
                <span><strong>Conteúdo:</strong></span>
                {parse(atividadeAluno.atividade.conteudo)}
            </Typography>
            <Typography >
                <span><strong>Nota/Visto:</strong></span>
                {atividadeAluno.atividade.semNota ?
                    (atividadeAluno.vistoProfessor ? <DoneOutlineIcon /> : '') :
                    atividadeAluno.nota}
            </Typography>


            {atividadeAluno.atividade.entrega ?
                <>
                    <Typography style={{ marginBottom: '20px', marginTop: '20px' }} variant='h6'>
                        Anexos
                    </Typography>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={hiddenFileInput}
                        onChange={handleChangeFile}
                    />
                    {atividadeAluno.nota || atividadeAluno.vistoProfessor ?
                        '' : <Button
                            variant="contained"
                            color="secondary"
                            component="span"
                            className={classes.sendBtn}
                            disabled={isAttachingFile}
                            onClick={handleAttachFile}
                        >
                            <AttachFileIcon style={{ marginRight: '5px' }} />
                            Anexar Arquivo
                        </Button>}


                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell ></TableCell>
                                <TableCell >Tipo Arquivo</TableCell>
                                <TableCell >Url</TableCell>
                                <TableCell >Data</TableCell>
                                {atividadeAluno.nota || atividadeAluno.vistoProfessor ? '' :
                                    <TableCell >Açoes</TableCell>
                                }

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {aaAnexos.map((aaAnexo) => (
                                <TableRow key={aaAnexo.codigo}>
                                    <TableCell>
                                        <Button
                                            component={Link}
                                            to={{
                                                pathname: '/image-full-screen',
                                                state: {
                                                    uri: aaAnexo.extensaoArquivo.includes('image') ?
                                                        (aaAnexo.anexoUrl ? aaAnexo.anexoUrl : aaAnexo.fileAttach)
                                                        : arquivoImg
                                                }
                                            }}
                                        >
                                            <img
                                                alt=""
                                                style={{ width: '50px', height: '60px' }}
                                                src={aaAnexo.extensaoArquivo.includes('image') ?
                                                    (aaAnexo.anexoUrl ? aaAnexo.anexoUrl : aaAnexo.fileAttach)
                                                    : arquivoImg}
                                            />
                                        </Button>
                                    </TableCell>
                                    <TableCell>{aaAnexo ? aaAnexo.extensaoArquivo : ''}</TableCell>
                                    <TableCell>{aaAnexo && aaAnexo.anexoUrl ?
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={aaAnexo.anexoUrl}>
                                            {aaAnexo.anexoUrl}
                                        </a> : ''}</TableCell>
                                    <TableCell>{aaAnexo ? dateTimeFormat(aaAnexo.createdAt) : ''}</TableCell>


                                    {atividadeAluno.nota || atividadeAluno.vistoProfessor ?
                                        ''
                                        :
                                        <TableCell>
                                            <DeleteIcon
                                                className={classes.cursorLink}
                                                onClick={() => { handleDelete(aaAnexo.codigo) }}
                                                style={{ marginRight: '10px' }}
                                                color="secondary"
                                            />
                                        </TableCell>
                                    }


                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </>
                : ''}



        </Container>
    );
}