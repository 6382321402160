import {
    SET_CURSO, 
    SET_CURSOS,
    ADD_CURSOS, 
    UPDATE_CURSOS, 
    REMOVE_CURSOS
} from './actionTypes'

export function setCurso(curso){
    return {type: SET_CURSO, curso}
}

export function setCursos(cursos){
    return {type: SET_CURSOS, cursos}
}

export function addCursos (curso) {
    return { type: ADD_CURSOS, curso }
}

export function updateCursos(curso){
    return {type : UPDATE_CURSOS, curso}
}

export function removeCursos(codCurso){
    return {type : REMOVE_CURSOS, codCurso}
}