import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'evento-turma/';

const findByEvento = (codEvento) => {
  const url = SERVER_URL + controller + 'evento/' + codEvento;
  return fetchGetAuth(url);
}

const save = (comunicadoTurma) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, comunicadoTurma);
}

const remove = (codEventoTurma) => {
  const url = SERVER_URL + controller + codEventoTurma;
  return fetchRemoveAuth(url);
}

export {
  findByEvento,
  save,
  remove,
}