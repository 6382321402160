import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    card: {
        width: 'fit-content'
    },
});

export default function SerieProfile({ serie, setSerie }) {
    const classes = useStyles();

    const handleTrocaSerie = () => {
        setSerie(null)
    }

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Série
                        </Typography>
                        <Typography variant="body1" >
                            {serie.descricao}
                        </Typography>
                    </div>
                </div>
                <CardActions>
                    <Button
                        onClick={handleTrocaSerie}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >Trocar Série</Button>
                </CardActions>

            </CardContent>
        </Card>
    );
}