import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import {
    rltAtividadeNotaBimestre,
    rltContrFreqMan,
    rltContrNotaMan,
    rltFaltaBimestre,
    rltObservacaoAluno
} from './api-relatorio';
import { findByCurrentUser } from '../TDP/api-tdp';
import CircularProgress from '@material-ui/core/CircularProgress';
import TDPComboBox from '../TDP/UI/TDPComboBox';
import BimestreComboBox from '../Bimestre/UI/BimestreComboBox';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RltTDPBimestreForm() {
    const classes = useStyles();
    const [tdps, setTdps] = useState([]);
    const [tdp, setTdp] = useState({});
    const [numeroBimestre, setNumeroBimestre] = useState(1);
    const [loading, setLoading] = useState({
        atividadeNotaBimestre: false,
        contrFreqMan: false,
        contrNotaMan: false,
        faltaBimestre: false,
        observacaoAluno: false
    });

    useEffect(() => {
        findByCurrentUser().then((data) => {
            if (data) {
                setTdps(data);
            }
        })
    }, []);

    const clickAtividadeNotaBimestre = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            atividadeNotaBimestre: true
        });
        if (tdp) {
            rltAtividadeNotaBimestre(tdp.codigo, numeroBimestre).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        atividadeNotaBimestre: false
                    });
                }
            })
        }
    }

    const clickContrFreqMan = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            contrFreqMan: true
        });
        if (tdp) {
            rltContrFreqMan(tdp.codigo, numeroBimestre).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        contrFreqMan: false
                    });
                }
            })
        }

    }

    const clickContrNotaMan = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            contrNotaMan: true
        });
        if (tdp) {
            rltContrNotaMan(tdp.codigo, numeroBimestre).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        contrNotaMan: false
                    });
                }
            })
        }
    }

    const clickFaltaBimestre = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            faltaBimestre: true
        });
        if (tdp) {
            rltFaltaBimestre(tdp.codigo, numeroBimestre).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        faltaBimestre: false
                    });
                }
            })
        }
    }

    const clickObservacaoAluno = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            observacaoAluno: true
        });
        if (tdp) {
            rltObservacaoAluno(tdp.codigo, numeroBimestre).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        observacaoAluno: false
                    });
                }
            })
        }
    }

    const handleTDPChange = (event) => {
        const tdp = tdps.find((t) => t.codigo === event.target.value);
        setTdp(tdp);
    }

    const handleBimestreChange = (event) => {
        setNumeroBimestre(event.target.value);
    }

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper} >
                <Typography className={classes.title} variant="body1">
                    Por Turma/Disciplina e Bimestre
                </Typography>
                <form>
                    <TDPComboBox
                        tdps={tdps}
                        tdp={tdp}
                        handleTDPChange={handleTDPChange}
                    />
                    <BimestreComboBox
                        numeroBimestre={numeroBimestre}
                        handleBimestreChange={handleBimestreChange}
                    />
                    {loading.atividadeNotaBimestre ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickAtividadeNotaBimestre}
                        >
                            Atividade Nota Bimestre
                        </Button>
                    }

                    {loading.contrFreqMan ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickContrFreqMan}
                        >
                            Controle Frequência Manual
                        </Button>
                    }


                    {loading.contrNotaMan ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickContrNotaMan}
                        >
                            Controle Nota Manual
                        </Button>
                    }


                    {loading.faltaBimestre ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickFaltaBimestre}
                        >
                            Falta Bimestre
                        </Button>
                    }


                    {loading.observacaoAluno ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickObservacaoAluno}
                        >
                            Observação Aluno
                        </Button>
                    }


                </form>
            </Container>
        </div>
    );
}