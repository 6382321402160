import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';

import { RltFreqFindAluno } from './RltFreqFindAluno';
import TextField from '../../../UI/TextField';
import { Grid } from '@material-ui/core';
import Select from '../../../UI/Select';
import { findFrequenciaAlunoMesAnoLetivo } from '../../../PresencaAluno/api-presenca-aluno';
import { RltFrequencia } from './RltFrequencia';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px'

    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    gerarRelatorio: {
        marginTop: "15px"
    }
});

export default function RltFrequenciaPorAlunoMesAnoLetivo(props) {
    const classes = useStyles();
    const [listAlunos, setListAlunos] = useState([]);
    const [open, setOpen] = useState(false);
    const [aluno, setAluno] = useState(null);
    const [openReport, setOpenReport] = useState(false);
    const [anoLetivo, setAnoLetivo] = useState();
    const listMes = [
        { value: 1, description: "Janeiro" },
        { value: 2, description: "Fevereiro" },
        { value: 3, description: "Março" },
        { value: 4, description: "Abril" },
        { value: 5, description: "Maio" },
        { value: 6, description: "Junho" },
        { value: 7, description: "Julho" },
        { value: 8, description: "Agosto" },
        { value: 9, description: "Setembro" },
        { value: 10, description: "Outubro" },
        { value: 11, description: "Novembro" },
        { value: 12, description: "Dezembro" },
    ];
    const [mes, setMes] = useState(1);
    const [listFrequencia, setListFrequencia] = useState([]);


    useEffect(() => {
        if (aluno !== null) {
            setListAlunos((prevState) => [...prevState, aluno]);
            setAluno(null)
        }
    }, [aluno]);

    useEffect(() => {
        if (openReport) {
            listAlunos.forEach((aluno) => {
                findFrequenciaAlunoMesAnoLetivo(aluno.codAlunoEscola, mes, anoLetivo).then((data) => {
                    if (data && !data.error) {
                        setListFrequencia((prevState) => [...prevState, data]);
                    }
                })
            })

        }
    }, [openReport, mes, anoLetivo, listAlunos]);

    const handleDelete = (codAluno) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este aluno?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setListAlunos((prevState) => prevState.filter((ps) => ps.codigo !== codAluno));
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    };

    const onChangeAnoLetivo = (event) => {
        setAnoLetivo(event.target.value);
    }

    const onChangeMes = (event) => {
        setMes(event.target.value);
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Button
                onClick={() => props.history.goBack()}
                variant="contained"
                color="secondary"
                className={classes.voltarButton}
            >
                <ArrowBackIcon style={{ marginRight: '10px' }} />
                Voltar
            </Button>
            <Typography className={classes.title} variant="h5">
                Frequência por Aluno, Mês e Ano Letivo
            </Typography>
            {openReport ?
                <RltFrequencia
                    listFrequencia={listFrequencia}
                    setOpenReport={setOpenReport}
                /> : <>
                    <RltFreqFindAluno
                        open={open}
                        setOpen={setOpen} setAluno={setAluno} listAlunos={listAlunos} handleDelete={handleDelete}
                    />

                    <Grid
                        container
                        direction='row'
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                        >
                            <TextField
                                value={anoLetivo}
                                fullWidth={false}
                                label="Ano Letivo"
                                onChange={onChangeAnoLetivo}
                            />
                        </Grid>

                        <Grid
                            item

                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                        >
                            <Select
                                className={classes.gerarRelatorio}
                                label="Mês"
                                value={mes}
                                list={listMes}
                                onChange={onChangeMes}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                        >
                            <Button
                                onClick={() => {
                                    setOpenReport(true);
                                }}
                                variant="contained"
                                className={classes.gerarRelatorio}
                                color="primary"
                            >
                                Gerar Relatório
                            </Button>

                        </Grid>

                    </Grid>



                </>}

        </Container>
    );
}