import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Button from '../UI/Button';
import ContainerWithGoBack from '../UI/ContainerWithGoBack';
import TextField from '../UI/TextField';
import { setUsuario } from './actions/actions';
import { saveCurrentUser } from './api-usuario';

const UsuarioDescricaoAutorForm = () => {
    const usuario = useSelector(state => state.usuario);
    const dispatch = useDispatch();

    const onChange = (event) => {
        dispatch(setUsuario({
            ...usuario,
            [event.target.name]: event.target.value
        }))
    }

    const handleClickSubmit = (event) => {
        event.preventDefault();
        saveCurrentUser(usuario).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Operação realizada com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        })
    }

    return (
        <ContainerWithGoBack >
            <Grid container justifyContent='center' spacing={3}>
                <Grid item xs={12}>
                    <Typography align='center' variant='body1'>
                        Compartilhe algo sobre você com os seus leitores.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <form onSubmit={handleClickSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rows="4"
                                    inputProps={{ maxLength: 200 }}
                                    label='Descrição Autor'
                                    onChange={onChange}
                                    name='descricaoAutor'
                                    value={usuario.descricaoAutor}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} direction='row'>
                                    <Grid item>
                                        <Button
                                            type='submit'
                                        >
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>

                </Grid>

            </Grid>

        </ContainerWithGoBack>
    );
}

export default UsuarioDescricaoAutorForm;