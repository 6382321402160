import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import { page, remove, uploadImage } from "./api-comunicado-banner";
import ComunicadoBannerForm from "./ComunicadoBannerForm";
import ComunicadoBannerList from "./ComunicadoBannerList";
import Container from "../../UI/Container";
import { successDefault } from "../../../util/ToastUtil";
import Loading from "../../UI/Loading";
import { confirmAlert } from "react-confirm-alert";

const ComunicadoBannerContainer = () => {
  const [comunicadoBanner, setComunicadoBanner] = useState(null);
  const [listCB, setListCB] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    page(pageNumber).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setListCB((prevState) => ({
          ...data,
          content: [...prevState.content, ...data.content],
        }));
      }
      setIsLoading(false);
    });
  }, [pageNumber]);

  const handleSubmit = (formData) => {
    setIsLoadingAction(true);
    uploadImage(formData).then((data) => {
      console.log(data);
      if (data.error) {
        errorMessage(data.message);
      } else {
        setListCB((prevState) => ({
          ...prevState,
          content: [...prevState.content, data],
        }));
        setComunicadoBanner(null);
        successDefault();
      }
      setIsLoadingAction(false);
    });
  };

  const handleRemove = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este comunicado banner?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingAction(true);
            remove(comunicadoBanner.codigo).then((data) => {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setListCB((prevState) => ({
                  ...prevState,
                  content: prevState.content.filter(
                    (ps) => ps.codigo !== comunicadoBanner.codigo
                  ),
                }));
                setComunicadoBanner(null);
                successDefault();
              }
              setIsLoadingAction(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleNewBanner = () => {
    setComunicadoBanner({ eNovo: true });
  };

  return (
    <Container>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Typography variant="h6">Comunicado Banner</Typography>
        </Grid>
        {comunicadoBanner ? (
          <Grid item>
            <ComunicadoBannerForm
              comunicadoBanner={comunicadoBanner}
              setComunicadoBanner={setComunicadoBanner}
              handleSubmit={handleSubmit}
              isLoadingAction={isLoadingAction}
              handleRemove={handleRemove}
            />
          </Grid>
        ) : (
          <>
            {isLoading ? (
              <Loading description="Carregando Comunicado Banner..." />
            ) : (
              <>
                <Grid item>
                  <Button variant="outlined" onClick={handleNewBanner}>
                    Novo Banner
                  </Button>
                </Grid>
                <Grid item>
                  <ComunicadoBannerList
                    listCB={listCB}
                    setComunicadoBanner={setComunicadoBanner}
                    setPageNumber={setPageNumber}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default ComunicadoBannerContainer;
