import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth,
  fetchRemoveAuthReturn,
  fetchGet
} from '../../util/FetchUtil';

const controller = 'livro/';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

const findBySerie = (codSerie) => {
  const url = SERVER_URL + controller + 'serie/' + codSerie;
  return fetchGetAuth(url);
}

const findByDisciplina = (codDisciplina) => {
  const url = SERVER_URL + controller + 'disciplina/' + codDisciplina;
  return fetchGetAuth(url);
}

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const remove = (codLivro) => {
  const url = SERVER_URL + controller + 'delete/' + codLivro;
  return fetchRemoveAuth(url);
}

const save = (livro) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, livro);
}

const findByDescricao = (descricao) => {
  const url = SERVER_URL + controller + 'descricao/' + descricao;
  return fetchGetAuth(url);
}

export const deleteById = (codLivro) => {
  const url = SERVER_URL + controller + codLivro;
  return fetchRemoveAuthReturn(url);
}

export const findByCodLivroAndIsPublic = (codLivro) => {
  const url = SERVER_URL + controller + "public/" + codLivro;
  return fetchGet(url);
}

export {
  page,
  save,
  remove,
  findBySerie,
  findAll,
  findByDescricao,
  findByDisciplina
}