import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TurmaDisciplinaProfessorProfile from '../TDP/TurmaDisciplinaProfessorProfile';
import { useSelector } from 'react-redux';
import PresencaForm from './PresencaForm';
import PresencaTable from './PresencaTable';
import PresencaCapituloPlanejamento from '../PresencaCapitulo/PresencaCapituloPlanejamento';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import PlanejamentoQuantAulasReg from '../Cronograma/CronogramaQuantAulaBimestre/PlanejamentoQuantAulasReg';
import { useEffect } from 'react';
import { page, remove, duplicarPresenca, findByTdpCodigoAndDataMonth } from './api-presenca';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { ADMIN } from '../Usuario/Perfil';
import TDPTableSelectView from '../TDP/TDPTableSelectView';
import { findByCurrentUser } from '../TDP/api-tdp';
import { findByAnoLetivo as findTurmasByAnoLetivo } from '../Turma/api-turma';
import { findAll as findAllDisciplinas } from '../Disciplina/api-disciplina';
import { PROFESSOR } from '../Usuario/Perfil';
import { findByPerfil } from '../Usuario/api-usuario-perfil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function PresencaContainer() {
    const classes = useStyles();
    const [tdp, setTdp] = useState(null);
    const [presenca, setPresenca] = useState(null);
    const [presencas, setPresencas] = useState([]);
    const user = useSelector(state => state.usuario);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [mesSelect, setMesSelect] = useState('');

    //New
    const [isLoadingTdp, setIsLoadingTdp] = useState(false);
    const [tdps, setTdps] = useState([]);
    const [tdpsAll, setTdpsAll] = useState([]);
    const [turmas, setTurmas] = useState([]);
    const [turma, setTurma] = useState(null);
    const [disciplinas, setDisciplinas] = useState([]);
    const [disciplina, setDisciplina] = useState(null);
    const [usuariosPerfil, setUsuariosPerfil] = useState([]);
    const [usuarioPerfil, setUsuarioPerfil] = useState(null);


    useEffect(() => {
        setIsLoadingTdp(true);
        findByCurrentUser().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setTdps(data);
                setTdpsAll(data);
            }
            setIsLoadingTdp(false);
        });

        findTurmasByAnoLetivo().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setTurmas(data);
            }
        })

        findAllDisciplinas().then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setDisciplinas(data);
            }
        })

        findByPerfil(PROFESSOR).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setUsuariosPerfil(data);
            }
        })

    }, []);


    useEffect(() => {
        setIsLoading(true);
        if (tdp && tdp.codigo && mesSelect) {
            findByTdpCodigoAndDataMonth(tdp.codigo, 0, mesSelect).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPresencas(data);
                }
                setIsLoading(false);
            });
        } else if (tdp && tdp.codigo) {
            page(tdp.codigo, 0).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPresencas(data);
                }
                setIsLoading(false);
            });
        }

    }, [tdp, mesSelect]);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        if (tdp && tdp.codigo && mesSelect) {
            findByTdpCodigoAndDataMonth(tdp.codigo, newPage, mesSelect).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPresencas(data);
                }
                setIsLoading(false);
            });
        } else if (tdp && tdp.codigo) {
            page(tdp.codigo, newPage).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setPresencas(data);
                }
                setIsLoading(false);
            });
        }

    };

    const removePresencas = (codPresenca) => {
        setPresencas((prevState) => ({
            ...prevState,
            content: prevState.content.filter((ps) => ps.codigo !== codPresenca)
        }));
    }

    const handleDelete = (codigo) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta presença?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingRemove(true);
                        remove(codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.error);
                            } else {
                                removePresencas(codigo);
                                setPresenca(null);
                                toast.success("Registro excluído com Sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                            setIsLoadingRemove(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const addPresencas = (data) => {
        setPresencas((prevState) => ({
            ...prevState,
            content: [data, ...prevState.content]
        }))
    }

    const handleDuplicarPresenca = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja duplicar esta presença?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        duplicarPresenca(presencas.content[index].codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                addPresencas(data);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Presenças
                    </Typography>
                </Grid>
                <Grid style={{ flexBasis: '5%' }} item xs={3}>
                    {user.perfil === "ADMIN" || user.perfil === "COORDERNADOR" ?
                        <>
                            <MenuIcon
                                fontSize='small'
                                className={classes.cursorLink}
                                onClick={(event) => handleMenuClick(event)}
                                color="secondary"

                            />
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem component={Link} to={'/presenca-capitulo-planejamento'}>
                                    Controle Planejamento - Todos
                                </MenuItem>
                                {user.perfil === ADMIN &&
                                    <MenuItem component={Link} to={'/presenca-lote'}>
                                        Presença Lote (Inclusão/Exclusão)
                                    </MenuItem>
                                }

                            </Menu>
                        </> : ''}
                </Grid>
            </Grid>

            {tdp ?
                <>
                    <TurmaDisciplinaProfessorProfile
                        tdp={tdp}
                        onTdpSelected={setTdp}
                    />
                    <Grid container spacing={3}>
                        <Grid item>
                            {tdp.eEletiva === false &&
                                tdp.eProjeto === false ?
                                <PresencaCapituloPlanejamento tdp={tdp} /> :
                                ''
                            }
                        </Grid>
                        <Grid item>
                            <PlanejamentoQuantAulasReg tdp={tdp} />
                        </Grid>

                    </Grid>
                    {presenca ?
                        <PresencaForm
                            presenca={presenca}
                            setPresenca={setPresenca}
                            setPresencas={setPresencas}
                            addPresencas={addPresencas}
                        /> :
                        <PresencaTable
                            presencas={presencas}
                            tdp={tdp}
                            isLoading={isLoading}
                            isLoadingRemove={isLoadingRemove}
                            handleDelete={handleDelete}
                            handleDuplicarPresenca={handleDuplicarPresenca}
                            handleChangePage={handleChangePage}
                            setPresenca={setPresenca}
                            showFilters={showFilters}
                            setShowFilters={setShowFilters}
                            mesSelect={mesSelect}
                            setMesSelect={setMesSelect}
                        />
                    }

                </> :
                <TDPTableSelectView
                    tdpsAll={tdpsAll}
                    tdps={tdps}
                    setTdps={setTdps}
                    setTdp={setTdp}
                    isLoading={isLoadingTdp}
                    usuariosPerfil={usuariosPerfil}
                    usuarioPerfil={usuarioPerfil}
                    setUsuarioPerfil={setUsuarioPerfil}
                    turmas={turmas}
                    disciplinas={disciplinas}
                    disciplina={disciplina}
                    setDisciplina={setDisciplina}
                    turma={turma}
                    setTurma={setTurma}
                />
            }
        </Container>
    );
}