import { toast } from "react-toastify";



export const success = (message) => {
    toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT
    });
}

export const errorToast = (message) => {
    toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT
    });
}

export const successDefault = () => {
    success("Operação Realizado com Sucesso.");
}