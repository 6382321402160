import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { findSimulados } from './api-avaliacao'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setAvaliacao } from './actions/actions';
import { ALUNO } from '../Usuario/Perfil';
import { createSimulado } from './Aluno/api-avaliacao-aluno';
import { setAvaliacaoAluno } from './Aluno/actions/actions';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function SimuladoTable() {
    const classes = useStyles();
    const [avaliacoes, setAvaliacoes] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = useSelector(state => state.usuario);

    useEffect(() => {
        findSimulados().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAvaliacoes(data);
            }
        })
    }, []);

    const handleSelecionarAvaliacao = (avaliacao) => {
        dispatch(setAvaliacao(avaliacao));
        if (avaliacao &&
            avaliacao.esimulado &&
            usuario.perfil === ALUNO) {
            createSimulado(avaliacao.codigo).then((data) => {
                if (data.error) {
                    if (data.message === 'User without student registration') {
                        toast.error("Usuário sem registro de aluno.", {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    } else {
                        console.log(data);
                    }
                } else {
                    dispatch(setAvaliacaoAluno(data));
                    history.push({
                        pathname: '/avaliacao-secao',
                        state: {
                            usuario: usuario,
                        }
                    })
                }
            })
        }

    }

    return (
        <div>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Título</TableCell>
                        <TableCell>Conteúdo</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {avaliacoes.map((avaliacao) => (
                        <TableRow key={avaliacao.codigo}>
                            <TableCell>{avaliacao.titulo}</TableCell>
                            <TableCell>{avaliacao.conteudo}</TableCell>
                            <TableCell>
                                <Button
                                    size='small'
                                    onClick={() => handleSelecionarAvaliacao(avaliacao)}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}