import { Divider, Grid, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import UserIcon from '../../../shared/assets/img/User.png';
import { dateTimeFormat } from '../../../util/DateUtil';
import Button from '../../UI/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ADMIN, COORDENADOR, PROFESSOR } from '../../Usuario/Perfil';


const useStyles = makeStyles({
    fotoComponent: {
        width: '60px',
        height: '60px',
        borderRadius: '30px',
        marginRight: '20px'
    },
    mensUserComp: {
        cursor: 'pointer',
    }
})

const MensagemAll = ({ sala }) => {
    const classes = useStyles();
    const [anchorTableEl, setAnchorTableEl] = useState(null);
    const user = useSelector(state => state.usuario);
    const history = useHistory();

    const handleMenuTableClick = (event) => {
        setAnchorTableEl(event.currentTarget);
    };

    const handleMenuTableClose = () => {
        setAnchorTableEl(null);
    };

    const handleSalaMensagem = () => {
        history.push('/sala-mensagem', {
            sala: sala,
            nameRoom: getNameRoom(sala),
        })
    }

    const getNameRoom = (sala) => {
        if (sala.isGroup) {
            return 'Grupo: ' + sala.descricao;
        } else {
            return sala.listaSP.map((sp, index) => {
                let result = sp.usuario.nome + " (" + sp.usuario.perfil + ")";
                if (index < sala.listaSP.length - 1) {
                    result += " - ";
                }
                return result;
            })
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={2}>
                        {sala.isGroup ?
                            <img
                                src={UserIcon}
                                alt=''
                                className={classes.fotoComponent}
                            /> :
                            <>
                                {sala.listaSP.map((sp) => (
                                    <>
                                        {sp.usuario.foto ?

                                            <img
                                                src={sp.usuario.foto}
                                                alt=''
                                                className={classes.fotoComponent}
                                            /> :
                                            <img
                                                src={UserIcon}
                                                alt=''
                                                className={classes.fotoComponent}
                                            />
                                        }
                                    </>
                                ))}
                            </>
                        }
                    </Grid>
                    {sala.isGroup ?
                        <Grid
                            item
                            xs={4}
                            className={classes.mensUserComp}
                            onClick={handleSalaMensagem}
                        >
                            Grupo: {sala.descricao}
                        </Grid>
                        :
                        <Grid
                            item
                            xs={4}
                            className={classes.mensUserComp}
                            onClick={handleSalaMensagem}
                        >
                            <Grid container >
                                {sala.listaSP.map((sp) => (
                                    <Grid item xs={12}>
                                        {sp.usuario.nome + " (" + sp.usuario.perfil + ")"}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    }
                    <Grid item>
                        {dateTimeFormat(sala.dataHoraUtlimaMensagem)}
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleSalaMensagem}
                            color='secondary'
                        >
                            Ir para sala
                        </Button>
                    </Grid>
                    <Grid item>
                        {sala.isGroup &&
                            user && user.perfil && (user.perfil === ADMIN ||
                                user.perfil === COORDENADOR ||
                                user.perfil === PROFESSOR)
                            ?
                            <>
                                <MoreVertIcon
                                    id={"menu" + sala.codigo}
                                    fontSize='small'
                                    className={classes.mensUserComp}
                                    onClick={(event) => handleMenuTableClick(event)}
                                    color="secondary"

                                />
                                <Menu
                                    id={"simple-menu" + sala.codigo}
                                    anchorEl={anchorTableEl}
                                    keepMounted
                                    open={Boolean(anchorTableEl)}
                                    onClose={handleMenuTableClose}
                                >
                                    <MenuItem
                                        component={Link}
                                        to={{
                                            pathname: '/group-form',
                                            state: {
                                                sala: sala
                                            }
                                        }}
                                    >
                                        Atualizar Grupo
                                    </MenuItem>
                                    <MenuItem
                                        component={Link}
                                        to={{
                                            pathname: '/group-list-participants',
                                            state: {
                                                sala: sala
                                            }
                                        }}
                                    >
                                        Lista Participantes
                                    </MenuItem>
                                </Menu>
                            </>
                            : ''}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

        </Grid>
    )

}

export default MensagemAll;