import { SERVER_URL } from '../../../constants';
import {
  fetchGet,
  fetchGetAuth,
  fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'livro-autor';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const save = (livro) => {
  const url = SERVER_URL + controller + '/save';
  return fetchPostAuth(url, livro);
}

const findByLivroDescricao = (descricao) => {
  const url = SERVER_URL + controller + '/query?descricao=' + descricao;
  return fetchGetAuth(url);
}

const findByLivroCodLivro = (codLivro) => {
  const url = SERVER_URL + controller + '/query?codLivro=' + codLivro;
  return fetchGetAuth(url);
}

export const findByLivroCodLivroAndPublic = (codLivro) => {
  const url = SERVER_URL + controller + '/public/' + codLivro;
  return fetchGet(url);
}

const findByLivroCodLivroAndUsuarioId = (codLivro, codUsuario) => {
  const url = SERVER_URL + controller + '/query?codLivro=' + codLivro + "&codUsuario=" + codUsuario;
  return fetchGetAuth(url);
}

export {
  page,
  save,
  findByLivroDescricao,
  findByLivroCodLivro,
  findByLivroCodLivroAndUsuarioId
}