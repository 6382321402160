import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { remove, findAll } from './api-cronograma-aula';
import { timeFormat } from '../../../util/DateUtil';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setCronogramasAula, setCronogramaAula, removeCronogramasAula } from "./actions/actions";


const useStyles = makeStyles({
    container: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function CronogramaAulaTable() {
    const classes = useStyles();
    const cronogramasAula = useSelector(state => state.cronogramasAula);
    const dispatch = useDispatch();

    useEffect(() => {
        findAll().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setCronogramasAula(data));
            }
        }).catch(err => {
            console.log(err);
        });
    }, [dispatch]);





    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este Cronograma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(cronogramasAula[index].codigo).then((data) => {
                            if (data) {
                                dispatch(removeCronogramasAula(cronogramasAula[index].codigo));
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };


    return (
        <div className={classes.container}>
            <Button
                onClick={() => dispatch(setCronogramaAula({
                    eNovo: true
                }))}
                variant="contained"
                color="primary">
                Adicionar Cronograma Aula
            </Button>

            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Ordem Aula</TableCell>
                        <TableCell>Horário</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cronogramasAula.length > 0 ? cronogramasAula.map((ca, index) => (
                        <TableRow key={ca.codigo}>
                            <TableCell>{ca.descricao}</TableCell>
                            <TableCell>{ca.ordemAula}</TableCell>
                            <TableCell>{timeFormat(ca.horarioInicio)} - {timeFormat(ca.horarioFim)}</TableCell>
                            <TableCell>
                                <EditIcon
                                    className={classes.cursorLink}
                                    fontSize='small'
                                    onClick={() => {
                                        dispatch(setCronogramaAula(ca));
                                    }}
                                    color="secondary"
                                    style={{ marginRight: '10px' }} />
                                <DeleteIcon
                                    className={classes.cursorLink}
                                    fontSize='small'
                                    onClick={() => { handleDelete(index) }} color="secondary" />
                            </TableCell>
                        </TableRow>
                    )) : ''}
                </TableBody>
            </Table>

        </div>
    );
}