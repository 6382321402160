import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { rltBoletimItinerarioWeb } from "../../api-relatorio";
import CircularProgress from '@material-ui/core/CircularProgress';
import RelatorioBoletimNotaBimestreWeb from "./RelatorioBoletimNotaBimestreWeb";
import RelatorioCabecalhoWeb from '../Cabecalho/RelatorioCabecalhoWeb';
import RelatorioBoletimEletivaWeb from './RelatorioBoletimEletivaWeb';
import RelatorioBoletimProjetoWeb from './RelatorioBoletimProjetoWeb';
import { open } from "../../../../util/SimpleDialogMessage/actions/actions";
import SimpleDialogMessage from "../../../../util/SimpleDialogMessage/SimpleDialogMessage";
import { useDispatch } from 'react-redux';
import { findByTurma } from '../../../AlunoTurma/api-aluno-turma';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center'
    },
    loading: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    voltarButton: {
        '@media print': {
            display: 'none'
        }
    }
});

export default function RelatorioBoletimItinerarioWeb(props) {
    const classes = useStyles();
    const [listaBE, setListaBE] = useState([]);
    const numeroBimestre = props.location.state.numeroBimestre;
    const [codAlunosTurmas, setCodAlunosTurmas] = useState(props.location.state.codAlunosTurmas);
    const codTurma = props.location.state.codTurma;
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState({
        title: '',
        description: ''
    });
    const dispatch = useDispatch();
    const progress = useRef(0);
    const history = useHistory();


    useEffect(() => {
        if (codTurma) {
            findByTurma(codTurma).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setCodAlunosTurmas(data.map((d) => d.codigo))
                }
            })
        }
    }, [codTurma]);

    useEffect(() => {
        if (codAlunosTurmas && codAlunosTurmas.length > 0) {
            async function asyncBoletimReport() {
                while (progress.current < codAlunosTurmas.length) {
                    await rltBoletimItinerarioWeb(numeroBimestre, codAlunosTurmas[progress.current]).then((data) => {
                        if (data.error) {
                            if (data.message === 'More than one turma') {
                                setMessage({
                                    title: 'Alerta',
                                    description: 'Aluno cadastrado em 2 turmas ou mais. O aluno só pode ser ativo em 1 turma.'
                                });
                                dispatch(open());


                            } else {
                                console.log(data);
                            }

                        } else {
                            setListaBE((prevState) => ([
                                ...prevState,
                                ...data
                            ]));

                        }
                        progress.current = progress.current + 1;
                    })
                }
                setIsLoading(false);

            }
            asyncBoletimReport();
        }



    }, [numeroBimestre, codAlunosTurmas, dispatch]);

    const handleGoBack = () => {
        history.goBack();

    }


    return (
        <div>
            <SimpleDialogMessage title={message.title} description={message.description} />
            <Container className={classes.paper} component={Paper}>
                <Button
                    onClick={handleGoBack}
                    variant="contained"
                    color="secondary"
                    className={classes.voltarButton}
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
                {listaBE.map((be, index) => (
                    <div key={index} style={{ marginTop: '20px', marginBottom: '100px' }}>
                        <Typography className={classes.title} variant="h5">
                            Boletim
                        </Typography>
                        <RelatorioCabecalhoWeb alunoTurma={be.alunoTurma} />
                        {be.bnotaBimestres && be.bnotaBimestres.length > 0 ? <RelatorioBoletimNotaBimestreWeb boletimEscolar={be} /> : ''}
                        {be.beletivas && be.beletivas.length > 0 ? <RelatorioBoletimEletivaWeb boletimEscolar={be} /> : ''}
                        {be.bprojetos && be.bprojetos.length > 0 ? <RelatorioBoletimProjetoWeb boletimEscolar={be} /> : ''}
                    </div>
                ))}
                {isLoading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" /> Carregando...
                    </div> :
                    ''
                }







            </Container>

        </div>
    );
}