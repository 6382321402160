import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../../util/FetchUtil';

const controller = 'avaliacao-resposta/';

const findByConteudoCodigoAndAvaliacaoAlunoCodigo = (codConteudo, codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'conteudo/' + codConteudo + '/avaliacao-aluno/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}

const save = (avaliacaoResposta) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, avaliacaoResposta);
}

const saveCorrection = (avaliacaoResposta) => {
  const url = SERVER_URL + controller + 'correction';
  return fetchPostAuth(url, avaliacaoResposta);
}

export {
  findByConteudoCodigoAndAvaliacaoAlunoCodigo,
  save,
  saveCorrection
}