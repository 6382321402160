import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SimuladoResultadoTable from '../SimuladoResultadoTable';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px',
    },
    voltarButton: {
        marginBottom: '20px',
        marginRight: '10px',
        cursor: 'pointer'
    }
});

export default function AvaliacaoResultadoContainer() {
    const classes = useStyles();

    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Link to={{
                    pathname: '/avaliacao'
                }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.voltarButton}
                    >
                        <ArrowBackIcon style={{ marginRight: '10px' }} />
                        Voltar
                    </Button>
                </Link>
                <Typography className={classes.title} variant="h6">
                    Resultado
                </Typography>


                <SimuladoResultadoTable />
            </Container>

        </div>
    );
}