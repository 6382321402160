import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-planejamento';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { findByCodLivro } from '../Capitulo/api-capitulo';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function PlanejamentoForm({ tdp, planejamentoContainer, updatePlanejamentos }) {
    const classes = useStyles();
    const [planejamento, setPlanejamento] = useState(planejamentoContainer);
    const [capitulos, setCapitulos] = useState([]);

    useEffect(() => {
        if (tdp.livro) {
            findByCodLivro(tdp.livro.codLivro).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setCapitulos(data);
                }

            }).catch(err => {
                console.log(err);
            })
        }


    }, [tdp.livro]);

    const handleCapituloChange = (event) => {
        const capitulo = capitulos.find((capitulo) => {
            return capitulo.codCapitulo === event.target.value;
        });
        setPlanejamento({
            ...planejamento,
            capitulo: capitulo
        });
    }

    const handleBimestreChange = (event) => {
        setPlanejamento({
            ...planejamento,
            numeroBimestre: event.target.value
        });
    }

    const handleChange = (event) => {
        setPlanejamento({
            ...planejamento,
            [event.target.name]: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();

        if (!planejamento.capitulo) {
            toast.error("Selecione o Capítulo.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        save(planejamento).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                updatePlanejamentos(data);

            }
        }).catch(err => {
            console.log(err);
        })

    }


    return (
        <div>
            <ToastContainer autoClose={3000} />
            <CssBaseline />
            <Container className={classes.paper} component={Paper} maxWidth="sm">
                <form onSubmit={clickSubmit}>
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="capitulo-label">Capítulo</InputLabel>
                        <Select
                            labelId="capitulo-label"
                            id="capitulo-select"
                            value={planejamento.capitulo ? planejamento.capitulo.codCapitulo : ''}
                            onChange={handleCapituloChange}
                        >
                            {capitulos.map((capitulo, index) => (
                                <MenuItem key={index} value={capitulo.codCapitulo}>{capitulo.descricao}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Quantidade Aulas"
                        name="quantidadeAulas"
                        onChange={handleChange}
                        value={planejamento && planejamento.quantidadeAulas ?
                            planejamento.quantidadeAulas : ''}
                    />
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="bimestre-label">Bimestre</InputLabel>
                        <Select
                            labelId="bimestre-label"
                            id="bimestre-select"
                            value={planejamento.numeroBimestre}
                            onChange={handleBimestreChange}
                        >
                            <MenuItem value={1}>1º Bimestre</MenuItem>
                            <MenuItem value={2}>2º Bimestre</MenuItem>
                            <MenuItem value={3}>3º Bimestre</MenuItem>
                            <MenuItem value={4}>4º Bimestre</MenuItem>

                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>
        </div>
    );
}