import {
  SET_ALUNO_TURMA,
  SET_ALUNOS_TURMA,
  ADD_ALUNOS_TURMA,
  UPDATE_ALUNOS_TURMA,
  REMOVE_ALUNOS_TURMA,
  OPEN,
  CLOSE
} from './actionTypes';
import { PURGE } from "redux-persist";

export function alunoTurma(state = null, action) {
  switch (action.type) {
    case SET_ALUNO_TURMA:
      return action.alunoTurma

    case PURGE:
      return null;

    default:
      return state
  }
}

export function alunosTurma(state = [], action) {
  switch (action.type) {
    case SET_ALUNOS_TURMA:
      return action.alunosTurma

    case ADD_ALUNOS_TURMA:

      return [
        ...state,
        action.alunoTurma
      ]

    case UPDATE_ALUNOS_TURMA:
      let content = state.map((alunoTurma) => {
        if (alunoTurma.codigo === action.alunoTurma.codigo) {
          return action.alunoTurma;
        } else {
          return alunoTurma;
        }
      });
      return content;

    case REMOVE_ALUNOS_TURMA:
      return state.filter((alunoTurma) => alunoTurma.codigo !== action.codAlunoTurma);

    case PURGE:
      return [];

    default:
      return state
  }
}

export function openSearchAlunoTurma(state = false, action) {
  switch (action.type) {
    case OPEN:
      return true;
    case CLOSE:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}