import { Button, Grid, IconButton } from "@material-ui/core";
import React from "react";
import TextFieldReadOnly from "../../UI/TextFieldReadOnly";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const TextFieldUsuario = ({
  label,
  value,
  handleBuscarUsuario,
  handleDeleteUsuario,
}) => {
  return (
    <Grid container alignItems="center" direction="row" spacing={3}>
      <Grid item xs={3}>
        <TextFieldReadOnly
          label={label}
          size="small"
          placeholder={label}
          style={{ minWidth: "50%" }}
          value={value}
        />
      </Grid>
      <Grid item>
        <IconButton onClick={handleDeleteUsuario} aria-label="search">
          <HighlightOffIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBuscarUsuario}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};

export default TextFieldUsuario;
