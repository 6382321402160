import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import DiaLetivoExtraTable from './DiaLetivoExtraTable';
import { deleteById, findByAnoLetivo, save } from './api-dia-letivo-extra';
import DiaLetivoExtraForm from './DiaLetivoExtraForm';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';



const DiaLetivoExtraContainer = () => {

    const [diaLetivoExtra, setDiaLetivoExtra] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [listDiaLetivoExtra, setListDiaLetivoExtra] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findByAnoLetivo().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setListDiaLetivoExtra(data);
            }
            setIsLoading(false);
        })
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoadingAction(true);
        save(diaLetivoExtra).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (diaLetivoExtra.eNovo) {
                    setListDiaLetivoExtra((prevState) => ([
                        ...prevState,
                        data
                    ]))
                } else {
                    setListDiaLetivoExtra((prevState) => prevState.map((ps) => {
                        if (ps.codigo === diaLetivoExtra.codigo) {
                            return data;
                        } else {
                            return ps;
                        }
                    }))
                }
                setDiaLetivoExtra(data);
            }
            setIsLoadingAction(false);
        })
    }

    const onRemove = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este registro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        deleteById(diaLetivoExtra.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data);
                            } else {
                                setListDiaLetivoExtra((prevState) => prevState.filter((ps) => ps.codigo !== diaLetivoExtra.codigo));
                                setDiaLetivoExtra(null);
                            }
                        })
                        setIsLoadingAction(false);
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    return (
        <Grid
            container
            justifyContent='center'
        >
            {diaLetivoExtra ?
                <Grid item
                    xs={12} sm={12}
                    md={6} lg={5} xl={4}
                >
                    <DiaLetivoExtraForm
                        diaLetivoExtra={diaLetivoExtra}
                        setDiaLetivoExtra={setDiaLetivoExtra}
                        onSubmit={onSubmit}
                        onRemove={onRemove}
                        isLoadingAction={isLoadingAction}
                    />
                </Grid> :
                <Grid
                    item
                    xs={12}
                >
                    <DiaLetivoExtraTable
                        listDiaLetivoExtra={listDiaLetivoExtra}
                        setDiaLetivoExtra={setDiaLetivoExtra}
                        isLoading={isLoading}
                    />
                </Grid>
            }

        </Grid>
    );
}

export default DiaLetivoExtraContainer;