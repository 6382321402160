import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { findByCodLivroPage } from '../Capitulo/api-capitulo';
import { save } from '../Planejamento/api-planejamento';
import Button from '@material-ui/core/Button';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '60%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function PlanejamentoLoteBimestreForm({ tdp, updatePlanejamentos }) {
    const classes = useStyles();
    const [capitulos, setCapitulos] = useState([]);
    const [bimestre, setBimestre] = useState(1);

    useEffect(() => {
        if (tdp.livro) {
            findByCodLivroPage(tdp.livro.codLivro, 0).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setCapitulos(data);
                }

            }).catch(err => {
                console.log(err);
            })
        }


    }, [tdp.livro]);


    const handleChangePage = (event, newPage) => {
        findByCodLivroPage(tdp.livro.codLivro, newPage).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCapitulos(data);
            }
        });
    };

    const adicionarPlanejamento = (capitulo) => {
        let planejamento = {
            capitulo: capitulo,
            tdp: tdp,
            quantidadeAulas: 1,
            numeroBimestre: bimestre
        }

        save(planejamento).then((data) => {
            if (data.error) {
                console.log(data);
            }
            else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                updatePlanejamentos();
            }
        })

    }

    const handleBimestreChange = (event) => {
        setBimestre(event.target.value);
    }

    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container className={classes.paper} component={Paper} maxWidth="sm">
                <Typography className={classes.title} variant="h5">
                    Cadastro Planejamento Lote
                </Typography>
                <FormControl required fullWidth className={classes.formControl}>
                    <InputLabel id="bimestre-label">Bimestre</InputLabel>
                    <Select
                        labelId="bimestre-label"
                        id="bimestre-select"
                        value={bimestre}
                        onChange={handleBimestreChange}
                    >
                        <MenuItem value={1}>1º Bimestre</MenuItem>
                        <MenuItem value={2}>2º Bimestre</MenuItem>
                        <MenuItem value={3}>3º Bimestre</MenuItem>
                        <MenuItem value={4}>4º Bimestre</MenuItem>
                    </Select>
                </FormControl>

                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Capítulo</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {capitulos && capitulos.content && capitulos.content.length > 0 ? capitulos.content.map((capitulo) => (
                            <TableRow key={capitulo.codigo}>
                                <TableCell>{capitulo.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => adicionarPlanejamento(capitulo)}
                                        className={classes.submit}>
                                        Adicionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )) : ''}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[]}
                    rowsPerPage={capitulos.size ? capitulos.size : 0}
                    component="div"
                    count={capitulos.totalElements}
                    page={capitulos.number}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                />
            </Container>
        </div>
    );
}