import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextFieldDate from '../../UI/TextFieldDate';
import { dateFormatMoment } from '../../../util/DateUtil';
import Button from '../../UI/Button';
import { confirmAlert } from 'react-confirm-alert';
import { errorMessage, successMessage } from '../../../util/ConfirmAlertUtil';
import { serviceExcludeByDate, serviceIncludeByDate } from '../api-presenca';
import LoadingDialog from '../../UI/LoadingDialog';
import { Divider } from '@material-ui/core';
import { serviceUpdateByDate, serviceUpdateByDateAndTurma } from '../../PresencaAluno/api-presenca-aluno';
import Select from '../../UI/Select';
import { findByAnoLetivo } from '../../Turma/api-turma';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
});

export default function PresencaLoteContainer() {
    const classes = useStyles();
    const [dataInclusao, setDataInclusao] = useState(null);
    const [isLoadingInclusao, setIsLoadingInclusao] = useState(false);
    const [dataExclusao, setDataExclusao] = useState(null);
    const [isLoadingExclusao, setIsLoadingExclusao] = useState(false);
    const [dataAtualizacaoPA, setDataAtualizacaoPA] = useState(null);
    const [isLoadingAtualizacaoPA, setIsLoadingAtualizacaoPA] = useState(false);
    const [dataAtualizacaoPAAndTurma, setDataAtualizacaoPAAndTurma] = useState(null);
    const [isLoadingAtualizacaoPAAndTurma, setIsLoadingAtualizacaoPAAndTurma] = useState(false);
    const [codTurma, setCodTurma] = useState(null);
    const [listTurma, setListTurma] = useState([]);

    useEffect(() => {
        findByAnoLetivo().then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListTurma(
                        data.map((d) => ({
                            value: d.codigo,
                            description: d.descricao
                        })))
                }
            }
        })
    }, []);

    const handleDataInclusaoChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataInclusao(data);
    }

    const handleDataExclusaoChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataExclusao(data);
    }

    const handleDataAtualizacaoPAChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataAtualizacaoPA(data);
    }

    const handleDataAtualizacaoPAAndTurmaChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataAtualizacaoPAAndTurma(data);
    }

    const handleChangeTurma = (event) => {
        setCodTurma(event.target.value);
    }

    const handleClickPresencaInclusao = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja Iniciar o Serviço de inlcusão de presença por Data?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingInclusao(true);
                        serviceIncludeByDate(dataInclusao).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.error);
                                } else {
                                    successMessage("Serviço de Inclusão de Datas realizado com sucesso.");
                                }
                            }
                            setIsLoadingInclusao(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleClickPresencaExclusao = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja Iniciar o Serviço de exclusão de presença por Data?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingExclusao(true);
                        serviceExcludeByDate(dataExclusao).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.error);
                                } else {
                                    successMessage("Serviço de Exclusão de Datas realizado com sucesso.");
                                }
                            }
                            setIsLoadingExclusao(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleClickPresencaAlunoAtualizacao = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja Iniciar o Serviço de atualização de presença aluno por Data?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAtualizacaoPA(true);
                        serviceUpdateByDate(dataAtualizacaoPA).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.error);
                                } else {
                                    successMessage("Serviço de Atualizacao de Datas realizado com sucesso.");
                                }
                            }
                            setIsLoadingAtualizacaoPA(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }




    const handleClickPresencaAlunoAndTurmaAtualizacao = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja Iniciar o Serviço de atualização de presença aluno por Data e Turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAtualizacaoPAAndTurma(true);
                        serviceUpdateByDateAndTurma(dataAtualizacaoPAAndTurma, codTurma).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.error);
                                } else {
                                    successMessage("Serviço de Atualizacao de Datas e Turma realizado com sucesso.");
                                }
                            }
                            setIsLoadingAtualizacaoPAAndTurma(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container direction="column" spacing={8}>
                {isLoadingInclusao &&
                    <LoadingDialog
                        openDialog={isLoadingInclusao}
                        setOpenDialog={setIsLoadingInclusao}
                        title="Em processo"
                        description="Serviço de Inclusão de Prensença por data em andamento..."
                    />
                }

                {isLoadingExclusao &&
                    <LoadingDialog
                        openDialog={isLoadingExclusao}
                        setOpenDialog={setIsLoadingExclusao}
                        title="Em processo"
                        description="Serviço de Exclusão de Prensença por data em andamento..."
                    />
                }
                {isLoadingAtualizacaoPA &&
                    <LoadingDialog
                        openDialog={isLoadingAtualizacaoPA}
                        setOpenDialog={setIsLoadingAtualizacaoPA}
                        title="Em processo"
                        description="Serviço de Atualizacao de Prensença Aluno (Presente-Falta) por data em andamento..."
                    />
                }
                {isLoadingAtualizacaoPAAndTurma &&
                    <LoadingDialog
                        openDialog={isLoadingAtualizacaoPAAndTurma}
                        setOpenDialog={setIsLoadingAtualizacaoPAAndTurma}
                        title="Em processo"
                        description="Serviço de Atualizacao de Prensença Aluno (Presente-Falta) por data e turma em andamento..."
                    />
                }
                <Grid item xs={3}>
                    <Typography variant="h6">
                        Presença Lote
                    </Typography>
                </Grid>
                <Grid item xs={6} >
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="body1">
                                Inclusão Presença por Data
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextFieldDate
                                required
                                label="Data"
                                value={dataInclusao ? dateFormatMoment(dataInclusao) : null}
                                onChange={handleDataInclusaoChange}
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClickPresencaInclusao}>
                                Incluir Prensenças
                            </Button>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item xs={6}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item><Divider /></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            <Typography variant="body1">
                                Exclusão Presença por Data
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextFieldDate
                                required
                                label="Data"
                                value={dataExclusao ? dateFormatMoment(dataExclusao) : null}
                                onChange={handleDataExclusaoChange}
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClickPresencaExclusao}>
                                Excluir Prensenças
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item><Divider /></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            <Typography variant="body1">
                                Atualização Presença Aluno por Data - (Presente-Falta)
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextFieldDate
                                required
                                label="Data"
                                value={dataAtualizacaoPA ? dateFormatMoment(dataAtualizacaoPA) : null}
                                onChange={handleDataAtualizacaoPAChange}
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClickPresencaAlunoAtualizacao}>
                                Atualizar Prensença Aluno
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item><Divider /></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            <Typography variant="body1">
                                Atualização Presença Aluno por Data e Turma - (Presente-Falta)
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextFieldDate
                                required
                                label="Data"
                                value={dataAtualizacaoPAAndTurma ? dateFormatMoment(dataAtualizacaoPAAndTurma) : null}
                                onChange={handleDataAtualizacaoPAAndTurmaChange}
                            />
                        </Grid>
                        <Grid item>
                            <Select
                                label="Turmas"
                                value={codTurma}
                                list={listTurma}
                                onChange={handleChangeTurma}
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClickPresencaAlunoAndTurmaAtualizacao}>
                                Atualizar Prensença Aluno
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Container>
    );
}