import { makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    imageView: {
        width: 80,
        height: 80,
        marginRight: 30
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
    },
})

const PictureAttach = ({ sm }) => {

    const classes = useStyles();

    return (
        <>
            {sm.pictureAttach &&
                <Button
                    component={Link}
                    to={{
                        pathname: '/image-full-screen',
                        state: {
                            uri: sm.pictureAttach
                        }
                    }}
                >
                    <div className={classes.imageView}>
                        <img
                            alt=''
                            className={classes.image}
                            src={sm.pictureAttach}
                        />
                    </div>
                </Button>
            }
        </>
    )
}

export default PictureAttach;