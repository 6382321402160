import React from "react";
import TextForm from "./componentsForm/TextoForm";
import ExerEssayForm from "./componentsForm/ExerEssayForm";
import ExerAlternativeForm from "./componentsForm/ExerAlternativeForm";
import VideoForm from "./componentsForm/VideoForm";
import ImagemForm from "./componentsForm/ImagemForm";

const Components = {
  text: TextForm,
  exerEssay: ExerEssayForm,
  exerAlternative: ExerAlternativeForm,
  video: VideoForm, 
  image: ImagemForm

};

export default conteudoSelected => {
  if (typeof Components[conteudoSelected.componente] !== "undefined") {
    return React.createElement(Components[conteudoSelected.componente], {
      key: conteudoSelected.codigo,
      conteudoSelected: conteudoSelected
    });
  }
  return React.createElement(
    () => <div>The component {conteudoSelected.componente} has not been created yet.</div>,
    { key: conteudoSelected.codigo }
  );
};