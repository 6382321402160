import React from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../../shared/components/Table/Actions';
import { dateFormat } from '../../../../util/DateUtil';
import Button from '../../../UI/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const IntegralPresencaAlunoUserTableSelect = ({ listaPresencaAluno, handleChangePage, setPresencaAluno }) => {
    const classes = useStyles();



    return (
        <>
            {listaPresencaAluno.totalElements > 0 ?
                <>
                    < Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.rowHeader}>Data</TableCell>
                                <TableCell className={classes.rowHeader}>Presença</TableCell>
                                <TableCell className={classes.rowHeader}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaPresencaAluno.content.map((presencaAluno) => (
                                <TableRow key={presencaAluno.codigo}>
                                    <TableCell>{dateFormat(presencaAluno.integralPresenca.data)}</TableCell>
                                    <TableCell>{presencaAluno.presente ? 'Presente' : 'Falta'}</TableCell>
                                    <TableCell>
                                        {presencaAluno.presente ?
                                            <Button
                                                onClick={() => setPresencaAluno(presencaAluno)}
                                            >
                                                Selecionar
                                            </Button> : ''
                                        }
                                    </TableCell>

                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={listaPresencaAluno.size ? listaPresencaAluno.size : 0}
                        component="div"
                        count={listaPresencaAluno.totalElements}
                        page={listaPresencaAluno.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </> :
                <Grid>
                    <Typography className={classes.title} variant="h6">
                        Nenhum Registro Cadastrado
                    </Typography>
                </Grid>
            }

        </>
    );
}

export default IntegralPresencaAlunoUserTableSelect;