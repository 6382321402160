import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'comunicado-usuario/';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

export const pageAndAlunoCodigo = (pageNumber) => {
  const url = SERVER_URL + controller + 'page-query?page=' + pageNumber + '&filtro=porAluno';
  return fetchGetAuth(url);
}

export const pageAndAlunoCodigoAndDataAteAndDataDe = (pageNumber, dataAte, dataDe) => {
  const url = SERVER_URL + controller + 'page-query?page=' + pageNumber + '&filtro=porAluno&dataAte=' + dataAte.getTime() + '&dataDe=' + dataDe.getTime();
  return fetchGetAuth(url);
}

export const pageAndAlunoCodigoAndDataParaAteAndDataParaDe = (pageNumber, dataAte, dataDe) => {
  const url = SERVER_URL + controller + 'page-query?page=' + pageNumber + '&filtro=porAluno&dataParaAte=' + dataAte.getTime() + '&dataParaDe=' + dataDe.getTime();
  return fetchGetAuth(url);
}

const findByComunicadoCodigo = (codComunicado, page) => {
  const url = SERVER_URL + controller + 'engajamento/' + codComunicado + "?page=" + page;
  return fetchGetAuth(url);
}

const getEngajamentoInfo = (codComunicado) => {
  const url = SERVER_URL + controller + 'engajamento-info/' + codComunicado;
  return fetchGetAuth(url);
}

const save = (comunicado) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, comunicado);
}

const findByTitulo = (titulo, page) => {
  const url = SERVER_URL + controller + 'titulo/' + titulo + '?page=' + page;
  return fetchGetAuth(url);
}

const remove = (codComunicado) => {
  const url = SERVER_URL + controller + codComunicado;
  return fetchRemoveAuth(url);
}

const findByCategoria = (codCategoria, page) => {
  const url = SERVER_URL + controller + 'categoria/' + codCategoria + '?page=' + page;
  return fetchGetAuth(url);
}

export const countByComunicadoCodigoAndPerfil = (codComunicado, perfil) => {
  const url = SERVER_URL + controller + 'count/' + codComunicado + '/' + perfil;
  return fetchGetAuth(url);
}

export {
  page,
  save,
  findByTitulo,
  remove,
  findByCategoria,
  findByComunicadoCodigo,
  getEngajamentoInfo
}