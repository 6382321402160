import {
    SET_CRONOGRAMA_AULA,
    SET_CRONOGRAMAS_AULA,
    ADD_CRONOGRAMAS_AULA,
    UPDATE_CRONOGRAMAS_AULA,
    REMOVE_CRONOGRAMAS_AULA,
    OPEN_CRONOGRAMA_AULA,
    CLOSE_CRONOGRAMA_AULA
} from './actionTypes'

export function setCronogramaAula(cronogramaAula) {
    return { type: SET_CRONOGRAMA_AULA, cronogramaAula }
}

export function setCronogramasAula(cronogramasAula) {
    return { type: SET_CRONOGRAMAS_AULA, cronogramasAula }
}

export function addCronogramasAula(cronogramaAula) {
    return { type: ADD_CRONOGRAMAS_AULA, cronogramaAula }
}

export function updateCronogramasAula(cronogramaAula) {
    return { type: UPDATE_CRONOGRAMAS_AULA, cronogramaAula }
}

export function removeCronogramasAula(codigo) {
    return { type: REMOVE_CRONOGRAMAS_AULA, codigo }
}

export function openCronogramaAula() {
    return { type: OPEN_CRONOGRAMA_AULA }
}

export function closeCronogramaAula() {
    return { type: CLOSE_CRONOGRAMA_AULA }
}