import {
    ADD_COMUNICADO_CATEGORIAS, 
    UPDATE_COMUNICADO_CATEGORIAS, 
    SET_COMUNICADO_CATEGORIAS, 
    REMOVE_COMUNICADO_CATEGORIAS,
    SET_COMUNICADO_CATEGORIA
} from './actionTypes'

export function addCategorias (categoria) {
    return { type: ADD_COMUNICADO_CATEGORIAS, categoria }
}

export function updateCategorias(categoria){
    return {type : UPDATE_COMUNICADO_CATEGORIAS, categoria}
}

export function setCategorias(categorias){
    return {type : SET_COMUNICADO_CATEGORIAS, categorias}
}

export function removeCategorias(codCategoria){
    return {type : REMOVE_COMUNICADO_CATEGORIAS, codCategoria}
}

export function setCategoria(categoria){
    return {type : SET_COMUNICADO_CATEGORIA, categoria}
}
