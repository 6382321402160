import { SERVER_URL } from "../../../constants"
import { fetchGetAuth, fetchGetAuthBlob, fetchPostAuth } from "../../../util/FetchUtil";

const controller = 'instituicao-providence'

export const findByCurrentUser = () => {
    const url = SERVER_URL + controller;
    return fetchGetAuth(url);
}

export const save = (instituicaoProvidence) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, instituicaoProvidence);
}

export const downloadProperties = () => {
    const url = SERVER_URL + controller + '/download-properties';
    return fetchGetAuthBlob(url);
}