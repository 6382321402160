import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Loading from "../UI/Loading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const AlunoTurmaTableSelectView = ({
  alunosTurma,
  handleSelectAlunoTurma,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title} variant="body1">
        Selecione um aluno turma:
      </Typography>
      {isLoading ? (
        <Loading description="Carregando Alunos Turma..." />
      ) : (
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Número Chamada</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alunosTurma.map((at) => (
              <TableRow key={at.codigo}>
                <TableCell>{at.aluno.nome}</TableCell>
                <TableCell>{at.numeroChamada}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleSelectAlunoTurma(at)}
                    variant="contained"
                    color="secondary"
                  >
                    Selecionar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default AlunoTurmaTableSelectView;
