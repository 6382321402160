import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { rltRendimentoEscolarUserWeb, rltRendimentoEscolarWeb } from "../api-relatorio";
import CircularProgress from '@material-ui/core/CircularProgress';
import RelatorioCabecalhoRendimentoWeb from "./Cabecalho/RelatorioCabecalhoRendimentoWeb";
import { noteRound } from "../../../util/DoubleUtil";
import { findByTurma } from '../../AlunoTurma/api-aluno-turma';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        margin: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    },
    header1: {
        border: 'black 3px solid',
        padding: '10px',
        textAlign: 'center',
        margin: '20px'
    },
    loading: {
        marginTop: '20px'
    }
});

export default function RelatorioRendimentoEscolarWeb(props) {
    const classes = useStyles();
    const [listaRE, setListaRE] = useState([]);
    const numeroBimestre = props.location.state.numeroBimestre;
    const turma = props.location.state.turma;
    const [codAlunosTurmas, setCodAlunosTurmas] = useState(props.location.state.codAlunosTurmas);
    const [isLoading, setIsLoading] = useState(true);
    const progress = useRef(0);

    useEffect(() => {
        if (turma && turma.codigo) {
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setCodAlunosTurmas(data.map((d) => d.codigo))
                }
            })
        }
    }, [turma]);

    useEffect(() => {
        if (turma || codAlunosTurmas) {
            if (codAlunosTurmas && codAlunosTurmas.length > 0) {
                async function asyncRendimentoReport() {
                    while (progress.current < codAlunosTurmas.length) {
                        await rltRendimentoEscolarWeb(numeroBimestre, codAlunosTurmas[progress.current]).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setListaRE((prevState) => ([
                                    ...prevState,
                                    ...data
                                ]));
                            }
                            progress.current = progress.current + 1;
                        });

                    }
                    setIsLoading(false);
                }
                asyncRendimentoReport();
            }


        } else {
            rltRendimentoEscolarUserWeb(numeroBimestre).then((data) => {
                setIsLoading(false);
                if (data.error) {
                    console.log(data);
                } else {
                    setListaRE(data);
                }
            });
        }

    }, [numeroBimestre, turma, codAlunosTurmas]);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                {listaRE.map((rend, index) => (
                    <div key={index} style={{ marginBottom: '80px' }}>
                        <Typography style={{ textAlign: 'center' }} variant="h5">
                            Rendimento Escolar
                        </Typography>
                        <RelatorioCabecalhoRendimentoWeb rendimento={rend} />
                        {rend.listaRTDP.map((rTdp, index) => (
                            <div key={index}>
                                <Typography className={classes.header1} variant="h6">
                                    <strong>Disciplina:</strong> {rTdp.tdp.disciplina.descricao}
                                    <strong> - Professor(a):</strong> {rTdp.tdp.usuario && rTdp.tdp.usuario.nome ? rTdp.tdp.usuario.nome.split(' ')[0] : ''}
                                    {rTdp.tdp.turma.serie.codSerie >= 5 && !rTdp.tdp.eProjeto && !rTdp.tdp.eEletiva ?
                                        <>
                                            <strong> - Nota:</strong> {noteRound(rTdp.mediaBimestral.nota)}
                                        </>
                                        : ''}
                                    {rTdp.tdp.turma.serie.codSerie >= 5 && (rTdp.tdp.eProjeto || rTdp.tdp.eEletiva) ?
                                        <>
                                            <strong> - Conceito:</strong> {rTdp.conceitoAluno.conceito && rTdp.conceitoAluno.conceito.descricao ? rTdp.conceitoAluno.conceito.descricao : ''}
                                        </>
                                        : ''}
                                    <strong> - Faltas:</strong> {rTdp.totalFaltas}
                                </Typography>
                                {rTdp.listaOA.length > 0 ?
                                    <>
                                        <Typography className={classes.title} variant="h6">
                                            <strong>Observações:</strong>
                                        </Typography>
                                        {rTdp.listaOA.map((oa) => (
                                            <p
                                                className={classes.title}
                                                key={oa.codigo}
                                            >
                                                {oa.conteudo}
                                            </p>
                                        ))}

                                    </>

                                    : ''}


                            </div>
                        ))}

                    </div>
                ))}
                {isLoading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                        Carregando...
                    </div> :
                    ''
                }







            </Container>

        </div>
    );
}