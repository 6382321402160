import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";

const TarefaFiltro = ({ filtros, filtro, handleChange }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Filtro</FormLabel>
      <RadioGroup
        aria-label="filtro"
        name="value"
        value={filtro.value}
        onChange={handleChange}
      >
        {filtros.map((f) => (
          <FormControlLabel
            value={f.value}
            control={<Radio />}
            label={f.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default TarefaFiltro;
