import {
  SET_NOTA_BIMESTRE_TIPO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function notaBimestreTipo(state = null, action) {
  switch (action.type) {
    case SET_NOTA_BIMESTRE_TIPO:
      return action.notaBimestreTipo

    case PURGE:
      return null;

    default:
      return state
  }
}
