import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByNome, findByEmail } from './api-usuario';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuarioSearch, closeUsuario } from './actions/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    message: {
        marginTop: '10px'
    }
}));



export default function UsuarioFindDialogRedux() {
    const classes = useStyles();
    const [usuarios, setUsuarios] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [opcoesBusca, setOpcoesBusca] = useState({
        por: 'nome',
        busca: ''
    });
    const dispatch = useDispatch();
    const openSearchUsuario = useSelector(state => state.openSearchUsuario);

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setMessage(null);
        if (opcoesBusca.por === 'nome') {
            findByNome(opcoesBusca.busca).then((data) => {
                if (data.length === 0) {
                    setMessage("Nenhum Usuário encontrado.")
                } else if (data.error) {
                    setMessage(data.error);
                }
                else {
                    setUsuarios(data);
                    setIsLoading(false);
                }

            });
        } else if (opcoesBusca.por === 'email') {
            findByEmail(opcoesBusca.busca).then((data) => {
                if (data.length === 0) {
                    setMessage("Nenhum Usuário encontrado.")
                } else if (data.error) {
                    setMessage(data.error);
                }
                else {
                    setUsuarios(data);
                    setIsLoading(false);
                }
            });
        }
    }

    const handleBuscaChange = (event) => {
        setOpcoesBusca({
            ...opcoesBusca,
            [event.target.name]: event.target.value
        });

    }

    const handleSelectedUsuario = (usuario) => {
        dispatch(setUsuarioSearch(usuario));
        dispatch(closeUsuario());
    }

    const handleClose = () => {
        dispatch(closeUsuario());
    };

    return (
        <>
            <Dialog open={openSearchUsuario} onClose={handleClose} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Procurar Usuário</DialogTitle>
                <DialogContent>
                    <form onSubmit={clickSubmit}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Buscar por:</FormLabel>
                            <RadioGroup aria-label="gender" name="por" value={opcoesBusca.por} onChange={handleBuscaChange}>
                                <FormControlLabel value="nome" control={<Radio />} label="Nome" />
                                <FormControlLabel value="email" control={<Radio />} label="E-mail" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Busca"
                            name="busca"
                            autoFocus
                            onChange={handleBuscaChange}
                            value={opcoesBusca.busca || ''}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Buscar
                        </Button>
                    </form>
                    {message &&
                        <Typography className={classes.message}>
                            {message}
                        </Typography>
                    }
                    {!isLoading ?
                        <>
                            {usuarios.length > 0 &&
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>E-mail</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usuarios.map((usuario) => (
                                            <TableRow key={usuario.id}>
                                                <TableCell>{usuario.nome}</TableCell>
                                                <TableCell>{usuario.email}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        onClick={() => handleSelectedUsuario(usuario)}
                                                        variant="contained"
                                                        color="secondary">
                                                        Selecionar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            }
                        </> :
                        <div className={classes.loading}>
                            <CircularProgress color="primary" /> Carregando...
                        </div>
                    }



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}