import { Button, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import Select from "../UI/Select";
import { monthList } from "../../util/DateUtil";

const PresencaTableFilter = ({ mesSelect, setMesSelect }) => {
  const onChangeMonth = (event) => {
    setMesSelect(event.target.value);
  };

  return (
    <Grid
      style={{ padding: "20px" }}
      container
      component={Paper}
      spacing={2}
      direction="column"
    >
      <Grid item>
        <Typography variant="h6">Filtro</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={3} alignItems="center">
          <Grid item>
            <Select
              label="Por Mês"
              value={mesSelect}
              list={monthList}
              onChange={onChangeMonth}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => setMesSelect("")}
              size="small"
              variant="outlined"
            >
              Limpar Filtro
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PresencaTableFilter;
