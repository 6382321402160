import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-avaliacao';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
    setAvaliacao as setAvaliacaoRedux,
    addAvaliacoes,
    updateAvaliacoes
} from './actions/actions';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ptLocale from 'date-fns/locale/pt-BR';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: "20px"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
}));

export default function AtividadeForm() {
    const classes = useStyles();
    const avaliacaoRedux = useSelector(state => state.avaliacao);
    const [avaliacao, setAvaliacao] = useState({});
    const [enviarPor] = useState([
        'Aluno',
        'Turma'
    ]);
    const dispatch = useDispatch();


    useEffect(() => {
        setAvaliacao(avaliacaoRedux);
    }, [avaliacaoRedux])


    const handleChange = (event) => {
        setAvaliacao({
            ...avaliacao,
            [event.target.name]: event.target.value
        })
    }

    const handleDataHoraChange = (name, dataHora) => {
        setAvaliacao({
            ...avaliacao,
            [name]: dataHora
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const codAvaliacao = avaliacao.codigo;
        save(avaliacao).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (codAvaliacao) {
                    dispatch(updateAvaliacoes(data));
                } else {
                    dispatch(addAvaliacoes(data));
                }
                dispatch(setAvaliacaoRedux(data));
                setAvaliacao(data);
            }

        }).catch(err => {
            console.log(err);
        })

    }

    const handleSwitchChange = name => event => {
        setAvaliacao((prevState) => ({
            ...prevState,
            [name]: event.target.checked
        }));
    };

    const handleEnviarPorChange = (event) => {
        const ep = enviarPor.find((ep) => {
            return ep === event.target.value
        });
        setAvaliacao({
            ...avaliacao,
            enviarPor: ep
        });
    }


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <Container className={classes.paper} component={Paper}>
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <CloseIcon
                            onClick={() => dispatch(setAvaliacaoRedux(null))}
                            className={classes.closeIcon}
                            fontSize='small'
                        />
                    </Grid>
                </Grid>
                <form onSubmit={clickSubmit}>
                    <div>
                        <TextField
                            size='small'
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            label="Título"
                            name="titulo"
                            onChange={handleChange}
                            value={avaliacao && avaliacao.titulo ? avaliacao.titulo : ''}
                        />
                        <TextField
                            size='small'
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            label="Conteúdo"
                            name="conteudo"
                            onChange={handleChange}
                            value={avaliacao && avaliacao.conteudo ? avaliacao.conteudo : ''}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={avaliacao && avaliacao.esimulado ? true : false}
                                    onChange={handleSwitchChange('esimulado')}
                                    value={avaliacao && avaliacao.esimulado ? true : false}
                                />
                            }
                            label="Simulado"
                        />
                        {!avaliacao.esimulado ?
                            <>
                                <FormControl fullWidth >
                                    <InputLabel id="enviar-por-label">Enviar Por:</InputLabel>
                                    <Select
                                        labelId="enviar-por-label"
                                        id="enviar-por-label"
                                        value={
                                            avaliacao.enviarPor ?
                                                avaliacao.enviarPor :
                                                ''
                                        }
                                        onChange={handleEnviarPorChange}
                                    >
                                        {enviarPor.map((ep, index) => (
                                            <MenuItem key={index} value={ep}>
                                                {ep}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                <DateTimePicker
                                    fullWidth
                                    ampm={false}
                                    required
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    label="Data Hora Início"
                                    value={avaliacao && avaliacao.dataHoraInicio ? avaliacao.dataHoraInicio : null}
                                    onChange={(event) => handleDataHoraChange('dataHoraInicio', event)}
                                    keyboardbuttonprops={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                <DateTimePicker
                                    fullWidth
                                    ampm={false}
                                    required
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    label="Data Hora Fim"
                                    value={avaliacao && avaliacao.dataHoraFim ? avaliacao.dataHoraFim : null}
                                    onChange={(event) => handleDataHoraChange('dataHoraFim', event)}
                                    keyboardbuttonprops={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={avaliacao && avaliacao.mostrarRespostas ? true : false}
                                            onChange={handleSwitchChange('mostrarRespostas')}
                                            value={avaliacao && avaliacao.mostrarRespostas ? true : false}
                                        />
                                    }
                                    label="Mostrar Respostas"
                                />
                            </>
                            : ''}


                    </div>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>


        </MuiPickersUtilsProvider>
    );
}