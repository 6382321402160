import { SERVER_URL } from '../../constants';
import {
  fetchPostAuthFile, 
  fetchGetAuth, 
  fetchRemoveAuth
} from '../../util/FetchUtil';

const controller = 'atividade-aluno-anexo/';


const attachFile = (codSala, fileForm) => {
  const url = SERVER_URL + controller + "upload-file/" + codSala;
  return fetchPostAuthFile(url, fileForm);
}

const findByCodAtividadeAluno = (codAtividadeAluno) => {
  const url = SERVER_URL + controller + codAtividadeAluno;
  return fetchGetAuth(url);
}

const deleteById = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuth(url);
}

export {
  attachFile, 
  findByCodAtividadeAluno, 
  deleteById
}