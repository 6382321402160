import { makeStyles } from '@material-ui/core';
import React from 'react';
import TablePaginationActions from '../../shared/components/Table/Actions';
import { dateFormat } from '../../util/DateUtil';
import Button from '../UI/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    submit: {
        marginRight: '8px',
    }
})

const MuralTable = ({
    pageMural,
    setMural,
    handleChangePage
}) => {
    const classes = useStyles();

    return (
        <>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Criado Por</TableCell>
                        <TableCell>Data Criação</TableCell>
                        <TableCell >Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pageMural.content.map((mural) => (
                        <TableRow key={mural.codigo}>
                            <TableCell>{mural.descricao}</TableCell>
                            <TableCell>{mural.usuario.nome}</TableCell>
                            <TableCell>{dateFormat(mural.createdAt)}</TableCell>
                            <TableCell>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setMural(mural);
                                    }}
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={pageMural.size ? pageMural.size : 0}
                component="div"
                count={pageMural.totalElements}
                page={pageMural.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />
        </>
    )
}

export default MuralTable;