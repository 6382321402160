import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TablePagination,
  Typography,
} from "@material-ui/core";
import React from "react";
import TablePaginationActions from "../../../shared/components/Table/Actions";
import { dateFormatMoment, dateTimeFormat } from "../../../util/DateUtil";
import Loading from "../../UI/Loading";
import { useSelector } from "react-redux";
import {
  ADMIN,
  COORDENADOR,
  PROFESSOR,
} from "../../Usuario/Perfil";
import Button from "../../UI/Button";
import TextFieldDate from "../../UI/TextFieldDate";

const useStyles = makeStyles({
  text: {
    textAlign: "center",
  },
  divBorderFiltro: {
    border: "1px solid black",
    padding: "20px",
    margin: "20px",
    borderRadius: "20px",
  },
});

const CatracaRegistroUsuarioList = ({
  listCR,
  isLoading,
  handleChangePage,
  handleNewRecord,
  fetchData,
  handleDataChange,
  dateSelected,
  porDataKey,
  filtro,
  handleChangeFiltro,
  todosKey,
}) => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);

  return (
    <Grid container spacing={3} direction="column">
      {usuario.perfil === ADMIN ||
      usuario.perfil === COORDENADOR ||
      usuario.perfil === PROFESSOR ? (
        <Grid item>
          <div className={classes.divBorderFiltro}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Filtro</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={filtro}
                onChange={handleChangeFiltro}
              >
                <FormControlLabel
                  value={todosKey}
                  control={<Radio />}
                  label="Todos"
                />
                <FormControlLabel
                  value={porDataKey}
                  control={<Radio />}
                  label="Por Data"
                />
              </RadioGroup>
            </FormControl>
            {filtro === porDataKey && (
              <Grid container>
                <Grid item xs={5}>
                  <TextFieldDate
                    label="Data"
                    value={dateSelected ? dateFormatMoment(dateSelected) : null}
                    onChange={handleDataChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => fetchData(0)}
                    color="primary"
                  >
                    Aplicar Datas
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
      ) : (
        ""
      )}

      {usuario.perfil === ADMIN || usuario.perfil === COORDENADOR ? (
        <Grid item>
          <Button onClick={handleNewRecord}>Novo Registro</Button>
        </Grid>
      ) : (
        ""
      )}

      <>
        {isLoading ? (
          <Grid item>
            <Loading description="Carregando Registros..." />
          </Grid>
        ) : (
          <Grid item>
            <Grid container spacing={3} direction="row">
              {listCR.content.map((catracaRegistro) => (
                <>
                  <Grid item xs={3}>
                    <Card variant="outlined">
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                          <Typography className={classes.text} variant="h5">
                            {catracaRegistro.entradaSaida}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.text}
                            variant="subtitle1"
                          >
                            {dateTimeFormat(catracaRegistro.dataHora)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.text} variant="body1">
                            {catracaRegistro.entradaSaida === "ENTRADA" &&
                              "Entrada Liberada"}
                            {catracaRegistro.saiuSozinho &&
                              "Autorizado(a) a Sair Sozinho(a)"}
                            {catracaRegistro.usuarioRetirou &&
                              "Acompanhado(a) com " +
                                catracaRegistro.usuarioRetirou.nome +
                                "."}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        )}
      </>
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage={listCR.size ? listCR.size : 0}
          component="div"
          count={listCR.totalElements}
          page={listCR.number}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            from +
            "-" +
            to +
            " de " +
            (count !== -1 ? count + " registros" : +" mais de " + to)
          }
        />
      </Grid>
    </Grid>
  );
};

export default CatracaRegistroUsuarioList;
