import {
    SET_NOTA_BIMESTRE, 
    SET_NOTAS_BIMESTRE, 
    UPDATE_NOTAS_BIMESTRE, 
    REMOVE_NOTAS_BIMESTRE
} from './actionTypes'

export function setNotaBimestre(notaBimestre){
    return {type : SET_NOTA_BIMESTRE, notaBimestre}
}

export function setNotasBimestre(notasBimestre){
    return {type : SET_NOTAS_BIMESTRE, notasBimestre}
}

export function updateNotasBimestre(notaBimestre){
    return {type : UPDATE_NOTAS_BIMESTRE, notaBimestre}
}

export function removeNotasBimestre(codNotaBimestre){
    return {type : REMOVE_NOTAS_BIMESTRE, codNotaBimestre}
}