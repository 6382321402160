import { SERVER_URL } from '../../constants';
import { fetchGetAuthBlob, fetchGetAuth } from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';

const controller = 'relatorio/';

const rltBoletimAluno = (relatorioAluno) => {
  const url = SERVER_URL + controller + 'boletim-aluno?codAlunoTurma=' + relatorioAluno.alunoTurma.codigo +
    '&anoLetivo=' + session.getAnoLetivo() + '&numeroBimestre=' + relatorioAluno.numeroBimestre;
  return fetchGetAuthBlob(url);
}

const rltBoletimWeb = (numeroBimestre, codAluno) => {
  const url = SERVER_URL + controller + 'boletim-web/' + session.getAnoLetivo() + '/' + numeroBimestre + "/" + codAluno;
  return fetchGetAuth(url);
}

const rltBoletimItinerarioWeb = (numeroBimestre, codAluno) => {
  const url = SERVER_URL + controller + 'boletim-itinerario-web/' + session.getAnoLetivo() + '/' + numeroBimestre + "/" + codAluno;
  return fetchGetAuth(url);
}

const rltBoletimItinerarioWebTurma = (numeroBimestre, codTurma) => {
  const url = SERVER_URL + controller + 'boletim-itinerario-web-turma/' + session.getAnoLetivo() + '/' + numeroBimestre + "/" + codTurma;
  return fetchGetAuth(url);
}

const rltAtaFinalWeb = (codAlunoTurma) => {
  const url = SERVER_URL + controller + 'ata-final-web/' + session.getAnoLetivo() + '/' + codAlunoTurma;
  return fetchGetAuth(url);
}

export const rltFrequenciaWeb = (codAlunoTurma) => {
  const url = SERVER_URL + controller + 'frequencia-web/' + codAlunoTurma;
  return fetchGetAuth(url);
}

export const rltFrequenciaWebTdp = (codAlunoTurma, codTdp) => {
  const url = SERVER_URL + controller + 'frequencia-web-tdp/' + codAlunoTurma + '/' + codTdp;
  return fetchGetAuth(url);
}

export const rltConfereRegAulaFaltaWebTurma = (turma) => {
  const url = SERVER_URL + controller + 'confere-registro-aula-falta-turma/' + turma.codigo;
  return fetchGetAuth(url);
}

export const rltConfereRegAulaFaltaWebTdp = (codTdp) => {
  const url = SERVER_URL + controller + 'confere-registro-aula-falta-tdp/' + codTdp;
  return fetchGetAuth(url);
}

export const rltConfereRegAulaFaltaWebAlunoTurma = (codAlunoTurma) => {
  const url = SERVER_URL + controller + 'confere-registro-aula-falta-aluno-turma/' + codAlunoTurma;
  return fetchGetAuth(url);
}

export const rltConfereRegAulaFaltaWeb = (codAlunoTurma, codTdp, codBimestre) => {
  const url = SERVER_URL + controller + 'confere-registro-aula-falta/' + codAlunoTurma + '/' + codTdp + '/' + codBimestre;
  return fetchGetAuth(url);
}

const rltAtaFinalTurmaWeb = (codAlunoTurma) => {
  const url = SERVER_URL + controller + 'ata-final-turma-web/' + codAlunoTurma;
  return fetchGetAuth(url);
}

const rltRendimentoEscolarUserWeb = (numeroBimestre) => {
  const url = SERVER_URL + controller + 'rendimento-user-web/' + session.getAnoLetivo() + '/' + numeroBimestre;
  return fetchGetAuth(url);
}

const rltRendimentoEscolarWeb = (numeroBimestre, codAlunoTurma) => {
  const url = SERVER_URL + controller + 'rendimento-web/' + session.getAnoLetivo() + '/' + numeroBimestre + "/" + codAlunoTurma;
  return fetchGetAuth(url);
}

const rltRendimentoEscolarWebTurma = (codTurma, numeroBimestre) => {
  const url = SERVER_URL + controller + 'rendimento-web-turma/' + codTurma + '/' + numeroBimestre;
  return fetchGetAuth(url);
}

const rltBoletimUser = (relatorioAluno) => {
  const url = SERVER_URL + controller + 'boletim-user/' + session.getAnoLetivo() + '/' + relatorioAluno.numeroBimestre;
  return fetchGetAuthBlob(url);
}

const rltRendimentoAluno = (relatorioAluno) => {
  const url = SERVER_URL + controller + 'rendimento-aluno/' + relatorioAluno.alunoTurma.codigo +
    '/' + session.getAnoLetivo() + '/' + relatorioAluno.numeroBimestre;
  console.log(url);
  return fetchGetAuthBlob(url);
}

const rltRendimentoUser = (relatorioAluno) => {
  const url = SERVER_URL + controller + 'rendimento-user/' + session.getAnoLetivo() + '/' + relatorioAluno.numeroBimestre;
  console.log(url);
  return fetchGetAuthBlob(url);
}

const rltAlunoTurma = (codTurma) => {
  const url = SERVER_URL + controller + 'aluno-turma/' + codTurma;
  return fetchGetAuthBlob(url);
}

const rltRendimentoEscolar = (codTurma, numeroBimestre) => {
  const url = SERVER_URL + controller + 'rendimento-escolar/' + codTurma + "/" + numeroBimestre;
  return fetchGetAuthBlob(url);
}

const rltBoletimEscolar = (codTurma, numeroBimestre) => {
  const url = SERVER_URL + controller + 'boletim-escolar/' + codTurma + "/" + numeroBimestre;
  return fetchGetAuthBlob(url);
}

const rltAtaFinal = (codTurma) => {
  const url = SERVER_URL + controller + 'ata-final/' + codTurma;
  return fetchGetAuthBlob(url);
}

const rltAtividadeNotaBimestre = (codTDP, numeroBimestre) => {
  const url = SERVER_URL + controller + 'atividade-nota-bimestre/' + codTDP + "/" + numeroBimestre;
  return fetchGetAuthBlob(url);
}

const rltConteudo = (codTDP) => {
  const url = SERVER_URL + controller + 'conteudo/' + codTDP;
  return fetchGetAuthBlob(url);
}

const rltMediaBimestralAno = (codTDP) => {
  const url = SERVER_URL + controller + 'media-bimestral-ano/' + codTDP;
  return fetchGetAuthBlob(url);
}

const rltContrFreqMan = (codTDP, numeroBimestre) => {
  const url = SERVER_URL + controller + 'contr-freq-manual/' + codTDP + "/" + numeroBimestre + "/" + session.getAnoLetivo();
  return fetchGetAuthBlob(url);
}

const rltContrNotaMan = (codTDP, numeroBimestre) => {
  const url = SERVER_URL + controller + 'contr-nota-manual/' + codTDP + "/" + numeroBimestre + "/" + session.getAnoLetivo();
  return fetchGetAuthBlob(url);
}

const rltFaltaBimestre = (codTDP, numeroBimestre) => {
  const url = SERVER_URL + controller + 'falta-bimestre/' + codTDP + "/" + numeroBimestre + "/" + session.getAnoLetivo();
  return fetchGetAuthBlob(url);
}

const rltObservacaoAluno = (codTDP, numeroBimestre) => {
  const url = SERVER_URL + controller + 'observacao-aluno/' + codTDP + "/" + numeroBimestre;
  return fetchGetAuthBlob(url);
}

export {
  rltBoletimAluno,
  rltAlunoTurma,
  rltAtaFinal,
  rltAtividadeNotaBimestre,
  rltConteudo,
  rltContrFreqMan,
  rltContrNotaMan,
  rltFaltaBimestre,
  rltMediaBimestralAno,
  rltObservacaoAluno,
  rltRendimentoEscolar,
  rltRendimentoAluno,
  rltBoletimEscolar,
  rltBoletimUser,
  rltRendimentoUser,
  rltBoletimWeb,
  rltRendimentoEscolarUserWeb,
  rltBoletimItinerarioWeb,
  rltAtaFinalWeb,
  rltRendimentoEscolarWebTurma,
  rltRendimentoEscolarWeb,
  rltBoletimItinerarioWebTurma,
  rltAtaFinalTurmaWeb
}