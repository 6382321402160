import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successDefault } from "../../../util/ToastUtil";
import Loading from "../../UI/Loading";
import TextField from "../../UI/TextField";
import { findByCurrentUser, save } from "./api-instituicao-providence";
import { downloadProperties } from "./api-instituicao-providence";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import { createAndDownloadBlobFile } from "../../../util/DownloadUtil";
import { useSelector } from "react-redux";
import { ADMIN } from "../../Usuario/Perfil";

const ConfiguracaoInstituicaoProvidenceForm = () => {
  const [instituicaoProvidence, setInstituicaoProvidence] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const usuario = useSelector((state) => state.usuario);

  useEffect(() => {
    if (usuario.perfil === ADMIN) {
      setIsLoading(true);
      findByCurrentUser().then((data) => {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setInstituicaoProvidence(data);
        }
        setIsLoading(false);
      });
    }
  }, [usuario.perfil]);

  const onChange = (event) => {
    setInstituicaoProvidence({
      ...instituicaoProvidence,
      [event.target.name]: event.target.value,
    });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    save(instituicaoProvidence).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setInstituicaoProvidence(data);
        successDefault();
      }
    });
  };

  const handleDownload = () => {
    downloadProperties().then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        createAndDownloadBlobFile(data, "application.properties");
      }
    });
  };

  return (
    <form onSubmit={clickSubmit}>
      <Grid container spacing={3} direction="column">
        {isLoading ? (
          <Loading description="Carregando Instituição Providence..." />
        ) : (
          <>
            <Grid item>
              <Button onClick={handleDownload} variant="outlined">
                Download Properties
              </Button>
            </Grid>
            {usuario.perfil === ADMIN ? (
              <>
                <Grid item>
                  <Grid container spacing={3} direction="row">
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Api Ip"
                        onChange={onChange}
                        name="apiIp"
                        value={
                          instituicaoProvidence && instituicaoProvidence.apiIp
                            ? instituicaoProvidence.apiIp
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Colesan Online Url"
                        onChange={onChange}
                        name="colesanOnlineUrl"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.colesanOnlineUrl
                            ? instituicaoProvidence.colesanOnlineUrl
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Impressora Bobina"
                        onChange={onChange}
                        name="impressoraBobina"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.impressoraBobina
                            ? instituicaoProvidence.impressoraBobina
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Ssh Port Winbox"
                        onChange={onChange}
                        name="sshPortWinbox"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.sshPortWinbox
                            ? instituicaoProvidence.sshPortWinbox
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Ssh Ip Winbox"
                        onChange={onChange}
                        name="sshIpWinbox"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.sshIpWinbox
                            ? instituicaoProvidence.sshIpWinbox
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Ssh User Winbox"
                        onChange={onChange}
                        name="sshUserWinbox"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.sshUserWinbox
                            ? instituicaoProvidence.sshUserWinbox
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Ssh Password Winbox"
                        onChange={onChange}
                        name="sshPasswordWinbox"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.sshPasswordWinbox
                            ? instituicaoProvidence.sshPasswordWinbox
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Aws Access Key Id"
                        onChange={onChange}
                        name="awsAccessKeyId"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.awsAccessKeyId
                            ? instituicaoProvidence.awsAccessKeyId
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Aws Secret Access Key"
                        onChange={onChange}
                        name="awsSecretAccessKey"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.awsSecretAccessKey
                            ? instituicaoProvidence.awsSecretAccessKey
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Login Colesan Online"
                        onChange={onChange}
                        name="loginColesanOnline"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.loginColesanOnline
                            ? instituicaoProvidence.loginColesanOnline
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <TextField
                        label="Senha Colesan Online"
                        onChange={onChange}
                        name="senhaColesanOnline"
                        value={
                          instituicaoProvidence &&
                          instituicaoProvidence.senhaColesanOnline
                            ? instituicaoProvidence.senhaColesanOnline
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="outlined">
                    Salvar
                  </Button>
                </Grid>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </Grid>
    </form>
  );
};

export default ConfiguracaoInstituicaoProvidenceForm;
