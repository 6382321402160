import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByLivroCodLivroAndAlunoCodigoAndAlunoInstituicaoCodigo } from './api-aluno-livro';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { errorMessage } from '../../util/ConfirmAlertUtil';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));


export default function AlunoLivroFindDialog({
    openSearchAlunoLivro,
    setOpenSearchAlunoLivro,
    livro,
    setAlunoLivro
}) {
    const classes = useStyles();
    const [alunosLivros, setAlunosLivros] = useState([]);
    const [busca, setBusca] = useState({
        type: 'codigo',
        value: ''
    });

    const clickSubmit = (event) => {
        event.preventDefault();
        findByLivroCodLivroAndAlunoCodigoAndAlunoInstituicaoCodigo(
            livro.codLivro,
            busca
        ).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setAlunosLivros(data);
            }

        });
    }


    const handleSelectedAlunoLivro = (alunoLivro) => {
        setAlunoLivro(alunoLivro);
        handleClose();
    }

    const handleClose = () => {
        setOpenSearchAlunoLivro(false);
    };

    const handleBuscaChange = (event) => {
        setBusca(event.target.value);
    }

    return (
        <Dialog
            open={openSearchAlunoLivro}
            onClose={handleClose}
            fullWidth

            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Procurar Livro</DialogTitle>
            <DialogContent>
                <form onSubmit={clickSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Descrição"
                        name="busca"
                        autoFocus
                        onChange={handleBuscaChange}
                        value={busca || ''}
                    />


                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Buscar
                    </Button>
                </form>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alunosLivros.map((alunoLivro) => (
                            <TableRow key={alunoLivro.codLivro}>
                                <TableCell>{alunoLivro.livro.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleSelectedAlunoLivro(alunoLivro)}
                                        variant="contained"
                                        color="secondary">
                                        Selecionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}