import {
  REMOVE_CAPITULOS,
  SET_CAPITULOS,
  ADD_CAPITULOS,
  UPDATE_CAPITULOS,
  SET_CAPITULO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function capitulo(state = null, action) {
  switch (action.type) {
    case SET_CAPITULO:
      return action.capitulo

    case PURGE:
      return null;

    default:
      return state
  }
}

export function capitulos(state = [], action) {

  switch (action.type) {

    case REMOVE_CAPITULOS:
      return state.filter((capitulo) => capitulo.codCapitulo !== action.codigo)

    case ADD_CAPITULOS:
      return [
        action.capitulo,
        ...state
      ]

    case SET_CAPITULOS:
      return action.capitulos

    case UPDATE_CAPITULOS:
      return state.map(capitulo => capitulo.codCapitulo === action.codCapitulo ? action.capitulo : capitulo)

    case PURGE:
      return [];

    default:
      return state
  }
}
