import {
    ADD_ALUNOS_FREQUENCIA, 
    UPDATE_ALUNOS_FREQUENCIA, 
    SET_ALUNOS_FREQUENCIA, 
    REMOVE_ALUNOS_FREQUENCIA,
    SET_ALUNO_FREQUENCIA, 
} from './actionTypes'

export function addAlunosFrequencia (alunoFrequencia) {
    return { type: ADD_ALUNOS_FREQUENCIA, alunoFrequencia }
}

export function updateAlunosFrequencia(alunoFrequencia){
    return {type : UPDATE_ALUNOS_FREQUENCIA, alunoFrequencia}
}

export function setAlunosFrequencia(alunosFrequencia){
    return {type : SET_ALUNOS_FREQUENCIA, alunosFrequencia}
}

export function removeAlunosFrequencia(index){
    return {type : REMOVE_ALUNOS_FREQUENCIA, index}
}

export function setAlunoFrequencia(alunoFrequencia){
    return {type : SET_ALUNO_FREQUENCIA, alunoFrequencia}
}