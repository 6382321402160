import React, { useState } from 'react';
import BimestreMenu from './BimestreMenu';
import Bimestre from './Bimestre';
import { Grid } from '@material-ui/core';
import DiaNaoLetivoContainerMenu from './DiaNaoLetivoMenuContainer';
import DiaLetivoExtraMenuContainer from './DiaLetivoExtraMenuContainer';


export const bimestreKey = 'bimestre';
export const diaLetivoExtraKey = 'diaLetivoExtra';
export const diaNaoLetivoKey = 'diaNaoLetivo';

const BimestreContainerMenu = () => {

    const [menu, setMenu] = useState(bimestreKey);

    return (
        <Grid
            container
            justifyContent='center'
            spacing={3}
        >
            <Grid
                item
                xs={8}
            >
                <BimestreMenu
                    menu={menu}
                    setMenu={setMenu}
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                {menu === bimestreKey &&
                    <Bimestre />
                }
                {menu === diaLetivoExtraKey &&
                    <DiaLetivoExtraMenuContainer />
                }
                {menu === diaNaoLetivoKey &&
                    <DiaNaoLetivoContainerMenu />
                }
            </Grid>
        </Grid >
    )
}



export default BimestreContainerMenu;