import {
    SET_TURMA, 
    SET_TURMAS, 
    OPEN_SEARCH_TURMAS, 
    CLOSE_SEARCH_TURMAS, 
    ADD_TURMAS, 
    UPDATE_TURMAS, 
    REMOVE_TURMAS
} from './actionTypes'


export function setTurma(turma) {
    return { type: SET_TURMA, turma }
}

export function setTurmas(turmas) {
    return { type: SET_TURMAS, turmas }
}

export function openSearchTurmas() {
    return { type: OPEN_SEARCH_TURMAS }
}

export function closeSearchTurmas() {
    return { type: CLOSE_SEARCH_TURMAS }
}

export function addTurmas (turma) {
    return { type: ADD_TURMAS, turma }
}

export function updateTurmas(turma){
    return {type : UPDATE_TURMAS, turma}
}

export function removeTurmas(codigo){
    return {type : REMOVE_TURMAS, codigo}
}