import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    findByCapituloCodCapitulo,
    findByCapituloCodCapituloNext,
    findByCapituloCodCapituloPrevious,
    findByCapituloCodCapituloAndCapituloLivroIsPublic,
    findByCapituloCodCapituloNextAndCapituloLivroIsPublic,
    findByCapituloCodCapituloPreviousAndCapituloLivroIsPublic
} from './api-conteudo';
import Components from "./components.js";
import { useSelector, useDispatch } from "react-redux";
import { setConteudos } from "./actions/actions";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { setCapitulo } from '../actions/actions'
import { useHistory } from "react-router-dom";
import ShareIcon from '@material-ui/icons/Share';
import Dialog from '../../UI/Dialog';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import LivroAutoresProfile from '../../Livro/Autor/LivroAutoresProfile';
import { MetaTags } from 'react-meta-tags';

const useStyles = makeStyles({
    voltarButton: {
        marginBottom: '20px',
        cursor: 'pointer'
    },
    loadingDiv: {
        display: 'flex',
        justifyContent: 'center',
    }
});

const ConteudoTable = () => {
    const conteudos = useSelector(state => state.conteudos);
    const capitulo = useSelector(state => state.capitulo);
    const livroInstituicao = useSelector(state => state.livroInstituicao);
    const tdp = useSelector(state => state.tdp);
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [descriptionDialog, setDescriptionDialog] = useState('');
    const usuario = useSelector(state => state.usuario);


    useEffect(() => {
        if (capitulo) {
            setIsLoading(true);
            if (usuario) {
                findByCapituloCodCapitulo(capitulo.codCapitulo).then((data) => {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        dispatch(setConteudos(data));
                    }
                    setIsLoading(false);
                })
            } else {
                findByCapituloCodCapituloAndCapituloLivroIsPublic(capitulo.codCapitulo).then((data) => {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        dispatch(setConteudos(data));
                    }
                    setIsLoading(false);
                })
            }

        }
    }, [capitulo, dispatch, usuario]);

    const handleNext = () => {
        setIsLoading(true);
        if (usuario) {
            findByCapituloCodCapituloNext(capitulo.codCapitulo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setConteudos(data));
                    if (data.length > 0) {
                        dispatch(setCapitulo(data[0].capitulo));
                    }
                }
                setIsLoading(false);
            })
        } else {
            findByCapituloCodCapituloNextAndCapituloLivroIsPublic(capitulo.codCapitulo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setConteudos(data));
                    if (data.length > 0) {
                        dispatch(setCapitulo(data[0].capitulo));
                    }
                }
                setIsLoading(false);
            })
        }

    }

    const handlePrevious = () => {
        setIsLoading(true);
        if (usuario) {
            findByCapituloCodCapituloPrevious(capitulo.codCapitulo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setConteudos(data));
                    if (data.length > 0) {
                        setCapitulo(data[0].capitulo);
                    }
                }
                setIsLoading(false);
            })
        } else {
            findByCapituloCodCapituloPreviousAndCapituloLivroIsPublic(capitulo.codCapitulo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setConteudos(data));
                    if (data.length > 0) {
                        setCapitulo(data[0].capitulo);
                    }
                }
                setIsLoading(false);
            })
        }

    }

    const handleGoBack = () => {
        if (livroInstituicao) {
            history.push('/livro-instituicao')
        } else {
            history.goBack();
        }

    }

    const handleShare = () => {
        setOpenDialog(true);
        setDescriptionDialog(usuario.instituicao.hostname + '/conteudos/' + capitulo.livro.codLivro + '/' + capitulo.codCapitulo);
    }


    return (
        <Grid container spacing={3}>
            <MetaTags>
                {capitulo.sobre && <meta name="description" content={capitulo.sobre} />}
                <meta property="og:title" content={capitulo.descricao} />
                {capitulo.livro.fotoCapa && <meta property="og:image" content={capitulo.livro.fotoCapa} />}
                <meta property="og:type" content="website" />
            </MetaTags>
            <Grid style={{ margin: '15px' }} item xs={12} component={Paper}>
                <Dialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    description={descriptionDialog}
                />
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleGoBack}
                            className={classes.voltarButton}
                        >
                            <ArrowBackIcon style={{ marginRight: '10px' }} />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleShare}
                        >
                            <ShareIcon
                                color="primary"
                                style={{ marginRight: '10px' }} />
                        </Button>
                    </Grid>
                </Grid>
                {isLoading ?
                    <div className={classes.loadingDiv}>
                        <CircularProgress
                            color="primary"
                            size={80}
                        />
                    </div> :
                    conteudos.map(conteudo => Components(conteudo, tdp, {}))
                }
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px'
                }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrevious}
                    >
                        Anterior
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleNext}
                    >
                        Próximo
                    </Button>
                </div>
            </Grid>

            <Grid item xs={12}>
                {capitulo && capitulo.livro && capitulo.livro.isPublic && <LivroAutoresProfile livro={capitulo.livro} />}
            </Grid>
        </Grid>
    );
}

export default ConteudoTable;