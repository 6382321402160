import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { findByLivroCodLivroAndPublic } from './api-livro-autor';

const LivroAutoresProfile = ({ livro }) => {
    const [livroAutores, setLivroAutores] = useState([]);

    useEffect(() => {
        if (livro && livro.codLivro) {
            findByLivroCodLivroAndPublic(livro.codLivro).then((data) => {
                if (data.error) {
                    console.log(data.message);
                } else {
                    setLivroAutores(data);
                }
            })
        }

    }, [livro]);


    return (
        <>
            {livroAutores.length && livroAutores.length > 0 ?
                <Grid style={{ marginBottom: '15px' }} container justifyContent='center' spacing={3}>
                    <Grid item component={Paper}>
                        <Grid container justifyContent='center' spacing={1} >
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontFamily: 'cursive'
                                    }}
                                    align='center'
                                    variant='h5'

                                >
                                    {livroAutores.length && livroAutores.length > 1 ? 'Autores' : 'Autor'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {livroAutores.map((la) => (
                                    <Grid key={la.codigo} container spacing={2} direction='row' alignItems='center'>
                                        <Grid item>
                                            <img
                                                src={la.usuario.foto ? la.usuario.foto : null}
                                                alt=''
                                                style={{ width: '80px', borderRadius: '40px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            fontFamily: 'cursive'
                                                        }}
                                                        variant='h6'

                                                    >
                                                        {la.usuario.nome}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            fontFamily: 'cursive',
                                                            whiteSpace: 'pre-line'
                                                        }}
                                                        variant='body1'
                                                    >
                                                        {la.usuario.descricaoAutor}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                : ''}

        </>
    );
}

export default LivroAutoresProfile;