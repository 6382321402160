import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '../../../UI/Container';
import IntegralProfessorTable from '../../Professor/IntegralProfessorTable';
import { findByTurmaAnoLetivo } from '../../Professor/api-integral-professor';
import Loading from '../../../UI/Loading';
import { findByIntegralProfessorCodigo } from '../api-integral-presenca-aluno';
import IntegralPresencaAlunoUserTable from './IntegralPresencaAlunoUserTable';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    divPresencaAlunoTable: {
        marginTop: '20px'
    }
})

const IntegralPresencaAlunoContainer = () => {
    const classes = useStyles();
    const [integralProfessor, setIntegralProfessor] = useState(null);
    const [listaIP, setListaIP] = useState([]);
    const [isLoadingIP, setIsLoadingIP] = useState(true);
    const [isLoadingPA, setIsLoadingPA] = useState(true);
    const [listaPresencaAluno, setListaPresencaAluno] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    })

    useEffect(() => {
        findByTurmaAnoLetivo().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaIP(data);
            }
            setIsLoadingIP(false);
        })
    }, [])

    useEffect(() => {
        if (integralProfessor) {
            setIsLoadingPA(true);
            findByIntegralProfessorCodigo(integralProfessor.codigo, 0).then((data) => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setListaPresencaAluno(data);
                    setIsLoadingPA(false);
                }
                console.log(data);
            })
        }

    }, [integralProfessor]);


    const handleChangePage = (event, newPage) => {
        setIsLoadingPA(true);
        findByIntegralProfessorCodigo(integralProfessor.codigo, newPage).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaPresencaAluno(data);
                setIsLoadingPA(false);
            }
        })
    };

    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Presença Aluno Integral
            </Typography>

            {isLoadingIP ? <Loading description='Carregando Integral Professor...' /> :
                <>
                    {integralProfessor ?
                        <>
                            {isLoadingPA ? <Loading description="Carregando Integral Presença Aluno" /> :
                                <IntegralPresencaAlunoUserTable
                                    listaPresencaAluno={listaPresencaAluno}
                                    handleChangePage={handleChangePage}
                                />
                            }

                        </>

                        :
                        <IntegralProfessorTable
                            listaIP={listaIP}
                            setIntegralProfessor={setIntegralProfessor}
                        />
                    }
                </>
            }



        </Container>
    )
}

export default IntegralPresencaAlunoContainer;