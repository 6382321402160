import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { save, deleteById } from './api-livro';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import TextField from '../UI/TextField';
import { Grid } from '@material-ui/core';
import Button from '../UI/Button';
import { confirmAlert } from 'react-confirm-alert';
import { getBase64 } from '../../util/Base64';
import { handleFileSizeException } from '../../util/ExceptionUtil';

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: '20px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    novoRegistro: {
        marginTop: '10px'
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '5px',
        cursor: 'pointer',
        alignContent: 'right',
    }
}));

const LivroForm = ({ livro, setLivro, history }) => {
    const classes = useStyles();
    const [livroPersist, setLivroPersist] = useState(livro);
    const hiddenFileInput = useRef(null);

    const handleChange = (event) => {
        setLivroPersist({
            ...livroPersist,
            [event.target.name]: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (!livroPersist.descricao) {
            toast.error("Preencha Descrição.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        save(livroPersist).then(data => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setLivro(data);
                setLivroPersist(data);
            }

        })
    }

    const handleDelete = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja remover este livro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleteById(livro.codLivro).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                handleCloseForm();
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    const handleCloseForm = () => {
        history.push('livro-autor');
    }

    const handleIsPublic = () => {
        setLivroPersist((prevState) => ({
            ...prevState,
            isPublic: !prevState.isPublic
        }))
    }

    const handleChangeImage = e => {
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i]
            if (file.size > 10000000) {
                handleFileSizeException();
                return;
            }
            getBase64(file)
                .then(result => {
                    setLivroPersist({
                        ...livroPersist,
                        fotoCapa: result,
                        fileType: file.type,
                        fileName: file.name,
                        fileSize: file.size
                    })
                });
        }
    };

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }

    const handleRemoveImage = () => {
        setLivroPersist({
            ...livroPersist,
            fotoCapa: null,
            fileType: null,
            fileName: null,
            fileSize: null
        })


    };

    return (
        <div>
            <Container className={classes.container} component={Paper} spacing={3} maxWidth="sm">
                <CloseIcon
                    onClick={handleCloseForm}
                    className={classes.closeIcon}
                    fontSize='small'
                />

                <form onSubmit={clickSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant="h6">
                                Cadastro Livro
                            </Typography>
                        </Grid>
                        {!livroPersist.eNovo ?
                            <Grid item xs={12}>
                                <Grid container direction='row' spacing={5}>
                                    <Grid item>
                                        {livroPersist.fotoCapa ?
                                            <img alt=''
                                                src={livroPersist.fotoCapa}
                                                style={{ width: '80px' }}
                                            /> :
                                            <div style={{
                                                width: '80px',
                                                borderWidth: '5px',
                                                borderStyle: 'solid',
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '15px'
                                            }}>
                                                Livro Sem Capa
                                            </div>
                                        }

                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png"
                                                    style={{ display: 'none' }}
                                                    ref={hiddenFileInput}
                                                    onChange={handleChangeImage}
                                                />
                                                <Button
                                                    onClick={handleAttachFile}
                                                >
                                                    Selecionar Foto Capa
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    color='secondary'
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remover Foto Capa
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid> : ''
                        }

                        <Grid item xs={12}>
                            <TextField
                                label="Descrição"
                                inputProps={{ maxLength: 45 }}
                                name="descricao"
                                onChange={handleChange}
                                value={livroPersist && livroPersist.descricao ? livroPersist.descricao : ''}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>
                                Este livro está em modo {livroPersist.isPublic ? "Público" : "Privado"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                color='secondary'
                                onClick={handleIsPublic}
                            >
                                Tornar {livroPersist.isPublic ? "Privado" : 'Público'}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Grid container direction='row' spacing={3}>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        className={classes.submit}>
                                        Salvar
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color='secondary'
                                        onClick={handleDelete}
                                    >
                                        Remover
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>



            </Container>
        </div>
    );
}

export default LivroForm;