import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid, makeStyles, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { findByIntegralPresencaCodigo, save as savePA } from './api-integral-presenca-aluno';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const IntegralPresencaAlunoTable = ({ integralPresenca }) => {
    const classes = useStyles();
    const [listaPA, setListaPA] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        findByIntegralPresencaCodigo(integralPresenca.codigo).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaPA(data);

            }
            setIsLoading(false)
        })
    }, [integralPresenca.codigo]);

    const handlePresenteChange = (pa) => {
        const persist = {
            ...pa,
            presente: !pa.presente
        };
        savePA(persist).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaPA((prevState) => (
                    prevState.map((ps) => {
                        if (ps.codigo === persist.codigo) {
                            return data;
                        } else {
                            return ps;
                        }
                    })))

            }
        })
    };

    return (
        <>
            {isLoading ? <Loading description="Carregando Integral Presenca Aluno..." /> :
                <>
                    {listaPA.length > 0 ?
                        < Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>Número Chamada</TableCell>
                                    <TableCell className={classes.rowHeader}>Código Aluno</TableCell>
                                    <TableCell className={classes.rowHeader}>Nome Aluno</TableCell>
                                    <TableCell className={classes.rowHeader}>Presença</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaPA.map((presencaAluno) => (
                                    <TableRow key={presencaAluno.codigo}>
                                        <TableCell>{presencaAluno.alunoTurma.numeroChamada}</TableCell>
                                        <TableCell>{presencaAluno.alunoTurma.aluno.codAlunoEscola}</TableCell>
                                        <TableCell>{presencaAluno.alunoTurma.aluno.nome}</TableCell>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={presencaAluno.presente ? true : false}
                                                        onChange={() => handlePresenteChange(presencaAluno)}
                                                        value={presencaAluno.presente ? true : false}
                                                    />
                                                }
                                                label={presencaAluno.presente ? 'Presente' : 'Falta'}
                                            />
                                        </TableCell>

                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table> :
                        <Grid>
                            <Typography className={classes.title} variant="h6">
                                Nenhum Registro Cadastrado
                            </Typography>
                        </Grid>
                    }
                </>
            }

        </>
    );
}

export default IntegralPresencaAlunoTable;