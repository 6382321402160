import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DisciplinaTable from './DisciplinaTable';
import DisciplinaForm from './DisciplinaForm';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    }
});

export default function PlanejamentoContainer() {
    const classes = useStyles();
    const disciplinaRedux = useSelector(state => state.disciplina);
    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Disciplinas
                </Typography>
                {disciplinaRedux ?
                    <DisciplinaForm /> :
                    <DisciplinaTable />
                }




            </Container>

        </div>
    );
}