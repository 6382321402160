import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'cronograma-aula/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const save = (cronogramaAula) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, cronogramaAula);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuth(url);
}

export {
  findAll,
  save, 
  remove

}