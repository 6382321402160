import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { findByCurrentUser } from '../TDP/api-tdp';
import 'react-toastify/dist/ReactToastify.css';
import DisciplinaTurmaTableSelect from '../TDP/DisciplinaTurmaTableSelect';
import TurmaDisciplinaProfessorProfile from '../TDP/TurmaDisciplinaProfessorProfile';
import PlanejamentoTable from './PlanejamentoTable';
import PlanejamentoForm from './PlanejamentoForm';
import PlanejamentoLoteBimestreForm from './PlanejamentoLoteBimestreForm';
import PlanejamentoDuplicarForm from './PlanejamentoDuplicarForm';
import { findByTdp } from './api-planejamento';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    },
    btnQuant: {
        marginBottom: '10px'
    }
});

export default function PlanejamentoContainer() {
    const classes = useStyles();
    const [tdps, setTdps] = useState([]);
    const [tdp, setTdp] = useState(null);
    const [planejamento, setPlanejamento] = useState({
        numeroBimestre: 1
    });
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [planejamentos, setPlanejamentos] = useState([]);
    const usuario = useSelector(state => state.usuario);


    useEffect(() => {
        if (tdp) {
            findByTdp(tdp.codigo).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setPlanejamentos(data);
                }
            }).catch(err => {
                console.log(err);
            });
        }

    }, [tdp]);

    const fetchPlanejamentos = () => {
        findByTdp(tdp.codigo).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setPlanejamentos(data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        findByCurrentUser().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setTdps(data);
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const onTdpSelected = (tdp) => {
        setTdp(tdp);
        setPlanejamento({
            tdp: tdp
        });
    }

    const updatePlanejamentos = () => {
        fetchPlanejamentos();
        setIsOpenForm(false);
        setInitPlanejamento();
    }

    const setInitPlanejamento = () => {
        setPlanejamento({
            numeroBimestre: 1,
            tdp: tdp
        });
    }

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Planejamento
                </Typography>
                {usuario.perfil === "ADMIN" || usuario.perfil === "COORDERNADOR" ?
                    <Button
                        className={classes.btnQuant}
                        component={Link}
                        to={'/planejamento-consulta-quant'}
                        variant="contained"
                        color="primary">
                        Consultar Quant. por Bimestre
                    </Button>
                    : ''}

                {tdp ?
                    <>
                        <TurmaDisciplinaProfessorProfile tdp={tdp} onTdpSelected={onTdpSelected} />

                        {isOpenForm && isOpenForm === 'unique' ?
                            <PlanejamentoForm
                                tdp={tdp}
                                planejamentoContainer={planejamento}
                                updatePlanejamentos={updatePlanejamentos}
                            />
                            : ''}

                        {isOpenForm && isOpenForm === 'lote' ?
                            <PlanejamentoLoteBimestreForm
                                tdp={tdp}
                                planejamentoContainer={planejamento}
                                updatePlanejamentos={updatePlanejamentos}
                                setIsOpenForm={setIsOpenForm}
                            />
                            : ''}

                        {isOpenForm && isOpenForm === 'duplicar' ?
                            <PlanejamentoDuplicarForm
                                tdp={tdp}
                                planejamentoContainer={planejamento}
                                updatePlanejamentos={updatePlanejamentos}
                                setIsOpenForm={setIsOpenForm}
                            />
                            : ''}

                        <PlanejamentoTable
                            planejamentos={planejamentos}
                            setPlanejamentos={setPlanejamentos}
                            setIsOpenForm={setIsOpenForm}
                            setPlanejamento={setPlanejamento}
                            setInitPlanejamento={setInitPlanejamento}
                            fetchPlanejamentos={fetchPlanejamentos}
                        />
                    </> :
                    <DisciplinaTurmaTableSelect tdps={tdps} onTdpSelected={onTdpSelected} />}


            </Container>

        </div>
    );
}