import React, { useState } from "react";
import AlunoProfile from "../Aluno/AlunoProfile";
import AlunoLivroListAdd from "./AlunoLivroListAdd";

export default function AlunoLivroContainer(props) {
    const [aluno] = useState(props.location.state.aluno);

    return (
        <>
            <AlunoProfile aluno={aluno} />
            <div style={{ marginTop: '20px' }}>
                <AlunoLivroListAdd aluno={aluno} />
            </div>
        </>
    );
}