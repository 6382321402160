import React, { useEffect, useState } from 'react';
import ContainerWithGoBack from '../../../UI/ContainerWithGoBack';
import { findResponsavelFinanceiroSemCelular } from '../../../AlunoTurma/api-aluno-turma';
import { CircularProgress, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }
});


const ResponsavelFinanceiroSemCelular = (props) => {
    const anoLetivo = props.location.state.anoLetivo;
    const [listaRfwat, setListaRfwat] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        setIsloading(true);
        findResponsavelFinanceiroSemCelular(anoLetivo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setListaRfwat(data);
            }
            setIsloading(false);
        })
    }, [anoLetivo]);


    return (
        <ContainerWithGoBack>
            <Typography className={classes.title} variant="h5">
                Relatório Responsável Financeiro sem Celular
            </Typography>


            {isLoading ?
                <div className={classes.loading}>
                    <CircularProgress color="primary" />
                    Carregando...
                </div> :
                <>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Ano Letivo</TableCell>
                                <TableCell>Turma</TableCell>
                                <TableCell>Responsável</TableCell>
                                <TableCell>Aluno</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaRfwat.map((rfwat) => (
                                <TableRow key={rfwat.alunoResponsavel.codigo}>
                                    <TableCell>{rfwat.alunoTurma.turma.anoLetivo}</TableCell>
                                    <TableCell>{rfwat.alunoTurma.turma.descricao}</TableCell>
                                    <TableCell>{rfwat.alunoResponsavel.usuario.nome}</TableCell>
                                    <TableCell>{rfwat.alunoTurma.aluno.nome}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Typography className={classes.title} variant="h5">
                        Total: {listaRfwat.length} responsáveis
                    </Typography>

                </>
            }


        </ContainerWithGoBack>
    );
}

export default ResponsavelFinanceiroSemCelular;