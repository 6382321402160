import { CircularProgress, Container, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Paper, Select, Switch, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { save } from './api-integral-rotina';
import { toast } from 'react-toastify';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    buttonSave: {
        marginTop: '20px'
    },
    buttonRemove: {
        marginTop: '20px',
        marginLeft: '20px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});

const IntegralRotinaForm = ({ integralRotina, setIntegralRotina, setListaIR }) => {
    const classes = useStyles();
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [persist, setPersist] = useState(integralRotina);
    const alimentouSeBemKey = 'Alimentou-se Bem';
    const comeuPoucoKey = 'Comeu pouco';
    const naoQuisKey = 'Não quis';

    useEffect(() => {
        setPersist(integralRotina);

    }, [integralRotina]);

    const handleLancheChange = (event) => {
        setPersist({
            ...persist,
            lanche: event.target.value
        })
    }

    const handleAlmocoChange = (event) => {
        setPersist({
            ...persist,
            almoco: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const eNovo = persist.eNovo;
        save(persist).then(data => {
            if (!data || data.error) {
                console.log(data);

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    handleAddIR(data);
                } else {
                    setListaIR((prevState) => (
                        prevState.map(ps => {
                            if (ps.codigo === persist.codigo) {
                                return persist;
                            } else {
                                return ps;
                            }
                        })
                    ))
                }
                setIntegralRotina(data);
            }
            setIsLoadingSave(false);
        })

    }

    const handleAddIR = (ir) => {
        setListaIR(prevState => ([
            ...prevState,
            ir
        ]))
    }

    const handleChange = (event) => {
        setPersist({
            ...persist,
            [event.target.name]: event.target.value
        })
    }

    const handleSwitchChange = (event) => {
        setPersist({
            ...persist,
            [event.target.name]: event.target.checked
        })
        console.log(event.target.checked)
    }

    return (
        <>
            <Container className={classes.container} component={Paper} maxWidth="sm">
                <CloseIcon
                    onClick={() => setIntegralRotina(null)}
                    fontSize='small'
                    className={classes.closeIcon}
                />
                <form onSubmit={clickSubmit}>
                    <Typography variant="h6">
                        Alimentação
                    </Typography>
                    <FormControl required fullWidth>
                        <InputLabel id="lanche-label">Lanche</InputLabel>
                        <Select
                            labelId="lanche-label"
                            id="lanche-select"
                            value={persist && persist.lanche ? persist.lanche : ''}
                            onChange={handleLancheChange}
                            required
                        >
                            <MenuItem value={alimentouSeBemKey}>{alimentouSeBemKey}</MenuItem>
                            <MenuItem value={comeuPoucoKey}>{comeuPoucoKey}</MenuItem>
                            <MenuItem value={naoQuisKey}>{naoQuisKey}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl required fullWidth>
                        <InputLabel id="almoco-label">Almoco</InputLabel>
                        <Select
                            labelId="almoco-label"
                            id="almoco-select"
                            value={persist && persist.almoco ? persist.almoco : ''}
                            onChange={handleAlmocoChange}
                            required
                        >
                            <MenuItem value={alimentouSeBemKey}>{alimentouSeBemKey}</MenuItem>
                            <MenuItem value={comeuPoucoKey}>{comeuPoucoKey}</MenuItem>
                            <MenuItem value={naoQuisKey}>{naoQuisKey}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 300 }}
                        value={persist && persist.observacaoAlimentacao ? persist.observacaoAlimentacao : ''}
                        name='observacaoAlimentacao'
                        label="Observação Alimentação"
                    />
                    <Typography variant="h6">
                        Sono/Descanso
                    </Typography>

                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="sonoDescanso"
                                checked={persist.sonoDescanso ? true : false}
                            />
                        }
                        label='Sono/Descanso'
                    />
                    <Typography variant="h6">
                        Acompanhamento de Saúde
                    </Typography>
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="coriza"
                                checked={persist.coriza ? true : false}
                            />
                        }
                        label='Coriza'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="febre"
                                checked={persist.febre ? true : false}
                            />
                        }
                        label='Febre'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="tosse"
                                checked={persist.tosse ? true : false}
                            />
                        }
                        label='Tosse'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="dorGarganta"
                                checked={persist.dorGarganta ? true : false}
                            />
                        }
                        label='Dor de Garganta'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="dorCabeca"
                                checked={persist.dorCabeca ? true : false}
                            />
                        }
                        label='Dor de Cabeça'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="dorCorpo"
                                checked={persist.dorCorpo ? true : false}
                            />
                        }
                        label='Dor no Corpo'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="dorOuvido"
                                checked={persist.dorOuvido ? true : false}
                            />
                        }
                        label='Dor de Ouvido'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="dorBarriga"
                                checked={persist.dorBarriga ? true : false}
                            />
                        }
                        label='Dor de Barriga'
                    />
                    <FormControlLabel

                        control={
                            <Switch
                                onChange={handleSwitchChange}
                                name="diarreia"
                                checked={persist.diarreia ? true : false}
                            />
                        }
                        label='Diarreia'
                    />
                    <TextField
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 300 }}
                        value={persist && persist.observacaoSaude ? persist.observacaoSaude : ''}
                        name='observacaoSaude'
                        label="Observação Saúde"
                    />
                    <div>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isLoadingSave}
                            color="primary"
                            className={classes.buttonSave}>
                            {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                            Salvar
                        </Button>
                    </div>


                </form>
            </Container>
        </>

    );
}

export default IntegralRotinaForm;