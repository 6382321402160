import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-capitulo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LivroProfile from '../Livro/LivroProfile';
import { Link } from 'react-router-dom';
import ConteudoContainer from "./Conteudo/ConteudoContainer";
import LivroCapituloProfile from "./LivroCapituloProfile";
import { useDispatch, useSelector } from 'react-redux';
import { addCapitulos, setCapitulo as setCapituloRedux, updateCapitulos } from './actions/actions';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        minWidth: '50%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    listaCapitulos: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    buttonNew: {
        marginRight: '10px'
    },
    capituloProfile: {
        marginTop: '20px'
    }

}));

export default function CapituloForm() {
    const classes = useStyles();
    const livroAutor = useSelector(state => state.livroAutor);
    const dispatch = useDispatch();
    const capituloRedux = useSelector(state => state.capitulo);
    const [capitulo, setCapitulo] = useState(capituloRedux);

    useEffect(() => {
        setCapitulo(capituloRedux);
    }, [capituloRedux])

    const handleChange = (event) => {
        setCapitulo({
            ...capitulo,
            [event.target.name]: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        save(capitulo).then(data => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (capitulo.eNovo) {
                    dispatch(addCapitulos(data));
                } else {
                    dispatch(updateCapitulos(data))
                }
                dispatch(setCapituloRedux(data));

            }

        });

    }


    return (
        <div>
            {livroAutor ?
                <>
                    <LivroProfile livro={livroAutor.livro} />
                    <Container component={Paper} className={classes.paper} maxWidth="sm">

                        <Typography className={classes.title} variant="h6">
                            Capítulo
                        </Typography>
                        <Button
                            className={classes.buttonNew}
                            onClick={() => {
                                dispatch(setCapituloRedux({
                                    livro: livroAutor.livro,
                                    eNovo: true
                                }))
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Novo Capítulo
                        </Button>
                        <Link to={{
                            pathname: '/livro-capitulos'
                        }}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.listaCapitulos}>
                                Lista Capítulos
                            </Button>
                        </Link>
                        <form onSubmit={clickSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                inputProps={{ maxLength: 500 }}
                                label="Descrição"
                                name="descricao"
                                onChange={handleChange}
                                value={capitulo.descricao || ''}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows='4'
                                fullWidth
                                inputProps={{ maxLength: 160 }}
                                label="Descricao Meta Tag"
                                name="descricaoMetaTag"
                                onChange={handleChange}
                                value={capitulo.descricaoMetaTag || ''}
                            />
                            {capitulo.codCapitulo ?
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{ maxLength: 5 }}
                                    label="Ordem"
                                    name="ordem"
                                    onChange={handleChange}
                                    value={capitulo.ordem || ''}
                                /> : ''
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Salvar
                            </Button>
                        </form>
                    </Container>
                </>
                :
                <>
                    {capituloRedux && capituloRedux.codigo ? <LivroCapituloProfile capitulo={capituloRedux} /> : ''}
                </>
            }
            {!capituloRedux.eNovo &&
                <ConteudoContainer />
            }

        </div>
    );
}