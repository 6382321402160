import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    divAluno: {
        border: '1px solid black',
        padding: '15px',
        marginBottom: '8px',
        borderRadius: '20px',
    }
});

const AlunoCard = ({ aluno }) => {
    const classes = useStyles();
    return (
        <div className={classes.divAluno}>
            <Typography>
                {aluno.nome}
            </Typography>
        </div>
    );
}

export default AlunoCard;