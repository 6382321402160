import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { errorMessage } from "../../../../util/ConfirmAlertUtil";
import { dayOfWeekList } from "../../../../util/DateUtil";
import Loading from "../../../UI/Loading";
import Select from "../../../UI/Select";
import { findByCurrentUserAndSelectedYearAndDiaSemana } from "../../api-cronograma";
import CronogramaProfessorTable from "../CronogramaProfessorTable";

const CronogramaProfessorTablePorDiaSemana = () => {
  const [cronogramas, setCronogramas] = useState([]);
  const [diaSemanaSelect, setDiaSemanaSelect] = useState(
    dayOfWeekList.find((ds) => ds.value === new Date().getDay())
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (diaSemanaSelect.value) {
      setIsLoading(true);
      findByCurrentUserAndSelectedYearAndDiaSemana(diaSemanaSelect.value).then(
        (data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setCronogramas(data);
          }
          setIsLoading(false);
        }
      );
    }
  }, [diaSemanaSelect.value]);

  const handleChangeDiaSemana = (event) => {
    setDiaSemanaSelect(
      dayOfWeekList.find((ds) => ds.value === event.target.value)
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Select
          label="Dia Semana"
          value={diaSemanaSelect.value}
          onChange={handleChangeDiaSemana}
          list={dayOfWeekList}
        />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading description="Carregando Cronograma Professor..." />
        ) : (
          <CronogramaProfessorTable cronogramas={cronogramas} />
        )}
      </Grid>
    </Grid>
  );
};

export default CronogramaProfessorTablePorDiaSemana;
