import { CircularProgress, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../UI/Button';
import { ADMIN } from '../Usuario/Perfil';
import { dateFormat } from '../../util/DateUtil';
import { currencyRound } from '../../util/DoubleUtil';
import LancamentoFilter from './LancamentoFilter';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    loading: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
});

const LancamentoTable = ({ aluno, setLancamento, setFilter, isLoading, filter, lancamentos }) => {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);

    const handleRowClick = (lancamento) => {
        setLancamento(lancamento)
    }

    const handleChangeFilter = (event) => {
        if (event.target.name === 'pendentes' && event.target.checked) {
            setFilter({
                pendentes: true,
                todos: false
            })
        } else if (event.target.name === 'todos' && event.target.checked) {
            setFilter({
                pendentes: false,
                todos: true
            })
        }

    }

    return (
        <>
            {usuario && usuario.perfil === ADMIN ?
                <Button
                    onClick={() => setLancamento({
                        aluno: aluno,
                        eNovo: true
                    })}
                    className={classes.novoRegistro}
                >
                    Novo Lançamento
                </Button> : ''}
            {!isLoading ?
                <>
                    <Grid>
                        <LancamentoFilter filter={filter} handleChangeFilter={handleChangeFilter} />
                    </Grid>

                    {lancamentos.length > 0 ?
                        < Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>Ano Letivo</TableCell>
                                    <TableCell className={classes.rowHeader}>Cód. Doc.</TableCell>
                                    <TableCell className={classes.rowHeader}>Data Emissão</TableCell>
                                    <TableCell className={classes.rowHeader}>Data Vencimento</TableCell>
                                    <TableCell className={classes.rowHeader}>Histórico</TableCell>
                                    <TableCell className={classes.rowHeader}>Valor</TableCell>
                                    <TableCell className={classes.rowHeader}>Data Desconto</TableCell>
                                    <TableCell className={classes.rowHeader}>Valor Desconto</TableCell>
                                    <TableCell className={classes.rowHeader}>Data Pagamento</TableCell>
                                    <TableCell className={classes.rowHeader}>Valor Pago</TableCell>
                                    <TableCell className={classes.rowHeader}>Código de Barras</TableCell>
                                    <TableCell className={classes.rowHeader}>Forma Pago</TableCell>
                                    <TableCell className={classes.rowHeader}>Quitado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lancamentos.map((lancamento) => (
                                    <TableRow onClick={() => handleRowClick(lancamento)} key={lancamento.codigo}>
                                        <TableCell>{lancamento.anoLetivo}</TableCell>
                                        <TableCell>{lancamento.codDoc}</TableCell>
                                        <TableCell>{dateFormat(lancamento.dataEmissao)}</TableCell>
                                        <TableCell>{dateFormat(lancamento.dataVencimento)}</TableCell>
                                        <TableCell>{lancamento.historicoLancamento.descricao}</TableCell>
                                        <TableCell>{currencyRound(lancamento.valor)}</TableCell>
                                        <TableCell>{lancamento.dataDesconto ? dateFormat(lancamento.dataDesconto) : '--'}</TableCell>
                                        <TableCell>{lancamento.valorDesconto ? currencyRound(lancamento.valorDesconto) : '--'}</TableCell>
                                        <TableCell>{lancamento.dataPagamento ? dateFormat(lancamento.dataPagamento) : '--'}</TableCell>
                                        <TableCell>{lancamento.valorPago ? currencyRound(lancamento.valorPago) : '--'}</TableCell>
                                        <TableCell>{lancamento.codigoBarras ? lancamento.codigoBarras : '--'}</TableCell>
                                        <TableCell>{lancamento.formaPago ? lancamento.formaPago : '--'}</TableCell>
                                        <TableCell>{lancamento.quitado ? 'Sim' : 'Não'}</TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table> :
                        <Grid>
                            <Typography className={classes.title} variant="h6">
                                Todas as Parcelas estão em dia.
                            </Typography>
                        </Grid>
                    }

                </>
                :
                <div className={classes.loading}>
                    <CircularProgress color="primary" /> Carregando...
                </div>
            }

        </>
    );
}

export default LancamentoTable;