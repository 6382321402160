import { SERVER_URL } from '../../../constants';

import {
  fetchGetAuth
} from '../../../util/FetchUtil';

const controller = 'sms-pendencia';

const page = (numberPage) => {
  const url = SERVER_URL + controller + '?page=' + numberPage;
  return fetchGetAuth(url);
}

export {
  page,
}