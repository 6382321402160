import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '../../UI/Button';
import { dateFormat } from '../../../util/DateUtil';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    container: {
        padding: '10px',
    },
});

const DiaNaoLetivoSerieTable = ({
    listDiaNaoLetivoSerie,
    isLoading,
    setIsAdding,
    handleRemove,
    isLoadingAction
}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            component={Paper}
            className={classes.container}
        >
            <Button
                disabled={isLoadingAction}
                onClick={() => setIsAdding(true)}
            >
                Novo Dia não Letivo Série
            </Button>
            {isLoading ?
                <Grid
                    container
                    justifyContent='center'
                >
                    <Loading description="Carregando Dias não Letivo" />
                </Grid>
                :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listDiaNaoLetivoSerie.map((diaNaoLetivoSerie) => (
                            <TableRow key={diaNaoLetivoSerie.codigo}>
                                <TableCell>{dateFormat(diaNaoLetivoSerie.diaNaoLetivo.data)}</TableCell>
                                <TableCell>{diaNaoLetivoSerie.diaNaoLetivo.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleRemove(diaNaoLetivoSerie.codigo)}
                                    >
                                        Remover
                                    </Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }

        </Grid>
    )
}

export default DiaNaoLetivoSerieTable;