import {
  ADD_OBSERVACOES_ALUNO,
  UPDATE_OBSERVACOES_ALUNO,
  SET_OBSERVACOES_ALUNO,
  REMOVE_OBSERVACOES_ALUNO,
  SET_OBSERVACAO_ALUNO,
  SET_OBSERVACAO_ALUNO_NULL
} from './actionTypes';
import { PURGE } from "redux-persist";

export function observacaoAluno(state = null, action) {
  switch (action.type) {
    case SET_OBSERVACAO_ALUNO:
      return action.observacaoAluno

    case SET_OBSERVACAO_ALUNO_NULL:
      return null

    case PURGE:
      return null;

    default:
      return state
  }
}

const initialState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function observacoesAluno(state = initialState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_OBSERVACOES_ALUNO:
      persist.content = [
        action.observacaoAluno,
        ...persist.content
      ]
      return persist

    case UPDATE_OBSERVACOES_ALUNO:
      let content = state.content.map((observacaoAluno) => {
        if (observacaoAluno.codigo === action.observacaoAluno.codigo) {
          return action.observacaoAluno;
        } else {
          return observacaoAluno;
        }
      });
      persist.content = content;
      return persist;

    case SET_OBSERVACOES_ALUNO:
      return action.observacoesAluno

    case REMOVE_OBSERVACOES_ALUNO:

      return {
        ...persist,
        content: persist.content.filter((c) => c.codigo !== action.codigo)
      };

    case PURGE:
      return initialState;

    default:
      return state
  }
}
