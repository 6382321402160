import { SERVER_URL } from '../../constants';
import { fetchGetAuth } from '../../util/FetchUtil';

const controller = 'turma-model/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

export {
  findAll
}