import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { findByUsuario } from './api-aluno-responsavel';
import { changeARAluno } from '../Usuario/api-usuario';
import { setUsuario } from '../Usuario/actions/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@material-ui/core/Typography';
import UserPhoto from '../../shared/assets/img/User.png'
import { useSelector, useDispatch } from 'react-redux';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        flex: '1 1 100%',
        marginBottom: '20px',
        marginTop: '20px'
    },
    arItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '20px',
        padding: '20px',
        alignItems: 'center',
    },
    arItemSelected: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '20px',
        padding: '20px',
        alignItems: 'center',
        backgroundColor: '#e3fbe3',
        borderRadius: '10%',
    }
}));

export default function AlunoResponsavelAtivoTable() {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const [alunosReponsavel, setAlunosReponsavel] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (usuario && usuario.id) {
            findByUsuario(usuario.id).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunosReponsavel(data);
                }
            });
        }

    }, [usuario]);


    const handleAtivar = (aluno) => {
        changeARAluno(aluno).then((data) => {
            if (data) {
                dispatch(setUsuario(data));
                toast.success("Aluno Selecionado.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        });
    }

    return (
        <div>
            {alunosReponsavel.length > 0 ?

                <Container className={classes.paper} component={Paper}>
                    <Typography className={classes.title} variant="h5">
                        Alterar Aluno Selecionado
                    </Typography>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                        {alunosReponsavel.map((ar) => (
                            <div
                                key={ar.codigo}
                                className={
                                    usuario && usuario.arAlunoSelecionado && usuario.arAlunoSelecionado.codigo === ar.aluno.codigo ?
                                        classes.arItemSelected :
                                        classes.arItem}
                            >
                                {ar.alunoFoto ?
                                    <img style={{
                                        height: '200px',
                                        borderRadius: '10%',
                                    }} src={ar.alunoFoto.foto} alt='' />
                                    :
                                    <img
                                        style={{
                                            height: '200px',
                                            borderRadius: '10%',
                                        }}
                                        src={UserPhoto}
                                        alt=''
                                    />
                                }
                                <p style={{
                                    fontSize: 'large',
                                    fontWeight: '800',
                                }}>{ar.aluno.nome}</p>
                                {usuario && usuario.arAlunoSelecionado && usuario.arAlunoSelecionado.codigo === ar.aluno.codigo ?
                                    <p>Selecionado</p> :
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleAtivar(ar.aluno)}
                                    >
                                        Selecionar
                                    </Button>
                                }
                            </div>
                        ))}
                    </div>
                </Container>

                : ''
            }

        </div >
    );
}