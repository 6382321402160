import React from 'react';
import TextFieldSelectBimestre from '../../Bimestre/UI/TextFieldSelectBimestre';
import Form from '../../UI/Form';
import TextField from '../../UI/TextField';

const CronogramaQuantAulaBimestreForm = ({
    cqab,
    setCqab,
    clickSubmit,
    handleRemove,
    isLoadingAction,
}) => {

    const handleClose = () => {
        setCqab(null);
    }

    const setBimestre = (bimestre) => {
        setCqab((prevState) => ({
            ...prevState,
            bimestre: bimestre
        }))
    }

    const onChange = (event) => {
        setCqab((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <Form
            clickSubmit={clickSubmit}
            handleClose={handleClose}
            handleRemove={handleRemove}
            isLoadingAction={isLoadingAction}
        >
            <TextFieldSelectBimestre
                bimestre={cqab.bimestre}
                setBimestre={setBimestre}
                serie={cqab.tdp.turma.serie}
            />
            <TextField
                label="Quantidade Aulas"
                onChange={onChange}
                name="quantAulas"
                value={cqab.quantAulas}
            />

        </Form>
    )
}

export default CronogramaQuantAulaBimestreForm;