import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    findBySecaoCodigo,
    findBySecaoCodigoNext,
    findBySecaoCodigoPrevious,
    findBySecaoCodigoAndAvaliacaoAluno,
    findBySecaoCodigoAndAvaliacaoAlunoNext,
    findBySecaoCodigoAndAvaliacaoAlunoPrevious
}
    from './api-avaliacao-conteudo';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Components from "../../Capitulo/Conteudo/components";
import { useSelector, useDispatch } from "react-redux";
import { setConteudos } from "../../Capitulo/Conteudo/actions/actions";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { finalizar } from '../Aluno/api-avaliacao-aluno';
import { useHistory } from 'react-router-dom';
import { setSecao } from '../Secao/actions/actions';
import { ALUNO } from '../../Usuario/Perfil';


const useStyles = makeStyles({
    voltarButton: {
        marginBottom: '20px',
        marginRight: '10px',
        cursor: 'pointer'
    }
});

export default function AvaliacaoConteudoTable() {
    const conteudos = useSelector(state => state.conteudos);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const secao = useSelector(state => state.secao);
    const usuario = useSelector(state => state.usuario);
    const avaliacaoAluno = useSelector(state => state.avaliacaoAluno);

    useEffect(() => {
        if (secao) {
            if (avaliacaoAluno) {
                findBySecaoCodigoAndAvaliacaoAluno(secao.codigo, avaliacaoAluno.codigo).then((data) => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        dispatch(setConteudos(data));
                    }
                })
            } else {
                findBySecaoCodigo(secao.codigo).then((data) => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        dispatch(setConteudos(data));
                    }
                })
            }

        }

    }, [secao, dispatch, avaliacaoAluno]);

    const handleNext = () => {
        if (avaliacaoAluno) {
            findBySecaoCodigoAndAvaliacaoAlunoNext(secao.codigo, avaliacaoAluno.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        dispatch(setConteudos(data));
                        if (data.length > 0) {
                            dispatch(setSecao(data[0].secao));
                        }

                    }
                }

            })
        } else {
            findBySecaoCodigoNext(secao.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        dispatch(setConteudos(data));
                        if (data.length > 0) {
                            dispatch(setSecao(data[0].secao));
                        }

                    }
                }

            })
        }

    }

    const handlePrevious = () => {
        if (avaliacaoAluno) {
            findBySecaoCodigoAndAvaliacaoAlunoPrevious(secao.codigo, avaliacaoAluno.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        dispatch(setConteudos(data));
                        if (data.length > 0) {
                            dispatch(setSecao(data[0].secao));
                        }
                    }
                }

            })
        } else {
            findBySecaoCodigoPrevious(secao.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        dispatch(setConteudos(data));
                        if (data.length > 0) {
                            dispatch(setSecao(data[0].secao));
                        }
                    }
                }

            })
        }
    }

    const handleFinalizarAvaliacao = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja finalizar ' +
                (avaliacaoAluno.avaliacao &&
                    avaliacaoAluno.avaliacao.esimulado ?
                    'este Simulado' : 'esta Avaliação') + '?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        finalizar(avaliacaoAluno.codigo).then((data) => {
                            if (data.error) {
                                console.log(data);
                            } else {
                                console.log(data);
                                history.push({
                                    pathname: '/avaliacao',
                                    state: { menuShow: 1 }
                                })
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <Link to={{
                pathname: '/avaliacao'
            }}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.voltarButton}
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
            </Link>
            {usuario && usuario.perfil !== ALUNO && avaliacaoAluno && avaliacaoAluno.provaFinalizada ?
                '' :
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.voltarButton}
                    onClick={handleFinalizarAvaliacao}
                >
                    Finalizar {avaliacaoAluno && avaliacaoAluno.avaliacao && avaliacaoAluno.avaliacao.esimulado ? 'Simulado' : 'Avaliação'}
                </Button>}

            {conteudos.map(conteudo => Components(conteudo, {}))}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px'
            }}>
                <Button

                    variant="contained"
                    color="secondary"
                    onClick={handlePrevious}
                >
                    Anterior
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNext}
                >
                    Próximo
                </Button>
            </div>
        </div>
    );
}