import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'livro-instituicao/';

const findByLivroCodLivro = (codLivro) => {
  const url = SERVER_URL + controller + 'query?codLivro=' + codLivro;
  return fetchGetAuth(url);
}

const findByLivroDescricao = (descricao) => {
  const url = SERVER_URL + controller + 'query?descricao=' + descricao;
  return fetchGetAuth(url);
}

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

const save = (livro) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, livro);
}


export {
  findByLivroCodLivro,
  page,
  save,
  findByLivroDescricao
}