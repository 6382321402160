import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useRef } from "react";
import { useState } from "react";
import { changePhoto, removeUserPhoto } from "./api-usuario";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import Button from "../UI/Button";

const useStyles = makeStyles({
  fotoComponent: {
    width: "80px",
    borderRadius: "20px",
  },
  accountCircleIcon: {
    fontSize: 80,
    color: "black",
  },
});

const UsuarioPhotoForm = ({ usuario, setUsuario }) => {
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];

    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);

    setIsLoadingAction(true);
    changePhoto(usuario.id, fileFormData).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setUsuario(data);
      }
      setIsLoadingAction(false);
    });
  };

  const handleAttachFile = () => {
    hiddenFileInput.current.click();
  };

  const handleRemoveImage = () => {
    setIsLoadingAction(true);
    removeUserPhoto(usuario.id).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setUsuario(data);
      }
      setIsLoadingAction(false);
    });
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        {usuario && usuario.fileName ? (
          <img src={usuario.fotoUrl} alt="" className={classes.fotoComponent} />
        ) : (
          <AccountCircle className={classes.accountCircleIcon} />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
              ref={hiddenFileInput}
              onChange={handleChangeImage}
              multiple={false}
            />
            <Button disabled={isLoadingAction} onClick={handleAttachFile}>
              Alterar Foto
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoadingAction}
              color="secondary"
              onClick={handleRemoveImage}
            >
              Remover Foto
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UsuarioPhotoForm;
