import React from "react";
import Button from "../UI/Button";
import Container from "../UI/Container";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    padding: "20px",
  },
  title: {
    marginBottom: "20px",
  },
});

const PresencaMenu = () => {
  const classes = useStyles();

  return (
    <Container className={classes.paper} component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography className={classes.title} variant="h6">
            Presença Menu
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Button
                component={Link}
                to={{
                  pathname: "/presenca-por-disciplina",
                }}
              >
                Presença por Disciplina
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to={{
                  pathname: "/presenca-por-turma",
                }}
              >
                Presença por Turma
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to={{
                  pathname: "/presenca-por-usuario",
                }}
              >
                Presença por Usuário
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to={{
                  pathname: "/presenca",
                }}
              >
                Presença por Turma - Disciplina
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PresencaMenu;
