import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function RelatorioFrequenciaWebAlunoFrequencia({ alunoFrequencia }) {

    return (

        <div style={{
            padding: '20px',
            margin: '20px'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
                <Typography >
                    Quantidade Aulas: {alunoFrequencia.quantidadeAulas}
                </Typography>
                <Typography >
                    Quantidade Faltas: {alunoFrequencia.quantidadeFaltas}
                </Typography>
                <Typography >
                    Ausência Compensada: {alunoFrequencia.ausenciaCompensada}
                </Typography>
                <Typography >
                    Frequência: {alunoFrequencia.frequencia}
                </Typography>

            </div>
        </div>

    );
}