import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { faltou, findByPresenca, presente } from './api-presenca-aluno';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Loading from '../UI/Loading';
import Switch from '../UI/Switch';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        padding: '10px'
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    rowNotSelected: {
        cursor: 'pointer'
    },
    rowSelected: {
        cursor: 'pointer',
        backgroundColor: '#b5e7a4'
    }
});


const PresencaAlunoTableSwitch = ({ presenca }) => {
    const classes = useStyles();
    const [presencaAlunos, setPresencaAlunos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (presenca.codigo) {
            setIsLoading(true);
            findByPresenca(presenca.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPresencaAlunos(data)
                }
                setIsLoading(false);
            })
        }

    }, [presenca.codigo]);

    const handleSwitch = (pa) => {
        if (pa.legendaDiario.codigo === 1) {
            faltou(pa.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    updatePresencaAlunos(data);
                }
            })
        } else {
            presente(pa.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    updatePresencaAlunos(data);
                }
            })
        }

    }

    const updatePresencaAlunos = (pa) => {
        setPresencaAlunos((prevState) => prevState.map((ps) => {
            if (ps.codigo === pa.codigo) {
                return pa;
            } else {
                return ps;
            }
        }))
    }

    return (
        <Container className={classes.paper} component={Paper}>
            {isLoading ? <Loading description="Carregando Presença Aluno..." /> :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Número Chamada</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {presencaAlunos.map((pa) => (
                            <TableRow key={pa.codigo}>
                                <TableCell>{pa.alunoTurma.numeroChamada}</TableCell>
                                <TableCell>{pa.aluno.nome}</TableCell>
                                <TableCell>
                                    <Switch
                                        size='small'
                                        handleSwitch={() => handleSwitch(pa)}
                                        value={pa.legendaDiario.codigo === 1 ? true : false}
                                        name='presente'
                                    />
                                    {pa.legendaDiario.descricao}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }


        </Container>
    );
}

export default PresencaAlunoTableSwitch;