import {
  REMOVE_CONTEUDOS,
  ADD_CONTEUDOS,
  SET_CONTEUDOS,
  UPDATE_CONTEUDOS
} from './actionTypes';
import { PURGE } from "redux-persist";

export function conteudos(state = [], action) {

  switch (action.type) {

    case REMOVE_CONTEUDOS:
      return state.filter((conteudo) => conteudo.codigo !== action.codigo)

    case ADD_CONTEUDOS:
      return [
        action.conteudo,
        ...state
      ]

    case SET_CONTEUDOS:
      return action.conteudos

    case UPDATE_CONTEUDOS:
      return state.map(s => s.codigo === action.conteudo.codigo ? action.conteudo : s)

    case PURGE:
      return [];

    default:
      return state
  }
}
