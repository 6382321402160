import {
    ADD_LIVROS_INSTITUICAO,
    UPDATE_LIVROS_INSTITUICAO,
    SET_LIVROS_INSTITUICAO,
    REMOVE_LIVROS_INSTITUICAO,
    SET_LIVRO_INSTITUICAO,
    OPEN_LIVRO_INSTITUICAO,
    CLOSE_LIVRO_INSTITUICAO
} from './actionTypes'

export function addLivrosInstituicao(livroInstituicao) {
    return { type: ADD_LIVROS_INSTITUICAO, livroInstituicao }
}

export function updateLivrosInstituicao(livroInstituicao) {
    return { type: UPDATE_LIVROS_INSTITUICAO, livroInstituicao }
}

export function setLivrosInstituicao(livrosInstituicao) {
    return { type: SET_LIVROS_INSTITUICAO, livrosInstituicao }
}

export function removeLivrosInstituicao(index) {
    return { type: REMOVE_LIVROS_INSTITUICAO, index }
}

export function setLivroInstituicao(livroInstituicao) {
    return { type: SET_LIVRO_INSTITUICAO, livroInstituicao }
}


export function openLivroInstituicao() {
    return { type: OPEN_LIVRO_INSTITUICAO }
}

export function closeLivroInstituicao() {
    return { type: CLOSE_LIVRO_INSTITUICAO }
}