import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { create, findUnregisteredSeries } from './api-bimestre';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Redirect } from 'react-router-dom';
import session from '../../SessionHandler/session-helper';

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: '20px'
    },
    paper: {
        marginTop: theme.spacing(8),
        minWidth: 650,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px',
        marginBottom: '20px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    messageSerie: {
        background: '#a5d6a7',
        padding: '20px'
    },
    gridItem: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    titleBimestre: {
        margin: "auto",
        textAlign: "center"
    },
    errorMessage: {
        textAlign: 'center',
        padding: '10px',
        background: '#ffcdd2'
    }
}));

export default function AlunoForm() {
    const anoLetivo = session.getAnoLetivo();
    const classes = useStyles();
    const [quantBimestre, setQuantBimestre] = useState(4);
    const [bimestres, setBimestres] = useState([{
        serie: '',
        anoLetivo: anoLetivo,
        dataInicio: '',
        dataFim: '',
        numeroBimestre: 1,
        error: ''
    },
    {
        serie: '',
        anoLetivo: anoLetivo,
        dataInicio: '',
        dataFim: '',
        numeroBimestre: 2,
        error: ''
    },
    {
        serie: '',
        anoLetivo: anoLetivo,
        dataInicio: '',
        dataFim: '',
        numeroBimestre: 3,
        error: ''
    },
    {
        serie: '',
        anoLetivo: anoLetivo,
        dataInicio: '',
        dataFim: '',
        numeroBimestre: 4,
        error: ''
    }]);
    const [series, setSeries] = useState([]);
    const [serie, setSerie] = useState();
    const [isRedirect, setRedirect] = useState(false);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        setBimestres([]);
        for (let i = 1; i <= quantBimestre; i++) {
            setBimestres((prevState) => [
                ...prevState,
                {
                    serie: '',
                    anoLetivo: anoLetivo,
                    dataInicio: '',
                    dataFim: '',
                    numeroBimestre: i,
                    error: ''
                }
            ])
        }

    }, [quantBimestre, anoLetivo]);
    useEffect(() => {
        if (inputLabel.current !== null) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }

        findUnregisteredSeries().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setSeries(data);
            }
        }).catch(err => {
            console.log(err);
        })

    }, []);

    const handleDataInicioChange1Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        let update = bimestres.map((bimestre) => {
            if (bimestre.numeroBimestre === 1) {
                bimestre.serie = serie;
                bimestre.dataInicio = data;
            }
            return bimestre;
        });
        setBimestres(update);
    }

    const handleDataFimChange1Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[0].dataInicio > data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 1) {
                    bimestre.error = 'Data Fim não pode ser menor que a Data Início do Bimestre';
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 1) {
                    bimestre.serie = serie;
                    bimestre.dataFim = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleDataInicioChange2Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[0].dataFim >= data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 2) {
                    bimestre.error = 'Data Início não pode ser menor ou igual que a Data Fim do Bimestre anterior'
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 2) {
                    bimestre.serie = serie;
                    bimestre.dataInicio = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleDataFimChange2Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[1].dataInicio > data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 2) {
                    bimestre.error = 'Data Fim não pode ser menor que a Data Início do Bimestre'
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 2) {
                    bimestre.serie = serie;
                    bimestre.dataFim = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleDataInicioChange3Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[1].dataInicio >= data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 3) {
                    bimestre.error = 'Data Início não pode ser menor ou igual que a Data Fim do Bimestre anterior'
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 3) {
                    bimestre.serie = serie;
                    bimestre.dataInicio = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleDataFimChange3Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[2].dataInicio > data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 3) {
                    bimestre.error = 'Data Fim não pode ser menor que a Data Início do Bimestre'
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 3) {
                    bimestre.serie = serie;
                    bimestre.dataFim = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleDataInicioChange4Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[2].dataInicio >= data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 4) {
                    bimestre.error = 'Data Início não pode ser menor ou igual que a Data Fim do Bimestre anterior'
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 4) {
                    bimestre.serie = serie;
                    bimestre.dataInicio = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleDataFimChange4Bimestre = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);
        if (bimestres[3].dataInicio > data) {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 4) {
                    bimestre.error = 'Data Fim não pode ser menor que a Data Início do Bimestre'
                }
                return bimestre;
            });
            setBimestres(update);
        } else {
            let update = bimestres.map((bimestre) => {
                if (bimestre.numeroBimestre === 4) {
                    bimestre.serie = serie;
                    bimestre.dataFim = data;
                    bimestre.error = '';
                }
                return bimestre;
            });
            setBimestres(update);
        }
    }

    const handleChangeSeries = (event) => {
        setSerie(series[event.target.value]);
    }

    const handleChangeQuantBimestre = (event) => {
        setQuantBimestre(event.target.value);
    }

    const clickSubmit = (event) => {
        event.preventDefault();

        create(bimestres).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setRedirect(true);
            }
        }).catch(err => {
            console.log(err);
        })

    }

    if (isRedirect) {
        return <Redirect to='/bimestre' />
    } else {
        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CssBaseline />
                    <Container className={classes.container} component={Paper} maxWidth="md">
                        <Typography className={classes.title} variant="h5">
                            Bimestre
                        </Typography>
                        {series.length > 0 ?
                            <form onSubmit={clickSubmit}>
                                <FormControl fullWidth required className={classes.formControl}>
                                    <InputLabel id="serie-label">
                                        Série
                                    </InputLabel>
                                    <Select
                                        name="serie"
                                        onChange={handleChangeSeries}
                                        labelWidth={labelWidth}

                                    >
                                        {series.map((serie, index) => (
                                            <option key={index} value={index}>{serie.descricao}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth required className={classes.formControl}>
                                    <InputLabel id="quantBimestre-label">
                                        Quant. Bimestre
                                    </InputLabel>
                                    <Select
                                        name="quantBimestre"
                                        onChange={handleChangeQuantBimestre}
                                        labelWidth={labelWidth}
                                        value={quantBimestre}
                                    >
                                        <option key={1} value={1}>1</option>
                                        <option key={2} value={2}>2</option>
                                        <option key={3} value={3}>3</option>
                                        <option key={4} value={4}>4</option>
                                    </Select>
                                </FormControl>

                                <Grid className={classes.gridItem} container spacing={4}>
                                    <Grid className={classes.titleBimestre} item xs={12} sm={6}>
                                        <Typography variant="h6">
                                            1º Bimestre
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            required
                                            disableToolbar
                                            disabled={!serie}
                                            variant="outlined"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label="Data Início"
                                            value={bimestres[0].dataInicio ? bimestres[0].dataInicio : null}
                                            onChange={handleDataInicioChange1Bimestre}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardDatePicker
                                            fullWidth
                                            disableToolbar
                                            required
                                            disabled={!bimestres[0].dataInicio}
                                            variant="outlined"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label="Data Fim"
                                            name="dataFim"
                                            value={bimestres[0].dataFim ? bimestres[0].dataFim : null}
                                            onChange={handleDataFimChange1Bimestre}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {bimestres[0].error ?
                                    <p className={classes.errorMessage}>{bimestres[0].error}</p> :
                                    ''}
                                <Divider />
                                {quantBimestre >= 2 ?
                                    <>
                                        <Grid className={classes.gridItem} container spacing={4}>
                                            <Grid className={classes.titleBimestre} item xs={12} sm={6}>
                                                <Typography variant="h6">
                                                    2º Bimestre
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    required
                                                    disableToolbar
                                                    disabled={!bimestres[0].dataFim}
                                                    variant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data Início"
                                                    name="dataInicio"
                                                    value={bimestres[1].dataInicio ? bimestres[1].dataInicio : null}
                                                    onChange={handleDataInicioChange2Bimestre}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    disableToolbar
                                                    required
                                                    disabled={!bimestres[1].dataInicio}
                                                    variant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data Fim"
                                                    name="dataFim"
                                                    value={bimestres[1].dataFim ? bimestres[1].dataFim : null}
                                                    onChange={handleDataFimChange2Bimestre}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {bimestres[1].error ?
                                            <p className={classes.errorMessage}>{bimestres[1].error}</p> :
                                            ''}
                                        <Divider /></>
                                    : ''}
                                {quantBimestre >= 3 ?
                                    <>
                                        <Grid className={classes.gridItem} container spacing={4}>
                                            <Grid className={classes.titleBimestre} item xs={12} sm={6}>
                                                <Typography variant="h6">
                                                    3º Bimestre
                                    </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    required
                                                    disableToolbar
                                                    disabled={!bimestres[1].dataFim}
                                                    variant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data Início"
                                                    name="dataInicio"
                                                    value={bimestres[2].dataInicio ? bimestres[2].dataInicio : null}
                                                    onChange={handleDataInicioChange3Bimestre}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    disableToolbar
                                                    required
                                                    disabled={!bimestres[2].dataInicio}
                                                    variant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data Fim"
                                                    name="dataFim"
                                                    value={bimestres[2].dataFim ? bimestres[2].dataFim : null}
                                                    onChange={handleDataFimChange3Bimestre}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {bimestres[2].error ?
                                            <p className={classes.errorMessage}>{bimestres[2].error}</p> :
                                            ''}
                                        <Divider />
                                    </>
                                    : ''}
                                {quantBimestre >= 4 ?
                                    <>
                                        <Grid className={classes.gridItem} container spacing={4}>
                                            <Grid className={classes.titleBimestre} item xs={12} sm={6}>
                                                <Typography variant="h6">
                                                    4º Bimestre
                                    </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    required
                                                    disabled={!bimestres[2].dataFim}
                                                    disableToolbar
                                                    variant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data Início"
                                                    name="dataInicio"
                                                    value={bimestres[3].dataInicio ? bimestres[3].dataInicio : null}
                                                    onChange={handleDataInicioChange4Bimestre}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    disableToolbar
                                                    required
                                                    disabled={!bimestres[3].dataInicio}
                                                    variant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data Fim"
                                                    name="dataFim"
                                                    value={bimestres[3].dataFim ? bimestres[3].dataFim : null}
                                                    onChange={handleDataFimChange4Bimestre}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {bimestres[3].error ?
                                            <p className={classes.errorMessage}>{bimestres[3].error}</p> :
                                            ''}
                                        <Divider />
                                    </>
                                    : ''}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                    Criar
                            </Button>
                            </form> :
                            <Typography className={classes.messageSerie} variant="h6">
                                Todas as Séries já foram cadastradas.
                            </Typography>}
                    </Container>
                </MuiPickersUtilsProvider>
            </div>
        );
    }


}