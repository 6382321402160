import {
  ADD_ALUNOS_FREQUENCIA,
  UPDATE_ALUNOS_FREQUENCIA,
  SET_ALUNOS_FREQUENCIA,
  REMOVE_ALUNOS_FREQUENCIA,
  SET_ALUNO_FREQUENCIA
} from './actionTypes';
import { PURGE } from "redux-persist";

export function alunoFrequencia(state = null, action) {
  switch (action.type) {
    case SET_ALUNO_FREQUENCIA:
      return action.alunoFrequencia

    case PURGE:
      return null;

    default:
      return state
  }
}

export function alunosFrequencia(state = [], action) {
  switch (action.type) {

    case ADD_ALUNOS_FREQUENCIA:
      return [
        ...state,
        action.alunoFrequencia
      ]

    case UPDATE_ALUNOS_FREQUENCIA:
      return state.map((alunoFrequencia) => {
        if (alunoFrequencia.codigo === action.alunoFrequencia.codigo) {
          return action.alunoFrequencia;
        } else {
          return alunoFrequencia;
        }
      });

    case SET_ALUNOS_FREQUENCIA:
      return action.alunosFrequencia

    case REMOVE_ALUNOS_FREQUENCIA:
      return state.splice(action.index, 1);

    case PURGE:
      return null;

    default:
      return state
  }
}
