import { Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';
import { diaLetivoExtraKey, diaLetivoExtraSerieKey } from './DiaLetivoExtraMenuContainer';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        padding: '20px',

    }
})

const DiaLetivoExtraMenu = ({ menu, setMenu }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            component={Paper}
            spacing={3}
            justifyContent='center'
            className={classes.container}

        >
            <Grid item>
                <Button
                    onClick={() => setMenu(diaLetivoExtraKey)}
                    disabled={menu === diaLetivoExtraKey}
                    xs={12} sm={12} md={6} lg={6} xl={6}
                >
                    Dia Letivo Extra
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => setMenu(diaLetivoExtraSerieKey)}
                    disabled={menu === diaLetivoExtraSerieKey}
                    xs={12} sm={12} md={6} lg={6} xl={6}
                >
                    Vincular Série
                </Button>
            </Grid>
        </Grid>
    )
}

export default DiaLetivoExtraMenu;