import { Grid, TablePagination } from "@material-ui/core";
import React from "react";
import TablePaginationActions from "../../../shared/components/Table/Actions";

const ComunicadoBannerList = ({
  listCB,
  setComunicadoBanner,
  setPageNumber,
}) => {
  const handleCBSelected = (cb) => {
    setComunicadoBanner(cb);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container spacing={3} direction="row">
          {listCB.content.map((cb) => (
            <Grid key={cb.codigo} item xs={12} sm={3}>
              <img
                style={{
                  width: "200px",
                  cursor: "pointer",
                }}
                onClick={() => handleCBSelected(cb)}
                src={cb.urlBanner}
                alt=""
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage={listCB.size ? listCB.size : 0}
          component="div"
          count={listCB.totalElements}
          page={listCB.number}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            from +
            "-" +
            to +
            " de " +
            (count !== -1 ? count + " registros" : +" mais de " + to)
          }
        />
      </Grid>
    </Grid>
  );
};

export default ComunicadoBannerList;
