import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const BimestreComboBox = ({ numeroBimestre, handleBimestreChange }) => {
    return (
        <FormControl required fullWidth>
            <InputLabel id="bimestre-label">Bimestre</InputLabel>
            <Select
                labelId="bimestre-label"
                id="bimestre-select"
                value={numeroBimestre ? numeroBimestre : ''}
                onChange={handleBimestreChange}
            >
                <MenuItem value={1}>1º Bimestre</MenuItem>
                <MenuItem value={2}>2º Bimestre</MenuItem>
                <MenuItem value={3}>3º Bimestre</MenuItem>
                <MenuItem value={4}>4º Bimestre</MenuItem>
            </Select>
        </FormControl>
    );
}

export default BimestreComboBox;