import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TurmaTableSelectRedux from '../Turma/TurmaTableSelectRedux';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TurmaProfileRedux from '../Turma/TurmaProfileRedux';
import AlunoTurmaTable from './AlunoTurmaTable';
import { useSelector } from 'react-redux';
import AlunoTurmaFormContainer from './AlunoTurmaFormContainer';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }

});

export default function AlunoTurmaContainer() {
    const classes = useStyles();
    const turma = useSelector(state => state.turma);
    const alunoTurma = useSelector(state => state.alunoTurma);

    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Alunos Turma
                </Typography>

                {turma ?
                    <>
                        <TurmaProfileRedux />
                        {alunoTurma ?
                            <AlunoTurmaFormContainer /> :
                            <AlunoTurmaTable />}

                    </>
                    :
                    <TurmaTableSelectRedux />
                }

            </Container>

        </div>
    );
}