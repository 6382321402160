import React from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Button from '../../UI/Button';
import { dayOfWeekName } from '../../../util/DateUtil';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const CardapioTable = ({ listaCS, setCardapioSemana }) => {
    const classes = useStyles();

    const handleSelecionarCardapioSemana = (cs) => {
        setCardapioSemana(cs);
    }

    return (
        <>
            {
                listaCS.length > 0 ?
                    <>
                        < Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>Nº Semana</TableCell>
                                    <TableCell className={classes.rowHeader}>Dia Semana</TableCell>
                                    <TableCell className={classes.rowHeader}>Lanche Manhã</TableCell>
                                    <TableCell className={classes.rowHeader}>Almoço</TableCell>
                                    <TableCell className={classes.rowHeader}>Lanche Tarde</TableCell>
                                    <TableCell className={classes.rowHeader}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaCS.map((cs) => (
                                    <TableRow key={cs.codigo}>
                                        <TableCell>{cs.numeroSemana}</TableCell>
                                        <TableCell>{dayOfWeekName(cs.diaSemana)}</TableCell>
                                        <TableCell>{cs.lancheManha.length > 50 ? cs.lancheManha.substring(0, 50) + '...' : cs.lancheManha}</TableCell>
                                        <TableCell>{cs.almoco.length > 50 ? cs.almoco.substring(0, 50) + '...' : cs.almoco}</TableCell>
                                        <TableCell>{cs.lancheTarde.length > 50 ? cs.lancheTarde.substring(0, 50) + '...' : cs.lancheTarde}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSelecionarCardapioSemana(cs)}>Selecionar</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </>

                    :
                    <Grid>
                        <Typography className={classes.title} variant="h6">
                            Nenhum Registro Cadastrado
                        </Typography>
                    </Grid>
            }

        </>
    );
}

export default CardapioTable;