import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const TurmaComboBox = ({ turmas, turma, handleTurmaChange }) => {
    return (
        <FormControl required fullWidth >
            <InputLabel id="turma-label">Turma</InputLabel>
            <Select
                labelId="turma-label"
                id="turma-select"
                value={turma ? turma.codigo : ''}
                onChange={handleTurmaChange}
            >
                {turmas.map(t => (
                    <MenuItem key={t.codigo} value={t.codigo}>{t.descricao} ({t.curso.abreviacao})</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default TurmaComboBox;