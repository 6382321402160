import {
  ADD_PRESENCAS,
  UPDATE_PRESENCAS,
  SET_PRESENCAS,
  REMOVE_PRESENCAS,
  SET_PRESENCA,
  SET_PRESENCA_NULL
} from './actionTypes';
import { PURGE } from "redux-persist";

export function presenca(state = null, action) {
  switch (action.type) {
    case SET_PRESENCA:
      return action.presenca

    case SET_PRESENCA_NULL:
      return null

    case PURGE:
      return null;

    default:
      return state
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function presencas(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_PRESENCAS:
      persist.content = [
        action.presenca,
        ...persist.content
      ]
      return persist

    case UPDATE_PRESENCAS:
      let content = state.content.map((presenca) => {
        if (presenca.codigo === action.presenca.codigo) {
          return action.presenca;
        } else {
          return presenca;
        }
      });
      persist.content = content;
      return persist;

    case SET_PRESENCAS:
      return action.presencas

    case REMOVE_PRESENCAS:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}
