import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const DisciplinaComboBox = ({ disciplinas, disciplina, handleDisciplinaChange }) => {
    return (
        <FormControl required fullWidth >
            <InputLabel id="turma-label">Disciplina</InputLabel>
            <Select
                labelId="turma-label"
                id="turma-select"
                value={disciplina ? disciplina.codDisciplina : ''}
                onChange={handleDisciplinaChange}
            >
                {disciplinas.map(d => (
                    <MenuItem key={d.codDisciplina} value={d.codDisciplina}>{d.descricao}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DisciplinaComboBox;