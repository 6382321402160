import React from 'react';
import Typography from '@material-ui/core/Typography';
import { dateFormat } from '../../../../util/DateUtil';

export default function RelatorioCabecalhoWebBimestre({ bimestre }) {

    return (

        <div style={{
            border: '1px solid black',
            padding: '20px',
            margin: '20px'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
                <Typography >
                    Número Bimestre: {bimestre.numeroBimestre}
                </Typography>
                <Typography >
                    Data Início: {dateFormat(bimestre.dataInicio)}
                </Typography>
                <Typography >
                    Data Fim: {dateFormat(bimestre.dataFim)}
                </Typography>
            </div>
        </div>

    );
}