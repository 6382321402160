export const UPDATE_COMUNICADOS = 'UDPATE_COMUNICADOS'
export const ADD_COMUNICADOS = 'ADD_COMUNICADOS'
export const SET_COMUNICADOS = 'SET_COMUNICADOS'
export const REMOVE_COMUNICADOS = 'REMOVE_COMUNICADOS'
export const SET_COMUNICADO = 'SET_COMUNICADO'
export const OPEN_SEARCH_COMUNICADO = 'OPEN_SEARCH_COMUNICADO'
export const CLOSE_SEARCH_COMUNICADO = 'CLOSE_SEARCH_COMUNICADO'
export const SET_QUANT_APROVACAO_COMUNICADO = 'SET_QUANT_APROVACAO_COMUNICADO'
export const DECREASE_QUANT_APROVACAO_COMUNICADO = 'DECREASE_QUANT_APROVACAO_COMUNICADO'
export const INCREASE_QUANT_APROVACAO_COMUNICADO = 'INCREASE_QUANT_APROVACAO_COMUNICADO'
