import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
}));

export const RltFrequencia = ({ listFrequencia, setOpenReport }) => {
    const classes = useStyles();
    return (
        <>
            <CloseIcon
                className={classes.closeIcon}
                onClick={() => setOpenReport(false)}
                fontSize='small'
            />
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Cód. Aluno</TableCell>
                        <TableCell>Nome Aluno</TableCell>
                        <TableCell>Turma</TableCell>
                        <TableCell>Mês</TableCell>
                        <TableCell>Ano Letivo</TableCell>
                        <TableCell>Quant. Aulas</TableCell>
                        <TableCell>Quant. Faltas</TableCell>
                        <TableCell>Frequência</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listFrequencia.map((freq, index) => (
                        <TableRow key={index}>
                            <TableCell>{freq.alunoTurma.aluno.codAlunoEscola}</TableCell>
                            <TableCell>{freq.alunoTurma.aluno.nome}</TableCell>
                            <TableCell>{freq.alunoTurma.turma.descricao}</TableCell>
                            <TableCell>{freq.mes}</TableCell>
                            <TableCell>{freq.alunoTurma.turma.anoLetivo}</TableCell>
                            <TableCell>{freq.quantAulas}</TableCell>
                            <TableCell>{freq.quantFaltas}</TableCell>
                            <TableCell>{freq.frequencia}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}
