import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const AlunoTurmaComboBox = ({ alunosTurma, alunoTurma, handleAlunoTurmaChange }) => {
    return (
        <FormControl required fullWidth >
            <InputLabel id="aluno-turma-label">Aluno</InputLabel>
            <Select
                labelId="aluno-turma-label"
                id="aluno-turma-select"
                value={alunoTurma && alunoTurma.codigo ? alunoTurma.codigo : ''}
                onChange={handleAlunoTurmaChange}
            >
                {alunosTurma.map(at => (
                    <MenuItem key={at.codigo} value={at.codigo}>{at.aluno.nome}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default AlunoTurmaComboBox;