import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import ComunicadoForm from './ComunicadoForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ComunicadoTable from "./ComunicadoTable";
import { Link } from 'react-router-dom';
import ComunicadoTurmaTable from "../ComunicadoTurma/ComunicadoTurmaTable";
import EngajamentoTable from "../ComunicadoUsuario/EngajamentoTable";
import ComunicadoAlunoTable from "../ComunicadoAluno/ComunicadoAlunoTable";
import Button from '../../UI/Button';
import ComunicadoUsuarioForm from '../ComunicadoUsuario/ComunicadoUsuarioForm';
import ComunicadoFindDialog from './ComunicadoFindDialog';
import { useEffect } from 'react';
import { page, remove } from './api-comunicado';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { confirmAlert } from 'react-confirm-alert';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px',
        margin: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function ComunicadoContainer() {
    const classes = useStyles();
    const [comunicado, setComunicado] = useState(null);
    const [comunicados, setComunicados] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [preview, setPreview] = useState(false);
    const [openSearchComunicado, setOpenSearchComunicado] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        page(0).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setComunicados(data)
            }
            setIsLoading(false);
        });
    }, []);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setComunicados(data)
            }
            setIsLoading(false);
        });

    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurar = () => {
        handleMenuClose();
        setComunicado(null)
        setOpenSearchComunicado(true);
    }

    const handlePreview = () => {
        let persistPreview = !preview;
        setPreview(persistPreview);
        if (persistPreview) {
            setComunicado({
                comunicado: comunicado
            })
        } else {
            setComunicado(comunicado.comunicado)
        }
    }

    const handleDelete = (codComunicado) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este comunicado?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(codComunicado).then((data) => {
                            if (data && data.error) {
                                errorMessage(data.message);
                            } else {
                                setComunicados((prevState) => ({
                                    ...prevState,
                                    content: prevState.content.filter((ps) => ps.codigo !== codComunicado)
                                }));
                                setComunicado(null);

                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const updateComunicados = (data) => {
        setComunicados((prevState) => ({
            ...prevState,
            content: prevState.content.map((ps) => {
                if (ps.codigo === data.codigo) {
                    return data;
                } else {
                    return ps
                }
            })
        }))
    }

    const addComunicados = (data) => {
        setComunicados((prevState) => ({
            ...prevState,
            content: [
                data,
                ...prevState.content
            ]
        }))
    }

    return (
        <div>
            <ComunicadoFindDialog
                openSearchComunicado={openSearchComunicado}
                setOpenSearchComunicado={setOpenSearchComunicado}
                setComunicado={setComunicado}
            />
            <Container className={classes.paper} component={Paper}>

                <Grid container justifyContent="space-between">
                    <Grid item xs={3}>
                        <Typography variant="h6">
                            Comunicados
                        </Typography>
                    </Grid>

                    

                    <Grid style={{ flexBasis: '5%' }} item xs={3}>


                        <MenuIcon
                            className={classes.cursorLink}
                            onClick={(event) => handleMenuClick(event)}
                            color="secondary"

                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem component={Link} to={{
                                pathname: '/comunicado-categoria'
                            }}>
                                Categorias
                            </MenuItem>
                            <MenuItem component={Link} to={{
                                pathname: '/comunicado-banner'
                            }}>
                                Banners
                            </MenuItem>
                            {comunicado && comunicado.codigo ?
                                ''
                                :
                                <MenuItem onClick={() => handleProcurar()}>
                                    Procurar Comunicado
                                </MenuItem>}


                        </Menu>

                    </Grid>
                </Grid>

                <Grid style={{ marginBottom: '20px' }} container justifyContent="space-around">


                    {comunicado ?
                        <Grid item xs={7}>
                            <>
                                <Button
                                    onClick={handlePreview}
                                >
                                    {preview ? 'Formulário' : 'Pré-Visualização'}
                                </Button>
                                {preview ?
                                    <ComunicadoUsuarioForm
                                        comunicadoUsuario={comunicado}
                                        setComunicadoUsuario={() => console.log("close")}
                                    /> :
                                    <ComunicadoForm
                                        comunicado={comunicado}
                                        setComunicado={setComunicado}
                                        updateComunicados={updateComunicados}
                                        addComunicados={addComunicados}
                                        handleDelete={handleDelete}
                                    />
                                }

                            </>
                        </Grid>
                        :
                        ''
                    }


                    <Grid item xs={4}>
                        {comunicado && comunicado.codigo && comunicado.enviarPor === 'Turma' ?
                            <ComunicadoTurmaTable comunicado={comunicado} />
                            : ''}
                        {comunicado && comunicado.codigo && comunicado.enviarPor === 'Aluno' ?
                            <ComunicadoAlunoTable
                                comunicado={comunicado}
                            />
                            : ''}
                    </Grid>



                </Grid>
                <Grid>
                    {comunicado ?
                        ''
                        :
                        <>
                            {isLoading ? <Loading description={"Carregando Comunicados..."} /> :
                                <ComunicadoTable
                                    comunicados={comunicados}
                                    handleChangePage={handleChangePage}
                                    setComunicado={setComunicado}
                                />
                            }

                        </>
                    }
                </Grid>
                <Grid>
                    {comunicado && comunicado.codigo && comunicado.enviado ?
                        <EngajamentoTable comunicado={comunicado} />
                        : ''}
                </Grid>
            </Container>
        </div >
    );
}