import {
  SET_INSTITUICAO,
  OPEN_INSTITUICAO,
  CLOSE_INSTITUICAO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchInstituicao(state = false, action) {
  switch (action.type) {
    case OPEN_INSTITUICAO:
      return true;
    case CLOSE_INSTITUICAO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function instituicao(state = null, action) {
  switch (action.type) {
    case SET_INSTITUICAO:
      return action.instituicao
    case PURGE:
      return null;
    default:
      return state
  }
}

