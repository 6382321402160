import { SERVER_URL } from '../../constants';
import session from '../../SessionHandler/session-helper';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth,
  fetchPutAuth
} from '../../util/FetchUtil';

const controller = 'bimestre/';

const findByNumeroBimestre = (numeroBimestre) => {
  const url = SERVER_URL + controller + session.getAnoLetivo() + "/" + numeroBimestre;
  return fetchGetAuth(url);
}

const findUnregisteredSeries = () => {
  const url = SERVER_URL + controller + "find-unregistered-series/" + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const update = (bimestre) => {
  const url = SERVER_URL + controller;
  return fetchPutAuth(url, bimestre);
}

const create = (bimestre) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, bimestre);
}

const remove = (codBimestre) => {
  const url = SERVER_URL + controller + 'delete/' + codBimestre;
  return fetchRemoveAuth(url);
}

const findByAnoLetivoESerieCodSerie = (codSerie) => {
  const url = SERVER_URL + controller + 'ano-letivo-serie/' + session.getAnoLetivo() + "/" + codSerie;
  return fetchGetAuth(url);
}

export const findByAnoLetivoParamESerieCodSerie = (anoLetivo, codSerie) => {
  const url = SERVER_URL + controller + 'ano-letivo-serie/' + anoLetivo + "/" + codSerie;
  return fetchGetAuth(url);
}

export const findByAnoLetivoAndSerieAndNumeroBimestre = (codSerie, numeroBimestre) => {
  const url = SERVER_URL + controller + 'ano-letivo-serie-numero-bimestre/' + session.getAnoLetivo() + "/" + codSerie + "/" + numeroBimestre;
  return fetchGetAuth(url);
}

export {
  findByNumeroBimestre,
  findUnregisteredSeries,
  update,
  create,
  remove,
  findByAnoLetivoESerieCodSerie
}