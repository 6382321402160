import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findAll } from './api-nota-bimestre-tipo';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { setNotaBimestreTipo } from './actions/actions';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function NotaBimestreTipoTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [notasBimestreTipo, setNotasBimestreTipo] = useState([]);

    useEffect(() => {
        findAll().then((data) => {
            if (data) {
                setNotasBimestreTipo(data);
            }
        })
    }, []);



    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notasBimestreTipo.map((notaBimestreTipo) => (
                            <TableRow key={notaBimestreTipo.codigo}>
                                <TableCell>{notaBimestreTipo.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            dispatch(setNotaBimestreTipo(notaBimestreTipo));
                                        }}
                                        className={classes.submit}>
                                        Selecionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>

        </div>
    );
}