
const session = {
  getAnoLetivo() {
    if (typeof window == "undefined") {
      return false
    }

    if (!sessionStorage.getItem('AnoLetivo'))
      session.setAnoLetivo(new Date().getFullYear());

    return sessionStorage.getItem('AnoLetivo')

  },
  setAnoLetivo(anoLetivo) {
    sessionStorage.setItem('AnoLetivo', anoLetivo);
  }

}

export default session
