import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, FormControl, InputLabel, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../UI/Button';
import { toast } from 'react-toastify';
import { save, remove } from './api-cardapio-semana';
import { confirmAlert } from 'react-confirm-alert';
import TextField from '../../UI/TextField';
import { dayOfWeekList } from '../../../util/DateUtil';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    buttonSave: {
        marginTop: '20px'
    },
    buttonRemove: {
        marginTop: '20px',
        marginLeft: '20px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});

const CardapioSemanaForm = ({ cardapioSemana, setCardapioSemana, setListaCS }) => {
    const [persist, setPersist] = useState(cardapioSemana);
    const classes = useStyles();
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    useEffect(() => {
        setPersist(cardapioSemana);
    }, [cardapioSemana]);

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const eNovo = persist.eNovo;
        save(persist).then(data => {
            if (!data || data.error) {
                console.log(data);

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    handleAddCardapioSemana(data);
                }
                setCardapioSemana(data);
            }
            setIsLoadingSave(false);
        })

    }

    const handleAddCardapioSemana = (c) => {
        setListaCS(prevState => ([
            ...prevState,
            c
        ]))
    }

    const handleRemove = () => {

        confirmAlert({
            title: 'Alerta',
            message: "Deseja Remover este registro?",
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        remove(persist.codigo).then((data) => {
                            if (!data || data.error) {
                                console.log(data);
                            } else {
                                if (setListaCS.length > 1) {
                                    setListaCS((prevState) => ([
                                        prevState.filter((ps) => ps.codigo !== persist.codigo)
                                    ]));
                                } else {
                                    setListaCS([]);
                                }
                                setCardapioSemana(null);
                            }
                        })
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleChange = (event) => {
        setCardapioSemana({
            ...cardapioSemana,
            [event.target.name]: event.target.value
        })
    }

    const handleNumeroSemanaChange = (event) => {
        setCardapioSemana({
            ...cardapioSemana,
            numeroSemana: event.target.value
        })
    }

    const handleDiaSemanaChange = (event) => {
        const dow = dayOfWeekList.find((d) => {
            return d.value === event.target.value;
        });
        setCardapioSemana({
            ...cardapioSemana,
            diaSemana: dow.value
        })
    }

    return (
        <>
            <Container className={classes.container} component={Paper} maxWidth="sm">
                <CloseIcon
                    onClick={() => setCardapioSemana(null)}
                    fontSize='small'
                    className={classes.closeIcon}
                />
                <form onSubmit={clickSubmit}>
                    <FormControl required fullWidth>
                        <InputLabel id="numero-semana-label">Nº Semana</InputLabel>
                        <Select
                            labelId="numero-semana-label"
                            id="numero-semana-select"
                            value={cardapioSemana && cardapioSemana.numeroSemana ? cardapioSemana.numeroSemana : ''}
                            onChange={handleNumeroSemanaChange}
                            required
                        >
                            <MenuItem value={1}>1ª Semana</MenuItem>
                            <MenuItem value={2}>2ª Semana</MenuItem>
                            <MenuItem value={3}>3ª Semana</MenuItem>
                            <MenuItem value={4}>4ª Semana</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl required fullWidth>
                        <InputLabel id="dia-semana-label">Dia Semana</InputLabel>
                        <Select
                            labelId="dia-semana-label"
                            id="dia-semana-select"
                            value={cardapioSemana && cardapioSemana.diaSemana ? cardapioSemana.diaSemana : ''}
                            onChange={handleDiaSemanaChange}
                            required
                        >
                            {dayOfWeekList.map((dow) => (
                                <MenuItem key={dow.value} value={dow.value}>{dow.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 300 }}
                        value={cardapioSemana && cardapioSemana.lancheManha ? cardapioSemana.lancheManha : ''}
                        name='lancheManha'
                        label="Lanche Manhã"
                    />
                    <TextField
                        onChange={handleChange}
                        fullWidth
                        required
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 300 }}
                        value={cardapioSemana && cardapioSemana.almoco ? cardapioSemana.almoco : ''}
                        name='almoco'
                        label="Almoço"
                    />
                    <TextField
                        onChange={handleChange}
                        fullWidth
                        required
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 300 }}
                        value={cardapioSemana && cardapioSemana.lancheTarde ? cardapioSemana.lancheTarde : ''}
                        name='lancheTarde'
                        label="Lanche Tarde"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoadingSave}
                        color="primary"
                        className={classes.buttonSave}>
                        {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Salvar
                    </Button>
                    {persist.eNovo ? '' :
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            className={classes.buttonRemove}
                            onClick={handleRemove}
                        >
                            Remover
                        </Button>
                    }

                </form>
            </Container>

        </>
    );
}

export default CardapioSemanaForm;