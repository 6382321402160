import React, { useEffect, useState } from "react";
import Container from "../../UI/Container";
import { Grid, Paper, Typography } from "@material-ui/core";
import TurmaTableSelectView from "../../Turma/TurmaTableSelectView";
import { findByAnoLetivo } from "../../Turma/api-turma";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import TurmaProfile from "../../Turma/TurmaProfile";
import { findByCheck } from "../api-catraca-registro";
import CatracaRegistroCheckTable from "./CatracaRegistroCheckTable";
import TextFieldDate from "../../UI/TextFieldDate";
import Button from "../../UI/Button";
import Loading from "../../UI/Loading";

const CatracaRegistroCheckContainer = () => {
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [listCRC, setListCRC] = useState([]);
  const [dataSelected, setDataSelected] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    findByAnoLetivo().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setTurmas(data);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (turma) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [turma]);

  const fetchData = () => {
    if (turma && dataSelected) {
      setIsLoading(true);
      findByCheck(turma.codigo, dataSelected).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCRC(data);
          }
        }
        setIsLoading(false);
      });
    }
  };

  const handleTrocaTurma = () => {
    setTurma(null);
  };

  const handleDateChange = (date) => {
    setDataSelected(date);
  };

  return (
    <Container component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h5">Monitor de Acesso</Typography>
        </Grid>
        {turma ? (
          <>
            <Grid item>
              <TurmaProfile turma={turma} handleTrocaTurma={handleTrocaTurma} />
            </Grid>
            <Grid item>
              <TextFieldDate
                onChange={handleDateChange}
                value={dataSelected}
                label="Data"
              />
            </Grid>
            <Grid item>
              <Button onClick={fetchData}>Aplicar Datas</Button>
            </Grid>
            {isLoading ? (
              <Loading description="Carregando Registros..." />
            ) : (
              <>
                {listCRC.map((catracaRegistroCheck) => (
                  <Grid item>
                    <CatracaRegistroCheckTable
                      catracaRegistroCheck={catracaRegistroCheck}
                    />
                  </Grid>
                ))}
              </>
            )}
          </>
        ) : (
          <Grid item>
            <TurmaTableSelectView turmas={turmas} onTurmaSelected={setTurma} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CatracaRegistroCheckContainer;
