import { SERVER_URL } from "../../constants";
import { fetchGetAuth, fetchPostAuth, fetchPutAuth } from "../../util/FetchUtil";

const controller = 'tarefa/';

export const findByUsuarioParaAndFinalizado = (page, finalizado) => {
    const url = SERVER_URL + controller + 'page-query?usuario-para=true&page=' + page + '&finalizado=' + finalizado;
    return fetchGetAuth(url);
}

export const findByUsuarioPara = (page) => {
    const url = SERVER_URL + controller + 'page-query?usuario-para=true&page=' + page;
    return fetchGetAuth(url);
}

export const findByUsuarioPorAndFinalizado = (page, finalizado) => {
    const url = SERVER_URL + controller + 'page-query?usuario-por=true&page=' + page + '&finalizado=' + finalizado;
    return fetchGetAuth(url);
}

export const findByUsuarioPor = (page) => {
    const url = SERVER_URL + controller + 'page-query?usuario-por=true&page=' + page;
    return fetchGetAuth(url);
}

export const save = (tarefa) => {
    const url = SERVER_URL + controller + 'save';
    return fetchPostAuth(url, tarefa);
}

export const finalizar = (codTarefa) => {
    const url = SERVER_URL + controller + 'finalizar/' + codTarefa
    return fetchPutAuth(url);
}