import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


export default function TurmaDisciplinaProfessorProfileInfo({ tdp }) {


    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Turma
                </Typography>
                <Typography variant="body1">
                    {tdp.turma.descricao}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Disciplina
                </Typography>
                <Typography variant="body1">
                    {tdp.disciplina.descricao}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Professor
                </Typography>
                <Typography variant="body1">
                    {tdp.usuario ? tdp.usuario.nome : 'Preencher'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Livro
                </Typography>
                <Typography variant="body1">
                    {tdp.livro ? tdp.livro.descricao : 'Preencher'}
                </Typography>
            </Grid>
        </Grid>
    );
}