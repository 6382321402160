import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Button from '../../UI/Button';
import TextFieldReadOnly from '../../UI/TextFieldReadOnly';
import BimestreDialogSelect from '../BimestreDialogSelect';

const TextFieldSelectBimestre = ({ bimestre, setBimestre, serie }) => {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <BimestreDialogSelect
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setBimestre={setBimestre}
                serie={serie}
            />
            <Grid container spacing={2}>

                <Grid item>
                    <Grid container direction='row' justifyContent='center' spacing={2}>
                        {console.log(bimestre)}
                        <Grid item xs={6} >
                            <TextFieldReadOnly
                                label="bimestre"
                                value={bimestre.anoLetivo + "-" + bimestre.numeroBimestre}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <Button>
                                Selecionar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </>
    )
}

export default TextFieldSelectBimestre;