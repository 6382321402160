import React, { useState } from 'react';
import Button from '../../UI/Button';
import AlunoTurmasTable from '../../AlunoTurma/Aluno/AlunoTurmasTable';
import { makeStyles } from '@material-ui/core';
import AlunoResponsavelTable from '../../AlunoResponsavel/AlunoResponsavelTable';

const useStyles = makeStyles({
    divAbaMenu: {
        padding: '20px',
        margin: '20px',
        border: '1px solid black',
        borderRadius: '20px'

    },
    divButtonsAbaMenu: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '30px',
    }
});

const AbaMenu = ({ aluno }) => {
    const [abaMenu, setAbaMenu] = useState(null);
    const turmasKey = 'turmas';
    const responsaveisKey = 'responsaveis';
    const classes = useStyles();

    return (
        <>
            <div
                className={classes.divAbaMenu}
            >
                <div className={classes.divButtonsAbaMenu}>
                    <Button
                        onClick={() => setAbaMenu(turmasKey)}
                    >
                        Turmas
                    </Button>
                    <Button
                        onClick={() => setAbaMenu(responsaveisKey)}
                    >
                        Responsáveis
                    </Button>
                </div>

                {abaMenu && abaMenu === turmasKey ?
                    <AlunoTurmasTable
                        aluno={aluno}
                    /> : ''}

                {abaMenu && abaMenu === responsaveisKey ?
                    <AlunoResponsavelTable
                        aluno={aluno}
                    /> : ''}
            </div>



        </>
    )
}

export default AbaMenu;