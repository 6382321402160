import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import AlunoFindDialogRedux from '../../Aluno/AlunoFindDialogRedux';
import { open } from '../../Aluno/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { createAvaliacao } from './api-avaliacao-aluno';
import { toast } from 'react-toastify';
import { add } from './actions/actions';

const useStyles = makeStyles({
    btnComponent: {
        marginRight: '8px'
    },
});




export default function AvaliacaoAlunoEnviar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const aluno = useSelector(state => state.aluno);
    const avaliacao = useSelector(state => state.avaliacao);

    useEffect(() => {

        if (aluno) {
            const aa = {
                aluno: aluno,
                avaliacao: avaliacao
            }
            createAvaliacao(aa).then((data) => {
                if (data.error) {
                    if (data.message === 'AvaliacaoAluno already regiostered') {
                        toast.error('Aluno já registrado com esta prova.', {
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        console.log(data);
                    }
                } else {
                    dispatch(add(data));
                }
            })

        }
    }, [aluno, avaliacao, dispatch]);

    const handleAlunoSearch = () => {
        dispatch(open());
    }




    return (
        <div>
            <AlunoFindDialogRedux />
            <Typography>
                Enviar Por
            </Typography>
            <Grid style={{ marginBottom: '20px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    onClick={handleAlunoSearch}
                    className={classes.btnComponent}>
                    Aluno
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size='small'
                    className={classes.btnComponent}>
                    Turma
                </Button>
            </Grid>
        </div>
    );
}