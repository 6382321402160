import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";

const TextFieldDateTime = ({ handleDateTimeChange, label, value }) => {
  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <DateTimePicker
        size="small"
        ampm={false}
        minutesStep={60}
        variant="outlined"
        margin="normal"
        label={label}
        value={value}
        onChange={handleDateTimeChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TextFieldDateTime;
