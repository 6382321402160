import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
});

const IntegralRotinaAlunoForm = ({ integralRotina, setPresencaAluno }) => {
    const classes = useStyles();


    return (
        <>
            <Container className={classes.container} component={Paper} maxWidth="sm">
                <CloseIcon
                    onClick={() => setPresencaAluno(null)}
                    fontSize='small'
                    className={classes.closeIcon}
                />
                <div>
                    <Typography variant="h6">
                        Alimentação
                    </Typography>
                </div>
                <div>
                    <Typography variant="body">
                        Lanche: {integralRotina.lanche}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body">
                        Almoço: {integralRotina.almoco}
                    </Typography>
                </div>
                {integralRotina.observacaoAlimentacao &&
                    <div>
                        <Typography variant="body">
                            Observação Alimentação: {integralRotina.observacaoAlimentacao}
                        </Typography>
                    </div>
                }
                <div>
                    <Typography variant="h6">
                        Sono/Descanso
                    </Typography>
                </div>
                <div>
                    <Typography variant="body">
                        Sono/Descanso: {integralRotina.sonoDescanso ? "Sim" : "Não"}
                    </Typography>
                </div>
                {integralRotina.coriza ||
                    integralRotina.febre ||
                    integralRotina.tosse ||
                    integralRotina.dorGarganta ||
                    integralRotina.dorCabeca ||
                    integralRotina.dorCorpo ||
                    integralRotina.dorOuvido ||
                    integralRotina.dorBarriga ||
                    integralRotina.diarreia ||
                    integralRotina.observacaoSaude ?
                    <>
                        <div>
                            <Typography variant="h6">
                                Acompanhamento de Saúde
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body">
                                O(A) aluno(a) apresentou:
                            </Typography>
                        </div>
                        {integralRotina.coriza &&
                            <div>
                                <Typography variant="body">
                                    - Coriza
                                </Typography>
                            </div>
                        }
                        {integralRotina.febre &&
                            <div>
                                <Typography variant="body">
                                    - Febre
                                </Typography>
                            </div>
                        }
                        {integralRotina.tosse &&
                            <div>
                                <Typography variant="body">
                                    - Tosse
                                </Typography>
                            </div>
                        }
                        {integralRotina.dorGarganta &&
                            <div>
                                <Typography variant="body">
                                    - Dor de Garganta
                                </Typography>
                            </div>
                        }
                        {integralRotina.dorCabeca &&
                            <div>
                                <Typography variant="body">
                                    - Dor de Cabeça
                                </Typography>
                            </div>
                        }
                        {integralRotina.dorCorpo &&
                            <div>
                                <Typography variant="body">
                                    - Dor no Corpo
                                </Typography>
                            </div>
                        }
                        {integralRotina.dorouvido &&
                            <div>
                                <Typography variant="body">
                                    - Dor de Ouvido
                                </Typography>
                            </div>
                        }
                        {integralRotina.dorBarriga &&
                            <div>
                                <Typography variant="body">
                                    - Dor de Barriga
                                </Typography>
                            </div>
                        }
                        {integralRotina.diarreia &&
                            <div>
                                <Typography variant="body">
                                    - Diarreia
                                </Typography>
                            </div>
                        }
                        {integralRotina.observacaoSaude &&
                            <div>
                                <Typography variant="body">
                                    Observação Saúde: {integralRotina.observacaoSaude}
                                </Typography>
                            </div>
                        }
                    </>




                    : ''

                }



            </Container>
        </>

    );
}

export default IntegralRotinaAlunoForm;