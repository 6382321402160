import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import { page, save } from './api-evento-usuario';
import CssBaseline from '@material-ui/core/CssBaseline';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setEventos, setEvento } from '../Evento/actions/actions';
import { dateTimeFormat } from "../../../util/DateUtil";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    tableRow: {
        cursor: 'pointer'
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontSize: 'large',
        fontWeight: '700'
    }
});

export default function EventoUsuarioTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const eventosUsuario = useSelector(state => state.eventos);

    useEffect(() => {
        page(0).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setEventos(data));
            }
        }).catch(err => {
            console.log(err);
        })
    }, [dispatch]);

    const handleChangePage = (event, newPage) => {
        page(newPage).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setEventos(data));
            }
        });
    };

    const handleRowClick = (eventoUsuario) => {
        let persistent = {
            ...eventoUsuario,
            visualizado: true
        }
        save(persistent);
        dispatch(setEvento(eventoUsuario));
    }

    return (
        <div>
            <CssBaseline />
            <Container component={Paper}>
                <div style={{ paddingTop: '32px' }}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.rowHeader}>Título</TableCell>
                                <TableCell className={classes.rowHeader}>Início</TableCell>
                                <TableCell className={classes.rowHeader}>Fim</TableCell>
                                <TableCell className={classes.rowHeader}>Aluno</TableCell>
                                <TableCell className={classes.rowHeader}>Resposta</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventosUsuario.content.map((eventoUsuario, index) => (
                                <TableRow
                                    onClick={() => handleRowClick(eventoUsuario)}
                                    key={eventoUsuario.codigo}
                                    className={classes.tableRow}
                                >
                                    <TableCell>{eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.titulo : ''}</TableCell>
                                    <TableCell>{dateTimeFormat(eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.dataHoraInicio : null)}</TableCell>
                                    <TableCell>{dateTimeFormat(eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.dataHoraFim : null)}</TableCell>
                                    <TableCell>{eventoUsuario && eventoUsuario.aluno ? eventoUsuario.aluno.nome : ''}</TableCell>
                                    <TableCell>
                                        {eventoUsuario.resposta ?
                                            eventoUsuario.resposta :
                                            ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={eventosUsuario.size ? eventosUsuario.size : 0}
                        component="div"
                        count={eventosUsuario.totalElements}
                        page={eventosUsuario.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </div>
            </Container>

        </div>
    );
}