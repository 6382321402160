import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth, fetchRemoveAuth, fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'serie/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const remove = (codSerie) => {
  const url = SERVER_URL + controller + codSerie;
  return fetchRemoveAuth(url);
}

const save = (serie) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, serie);
}

export {
  findAll, 
  remove, 
  save
}