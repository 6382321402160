import {
  SET_QUANT_NAO_LIDAS
} from './actionTypes';
import { PURGE } from "redux-persist";

export function quantNaoLidas(state = 0, action) {
  switch (action.type) {
    case SET_QUANT_NAO_LIDAS:
      return action.quantNaoLidas;
    case PURGE:
      return false;
    default:
      return false;
  }
}
