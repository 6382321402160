import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { findBySala, remove, addUser } from "./api-sala-participante";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import UsuarioFindDialog from '../../Usuario/UsuarioFindDialog';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    container: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    table: {
        minWidth: 650,
    },
    header: {
        paddingTop: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    menuIcon: {
        cursor: 'pointer',
        margin: '20px'
    },
    menuIconTable: {
        cursor: 'pointer'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function GroupListParticipants(props) {
    const classes = useStyles();
    const [sala] = useState(props.location.state.sala);
    const [salaParticipantes, setSalaParticipantes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSearchUsuario, setOpenSearchUsuario] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findBySala(sala.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setSalaParticipantes(data);
            }
            setIsLoading(false);
        });
    }, [sala.codigo]);

    const handleSelectedUsuario = (usuario) => {

        const salaParticipante = {
            usuario: usuario,
            sala: sala
        }
        addUser(salaParticipante).then((data) => {
            if (data) {
                if (data.status === 409) {
                    toast.success("Usuário já cadastrado.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                } else {
                    setSalaParticipantes([
                        ...salaParticipantes,
                        data
                    ]);
                    toast.success("Operação Realizada com Sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }

            }
        })

    }


    const handleDelete = (sp) => {
        remove(sp.codigo).then((data) => {
            setSalaParticipantes(salaParticipantes.filter(spCurrent => spCurrent.codigo !== sp.codigo));
            toast.success("Operação Realizada com Sucesso.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
        })
    }

    const handleBuscarUsuario = () => {
        setOpenSearchUsuario(true);
    }

    const closeUsuarioSearch = () => {
        setOpenSearchUsuario(false);
    }

    return (
        <div>
            <ToastContainer autoClose={3000} />
            <Container className={classes.container} component={Paper}>
                <UsuarioFindDialog
                    openSearchUsuario={openSearchUsuario}
                    handleSelectedUsuario={handleSelectedUsuario}
                    closeUsuarioSearch={closeUsuarioSearch}
                />
                <Button
                    component={Link}
                    to={{
                        pathname: '/mensagem'
                    }}
                    variant="contained"
                    color="secondary"
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
                <Grid className={classes.header} container justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography variant="h5">
                            Participantes - {sala.descricao}
                        </Typography>

                    </Grid>
                    <Grid style={{ flexBasis: '5%' }} item xs={3}>
                        <AddIcon
                            fontSize="large"
                            onClick={handleBuscarUsuario}
                            className={classes.cursorLink}
                        />
                    </Grid>
                </Grid>
                {isLoading ? <Loading description="Carregando Participantes..." /> :
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {salaParticipantes.map((sp, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {sp.usuario.nome}
                                    </TableCell>
                                    <TableCell>
                                        <DeleteIcon
                                            className={classes.cursorLink}
                                            onClick={() => { handleDelete(sp) }}
                                            style={{ marginRight: '10px' }}
                                            color="secondary"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }


            </Container>

        </div>
    );
}