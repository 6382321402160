import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { errorMessage } from "../../../../util/ConfirmAlertUtil";
import Loading from "../../../UI/Loading";
import { findByCurrentUserAndSelectedYear } from "../../api-cronograma";
import CronogramaProfessorTable from "../CronogramaProfessorTable";

const CronogramaProfessorTableSemanaToda = () => {
  const [cronogramas, setCronogramas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    findByCurrentUserAndSelectedYear().then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setCronogramas(data);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading description="Carregando Cronograma Professor..." />
        ) : (
          <CronogramaProfessorTable cronogramas={cronogramas} />
        )}
      </Grid>
    </Grid>
  );
};

export default CronogramaProfessorTableSemanaToda;
