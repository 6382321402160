import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import {
    Player,
    ControlBar,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton,
    CurrentTimeDisplay,
    TimeDivider
} from 'video-react';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
});

export default props => {
    const classes = useStyles();
    const [conteudo] = useState({
        ...props.conteudo
      });

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Player
                    playsInline
                    poster={conteudo.video.posterUrl}
                >
                    <BigPlayButton position="center" />
                    <source
                        src={conteudo.video.videoUrl}
                        type="video/mp4"
                    />
                    <ControlBar>
                        <VolumeMenuButton order={1.1} vertical />
                        <CurrentTimeDisplay order={4.1} />
                        <TimeDivider order={4.2} />
                        <PlaybackRateMenuButton rates={[0.25, 0.5, 0.75, 1, 1.25, 1.75, 2]} order={7.1} />
                    </ControlBar>
                </Player>
            </Container>

        </div>
    );
}