import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'evento/';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

const save = (evento) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, evento);
}


const remove = (codEvento) => {
  const url = SERVER_URL + controller + codEvento;
  return fetchRemoveAuth(url);
}

const send = (codEvento) => {
  const url = SERVER_URL + controller + 'send/' + codEvento;
  return fetchGetAuth(url);
}

const getQuantAprovacaoEvento = () => {
  const url = SERVER_URL + controller + 'aprovacao';
  return fetchGetAuth(url);
}

export {
  page,
  save,
  remove,
  send,
  getQuantAprovacaoEvento
}