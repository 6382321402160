import React, { useEffect, useState } from 'react';
import Container from '../../UI/Container';
import CardapioSemanaTable from './CardapioSemanaTable';
import { findByCardapioCodigo } from './api-cardapio-semana';
import Loading from '../../UI/Loading';
import CardapioSemanaForm from './CardapioSemanaForm';
import Button from '../../UI/Button';
import { makeStyles, Typography } from '@material-ui/core';


const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    }
})

const CardapioSemanaContainer = ({ cardapio }) => {
    const classes = useStyles();
    const [listaCS, setListaCS] = useState([]);
    const [cardapioSemana, setCardapioSemana] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (!cardapio.eNovo) {
            findByCardapioCodigo(cardapio.codigo).then((data) => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setListaCS(data);
                }
                setIsLoading(false);
            })
        } else {
            setListaCS([]);
            setIsLoading(false);
        }


    }, [cardapio])

    const handleNovoRegistro = () => {
        setCardapioSemana({
            cardapio: cardapio,
            eNovo: true
        });
    }

    return (
        <Container>

            <Typography className={classes.title} variant="h6">
                Cardápio Semana
            </Typography>
            <Button onClick={handleNovoRegistro}>Novo Registro</Button>
            {isLoading ?
                <Loading description='Carregando Cardápio Semana...' /> :

                <>
                    {cardapioSemana ?
                        <CardapioSemanaForm
                            cardapioSemana={cardapioSemana}
                            setCardapioSemana={setCardapioSemana}
                            setListaCS={setListaCS}
                        /> :
                        <CardapioSemanaTable
                            listaCS={listaCS}
                            cardapioSemana={cardapioSemana}
                            setCardapioSemana={setCardapioSemana}
                        />
                    }

                </>

            }

        </Container>
    );
}

export default CardapioSemanaContainer;