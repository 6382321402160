import { SERVER_URL } from '../../constants';

import {
  fetchGetAuth, 
  fetchRemoveAuth,
  fetchPostAuth
} from '../../util/FetchUtil';
const controller = 'curso/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const remove = (codCurso) => {
  const url = SERVER_URL + controller + codCurso;
  return fetchRemoveAuth(url);
}

const save = (curso) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, curso);
}

export {
  findAll, 
  remove, 
  save
}