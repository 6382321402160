import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findAll, remove } from './api-disciplina';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux'
import { setDisciplinas, setDisciplina, removeDisciplinas } from '../../actions';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    }
});


export default function DisciplinaTable() {
    const classes = useStyles();
    const disciplinas = useSelector(state => state.disciplinas);
    const dispatch = useDispatch();

    useEffect(() => {
        findAll().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setDisciplinas(data));
            }
        }).catch(err => {
            console.log(err);
        })
    }, [dispatch]);


    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta disciplina?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        const codDisciplina = disciplinas[index].codDisciplina;
                        remove(codDisciplina).then((data) => {
                            if (data) {
                                dispatch(removeDisciplinas(codDisciplina));
                            } else {
                                handleDeleteError();
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'A Disciplina não pode ser removido pois possui Turmas cadastradas com a mesma. Remova todos os Disciplinas nas Turmas cadastradas.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <div>
            <Button
                onClick={() => dispatch(setDisciplina({ eNovo: true }))}
                variant="contained"
                className={classes.novoRegistro}
                color="primary">
                Nova Disciplina
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {disciplinas.map((disciplina, index) => (
                        <TableRow key={disciplina.codDisciplina}>
                            <TableCell>{disciplina.descricao}</TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => {
                                        dispatch(setDisciplina(disciplina));
                                    }}
                                    color="secondary"
                                    style={{ marginRight: '10px' }}
                                />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
}