import {
  SET_CRONOGRAMA,
  SET_CRONOGRAMAS,
  ADD_CRONOGRAMAS,
  UPDATE_CRONOGRAMAS,
  REMOVE_CRONOGRAMAS,
} from './actionTypes';
import { PURGE } from "redux-persist";

export function cronograma(state = null, action) {
  switch (action.type) {
    case SET_CRONOGRAMA:
      return action.cronograma

    case PURGE:
      return null;

    default:
      return state
  }
}

export function cronogramas(state = [], action) {
  switch (action.type) {
    case SET_CRONOGRAMAS:
      return action.cronogramas

    case ADD_CRONOGRAMAS:

      return [
        ...state,
        action.cronograma
      ]

    case UPDATE_CRONOGRAMAS:
      let content = state.map((cronograma) => {
        if (cronograma.codigo === action.cronograma.codigo) {
          return action.cronograma;
        } else {
          return cronograma;
        }
      });
      return content;

    case REMOVE_CRONOGRAMAS:
      return state.filter((cronograma) => cronograma.codigo !== action.codigo);

    case PURGE:
      return [];

    default:
      return state
  }
}
 