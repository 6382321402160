import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import TextField from "../UI/TextField";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useSelector } from "react-redux";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { remove } from "./api-tdp";
import { dateFormatMoment } from "../../util/DateUtil";
import TextFieldDate from "../UI/TextFieldDate";
import { useEffect } from "react";
import { findByPerfil } from "../Usuario/api-usuario-perfil";
import { PROFESSOR } from "../Usuario/Perfil";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import { findAll } from "../Livro/api-livro";

const useStyles = makeStyles({
  loading: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  linkBtn: {
    cursor: "pointer",
  },
});

const DisciplinaTurmaForm = ({
  tdp,
  setTdp,
  handleAdicionarDisciplina,
  loadingAdicionarDisciplina,
  unregisteredDisciplinas,
  setTdps,
  tdps,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const usuario = useSelector((state) => state.usuario);
  const [codTDPSelected, setCodTDPSelected] = useState(null);
  const [professoresPerfil, setProfessoresPerfil] = useState([]);
  const [livros, setLivros] = useState([]);

  const handleMenuClick = (event, codTDP) => {
    setAnchorEl(event.currentTarget);
    setCodTDPSelected(codTDP);
  };

  useEffect(() => {
    findByPerfil(PROFESSOR).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setProfessoresPerfil(data);
      }
    });

    findAll().then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setLivros(data);
      }
    });
  }, []);

  const handleDeleteError = () => {
    confirmAlert({
      title: "Erro",
      message:
        "A Disciplina/Turma não pode ser removido pois possui Presenças/Atividades cadastradas com a mesma. Remova todos os Registros cadastradas.",
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDisciplinaChange = (event) => {
    const disciplina = unregisteredDisciplinas.find((disciplina) => {
      return disciplina.codDisciplina === event.target.value;
    });
    setTdp({
      ...tdp,
      disciplina: disciplina,
    });
  };

  const handleSwitchChange = (name) => (event) => {
    if (name === "eEletiva") {
      setTdp((prevState) => ({
        ...prevState,
        [name]: event.target.checked,
        eProjeto: false,
      }));
    } else if (name === "eProjeto") {
      setTdp((prevState) => ({
        ...prevState,
        [name]: event.target.checked,
        eEletiva: false,
      }));
    } else {
      setTdp((prevState) => ({
        ...prevState,
        [name]: event.target.checked,
      }));
    }
  };


  const handleLivroChange = (event) => {
    const livro = livros.find((livro) => {
      return livro.codLivro === event.target.value;
    });
    setTdp({
      ...tdp,
      livro: livro,
    });
  };

  const handleChange = (event) => {
    setTdp({
      ...tdp,
      [event.target.name]: event.target.value,
    });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveTDP = () => {
    handleMenuClose();
    confirmAlert({
      title: "Alerta",
      message:
        "Deseja deletar esta disciplina da turma? ATENÇÃO: As Notas Bimestres desta disciplina serão excluídas após a confirmação.",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            remove(codTDPSelected).then((data) => {
              if (data.error) {
                handleDeleteError();
              } else {
                setTdps(tdps.filter((tdp) => tdp.codigo !== codTDPSelected));
                setTdp(null);
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDataChange = (data, name) => {
    if (data) data.setHours(0, 0, 0, 0);

    setTdp({
      ...tdp,
      [name]: data,
    });
  };

  const handleProfessorChange = (event) => {
    const professorPerfil = professoresPerfil.find((professorPerfil) => {
      return professorPerfil.usuario.id === event.target.value;
    });
    setTdp({
      ...tdp,
      usuario: professorPerfil.usuario,
    });
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item>
            <CloseIcon
              onClick={() => setTdp(null)}
              fontSize="small"
              className={classes.linkBtn}
            />
          </Grid>
          <Grid item>
            {!tdp.eNovo &&
            (usuario.perfil === "ADMIN" ||
              usuario.perfil === "COORDERNADOR") ? (
              <>
                <MenuIcon
                  fontSize="small"
                  className={classes.linkBtn}
                  onClick={(event) => handleMenuClick(event, tdp.codigo)}
                  color="secondary"
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleRemoveTDP}>
                    <DeleteForeverIcon
                      style={{ marginRight: "5px", color: "#b20202" }}
                    />
                    Excluir Turma/Disciplina
                  </MenuItem>
                </Menu>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <form onSubmit={handleAdicionarDisciplina}>
          <FormControl required fullWidth>
            <Grid container spacing={2} direction="column">
              <Grid item>
                {tdp.eNovo ? (
                  <>
                    <InputLabel id="disciplina-label">Disciplinas</InputLabel>
                    <Select
                      labelId="disciplina-label"
                      id="disciplina-select"
                      disabled={tdp.eNovo ? false : true}
                      value={
                        tdp && tdp.disciplina
                          ? tdp.disciplina.codDisciplina
                          : ""
                      }
                      onChange={handleDisciplinaChange}
                    >
                      {unregisteredDisciplinas.map((disciplina, index) => (
                        <MenuItem key={index} value={disciplina.codDisciplina}>
                          {disciplina.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <Typography variant="body1">
                    Disciplina: {tdp.disciplina.descricao}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={tdp.eNovo ? false : true}
                          checked={tdp && tdp.eEletiva ? true : false}
                          onChange={handleSwitchChange("eEletiva")}
                          value={tdp && tdp.eEletiva ? true : false}
                        />
                      }
                      label="É Eletiva"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={tdp.eNovo ? false : true}
                          checked={tdp && tdp.eProjeto ? true : false}
                          onChange={handleSwitchChange("eProjeto")}
                          value={tdp && tdp.eProjeto ? true : false}
                        />
                      }
                      label="É Projeto"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={tdp.eNovo ? false : true}
                          checked={tdp && tdp.escolhaAluno ? true : false}
                          onChange={handleSwitchChange("escolhaAluno")}
                          value={tdp && tdp.escolhaAluno ? true : false}
                        />
                      }
                      label="Escolha Aluno"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {tdp && tdp.eProjeto ? (
                <>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={tdp && tdp.eDataSerie ? true : false}
                          onChange={handleSwitchChange("eDataSerie")}
                          value={tdp && tdp.eDataSerie ? true : false}
                        />
                      }
                      label="É Data Série"
                    />
                  </Grid>
                  {tdp && tdp.eProjeto && !tdp.eDataSerie ? (
                    <>
                      <Grid item>
                        <TextFieldDate
                          label="Data Início"
                          value={
                            tdp && tdp.dataInicio
                              ? dateFormatMoment(tdp.dataInicio)
                              : null
                          }
                          onChange={(data) =>
                            handleDataChange(data, "dataInicio")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TextFieldDate
                          label="Data Fim"
                          value={
                            tdp && tdp.dataFim
                              ? dateFormatMoment(tdp.dataFim)
                              : null
                          }
                          onChange={(data) => handleDataChange(data, "dataFim")}
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {tdp && tdp.escolhaAluno ? (
                <Grid item>
                  <TextField
                    inputProps={{ maxLength: 3 }}
                    label="Capacidade Máxima"
                    name="capacidadeMaxima"
                    onChange={handleChange}
                    value={
                      tdp && tdp.capacidadeMaxima ? tdp.capacidadeMaxima : ""
                    }
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item>
                <TextField
                  inputProps={{ maxLength: 10 }}
                  label="Carga Horária"
                  name="cargaHoraria"
                  onChange={handleChange}
                  value={tdp && tdp.cargaHoraria ? tdp.cargaHoraria : ""}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleSwitchChange("enviarNotificacaoPresenca")}
                      value={
                        tdp.enviarNotificacaoPresenca
                          ? tdp.enviarNotificacaoPresenca
                          : ""
                      }
                      checked={
                        tdp.enviarNotificacaoPresenca
                          ? tdp.enviarNotificacaoPresenca
                          : false
                      }
                    />
                  }
                  label="Enviar Notificação Presença"
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="professor-label">Professor</InputLabel>
                  <Select
                    labelId="professor-label"
                    id="professor-select"
                    value={tdp.usuario ? tdp.usuario.id : ""}
                    onChange={handleProfessorChange}
                  >
                    {professoresPerfil.map((professorPerfil) => (
                      <MenuItem
                        key={professorPerfil.usuario.id}
                        value={professorPerfil.usuario.id}
                      >
                        {professorPerfil.usuario.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="livro-label">Livro</InputLabel>
                  <Select
                    labelId="livro-label"
                    id="livro-select"
                    value={tdp.livro ? tdp.livro.codLivro : ""}
                    onChange={handleLivroChange}
                  >
                    {livros.map((livro, index) => (
                      <MenuItem key={index} value={livro.codLivro}>
                        {livro.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={loadingAdicionarDisciplina}
                >
                  {loadingAdicionarDisciplina && (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                      size={20}
                    />
                  )}
                  {tdp.eNovo ? "Adicionar Disciplina" : "Salvar"}
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
};

export default DisciplinaTurmaForm;
