import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const TDPComboBox = ({ tdps, tdp, handleTDPChange }) => {
    return (
        <FormControl required fullWidth >
            <InputLabel id="tdp-label">Turma/Disciplina</InputLabel>
            <Select
                labelId="tdp-label"
                id="tdp-select"
                value={tdp && tdp.codigo ? tdp.codigo : ''}
                onChange={handleTDPChange}
            >
                {tdps.map((tdp) => (
                    <MenuItem key={tdp.codigo} value={tdp.codigo}>{tdp.turma.descricao} ({tdp.turma.curso.abreviacao}) - {tdp.disciplina.descricao}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default TDPComboBox;