import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByEvento, save, remove } from './api-evento-aluno';
import DeleteIcon from '@material-ui/icons/Delete';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { open, setAluno } from '../../Aluno/actions/actions';

const useStyles = makeStyles({
    title: {
        paddingTop: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function EventoAlunoTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const evento = useSelector(state => state.evento);
    const [eventoAlunos, setEventoAlunos] = useState([]);
    const aluno = useSelector(state => state.aluno);

    useEffect(() => {
        dispatch(setAluno(null));
    }, [dispatch]);

    useEffect(() => {
        if (aluno !== null) {
            const newEventoAluno = {
                evento: evento,
                aluno: aluno
            }
            save(newEventoAluno).then((data) => {
                if (data) {
                    if (data.error) {
                        toast.error(`Aluno ${aluno} já cadastrada.`, {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    } else {
                        setEventoAlunos(prevState => ([
                            ...prevState,
                            data
                        ]));
                    }

                }

            });
            dispatch(setAluno(null));
        }
    }, [aluno, evento, dispatch]);

    useEffect(() => {
        findByEvento(evento.codigo).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setEventoAlunos(data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [evento.codigo]);


    const handleDelete = (index) => {
        if (!evento.enviado) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja deletar este aluno do evento?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            remove(eventoAlunos[index].codigo).then((data) => {
                                if (data && data.error) {
                                    console.log(data.error);

                                } else {
                                    setEventoAlunos(
                                        eventoAlunos.filter((ct) => ct.codigo !== eventoAlunos[index].codigo)
                                    );

                                }
                            });
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }

    };

    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Evento Aluno
                </Typography>
                <Button
                    onClick={() => {
                        dispatch(setAluno(null));
                        dispatch(open());
                    }}
                    variant="contained"
                    className={classes.novoRegistro}
                    disabled={evento.enviado}
                    color="primary">
                    Adicionar Aluno
                </Button>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Aluno</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventoAlunos.map((ca, index) => (
                            <TableRow key={ca.codigo}>
                                <TableCell>{ca.aluno.nome}</TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        className={classes.cursorLink}
                                        onClick={() => { handleDelete(index) }}
                                        color="secondary"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>

        </div>
    );
}