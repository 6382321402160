import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '../UI/Container';
import ButtonImageLabel from '../UI/ButtonImageLabel';
import PresencaIntegralIcon from '../../shared/assets/img/integral/presenca_integral.png'
import CardapioIntegralIcon from '../../shared/assets/img/integral/cardapio.png'
import RotinaIntegralIcon from '../../shared/assets/img/integral/saude.png'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ADMIN, COORDENADOR, PROFESSOR, ALUNO, RESPONSAVEL } from '../Usuario/Perfil';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    divMenu: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px'
    },
    divButton: {
        width: '50%'
    }
})

const IntegralMenu = () => {
    const classes = useStyles();
    const history = useHistory();
    const usuario = useSelector(state => state.usuario);

    const handleGoToPresenca = () => {
        if (usuario.perfil === ADMIN ||
            usuario.perfil === COORDENADOR ||
            usuario.perfil === PROFESSOR) {
            history.push('/integral-presenca');
        } else if (usuario.perfil === ALUNO ||
            usuario.perfil === RESPONSAVEL) {
            history.push('/integral-presenca-aluno');
        }
    }

    const handleGoToCardapio = () => {
        if (usuario.perfil === ADMIN ||
            usuario.perfil === COORDENADOR ||
            usuario.perfil === PROFESSOR) {
            history.push('/cardapio');
        } else if (usuario.perfil === ALUNO ||
            usuario.perfil === RESPONSAVEL) {
            history.push('/cardapio-usuario');
        }
    }

    const handleGoToRotina = () => {
        if (usuario.perfil === ADMIN ||
            usuario.perfil === COORDENADOR ||
            usuario.perfil === PROFESSOR) {
            history.push('/rotina');
        } else if (usuario.perfil === ALUNO ||
            usuario.perfil === RESPONSAVEL) {
            history.push('/rotina-usuario');
        }
    }

    return (
        <>
            <Container>
                <Typography className={classes.title} variant="h6">
                    Integral
                </Typography>
                <div className={classes.divMenu}>
                    <div className={classes.divButton}>
                        <ButtonImageLabel
                            description='Presença'
                            image={PresencaIntegralIcon}
                            onClick={handleGoToPresenca}
                        />
                    </div>
                    <div className={classes.divButton}>
                        <ButtonImageLabel
                            description='Cardápio'
                            image={CardapioIntegralIcon}
                            onClick={handleGoToCardapio}
                        />
                    </div>
                    <div className={classes.divButton}>
                        <ButtonImageLabel
                            description='Rotina'
                            image={RotinaIntegralIcon}
                            onClick={handleGoToRotina}
                        />
                    </div>
                </div>




            </Container>
        </>
    );
}

export default IntegralMenu;