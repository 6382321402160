import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Container from '../../UI/Container';
import Loading from '../../UI/Loading';
import IntegralPresencaTable from '../Presenca/IntegralPresencaTable';
import { findByTurmaAnoLetivo } from '../Professor/api-integral-professor';
import IntegralProfessorTable from '../Professor/IntegralProfessorTable';
import { findByIntegralProfessorCodigo } from '../Presenca/api-integral-presenca';
import { findByIntegralPresencaCodigo } from './api-integral-rotina';
import IntegralRotinaTable from './IntegralRotinaTable';
import IntegralRotinaForm from './IntegralRotinaForm';
import { useHistory } from 'react-router-dom';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    btnMenuIntegral: {
        marginTop: '10px',
        marginBottom: '10px'
    }
})

const IntegralRotinaContainer = () => {
    const classes = useStyles();
    const [integralProfessor, setIntegralProfessor] = useState(null);
    const [isLoadingIP, setIsLoadingIP] = useState(true);
    const [listaIP, setListaIP] = useState([]);
    const [integralPresenca, setIntegralPresenca] = useState(null);
    const [isLoadingP, setIsLoadingP] = useState(true);
    const [listaPresenca, setListaPresenca] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const [isLoadingRotina, setIsLoadingRotina] = useState(true);
    const [listaIR, setListaIR] = useState([]);
    const [integralRotina, setIntegralRotina] = useState(null);
    const history = useHistory();

    useEffect(() => {
        findByTurmaAnoLetivo().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaIP(data);
            }
            setIsLoadingIP(false);
        })
    }, []);

    useEffect(() => {
        if (integralProfessor) {
            setIsLoadingP(true);
            findByIntegralProfessorCodigo(integralProfessor.codigo, 0).then((data) => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setListaPresenca(data);
                    setIsLoadingP(false);
                }
            })
        }

    }, [integralProfessor]);

    useEffect(() => {
        if (integralPresenca) {
            setIsLoadingRotina(true);
            findByIntegralPresencaCodigo(integralPresenca.codigo).then((data) => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setListaIR(data);
                }
                setIsLoadingRotina(false);
            })
        }
    }, [integralPresenca]);

    const handleChangePage = (event, newPage) => {
        setIsLoadingP(true);
        findByIntegralProfessorCodigo(integralProfessor.codigo, newPage).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaPresenca(data);
                setIsLoadingP(false);
            }
        })
    };

    const handleMenuIntegral = () => {
        history.push("/integral");
    }


    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Rotina Integral
            </Typography>

            <div className={classes.btnMenuIntegral}>
                <Button
                    onClick={handleMenuIntegral}
                >
                    Menu Integral
                </Button>
            </div>

            {integralProfessor ?
                <>
                    {integralPresenca ?
                        <>
                            {integralRotina ?
                                <IntegralRotinaForm
                                    integralRotina={integralRotina}
                                    setIntegralRotina={setIntegralRotina}
                                    setListaIR={setListaIR}
                                /> :
                                <>
                                    {isLoadingRotina ? <Loading description='Carregando Integral Rotina...' /> :
                                        <IntegralRotinaTable
                                            listaIR={listaIR}
                                            setIntegralRotina={setIntegralRotina}
                                        />
                                    }
                                </>
                            }


                        </> :
                        <>
                            {isLoadingP ? <Loading description='Carregando Integral Presença...' /> :
                                <IntegralPresencaTable
                                    listaPresenca={listaPresenca}
                                    setIntegralPresenca={setIntegralPresenca}
                                    handleChangePage={handleChangePage}
                                />
                            }

                        </>

                    }

                </>
                :
                <>
                    {isLoadingIP ?
                        <Loading description="Carregando Integral Professor..." /> :
                        <IntegralProfessorTable
                            listaIP={listaIP}
                            setIntegralProfessor={setIntegralProfessor}
                        />
                    }

                </>
            }


        </Container>
    );
}

export default IntegralRotinaContainer;