import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { dateFormat } from '../../util/DateUtil';


export default function BimestreProfileInfo({ bimestre }) {


    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Ano Letivo
                </Typography>
                <Typography variant="body1">
                    {bimestre.anoLetivo}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Série
                </Typography>
                <Typography variant="body1">
                    {bimestre.serie.descricao}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Nº Bimestre
                </Typography>
                <Typography variant="body1">
                    {bimestre.numeroBimestre}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Data Início
                </Typography>
                <Typography variant="body1">
                    {dateFormat(bimestre.dataInicio)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Data Fim
                </Typography>
                <Typography variant="body1">
                    {dateFormat(bimestre.dataFim)}
                </Typography>
            </Grid>
        </Grid>
    );
}