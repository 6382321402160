import React, { useEffect, useState } from 'react';
import SerieTableSelect from '../../Serie/SerieTableSelect';
import SerieProfile from '../../Serie/SerieProfile';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import DiaNaoLetivoSerieTable from './DiaNaoLetivoSerieTable';
import { deleteById, save, findByAnoLetivoAndSerieCodSerie } from './api-dia-nao-letivo-serie';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { confirmAlert } from 'react-confirm-alert';
import DiaNaoLetivoSerieTableAdding from './DiaNaoLetivoSerieTableAdding';


const useStyles = makeStyles({
    container: {
        flexGrow: '1',
        margin: '20px',
        padding: '10px'

    }
})

const DiaNaoLetivoSerieContainer = () => {
    const [serie, setSerie] = useState(null);
    const classes = useStyles();
    const [listDiaNaoLetivoSerie, setListDiaNaoLetivoSerie] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isLoadingAction, setIsLoadingAction] = useState(false);

    useEffect(() => {
        if (serie && serie.codSerie) {
            setIsLoading(true);
            findByAnoLetivoAndSerieCodSerie(serie.codSerie).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListDiaNaoLetivoSerie(data);
                }
                setIsLoading(false);
            })
        }

    }, [serie]);

    const handleRemove = (codDiaNaoLetivoSerie) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este registro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        deleteById(codDiaNaoLetivoSerie).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setListDiaNaoLetivoSerie((prevState) => prevState.filter((ps) => ps.codigo !== codDiaNaoLetivoSerie));
                            }
                        })
                        setIsLoadingAction(false);
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    const handleSave = async (listDiaNaoLetivoSerieSelect) => {
        setIsLoadingAction(true);
        await listDiaNaoLetivoSerieSelect.forEach((dnl) => {
            const dnlSerie = {
                diaNaoLetivo: dnl,
                serie: serie
            }
            save(dnlSerie).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListDiaNaoLetivoSerie((prevState) => ([
                        ...prevState,
                        data
                    ]));
                }
            })
        })
        setIsAdding(false);
        setIsLoadingAction(false);
    }

    return (
        <Container maxWidth='lg'>
            <Grid
                container
                component={Paper}
                justifyContent='center'
                spacing={3}
                className={classes.container}
            >
                {serie ?
                    <Grid container component={Paper}>
                        <SerieProfile
                            serie={serie}
                            setSerie={setSerie}
                        />
                        {isAdding ?
                            <>
                                <DiaNaoLetivoSerieTableAdding
                                    serie={serie}
                                    handleSave={handleSave}
                                    isLoadingAction={isLoadingAction}
                                    setIsAdding={setIsAdding}
                                />
                            </> :
                            <DiaNaoLetivoSerieTable
                                listDiaNaoLetivoSerie={listDiaNaoLetivoSerie}
                                isLoading={isLoading}
                                setIsAdding={setIsAdding}
                                handleRemove={handleRemove}
                                isLoadingAction={isLoadingAction}
                            />
                        }


                    </Grid> :
                    <SerieTableSelect setSerie={setSerie} />
                }

            </Grid>
        </Container>
    )
}

export default DiaNaoLetivoSerieContainer;