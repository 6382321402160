import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
} from '../../../util/FetchUtil';

const controller = 'sala/';

const page = (page) => {
  const url = SERVER_URL + controller + "?page=" + page;
  return fetchGetAuth(url);
}

const findAllByUsuarioNome = (nome, page) => {
  const url = SERVER_URL + controller + "find-all?nome=" + nome + "&page=" + page;
  console.log(url);
  return fetchGetAuth(url);
}

export {
  page,
  findAllByUsuarioNome
}