import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByEvento, save, remove } from './api-evento-turma';
import DeleteIcon from '@material-ui/icons/Delete';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { openSearchTurmas, setTurmas } from "../../Turma/actions/actions";
import Typography from '@material-ui/core/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
    title: {
        paddingTop: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function EventoTurmaTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const evento = useSelector(state => state.evento);
    const [eventoTurmas, setEventoTurmas] = useState([]);
    const turmas = useSelector(state => state.turmas);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        dispatch(setTurmas([]));
    }, [dispatch]);

    useEffect(() => {
        if (turmas.length > 0 && isAdding) {
            turmas.forEach((t) => {
                const newEventoTurma = {
                    evento: evento,
                    turma: t
                }
                save(newEventoTurma).then((data) => {
                    if (data) {
                        if (data.error) {
                            toast.error(`Turma ${t.descricao} já cadastrada.`, {
                                position: toast.POSITION.BOTTOM_LEFT
                            });
                        } else {
                            setEventoTurmas(prevState => ([
                                ...prevState,
                                data
                            ]));
                        }

                    }

                });
                setIsAdding(false);
            });
            dispatch(setTurmas([]));
        }
    }, [turmas, evento, dispatch, isAdding]);

    useEffect(() => {
        findByEvento(evento.codigo).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setEventoTurmas(data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [evento.codigo]);


    const handleDelete = (index) => {
        if (!evento.enviado) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja deletar esta turma do evento?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            remove(eventoTurmas[index].codigo).then((data) => {
                                if (data && data.error) {
                                    console.log(data.error);
                                } else {
                                    setEventoTurmas(
                                        eventoTurmas.filter((ct) => ct.codigo !== eventoTurmas[index].codigo)
                                    );

                                }
                            });
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }
    };

    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Evento Turma
                </Typography>
                <Button
                    onClick={() => {
                        dispatch(setTurmas([]));
                        dispatch(openSearchTurmas());
                        setIsAdding(true);
                    }}
                    variant="contained"
                    className={classes.novoRegistro}
                    disabled={evento.enviado}
                    color="primary">
                    Adicionar Turma
                </Button>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Turma</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventoTurmas.map((ct, index) => (
                            <TableRow key={ct.codigo}>
                                <TableCell>{ct.turma.descricao}</TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        fontSize='small'
                                        className={classes.cursorLink}
                                        onClick={() => { handleDelete(index) }}
                                        color="secondary"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>

        </div>
    );
}