import React from "react";
import { useEffect } from "react";
import ComunicadoBannerList from "./ComunicadoBannerList";
import { page } from "./api-comunicado-banner";
import { useState } from "react";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, DialogActions } from "@material-ui/core";

const ComunicadoBannerDialog = ({ open, handleClose, setComunicadoBanner }) => {
  const [listCB, setListCB] = useState({
    content: [],
  });
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    page(pageNumber).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setListCB(data);
      }
    });
  }, [pageNumber]);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Selecione um Banner</DialogTitle>
      <DialogContent>
        <ComunicadoBannerList
          listCB={listCB}
          setComunicadoBanner={setComunicadoBanner}
          setPageNumber={setPageNumber}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComunicadoBannerDialog;
