import {
    SET_USUARIO,
    SET_PERFIL,
    OPEN_USUARIO,
    CLOSE_USUARIO,
    ADD_USUARIOS,
    UPDATE_USUARIOS,
    REMOVE_USUARIOS,
    SET_USUARIO_SEARCH
} from './actionTypes'

export function setUsuarioSearch(usuario) {
    return { type: SET_USUARIO_SEARCH, usuario }
}

export function setUsuario(usuario) {
    return { type: SET_USUARIO, usuario }
}

export function setPerfil(perfil) {
    return { type: SET_PERFIL, perfil }
}

export function openUsuario() {
    return { type: OPEN_USUARIO }
}

export function closeUsuario() {
    return { type: CLOSE_USUARIO }
}

export function addUsuarios(usuario) {
    return { type: ADD_USUARIOS, usuario }
}

export function updateUsuarios(usuario) {
    return { type: UPDATE_USUARIOS, usuario }
}

export function removeUsuarios(index) {
    return { type: REMOVE_USUARIOS, index }
}