import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "../UI/TextField";

const useStyles = makeStyles({
  closeIcon: {
    cursor: "pointer",
  },
  loadingIconBtn: {
    marginRight: "15px",
  },
});

const LocalForm = ({
  setLocal,
  local,
  clickSubmit,
  handleRemove,
  isLoadingAction,
}) => {
  const classes = useStyles();

  const onChange = (event) => {
    setLocal({
      ...local,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <CloseIcon
          onClick={() => setLocal(null)}
          className={classes.closeIcon}
        />
      </Grid>
      <Grid item>
        <form onSubmit={clickSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Descrição"
                onChange={onChange}
                name="descricao"
                value={local.descricao ? local.descricao : ""}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="row">
                <Grid item>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={isLoadingAction}
                  >
                    {isLoadingAction && (
                      <CircularProgress
                        className={classes.loadingIconBtn}
                        size={20}
                      />
                    )}
                    Salvar
                  </Button>
                </Grid>
                {!local.eNovo ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleRemove}
                      disabled={isLoadingAction}
                    >
                      {isLoadingAction && (
                        <CircularProgress
                          className={classes.loadingIconBtn}
                          size={20}
                        />
                      )}
                      Deletar
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default LocalForm;
