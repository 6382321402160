import {
  ADD_COMUNICADO_CATEGORIAS,
  UPDATE_COMUNICADO_CATEGORIAS,
  SET_COMUNICADO_CATEGORIAS,
  REMOVE_COMUNICADO_CATEGORIAS,
  SET_COMUNICADO_CATEGORIA
} from './actionTypes';
import { PURGE } from "redux-persist";

export function comunicadoCategoria(state = null, action) {
  switch (action.type) {
    case SET_COMUNICADO_CATEGORIA:
      return action.categoria

    case PURGE:
      return null;

    default:
      return state
  }
}

export function comunicadoCategorias(state = [], action) {
  switch (action.type) {

    case ADD_COMUNICADO_CATEGORIAS:
      return [
        action.categoria,
        ...state
      ]

    case UPDATE_COMUNICADO_CATEGORIAS:
      let content = state.map((categoria) => {
        if (categoria.codigo === action.categoria.codigo) {
          return action.categoria;
        } else {
          return categoria;
        }
      });
      return content;

    case SET_COMUNICADO_CATEGORIAS:
      return action.categorias

    case REMOVE_COMUNICADO_CATEGORIAS:
      return state.filter((categoria) => categoria.codigo !== action.codCategoria)
    case PURGE:
      return [];

    default:
      return state
  }
}
