import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../UI/Button';
import { bimestreKey, diaLetivoExtraKey, diaNaoLetivoKey } from './BimestreContainerMenu';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        padding: '20px',
        marginTop: '10px',
        marginBottom: '10px',

    }
})

const BimestreMenu = ({ menu, setMenu }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            component={Paper}
            spacing={3}
            justifyContent='center'
            className={classes.container}
        >
            <Grid
                item
            >
                <Button
                    onClick={() => setMenu(bimestreKey)}
                    disabled={menu === bimestreKey}
                >
                    Bimestre
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => setMenu(diaLetivoExtraKey)}
                    disabled={menu === diaLetivoExtraKey}
                >
                    Dia Letivo Extra
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => setMenu(diaNaoLetivoKey)}
                    disabled={menu === diaNaoLetivoKey}
                >
                    Dia não Letivo
                </Button>
            </Grid>

        </Grid>
    )
}

export default BimestreMenu;