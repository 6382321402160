import {
  SET_TURMA,
  SET_TURMAS,
  OPEN_SEARCH_TURMAS,
  CLOSE_SEARCH_TURMAS,
  ADD_TURMAS,
  UPDATE_TURMAS,
  REMOVE_TURMAS
} from "./actionTypes";
import { PURGE } from "redux-persist";

export function turma(state = null, action) {
  switch (action.type) {
    case SET_TURMA:
      return action.turma

    case PURGE:
      return null;

    default:
      return state
  }
}

export function turmas(state = [], action) {
  switch (action.type) {
    case SET_TURMAS:
      return action.turmas;

    case ADD_TURMAS:
      return [
        ...state,
        action.turma
      ]

    case UPDATE_TURMAS:
      let content = state.map((turma) => {
        if (turma.codigo === action.turma.codigo) {
          return action.turma;
        } else {
          return turma;
        }
      });
      return content;

    case REMOVE_TURMAS:
      return state.filter((turma) => turma.codigo !== action.codigo);

    case PURGE:
      return [];

    default:
      return state;
  }
}

export function openSearchTurmas(state = false, action) {
  switch (action.type) {
    case OPEN_SEARCH_TURMAS:
      return true;

    case CLOSE_SEARCH_TURMAS:
      return false;

    case PURGE:
      return false;
    default:
      return state;
  }
}