import {
  ADD_LIVROS,
  UPDATE_LIVROS,
  SET_LIVROS,
  REMOVE_LIVROS,
  SET_LIVRO,
  OPEN_LIVRO,
  CLOSE_LIVRO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchLivro(state = false, action) {
  switch (action.type) {
    case OPEN_LIVRO:
      return true;
    case CLOSE_LIVRO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function livro(state = null, action) {
  switch (action.type) {
    case SET_LIVRO:
      return action.livro
    case PURGE:
      return null;
    default:
      return state
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function livros(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_LIVROS:
      persist.content = [
        ...persist.content,
        action.livro
      ]
      return persist

    case UPDATE_LIVROS:
      let content = state.content.map((livro) => {
        if (livro.codLivro === action.livro.codLivro) {
          return action.livro;
        } else {
          return livro;
        }
      });
      persist.content = content;
      return persist;

    case SET_LIVROS:
      return action.livros

    case REMOVE_LIVROS:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}
