import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({

    container: {
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px',
        "&:hover, &:focus": {
            backgroundColor: '#afa'
        },
    },
    fotoCapa: {
        width: '100%'
    },

})

const MuralCard = ({ mural }) => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid
            className={classes.container}
            direction='row'
            container
            spacing={3}
            onClick={() => history.push('mural-foto-usuario', { mural })}
        >
            {mural.fotoCapa ?
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    xl={3}
                >
                    <img
                        className={classes.fotoCapa}
                        src={mural.fotoCapa}
                        alt=''
                    />

                </Grid> : ''
            }
            <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={9}
                xl={9}
            >
                <Grid
                    container
                    justifyContent='space-between'
                    direction='column'
                >
                    <Grid
                        item
                    >
                        <Typography variant="h6">
                            {mural.descricao}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                    >
                        <Typography variant="body2">
                            Criado Por: {mural.usuario.nome}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )
}

export default MuralCard;