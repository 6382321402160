import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AlunoFindDialog from '../Aluno/AlunoFindDialog';
import Button from '../UI/Button';
import AlunoProfile from '../Aluno/AlunoProfile';
import AlunoFotoList from './AlunoFotoList';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Typography from '@material-ui/core/Typography';
import { findByAlunoCodigo } from './api-aluno-foto';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
    container: {
        backgroundColor: '#fff',
        margin: '5px',
        padding: '10px'
    }
})

const AlunoFotoContainer = () => {
    const classes = useStyles();
    const [aluno, setAluno] = useState(null);
    const [listaAF, setListaAF] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (aluno) {
            findByAlunoCodigo(aluno.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListaAF(data);
                }
            })
        }
    }, [aluno]);

    return (
        <Grid
            className={classes.container}
            container
            direction='column'
            spacing={3}
        >
            <Grid item >
                <Typography variant="h6">
                    Aluno Foto
                </Typography>
            </Grid>
            <AlunoFindDialog
                openSearch={openSearch}
                setOpenSearch={setOpenSearch}
                setAluno={setAluno}
            />
            {
                aluno ?
                    <>
                        <AlunoProfile
                            aluno={aluno}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                            justifyContent='center'
                            alignContent='center'
                            alignItems='center'
                        >
                            <Button
                                onClick={() => setAluno(null)}
                            >
                                Trocar Aluno
                            </Button>
                        </Grid>
                        <AlunoFotoList
                            listaAF={listaAF}
                        />
                    </> :
                    <Grid container direction='row'>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                            justifyContent='center'
                            alignContent='center'
                            alignItems='center'
                        >
                            <Button
                                onClick={() => setOpenSearch(true)}
                            >
                                Selecionar Aluno
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                            justifyContent='center'
                            alignContent='center'
                            alignItems='center'
                        >
                            <Button
                                onClick={() => history.push("aluno-foto-insert")}
                            >
                                Inserir por Ano Letivo
                            </Button>
                        </Grid>
                    </Grid>
            }

        </Grid >
    )
}

export default AlunoFotoContainer;