import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { page, remove } from './api-atividade';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setAtividades, removeAtividades, setAtividade } from './actions/actions';
import { dateFormat } from '../../util/DateUtil';
import { Link } from 'react-router-dom';
import { setAlunoTurma } from "../AlunoTurma/actions/actions";
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Loading from '../UI/Loading';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AtividadeTable() {
    const classes = useStyles();
    const atividades = useSelector(state => state.atividades)
    const tdp = useSelector(state => state.tdp);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        page(tdp.codigo, 0).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setAtividades(data));
                dispatch(setAtividade(null));
            }
            setIsLoading(false);
        });
    }, [dispatch, tdp.codigo]);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(tdp.codigo, newPage).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setAtividades(data));
            }
            setIsLoading(false);
        });
    };

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta atividade?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(atividades.content[index].codigo).then((data) => {
                            dispatch(removeAtividades(atividades.content[index].codigo));
                            dispatch(setAtividade(null));
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <div>
            <Button
                className={classes.novoRegistro}
                onClick={() => dispatch(setAtividade({ tdp: tdp }))}
                variant="contained"
                color="primary">
                Nova Atividade
            </Button>
            {isLoading ? <Loading description="Carregando Atividades..." /> :
                <>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {atividades.content.map((atividade, index) => (
                                <TableRow key={atividade.codigo}>
                                    <TableCell>{atividade.descricao}</TableCell>
                                    <TableCell>{dateFormat(atividade.data)}</TableCell>
                                    <TableCell>
                                        <EditIcon
                                            fontSize='small'
                                            color="secondary"
                                            style={{ marginRight: '10px' }}
                                            className={classes.cursorLink}
                                            onClick={() => dispatch(setAtividade(atividade))}
                                        />
                                        <DeleteIcon
                                            fontSize='small'
                                            className={classes.cursorLink}
                                            onClick={() => { handleDelete(index) }}
                                            style={{ marginRight: '10px' }}
                                            color="secondary"
                                        />
                                        <Button
                                            size='small'
                                            component={Link}
                                            to={'/atividade-aluno'}
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                dispatch(setAtividade(atividade));
                                                dispatch(setAlunoTurma(null));
                                            }}
                                            className={classes.submit}>
                                            Atividade Aluno
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={atividades.size ? atividades.size : 0}
                        component="div"
                        count={atividades.totalElements}
                        page={atividades.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>
            }

        </div>
    );
}