import {
    SET_IS_ALL,
    SET_SALAS
} from './actionTypes'

export function setIsAll(isAll) {
    return { type: SET_IS_ALL, isAll }
}

export function setSalas(salas) {
    return { type: SET_SALAS, salas }
}