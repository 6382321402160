import {
  ADD_EVENTOS,
  UPDATE_EVENTOS,
  SET_EVENTOS,
  REMOVE_EVENTOS,
  SET_EVENTO,
  OPEN_SEARCH_EVENTO,
  CLOSE_SEARCH_EVENTO,
  SET_QUANT_APROVACAO_EVENTO,
  DECREASE_QUANT_APROVACAO_EVENTO,
  INCREASE_QUANT_APROVACAO_EVENTO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchEvento(state = false, action) {
  switch (action.type) {
    case OPEN_SEARCH_EVENTO:
      return true;
    case CLOSE_SEARCH_EVENTO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function evento(state = null, action) {
  switch (action.type) {
    case SET_EVENTO:
      return action.evento
    case PURGE:
      return null;

    default:
      return state
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function eventos(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_EVENTOS:
      persist.content = [
        ...persist.content,
        action.evento
      ]
      return persist

    case UPDATE_EVENTOS:
      let content = state.content.map((evento) => {
        if (evento.codigo === action.evento.codigo) {
          return action.evento;
        } else {
          return evento;
        }
      });
      persist.content = content;
      return persist;

    case SET_EVENTOS:
      return action.eventos

    case REMOVE_EVENTOS:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}


export function quantAprovacaoEvento(state = 0, action) {
  switch (action.type) {
    case SET_QUANT_APROVACAO_EVENTO:
      return action.quantAprovacao
    case DECREASE_QUANT_APROVACAO_EVENTO:
      return state - 1
    case INCREASE_QUANT_APROVACAO_EVENTO:
      return state + 1
    case PURGE:
      return 0;
    default:
      return state
  }
}