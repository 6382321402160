import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { getBase64 } from '../../../util/Base64';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { handleFileSizeException } from '../../../util/ExceptionUtil';
import Button from '../../UI/Button';
import InputImageByte from '../../UI/InputImageByte';
import { deleteById, save } from './api-mural-foto';


const useStyles = makeStyles({
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});


const MuralFotoForm = ({
    mural,
    muralFoto,
    setMuralFoto,
    setMuralFotos
}) => {
    const classes = useStyles();
    const [isLoadingAction, setIsLoadingAction] = useState(false);

    const handleChangeImage = e => {
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i]
            if (file.size > 10000000) {
                handleFileSizeException();
                return;
            }
            getBase64(file)
                .then(result => {
                    setIsLoadingAction(true);
                    let persist = {
                        foto: result,
                        mural: mural,
                        fileType: file.type,
                        fileName: file.name,
                        fileSize: file.size
                    }
                    save(persist).then((data) => {
                        if (data.error) {
                            errorMessage(data.message);
                        } else {
                            setMuralFotos((prevState) => ({
                                ...prevState,
                                content: [
                                    ...prevState.content,
                                    data
                                ]
                            }));
                        }
                        setIsLoadingAction(false);
                    })
                });

        }


    };

    const handleDeletePhoto = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta foto?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleteById(muralFoto.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setMuralFotos((prevState) => ({
                                    ...prevState,
                                    content: prevState.content.filter((ps) => ps.codigo !== muralFoto.codigo)
                                }));
                                setMuralFoto(null);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    return (
        <Grid container spacing={3}>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
            >
                <p>Foto:</p>
                <InputImageByte
                    image={muralFoto ? muralFoto.foto : null}
                    handleChangeImage={handleChangeImage}
                    multiple
                />
            </Grid>
            <Grid container direction='row' spacing={3}>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}
                >
                    <Button
                        disabled={isLoadingAction}
                        onClick={() => setMuralFoto(null)}
                    >
                        {isLoadingAction && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Nova Foto
                    </Button>

                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}
                >
                    <Button
                        disabled={isLoadingAction}
                        onClick={handleDeletePhoto}
                    >
                        {isLoadingAction && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Excluir Foto
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MuralFotoForm;