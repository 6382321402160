import { OPEN_SIMPLE_DIALOG, CLOSE_SIMPLE_DIALOG } from "./actionTypes"

export function simpleDialogMessage(state = false, action) {
  switch (action.type) {
    case OPEN_SIMPLE_DIALOG:
      return true

    case CLOSE_SIMPLE_DIALOG:
      return false

    default:
      return false
  }
}
