import {
    ADD_LIVROS,
    UPDATE_LIVROS,
    SET_LIVROS,
    REMOVE_LIVROS,
    SET_LIVRO,
    OPEN_LIVRO,
    CLOSE_LIVRO
} from './actionTypes'

export function addLivros(livro) {
    return { type: ADD_LIVROS, livro }
}

export function updateLivros(livro) {
    return { type: UPDATE_LIVROS, livro }
}

export function setLivros(livros) {
    return { type: SET_LIVROS, livros }
}

export function removeLivros(index) {
    return { type: REMOVE_LIVROS, index }
}

export function setLivro(livro) {
    return { type: SET_LIVRO, livro }
}


export function openLivro() {
    return { type: OPEN_LIVRO }
}

export function closeLivro() {
    return { type: CLOSE_LIVRO }
}