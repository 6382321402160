import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { noteRound } from "../../../../util/DoubleUtil";
import { rltAtaFinalTurmaWeb } from '../../api-relatorio';
import { findByTurma } from '../../../AlunoTurma/api-aluno-turma';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Switch } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    paper: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    showRI: {

    },
    hideRI: {
        display: 'none',
    },
    notaVermelha: {
        color: 'red'
    }
});

export default function RelatorioAtaFinalTurmaWeb(props) {
    const [listaAFT, setListaAFT] = useState([]);
    const [listaAT, setListaAT] = useState([]);
    const codTurma = props.location.state.codTurma;
    const [showRI, setShowRI] = useState(true);
    const classes = useStyles();
    const progress = useRef(0);


    useEffect(() => {
        findByTurma(codTurma).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setListaAT(data);
            }
        });
    }, [codTurma]);

    useEffect(() => {

        async function asyncAFTReport() {
            while (progress.current < listaAT.length) {
                await rltAtaFinalTurmaWeb(listaAT[progress.current].codigo).then((data) => {
                    if (data.error) {
                        console.log(data);
                    } else {
                        console.log(data);
                        setListaAFT((prevState) => [
                            ...prevState,
                            data
                        ]);
                    }
                    progress.current = progress.current + 1;
                })
            }
        }
        asyncAFTReport();

        // eslint-disable-next-line
    }, [listaAT])

    const handleChangeShowRI = () => {
        setShowRI((prevState) => (
            !prevState
        ));
    }

    return (
        <Container maxWidth={false} className={classes.paper} component={Paper} >
            <Typography
                style={{
                    textAlign: 'center',
                    textDecoration: 'underline',
                    marginBottom: '40px'
                }}
                variant="h6">
                Ata Final Turma
            </Typography>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2}>RM</TableCell>
                        <TableCell rowSpan={2}>N. Chamada</TableCell>
                        <TableCell rowSpan={2}>Nome</TableCell>
                        {listaAFT.length > 0 ?
                            <>
                                {listaAFT[0].listaAF.map((af) => (
                                    <>
                                        <TableCell
                                            colSpan={showRI ? 2 : 0}
                                        >
                                            {af.tdp.disciplina.descricao}
                                        </TableCell>
                                    </>


                                ))}
                            </>
                            : ''}
                    </TableRow>
                    <TableRow>
                        {listaAFT.length > 0 ?
                            <>
                                {listaAFT[0].listaAF.map(() => (
                                    <>
                                        <TableCell>5º C.</TableCell>
                                        <TableCell className={showRI ? classes.showRI : classes.hideRI}>R. I.</TableCell>
                                    </>
                                ))}
                            </>
                            : ''}

                    </TableRow>
                </TableHead>

                <TableBody>
                    {listaAFT.map((aft, index) => (
                        <>
                            <TableRow key={index}>
                                <TableCell>{aft.alunoTurma.aluno.codAlunoEscola}</TableCell>
                                <TableCell>{aft.alunoTurma.numeroChamada}</TableCell>
                                <TableCell>{aft.alunoTurma.aluno.nome}</TableCell>
                                {aft.listaAF.map((af) => (
                                    <>
                                        <TableCell
                                            className={
                                                af.quintoConteito && af.quintoConceito.nota && af.quintoConceito.nota < 6 ?
                                                    classes.notaVermelha :
                                                    ''
                                            }
                                        >
                                            {
                                                af.quintoConceito && af.quintoConceito.nota ?
                                                    noteRound(af.quintoConceito.nota) :
                                                    '--'
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={showRI ? classes.showRI : classes.hideRI}
                                        >
                                            <div
                                                className={
                                                    af.recuperacaoIntensiva && af.recuperacaoIntensiva.nota && af.recuperacaoIntensiva.nota < 6 ?
                                                        classes.notaVermelha :
                                                        ''}
                                            >
                                                {
                                                    af.recuperacaoIntensiva && af.recuperacaoIntensiva.nota ?
                                                        noteRound(af.recuperacaoIntensiva.nota)
                                                        :
                                                        '--'
                                                }
                                            </div>
                                        </TableCell>
                                    </>
                                ))}


                            </TableRow>



                        </>
                    ))}
                </TableBody>
            </Table>

            <FormControl
                style={{
                    margin: '30px'
                }}
                component="fieldset">
                <FormLabel component="legend">Opções</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={showRI} onChange={handleChangeShowRI} name="gilad" />}
                        label="Mostrar R.I."
                    />
                </FormGroup>
                <FormHelperText>R.I. -> Recuperação Intensiva</FormHelperText>
            </FormControl>

            <LinearProgressWithLabel value={(progress.current + 1) * 100 / listaAT.length} />

        </Container>
    );
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};