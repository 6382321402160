import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth
} from '../../util/FetchUtil';

const controller = 'nota-bimestre-tipo/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

export {
  findAll
}