import React, { useState } from 'react';
import DiaNaoLetivoMenu from './DiaNaoLetivoMenu';
import DiaNaoLetivoContainer from './DiaNaoLetivo/DiaNaoLetivoContainer';
import { Grid } from '@material-ui/core';
import DiaNaoLetivoSerieContainer from './DiaNaoLetivoSerie/DiaNaoLetivoSerieContainer';

export const diaNaoLetivoKey = 'diaNaoLetivo';
export const diaNaoLetivoSerieKey = 'diaNaoLetivoSerie';

const DiaNaoLetivoContainerMenu = () => {
    const [menu, setMenu] = useState(diaNaoLetivoKey)

    return (
        <Grid
            justifyContent='center'
            container
            spacing={3}
        >
            <Grid
                item
                xs={8}
            >
                <DiaNaoLetivoMenu
                    menu={menu}
                    setMenu={setMenu}
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                {menu === diaNaoLetivoKey &&
                    <DiaNaoLetivoContainer />
                }
                {menu === diaNaoLetivoSerieKey &&
                    <DiaNaoLetivoSerieContainer />
                }
            </Grid>
        </Grid>
    )
}

export default DiaNaoLetivoContainerMenu;