import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { findByProfessores } from "../TDP/api-tdp";
import MenuItem from '@material-ui/core/MenuItem';
import { findByUsuarioCodigo, findByCurrentUser, sendEmailPresencaAtradas } from "./api-acompanhamento";
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dateFormat } from '../../util/DateUtil';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontSize: 13,
        fontWeight: '700',
        textAlign: 'center',
    },
    row: {
        textAlign: 'center',
    }
}));

export default function AcompanhamentoContainer() {
    const classes = useStyles();
    const [acompanhamentos, setAcompanhamnetos] = useState([]);
    const usuario = useSelector(state => state.usuario);
    const [professores, setProfessores] = useState([]);
    const [codProfessorSelected, setCodProfessorSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (usuario && (usuario.perfil === 'ADMIN' || usuario.perfil === 'COORDENADOR')) {
            findByProfessores().then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setProfessores(data);
                }
            });
        } else if (usuario && usuario.perfil === 'PROFESSOR') {
            setIsLoading(true);
            findByCurrentUser().then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setAcompanhamnetos(data);
                    setIsLoading(false);
                }
            })
        }
    }, [usuario]);

    useEffect(() => {
        if (codProfessorSelected) {
            setIsLoading(true);
            findByUsuarioCodigo(codProfessorSelected).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setAcompanhamnetos(data);
                    setIsLoading(false);
                }
            })
        }

    }, [codProfessorSelected]);

    const handleProfessorSelected = (event) => {
        setCodProfessorSelected(event.target.value);
    };

    const handleEnviarEmailPresencaAtrasadas = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja enviar os e-mails de notificação?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        sendEmailPresencaAtradas().then((data) => {
                            if (data.error) {
                                console.log(data.error);
                            }
                        });
                        toast.success("E-mails de Notificação estão sendo enviados.", {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} maxWidth={'xl'} component={Paper}>

                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <Typography className={classes.title} variant="h6">
                            Acompanhamento
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleEnviarEmailPresencaAtrasadas}
                        >
                            Enviar E-mail Presenças Atrasadas
                        </Button>
                    </Grid>
                </Grid>

                {usuario && (usuario.perfil === 'ADMIN' || usuario.perfil === 'COORDENADOR') ?
                    <FormControl
                        className={classes.formControl}
                    >
                        <InputLabel
                            id="demo-simple-select-label">
                            Professor(a)
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={codProfessorSelected ? codProfessorSelected : ''}
                            onChange={handleProfessorSelected}
                        >
                            {professores.map((professor) => (
                                <MenuItem
                                    key={professor.id}
                                    value={professor.id}
                                >
                                    {professor.nome}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl> : ''
                }

                {codProfessorSelected || (usuario && usuario.perfil === 'PROFESSOR') ?

                    <>
                        {isLoading ?
                            <div className={classes.loading}>
                                <CircularProgress color="primary" />
                                Carregando...
                            </div>
                            :
                            <div style={{ paddingTop: '32px' }}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell rowSpan={2} className={classes.rowHeader}>Turma - Disciplina</TableCell>
                                            <TableCell rowSpan={2} className={classes.rowHeader}>Data Últ. Presença</TableCell>
                                            <TableCell rowSpan={2} className={classes.rowHeader}>Data Últ. Atividade</TableCell>
                                            <TableCell style={{
                                                borderBottom: 0,
                                                borderRight: 'solid black',
                                                borderLeft: 'solid black',
                                            }} colSpan={4} className={classes.rowHeader}>Quantidade Presenças - (Quant. Aulas/Quant. Aulas Registradas)</TableCell>
                                            <TableCell style={{
                                                borderBottom: 0,
                                                borderRight: 'solid black',
                                                borderLeft: 'solid black',
                                            }} colSpan={4} className={classes.rowHeader}>Quantidade Atividades</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{
                                                borderBottom: 0,
                                                borderLeft: 'solid black',
                                            }} className={classes.rowHeader}>1º Bimestre</TableCell>
                                            <TableCell className={classes.rowHeader}>2º Bimestre</TableCell>
                                            <TableCell className={classes.rowHeader}>3º Bimestre</TableCell>
                                            <TableCell style={{
                                                borderBottom: 0,
                                                borderRight: 'solid black',
                                            }} className={classes.rowHeader}>4º Bimestre</TableCell>
                                            <TableCell style={{
                                                borderBottom: 0,
                                                borderLeft: 'solid black',
                                            }} className={classes.rowHeader}>1º Bimestre</TableCell>
                                            <TableCell className={classes.rowHeader}>2º Bimestre</TableCell>
                                            <TableCell className={classes.rowHeader}>3º Bimestre</TableCell>
                                            <TableCell style={{
                                                borderBottom: 0,
                                                borderRight: 'solid black',
                                            }} className={classes.rowHeader}>4º Bimestre</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {acompanhamentos.map((acompanhamento, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.row}>{acompanhamento.tdp.turma.descricao} - {acompanhamento.tdp.disciplina.descricao}</TableCell>
                                                <TableCell className={classes.row}>{acompanhamento.dataUltPresenca ? dateFormat(acompanhamento.dataUltPresenca) : '--'}</TableCell>
                                                <TableCell className={classes.row}>{acompanhamento.dataUltAtividade ? dateFormat(acompanhamento.dataUltAtividade) : '--'}</TableCell>
                                                <TableCell className={classes.row}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item>
                                                            {acompanhamento.quantAula1}/{acompanhamento.quantPresenca1}
                                                        </Grid>
                                                        <Grid item>
                                                            <Link
                                                                to={{
                                                                    pathname: "conferir-cronograma-presenca",
                                                                    state: {
                                                                        tdp: acompanhamento.tdp,
                                                                        numeroBimestre: 1
                                                                    }
                                                                }}
                                                            >
                                                                Conferir
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classes.row}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item>
                                                            {acompanhamento.quantAula2}/{acompanhamento.quantPresenca2}
                                                        </Grid>
                                                        <Grid item>
                                                            <Link
                                                                to={{
                                                                    pathname: "conferir-cronograma-presenca",
                                                                    state: {
                                                                        tdp: acompanhamento.tdp,
                                                                        numeroBimestre: 2
                                                                    }
                                                                }}
                                                            >
                                                                Conferir
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classes.row}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item>
                                                            {acompanhamento.quantAula3}/{acompanhamento.quantPresenca3}
                                                        </Grid>
                                                        <Grid item>
                                                            <Link
                                                                to={{
                                                                    pathname: "conferir-cronograma-presenca",
                                                                    state: {
                                                                        tdp: acompanhamento.tdp,
                                                                        numeroBimestre: 3
                                                                    }
                                                                }}
                                                            >
                                                                Conferir
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classes.row}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item>
                                                            {acompanhamento.quantAula4}/{acompanhamento.quantPresenca4}
                                                        </Grid>
                                                        <Grid item>
                                                            <Link
                                                                to={{
                                                                    pathname: "conferir-cronograma-presenca",
                                                                    state: {
                                                                        tdp: acompanhamento.tdp,
                                                                        numeroBimestre: 4
                                                                    }
                                                                }}
                                                            >
                                                                Conferir
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classes.row}>{acompanhamento.quantAtividade1}</TableCell>
                                                <TableCell className={classes.row}>{acompanhamento.quantAtividade2}</TableCell>
                                                <TableCell className={classes.row}>{acompanhamento.quantAtividade3}</TableCell>
                                                <TableCell className={classes.row}>{acompanhamento.quantAtividade4}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                        }
                    </>
                    : ''
                }


            </Container>
        </div>
    );
}