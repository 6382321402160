import { makeStyles } from '@material-ui/core';
import React from 'react';
import ContainerWithGoBack from '../../UI/ContainerWithGoBack';
import MuralFotoContainer from './MuralFotoContainer';

const useStyles = makeStyles({
    container: {
        padding: '20px',
        backgroundColor: '#fff'
    }
})

const MuralFotoUsuarioContainer = (props) => {
    const classes = useStyles();
    const mural = props.location.state.mural;

    return (
        <ContainerWithGoBack
            className={classes.container}
        >
            <MuralFotoContainer
                mural={mural}
            />
        </ContainerWithGoBack>
    );
}

export default MuralFotoUsuarioContainer;