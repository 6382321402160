import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Container from '../../UI/Container';
import HistoricoLancamentoForm from './HistoricoLancamentoForm';
import HistoricoLancamentoTable from './HistoricoLancamentoTable';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
});

const HistoricoLancamentoContainer = () => {
    const historicoLancamento = useSelector(state => state.historicoLancamento);
    const classes = useStyles();
    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Histórico Lançamento
            </Typography>

            {!historicoLancamento && <HistoricoLancamentoTable />}
            {historicoLancamento && <HistoricoLancamentoForm />}

        </Container>
    );
}

export default HistoricoLancamentoContainer;