import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { findByCodTurmaAndCodCronogramaData } from './api-cronograma';
import { timeFormat, dayOfWeekName } from '../../util/DateUtil';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';
import CloseIcon from '@material-ui/icons/Close';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import Button from '../UI/Button';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
});


export default function CronogramaTableSelect({
    cronogramaData,
    setOpenCronogramaTableSelect,
    listCronogramaSelected,
    setListCronogramaSelected, 
    handleAdicionarCronogramasSelect 
}) {
    const classes = useStyles();
    const [cronogramas, setCronogramas] = useState([]);
    const turma = useSelector(state => state.turma);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (turma) {
            setIsLoading(true);
            findByCodTurmaAndCodCronogramaData(turma.codigo, cronogramaData.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setCronogramas(data)
                    }
                    setIsLoading(false);
                }
            });
        }
    }, [turma, cronogramaData]);


    const handleChange = (event, cronograma) => {
        if (event.target.checked) {
            setListCronogramaSelected((prevState) => ([
                ...prevState,
                cronograma
            ]));
        } else {
            setListCronogramaSelected((prevState) => prevState.filter((ps) => ps.codigo !== cronograma.codigo));
        }
    }

    const handleMarcarTodos = () => {
        setListCronogramaSelected(cronogramas);
    }

    const handleDescarmarTodos = () => {
        setListCronogramaSelected([]);
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <CloseIcon
                    fontSize='small'
                    onClick={() => setOpenCronogramaTableSelect(false)} className={classes.closeIcon} />
            </Grid>
            <Grid item>
                <Typography variant='body1'>
                    Selecione os Cronogramas para Copiar
                </Typography>
            </Grid>


            {isLoading ?
                <Grid item>
                    <Loading description="Carregando Cronograma..." />
                </Grid>
                :
                <>
                    <Grid item>
                        <Grid container direction='row' spacing={3}>
                            <Grid item>
                                <Button onClick={handleMarcarTodos}>Marcar Todos</Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleDescarmarTodos}>Descarmar Todos</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Dia Semana</TableCell>
                                    <TableCell>Ordem Aula</TableCell>
                                    <TableCell>Horário</TableCell>
                                    <TableCell>Disciplina</TableCell>
                                    <TableCell>Professor</TableCell>
                                    <TableCell>Local</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cronogramas.map((c) => (
                                    <TableRow key={c.codigo}>
                                        <TableCell>
                                            <Checkbox
                                                checked={listCronogramaSelected.some((dnl) => dnl.codigo === c.codigo)}
                                                onChange={(event) => handleChange(event, c)}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell>{dayOfWeekName(c.diaSemana)}</TableCell>
                                        <TableCell>{c.cronogramaAula.ordemAula}</TableCell>
                                        <TableCell>{timeFormat(c.cronogramaAula.horarioInicio)} - {timeFormat(c.cronogramaAula.horarioFim)}</TableCell>
                                        <TableCell>{c.tdp.disciplina.descricao}</TableCell>
                                        <TableCell>{c.tdp.usuario ? c.tdp.usuario.nome : '--'}</TableCell>
                                        <TableCell>{c.local ? c.local.descricao : '--'}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleAdicionarCronogramasSelect}>Adicionar</Button>
                    </Grid>
                </>
            }



        </Grid>
    );
}