import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Paper, makeStyles, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../UI/Button';
import { dateFormat } from '../../../util/DateUtil';
import Loading from '../../UI/Loading';
import { findByUnregistered } from './api-dia-letivo-extra-serie';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    container: {
        padding: '10px',
    },
});

const DiaLetivoExtraSerieTableAdding = ({
    handleSave,
    serie,
    isLoadingAction,
    setIsAdding
}) => {
    const classes = useStyles();
    const [listDiaLetivoExtra, setListDiaLetivoExtra] = useState([]);
    const [listDiaLetivoExtraSelected, setListDiaLetivoExtraSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (serie && serie.codSerie) {
            setIsLoading(true);
            findByUnregistered(serie.codSerie).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListDiaLetivoExtra(data);
                }
                setIsLoading(false);
            })
        }

    }, [serie]);

    const handleChange = (event, diaNaoLetivo) => {
        if (event.target.checked) {
            setListDiaLetivoExtraSelected((prevState) => ([
                ...prevState,
                diaNaoLetivo
            ]));
        } else {
            setListDiaLetivoExtraSelected((prevState) => prevState.filter((ps) => ps.codigo !== diaNaoLetivo.codigo));
        }
    }

    const selectAll = () => {
        setListDiaLetivoExtraSelected(listDiaLetivoExtra);
    }

    const deselectAll = () => {
        setListDiaLetivoExtraSelected([]);
    }

    return (
        <Grid
            container
            component={Paper}
            className={classes.container}
            spacing={3}
        >
            <Grid item >
                <Button
                    onClick={() => setIsAdding(false)}
                >
                    Voltar
                </Button>
            </Grid>
            {listDiaLetivoExtra.length > 0 &&
                <>
                    <Grid item >
                        <Button
                            disabled={isLoadingAction}
                            onClick={() => handleSave(listDiaLetivoExtraSelected)}
                        >
                            Adicionar
                        </Button>
                    </Grid>

                    <Grid item >
                        <Button
                            disabled={isLoadingAction}
                            onClick={selectAll}
                        >
                            Marcar Todos
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button
                            disabled={isLoadingAction}
                            onClick={deselectAll}
                        >
                            Desmarcar Todos
                        </Button>
                    </Grid>
                </>
            }

            {isLoading ?
                <Grid
                    container
                    justifyContent='center'
                >
                    <Loading description="Carregando Dias Letivo Extra" />
                </Grid>
                :
                <>
                    {listDiaLetivoExtra.length > 0 ?
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ações</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Descrição</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listDiaLetivoExtra.map((diaLetivoExtra) => (
                                    <TableRow key={diaLetivoExtra.codigo}>
                                        <TableCell>
                                            <Checkbox
                                                checked={listDiaLetivoExtraSelected.some((dnl) => dnl.codigo === diaLetivoExtra.codigo)}
                                                onChange={(event) => handleChange(event, diaLetivoExtra)}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell>{dateFormat(diaLetivoExtra.data)}</TableCell>
                                        <TableCell>{diaLetivoExtra.descricao}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        <p>Todas as datas já foram cadastradas.</p>
                    }

                </>
            }

        </Grid>
    )
}

export default DiaLetivoExtraSerieTableAdding;