import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TurmaTableSelected from '../Turma/TurmaTableSelect';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TurmaProfile from '../Turma/TurmaProfile';
import { findByTurma, save } from './api-tdp';
import 'react-toastify/dist/ReactToastify.css';
import DisciplinaTurmaTableSelect from './DisciplinaTurmaTableSelect';
import LivroForm from './LivroForm';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }

});

export default function DisciplinaTurmaForm() {
    const classes = useStyles();
    const [turma, setTurma] = useState(null);
    const [tdp, setTdp] = useState(null);
    const [tdps, setTdps] = useState([]);

    useEffect(() => {
        if (turma) {
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setTdps(data);
                }
            }).catch(err => {
                console.log(err);
            });
        }

    }, [turma]);

    const onTurmaSelected = (turma) => {
        setTurma(turma);
    }

    const onTdpSelected = (tdp) => {
        setTdp(tdp);
    }

    const saveLivro = (event) => {
        event.preventDefault();
        save(tdp).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setTdps(
                    tdps.map((tdp) => {
                        if (tdp.codigo === data.codigo) {
                            return data;
                        } else {
                            return tdp;
                        }
                    })
                )
                setTdp(null);

            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <div>
            <ToastContainer autoClose={3000} />
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Livro Disciplina Turma
                </Typography>

                {turma ?
                    <>
                        <TurmaProfile turma={turma} onTurmaSelected={onTurmaSelected} />
                        <DisciplinaTurmaTableSelect tdps={tdps} onTdpSelected={onTdpSelected} />
                        {tdp ? <LivroForm tdp={tdp} setTdp={setTdp} saveLivro={saveLivro} /> : ''}

                    </>
                    :
                    <TurmaTableSelected onTurmaSelected={onTurmaSelected} />
                }

            </Container>

        </div>
    );
}