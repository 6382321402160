import { SERVER_URL } from '../../constants';

import {
  fetchGetAuth, fetchPostAuth, fetchRemoveAuth,
} from '../../util/FetchUtil';

const controller = 'lancamento/';

const findByAlunoCodigo = (codAluno) => {
  const url = SERVER_URL + controller + 'query?codAluno=' + codAluno;
  return fetchGetAuth(url);
}

const save = (lancamento) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, lancamento);
}

const remove = (codLancamento) => {
  const url = SERVER_URL + controller + codLancamento;
  return fetchRemoveAuth(url);
}

const findByAlunoCodigoAndPendente = (codAluno) => {
  const url = SERVER_URL + controller + 'query?codAluno=' + codAluno + '&type=pendente';
  return fetchGetAuth(url);
}

const findByAlunoCodigoAndAnoLetivo = (codAluno, anoLetivo) => {
  const url = SERVER_URL + controller + 'query?codAluno=' + codAluno + '&ano-letivo=' + anoLetivo;
  return fetchGetAuth(url);
}

const findByAlunoCodigoAndAVencer = (codAluno) => {
  const url = SERVER_URL + controller + 'query?codAluno=' + codAluno + '&type=a-vencer';
  return fetchGetAuth(url);
}

const findYearsByCodAluno = (codAluno) => {
  const url = SERVER_URL + controller + 'list-year/' + codAluno;
  return fetchGetAuth(url);
}

export {
  findByAlunoCodigo,
  save,
  remove,
  findByAlunoCodigoAndPendente,
  findByAlunoCodigoAndAnoLetivo,
  findByAlunoCodigoAndAVencer,
  findYearsByCodAluno
}