import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import { save as saveRespostaCapitulo, findByConteudoCodigoAndAlunoCodigo as findByConteudoCodigoAndAlunoCodigoCapitulo } from "../Resposta/api-resposta";
import {
  findByConteudoCodigoAndAvaliacaoAlunoCodigo as findByConteudoCodigoAndAvaliacaoAlunoCodigoSecao,
  save as saveRespostaAvaliacao,
  saveCorrection as saveCorrectionInSecao
} from '../../../Avaliacao/Resposta/api-avaliacao-resposta';
import { toast } from 'react-toastify';
import { save as saveConteudo } from "../api-conteudo";
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { PROFESSOR, ALUNO, RESPONSAVEL } from '../../../Usuario/Perfil'
import { useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { errorMessage } from "../../../../util/ConfirmAlertUtil";

const useStyles = makeStyles(theme => ({
  alternativeButton: {
    margin: theme.spacing(3, 0, 3),
    cursor: 'pointer'
  },
  alternativeButtonSelected: {
    margin: theme.spacing(3, 0, 3),
    cursor: 'pointer',
    backgroundColor: 'cornflowerblue'
  },
  alternativeBox: {
    display: 'inline-grid',
  },
  boxExerAlternative: {
    padding: '20px',
    borderColor: 'burlywood',
    borderStyle: 'solid',
    borderWidth: 'thick',
    borderRadius: '20px',
    margin: '20px',
    userSelect: 'none',
  },
  title: {
    fontSize: 'x-large',
    fontWeight: 'bold',
    fontFamily: 'serif',
    color: 'black',
  },
  alternativeLetter: {
    marginRight: '5px'
  },
  buttonPorcentageSelected: {
    backgroundColor: 'cornflowerblue'
  },
  cursorLink: {
    cursor: 'pointer'
  }
}));

export default props => {
  const classes = useStyles();
  const [resposta, setResposta] = useState({
    conteudo: props.conteudo
  });
  const avaliacaoAluno = useSelector(state => state.avaliacaoAluno);
  const usuario = useSelector(state => state.usuario);
  const [anchorEl, setAnchorEl] = useState(null);
  const [conteudo, setConteudo] = useState(props.conteudo)

  useEffect(() => {
    if (avaliacaoAluno) {
      setResposta((prevState) => ({
        ...prevState,
        avaliacaoAluno: avaliacaoAluno
      }));
    }

  }, [avaliacaoAluno]);

  useEffect(() => {
    if (conteudo.secao && avaliacaoAluno && avaliacaoAluno.codigo && usuario) {
      findByConteudoCodigoAndAvaliacaoAlunoCodigoSecao(conteudo.codigo, avaliacaoAluno.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setResposta(data);
          }
        }
      })
    }

  }, [conteudo.codigo, conteudo.secao, avaliacaoAluno, usuario]);

  useEffect(() => {
    if (conteudo.capitulo && usuario) {
      findByConteudoCodigoAndAlunoCodigoCapitulo(conteudo.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data);
          } else {
            setResposta(data);
          }
        }
      })
    }
  }, [conteudo.capitulo, conteudo.codigo, usuario])

  const handleFecharQuestao = () => {
    let conteudoSave = {
      ...conteudo,
      questao: {
        ...conteudo.questao,
        finalizado: !conteudo.questao.finalizado
      }
    }
    saveConteudo(conteudoSave).then((data) => {
      console.log(data);
      if (data.error) {
        console.log(data);
      } else {
        setConteudo(data);
      }
    })
  }

  const handleDevolutivaChange = (event, respostaSave) => {
    setResposta({
      ...respostaSave,
      devolutiva: event.target.value
    })
  }

  const handlePontosChange = (event, respostaSave) => {
    setResposta({
      ...respostaSave,
      pontos: event.target.value
    })
  }

  const handleClickAnswer = (answer) => {
    let saveAnswer = {
      ...resposta,
      resposta: answer
    }
    if (conteudo.secao) {
      saveRespostaAvaliacao(saveAnswer).then((data) => {
        if (data.error) {
          if (data.message === 'Not a student') {
            toast.error("Não pode responder por não ter perfil estudante.", {
              position: toast.POSITION.BOTTOM_LEFT
            });
          } else if (data.message === 'Test already finalized') {
            toast.error("Avaliação já está finalizada.", {
              position: toast.POSITION.BOTTOM_LEFT
            });
          } else if (data.message === 'Datetime end reached') {
            toast.error("Data hora fim da avaliação alcançada. Prova Finalizada.", {
              position: toast.POSITION.BOTTOM_LEFT
            });
          }
          else {
            console.log(data);
          }
        } else {
          toast.success("Resposta salva com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });
          setResposta(data);
        }
      })
    } else if (conteudo.capitulo) {
      if (!conteudo.questao.finalizado) {
        saveRespostaCapitulo(saveAnswer).then((data) => {
          console.log(data);
          if (data.error) {
            if (data.message === 'Not a student') {
              toast.error("Não pode responder por não ter perfil estudante.", {
                position: toast.POSITION.BOTTOM_LEFT
              });
            } else {
              console.log(data);
            }
          } else {
            toast.success("Resposta salva com sucesso.", {
              position: toast.POSITION.BOTTOM_LEFT
            });
            setResposta(data);
          }
        })
      }
    }
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSavePorcentage = (resposta, porcentage) => {
    let respostaSave = {
      ...resposta,
      porcentagemAcerto: porcentage
    }
    saveCorrectionInSecao(respostaSave).then((data) => {
      if (data.error) {
        console.log(data);
      } else {
        setResposta(data);
      }
    });

  }

  return (
    <>
      <div className={classes.boxExerAlternative}>


        <Grid container justifyContent="space-between">
          <p className={classes.title}>Questão</p>

          {usuario && usuario.perfil === PROFESSOR && conteudo.capitulo ?
            <Grid style={{ flexBasis: '5%' }} item xs={3}>
              <MenuIcon
                fontSize='large'
                className={classes.cursorLink}
                onClick={(event) => handleMenuClick(event)}
                color="secondary"
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Link}
                  to={{
                    pathname: '/correcao',
                    state: {
                      conteudo: conteudo,
                    }
                  }}
                >
                  Corrigir Respostas
                </MenuItem>
              </Menu>
            </Grid>

            :
            ''
          }
        </Grid>
        <Grid item xs={10}>
          {parse(conteudo.questao.descricao)}
          <img
            alt=''
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '20px',
              width: conteudo.questao.largura ? conteudo.questao.largura : 300
            }}
            src={conteudo.questao.imagemUrl}
          />
        </Grid>

        <div className={classes.alternativeBox}>
          <div>
            <span className={classes.alternativeLetter}>A)</span>
            <button
              onClick={() => handleClickAnswer('Alternativa A')}
              className={resposta && resposta.resposta === 'Alternativa A' ? classes.alternativeButtonSelected : classes.alternativeButton}
            >

              {parse(conteudo.questao.alternativaA)}
            </button>
          </div>
          <div>
            <span className={classes.alternativeLetter}>B)</span>

            <button
              onClick={() => handleClickAnswer('Alternativa B')}
              className={resposta && resposta.resposta === 'Alternativa B' ? classes.alternativeButtonSelected : classes.alternativeButton}
            >
              {parse(conteudo.questao.alternativaB)}
            </button>
          </div>
          <div>
            <span className={classes.alternativeLetter}>C)</span>

            <button
              onClick={() => handleClickAnswer('Alternativa C')}
              className={resposta && resposta.resposta === 'Alternativa C' ? classes.alternativeButtonSelected : classes.alternativeButton}
            >
              {parse(conteudo.questao.alternativaC)}
            </button>
          </div>
          <div>
            <span className={classes.alternativeLetter}>D)</span>
            <button
              onClick={() => handleClickAnswer('Alternativa D')}
              className={resposta && resposta.resposta === 'Alternativa D' ? classes.alternativeButtonSelected : classes.alternativeButton}
            >
              {parse(conteudo.questao.alternativaD)}
            </button>
          </div>
          <div>
            <span className={classes.alternativeLetter}>E)</span>
            <button
              onClick={() => handleClickAnswer('Alternativa E')}
              className={resposta && resposta.resposta === 'Alternativa E' ? classes.alternativeButtonSelected : classes.alternativeButton}
            >
              {parse(conteudo.questao.alternativaE)}
            </button>
          </div>
        </div>


        {usuario && (usuario.perfil === ALUNO || usuario.perfil === RESPONSAVEL) ?
          <>
            {conteudo.capitulo ?
              <p style={{ fontSize: '20px' }}>
                Porcentagem de Acerto:
                {
                  resposta && resposta.porcentagemAcerto && resposta.porcentagemAcerto !== null ?
                    resposta.porcentagemAcerto + '%' :
                    'Questão ainda não corrigida'
                }
              </p>
              : ''}
            {resposta && resposta.devolutiva ? <p style={{ fontSize: '20px' }}>Devolutiva: {resposta.devolutiva}</p> : ''}
            {conteudo.questao.finalizado ||
              (conteudo.secao && conteudo.secao.avaliacao.esimulado && resposta.avaliacaoAluno && resposta.avaliacaoAluno.provaFinalizada) ||
              (conteudo.secao && conteudo.secao.avaliacao.mostrarRespostas) ?
              <div style={{ fontSize: '20px' }}>
                <p>Resposta Questão:</p>
                {parse(conteudo.questao.resposta)}
                <p>Resposta Aluno:</p>
                {parse(resposta && resposta.resposta ? resposta.resposta : '')}
              </div> : ''
            }
          </>
          :
          <div style={{ fontSize: '20px' }}>
            {conteudo.questao.resposta ?
              <>
                <p>Resposta Questão:</p>
                {parse(conteudo.questao.resposta)}
              </> : ''}
            <p>Resposta Aluno:</p>
            {parse(resposta && resposta.resposta ? resposta.resposta : '')}
          </div>
        }
        {usuario && usuario.perfil === PROFESSOR && conteudo.capitulo ?
          <Button
            variant="contained"
            color="secondary"
            className={classes.sendAnswer}
            onClick={handleFecharQuestao}
          >
            {conteudo.questao.finalizado ? 'Abrir Questão' : 'Fechar Questão'}
          </Button>
          : ''
        }

        {usuario && usuario.perfil === PROFESSOR && conteudo.secao ?
          <>
            <p style={{ marginTop: '20px' }}>Correção:</p>
            <Button
              onClick={() => handleSavePorcentage(resposta, 0)}
              variant="outlined"
              color="primary"
              className={resposta.porcentagemAcerto === 0 ? classes.buttonPorcentageSelected : ''}
            >
              0%
            </Button>
            <Button
              onClick={() => handleSavePorcentage(resposta, 25)}
              variant="outlined"
              color="primary"
              className={resposta.porcentagemAcerto === 25 ? classes.buttonPorcentageSelected : ''}
            >
              25%
            </Button>
            <Button
              onClick={() => handleSavePorcentage(resposta, 50)}
              variant="outlined"
              color="primary"
              className={resposta.porcentagemAcerto === 50 ? classes.buttonPorcentageSelected : ''}
            >
              50%
            </Button>
            <Button
              onClick={() => handleSavePorcentage(resposta, 75)}
              variant="outlined"
              color="primary"
              className={resposta.porcentagemAcerto === 75 ? classes.buttonPorcentageSelected : ''}
            >
              75%
            </Button>
            <Button
              onClick={() => handleSavePorcentage(resposta, 100)}
              variant="outlined"
              color="primary"
              className={resposta.porcentagemAcerto === 100 ? classes.buttonPorcentageSelected : ''}
            >
              100%
            </Button>
            <>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>Pontos:</p>
              <TextField
                variant="outlined"
                margin="normal"
                style={{ marginTop: '5px' }}
                type="number"
                name="pontos"
                onChange={(event) => handlePontosChange(event, resposta)}
                value={resposta && resposta.pontos !== null ? resposta.pontos : ''}
              />
            </>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Devolutiva"
              name="devolutiva"
              onChange={(event) => handleDevolutivaChange(event, resposta)}
              value={resposta && resposta.devolutiva ? resposta.devolutiva : ''}
            />
            <Button
              variant="contained"
              onClick={() => saveCorrectionInSecao(resposta)}
              color="primary"
              className={classes.sendAnswer}>
              Enviar
            </Button>
          </> : ''}

        {conteudo.capitulo && conteudo.questao.finalizado ? <p style={{
          color: 'blueviolet',
          fontStyle: 'italic'
        }}>Questão fechada</p> : ''}
      </div>
    </>
  );
}


