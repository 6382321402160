import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MuralFotoForm from './MuralFotoForm';
import MuralFotoList from './MuralFotoList';
import { findByMuralCodigo } from './api-mural-foto';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { useSelector } from 'react-redux';
import { ADMIN, COORDENADOR, PROFESSOR } from '../../Usuario/Perfil';

const useStyles = makeStyles({
    divMuralFotoForm: {
        borderColor: 'black',
        borderWidth: 'medium',
        borderStyle: 'solid',
        padding: '20px',
        margin: '20px',
    }
})

const MuralFotoContainer = ({ mural }) => {
    const classes = useStyles();
    const [muralFoto, setMuralFoto] = useState(null);
    const [muralFotos, setMuralFotos] = useState({
        content: [],
        size: 0,
        number: -1,
        totalPages: 0
    });
    const usuario = useSelector(state => state.usuario);

    useEffect(() => {
        findByMuralCodigo(mural.codigo, muralFotos.number + 1).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                if (!data.empty) {
                    setMuralFotos(prevState => ({
                        ...data,
                        content: [
                            ...prevState.content,
                            ...data.content
                        ]
                    }));
                }

            }
        })


    }, [mural.codigo, muralFotos.number]);

    return (
        <Grid
            container
        >
            {usuario.perfil === ADMIN ||
                usuario.perfil === COORDENADOR ||
                usuario.perfil === PROFESSOR ?
                <div
                    className={classes.divMuralFotoForm}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <MuralFotoForm
                            mural={mural}
                            muralFoto={muralFoto}
                            setMuralFoto={setMuralFoto}
                            setMuralFotos={setMuralFotos}
                        />
                    </Grid>
                </div> : ''
            }


            <MuralFotoList
                setMuralFoto={setMuralFoto}
                muralFotos={muralFotos}

            />
        </Grid>
    )
}

export default MuralFotoContainer;