import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TurmaDisciplinaProfessorProfileInfo from './TurmaDisciplinaProfessorProfileInfo';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '100%',
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function TurmaDisciplinaProfessorProfileWithoutChange({ tdp }) {
    const classes = useStyles();


    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <TurmaDisciplinaProfessorProfileInfo
                    tdp={tdp} />

            </CardContent>
        </Card>
    );
}