import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'comunicado-aluno/';

const findByComunicado = (codComunicado) => {
  const url = SERVER_URL + controller + 'comunicado/' + codComunicado;
  return fetchGetAuth(url);
}

const save = (comunicadoAluno) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, comunicadoAluno);
}

const remove = (codComunicado) => {
  const url = SERVER_URL + controller + codComunicado;
  return fetchRemoveAuth(url);
}

export {
  findByComunicado,
  save,
  remove,
}