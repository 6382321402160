import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { save } from './api-conceito-aluno';
import { findAll } from "../Conceito/api-conceito";
import { setConceitoAluno, updateConceitoAlunos } from './actions/actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function PresencaAlunoForm() {
    const classes = useStyles();
    const conceitoAluno = useSelector(state => state.conceitoAluno);
    const conceitoAlunos = useSelector(state => state.conceitoAlunos);
    const dispatch = useDispatch();
    const [conceitos, setConceitos] = useState([]);

    useEffect(() => {
        findAll().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setConceitos(data);
            }
        })
    }, []);

    const nextCA = () => {
        const index = conceitoAlunos.map(nb => nb.codigo).indexOf(conceitoAluno.codigo);
        const size = conceitoAlunos.length;
        if (index + 1 === size) {
            dispatch(setConceitoAluno(conceitoAlunos[0]));
        } else {
            dispatch(setConceitoAluno(conceitoAlunos[index + 1]));
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        save(conceitoAluno).then((data) => {
            if (data) {
                dispatch(updateConceitoAlunos(data));
            }
        });
        nextCA();
    }

    const handleConceitoChange = (event) => {
        dispatch(setConceitoAluno({
            ...conceitoAluno,
            conceito: conceitos.find((c) => (c.codigo === event.target.value))
        }))
    }


    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper} >

                <Typography className={classes.title} variant="h5">
                    Nome: {conceitoAluno ? conceitoAluno.alunoTurma.aluno.nome : ''}
                </Typography>
                <Typography className={classes.title} variant="h6">
                    Número Chamada: {conceitoAluno ? conceitoAluno.alunoTurma.numeroChamada : ''}
                </Typography>

                <form onSubmit={clickSubmit}>
                    <FormControl fullWidth >
                        <InputLabel id="bimestre-label">Conceito</InputLabel>
                        <Select
                            labelId="conceito-label"
                            id="conceito-select"
                            value={conceitoAluno && conceitoAluno.conceito ? conceitoAluno.conceito.codigo : ''}
                            onChange={handleConceitoChange}
                        >
                            {conceitos.map((c) => (
                                <MenuItem key={c.codigo} value={c.codigo}>{c.descricao}</MenuItem>
                            ))}
                            <MenuItem value={null}>---Retirar Nota---</MenuItem>

                        </Select>
                    </FormControl>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Salvar
                    </Button>
                </form>



            </Container>
        </div>
    );
}