import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-aluno-turma';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { addAlunosTurma, updateAlunosTurma, setAlunoTurma as setAlunoTurmaRedux } from "./actions/actions";
import { confirmAlert } from 'react-confirm-alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AlunoTextFieldSelect from '../Aluno/UI/AlunoTextFieldSelect';
import AlunoProfile from '../Aluno/AlunoProfile';
import { ptBR } from "date-fns/locale";

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function AlunoTurmaForm() {
    const classes = useStyles();
    const alunoTurmaRedux = useSelector(state => state.alunoTurma);
    const [alunoTurma, setAlunoTurma] = useState(useSelector(state => state.alunoTurma));

    const dispatch = useDispatch();
    const [loadingSave, setLoadingSave] = useState(false);

    useEffect(() => {
        setAlunoTurma(alunoTurmaRedux);
    }, [alunoTurmaRedux]);


    const handleChange = (event) => {
        setAlunoTurma({
            ...alunoTurma,
            [event.target.name]: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();

        if (!alunoTurma.dataEntrada) {
            toast.error("Preencha a Data de Entrada.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        const eNovo = alunoTurma.eNovo;
        setLoadingSave(true);
        save(alunoTurma).then((data) => {
            if (data.error) {
                if (data.message === 'Erro: Bimestre nao cadastrado') {
                    handleSaveNoBimestreError();
                } else if (data.message === 'Erro: Aluno Turma já cadastrado') {
                    handleSaveStudentAlredyRegistered();
                }
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addAlunosTurma(data));
                } else {
                    dispatch(updateAlunosTurma(data));
                }
                dispatch(setAlunoTurmaRedux(data));
                setAlunoTurma(data);
            }
            setLoadingSave(false);

        })

    }


    const handleSaveNoBimestreError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'A série para esta turma não possui cadastro de bimestres. Cadastre os bimestres antes de inserir alunos na turma.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleSaveStudentAlredyRegistered = () => {
        confirmAlert({
            title: 'Erro',
            message: 'Aluno já cadastrado na Turma.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }


    const handleDataEntrada = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setAlunoTurma({
            ...alunoTurma,
            dataEntrada: data
        });
    }

    const handleDataSaida = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setAlunoTurma({
            ...alunoTurma,
            dataSaida: data
        });
    }

    const handleSwitchChange = name => event => {
        setAlunoTurma((prevState) => ({
            ...prevState,
            [name]: event.target.checked
        }));
    };

    const setAluno = (aluno) => {
        setAlunoTurma({
            ...alunoTurma,
            aluno: aluno
        });
    }


    return (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <form onSubmit={clickSubmit}>
                {alunoTurma.eNovo ?
                    <AlunoTextFieldSelect
                        aluno={alunoTurma.aluno}
                        setAluno={setAluno}
                    /> :
                    <AlunoProfile aluno={alunoTurma.aluno} />
                }


                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    {!alunoTurma.eNovo ?
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            required
                            label="Número Chamada"
                            name="numeroChamada"
                            onChange={handleChange}
                            value={alunoTurma.numeroChamada || ''}
                        /> :
                        ''}

                    <KeyboardDatePicker
                        required
                        size="small"
                        disableToolbar
                        variant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data Início"
                        value={alunoTurma && alunoTurma.dataEntrada ? alunoTurma.dataEntrada : null}
                        onChange={handleDataEntrada}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    {!alunoTurma.eNovo ?
                        <KeyboardDatePicker
                            disableToolbar
                            size="small"
                            variant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Data Fim"
                            value={alunoTurma && alunoTurma.dataSaida ? alunoTurma.dataSaida : null}
                            onChange={handleDataSaida}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        : ''
                    }
                </div>

                {!alunoTurma.eNovo ?
                    <FormControlLabel
                        style={{ display: 'flex' }}
                        control={
                            <Switch
                                checked={alunoTurma && alunoTurma.ativo ? true : false}
                                onChange={handleSwitchChange('ativo')}
                                value={alunoTurma && alunoTurma.ativo ? true : false}
                            />
                        }
                        label="Ativo"
                    /> : ''}

                <Button

                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loadingSave}
                >
                    {loadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                    Salvar
                </Button>
            </form>
        </MuiPickersUtilsProvider>
    );
}