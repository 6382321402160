import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { setEvento, updateEventos } from '../Evento/actions/actions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { dateTimeFormat } from "../../../util/DateUtil";
import Button from '@material-ui/core/Button';
import parse from 'html-react-parser';
import { Grid } from '@material-ui/core';
import { save } from './api-evento-usuario';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    respostaContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
        paddingTop: '20px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    title: {
        marginBottom: '20px',
    },
    respostaBox: {

    },
    respostaItens: {
        margin: '30px',
    }
}));

export default function EventoUsuarioForm() {
    const classes = useStyles();
    const eventoUsuario = useSelector(state => state.evento);
    const dispatch = useDispatch();

    const handleResposta = (resposta) => {
        let persistent = {
            ...eventoUsuario,
            resposta: resposta
        }

        save(persistent).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                toast.success("Resposta Enviada.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                dispatch(setEvento(data));
                dispatch(updateEventos(data));
            }
        });


    }

    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container className={classes.paper} component={Paper} >
                <CloseIcon onClick={() => dispatch(setEvento(null))} className={classes.closeIcon} />

                <Typography variant='h4' className={classes.title}>
                    {eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.titulo : ''}
                </Typography>

                <Typography className={classes.title}>
                    Início: {dateTimeFormat(eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.dataHoraInicio : null)}
                </Typography>

                <Typography className={classes.title}>
                    Fim: {dateTimeFormat(eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.dataHoraFim : null)}
                </Typography>

                {parse(eventoUsuario && eventoUsuario.evento ? eventoUsuario.evento.descricao : '')}

                {eventoUsuario && eventoUsuario.aluno ?
                    <Typography className={classes.title}>
                        Aluno: {eventoUsuario.aluno.nome}
                    </Typography> : ''
                }

            </Container>

            <Container className={classes.respostaContainer} component={Paper} >
                <Typography variant='h5'>
                    Informe se você poderá ir ao evento.
                </Typography>
                <Grid container justify='flex-start'>

                    <Button
                        onClick={() => handleResposta('Sim')}
                        variant={eventoUsuario &&
                            eventoUsuario.resposta &&
                            eventoUsuario.resposta === 'Sim'
                            ? 'contained' : 'outlined'}
                        color="primary"
                        className={classes.respostaItens}
                    >
                        Sim
                    </Button>
                    <Button
                        onClick={() => handleResposta('Não')}
                        variant={eventoUsuario &&
                            eventoUsuario.resposta &&
                            eventoUsuario.resposta === 'Não'
                            ? 'contained' : 'outlined'}
                        color="primary"
                        className={classes.respostaItens}
                    >
                        Não
                    </Button>
                    <Button
                        onClick={() => handleResposta('Talvez')}
                        variant={eventoUsuario &&
                            eventoUsuario.resposta &&
                            eventoUsuario.resposta === 'Talvez'
                            ? 'contained' : 'outlined'}
                        color="primary"
                        className={classes.respostaItens}
                        selected={true}
                    >
                        Talvez
                    </Button>
                </Grid>
            </Container>
        </div>
    );
}