import React, { useState, useEffect } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import { page } from '../Sala/api-sala';
import { useSelector } from 'react-redux';
import Loading from '../../UI/Loading';
import { useDispatch } from 'react-redux';
import { setSalas } from './actions/actions';
import MensagemAll from './MensagemAll';


export default function MensagemListAll() {
    const salas = useSelector(state => state.salas);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!salas) {
            setIsLoading(true);
            page(0).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setSalas(data));
                    setIsLoading(false);
                }
            });
        }

    }, [dispatch, salas]);


    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setSalas(data));
                setIsLoading(false);
            }
        });
    };

    return (
        <>
            {isLoading ? <Loading description='Carregando Mensagens...' /> :
                <>
                    {salas &&
                        salas.content &&
                        salas.content.length > 0 &&
                        salas.content.map((sala) => (
                            <MensagemAll sala={sala} />
                        ))}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={salas && salas.size ? salas.size : 0}
                        component="div"
                        count={salas && salas.totalElements}
                        page={salas && salas.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>}

        </>
    )
}