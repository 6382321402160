import { confirmAlert } from 'react-confirm-alert';

export const errorMessage = (message) => {
    confirmAlert({
        title: 'Erro',
        message: message,
        buttons: [
            {
                label: 'Ok',
                onClick: () => { }
            }
        ]
    });
};

export const successMessage = (message) => {
    confirmAlert({
        title: 'Sucesso',
        message: message,
        buttons: [
            {
                label: 'Ok',
                onClick: () => { }
            }
        ]
    });
};
