import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch } from '@material-ui/core';
import React from 'react';

const LancamentoFilter = ({ filter, handleChangeFilter}) => {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Filtro</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch checked={filter.pendentes} onChange={handleChangeFilter} name="pendentes" />
                    }
                    label="Pendentes"
                />
                <FormControlLabel
                    control={
                        <Switch checked={filter.todos} onChange={handleChangeFilter} name="todos" />
                    }
                    label="Todos"
                />
            </FormGroup>
        </FormControl>

    );
}

export default LancamentoFilter;