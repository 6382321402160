import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchPostAuthFile,
  fetchRemoveAuthReturn
} from '../../../util/FetchUtil';

const controller = 'avaliacao-conteudo/';

const findBySecaoCodigo = (codSecao) => {
  const url = SERVER_URL + controller + codSecao;
  return fetchGetAuth(url);
}

const findBySecaoCodigoAndAvaliacaoAluno = (codSecao, codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'secao/' + codSecao + '/avaliacao-aluno/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}

const findBySecaoCodigoNext = (codSecaoConteudo) => {
  const url = SERVER_URL + controller + 'next/' + codSecaoConteudo;
  return fetchGetAuth(url);
}

const findBySecaoCodigoAndAvaliacaoAlunoNext = (codSecaoConteudo, codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'next/secao/' + codSecaoConteudo + '/avaliacao-aluno/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}

const findBySecaoCodigoPrevious = (codSecaoConteudo) => {
  const url = SERVER_URL + controller + 'previous/' + codSecaoConteudo;
  return fetchGetAuth(url);
}

const findBySecaoCodigoAndAvaliacaoAlunoPrevious = (codSecaoConteudo, codAvaliacaoAluno) => {
  const url = SERVER_URL + controller + 'previous/secao/' + codSecaoConteudo + '/avaliacao-aluno/' + codAvaliacaoAluno;
  return fetchGetAuth(url);
}

const remove = (codSecaoConteudo) => {
  const url = SERVER_URL + controller + codSecaoConteudo;
  return fetchRemoveAuthReturn(url);
}

const deleteFileBucket = (codSecaoConteudo) => {
  const url = SERVER_URL + controller + "delete-file-bucket/" + codSecaoConteudo;
  return fetchGetAuth(url);
}

const save = (secaoConteudo) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, secaoConteudo);
}

const uploadImagem = (codSecaoConteudo, fileForm) => {
  const url = SERVER_URL + controller + "upload-imagem/" + codSecaoConteudo;
  return fetchPostAuthFile(url, fileForm);
}

const uploadImagemExer = (codSecaoConteudo, fileForm) => {
  const url = SERVER_URL + controller + "upload-imagem-exer/" + codSecaoConteudo;
  return fetchPostAuthFile(url, fileForm);
}

export {
  findBySecaoCodigo,
  save,
  remove,
  uploadImagem,
  uploadImagemExer,
  deleteFileBucket,
  findBySecaoCodigoNext, 
  findBySecaoCodigoPrevious, 
  findBySecaoCodigoAndAvaliacaoAluno, 
  findBySecaoCodigoAndAvaliacaoAlunoNext, 
  findBySecaoCodigoAndAvaliacaoAlunoPrevious
}