import React, { useState, useEffect } from 'react';
import TurmaTableSelected from '../Turma/TurmaTableSelect';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TurmaProfile from '../Turma/TurmaProfile';
import { findUnregisteredDisciplinas, save, findByTurma } from './api-tdp';
import DisciplinaTurmaTable from './DisciplinaTurmaTable';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Grid, makeStyles } from '@material-ui/core';
import DisciplinaTurmaForm from './DisciplinaTurmaForm';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    container: {
        paddingTop: '10px',
        paddingBottom: '10px'
    }
})

export default function DisciplinaTurmaContainer() {
    const classes = useStyles();
    const [tdp, setTdp] = useState(null);
    const [tdps, setTdps] = useState([]);
    const [loadingAdicionarDisciplina, setLoadingAdicionarDisciplina] = useState(false);
    const [turma, setTurma] = useState(null);
    const [unregisteredDisciplinas, setUnregisteredDisciplinas] = useState([]);

    useEffect(() => {
        if (turma) {
            findUnregisteredDisciplinas(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setUnregisteredDisciplinas(data);
                }
            });

            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data);
                }
            });
        }
    }, [turma]);

    const handleSaveNoBimestreError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'A série para esta turma não possui cadastro de bimestres. Cadastre os bimestres antes de inserir disciplina na turma.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleSaveTurmaAndDisciplinaAlreadyRegistered = () => {
        confirmAlert({
            title: 'Erro',
            message: 'Disciplina já cadastrada para a Turma.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleAdicionarDisciplina = (e) => {
        e.preventDefault();


        if (!tdp.disciplina) {
            toast.error("Selecione uma disciplina.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        setLoadingAdicionarDisciplina(true);
        const eNovo = tdp.eNovo;
        save(tdp).then(data => {
            if (data.error) {
                if (data.message === 'Erro: Bimestre nao cadastrado') {
                    handleSaveNoBimestreError();
                } else if (data.message === 'Erro: Turma and Disciplina already registered') {
                    handleSaveTurmaAndDisciplinaAlreadyRegistered();
                }
                else {
                    errorMessage(data.message);
                }
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setTdp(data);
                if (eNovo) {
                    setTdps([
                        ...tdps,
                        data
                    ]);
                    setUnregisteredDisciplinas(
                        unregisteredDisciplinas.filter((d) => (
                            d.codDisciplina !== data.disciplina.codDisciplina
                        ))
                    );
                } else {
                    setTdps((prevState) => prevState.map((ps) => {
                        if (ps.codigo === data.codigo) {
                            return data;
                        } else {
                            return ps;
                        }
                    }));
                }

                setLoadingAdicionarDisciplina(false);
            }
        });
    }

    const handleTrocaTurma = () => {
        setTurma(null);
    }

    return (
        <Container className={classes.container} component={Paper}>
            <Grid container spacing={3} direction='column'>
                <Grid item>
                    <Typography variant="h6">
                        Disciplina Turma
                    </Typography>
                </Grid>
                {turma ?
                    <>
                        <Grid item>
                            <TurmaProfile turma={turma} handleTrocaTurma={handleTrocaTurma} />
                        </Grid>
                        {tdp ?
                            <Grid item>
                                <DisciplinaTurmaForm
                                    tdp={tdp}
                                    setTdp={setTdp}
                                    handleAdicionarDisciplina={handleAdicionarDisciplina}
                                    loadingAdicionarDisciplina={loadingAdicionarDisciplina}
                                    unregisteredDisciplinas={unregisteredDisciplinas}
                                    setTdps={setTdps}
                                    tdps={tdps}
                                />
                            </Grid> :
                            <>
                                <Grid item>
                                    <Button variant='outlined' onClick={() => setTdp({
                                        eNovo: true,
                                        turma: turma,
                                        eDataSerie: true
                                    })}>
                                        Novo Registro
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <DisciplinaTurmaTable tdps={tdps} setTdp={setTdp} />
                                </Grid>
                            </>
                        }

                    </> : (
                        <Grid item>
                            <TurmaTableSelected onTurmaSelected={setTurma} />
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    );
}