import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-curso';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addCursos, updateCursos, setCurso as setCursoRedux } from './actions/actions'
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));

export default function CursoForm() {
    const classes = useStyles();
    const cursoRedux = useSelector(state => state.curso);
    const [curso, setCurso] = useState(useSelector(state => state.curso));
    const dispatch = useDispatch();

    useEffect(() => {
        setCurso(cursoRedux);
    }, [cursoRedux]);

    const handleChange = (event) => {
        setCurso({
            ...curso,
            [event.target.name]: event.target.value
        });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = curso.eNovo;

        if (!curso.descricao) {
            toast.error("Preencha a Descrição.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        save(curso).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addCursos(data));
                } else {
                    dispatch(updateCursos(data));
                }
                dispatch(setCurso(data));
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <div>
            <Container component={Paper} >
                <CloseIcon
                    onClick={() => dispatch(setCursoRedux(null))}
                    className={classes.closeIcon}
                    fontSize='small'
                />
                <form onSubmit={clickSubmit}>
                    <TextField
                        variant="outlined"
                        size='small'
                        margin="normal"
                        fullWidth
                        inputProps={{ maxLength: 45 }}
                        autoFocus
                        label="Descrição"
                        name="descricao"
                        onChange={handleChange}
                        value={curso && curso.descricao ? curso.descricao : ''}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size='small'
                        fullWidth
                        inputProps={{ maxLength: 4 }}
                        label="Abreviação"
                        name="abreviacao"
                        onChange={handleChange}
                        value={curso && curso.abreviacao ? curso.abreviacao : ''}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>
        </div>
    );
}