import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { findPCPTodos } from './api-presenca-capitulo';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    },
    loading: {
        marginRight: '20px',
        marginLeft: '20px'
    }
});

export default function PlanejamentoConsultaQuant() {
    const classes = useStyles();
    const [listaPCP, setListaPCP] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        findPCPTodos().then((data) => {
            if (data) {
                setListaPCP(data);
                setLoading(false);
            }
        })

    }, []);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h5">
                    Controle Planejamento
                </Typography>


                {loading ?
                    <>
                        <CircularProgress className={classes.loading} color="primary" />
                        Carregando...
                    </>
                    :
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Turma</TableCell>
                                <TableCell>Disciplina</TableCell>
                                <TableCell>Professor</TableCell>
                                <TableCell>1º Bimestre</TableCell>
                                <TableCell>2º Bimestre</TableCell>
                                <TableCell>3º Bimestre</TableCell>
                                <TableCell>4º Bimestre</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaPCP.length > 0 ? listaPCP.map((pcp, index) => (
                                <TableRow key={index}>
                                    <TableCell>{pcp.tdp.turma.descricao}</TableCell>
                                    <TableCell>{pcp.tdp.disciplina.descricao}</TableCell>
                                    <TableCell>{pcp.tdp.usuario ? pcp.tdp.usuario.nome : ''}</TableCell>
                                    <TableCell style={{ backgroundColor: pcp.atrasado1 === 0 ? '#ef9a9a' : '' }}>{pcp.capitulo1} / {pcp.planejamento1}</TableCell>
                                    <TableCell style={{ backgroundColor: pcp.atrasado2 === 0 ? '#ef9a9a' : '' }}>
                                        {pcp.capitulo2} / {pcp.planejamento2}
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: pcp.atrasado3 === 0 ? '#ef9a9a' : '' }}>{pcp.capitulo3} / {pcp.planejamento3}</TableCell>
                                    <TableCell style={{ backgroundColor: pcp.atrasado34 === 0 ? '#ef9a9a' : '' }}>{pcp.capitulo4} / {pcp.planejamento4}</TableCell>
                                </TableRow>
                            )) : ''}
                        </TableBody>
                    </Table>
                }








            </Container>

        </div>
    );
}