import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    divImage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    image: {
        height: '200px',
        cursor: 'pointer',
    },
})

const AlunoFotoList = ({ listaAF }) => {
    const classes = useStyles();

    return (
        <Grid
            className={classes.container}
            container
            direction='row'
            alignContent='flex-start'
            spacing={3}
        >
            {listaAF.map((af) => (
                <Grid
                    key={af.codigo}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                >
                    {
                        af.foto ?
                            <div className={classes.divImage}>
                                <img
                                    className={classes.image}
                                    src={af.foto}
                                    alt=''
                                />
                                {af.anoLetivo}
                            </div>
                            :
                            <p>Nenhuma Imagem Selecionado</p>
                    }
                </Grid>
            ))}
        </Grid>
    )
}

export default AlunoFotoList;