import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByTdpNumeroBimestreNBT, gerarMediaMensal, gerarMediaBimestral, gerarQuintoConceito } from './api-nota-bimestre';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { setNotaBimestre, setNotasBimestre } from './actions/actions';
import { setNotaBimestreTipo } from '../NotaBimestreTipo/actions/actions';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { confirmAlert } from 'react-confirm-alert';
import { dateTimeFormat } from '../../util/DateUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        paddingBottom: '20px'
    },
    title: {
        paddingTop: '20px;',
        paddingBottom: '20px;'
    },
    rowNotSelected: {
        cursor: 'pointer'
    },
    rowSelected: {
        cursor: 'pointer',
        backgroundColor: '#b5e7a4',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function NotaBimestreTipoTable() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const notaBimestreTipo = useSelector(state => state.notaBimestreTipo);
    const notasBimestre = useSelector(state => state.notasBimestre);
    const notaBimestre = useSelector(state => state.notaBimestre);
    const dispatch = useDispatch();
    const [numeroBimestre, setNumeroBimestre] = useState(notaBimestreTipo.codigo <= 9 ? 1 : 4);
    const [loadingGerarMediaMensal, setLoadingGerarMediaMensal] = useState(false);
    const [loadingGerarMediaBimestral, setLoadingGerarMediaBimestral] = useState(false);
    const [loadingGerarQuintoConceito, setLoadingGerarQuintoConceito] = useState(false);

    useEffect(() => {
        findByTdpNumeroBimestreNBT(tdp.codigo, numeroBimestre, notaBimestreTipo.codigo).then((data) => {
            if (data) {
                dispatch(setNotasBimestre(data));
                if (data.length > 0) {
                    dispatch(setNotaBimestre(data[0]));
                }

            }
        })
    }, [tdp, numeroBimestre, notaBimestreTipo, dispatch]);


    const handleBimestreChange = (event) => {
        setNumeroBimestre(event.target.value);
    }

    const handleGerarMendiaMensal = () => {
        setLoadingGerarMediaMensal(true);
        gerarMediaMensal(tdp.codigo, numeroBimestre).then((data) => {
            if (data.error) {
                handleError(data.message);
            } else {
                dispatch(setNotasBimestre(data));
                if (data.length > 0) {
                    dispatch(setNotaBimestre(data[0]));
                }
            }
            setLoadingGerarMediaMensal(false);
        });
    }

    const handleError = (message) => {
        confirmAlert({
            title: 'Erro',
            message: message,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleGerarMendiaBimestral = () => {
        setLoadingGerarMediaBimestral(true);
        gerarMediaBimestral(tdp.codigo, numeroBimestre).then((data) => {
            if (data.error) {
                handleError(data.message)
            } else {
                dispatch(setNotasBimestre(data));
                if (data.length > 0) {
                    dispatch(setNotaBimestre(data[0]));
                }
            }
            setLoadingGerarMediaBimestral(false);
        });
    }

    const handleGerarQuintoConceito = () => {
        setLoadingGerarQuintoConceito(true);
        gerarQuintoConceito(tdp.codigo).then((data) => {
            if (data.error) {
                handleError(data.message)
            } else {
                dispatch(setNotasBimestre(data));
                if (data.length > 0) {
                    dispatch(setNotaBimestre(data[0]));
                }
            }
            setLoadingGerarQuintoConceito(false);
        });
    }



    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Grid container className={classes.title} justify="space-between">
                    <Grid item xs={5}>
                        <Typography variant="body1">
                            {notaBimestreTipo.descricao}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => dispatch(setNotaBimestreTipo(null))}
                            size="small"
                            color="secondary"
                            variant="contained"
                        >
                            Trocar Tipo Nota Bimestre
                        </Button>
                    </Grid>
                    {notaBimestreTipo.codigo === 7 ?
                        <Grid item xs={6}>
                            <div className={classes.wrapper}>
                                <Button
                                    onClick={handleGerarMendiaMensal}
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    disabled={loadingGerarMediaMensal}
                                >
                                    {loadingGerarMediaMensal && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                    Gerar Média Mensal
                                </Button>

                            </div>

                        </Grid> : ''}

                    {notaBimestreTipo.codigo === 9 ?
                        <Grid item xs={6}>
                            <div className={classes.wrapper}>
                                <Button
                                    onClick={handleGerarMendiaBimestral}
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    disabled={loadingGerarMediaBimestral}
                                >
                                    {loadingGerarMediaBimestral && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                    Gerar Média Bimestral
                                </Button>

                            </div>

                        </Grid> : ''}

                    {notaBimestreTipo.codigo === 11 ?
                        <Grid item xs={6}>
                            <div className={classes.wrapper}>
                                <Button
                                    onClick={handleGerarQuintoConceito}
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    disabled={loadingGerarQuintoConceito}
                                >
                                    {loadingGerarQuintoConceito && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                    Gerar 5º Conceito
                                </Button>

                            </div>

                        </Grid> : ''}

                    {notaBimestreTipo.codigo <= 9 ?
                        <Grid item xs={6}>
                            <FormControl >
                                <InputLabel id="bimestre-label">Bimestre</InputLabel>
                                <Select
                                    labelId="bimestre-label"
                                    id="bimestre-select"
                                    value={numeroBimestre}
                                    onChange={handleBimestreChange}
                                >
                                    <MenuItem value={1}>1º Bimestre</MenuItem>
                                    <MenuItem value={2}>2º Bimestre</MenuItem>
                                    <MenuItem value={3}>3º Bimestre</MenuItem>
                                    <MenuItem value={4}>4º Bimestre</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        : ''}

                </Grid>

                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nº Chamada</TableCell>
                            <TableCell>Aluno</TableCell>
                            <TableCell>Nota</TableCell>
                            <TableCell>Criado em</TableCell>
                            <TableCell>Última Atualização</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notasBimestre &&
                            notasBimestre.length > 0 ?
                            notasBimestre.map((nb) => (
                                <TableRow
                                    className={notaBimestre && notaBimestre.codigo === nb.codigo ? classes.rowSelected : classes.rowNotSelected}
                                    onClick={() => dispatch(setNotaBimestre(nb))} key={nb.codigo}
                                >
                                    <TableCell>{nb.alunoTurma.numeroChamada}</TableCell>
                                    <TableCell>{nb.aluno.nome}</TableCell>
                                    <TableCell>{nb.nota}</TableCell>
                                    <TableCell>{dateTimeFormat(nb.createdAt)}</TableCell>
                                    <TableCell>{dateTimeFormat(nb.lastModified)}</TableCell>
                                </TableRow>
                            )) : ''}
                    </TableBody>
                </Table>
            </Container>
        </div>
    );
}