import {
    ADD_ALUNOS, 
    UPDATE_ALUNOS, 
    SET_ALUNOS, 
    REMOVE_ALUNOS,
    SET_ALUNO, 
    SET_ALUNO_NULL, 
    OPEN, 
    CLOSE
} from './actionTypes'

export function addAlunos (aluno) {
    return { type: ADD_ALUNOS, aluno }
}

export function updateAlunos(aluno){
    return {type : UPDATE_ALUNOS, aluno}
}

export function setAlunos(alunos){
    return {type : SET_ALUNOS, alunos}
}

export function removeAlunos(index){
    return {type : REMOVE_ALUNOS, index}
}

export function setAluno(aluno){
    return {type : SET_ALUNO, aluno}
}

export function setAlunoNull(){
    return {type : SET_ALUNO_NULL}
}

export function open(){
    return {type : OPEN}
}

export function close(){
    return {type : CLOSE}
}