import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import DiaLetivoExtraMenu from './DiaLetivoExtraMenu';
import DiaLetivoExtraContainer from './DiaLetivoExtra/DiaLetivoExtraContainer';
import DiaLetivoExtraSerieContainer from './DiaLetivoExtraSerie/DiaLetivoExtraSerieContainer';

export const diaLetivoExtraKey = 'diaLetivoExtra';
export const diaLetivoExtraSerieKey = 'diaLetivoExtraSerie';

const DiaLetivoExtraMenuContainer = () => {
    const [menu, setMenu] = useState(diaLetivoExtraKey)

    return (
        <Grid
            justifyContent='center'
            container
            spacing={3}
        >
            <Grid
                item
                xs={8}
            >
                <DiaLetivoExtraMenu
                    menu={menu}
                    setMenu={setMenu}
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                {menu === diaLetivoExtraKey &&
                    <DiaLetivoExtraContainer />
                }
                {menu === diaLetivoExtraSerieKey &&
                    <DiaLetivoExtraSerieContainer />
                }
            </Grid>
        </Grid>
    )
}

export default DiaLetivoExtraMenuContainer;