import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { findByNumeroBimestre, update, remove } from '../Bimestre/api-bimestre';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Loading from '../UI/Loading';
import { dateFormatMoment } from '../../util/DateUtil';
import TextFieldDate from '../UI/TextFieldDate';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    insereSerie: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
}));

const rows = [
    '1º Bimestre',
    '2º Bimestre',
    '3º Bimestre',
    '4º Bimestre'
];

export default function Bimestre() {
    const classes = useStyles();
    const [selectedBimestre, setSelectedBimestre] = useState();
    const [bimestres, setBimestres] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleDataInicioChange = (data, index) => {
        let bimestre = bimestres[index];
        bimestre.dataInicio = data;
        update(bimestre).then(data => {
            if (data.error) {
                errorMessage(data.message);
                console.log(data);
            } else {
                setBimestres(prevState => (
                    prevState.map(b => {
                        if (b.codigo === bimestres[index].codigo) {
                            return bimestre;
                        } else {
                            return b;
                        }
                    })
                ))
            }
        });
    }

    useEffect(() => {
        handleSelecionarBimestre(0, rows[0]);
    }, []);

    const handleDataFimChange = (data, index) => {
        let bimestre = bimestres[index];
        bimestre.dataFim = data;
        update(bimestre).then(data => {
            if (data.error) {
                errorMessage(data.message);
                console.log(data);
            } else {
                setBimestres(prevState => (
                    prevState.map(b => {
                        if (b.codigo === bimestres[index].codigo) {
                            return bimestre;
                        } else {
                            return b;
                        }
                    })
                ))
            }

        });
    }

    const handleSelecionarBimestre = (index, row) => {
        setIsLoading(true);
        findByNumeroBimestre(index + 1).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setBimestres(data);
                setSelectedBimestre(row);
            }
            setIsLoading(false);
        })
    };

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este bimestre?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(bimestres[index].codigo).then((data) => {
                            if (data) {
                                let content = bimestres.filter((bimestre, i) => i !== index);
                                setBimestres(content);
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };


    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item
                    xs={4}
                >
                    <Paper className={classes.paper}>
                        <Typography className={classes.title} variant="h5">
                            Bimestres
                        </Typography>
                        <Container component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Nº Bimestre</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {row}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Button onClick={() => { handleSelecionarBimestre(index, row) }}
                                                    variant="contained"
                                                    color="secondary">
                                                    Selecionar
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Container>

                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.title} variant="h5">
                            Bimestres por Série{`${selectedBimestre ? ' - ' + selectedBimestre : ''}`}
                        </Typography>
                        <Link to={"/bimestre-form"}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.insereSerie}>
                                Inserir Série
                            </Button>
                        </Link>
                        {isLoading ? <Loading description={"Carregando Bimestres..."} /> :
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Série</StyledTableCell>
                                        <StyledTableCell>Data Início</StyledTableCell>
                                        <StyledTableCell>Data Fim</StyledTableCell>
                                        <StyledTableCell>Ações</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bimestres.map((bimestre, index) => (
                                        <StyledTableRow key={bimestre.codigo}>
                                            <StyledTableCell component="th" scope="row">
                                                {bimestre.serie.descricao}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextFieldDate
                                                    onChange={(data) => handleDataInicioChange(data, index)}
                                                    value={bimestre.dataInicio ? dateFormatMoment(bimestre.dataInicio) : null}
                                                    label="Data Início"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextFieldDate
                                                    onChange={(data) => handleDataFimChange(data, index)}
                                                    value={bimestre.dataFim ? dateFormatMoment(bimestre.dataFim) : null}
                                                    label="Data Fim"
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <DeleteIcon
                                                    className={classes.cursorLink}
                                                    onClick={() => { handleDelete(index) }}
                                                    color="secondary" />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }

                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}