import {
  Button,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@material-ui/core";
import React from "react";
import TablePaginationActions from "../../../shared/components/Table/Actions";
import { dateTimeFormat } from "../../../util/DateUtil";
import { errorBg, successBg } from "../../UI/ColorUtil";
import Loading from "../../UI/Loading";

const ComunicadoUsuarioList = ({
  listCU,
  isLoading,
  handleChangePage,
  handleRowClick,
}) => {
  return (
    <Grid container spacing={3} direction="column">
      <>
        {isLoading ? (
          <Loading description="Carregando Comunicados..." />
        ) : (
          <>
            {listCU.content.map((comunicadoUsuario) => (
              <>
                <Grid item>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <img
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                        }}
                        src={
                          comunicadoUsuario.comunicado.comunicadoBanner
                            ? comunicadoUsuario.comunicado.comunicadoBanner
                                .urlBanner
                            : null
                        }
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2} direction="column">
                        <Grid item>
                          <Typography variant="h5">
                            {comunicadoUsuario && comunicadoUsuario.comunicado
                              ? comunicadoUsuario.comunicado.titulo
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Data Hora Envio:{" "}
                            {dateTimeFormat(comunicadoUsuario.createdAt)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Aluno:{" "}
                            {comunicadoUsuario.aluno
                              ? comunicadoUsuario.aluno.nome
                              : "--"}
                          </Typography>
                        </Grid>
                        {comunicadoUsuario.comunicado.dataHoraPara && (
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 1000,
                              }}
                            >
                              Data Hora Para:{" "}
                              {dateTimeFormat(
                                comunicadoUsuario.comunicado.dataHoraPara
                              )}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        container
                        spacing={3}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Button
                            onClick={() => handleRowClick(comunicadoUsuario)}
                            variant="outlined"
                          >
                            Selecionar
                          </Button>
                        </Grid>
                        <Grid
                          style={{
                            borderRadius: "20px",
                            backgroundColor: comunicadoUsuario.leituraConfirmada
                              ? successBg
                              : errorBg,
                          }}
                          item
                        >
                          <Typography variant="body1">
                            {comunicadoUsuario.leituraConfirmada
                              ? "Leitura Confirmada"
                              : "Leitura ainda não Confirmada"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
              </>
            ))}
          </>
        )}
      </>
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage={listCU.size ? listCU.size : 0}
          component="div"
          count={listCU.totalElements}
          page={listCU.number}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            from +
            "-" +
            to +
            " de " +
            (count !== -1 ? count + " registros" : +" mais de " + to)
          }
        />
      </Grid>
    </Grid>
  );
};

export default ComunicadoUsuarioList;
