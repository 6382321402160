import React, { useEffect, useState } from "react";
import { dateFormatMoment, timeFormat } from "../../../util/DateUtil";
import TextFieldDate from "../../UI/TextFieldDate";
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Container from "../../UI/Container";
import CloseIcon from "@material-ui/icons/Close";
import Select from "../../UI/Select";
import { toast } from "react-toastify";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import { save } from "../api-presenca";
import Button from "../../UI/Button";
import { findByTdpCodigoAndData } from "../../Cronograma/api-cronograma";
import CircularProgress from "@material-ui/core/CircularProgress";
import { findByTurma } from "../../TDP/api-tdp";
import PresencaAlunoTableSwitch from "../../PresencaAluno/PresencaAlunoTableSwitch";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  closeIcon: {
    marginTop: "20px",
    cursor: "pointer",
  },
  loading: {
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

const PresencaTurmaForm = ({ presenca, setPresenca, setPresencas, turma }) => {
  const classes = useStyles();
  const [listValuesCronograma, setListValuesCronograma] = useState([]);
  const [listCronograma, setListCronograma] = useState([]);
  const [isLoadingCronograma, setIsLoadingCronograma] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [listTdp, setListTdp] = useState([]);
  const [listValuesTdp, setListValuesTdp] = useState([]);

  useEffect(() => {
    findByTurma(turma.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListTdp(data);
          setListValuesTdp(
            data.map((d) => {
              return {
                description: d.disciplina.descricao,
                value: d.codigo,
              };
            })
          );
        }
      }
    });
  }, [turma.codigo]);

  const handleChange = (event) => {
    setPresenca({
      ...presenca,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeCronograma = (event) => {
    setPresenca((prevState) => ({
      ...prevState,
      cronograma: listCronograma.find((c) => c.codigo === event.target.value),
    }));
  };

  const onChangeTdp = (event) => {
    setPresenca((prevState) => ({
      ...prevState,
      tdp: listTdp.find((c) => c.codigo === event.target.value),
    }));
  };

  const getCronogramaDescription = (cronogramaDescription) => {
    return (
      "Aula " +
      cronogramaDescription.cronogramaAula.ordemAula +
      " : " +
      timeFormat(cronogramaDescription.cronogramaAula.horarioInicio) +
      " - " +
      timeFormat(cronogramaDescription.cronogramaAula.horarioFim)
    );
  };

  const handleDataChange = (data) => {
    if (data) data.setHours(0, 0, 0, 0);

    setPresenca({
      ...presenca,
      data: data,
    });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setIsLoadingSave(true);
    const eNovo = presenca.eNovo;
    save(presenca).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          if (eNovo) {
            addPresencas(data);
          } else {
            updatePresencas(data);
          }
          setPresenca(null);
        }
      }
      setIsLoadingSave(false);
    });
  };

  const addPresencas = (data) => {
    setPresencas((prevState) => ({
      ...prevState,
      content: [data, ...prevState.content],
    }));
  };

  const updatePresencas = (data) => {
    setPresencas((prevState) => ({
      ...prevState,
      content: prevState.content.map((ps) => {
        if (ps.codigo === data.codigo) {
          return data;
        } else {
          return ps;
        }
      }),
    }));
  };

  const handleLoadCronogramas = () => {
    if (presenca.data) {
      setIsLoadingCronograma(true);
      findByTdpCodigoAndData(presenca.tdp.codigo, presenca.data).then(
        (data) => {
          if (data) {
            if (data.error) {
              errorMessage(data.message);
            } else {
              setListCronograma(data);
              setListValuesCronograma(
                data.map((d) => {
                  return {
                    description: getCronogramaDescription(d),
                    value: d.codigo,
                  };
                })
              );
            }
          } else {
            errorMessage("No data");
          }
          setIsLoadingCronograma(false);
        }
      );
    } else {
      errorMessage("Digite a data para Carregar os Cronogramas.");
    }
  };

  return (
    <Container className={classes.paper} component={Paper}>
      <div className={classes.closeIcon}>
        <CloseIcon onClick={() => setPresenca(null)} fontSize="small" />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <form onSubmit={clickSubmit}>
            <Grid container direction="column">
              <Grid item>
                <TextFieldDate
                  required
                  label="Data"
                  value={
                    presenca && presenca.data
                      ? dateFormatMoment(presenca.data)
                      : null
                  }
                  onChange={handleDataChange}
                />
              </Grid>
              <Grid item>
                <Select
                  label="Disciplina"
                  value={
                    presenca.tdp && presenca.tdp.codigo
                      ? presenca.tdp.codigo
                      : ""
                  }
                  list={listValuesTdp}
                  onChange={onChangeTdp}
                />
              </Grid>
              {presenca.tdp && (
                <>
                  <Grid item>
                    <Typography variant="body1" >
                      Cronograma:{" "}
                      {presenca.cronograma &&
                        getCronogramaDescription(presenca.cronograma)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {listValuesCronograma.length > 0 && (
                      <Select
                        label="Cronograma"
                        value={
                          presenca.cronograma && presenca.cronograma.codigo
                            ? presenca.cronograma.codigo
                            : ""
                        }
                        list={listValuesCronograma}
                        onChange={onChangeCronograma}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleLoadCronogramas}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      disabled={isLoadingCronograma}
                      className={classes.submit}
                    >
                      {isLoadingCronograma && (
                        <CircularProgress
                          className={classes.loading}
                          color="primary"
                          size={20}
                        />
                      )}
                      Carregar Cronogramas
                    </Button>
                  </Grid>
                </>
              )}

              <Grid item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  multiline
                  minRows="4"
                  inputProps={{ maxLength: 500 }}
                  fullWidth
                  label="Conteúdo"
                  name="conteudo"
                  onChange={handleChange}
                  value={presenca && presenca.conteudo ? presenca.conteudo : ""}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoadingSave}
                  color="primary"
                  className={classes.submit}
                >
                  {isLoadingSave && (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                      size={20}
                    />
                  )}
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <PresencaAlunoTableSwitch presenca={presenca} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PresencaTurmaForm;
