import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth,
  fetchPostAuthFile,
  fetchGet
} from '../../../util/FetchUtil';

const controller = 'capitulo-conteudo/';

const findByCapituloCodCapitulo = (codCapitulo) => {
  const url = SERVER_URL + controller + codCapitulo;
  return fetchGetAuth(url);
}

export const findByCapituloCodCapituloAndCapituloLivroIsPublic = (codCapitulo) => {
  const url = SERVER_URL + controller + 'public/' + codCapitulo;
  return fetchGet(url);
}

const findByCapituloCodCapituloNext = (codCapitulo) => {
  const url = SERVER_URL + controller + 'next/' + codCapitulo;
  return fetchGetAuth(url);
}

export const findByCapituloCodCapituloNextAndCapituloLivroIsPublic = (codCapitulo) => {
  const url = SERVER_URL + controller + 'public/next/' + codCapitulo;
  return fetchGet(url);
}

const findByCapituloCodCapituloPrevious = (codCapitulo) => {
  const url = SERVER_URL + controller + 'previous/' + codCapitulo;
  return fetchGetAuth(url);
}

export const findByCapituloCodCapituloPreviousAndCapituloLivroIsPublic = (codCapitulo) => {
  const url = SERVER_URL + controller + 'public/previous/' + codCapitulo;
  return fetchGet(url);
}

const remove = (codConteudo) => {
  const url = SERVER_URL + controller + codConteudo;
  return fetchRemoveAuth(url);
}

const deleteFileBucket = (codConteudo) => {
  const url = SERVER_URL + controller + "delete-file-bucket/" + codConteudo;
  return fetchGetAuth(url);
}

const save = (conteudo) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, conteudo);
}

const uploadVideo = (codigoCapitulo, fileForm) => {
  const url = SERVER_URL + controller + "upload-video/" + codigoCapitulo;
  return fetchPostAuthFile(url, fileForm);
}

const uploadImagem = (codigoCapitulo, fileForm) => {
  const url = SERVER_URL + controller + "upload-imagem/" + codigoCapitulo;
  return fetchPostAuthFile(url, fileForm);
}

const uploadImagemExer = (codConteudo, fileForm) => {
  const url = SERVER_URL + controller + "upload-imagem-exer/" + codConteudo;
  return fetchPostAuthFile(url, fileForm);
}

export {
  findByCapituloCodCapitulo,
  save,
  remove,
  uploadVideo,
  uploadImagem,
  uploadImagemExer,
  deleteFileBucket,
  findByCapituloCodCapituloNext,
  findByCapituloCodCapituloPrevious
}