import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAlunoTurma } from './actions/actions';
import { setObservacaoAluno } from '../ObservacaoAluno/actions/actions';
import AlunoTurmaProfileView from './AlunoTurmaProfileView';

export default function PresencaProfile() {
    const alunoTurma = useSelector(state => state.alunoTurma);
    const dispatch = useDispatch();
    const handleChangeAlunoTurma = () => {
        dispatch(setAlunoTurma(null));
        dispatch(setObservacaoAluno(null));
    }

    return (
        <AlunoTurmaProfileView
            alunoTurma={alunoTurma}
            handleChangeAlunoTurma={handleChangeAlunoTurma}
        />
    );
}