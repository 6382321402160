import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '../../UI/Button';
import { dateFormat } from '../../../util/DateUtil';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    container: {
        padding: '10px',
    },
});

const DiaNaoLetivoTable = ({ listDiaNaoLetivo, setDiaNaoLetivo, isLoading }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            component={Paper}
            className={classes.container}
        >
            <Button
                onClick={() => setDiaNaoLetivo({
                    eNovo: true
                })}
            >
                Novo Dia não Letivo
            </Button>
            {isLoading ?
                <Grid
                    container
                    justifyContent='center'
                >
                    <Loading description="Carregando Dias não Letivo" />
                </Grid>
                :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listDiaNaoLetivo.map((diaNaoLetivo) => (
                            <TableRow key={diaNaoLetivo.codigo}>
                                <TableCell>{dateFormat(diaNaoLetivo.data)}</TableCell>
                                <TableCell>{diaNaoLetivo.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => setDiaNaoLetivo(diaNaoLetivo)}
                                    >
                                        Selecionar
                                    </Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }

        </Grid>
    )
}

export default DiaNaoLetivoTable;