import {
    ADD_EVENTOS, 
    UPDATE_EVENTOS, 
    SET_EVENTOS, 
    REMOVE_EVENTOS,
    SET_EVENTO, 
    OPEN_SEARCH_EVENTO, 
    CLOSE_SEARCH_EVENTO, 
    SET_QUANT_APROVACAO_EVENTO,
    DECREASE_QUANT_APROVACAO_EVENTO,
    INCREASE_QUANT_APROVACAO_EVENTO
} from './actionTypes'

export function addEventos (evento) {
    return { type: ADD_EVENTOS, evento }
}

export function updateEventos(evento){
    return {type : UPDATE_EVENTOS, evento}
}

export function setEventos(eventos){
    return {type : SET_EVENTOS, eventos}
}

export function removeEventos(index){
    return {type : REMOVE_EVENTOS, index}
}

export function setEvento(evento){
    return {type : SET_EVENTO, evento}
}

export function openSearchEvento(){
    return {type : OPEN_SEARCH_EVENTO}
}

export function closeSearchEvento(){
    return {type : CLOSE_SEARCH_EVENTO}
}

export function setQuantAprovacaoEvento(quantAprovacao) {
    return { type: SET_QUANT_APROVACAO_EVENTO, quantAprovacao }
}

export function decreaseQuantAprovacaoEvento() {
    return { type: DECREASE_QUANT_APROVACAO_EVENTO }
}
export function increaseQuantAprovacaoEvento() {
    return { type: INCREASE_QUANT_APROVACAO_EVENTO }
}