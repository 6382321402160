import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PAAlunoTable from './PAAlunoTable';
import PAResumoTable from './PAResumoTable';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
    paper: {

        padding: '20px',
        backgroundColor: '##fff',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    tabMenu: {
        marginTop: '20px'
    }
});

export default function PAAlunoContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const [menuShow, setMenuShow] = useState(0);


    const handleChange = (event, newValue) => {
        setMenuShow(newValue);
    };

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h5">
                        Presenças
                    </Typography>
                </Grid>
            </Grid>
            {tdp ?
                <>
                    <TurmaDisciplinaProfessorProfileRedux />
                    <Grid className={classes.tabMenu} container justifyContent="center">
                        <Grid item xs={6}>
                            <Paper style={{
                                backgroundColor: 'rgb(146 89 167)'
                            }}>
                                <Tabs
                                    value={menuShow}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab style={{ color: 'white' }} label="Tabela" />
                                    <Tab style={{ color: 'white' }} label="Resumo" />
                                </Tabs>
                            </Paper>
                        </Grid>
                    </Grid>

                    {menuShow === 0 ? <PAAlunoTable /> : ''}
                    {menuShow === 1 ? <PAResumoTable /> : ''}

                </> :
                <TDPTableSelectRedux />
            }
        </Container>
    );
}