import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'cardapio-semana/';

const findByCardapioCodigo = (codCardapio) => {
  const url = SERVER_URL + controller + codCardapio;
  return fetchGetAuth(url);
}

const findByCardapioAtivo = (cardapioAtivo) => {
  const url = SERVER_URL + controller + 'query?cardapio-ativo=' + cardapioAtivo;
  return fetchGetAuth(url);
}

const save = (integralPresenca) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, integralPresenca);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuthReturn(url);
}


export {
  findByCardapioCodigo,
  save,
  remove, 
  findByCardapioAtivo
}