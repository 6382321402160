import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function DisciplinaTurmaTable({ tdps, setTdp }) {
    const classes = useStyles();

    return (
        <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Capacidade Máxima</TableCell>
                    <TableCell>É Eletiva</TableCell>
                    <TableCell>É Projeto</TableCell>
                    <TableCell>Escolha Aluno</TableCell>
                    <TableCell>Carga Horária</TableCell>
                    <TableCell>Selecionar</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {tdps && tdps.map((tdp) => (
                    <TableRow key={tdp.disciplina.codDisciplina}>
                        <TableCell>{tdp.disciplina.descricao}</TableCell>
                        <TableCell>{tdp.capacidadeMaxima ? tdp.capacidadeMaxima : '--'}</TableCell>
                        <TableCell>
                            {tdp.eEletiva ?
                                <CheckCircle style={{ color: green[500] }} /> :
                                <Cancel style={{ color: red[500] }} />}
                        </TableCell>
                        <TableCell>
                            {tdp.eProjeto ?
                                <CheckCircle style={{ color: green[500] }} /> :
                                <Cancel style={{ color: red[500] }} />}
                        </TableCell>
                        <TableCell>
                            {tdp.escolhaAluno ?
                                <CheckCircle style={{ color: green[500] }} /> :
                                <Cancel style={{ color: red[500] }} />}
                        </TableCell>
                        <TableCell>{tdp.cargaHoraria ? tdp.cargaHoraria : '--'}</TableCell>
                        <TableCell>
                            <Button
                                variant='outlined'
                                onClick={() => setTdp(tdp)}
                            >
                                Selecionar
                            </Button>
                        </TableCell>

                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}