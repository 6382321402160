import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import Container from '../../UI/Container';
import { useEffect } from 'react';
import CatracaRegistroUsuarioList from './CatracaRegistroUsuarioList';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RESPONSAVEL } from '../../Usuario/Perfil';
import TextFieldDate from '../../UI/TextFieldDate';
import { dateFormatMoment } from '../../../util/DateUtil';
import Button from '../../UI/Button';
import { page, pageAndData } from '../api-catraca-registro';

const useStyles = makeStyles({
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    divBorderFiltro: {
        border: '1px solid black',
        padding: '20px',
        margin: '20px',
        borderRadius: '20px',
    }
});

export default function CatracaRegistroUsuarioContainer() {
    const classes = useStyles();
    const [listCR, setListCR] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0

    })
    const [isLoading, setIsLoading] = useState(false);
    const porAlunoKey = "porAluno";
    const [filtro, setFiltro] = useState(porAlunoKey);
    const porAlunoEDataKey = "porAlunoEData";
    const user = useSelector(state => state.usuario);
    const [dateSelected, setDateSelected] = useState(new Date());

    useEffect(() => {
        fetchData(0);
        // eslint-disable-next-line
    }, [filtro, user.arAlunoSelecionado]);


    const fetchData = (newPage) => {
        if (filtro === porAlunoKey) {
            setIsLoading(true);
            page(newPage).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListCR(data);
                }
                setIsLoading(false);
            })
        } else if (filtro === porAlunoEDataKey) {
            if (dateSelected === null) {
                errorMessage("Data De não preenchida.");
                return;
            }

            setIsLoading(true);
            pageAndData(newPage, dateSelected).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListCR(data);
                }
                setIsLoading(false);
            })
        }
    }

    const handleChangePage = (event, newPage) => {
        fetchData(newPage);
    };

    const handleChangeFiltro = (event) => {
        setFiltro(event.target.value);
    }

    const handleDataChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDateSelected(data)
    }



    return (
        <Container component={Paper}>

            <Grid container spacing={3} direction='column'>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Monitor de Acesso
                    </Typography>
                </Grid>
                {user.perfil === RESPONSAVEL &&
                    <Grid item xs={8}>
                        <div className={classes.divBorderFiltro}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Filtro</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={filtro} onChange={handleChangeFiltro}>
                                    <FormControlLabel value={porAlunoKey} control={<Radio />} label="Por Aluno" />
                                    <FormControlLabel value={porAlunoEDataKey} control={<Radio />} label="Por Aluno e Data" />
                                </RadioGroup>
                            </FormControl>
                            {filtro === porAlunoEDataKey &&
                                <Grid container >
                                    <Grid item xs={5}>
                                        <TextFieldDate
                                            label="Data"
                                            value={dateSelected ? dateFormatMoment(dateSelected) : null}
                                            onChange={handleDataChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => fetchData(0)}
                                            color="primary">
                                            Aplicar Datas
                                        </Button>
                                    </Grid>
                                </Grid>
                            }



                        </div>
                    </Grid>
                }

                <Grid item>
                    <CatracaRegistroUsuarioList
                        listCR={listCR}
                        isLoading={isLoading}
                        handleChangePage={handleChangePage}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}