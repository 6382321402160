import React from 'react';
import TextFieldDate from '../../UI/TextFieldDate'
import TextField from '../../UI/TextField'
import Button from '../../UI/Button';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { dateFormatMoment } from '../../../util/DateUtil';

const useStyles = makeStyles({

    container: {
        padding: '10px',
    },
    closeIcon: {
        cursor: 'pointer',
    },
});

const DiaLetivoExtraForm = ({
    diaLetivoExtra,
    setDiaLetivoExtra,
    onSubmit,
    onRemove,
    isLoadingAction
}) => {
    const classes = useStyles();

    const handleDateChange = (date) => {
        console.log('handleDateChange');
        console.log(date);
        date.setHours(0, 0, 0, 0);
        console.log(date);
        setDiaLetivoExtra({
            ...diaLetivoExtra,
            data: date
        })
    }

    const handleChange = (event) => {
        setDiaLetivoExtra({
            ...diaLetivoExtra,
            [event.target.name]: event.target.value
        })
    }

    return (
        <Grid
            container
            component={Paper}
            spacing={2}
            className={classes.container}
            justifyContent='center'
        >
            <Grid item xs={12}>
                <CloseIcon
                    onClick={() => setDiaLetivoExtra(null)}
                    className={classes.closeIcon}
                    fontSize='small'
                />
                <form onSubmit={onSubmit}>
                    {console.log(diaLetivoExtra.data)}
                    <TextFieldDate
                        value={diaLetivoExtra.data ? dateFormatMoment(diaLetivoExtra.data) : null}
                        onChange={handleDateChange}
                        label='Data'
                    />
                    <TextField
                        value={diaLetivoExtra.descricao}
                        onChange={handleChange}
                        name='descricao'
                        label='Descrição'
                    />
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={3}
                        >
                            <Button
                                type='submit'
                                disabled={isLoadingAction}
                            >
                                Salvar
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <Button
                                onClick={onRemove}
                                disabled={isLoadingAction}
                            >
                                Remover
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}

export default DiaLetivoExtraForm;