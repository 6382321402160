import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom'
import './App.css';
import MenuRouter from './MenuRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({

  palette: {
    primary: {
      main: '#6a1b9a',
    },
    secondary: {
      main: '#283593',
    },
  }
});

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none', // removes uppercase transformation
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: 12
    },
  },
  MuiSelect: {
    root: {
      fontSize: 12
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: 12
    },
  },
  MuiTableCell: {
    root: {
      fontSize: 12,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: 12,
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 12,
    },
  },




};

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastContainer autoClose={3000} />
          <MenuRouter />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
