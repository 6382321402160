import { Button, Container, Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { setAlunoTurma as setAlunoTurmaRedux } from "./actions/actions";
import AlunoTurmaForm from "./AlunoTurmaForm";
import { useState } from "react";
import AlunoTurmaFormList from "./AlunoTurmaFormList";

const useStyles = makeStyles({
  paper: {
    marginTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    marginTop: "20px",
    cursor: "pointer",
  },
});

const AlunoTurmaFormContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const porRegistroKey = "por-registro";
  const porListaKey = "por-lista";
  const [menu, setMenu] = useState(porRegistroKey);

  return (
    <Container className={classes.paper} component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <CloseIcon
            fontSize="small"
            onClick={() => dispatch(setAlunoTurmaRedux(null))}
            className={classes.closeIcon}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Button
                onClick={() => setMenu(porRegistroKey)}
                variant="outlined"
              >
                Por Registro
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => setMenu(porListaKey)}>
                Por Lista
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {menu === porRegistroKey ? (
            <AlunoTurmaForm />
          ) : (
            <AlunoTurmaFormList />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AlunoTurmaFormContainer;
