import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../../util/FetchUtil';

const controller = 'integral-presenca-aluno/';

const findByIntegralPresencaCodigo = (codIntegralPresenca) => {
  const url = SERVER_URL + controller + codIntegralPresenca;
  return fetchGetAuth(url);
}

const save = (presencaAluno) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, presencaAluno);
}

const findByIntegralProfessorCodigo = (codIntegralProfessor, page) => {
  const url = SERVER_URL + controller + 'aluno/' + codIntegralProfessor + '?page=' + page;
  return fetchGetAuth(url);
}

export {
  findByIntegralPresencaCodigo,
  save,
  findByIntegralProfessorCodigo
}