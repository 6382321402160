import React from 'react';
import { Switch as SwitchMaterial } from '@material-ui/core';

const Switch = (props) => {
    const { handleSwitch, value, name } = props
    return (
        <SwitchMaterial
            checked={value ? true : false}
            onChange={() => handleSwitch(name, !value)}
            value={value ? true : false}
            {...props}
        />
    )
}

export default Switch;