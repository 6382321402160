import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Container from '../../../UI/Container';
import { findByTurmaAnoLetivo } from '../../Professor/api-integral-professor';
import { findByIntegralProfessorCodigo } from '../../PresencaAluno/api-integral-presenca-aluno';
import Loading from '../../../UI/Loading';
import IntegralPresencaAlunoUserTableSelect from '../../PresencaAluno/Aluno/IntegralPresencaAlunoUserTableSelect';
import IntegralProfessorTable from '../../Professor/IntegralProfessorTable';
import { findByPresencaCurrentUser } from '../api-integral-rotina';
import IntegralRotinaAlunoForm from './IntegralRotinaAlunoForm';

const useStyles = makeStyles({

});

const IntegralRotinaAlunoContainer = () => {
    const classes = useStyles();
    const [integralProfessor, setIntegralProfessor] = useState(null);
    const [listaIP, setListaIP] = useState([]);
    const [isLoadingIP, setIsLoadingIP] = useState(true);
    const [isLoadingPA, setIsLoadingPA] = useState(true);
    const [isLoadingR, setIsLoadingR] = useState(true);
    const [listaPresencaAluno, setListaPresencaAluno] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    })
    const [presencaAluno, setPresencaAluno] = useState(null);
    const [integralRotina, setIntegralRotina] = useState(null);


    useEffect(() => {
        findByTurmaAnoLetivo().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaIP(data);
            }
            setIsLoadingIP(false);
        })
    }, [])

    useEffect(() => {
        if (integralProfessor) {
            setIsLoadingPA(true);
            findByIntegralProfessorCodigo(integralProfessor.codigo, 0).then((data) => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setListaPresencaAluno(data);
                    setIsLoadingPA(false);
                }
            })
        }

    }, [integralProfessor]);

    useEffect(() => {
        if (presencaAluno) {
            setIsLoadingR(true);
            findByPresencaCurrentUser(presencaAluno.integralPresenca.codigo).then(data => {
                if (!data || data.error) {
                    console.log(data);
                } else {
                    setIntegralRotina(data);
                }
                console.log(data);
                setIsLoadingR(false);
            })
        }
    }, [presencaAluno]);

    const handleChangePage = (event, newPage) => {
        setIsLoadingPA(true);
        findByIntegralProfessorCodigo(integralProfessor.codigo, newPage).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaPresencaAluno(data);
                setIsLoadingPA(false);
            }
        })
    };

    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Rotina Integral
            </Typography>

            {isLoadingIP ? <Loading description='Carregando Integral Professor...' /> :
                <>
                    {integralProfessor ?
                        <>
                            {isLoadingPA ? <Loading description="Carregando Integral Presença Aluno" /> :
                                <>
                                    {presencaAluno ?
                                        <>
                                            {isLoadingR ? <Loading description='Carregando Integral Rotina...' /> :
                                                <>
                                                    {integralRotina ?
                                                        <IntegralRotinaAlunoForm
                                                            integralRotina={integralRotina}
                                                            setPresencaAluno={setPresencaAluno}
                                                        /> : ''
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <IntegralPresencaAlunoUserTableSelect
                                            setPresencaAluno={setPresencaAluno}
                                            listaPresencaAluno={listaPresencaAluno}
                                            handleChangePage={handleChangePage}
                                        />
                                    }
                                </>
                            }

                        </>

                        :
                        <IntegralProfessorTable
                            listaIP={listaIP}
                            setIntegralProfessor={setIntegralProfessor}
                        />
                    }
                </>
            }



        </Container>
    );
}

export default IntegralRotinaAlunoContainer;