import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth
} from '../../util/FetchUtil';

const controller = 'conceito/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}


export {
  findAll, 
}