import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findByCurrentUser } from './api-historico-lancamento';
import { setHistoricoLancamento, setHistoricosLancamentos, removeHistoricosLancamentos } from './actions/actions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import { remove } from './api-historico-lancamento';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
});

const HistoricoLancamentoTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const historicosLancamentos = useSelector(state => state.historicosLancamentos);

    useEffect(() => {
        findByCurrentUser().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                dispatch(setHistoricosLancamentos(data));
            }
        })
    }, [dispatch]);

    const handleDelete = (hl) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este histórico de lançamento?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(hl.codigo).then((data) => {
                            if (data) {
                                dispatch(removeHistoricosLancamentos(hl.codigo));
                                dispatch(setHistoricoLancamento(null));
                            } else {
                                handleDeleteError();
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'O Histórico de Lançamento não pode ser removido pois possui Lançamentos cadastradas com o mesmo. Remova todas os Lançamentos do Histórico cadastrados.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }


    return (
        <>
            <Button
                onClick={() => dispatch(setHistoricoLancamento({ eNovo: true }))}
                className={classes.novoRegistro}
            >
                Novo Histórico Lançamento
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowHeader}>Código</TableCell>
                        <TableCell className={classes.rowHeader}>Descrição</TableCell>
                        <TableCell className={classes.rowHeader}>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {historicosLancamentos.map((hl) => (
                        <TableRow key={hl.codigo}>
                            <TableCell>{hl.codHistoricoEscola}</TableCell>
                            <TableCell>{hl.descricao}</TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { dispatch(setHistoricoLancamento(hl)) }}
                                    color="secondary"
                                    style={{ marginRight: '10px' }} />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(hl) }} color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default HistoricoLancamentoTable;