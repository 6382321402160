import React from 'react'
import Button from '../../../UI/Button';
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import AlunoFindDialog from '../../../Aluno/AlunoFindDialog';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px'

    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export const RltFreqFindAluno = ({open, setOpen, setAluno, listAlunos, handleDelete}) => {

    const classes = useStyles();
    return (
        <>
            <AlunoFindDialog
                openSearch={open}
                setOpenSearch={setOpen}
                setAluno={setAluno}
            />
            <Button
                onClick={() => {
                    setOpen(true);
                }}
                variant="contained"
                className={classes.novoRegistro}
                color="primary"
            >
                Adicionar Aluno
            </Button>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Aluno</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listAlunos.map((aluno, index) => (
                        <TableRow key={aluno.codigo}>
                            <TableCell>{aluno.nome}</TableCell>
                            <TableCell>
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(aluno.codigo) }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            
        </>
    )
}
