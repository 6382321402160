import {
    ADD_PRESENCAS, 
    UPDATE_PRESENCAS, 
    SET_PRESENCAS, 
    REMOVE_PRESENCAS,
    SET_PRESENCA, 
    SET_PRESENCA_NULL
} from './actionTypes'

export function addPresencas (presenca) {
    return { type: ADD_PRESENCAS, presenca }
}

export function updatePresencas(presenca){
    return {type : UPDATE_PRESENCAS, presenca}
}

export function setPresencas(presencas){
    return {type : SET_PRESENCAS, presencas}
}

export function removePresencas(index){
    return {type : REMOVE_PRESENCAS, index}
}

export function setPresenca(presenca){
    return {type : SET_PRESENCA, presenca}
}

export function setPresencaNull(){
    return {type : SET_PRESENCA_NULL}
}