import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'aluno-responsavel/';

const findByUsuario = (codUsuario) => {
  const url = SERVER_URL + controller + 'usuario-codigo/' + codUsuario;
  return fetchGetAuth(url);
}

const remove = (codAlunoResponsavel) => {
  const url = SERVER_URL + controller + 'remove/' + codAlunoResponsavel;
  return fetchRemoveAuth(url);
}

const save = (alunoResponsavel) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, alunoResponsavel);
}

const findByTurmaCodigo = (codTurma) => {
  const url = SERVER_URL + controller + 'query?turma=' + codTurma;
  return fetchGetAuth(url);

}

const findByAlunoCodigo = (codAluno) => {
  const url = SERVER_URL + controller + 'query?cod-aluno=' + codAluno;
  return fetchGetAuth(url);
}



export {
  findByUsuario,
  remove,
  save,
  findByTurmaCodigo,
  findByAlunoCodigo
}