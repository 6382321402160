import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { findByUsuario } from './api-usuario-perfil';
import { changePerfil } from './api-usuario';
import { setUsuario } from './actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { Grid } from '@material-ui/core';


export default function UsuarioPerfilTable() {
    const usuario = useSelector(state => state.usuario);
    const [usuarioPerfis, setUsuarioPerfis] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (usuario && usuario.id) {
            findByUsuario(usuario.id).then((data) => {
                if (data.error) {
                    errorMessage(data.message)
                } else {
                    setUsuarioPerfis(data);
                }
            });
        }

    }, [usuario]);


    const handleAtivar = (perfil) => {
        changePerfil(perfil).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setUsuario(data));
                toast.success("Perfil Ativo Alterado.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        });
    }

    return (
        <>
            {usuarioPerfis.length > 0 ?

                <Grid container spacing={2} direction='column'>
                    <Grid item>
                        <Typography variant="h5">
                            Alterar Perfil Ativo
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Perfil</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usuarioPerfis.map((up) => (
                                    <TableRow key={up.codigo}>
                                        <TableCell>{up.perfil}</TableCell>
                                        <TableCell>
                                            {usuario.perfil !== up.perfil ?
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleAtivar(up.perfil)}
                                                >
                                                    Ativar
                                                </Button>
                                                : 'Selecionado'}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                : ''}

        </>
    );
}