import {
    SET_INSTITUICAO,
    OPEN_INSTITUICAO,
    CLOSE_INSTITUICAO
} from './actionTypes'

export function setInstituicao(instituicao) {
    return { type: SET_INSTITUICAO, instituicao }
}


export function openInstituicao() {
    return { type: OPEN_INSTITUICAO }
}

export function closeInstituicao() {
    return { type: CLOSE_INSTITUICAO }
}