import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AlunoLivroTableAdd from './AlunoLivroTableAdd';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AlunoLivroFormAdd from './AlunoLivroFormAdd';
import { findByLivroCodLivroAndAlunoInstituicaoCodigo } from '../api-aluno-livro';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { save } from '../api-aluno-livro';
import AlunoLivroFindDialog from '../AlunoLivroFindDialog';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function AlunoLivroAddContainer(props) {
    const { livroAutor } = props.location.state;
    const usuario = useSelector(state => state.usuario);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSearchAlunoLivro, setOpenSearchAlunoLivro] = useState(false);
    const [alunoLivro, setAlunoLivro] = useState(null);
    const [alunosLivros, setAlunosLivros] = useState({
        content: [],
        size: 0,
        number: 0,
        totalElements: 0
    });
    const [loadingSave, setLoadingSave] = useState(false);


    useEffect(() => {
        if (livroAutor.livro.codLivro) {
            findByLivroCodLivroAndAlunoInstituicaoCodigo(livroAutor.livro.codLivro, usuario.instituicao.codigo, 0).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunosLivros(data)
                }
            })
        }

    }, [usuario, livroAutor]);

    const handleChangePage = (event, newPage) => {
        findByLivroCodLivroAndAlunoInstituicaoCodigo(livroAutor.livro.codLivro, usuario.instituicao.codigo, newPage).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setAlunosLivros(data)
            }
        })
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurarAlunoLivro = () => {
        handleMenuClose();
        setOpenSearchAlunoLivro(true);
    }

    const handleSaveAlunoLivro = (event) => {
        event.preventDefault();

        if (!alunoLivro.aluno) {
            toast.error("Selecione o Aluno.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        const eNovo = alunoLivro.eNovo;
        setLoadingSave(true);
        save(alunoLivro).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    setAlunosLivros((prevState) => ({
                        ...prevState,
                        content: [
                            ...prevState.content,
                            data
                        ]
                    }))
                } else {
                    setAlunosLivros((prevState) => ({
                        ...prevState,
                        content: prevState.content.map((content) => {
                            if (content.codigo === data.codigo) {
                                return data;
                            } else {
                                return content;
                            }
                        })
                    }))
                }
                setAlunoLivro(null);
            }
            setLoadingSave(false);

        })

    }

    return (
        <Container className={classes.paper} component={Paper}>
            <AlunoLivroFindDialog
                openSearchAlunoLivro={openSearchAlunoLivro}
                setOpenSearchAlunoLivro={setOpenSearchAlunoLivro}
                livro={livroAutor.livro}
                setAlunoLivro={setAlunoLivro}
            />
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Aluno Livro
                    </Typography>
                </Grid>
                <Grid style={{ flexBasis: '5%' }} item xs={3}>
                    <MenuIcon
                        fontSize='small'
                        className={classes.cursorLink}
                        onClick={(event) => handleMenuClick(event)}
                        color="secondary"
                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleProcurarAlunoLivro()}>
                            Procurar Aluno Livro
                        </MenuItem>
                    </Menu>
                </Grid>

            </Grid>



            {alunoLivro ?
                <AlunoLivroFormAdd
                    alunoLivro={alunoLivro}
                    setAlunoLivro={setAlunoLivro}
                    handleSaveAlunoLivro={handleSaveAlunoLivro}
                    loadingSave={loadingSave}
                /> :
                <AlunoLivroTableAdd
                    alunosLivros={alunosLivros}
                    handleChangePage={handleChangePage}
                    setAlunoLivro={setAlunoLivro}
                    livroAutor={livroAutor}
                />
            }


        </Container>
    );
}