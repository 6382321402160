import { Button, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
    formMensagem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    emojiOpenPicker: {
        width: '30px',
        marginRight: '10px',
        cursor: 'pointer'
    },
    btnAttachFile: {
        marginRight: '8px',
        marginLeft: '8px',
    }
})

const FormSendMessage = ({
    handleSend,
    handleEmojiShow,
    mensagem,
    handleChange,
    hiddenFileInput,
    handleChangeFile,
    isAttachingFile,
    handleAttachFile,
    isSending
}) => {

    const classes = useStyles();

    return (
        <div>
            <form
                className={classes.formMensagem}
                onSubmit={handleSend}
            >
                <img
                    onClick={handleEmojiShow}
                    className={classes.emojiOpenPicker}
                    src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                    alt=''
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    size='small'
                    fullWidth
                    multiline
                    label="Mensagem"
                    autoFocus
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                            e.preventDefault();
                            handleSend(e);
                        }
                    }}
                    value={mensagem}
                />

                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={hiddenFileInput}
                    onChange={handleChangeFile}
                />
                <div
                    className={classes.btnAttachFile}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        // size='small'
                        component="span"
                        className={classes.sendBtn}
                        disabled={isAttachingFile}
                        onClick={handleAttachFile}
                    >
                        <AttachFileIcon fontSize='small' />
                    </Button>
                </div>
                <div>
                    <Button
                        type="submit"
                        variant="contained"
                        // size='small'
                        color="secondary"
                        className={classes.sendBtn}
                        disabled={isSending}
                    >
                        <SendIcon fontSize='small' />
                    </Button>
                </div>
            </form>
        </div>
    );
}


export default FormSendMessage;