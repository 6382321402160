import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { updateCategorias, addCategorias, setCategoria as setCategoriaRedux } from './actions/actions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { save } from "./api-comunicado-categoria";

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 650,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 3),
    },
    buscarBtn: {
        margin: theme.spacing(3, 3, 3),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    }
}));

export default function ComunicadoForm() {
    const classes = useStyles();
    const categoriaRedux = useSelector(state => state.comunicadoCategoria);
    const [categoria, setCategoria] = useState(categoriaRedux);
    const dispatch = useDispatch();


    useEffect(() => {
        setCategoria(categoriaRedux);
    }, [categoriaRedux]);


    const handleChange = (event) => {
        setCategoria({
            ...categoria,
            [event.target.name]: event.target.value
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = categoria.eNovo;
        save(categoria).then(data => {
            if (data) {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addCategorias(data));
                } else {
                    dispatch(updateCategorias(data));
                }
                dispatch(setCategoriaRedux(data));
                setCategoria(data);
            }

        })
    }

    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />

            <Container className={classes.paper} component={Paper} >
                <CloseIcon onClick={() => dispatch(setCategoriaRedux(null))} className={classes.closeIcon} />
                <Typography className={classes.title} variant="h5">
                    Cadastro Categoria
                </Typography>
                <Grid item >
                    <form onSubmit={clickSubmit}>
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Descrição"
                            name="descricao"
                            onChange={handleChange}
                            inputProps={{ maxLength: 100 }}
                            value={categoria && categoria.descricao ? categoria.descricao : ''}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Salvar
                    </Button>
                    </form>
                </Grid>
            </Container>
        </div>
    );
}