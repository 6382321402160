import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth
} from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';
const controller = 'acompanhamento/';

const findByUsuarioCodigo = (codUsuario) => {
  const url = SERVER_URL + controller + 'professor/' + codUsuario + '/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const findByCurrentUser = () => {
  const url = SERVER_URL + controller + session.getAnoLetivo();
  return fetchGetAuth(url);
}

const sendEmailPresencaAtradas = () => {
  const url = SERVER_URL + controller + 'email/presenca/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

export {
  findByUsuarioCodigo,
  findByCurrentUser, 
  sendEmailPresencaAtradas
}