import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AlunoLivroTableAdd({ alunosLivros, handleChangePage, setAlunoLivro, livroAutor }) {
    const classes = useStyles();

    return (
        <div>
            <Button
                onClick={() => setAlunoLivro({
                    eNovo: true,
                    livro: livroAutor.livro
                })}
                variant="contained"
                color="primary"
                className={classes.novoRegistro}
            >
                Novo Aluno Livro
            </Button>

            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nome Aluno</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alunosLivros.content.map((alunoLivro) => (
                        alunoLivro && alunoLivro.codigo ?
                            <TableRow key={alunoLivro.codigo}>
                                <TableCell>{alunoLivro.aluno.nome}</TableCell>
                            </TableRow> : null
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={alunosLivros.size ? alunosLivros.size : 0}
                component="div"
                count={alunosLivros.totalElements}
                page={alunosLivros.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}