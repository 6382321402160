import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'avaliacao-secao/';

const findFirstByAvaliacaoCodigo = (codAvaliacao) => {
  const url = SERVER_URL + controller + codAvaliacao;
  return fetchGetAuth(url);
}

const findbyAvaliacaoCodigo = (codAvaliacao) => {
  const url = SERVER_URL + controller + 'all/' + codAvaliacao;
  return fetchGetAuth(url);
}



const create = (codAvaliacao) => {
  const url = SERVER_URL + controller + 'create/' +  codAvaliacao;
  return fetchGetAuth(url);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuth(url);
}

const save = (secaoConteudo) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, secaoConteudo);
}

export {
  findFirstByAvaliacaoCodigo,
  save,
  remove,
  create, 
  findbyAvaliacaoCodigo
}