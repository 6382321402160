import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Button from '../UI/Button';
import Loading from '../UI/Loading';
import { findAll } from './api-serie';


const SerieTableSelect = ({ setSerie }) => {
    const [series, setSeries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findAll().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setSeries(data)
            }
            setIsLoading(false);
        })
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <Typography variant="body1">
                    Selecione uma Série
                </Typography>
            </Grid>
            {isLoading ?
                <Grid container justifyContent='center'>
                    <Loading description='Carregando Séries' />
                </Grid>
                :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell >Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {series.map((serie) => (
                            <TableRow key={serie.codSerie}>
                                <TableCell>{serie.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => setSerie(serie)}
                                    >
                                        Selecionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }

        </Grid>
    )
}

export default SerieTableSelect;