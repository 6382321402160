import { SERVER_URL } from "../../../constants"
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from "../../../util/FetchUtil";
import session from '../../../SessionHandler/session-helper';

const controller = "cronograma-quant-aula-bimestre/"

export const generateCreateCronogramaDiaByTurma = (codTurma) => {
    const url = SERVER_URL + controller + "generate-create-cronograma-dia-turma/" + codTurma;
    return fetchGetAuth(url);
}

export const generateCreateCronogramaDiaByTdp = (codTdp) => {
    const url = SERVER_URL + controller + "generate-create-cronograma-dia-tdp/" + codTdp;
    return fetchGetAuth(url);
}

export const generateCreateCronogramaDiaByTdpAndDate = (codTdp, date) => {
    const url = SERVER_URL + controller + "generate-create-cronograma-dia-tdp-date/" + codTdp + "/" + date;
    return fetchGetAuth(url);
}

export const generateCreateCronogramaDia = () => {
    const url = SERVER_URL + controller + "generate-create-cronograma-dia/" + session.getAnoLetivo();
    return fetchGetAuth(url);
}

export const generateByCronogramaDia = (codTurma) => {
    const url = SERVER_URL + controller + "generate-by-cronograma-dia/" + codTurma;
    return fetchGetAuth(url);
}

export const generateByDia = (codTurma) => {
    const url = SERVER_URL + controller + "generate-by-dia/" + codTurma;
    return fetchGetAuth(url);
}

export const findByTdpCodigo = (codTdp) => {
    const url = SERVER_URL + controller + "tdp/" + codTdp;
    return fetchGetAuth(url);
}

export const findByTdpCodigoRegistered = (codTdp) => {
    const url = SERVER_URL + controller + "registered/tdp/" + codTdp;
    return fetchGetAuth(url);
}

export const deleteById = (codigo) => {
    const url = SERVER_URL + controller + codigo;
    return fetchRemoveAuthReturn(url);
}

export const save = (cqab) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, cqab);
}

