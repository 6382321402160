import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '../UI/TextField';
import { handleFileSizeException } from '../../util/ExceptionUtil';
import { getBase64 } from '../../util/Base64';
import { save } from './api-aluno-foto';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import InputImageByte from '../UI/InputImageByte';
import AlunoFotoList from './AlunoFotoList';

const useStyles = makeStyles({
    container: {
        backgroundColor: '#fff',
        margin: '5px',
        padding: '10px'
    }
})


const AlunoFotoInsert = () => {
    const classes = useStyles();
    const [anoLetivo, setAnoLetivo] = useState(new Date().getFullYear());
    const [listaAF, setListaAF] = useState([]);

    const onChangeAnoLetivo = (event) => {
        console.log(event);
        setAnoLetivo(event.target.value);
    }

    const handleChangeImage = e => {
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i]
            if (file.size > 10000000) {
                handleFileSizeException();
                return;
            }
            getBase64(file)
                .then(result => {
                    let persist = {
                        foto: result,
                        anoLetivo: anoLetivo,
                        fileType: file.type,
                        fileName: file.name,
                        fileSize: file.size
                    }
                    save(persist).then((data) => {
                        if (data.error) {
                            errorMessage(data.message);
                        } else {
                            setListaAF((prevState) => ([
                                ...prevState,
                                data
                            ]));
                        }
                    })
                });

        }


    };

    return (
        <Grid
            className={classes.container}
            container
            direction='column'
            spacing={3}
        >
            <Typography variant="h6">
                Aluno Foto
            </Typography>

            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
            >
                <TextField
                    label="Ano Letivo"
                    onChange={onChangeAnoLetivo}
                    value={anoLetivo}
                />
                <InputImageByte
                    image={listaAF.length > 0 ? listaAF[listaAF.length - 1] : null}
                    handleChangeImage={handleChangeImage}
                    multiple
                />
            </Grid>
            <AlunoFotoList
                listaAF={listaAF}
            />

        </Grid>
    )
}

export default AlunoFotoInsert;