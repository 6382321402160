import React, { useState, useEffect } from 'react';
import { findByCodConteudoAndCodTdp } from "./api-resposta";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import { saveCorrection } from "./api-resposta";
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import { errorMessage } from '../../../../util/ConfirmAlertUtil';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
    },
    buttonPorcentageSelected: {
        backgroundColor: 'cornflowerblue'
    },
    paragraph: {
        fontSize: '20px'
    }
});

export default function RespostaList({ conteudo }) {
    const tdp = useSelector(state => state.tdp);
    const classes = useStyles();
    const [respostas, setRespostas] = useState([]);

    useEffect(() => {
        if (conteudo && tdp) {
            findByCodConteudoAndCodTdp(conteudo.codigo, tdp.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setRespostas(data);
                }
            })
        }
    }, [conteudo, tdp]);

    const handleSavePorcentage = (resposta, porcentage) => {
        let respostaSave = {
            ...resposta,
            porcentagemAcerto: porcentage
        }
        save(respostaSave);

    }

    const save = (respostaSave) => {
        saveCorrection(respostaSave).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setRespostas(
                    respostas.map((resposta) => {
                        if (resposta.codigo === data.codigo) {
                            return data;
                        } else {
                            return resposta;
                        }
                    })
                )
                toast.success("Resposta salva com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        })
    }

    const handleDevolutivaChange = (event, respostaSave) => {
        setRespostas(respostas.map(r => {
            if (r.codigo === respostaSave.codigo) {
                return {
                    ...respostaSave,
                    devolutiva: event.target.value
                }
            } else {
                return r
            }

        }))
    }



    return (
        <>
            <Container component={Paper} maxWidth="xl" className={classes.paper}>
                <p className={classes.paragraph}>Pergunta:</p>
                {parse(conteudo.questao.descricao)}
                <p className={classes.paragraph}>Resposta:</p>
                {parse(conteudo.questao.resposta)}
            </Container>
            {respostas.map((r, index) => (
                <Container key={index} component={Paper} maxWidth="xl" className={classes.paper}>
                    <p className={classes.paragraph}>Aluno: {r.aluno.nome}</p>
                    <p className={classes.paragraph}>Resposta: {r.resposta ? r.resposta : <span style={{ color: 'red' }}>Sem resposta</span>}</p>
                    <Button
                        onClick={() => handleSavePorcentage(r, 0)}
                        variant="outlined"
                        color="primary"
                        className={r.porcentagemAcerto === 0 ? classes.buttonPorcentageSelected : ''}
                    >
                        0%
                    </Button>
                    <Button
                        onClick={() => handleSavePorcentage(r, 25)}
                        variant="outlined"
                        color="primary"
                        className={r.porcentagemAcerto === 25 ? classes.buttonPorcentageSelected : ''}
                    >
                        25%
                    </Button>
                    <Button
                        onClick={() => handleSavePorcentage(r, 50)}
                        variant="outlined"
                        color="primary"
                        className={r.porcentagemAcerto === 50 ? classes.buttonPorcentageSelected : ''}
                    >
                        50%
                    </Button>
                    <Button
                        onClick={() => handleSavePorcentage(r, 75)}
                        variant="outlined"
                        color="primary"
                        className={r.porcentagemAcerto === 75 ? classes.buttonPorcentageSelected : ''}
                    >
                        75%
                    </Button>
                    <Button
                        onClick={() => handleSavePorcentage(r, 100)}
                        variant="outlined"
                        color="primary"
                        className={r.porcentagemAcerto === 100 ? classes.buttonPorcentageSelected : ''}
                    >
                        100%
                    </Button>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Devolutiva"
                        name="devolutiva"
                        onChange={(event) => handleDevolutivaChange(event, r)}
                        value={r && r.devolutiva ? r.devolutiva : ''}
                    />
                    <Button
                        variant="contained"
                        onClick={() => save(r)}
                        color="primary"
                        className={classes.sendAnswer}>
                        Enviar
                    </Button>
                </Container>
            ))}

        </>
    )
}