import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dateFormat } from '../../../util/DateUtil';
import { findByAluno } from '../api-aluno-turma';
import Loading from '../../UI/Loading';
import { errorMessage } from '../../../util/ConfirmAlertUtil';


const useStyles = makeStyles({
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    row: {
        fontSize: 12,
    }
});


export default function AlunoTurmaTable({ aluno }) {
    const classes = useStyles();
    const [alunoTurmas, setAlunoTurmas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (aluno) {
            setIsLoading(true);
            findByAluno(aluno.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunoTurmas(data);
                    setIsLoading(false);
                }
            })
        }
    }, [aluno]);




    return (
        <>
            {isLoading ? <Loading description='Carregando Turmas...' /> :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.rowHeader}>Ano Letivo</TableCell>
                            <TableCell className={classes.rowHeader}>Turma</TableCell>
                            <TableCell className={classes.rowHeader}>Data Entrada</TableCell>
                            <TableCell className={classes.rowHeader}>Data Saída</TableCell>
                            <TableCell className={classes.rowHeader}>Situação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alunoTurmas.map((at) => (
                            <TableRow key={at.codigo}>
                                <TableCell className={classes.row}>{at.turma.anoLetivo}</TableCell>
                                <TableCell className={classes.row}>{at.turma.descricao}</TableCell>
                                <TableCell className={classes.row}>{dateFormat(at.dataEntrada)}</TableCell>
                                <TableCell className={classes.row}>{at.dataSaida ? dateFormat(at.dataSaida) : ''}</TableCell>
                                <TableCell className={classes.row}>{at.ativo ? "Ativo" : 'Desativado'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </>
    );
}