import { combineReducers } from 'redux'
import { tdps, tdp } from './components/TDP/actions/reducers';
import { turma, turmas, openSearchTurmas } from './components/Turma/actions/reducers';
import { presenca, presencas } from './components/Presenca/actions/reducers';
import { atividade, atividades } from './components/Atividade/actions/reducers';
import { presencaAlunos, presencaAluno } from './components/PresencaAluno/actions/reducers';
import { atividadeAlunos, atividadeAluno } from './components/AtividadeAluno/actions/reducers';
import { notaBimestre, notasBimestre } from './components/NotaBimestre/actions/reducers';
import { conceitoAluno, conceitoAlunos } from './components/ConceitoAluno/actions/reducers';
import { notaBimestreTipo } from './components/NotaBimestreTipo/actions/reducers';
import { alunoTurma, alunosTurma, openSearchAlunoTurma } from './components/AlunoTurma/actions/reducers';
import { observacaoAluno, observacoesAluno } from './components/ObservacaoAluno/actions/reducers';
import { observacaoTurma, observacoesTurma } from './components/ObservacaoTurma/actions/reducers';
import { usuario, usuarioSearch, openSearchUsuario, usuarios } from './components/Usuario/actions/reducers';
import { logout } from './auth/actions/reducers';
import { alunos, aluno, openSearch } from './components/Aluno/actions/reducers';
import { livros, livro, openSearchLivro } from './components/Livro/actions/reducers';
import { livrosInstituicao, livroInstituicao, openSearchLivroInstituicao } from './components/Livro/Instituicao/actions/reducers';
import { openSearchInstituicao, instituicao } from './components/Instituicao/actions/reducers';
import { livrosAutor, livroAutor, openSearchLivroAutor } from './components/Livro/Autor/actions/reducers';
import { openSearchComunicado, comunicado, comunicados, quantAprovacaoComunicado } from "./components/Comunicado/Comunicado/actions/reducers";
import { comunicadoCategoria, comunicadoCategorias } from "./components/Comunicado/ComunicadoCategoria/actions/reducers";
import { openSearchEvento, evento, eventos, quantAprovacaoEvento } from "./components/Evento/Evento/actions/reducers";
import { simpleDialogMessage } from "./util/SimpleDialogMessage/actions/reducers";
import { curso, cursos } from "./components/Curso/actions/reducers";
import { serie, series } from "./components/Serie/actions/reducers";
import { progressLog } from "./shared/components/ProgressLog/actions/reducers";
import { conteudos } from './components/Capitulo/Conteudo/actions/reducers';
import { alunoFrequencia, alunosFrequencia } from './components/AlunoFrequencia/actions/reducers';
import { avaliacao, avaliacoes } from './components/Avaliacao/actions/reducers';
import { secao, secoes } from './components/Avaliacao/Secao/actions/reducers';
import { avaliacaoAluno, listaAvaliacaoAluno } from './components/Avaliacao/Aluno/actions/reducers';
import { cronograma, cronogramas } from './components/Cronograma/actions/reducers';
import { cronogramaAula, cronogramasAula, openSearchCronogramaAula } from './components/Cronograma/Aula/actions/reducers';
import { capitulo, capitulos } from './components/Capitulo/actions/reducers';
import { alunoLivro, alunosLivros, openSearchAlunoLivro } from './components/AlunoLivro/actions/reducers';
import {
  historicoLancamento,
  historicosLancamentos,
  openSearchHistoricoLancamento
} from './components/Financeiro/HistoricoLancamento/actions/reducers';
import { lancamento, lancamentos, openSearchLancamento } from './components/Financeiro/actions/reducers';
import { quantNaoLidas } from './components/Mensagem/SalaParticipante/actions/reducers';
import { isAllMensagemTable, salas } from './components/Mensagem/MensagemTable/actions/reducers';

import { PURGE } from "redux-persist";

function user(state = {}, action) {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        username: action.user.email,
        perfil: action.user.perfil
      }

    case 'REMOVE_USER':
      return state.user = {}

    case PURGE:
      return {};

    default:
      return state
  }
}

function disciplinas(state = [], action) {
  switch (action.type) {
    case ('SET_DISCIPLINAS'):
      return [
        ...action.disciplinas
      ]
    case ('UPDATE_DISCIPLINAS'):
      const disciplina = action.disciplina;
      return state.map((d) => {
        if (disciplina.codDisciplina === d.codDisciplina) {
          return disciplina;
        } else {
          return d;
        }
      });

    case ('INSERT_DISCIPLINAS'):
      return [
        ...state,
        action.disciplina
      ];

    case ('REMOVE_DISCIPLINAS'):
      return state.filter((d) => d.codDisciplina !== action.codDisciplina)

    case PURGE:
      return [];

    default:
      return state
  }
}

function disciplina(state = null, action) {
  switch (action.type) {
    case ('SET_DISCIPLINA'):
      return action.disciplina


    case PURGE:
      return null;

    default:
      return state;
  }
}

const appReducer = combineReducers({
  user,
  disciplinas,
  disciplina,
  tdps,
  tdp,
  turma,
  turmas,
  presenca,
  presencas,
  atividade,
  atividades,
  presencaAlunos,
  presencaAluno,
  atividadeAlunos,
  atividadeAluno,
  notaBimestreTipo,
  notaBimestre,
  notasBimestre,
  conceitoAluno,
  conceitoAlunos,
  alunoTurma,
  alunosTurma,
  observacoesAluno,
  observacaoAluno,
  observacaoTurma,
  observacoesTurma,
  usuario,
  usuarioSearch,
  usuarios,
  logout,
  alunos,
  aluno,
  openSearch,
  openSearchUsuario,
  livros,
  livro,
  openSearchLivro,
  livrosAutor,
  livroAutor,
  openSearchLivroAutor,
  openSearchAlunoTurma,
  openSearchComunicado,
  comunicado,
  quantAprovacaoComunicado,
  comunicados,
  comunicadoCategorias,
  comunicadoCategoria,
  openSearchTurmas,
  simpleDialogMessage,
  curso,
  cursos,
  series,
  serie,
  progressLog,
  conteudos,
  alunoFrequencia,
  alunosFrequencia,
  evento,
  eventos,
  openSearchEvento,
  quantAprovacaoEvento,
  avaliacao,
  avaliacoes,
  secao,
  secoes,
  avaliacaoAluno,
  listaAvaliacaoAluno,
  openSearchInstituicao,
  instituicao,
  cronograma,
  cronogramas,
  cronogramaAula,
  cronogramasAula,
  openSearchCronogramaAula,
  livrosInstituicao,
  livroInstituicao,
  openSearchLivroInstituicao,
  capitulo,
  capitulos,
  alunoLivro,
  alunosLivros,
  openSearchAlunoLivro,
  historicoLancamento,
  historicosLancamentos,
  openSearchHistoricoLancamento,
  lancamento,
  lancamentos,
  openSearchLancamento,
  quantNaoLidas,
  isAllMensagemTable,
  salas
})
export default appReducer