import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByAnoLetivo, remove } from './api-turma';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { insertByTurma } from '../NotaBimestre/api-nota-bimestre';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setTurma, setTurmas, removeTurmas } from "./actions/actions";

const useStyles = makeStyles({
    novoRegistro: {
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    actionIcon: {
        cursor: 'pointer',
        marginRight: '10px'
    }
});


export default function TurmaTable() {
    const classes = useStyles();
    const turmas = useSelector(state => state.turmas);
    const [anchorEl, setAnchorEl] = useState(null);
    const [codTurmaSelected, setCodTurmaSelected] = useState(null);
    const user = useSelector(state => state.usuario);
    const dispatch = useDispatch();

    useEffect(() => {

        findByAnoLetivo().then((data) => {
            if (data) {
                dispatch(setTurmas(data));
            }
        }).catch(err => {
            console.log(err);
        })
    }, [dispatch]);

    const handleMenuClick = (event, codTurma) => {
        setAnchorEl(event.currentTarget);
        setCodTurmaSelected(codTurma);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = (turma) => {
        dispatch(setTurma(turma));
    }

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(turmas[index].codigo).then((data) => {
                            if (data) {
                                dispatch(removeTurmas(turmas[index].codigo));
                            } else {
                                handleDeleteError();
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'A Turma não pode ser removido pois possui alunos cadastradas. Remova todos os alunos cadastradas com esta Turma.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleAdicionarNB = () => {
        handleMenuClose();
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja adicionar todas as Notas Bimestre para esta turma? Após confirmado esta ação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        insertByTurma(codTurmaSelected).then((data) => {
                            if (data) {
                                toast.success("Salvo com sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    return (
        <div>
            <Button
                onClick={() => dispatch(setTurma({ eNovo: true }))}
                variant="contained"
                color="primary"
                className={classes.novoRegistro}>
                Nova Turma
            </Button>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Curso</TableCell>
                        <TableCell>Série</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Modelo</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {turmas.map((turma, index) => (
                        <TableRow key={turma.codigo}>
                            <TableCell>{turma.curso.descricao}</TableCell>
                            <TableCell>{turma.serie.descricao}</TableCell>
                            <TableCell>{turma.descricao}</TableCell>
                            <TableCell>{turma.model ? turma.model : ''}</TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    onClick={() => handleEdit(turma)}
                                    color="secondary"
                                    className={classes.actionIcon}
                                />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    color="secondary"
                                    style={{ marginRight: '10px' }}
                                />
                                {user.perfil === "ADMIN" || user.perfil === "COORDERNADOR" ?
                                    <>
                                        <MenuIcon
                                            fontSize='small'
                                            className={classes.cursorLink}
                                            onClick={(event) => handleMenuClick(event, turma.codigo)}
                                            color="secondary"

                                        />
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem
                                                onClick={handleAdicionarNB}
                                            >
                                                Adicionar Nota Bimestre por Turma
                                            </MenuItem>
                                        </Menu>
                                    </> : ''}

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
}