import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { findByAnoLetivo } from './api-turma';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setTurmas, setTurma } from './actions/actions';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        marginBottom: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function TurmaTableSelectRedux() {
    const classes = useStyles();
    const turmas = useSelector(state => state.turmas);
    const dispatch = useDispatch();

    useEffect(() => {
        findByAnoLetivo().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setTurmas(data));
            }
        }).catch(err => {
            console.log(err);
        })
    }, [dispatch]);

    return (
        <div>
            <Typography className={classes.title} variant="body1">
                Selecione uma turma
            </Typography>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ano Letivo</TableCell>
                        <TableCell>Curso</TableCell>
                        <TableCell>Série</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {turmas ? turmas.map((turma) => (
                        <TableRow key={turma.codigo}>
                            <TableCell>{turma.anoLetivo}</TableCell>
                            <TableCell>{turma.curso.descricao}</TableCell>
                            <TableCell>{turma.serie.descricao}</TableCell>
                            <TableCell>{turma.descricao}</TableCell>
                            <TableCell>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => dispatch(setTurma(turma))}
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    )) : ''}
                </TableBody>
            </Table>

        </div>
    );
}