import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../UI/Button';
import { toast } from 'react-toastify';
import { save, remove } from './api-cardapio';
import { confirmAlert } from 'react-confirm-alert';
import TextField from '../UI/TextField';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    buttonSave: {
        marginTop: '20px'
    },
    buttonRemove: {
        marginTop: '20px',
        marginLeft: '20px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },

    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});

const CardapioForm = ({ cardapio, setCardapio, setCardapios }) => {


    const [persist, setPersist] = useState(cardapio);
    const classes = useStyles();
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    useEffect(() => {
        setPersist(cardapio);
    }, [cardapio]);



    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const eNovo = persist.eNovo;
        save(persist).then(data => {
            if (!data || data.error) {
                console.log(data);

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    handleAddCardapio(data);
                }
                setCardapio(data);
            }
            setIsLoadingSave(false);
        })

    }

    const handleAddCardapio = (c) => {
        setCardapios(prevState => ([
            ...prevState,
            c
        ]))
    }

    const handleRemove = () => {

        confirmAlert({
            title: 'Alerta',
            message: "Deseja Remover este registro?",
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        remove(persist.codigo).then((data) => {
                            if (!data || data.error) {
                                console.log(data);
                            } else {
                                setCardapios((prevState) => ([
                                    prevState.filter((ps) => ps.codigo !== persist.codigo)
                                ]));
                                setCardapio(null);
                            }
                        })
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => { }
                }
            ]
        });

    }

    const handleChange = (event) => {
        setCardapio({
            ...cardapio,
            [event.target.name]: event.target.value
        })
    }

    return (
        <>
            <Container className={classes.container} component={Paper} maxWidth="sm">
                <CloseIcon
                    onClick={() => setCardapio(null)}
                    fontSize='small'
                    className={classes.closeIcon}
                />
                <form onSubmit={clickSubmit}>
                    <TextField
                        onChange={handleChange}
                        fullWidth
                        value={cardapio && cardapio.descricao ? cardapio.descricao : ''}
                        name='descricao'
                        label="Descrição"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoadingSave}
                        color="primary"
                        className={classes.buttonSave}>
                        {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Salvar
                    </Button>
                    {persist.eNovo ? '' :
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            className={classes.buttonRemove}
                            onClick={handleRemove}
                        >
                            Remover
                        </Button>
                    }

                </form>
            </Container>

        </>
    );
}

export default CardapioForm;