import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '../../UI/Button';
import Loading from '../../UI/Loading';


const CronogramaQuantAulaBimestreTable = ({ list, setCqab, isLoading, handleNewRegister }) => {

    return (
        <div>
            <Button
                onClick={handleNewRegister}
                variant="contained"
                color="primary">
                Novo Registro
            </Button>
            {isLoading ?
                <Loading description="Carregando Cronograma Quant. Aula Dia.." /> :
                <>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell >Bimestre</TableCell>
                                <TableCell >Quantidade</TableCell>
                                <TableCell >Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((cqad) => (
                                <TableRow key={cqad.codigo}>
                                    <TableCell>{cqad.bimestre.anoLetivo} - {cqad.bimestre.numeroBimestre}</TableCell>
                                    <TableCell>{cqad.quantAulas}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => setCqab(cqad)}
                                        >
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            }


        </div>
    );
}

export default CronogramaQuantAulaBimestreTable;