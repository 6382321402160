import {
  ADD_LISTA_AVALIACAO_ALUNO,
  UPDATE_LISTA_AVALIACAO_ALUNO,
  SET_LISTA_AVALIACAO_ALUNO,
  REMOVE_LISTA_AVALIACAO_ALUNO,
  SET_AVALIACAO_ALUNO,
  SET_LISTA_INIT
} from './actionTypes';
import { PURGE } from "redux-persist";

export function avaliacaoAluno(state = null, action) {
  switch (action.type) {
    case SET_AVALIACAO_ALUNO:
      return action.avaliacaoAluno

    case PURGE:
      return null;

    default:
      return state
  }
}

const listaInit = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function listaAvaliacaoAluno(state = listaInit, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_LISTA_AVALIACAO_ALUNO:
      persist.content = [
        action.avaliacaoAluno,
        ...persist.content
      ]
      return persist

    case SET_LISTA_INIT:
      return listaInit

    case UPDATE_LISTA_AVALIACAO_ALUNO:
      let content = state.content.map((aa) => {
        if (aa.codigo === action.avaliacaoAluno.codigo) {
          return action.avaliacaoAluno;
        } else {
          return aa;
        }
      });
      persist.content = content;
      return persist;

    case SET_LISTA_AVALIACAO_ALUNO:
      return action.lista

    case REMOVE_LISTA_AVALIACAO_ALUNO:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return listaInit;

    default:
      return state
  }
}
