import { FormControlLabel } from "@material-ui/core";
import React from "react";
import Switch from "./Switch";

const SwitchLabel = ({ value, handleSwitch, label, name }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          handleSwitch={handleSwitch}
          value={value}
          name={name}
        />
      }
      label={label}
    />
  );
};

export default SwitchLabel;
