import {
  ADD_COMUNICADOS,
  UPDATE_COMUNICADOS,
  SET_COMUNICADOS,
  REMOVE_COMUNICADOS,
  SET_COMUNICADO,
  OPEN_SEARCH_COMUNICADO,
  CLOSE_SEARCH_COMUNICADO,
  SET_QUANT_APROVACAO_COMUNICADO,
  DECREASE_QUANT_APROVACAO_COMUNICADO,
  INCREASE_QUANT_APROVACAO_COMUNICADO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchComunicado(state = false, action) {
  switch (action.type) {
    case OPEN_SEARCH_COMUNICADO:
      return true;
    case CLOSE_SEARCH_COMUNICADO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function comunicado(state = null, action) {
  switch (action.type) {
    case SET_COMUNICADO:
      return action.comunicado
    case PURGE:
      return null;
    default:
      return state
  }
}

export const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function comunicados(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_COMUNICADOS:
      persist.content = [
        ...persist.content,
        action.comunicado
      ]
      return persist

    case UPDATE_COMUNICADOS:
      let content = state.content.map((comunicado) => {
        if (comunicado.codigo === action.comunicado.codigo) {
          return action.comunicado;
        } else {
          return comunicado;
        }
      });
      persist.content = content;
      return persist;

    case SET_COMUNICADOS:
      return action.comunicados

    case REMOVE_COMUNICADOS:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}

export function quantAprovacaoComunicado(state = 0, action) {
  switch (action.type) {
    case SET_QUANT_APROVACAO_COMUNICADO:
      return action.quantAprovacao
    case DECREASE_QUANT_APROVACAO_COMUNICADO:
      return state - 1
    case INCREASE_QUANT_APROVACAO_COMUNICADO:
      return state + 1
    case PURGE:
      return 0;
    default:
      return state
  }
}
