import React, { useEffect } from 'react';
import { findBySecaoCodigo } from './api-avaliacao-conteudo';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ComponentsForm from "../../Capitulo/Conteudo/componentsForm";
import { useSelector, useDispatch } from "react-redux";
import { setConteudos } from "../../Capitulo/Conteudo/actions/actions";



export default function AvaliacaoConteudoTableForm() {
    const conteudos = useSelector(state => state.conteudos);
    const dispatch = useDispatch();
    const secao = useSelector(state => state.secao);

    useEffect(() => {
        if (secao) {
            findBySecaoCodigo(secao.codigo).then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    dispatch(setConteudos(data));
                }
            }).catch(err => {
                console.log(err);
            })
        } else {
            dispatch(setConteudos([]));
        }
    }, [secao, dispatch]);
    return (
        <div>
            {conteudos.map(conteudo => ComponentsForm(conteudo))}
        </div>
    );
}