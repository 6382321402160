import {
  ADD_ALUNOS_LIVROS,
  UPDATE_ALUNOS_LIVROS,
  SET_ALUNOS_LIVROS,
  REMOVE_ALUNOS_LIVROS,
  SET_ALUNO_LIVRO,
  OPEN_ALUNO_LIVRO,
  CLOSE_ALUNO_LIVRO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function openSearchAlunoLivro(state = false, action) {
  switch (action.type) {
    case OPEN_ALUNO_LIVRO:
      return true;
    case CLOSE_ALUNO_LIVRO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}

export function alunoLivro(state = null, action) {
  switch (action.type) {
    case SET_ALUNO_LIVRO:
      return action.alunoLivro

    case PURGE:
      return null;
    default:
      return state
  }
}


export const initialState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function alunosLivros(state = initialState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_ALUNOS_LIVROS:
      persist.content = [
        ...persist.content,
        action.aluno
      ]
      return persist

    case UPDATE_ALUNOS_LIVROS:
      let content = state.content.map((al) => {
        if (al.codigo === action.alunoLivro.codigo) {
          return action.alunoLivro;
        } else {
          return al;
        }
      });
      persist.content = content;
      return persist;

    case SET_ALUNOS_LIVROS:
      return action.alunosLivros

    case REMOVE_ALUNOS_LIVROS:
      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initialState;

    default:
      return state
  }
}
