import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldDate from '../../UI/TextFieldDate';
import { dateFormatMoment } from '../../../util/DateUtil';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    }
}));

export default function CronogramaDataForm({ cronogramaData, setCronogramaData, loadingSave, clickSubmit }) {
    const classes = useStyles();

    const handleDataInicioChange = (dataInicio) => {
        if (dataInicio)
            dataInicio.setHours(0, 0, 0, 0);

        setCronogramaData({
            ...cronogramaData,
            dataInicio: dataInicio
        })
    }

    const handleDataFimChange = (dataFim) => {
        if (dataFim)
            dataFim.setHours(0, 0, 0, 0);

        setCronogramaData({
            ...cronogramaData,
            dataFim: dataFim
        })
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <CloseIcon
                fontSize='small'
                onClick={() => (setCronogramaData(null))} className={classes.closeIcon} />
            <form onSubmit={clickSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextFieldDate
                            required
                            label="Data Início"
                            value={cronogramaData && cronogramaData.dataInicio ? dateFormatMoment(cronogramaData.dataInicio) : null}
                            onChange={handleDataInicioChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldDate
                            label="Data Fim"
                            value={cronogramaData && cronogramaData.dataFim ? dateFormatMoment(cronogramaData.dataFim) : null}
                            onChange={handleDataFimChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button

                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={loadingSave}
                        >
                            {loadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                            Salvar
                        </Button>
                    </Grid>

                </Grid>

            </form>
        </Container>
    );
}