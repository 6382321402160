import React, { useState, useEffect } from 'react';
import { findByAlunoCodigo } from './api-aluno-responsavel';
import 'react-toastify/dist/ReactToastify.css';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Loading from '../UI/Loading';

const AlunoResponsavelTable = ({ aluno }) => {
    const [alunosResponsavel, setAlunosResponsavel] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        findByAlunoCodigo(aluno.codigo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAlunosResponsavel(data);
                setIsLoading(false);
            }
        })
    }, [aluno.codigo]);



    return (
        <>
            {isLoading ? <Loading description='Carregando Responsáveis...' /> :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Responsável</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Parentesco</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alunosResponsavel.length > 0 ? alunosResponsavel.map((ar, index) => (
                            <TableRow key={ar.codigo}>
                                <TableCell>{ar.usuario.nome}</TableCell>
                                <TableCell>{ar.usuario.email}</TableCell>
                                <TableCell>{ar.parentesco.descricao}</TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            }
        </>
    );
}

export default AlunoResponsavelTable;