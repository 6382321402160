import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage, successMessage } from '../../../util/ConfirmAlertUtil';
import Button from '../../UI/Button';
import { generateCreateCronogramaDiaByTurma, generateCreateCronogramaDiaByTdp, generateCreateCronogramaDiaByTdpAndDate, generateByCronogramaDia, findByTdpCodigo, deleteById, save, generateCreateCronogramaDia } from './api-cronograma-quant-aula-bimestre';
import LoadingDialog from '../../UI/LoadingDialog';
import { confirmAlert } from 'react-confirm-alert';
import TurmaDisciplinaProfessorProfile from '../../TDP/TurmaDisciplinaProfessorProfile';
import TDPTableSelect from '../../TDP/TDPTableSelect';
import CronogramaQuantAulaBimestreTable from './CronogramaQuantAulaBimestreTable';
import CronogramaQuantAulaBimestreForm from './CronogramaQuantAulaBimestreForm';
import { toast } from 'react-toastify';
import TextFieldDate from '../../UI/TextFieldDate';

const useStyles = makeStyles({
    container: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '10px',
    }
})

const CronogramaQuantAulaBimestreContainer = () => {
    const classes = useStyles();
    const [list, setList] = useState([]);
    const [cqab, setCqab] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [tdp, setTdp] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dateGenerate, setDateGenerate] = useState(null);

    useEffect(() => {
        if (tdp && tdp.codigo) {
            setIsLoading(true);
            findByTdpCodigo(tdp.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.error);
                } else {
                    setList(data);
                }
                setIsLoading(false);
            });
        }

    }, [tdp]);

    const fetch = () => {
        if (tdp && tdp.codigo) {
            setIsLoading(true);
            findByTdpCodigo(tdp.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.error);
                } else {
                    setList(data);
                }
                setIsLoading(false);
            });
        }
    }

    const onChangeDateGenerate = (date) => {
        setDateGenerate(date);
    }


    const handleGenerateCreateCronogramaDiaByTdp = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja gerar todas as quantidades de aulas da turma-disciplina?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        generateCreateCronogramaDiaByTdp(tdp.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                successMessage("Quantidades Gerada com Sucesso.")
                            }
                            setOpenDialog(false);
                            fetch();
                        })

                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }
    const handleGenerateCreateCronogramaDiaByTdpAndDate = () => {
        if (dateGenerate) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja gerar todas as quantidades de aulas da turma-disciplina?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            setOpenDialog(true);
                            generateCreateCronogramaDiaByTdpAndDate(tdp.codigo, dateGenerate).then((data) => {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    successMessage("Quantidades Gerada com Sucesso.")
                                }
                                setOpenDialog(false);
                                fetch();
                            })

                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            errorMessage("Digite a Data para a Geração.");
        }
    }

    const handleGenerateCreateCronogramaDiaByTurma = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja gerar todas as quantidades de aulas da turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        generateCreateCronogramaDiaByTurma(tdp.turma.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                successMessage("Quantidades Gerada com Sucesso.")
                            }
                            setOpenDialog(false);
                            fetch();
                        })

                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleGenerateCreateCronogramaDia = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja gerar todas as quantidades de aulas da instituição?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);
                        generateCreateCronogramaDia().then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                successMessage("Quantidades Gerada com Sucesso.")
                            }
                            setOpenDialog(false);
                            fetch();
                        })

                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleGenerateByCronogramaDia = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja gerar todas as quantidades de aulas da turma?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setOpenDialog(true);

                        generateByCronogramaDia(tdp.turma.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                successMessage("Quantidades Gerada com Sucesso.")
                            }
                            setOpenDialog(false);
                            fetch();
                        })

                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleNewRegister = () => {
        setCqab({
            eNovo: true,
            tdp: tdp
        })
    }

    const handleRemove = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este Cronograma Quant. Aula Bimestre?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        deleteById(cqab.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                setList((prevState) => ({
                                    ...prevState,
                                    content: prevState.filter((content) => content.codigo !== cqab.codigo)
                                }))
                            }
                            setIsLoadingAction(false);
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    const clickSubmit = (event) => {
        event.preventDefault();
        save(cqab).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                if (cqab.eNovo) {
                    setList((prevState) => ([
                        data,
                        ...prevState
                    ]))
                } else {
                    setList((prevState) => (
                        prevState.map((content) => {
                            if (content.codigo === cqab.codigo) {
                                return data;
                            } else {
                                return content;
                            }
                        })))
                }
                toast.success("Registro salvo com Sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        })

    }

    return (
        <Container>
            <LoadingDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                title="Esta ação pode levar alguns minutos"
                description="Gerando Quantidades de Aulas..."
            />
            <Grid className={classes.container} container spacing={3} component={Paper}>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="body1">
                        Cronograma Quantidade Aula Bimestre
                    </Typography>
                </Grid>

                {tdp ?
                    <>
                        <Grid item>
                            <TurmaDisciplinaProfessorProfile
                                tdp={tdp}
                                onTdpSelected={setTdp}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        onClick={handleGenerateCreateCronogramaDiaByTurma}
                                    >
                                        Gerar e Criar Cronograma Dia por Turma
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleGenerateByCronogramaDia}
                                    >
                                        Gerar por Cronograma Dia por Turma
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        onClick={handleGenerateCreateCronogramaDiaByTdp}
                                    >
                                        Gerar e Criar Cronograma Dia por Turma/Disciplina - Todos
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Grid container alignItems='center' spacing={3} direction='column'>
                                        <Grid item>
                                            <TextFieldDate
                                                onChange={onChangeDateGenerate}
                                                value={dateGenerate}
                                                label='Data' />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={handleGenerateCreateCronogramaDiaByTdpAndDate}
                                            >
                                                Gerar e Criar Cronograma Dia por Turma/Disciplina - A partir da Data
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {cqab ?
                            <Grid item xs={12}>

                                <CronogramaQuantAulaBimestreForm
                                    cqab={cqab}
                                    setCqab={setCqab}
                                    clickSubmit={clickSubmit}
                                    handleRemove={handleRemove}
                                    isLoadingAction={isLoadingAction}
                                />
                            </Grid> :
                            <Grid item xs={12}>
                                <CronogramaQuantAulaBimestreTable
                                    list={list}
                                    setCqab={setCqab}
                                    isLoading={isLoading}
                                    handleNewRegister={handleNewRegister}
                                />
                            </Grid>
                        }
                    </> :
                    <>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleGenerateCreateCronogramaDia}
                            >
                                Gerar e Criar Cronograma Dia para Toda a Instituição
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TDPTableSelect setTdp={setTdp} />
                        </Grid>
                    </>
                }

            </Grid>
        </Container>
    )
}

export default CronogramaQuantAulaBimestreContainer;