import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import Loading from "../UI/Loading";
import {
  findByUsuarioParaAndFinalizado,
  findByUsuarioPara,
  findByUsuarioPorAndFinalizado,
  findByUsuarioPor,
  save,
  finalizar,
} from "./api-tarefa";
import TarefaForm from "./TarefaForm";
import TarefaProfile from "./TarefaProfile";
import TarefaTable from "./TarefaTable";
import { success } from "../../util/ToastUtil";
import TarefaFiltro from "./TarefaFiltro";

export const toUserKey = "to-user";
export const forUserKey = "for-user";

const TarefaContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(toUserKey);
  const [tarefas, setTarefas] = useState({
    content: [],
    size: 0,
    totalElements: 0,
    number: 0,
  });
  const usuario = useSelector((state) => state.usuario);
  const [tarefa, setTarefa] = useState(null);
  const naoFinalizadoKey = "nao-finalizado";
  const finalizadoKey = "finalizado";
  const todosKey = "todos";
  const filtros = [
    { value: naoFinalizadoKey, label: "Não Finalizado" },
    { value: finalizadoKey, label: "Finalizado" },
    { value: todosKey, label: "Todos" },
  ];
  const [filtro, setFiltro] = useState(
    filtros.find((f) => f.value === naoFinalizadoKey)
  );
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    if (menu === toUserKey) {
      setIsLoading(true);
      if (filtro.value === naoFinalizadoKey || filtro.value === finalizadoKey) {
        const finalizado = filtro.value === finalizadoKey;
        findByUsuarioParaAndFinalizado(0, finalizado).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      } else {
        findByUsuarioPara(0).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      }
    } else if (menu === forUserKey) {
      setIsLoading(true);
      if (filtro.value === naoFinalizadoKey || filtro.value === finalizadoKey) {
        const finalizado = filtro.value === finalizadoKey;
        findByUsuarioPorAndFinalizado(0, finalizado).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      } else {
        findByUsuarioPor(0).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      }
    }
  }, [menu, usuario, filtro]);

  const handleChangePage = (event, newPage) => {
    if (menu === toUserKey) {
      setIsLoading(true);
      if (filtro.value === naoFinalizadoKey || filtro.value === finalizadoKey) {
        const finalizado = filtro.value === finalizadoKey;
        findByUsuarioParaAndFinalizado(newPage, finalizado).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      } else {
        findByUsuarioPara(newPage).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      }
    } else if (menu === forUserKey) {
      setIsLoading(true);
      if (filtro.value === naoFinalizadoKey || filtro.value === finalizadoKey) {
        const finalizado = filtro.value === finalizadoKey;
        findByUsuarioPorAndFinalizado(newPage, finalizado).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      } else {
        findByUsuarioPor(newPage).then((data) => {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setTarefas(data);
          }
          setIsLoading(false);
        });
      }
    }
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setIsLoadingAction(true);
    const eNovo = tarefa.eNovo;
    save(tarefa).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        success("Salvo com sucesso.");
        setTarefa(data);
        if (eNovo) {
          setTarefas((prevState) => ({
            ...prevState,
            content: [data, ...prevState.content],
          }));
        } else {
          updateTarefas(data);
        }
      }
      setIsLoadingAction(false);
    });
  };

  const updateTarefas = (data) => {
    setTarefas((prevState) => ({
      ...prevState,
      content: prevState.content.map((ps) => {
        if (ps.codigo === data.codigo) {
          return data;
        } else {
          return ps;
        }
      }),
    }));
  };

  const handleRemove = ({ tarefa }) => {
    setTarefas((prevState) => ({
      ...prevState,
      content: prevState.content.filter((ps) => ps.codigo === tarefa.codigo),
    }));
    success("Tarefa Removida.");
  };

  const handleClose = () => {
    setTarefa(null);
  };

  const handleFinalizar = (codTarefa) => {
    finalizar(codTarefa).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setTarefa(data);
        updateTarefas(data);
        success("Tarefa Finalizada.");
      }
    });
  };

  const handleChangeFiltro = (event) => {
    console.log(event);
    setFiltro(filtros.find((f) => f.value === event.target.value));
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Typography variant="h5">Tarefa</Typography>
      </Grid>
      {tarefa ? (
        <>
          {menu === toUserKey ? (
            <TarefaProfile
              handleFinalizar={handleFinalizar}
              tarefa={tarefa}
              handleClose={handleClose}
            />
          ) : (
            <TarefaForm
              tarefa={tarefa}
              setTarefa={setTarefa}
              clickSubmit={clickSubmit}
              handleRemove={handleRemove}
              isLoadingAction={isLoadingAction}
            />
          )}
        </>
      ) : (
        <>
          <Grid item>
            <Grid container spacing={3} direction="row">
              <Grid item>
                <Button variant="outlined" onClick={() => setMenu(toUserKey)}>
                  Atribuído para o Usuário
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setMenu(forUserKey)}>
                  Atribuído pelo Usuário
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TarefaFiltro
              filtros={filtros}
              filtro={filtro}
              handleChange={handleChangeFiltro}
            />
          </Grid>
          {menu === forUserKey ? (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setTarefa({ eNovo: true })}
              >
                Nova Tarefa
              </Button>
            </Grid>
          ) : (
            ""
          )}
          {isLoading ? (
            <Grid item>
              <Loading description="Carregando Tarefas..." />
            </Grid>
          ) : (
            <>
              <Grid item>
                <TarefaTable
                  tarefas={tarefas}
                  handleChangePage={handleChangePage}
                  setTarefa={setTarefa}
                  menu={menu}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default TarefaContainer;
