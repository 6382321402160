import { SERVER_URL } from "../../../constants"
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from "../../../util/FetchUtil";

const controller = 'cronograma-quant-aula-dia/';

export const page = (newPage, codTdp, codBimestre) => {
    const url = SERVER_URL + controller + "tdp/" + codTdp + "/bimestre/" + codBimestre + "?page=" + newPage;
    return fetchGetAuth(url);
}

export const save = (cqad) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, cqad);
}

export const deleteById = (codigo) => {
    const url = SERVER_URL + controller + codigo
    return fetchRemoveAuthReturn(url);
}

export const check = (codTdp, codBimestre) => {
    const url = SERVER_URL + controller + "check/" + codTdp + "/" + codBimestre;
    return fetchGetAuth(url);
}

export const checkByNumeroBimestre = (codTdp, numeroBimestre) => {
    const url = SERVER_URL + controller + "check-by-number-bimestre/" + codTdp + "/" + numeroBimestre;
    return fetchGetAuth(url);
}