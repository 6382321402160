import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import AtividadeForm from './AtividadeForm';
import AtividadeTable from './AtividadeTable';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px',
    },
});

export default function AtividadeContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const atividade = useSelector(state => state.atividade);

    return (
        <Container className={classes.paper} component={Paper}>
            <Typography className={classes.title} variant="h6">
                Atividades
            </Typography>

            {tdp ?
                <>
                    <TurmaDisciplinaProfessorProfileRedux />
                    {atividade ? <AtividadeForm /> : <AtividadeTable />}

                </> :
                <TDPTableSelectRedux />
            }
        </Container>
    );
}