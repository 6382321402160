import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { rltAlunoTurma, rltAtaFinal } from './api-relatorio';
import CircularProgress from '@material-ui/core/CircularProgress';
import Countdown from 'react-countdown';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import TurmaComboBox from '../Turma/UI/TurmaComboBox';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RltTurmaForm({ turmas }) {
    const classes = useStyles();
    const [turma, setTurma] = useState({});
    const [loading, setLoading] = useState({
        alunoTurma: false,
        ataFinal: {
            isVisible: false,
            timeLoading: 900000
        }
    })

    const clickAlunoTurma = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            alunoTurma: true
        });
        if (turma) {
            rltAlunoTurma(turma.codigo).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        alunoTurma: false
                    });
                }
            })
        }

    }

    const clickAtaFinal = (event) => {
        event.preventDefault();
        setLoading({
            ...loading,
            ataFinal: {
                ...loading.ataFinal,
                isVisible: true
            }
        });
        if (turma) {
            rltAtaFinal(turma.codigo).then(data => {
                if (data) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setLoading({
                        ...loading,
                        ataFinal: {
                            ...loading.ataFinal,
                            isVisible: false
                        }
                    });
                }
            })
        }

    }

    const handleTurmaChange = (event) => {
        const result = turmas.find((t) => t.codigo === event.target.value);
        setTurma(result);
    }

    return (
        <div>
            <CssBaseline />
            <Container component={Paper} >
                <Typography className={classes.title} variant="body1">
                    Por Turma
                </Typography>
                <form>
                    <TurmaComboBox
                        turmas={turmas}
                        turma={turma}
                        handleTurmaChange={handleTurmaChange}
                    />
                    {loading.alunoTurma ?
                        <CircularProgress
                            className={classes.loading}
                            color="primary"
                        /> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickAlunoTurma}
                        >
                            Aluno Turma
                        </Button>
                    }

                    {loading.ataFinal.isVisible ?
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <CircularProgress
                                        className={classes.loading}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography className={classes.title} variant="h5">
                                        <Countdown date={Date.now() + loading.ataFinal.timeLoading}>
                                            Carregando Relatório
                                        </Countdown>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </> :
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickAtaFinal}
                        >
                            Ata Final
                        </Button>
                    }

                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        component={Link}
                        to={{
                            pathname: '/relatorio-ata-final-turma-web',
                            state: {
                                codTurma: turma.codigo,
                            }
                        }}
                    >
                        Ata Final Turma Web
                    </Button>
                    <Button
                        component={Link}
                        to={{
                            pathname: '/confere-registro-aula-falta',
                            state: {
                                turma: turma
                            }
                        }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Confere Registro Aulas e Faltas
                    </Button>

                </form>
            </Container>
        </div>
    );
}