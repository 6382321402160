import {
  SET_CONCEITO_ALUNOS,
  SET_CONCEITO_ALUNO,
  UPDATE_CONCEITO_ALUNOS,
  REMOVE_CONCEITO_ALUNOS
} from './actionTypes';
import { PURGE } from "redux-persist";

export function conceitoAluno(state = null, action) {
  switch (action.type) {
    case SET_CONCEITO_ALUNO:
      return action.conceitoAluno

    case PURGE:
      return null;
    default:
      return state
  }
}

export function conceitoAlunos(state = [], action) {
  switch (action.type) {
    case SET_CONCEITO_ALUNOS:
      return action.conceitoAlunos


    case UPDATE_CONCEITO_ALUNOS:
      return state.map((ca) => {
        if (ca.codigo === action.conceitoAluno.codigo) {
          return action.conceitoAluno;
        } else {
          return ca;
        }
      });

    case REMOVE_CONCEITO_ALUNOS:
      return state.filter((ca) => ca.codigo !== action.codConceitoAluno);

    case PURGE:
      return [];

    default:
      return state
  }
}
