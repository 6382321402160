import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function RelatorioFrequenciaWebTdp({
    setFrequencia,
    freqTdp,
    bgColor,
    children
}) {

    const handleSeeMoreClick = () => {
        setFrequencia((prevState) => ({
            ...prevState,
            listFreqTdp: prevState.listFreqTdp.map((freqTdpPS) => {
                if (freqTdpPS.tdp.codigo === freqTdp.tdp.codigo) {
                    return {
                        ...freqTdpPS,
                        seeMore: !freqTdpPS.seeMore
                    }
                } else {
                    return freqTdpPS;
                }
            })
        }))
    }

    return (

        <div style={{
            border: '1px solid black',
            padding: '20px',
            margin: '20px',
            backgroundColor: bgColor
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
                <Typography >
                    Turma: {freqTdp.tdp.turma.descricao}
                </Typography>
                <Typography >
                    Disciplina: {freqTdp.tdp.disciplina.descricao}
                </Typography>
                <Typography >
                    Professor: {freqTdp.tdp.usuario.nome}
                </Typography>
                <Button
                    onClick={handleSeeMoreClick}
                >{freqTdp.seeMore ? <RemoveIcon /> : <AddIcon />}</Button>
            </div>
            {freqTdp.seeMore ? <>{children}</> : ''}

        </div>

    );
}