import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import OAAlunoTable from './OAAlunoTable';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }, 
    tabMenu:{
        marginTop:'20px'
    }
});

export default function OAAlunoContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <Typography className={classes.title} variant="h5">
                            Observações
                        </Typography>
                    </Grid>
                </Grid>
                {tdp ?
                    <>
                        <TurmaDisciplinaProfessorProfileRedux />
                        <OAAlunoTable />
                        
                    </> :
                    <TDPTableSelectRedux />
                }
            </Container>

        </div>
    );
}