import React from 'react';
import { Button as ButtonMaterial } from '@material-ui/core';

const Button = (props) => {
    return (
        <ButtonMaterial
            variant="contained"
            color="primary"
            {...props}
        >
            {props.children}
        </ButtonMaterial>
    )
}

export default Button;