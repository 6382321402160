import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { dateFormat, timeFormat } from '../../util/DateUtil';
import Loading from '../UI/Loading';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PresencaTableFilter from './PresencaTableFilter';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px',
        margin: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    submit: {
        marginRight: '8px',
    },
    loadingRemove: {
        marginRight: '10px',
        marginLeft: '10px'
    }
});


export default function PresencaTable({
    presencas,
    tdp,
    isLoading,
    isLoadingRemove,
    handleDelete,
    handleDuplicarPresenca,
    handleChangePage,
    setPresenca,
    showFilters,
    setShowFilters,
    mesSelect,
    setMesSelect,
}) {
    const classes = useStyles();


    return (
        <div>
            <Grid container spacing={3} direction='column'>
                <Grid item>
                    <Grid container spacing={3} direction='row'>

                        <Grid item>
                            <Button
                                className={classes.novoRegistro}
                                onClick={() => setPresenca({ tdp: tdp, eNovo: true })}
                                variant="contained"
                                color="primary">
                                Nova Presença
                            </Button>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Filtrar">
                                <Button
                                    variant={showFilters ? 'contained' : 'outlined'}
                                    onClick={() => setShowFilters((prevState) => (!prevState))}
                                >
                                    <FilterListIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    {showFilters ?
                        <PresencaTableFilter
                            mesSelect={mesSelect}
                            setMesSelect={setMesSelect}
                        /> :
                        ''}
                </Grid>

            </Grid>


            {isLoading ? <Loading description="Carregando Presenças..." /> :
                <>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={presencas.size ? presencas.size : 0}
                        component="div"
                        count={presencas.totalElements}
                        page={presencas.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "350px" }}>Conteúdo</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Cronograma Aula</TableCell>
                                <TableCell >Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {presencas.content.map((presenca, index) => (
                                <TableRow key={presenca.codigo}>
                                    <TableCell>{presenca.conteudo}</TableCell>
                                    <TableCell>{dateFormat(presenca.data)}</TableCell>
                                    <TableCell>{presenca.cronograma && "Aula " + presenca.cronograma.cronogramaAula.ordemAula + " : " + timeFormat(presenca.cronograma.cronogramaAula.horarioInicio) + " - " + timeFormat(presenca.cronograma.cronogramaAula.horarioFim)}</TableCell>
                                    <TableCell>
                                        <EditIcon
                                            fontSize='small'
                                            color="secondary"
                                            style={{ marginRight: '10px' }}
                                            className={classes.cursorLink}
                                            onClick={() => setPresenca(presenca)}
                                        />
                                        {isLoadingRemove ?
                                            <CircularProgress
                                                className={classes.loadingRemove}
                                                color="secondary"
                                                size={20}
                                            /> :
                                            <DeleteIcon
                                                fontSize='small'
                                                className={classes.cursorLink}
                                                onClick={() => { handleDelete(presenca.codigo) }}
                                                style={{ marginRight: '10px' }}
                                                color="secondary"
                                            />
                                        }
                                        {/* <Button
                                            component={Link}
                                            to={{
                                                pathname: '/presenca-aluno',
                                                state: {
                                                    presenca: presenca
                                                }
                                            }}
                                            type="button"
                                            variant="contained"
                                            size='small'
                                            color="secondary"
                                            onClick={() => {
                                                setPresenca(presenca);
                                            }}
                                            className={classes.submit}>
                                            Presença Aluno
                                        </Button> */}
                                        <Button
                                            type="button"
                                            variant="contained"
                                            size='small'
                                            color="secondary"
                                            onClick={() => { handleDuplicarPresenca(index) }}
                                            className={classes.submit}>
                                            Duplicar Presença
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={presencas.size ? presencas.size : 0}
                        component="div"
                        count={presencas.totalElements}
                        page={presencas.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>
            }

        </div>
    );
}