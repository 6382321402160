const handleFincanceiroError = (data, setMessage) => {
    if (data.message === 'User not Authorized.') {
        setMessage({
            type: typeError,
            description: 'Usuário sem autorização.'
        });
    } else if (data.message === 'Responsavel not registered for the student or not financeiro') {
        setMessage({
            type: typeError,
            description: 'Responsável não autorizado para ver Financeiro do Aluno. Caso queira acompanhar solicite na Secretaria.'
        });
    }
    else {
        console.log(data);
    }
}



export {
    handleFincanceiroError
}


export const typeError = 'error';
export const typeSuccess = 'success';

export const initMessage = {
    type: '',
    description: ''
}