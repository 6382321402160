import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { dateTimeFormat } from "../../util/DateUtil";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";
const useStyles = makeStyles({
  container: {
    padding: "10px",
  },
  closeIcon: {
    marginTop: "20px",
    cursor: "pointer",
  },
});

const TarefaProfile = ({ tarefa, handleClose, handleFinalizar }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      spacing={3}
      direction="column"
    >
      <Grid className={classes.closeIcon} item>
        <CloseIcon onClick={handleClose} fontSize="small" />
      </Grid>
      <Grid item>
        <Typography variant="h6">
          Descrição: {tarefa.descricao ? tarefa.descricao : ""}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Atribuído Por: {tarefa.usuarioPor.nome ? tarefa.usuarioPor.nome : ""}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Atribuído Para:{" "}
          {tarefa.usuarioPara.nome ? tarefa.usuarioPara.nome : ""}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Data Hora: {tarefa.dataHora ? dateTimeFormat(tarefa.dataHora) : ""}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Possui Prazo:
          {tarefa.possuiPrazo ? (
            <CheckCircle style={{ color: green[500] }} />
          ) : (
            <Cancel style={{ color: red[500] }} />
          )}{" "}
          {tarefa.dataHoraPrazo
            ? "Data Hora Prazo: " + dateTimeFormat(tarefa.dataHoraPrazo)
            : ""}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Finalizado:{" "}
          {tarefa.finalizado ? (
            <CheckCircle style={{ color: green[500] }} />
          ) : (
            <Cancel style={{ color: red[500] }} />
          )}{" "}
          Data Hora Finalizado:{" "}
          {tarefa.dataHoraFinalizado
            ? dateTimeFormat(tarefa.dataHoraFinalizado)
            : ""}
        </Typography>
      </Grid>
      {tarefa.finalizado ? (
        ""
      ) : (
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => handleFinalizar(tarefa.codigo)}
          >
            Finalizar
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TarefaProfile;
