import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { dateFormat, timeFormat } from "../../../util/DateUtil";
import { CircularProgress } from "@material-ui/core";
import Button from "../../UI/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cursorLink: {
    cursor: "pointer",
  },
  submit: {
    marginRight: "8px",
  },
  loadingRemove: {
    marginRight: "10px",
    marginLeft: "10px",
  },
});

const PresencaUsuarioTable = ({
  presencas,
  handleChangePage,
  isLoadingRemove,
  handleDelete,
  setPresenca,
}) => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.novoRegistro}
        onClick={() => setPresenca({ eNovo: true })}
        variant="contained"
        color="primary"
      >
        Nova Presença
      </Button>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={presencas.size ? presencas.size : 0}
        component="div"
        count={presencas.totalElements}
        page={presencas.number}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={({ from, to, count }) =>
          from +
          "-" +
          to +
          " de " +
          (count !== -1 ? count + " registros" : +" mais de " + to)
        }
      />
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Turma</TableCell>
            <TableCell>Disciplina</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Cronograma Aula</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {presencas.content.map((presenca, index) => (
            <TableRow key={presenca.codigo}>
              <TableCell>{presenca.tdp.turma.descricao}</TableCell>
              <TableCell>{presenca.tdp.disciplina.descricao}</TableCell>
              <TableCell>{dateFormat(presenca.data)}</TableCell>
              <TableCell>
                {presenca.cronograma &&
                  "Aula " +
                    presenca.cronograma.cronogramaAula.ordemAula +
                    " : " +
                    timeFormat(
                      presenca.cronograma.cronogramaAula.horarioInicio
                    ) +
                    " - " +
                    timeFormat(presenca.cronograma.cronogramaAula.horarioFim)}
              </TableCell>
              <TableCell>
                {isLoadingRemove ? (
                  <CircularProgress
                    className={classes.loadingRemove}
                    color="secondary"
                    size={20}
                  />
                ) : (
                  <DeleteIcon
                    fontSize="small"
                    className={classes.cursorLink}
                    onClick={() => {
                      handleDelete(presenca.codigo);
                    }}
                    style={{ marginRight: "10px" }}
                    color="secondary"
                  />
                )}
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => setPresenca(presenca)}
                  className={classes.submit}
                >
                  Selecionar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={presencas.size ? presencas.size : 0}
        component="div"
        count={presencas.totalElements}
        page={presencas.number}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={({ from, to, count }) =>
          from +
          "-" +
          to +
          " de " +
          (count !== -1 ? count + " registros" : +" mais de " + to)
        }
      />
    </>
  );
};

export default PresencaUsuarioTable;
