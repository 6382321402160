import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import InputImageByte from "../../UI/InputImageByte";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { ADMIN } from "../../Usuario/Perfil";

const useStyles = makeStyles({
  closeIcon: {
    marginTop: "20px",
    marginBottom: "20px",
    cursor: "pointer",
  },
});

const ComunicadoBannerForm = ({
  handleSubmit,
  setComunicadoBanner,
  comunicadoBanner,
  isLoadingAction,
  handleRemove,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const usuario = useSelector((state) => state.usuario);

  useEffect(() => {
    if (file) {
      // create the preview
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const handleChangeImage = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmitInit = (event) => {
    event.preventDefault();

    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);
    handleSubmit(fileFormData);
  };

  return (
    <Grid container spacing={2} direction="column">
      <CloseIcon
        onClick={() => setComunicadoBanner(null)}
        className={classes.closeIcon}
        fontSize="small"
      />
      <Grid item>
        <form onSubmit={handleSubmitInit}>
          <Grid container spacing={2} direction="column">
            {comunicadoBanner.eNovo ? (
              <Grid item>
                <InputImageByte
                  image={preview}
                  handleChangeImage={handleChangeImage}
                  multiple={false}
                />
              </Grid>
            ) : (
              <Grid item>
                <img
                  style={{
                    width: "200px",
                  }}
                  src={comunicadoBanner.urlBanner}
                  alt=""
                />
              </Grid>
            )}

            <Grid item>
              {comunicadoBanner.eNovo ? (
                <Button
                  disabled={isLoadingAction}
                  variant="outlined"
                  type="submit"
                >
                  Salvar
                </Button>
              ) : (
                <>
                  {usuario && usuario.perfil === ADMIN && (
                    <Button
                      disabled={isLoadingAction}
                      variant="outlined"
                      type="button"
                      onClick={handleRemove}
                    >
                      Remover
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ComunicadoBannerForm;
