import {
  Button,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@material-ui/core";
import React from "react";
import TablePaginationActions from "../../shared/components/Table/Actions";

const LocalList = ({ locais, setLocal, handleChangePage }) => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Button variant="outlined" onClick={() => setLocal({ eNovo: true })}>
          Novo Local
        </Button>
      </Grid>
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage={locais.size ? locais.size : 0}
          component="div"
          count={locais.totalElements}
          page={locais.number}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            from +
            "-" +
            to +
            " de " +
            (count !== -1 ? count + " registros" : +" mais de " + to)
          }
        />
      </Grid>
      {locais.content.map((local) => (
        <>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
            >
              <Grid item>
                <Typography variant="body1">{local.descricao}</Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setLocal(local)}>
                  Selecionar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </>
      ))}
      <Grid item>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage={locais.size ? locais.size : 0}
          component="div"
          count={locais.totalElements}
          page={locais.number}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            from +
            "-" +
            to +
            " de " +
            (count !== -1 ? count + " registros" : +" mais de " + to)
          }
        />
      </Grid>
    </Grid>
  );
};

export default LocalList;
