import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-turma';
import { findAll as findAllCursos } from '../Curso/api-curso';
import { findAll as findAllSeries } from '../Serie/api-serie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { setTurma as setTurmaRedux, addTurmas, updateTurmas } from "./actions/actions";
import { findAll } from './api-turma-model';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    novoRegistro: {
        marginTop: '10px'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));

export default function TurmaForm() {
    const classes = useStyles();
    const turmaRedux = useSelector(state => state.turma);
    const [turma, setTurma] = useState(useSelector(state => state.turma));
    const dispatch = useDispatch();
    const [models, setModels] = useState([])

    const [cursos, setCursos] = useState([]);
    const [series, setSeries] = useState([]);

    useEffect(() => {
        findAllCursos().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setCursos(data);
            }
        });

        findAllSeries().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setSeries(data);
            }
        });

        findAll().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setModels(data);
            }
        })

    }, []);

    useEffect(() => {
        setTurma(turmaRedux);
    }, [turmaRedux]);

    const handleCursoChange = (event) => {
        const curso = cursos.find((curso) => {
            return curso.codCurso === event.target.value;
        });
        setTurma({
            ...turma,
            curso: curso
        });
    }

    const handleSerieChange = (event) => {
        const serie = series.find((serie) => {
            return serie.codSerie === event.target.value;
        });
        setTurma({
            ...turma,
            serie: serie
        })
    }

    const handleModelChange = (event) => {
        setTurma({
            ...turma,
            model: event.target.value
        })
    }

    const handleDescricaoChange = (event) => {
        setTurma({
            ...turma,
            descricao: event.target.value
        })
    }


    const clickSubmit = (event) => {
        event.preventDefault();

        if (!turma.curso) {
            toast.error("Selecione um Curso.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        if (!turma.serie) {
            toast.error("Selecione uma Série.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        if (!turma.descricao) {
            toast.error("Preencha a Descrição da Turma.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        const eNovo = turma.eNovo;
        save(turma).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addTurmas(data));
                } else {
                    dispatch(updateTurmas(data));
                }
                dispatch(setTurmaRedux(data));
            }

        }).catch(err => {
            console.log(err);
        })

    }

    return (
        <div>
            <Container component={Paper} maxWidth="sm">
                <CloseIcon
                    fontSize='small'
                    onClick={() => dispatch(setTurmaRedux(null))}
                    className={classes.closeIcon}
                />
                <form onSubmit={clickSubmit}>
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="curso-label">Curso</InputLabel>
                        <Select
                            labelId="curso-label"
                            id="curso-select"
                            value={turma.curso ? turma.curso.codCurso : ''}
                            onChange={handleCursoChange}
                        >
                            {cursos.map((curso, index) => (
                                <MenuItem key={index} value={curso.codCurso}>{curso.descricao}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="serie-label">Série</InputLabel>
                        <Select
                            labelId="serie-label"
                            id="serie-select"
                            value={turma.serie ? turma.serie.codSerie : ''}
                            onChange={handleSerieChange}
                        >
                            {series.map((serie, index) => (
                                <MenuItem key={index} value={serie.codSerie}>{serie.descricao}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Descrição"
                        name="descricao"
                        size='small'
                        inputProps={{ maxLength: 20 }}
                        onChange={handleDescricaoChange}
                        value={turma.descricao || ''}
                    />
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="serie-label">Modelo</InputLabel>
                        <Select
                            labelId="serie-label"
                            id="serie-select"
                            value={turma.model ? turma.model : ''}
                            onChange={handleModelChange}
                            disabled={!turma.eNovo}
                        >
                            {models.map((model) => (
                                <MenuItem key={model} value={model}>{model}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>
        </div>
    );
}