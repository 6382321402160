import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'aluno-foto/';

export const findByAlunoCodigo = (codAluno) => {
    const url = SERVER_URL + controller + 'query?codAluno=' + codAluno;
    return fetchGetAuth(url);
}

export const findTop1ByAlunoCodigo = (codAluno) => {
    const url = SERVER_URL + controller + codAluno;
    return fetchGetAuth(url);
}

export const save = (alunoFoto) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, alunoFoto);
}