import {
  SET_HISTORICO_LANCAMENTO,
  SET_HISTORICOS_LANCAMENTOS,
  ADD_HISTORICOS_LANCAMENTOS,
  UPDATE_HISTORICOS_LANCAMENTOS,
  REMOVE_HISTORICOS_LANCAMENTOS,
  OPEN_HISTORICO_LANCAMENTO,
  CLOSE_HISTORICO_LANCAMENTO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function historicoLancamento(state = null, action) {
  switch (action.type) {
    case SET_HISTORICO_LANCAMENTO:
      return action.historicoLancamento

    case PURGE:
      return null;

    default:
      return state
  }
}

export function historicosLancamentos(state = [], action) {
  switch (action.type) {
    case SET_HISTORICOS_LANCAMENTOS:
      return action.historicosLancamentos

    case ADD_HISTORICOS_LANCAMENTOS:

      return [
        ...state,
        action.historicoLancamento
      ]

    case UPDATE_HISTORICOS_LANCAMENTOS:
      let content = state.map((hl) => {
        if (hl.codigo === action.historicoLancamento.codigo) {
          return action.historicoLancamento;
        } else {
          return hl;
        }
      });
      return content;

    case REMOVE_HISTORICOS_LANCAMENTOS:
      return state.filter((hl) => hl.codigo !== action.codigo);

    case PURGE:
      return [];

    default:
      return state
  }
}

export function openSearchHistoricoLancamento(state = false, action) {
  switch (action.type) {
    case OPEN_HISTORICO_LANCAMENTO:
      return true;
    case CLOSE_HISTORICO_LANCAMENTO:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}