import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '80%',
    },
    pos: {
        marginBottom: 12,
    },
});

export default function LivroProfile({ livro }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Grid container justifyContent='space-evenly' spacing={3}>
                    <Grid item xs={5}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Livro
                        </Typography>
                        <Typography variant="body1" >
                            {livro.descricao}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
}