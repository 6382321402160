import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth
} from '../../util/FetchUtil';

const controller = 'usuario-instituicao';

const findByCurrentUser = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

export {
  findByCurrentUser,
}