import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { rltRendimentoAluno, rltBoletimAluno } from './api-relatorio';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { findByTurma } from "../AlunoTurma/api-aluno-turma";
import TurmaComboBox from '../Turma/UI/TurmaComboBox';
import BimestreComboBox from '../Bimestre/UI/BimestreComboBox';
import AlunoTurmaComboBox from '../AlunoTurma/UI/AlunoTurmaComboBox';
import { useHistory } from 'react-router-dom';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RelatorioAlunoBimestreForm({ turmas }) {
    const classes = useStyles();
    const [relatorioAluno, setRelatorioAluno] = useState({
        numeroBimestre: 1
    });
    const [loading, setLoading] = useState(false);
    const [turma, setTurma] = useState();
    const [alunosTurma, setAlunosTurma] = useState([]);
    const history = useHistory();



    useEffect(() => {
        if (turma) {
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunosTurma(data);
                }
            });
        }

    }, [turma]);

    const handleRendimentoEscolar = () => {
        setLoading(true);
        rltRendimentoAluno(relatorioAluno).then(data => {
            if (data) {
                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setLoading(false);
            }
        })
    }

    const handleRendimentoEscolarWeb = () => {
        if (!relatorioAluno.alunoTurma) {
            handleErrorSelecionarAluno();
        } else {
            history.push("/relatorio-rendimento-escolar-web", {
                numeroBimestre: relatorioAluno.numeroBimestre,
                codAlunosTurmas: relatorioAluno.alunoTurma ? [relatorioAluno.alunoTurma.codigo] : []
            })
        }

    }

    const handleErrorSelecionarAluno = () => {
        errorMessage("Selecione um Aluno.");
    }

    const handleBoletimEscolar = () => {
        setLoading(true);
        rltBoletimAluno(relatorioAluno).then(data => {
            if (data) {
                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setLoading(false);
            }
        })
    }

    const handleBimestreChange = (event) => {
        setRelatorioAluno({
            ...relatorioAluno,
            numeroBimestre: event.target.value
        });
    }

    const handleTurmaChange = (event) => {
        setTurma(turmas.find(t => t.codigo === event.target.value));
    }

    const handleAlunoTurmaChange = (event) => {
        setRelatorioAluno({
            ...relatorioAluno,
            alunoTurma: {
                codigo: event.target.value
            }
        });
    }

    return (
        <Container  component={Paper} >
            <Typography className={classes.title} variant="body1">
                Por Aluno e Bimestre
            </Typography>
            <form>
                <TurmaComboBox
                    turmas={turmas}
                    turma={turma}
                    handleTurmaChange={handleTurmaChange}
                />

                {turma ?
                    <AlunoTurmaComboBox
                        alunosTurma={alunosTurma}
                        alunoTurma={relatorioAluno.alunoTurma}
                        handleAlunoTurmaChange={handleAlunoTurmaChange}
                    /> : ''
                }


                <BimestreComboBox
                    numeroBimestre={relatorioAluno.numeroBimestre}
                    handleBimestreChange={handleBimestreChange}
                />
                {loading ?
                    <CircularProgress
                        className={classes.loading}
                        color="primary"
                    /> :
                    <>
                        <Button
                            component={Link}
                            to={{
                                pathname: '/relatorio-ata-final-web',
                                state: {
                                    codAlunoTurma: relatorioAluno.alunoTurma ? relatorioAluno.alunoTurma.codigo : null
                                }
                            }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Ata Final Web
                        </Button>
                        <Button
                            component={Link}
                            to={{
                                pathname: '/relatorio-boletim-web',
                                state: {
                                    numeroBimestre: relatorioAluno.numeroBimestre,
                                    codAlunosTurmas: relatorioAluno.alunoTurma ? [relatorioAluno.alunoTurma.codigo] : []
                                }
                            }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Boletim Web
                        </Button>
                        <Button
                            onClick={handleBoletimEscolar}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Boletim PDF (Habilitar Pop Up)
                        </Button>
                        <Button
                            onClick={handleRendimentoEscolar}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Rendimento Escolar
                        </Button>
                        <Button
                            onClick={handleRendimentoEscolarWeb}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Rendimento Escolar Web
                        </Button>
                    </>
                }

            </form>
        </Container>
    );
}