import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findByLivroDescricao } from './api-livro-instituicao';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { errorMessage } from '../../../util/ConfirmAlertUtil';


const useStyles = makeStyles({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
});


const LivroInstituicaoFindDialog = ({ openDialog, setOpenDialog, setLivrosInstituicao }) => {
    const classes = useStyles();
    const [livrosInstituicaoSearch, setLivrosInstituicaoSearch] = useState([]);
    const [opcoesBusca, setOpcoesBusca] = useState({
        por: 'descricao',
        busca: ''
    });

    const clickSubmit = (event) => {
        event.preventDefault();
        if (opcoesBusca.por === 'descricao') {
            findByLivroDescricao(opcoesBusca.busca).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setLivrosInstituicaoSearch(data);
                }

            });
        }
    }

    const handleBuscaChange = (event) => {
        setOpcoesBusca({
            ...opcoesBusca,
            [event.target.name]: event.target.value
        });

    }

    const handleSelectedLivro = (li) => {
        const livroPesist = {
            content: [li],
            totalElements: 1,
            number: 1,
            numberOfElements: 1
        }
        setLivrosInstituicao(livroPesist);
        setOpenDialog(false);
    }

    const handleClose = () => {
        setOpenDialog(false);
    };





    return (
        <Dialog
            open={openDialog ? openDialog : false}
            onClose={handleClose}
            fullWidth
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Procurar Livro</DialogTitle>
            <DialogContent>
                <form onSubmit={clickSubmit}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Buscar por:</FormLabel>
                        <RadioGroup aria-label="gender" name="por" value={opcoesBusca.por} onChange={handleBuscaChange}>
                            <FormControlLabel value="descricao" control={<Radio />} label="Descrição" />
                        </RadioGroup>
                    </FormControl>

                    {opcoesBusca.por === 'descricao' ?
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Busca"
                            name="busca"
                            autoFocus
                            onChange={handleBuscaChange}
                            value={opcoesBusca.busca || ''}
                        />
                        :
                        ''
                    }


                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Buscar
                    </Button>
                </form>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {livrosInstituicaoSearch.map((li) => (
                            <TableRow key={li.codigo}>
                                <TableCell>{li.livro.descricao}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleSelectedLivro(li)}
                                        variant="contained"
                                        color="secondary">
                                        Selecionar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LivroInstituicaoFindDialog;