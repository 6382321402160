import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import { findUsers, getUsuarioSala, findUsersAll } from "./api-mensagem";
import { useHistory } from 'react-router-dom';

import { RESPONSAVEL, ALUNO } from '../Usuario/Perfil';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formulario: {
        marginBottom: '20px'
    }
});


export default function MensagemFind() {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const [search, setSearch] = useState();
    const [usuarios, setUsuarios] = useState([]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (usuario && (usuario.perfil === RESPONSAVEL || usuario.perfil === ALUNO)) {
            setIsLoading(true);
            findUsersAll().then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setUsuarios(data);
                    setIsLoading(false);
                }
            })
        }
    }, [usuario]);

    const handleChange = (event) => {
        setSearch(event.target.value);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        findUsers(search).then((data) => {
            if (data) {
                setUsuarios(data);
            }
        });
    }

    const handleSelectedUser = (usuario) => {
        getUsuarioSala(usuario.id).then((data) => {
            if (data) {
                history.push('/sala-mensagem', { sala: data })
            }
        })
    }

    return (
        <>
            {isLoading ?
                <Loading description='Carregando Contatos...' />
                :
                <>
                    {usuario && usuario.perfil !== RESPONSAVEL && usuario.perfil !== ALUNO ?
                        <form className={classes.formulario} onSubmit={clickSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                                label="Nome"
                                name="nome"
                                onChange={handleChange}
                                value={search ? search : ''}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Buscar
                            </Button>
                        </form> : ''}

                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Perfil</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usuarios.map((usuario, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>
                                        {usuario && usuario.nome ? usuario.nome : ''}
                                    </TableCell>
                                    <TableCell>
                                        {usuario && usuario.perfil ? usuario.perfil : ''}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.submit}
                                            onClick={() => handleSelectedUser(usuario)}
                                        >
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>

            }

        </>
    );
}