import {
  SET_USUARIO,
  SET_PERFIL,
  OPEN_USUARIO,
  CLOSE_USUARIO,
  ADD_USUARIOS,
  UPDATE_USUARIOS,
  REMOVE_USUARIOS,
  SET_USUARIOS,
  SET_USUARIO_SEARCH
} from "./actionTypes"
import { PURGE } from "redux-persist";

export function usuario(state = null, action) {
  switch (action.type) {
    case SET_USUARIO:
      return action.usuario

    case SET_PERFIL:
      return {
        ...state,
        perfil: action.perfil
      }

    default:
      return state
  }
}

export function usuarioSearch(state = null, action) {
  switch (action.type) {
    case SET_USUARIO_SEARCH:
      return action.usuario

    default:
      return state
  }
}

export function openSearchUsuario(state = false, action) {
  switch (action.type) {
    case OPEN_USUARIO:
      return true;
    case CLOSE_USUARIO:
      return false;

    case PURGE:
      return false;

    default:
      return false;
  }
}

const initState = {
  content: [],
  totalElements: 0,
  number: 0,
  numberOfElements: 0
};

export function usuarios(state = initState, action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_USUARIOS:
      persist.content = [
        ...persist.content,
        action.usuario
      ]
      return persist

    case UPDATE_USUARIOS:
      let content = state.content.map((usuario) => {
        if (usuario.codigo === action.usuario.codigo) {
          return action.usuario;
        } else {
          return usuario;
        }
      });
      persist.content = content;
      return persist;

    case SET_USUARIOS:
      return action.usuarios

    case REMOVE_USUARIOS:

      persist.content.splice(action.index, 1);
      return persist;

    case PURGE:
      return initState;

    default:
      return state
  }
}
