import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as SelectMaterial,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

const Select = ({ label, value, list, onChange }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-label">{label}</InputLabel>
      <SelectMaterial labelId="select-label" value={value} onChange={onChange}>
        {list.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.description}
          </MenuItem>
        ))}
      </SelectMaterial>
    </FormControl>
  );
};

export default Select;
