import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByAlunoCodigoOrderByLivroDescricao, create, trocarHabilitado } from './api-aluno-livro';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { openLivro, setLivro } from '../Livro/actions/actions';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function AlunoLivroListAdd({ aluno }) {
    const classes = useStyles();
    const [alunoLivros, setAlunoLivros] = useState([]);
    const dispatch = useDispatch();
    const livro = useSelector(state => state.livro);

    useEffect(() => {
        dispatch(setLivro(null));
    }, [dispatch]);

    useEffect(() => {
        findByAlunoCodigoOrderByLivroDescricao(aluno.codigo).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAlunoLivros(data);
            }
        })
    }, [aluno]);

    useEffect(() => {
        if (livro) {
            let alunoLivro = {
                aluno: aluno,
                livro: livro
            }
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja incluir este livro para o aluno?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            create(alunoLivro).then((data) => {
                                console.log(data);
                                if (data.error) {
                                    if (data.message === 'Book already registered for this student') {
                                        toast.error("Livro já cadastrado para este aluno.", {
                                            position: toast.POSITION.BOTTOM_LEFT
                                        });
                                    } else {
                                        console.log(data);
                                    }
                                } else {
                                    setAlunoLivros(prevState => [
                                        ...prevState,
                                        data
                                    ]);
                                    toast.success("Livro cadastrado para o aluno com sucesso.", {
                                        position: toast.POSITION.BOTTOM_LEFT
                                    });
                                }
                            })
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }

    }, [livro, aluno]);


    const handleAdicionarLivro = () => {
        dispatch(setLivro(null));
        dispatch(openLivro());
    }

    const handleSwitchHabilitado = (codAlunoLivro) => {
        trocarHabilitado(codAlunoLivro).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAlunoLivros(
                    alunoLivros.map(al => {
                        if (al.codigo === codAlunoLivro) {
                            return data;
                        } else {
                            return al;
                        }
                    })
                )
            }
        })
    };

    return (
        <div>
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Container component={Paper}>
                <Button
                    onClick={() => handleAdicionarLivro()}
                    variant="contained"
                    className={classes.novoRegistro}
                    color="primary">
                    Adicionar Livro
                </Button>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Série</TableCell>
                            <TableCell>Disciplina</TableCell>
                            <TableCell>Habilitado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alunoLivros.map((alunoLivro) => (
                            <TableRow
                                key={alunoLivro.codigo}
                            >
                                <TableCell>{alunoLivro.livro.descricao}</TableCell>
                                <TableCell>{alunoLivro.livro.serie.descricao}</TableCell>
                                <TableCell>{alunoLivro.livro.disciplina.descricao}</TableCell>
                                <TableCell>
                                    <Switch
                                        checked={alunoLivro && alunoLivro.habilitado ? true : false}
                                        onChange={() => handleSwitchHabilitado(alunoLivro.codigo)}
                                    />
                                </TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>
        </div>
    );
}