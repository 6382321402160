import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { findByTurma } from './api-aluno-turma';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { setAlunoTurma, close } from './actions/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function AlunoTurmaTableSelectByTurma() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const [alunosTurma, setAlunosTurma] = useState([]);
    const dispatch = useDispatch();
    const openSearch = useSelector(state => state.openSearchAlunoTurma);

    useEffect(() => {
        if (tdp) {
            findByTurma(tdp.turma.codigo).then((data) => {
                if (data) {
                    setAlunosTurma(data);
                }
            })
        }


    }, [tdp, dispatch]);

    const handleClose = () => {
        dispatch(close());
    };

    return (
        <div>
            <CssBaseline />

            <Dialog open={openSearch} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Procurar Aluno</DialogTitle>
                <DialogContent>


                    <Container component={Paper}>
                        <Typography className={classes.title} variant="h5">
                            Alunos Turma
                </Typography>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Número Chamada</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alunosTurma.map((at) => (
                                    <TableRow key={at.codigo}>
                                        <TableCell>{at.aluno.nome}</TableCell>
                                        <TableCell>{at.numeroChamada}</TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => {
                                                    dispatch(setAlunoTurma(at));
                                                    dispatch(close());
                                                }}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Selecionar
                                    </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}