import React, { useEffect, useState } from "react";
import Container from "../../UI/Container";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PresencaUsuarioTable from "./PresencaUsuarioTable";
import { findByTdpUsuarioId, remove } from "../api-presenca";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import Loading from "../../UI/Loading";
import PresencaUsuarioForm from "./PresencaUsuarioForm";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { findApenasProfessoresAndAnoLetivo } from "../../Usuario/api-usuario";
import UsuarioTableSelectView from "../../Usuario/UsuarioTableSelectView";
import { useSelector } from "react-redux";
import { PROFESSOR } from "../../Usuario/Perfil";
import UsuarioProfile from "../../Usuario/UsuarioProfile";

const useStyles = makeStyles({
  paper: {
    padding: "20px",
  },
  title: {
    marginBottom: "20px",
  },
});

const PresencaUsuarioContainer = () => {
  const classes = useStyles();
  const [usuario, setUsuario] = useState(null);
  const [presencas, setPresencas] = useState({
    content: [],
    size: 0,
    totalElements: 0,
    number: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [presenca, setPresenca] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [isLoadingUsuarios, setIsLoadingUsuarios] = useState(false);
  const user = useSelector((state) => state.usuario);

  useEffect(() => {
    if (user.perfil !== PROFESSOR) {
      setIsLoadingUsuarios(true);
      findApenasProfessoresAndAnoLetivo().then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setUsuarios(data);
          }
        }
        setIsLoadingUsuarios(false);
      });
    }
  }, [user.perfil]);

  useEffect(() => {
    if (usuario && usuario.id) {
      fetchData(usuario.id, 0);
    } else if (user.perfil === PROFESSOR) {
      fetchData(user.id, 0);
    }
  }, [usuario, user]);

  const fetchData = (codUsuario, page) => {
    setIsLoading(true);
    findByTdpUsuarioId(codUsuario, page).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setPresencas(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleTrocaUsuario = () => {
    setUsuario(null);
  };

  const handleChangePage = (event, newPage) => {
    if (usuario && usuario.id) {
      fetchData(usuario.id, newPage);
    } else if (user.perfil === PROFESSOR) {
      fetchData(user.id, newPage);
    }
  };

  const removePresencas = (codPresenca) => {
    setPresencas((prevState) => ({
      ...prevState,
      content: prevState.content.filter((ps) => ps.codigo !== codPresenca),
    }));
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta presença?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  removePresencas(codigo);
                  setPresenca(null);
                  toast.success("Registro excluído com Sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                }
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Container className={classes.paper} component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography className={classes.title} variant="h6">
            Presença por Usuário
          </Typography>
        </Grid>
        {usuario || user.perfil === PROFESSOR ? (
          <>
            {usuario && (
              <Grid item>
                <UsuarioProfile
                  usuario={usuario}
                  handleTrocaUsuario={handleTrocaUsuario}
                />
              </Grid>
            )}

            <Grid item>
              {isLoading ? (
                <Loading description="Carregando Presenças..." />
              ) : (
                <>
                  {presenca ? (
                    <PresencaUsuarioForm
                      presenca={presenca}
                      setPresenca={setPresenca}
                      setPresencas={setPresencas}
                      turma={usuario}
                    />
                  ) : (
                    <PresencaUsuarioTable
                      presencas={presencas}
                      handleChangePage={handleChangePage}
                      isLoadingRemove={isLoadingRemove}
                      handleDelete={handleDelete}
                      setPresenca={setPresenca}
                    />
                  )}
                </>
              )}
            </Grid>
          </>
        ) : (
          <Grid item>
            {isLoadingUsuarios ? (
              <Loading description="Carregando Usuários..." />
            ) : (
              <UsuarioTableSelectView
                usuarios={usuarios}
                onUsuarioSelected={setUsuario}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PresencaUsuarioContainer;
