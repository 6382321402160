import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AtividadeAlunoTable from './AtividadeAlunoTable';
import AtividadeAlunoForm from './AtividadeAlunoForm';
import Grid from '@material-ui/core/Grid';
import AtividadeProfile from '../Atividade/AtividadeProfile';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useSelector, useDispatch } from 'react-redux';
import AlunoTurmaSelectByTurmaDialog from "../AlunoTurma/AlunoTurmaSelectByTurmaDialog";
import { open, setAlunoTurma } from "../AlunoTurma/actions/actions";
import { confirmAlert } from 'react-confirm-alert';
import { save } from './api-atividade-aluno';
import { addAtividadeAlunos } from "./actions/actions";

const useStyles = makeStyles({
    table: {
        paddingTop: '20px',
        margin: '20px'
    },
    containerTop: {
        padding: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});



export default function PresencaAlunoContainer() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const alunoTurma = useSelector(state => state.alunoTurma);
    const atividade = useSelector(state => state.atividade);

    useEffect(() => {
        dispatch(setAlunoTurma(null));
    }, [dispatch]);

    useEffect(() => {
        if (alunoTurma) {
            confirmAlert({
                title: 'Alerta',
                message: `Deseja Adicionar ${alunoTurma.aluno.nome} nesta atividade?`,
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            const atividadeAluno = {
                                atividade: atividade,
                                aluno: alunoTurma.aluno,
                                alunoTurma: alunoTurma
                            }
                            save(atividadeAluno).then((data) => {
                                if (data) {
                                    dispatch(addAtividadeAlunos(data));
                                    dispatch(setAlunoTurma(null));
                                }
                            })

                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }
    }, [alunoTurma, atividade, dispatch]);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddAluno = () => {
        dispatch(setAlunoTurma(null));
        dispatch(open());
    };

    return (
        <div>
            <AlunoTurmaSelectByTurmaDialog />
            <Container className={classes.paper} component={Paper}>
                <Grid className={classes.containerTop} container justify="space-between">
                    <Grid item xs={3}>
                        <Typography variant="h6">
                            Atividade Alunos
                        </Typography>
                    </Grid>
                    <Grid style={{ flexBasis: '5%' }} item xs={3}>
                        <MenuIcon
                            fontSize='small'
                            className={classes.cursorLink}
                            onClick={(event) => handleMenuClick(event)}
                            color="secondary"

                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleAddAluno} >
                                <AddBoxIcon style={{ marginRight: '5px', color: '#009900' }} /> Adicionar Aluno na Atividade
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <AtividadeProfile />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <AtividadeAlunoTable />
                    </Grid>
                    <Grid item xs={6}>
                        <AtividadeAlunoForm />
                    </Grid>
                </Grid>

            </Container>

        </div>
    );
}