import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AlunoCard from '../Aluno/UI/AlunoCard';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    gridAlunoCard: {
        cursor: 'pointer'
    },
    typographyAlunos: {
        marginTop: '20px',
        marginBottom: '20px',
    }
});

const MensagemFindTurmaAlunoTurmaList = ({ alunosTurmas, handleSelectedUser }) => {
    const classes = useStyles();

    const handleSelectedAT = (at) => {
        if (at.aluno.usuarioAluno) {
            handleSelectedUser(at.aluno.usuarioAluno);
        } else {
            errorMessage('Este Aluno não possui usuário. Informar administração!');

        }
    }

    return (
        <>
            <Typography
                variant="h5"
                className={classes.typographyAlunos}
            >
                Alunos
            </Typography>
            {alunosTurmas && alunosTurmas.length > 0 ?
                <>
                    {alunosTurmas.map((at) => (
                        <Grid
                            item
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            xs={12}
                            key={at.codigo}
                            onClick={() => handleSelectedAT(at)}
                            className={classes.gridAlunoCard}
                        >
                            <AlunoCard aluno={at.aluno} />
                        </Grid>
                    ))
                    }
                </> :
                null
            }
        </>
    );
}

export default MensagemFindTurmaAlunoTurmaList;