import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchPostAuthFile,
  fetchRemoveAuthReturn, 
  fetchGetAuthBlob
} from '../../../util/FetchUtil';

const controller = 'sala-mensagem/';

const findBySala = (codSala, page) => {
  const url = SERVER_URL + controller + codSala + "?page=" + page;
  return fetchGetAuth(url);
}

const save = (salaMensagem) => {
  const url = SERVER_URL + controller;
  console.log(salaMensagem);
  return fetchPostAuth(url, salaMensagem);
}

const attachFile = (codSala, fileForm) => {
  const url = SERVER_URL + controller + "upload-file/" + codSala;
  return fetchPostAuthFile(url, fileForm);
}

const removeByCodigo = (codigo) => {
  const url = SERVER_URL + controller + codigo;
  return fetchRemoveAuthReturn(url);

}

const downloadFile = (codigo, fileName, fileType) => {
  const url = SERVER_URL + controller + 'download-file/' + codigo;
  return fetchGetAuthBlob(url, fileName, fileType);
}

export {
  findBySala,
  save,
  attachFile,
  removeByCodigo, 
  downloadFile
}