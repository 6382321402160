import { Grid } from '@material-ui/core';
import React, { } from 'react';
import Form from '../UI/Form';
import TextField from '../UI/TextField';
import TextFieldReadOnly from '../UI/TextFieldReadOnly';
import InputImageByte from '../UI/InputImageByte';
import { getBase64 } from '../../util/Base64';
import { handleFileSizeException } from '../../util/ExceptionUtil';
import MuralTurma from './MuralTurma/MuralTurma';
import MuralFotoContainer from './MuralFoto/MuralFotoContainer';

const MuralForm = ({
    handleClose,
    handleRemove,
    mural,
    setMural,
    clickSubmit,
    isLoadingAction
}) => {

    const handleChangeImage = e => {
        let file = e.target.files[0];

        if (file.size > 10000000) {
            handleFileSizeException();
            return;
        }
        getBase64(file)
            .then(result => {
                setMural({
                    ...mural,
                    fotoCapa: result,
                    fileType: file.type,
                    fileName: file.name,
                    fileSize: file.size
                });
            })
            .catch(err => {
                console.log(err);
            });
    };



    const onChange = (event) => {
        setMural({
            ...mural,
            [event.target.name]: event.target.value
        })
    }

    return (
        <>
            <Form
                clickSubmit={clickSubmit}
                handleClose={handleClose}
                handleRemove={handleRemove}
                isLoadingAction={isLoadingAction}
            >

                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <TextField
                            label="Descrição"
                            value={mural.descricao ? mural.descricao : ''}
                            onChange={onChange}
                            name='descricao'
                        />
                    </Grid>
                    {mural.usuario ?
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <TextFieldReadOnly
                                label="Criado Por"
                                value={mural.usuario ? mural.usuario.nome : ''}
                            />
                        </Grid> : ''
                    }


                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <p>Foto Capa:</p>
                        <InputImageByte
                            image={mural.fotoCapa ? mural.fotoCapa : null}
                            handleChangeImage={handleChangeImage}
                        />
                    </Grid>




                </Grid>


            </Form>

            {mural.eNovo ? '' :
                <>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <MuralTurma
                            mural={mural}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <MuralFotoContainer
                            mural={mural}
                        />
                    </Grid>
                </>


            }
        </>
    );
}


export default MuralForm;