import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Grid, makeStyles, Switch } from '@material-ui/core';
import session from '../../../SessionHandler/session-helper';
import { toast } from 'react-toastify';
import Divider from '@material-ui/core/Divider';
import { ADMIN } from '../../Usuario/Perfil';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../../Usuario/actions/actions';
import { save } from '../../Instituicao/api-instituicao';
import { changePhoto, removeUserPhoto } from '../../Usuario/api-usuario';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import Button from '../../UI/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles({
    container: {
        paddingBottom: '20px'
    },
    submit: {
        marginTop: '20px',
        marginLeft: '20px'
    },
    fotoComponent: {
        width: '80px',
        borderRadius: '20px',
    },
    accountCircleIcon: {
        fontSize: 80,
        color: 'black'
    },
});

const Configuracao = () => {
    const [anoLetivo, setAnoLetivo] = useState();
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);

    useEffect(() => {
        setAnoLetivo(session.getAnoLetivo);
    }, [])

    const handleChangeAnoLetivo = (event) => {
        setAnoLetivo(event.target.value);

    };

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }

    const handleChangeImage = e => {
        const file = e.target.files[0];

        if (file === null) {
            return;
        }

        const fileFormData = new FormData();
        fileFormData.append('file', file);

        setIsLoadingAction(true);
        changePhoto(usuario.id, fileFormData).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setUsuario(data));
            }
            setIsLoadingAction(false);
        });
    };

    const handleRemoveImage = () => {
        setIsLoadingAction(true);
        removeUserPhoto(usuario.id).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setUsuario(data));
            }
            setIsLoadingAction(false);
        })


    };

    const clickSubmit = (event) => {
        event.preventDefault();
        session.setAnoLetivo(anoLetivo);
        toast.success("Ano Letivo da plataforma alterado com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
        });
        window.location.reload(false);

    }

    const handleSwitchChange = (name) => {
        let instituicao = {
            ...usuario.instituicao,
            [name]: !usuario.instituicao.comunicadoAprovacao
        }

        save(instituicao).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(
                    setUsuario(
                        {
                            ...usuario,
                            instituicao: data
                        }
                    )
                );
            }
        })

    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <form onSubmit={clickSubmit}>
                    <Grid container spacing={2} direction='row'>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Ano Letivo"
                                name="anoLetivo"
                                autoFocus
                                onChange={handleChangeAnoLetivo}
                                value={anoLetivo ? anoLetivo : ''}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                className={classes.submit}>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {usuario && usuario.perfil === ADMIN ?
                <>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <div style={{ marginLeft: '45px' }}>
                                    <Switch
                                        color="primary"
                                        checked={usuario &&
                                            usuario.instituicao.comunicadoAprovacao ?
                                            true :
                                            false
                                        }
                                        onChange={() => handleSwitchChange('comunicadoAprovacao')}
                                        value={usuario &&
                                            usuario.instituicao.comunicadoAprovacao ?
                                            true :
                                            false
                                        }
                                    />
                                </div>
                            }
                            label="Comunicado Aprovação Habilitador"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <div style={{ marginLeft: '45px' }}>
                                    <Switch
                                        color="primary"
                                        checked={usuario &&
                                            usuario.instituicao.smsLembreteHabilitado ?
                                            true :
                                            false
                                        }
                                        onChange={() => handleSwitchChange('smsLembreteHabilitado')}
                                        value={usuario &&
                                            usuario.instituicao.smsLembreteHabilitado ?
                                            true :
                                            false
                                        }
                                    />
                                </div>
                            }
                            label="SMS Lembrete Habilitado"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <div style={{ marginLeft: '45px' }}>
                                    <Switch
                                        color="primary"
                                        checked={usuario &&
                                            usuario.instituicao.smsPendenciaHabilitado ?
                                            true :
                                            false
                                        }
                                        onChange={() => handleSwitchChange('smsPendenciaHabilitado')}
                                        value={usuario &&
                                            usuario.instituicao.smsPendenciaHabilitado ?
                                            true :
                                            false
                                        }
                                    />
                                </div>
                            }
                            label="SMS Pendência Habilitado"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </> : ''
            }

            <Grid item xs={12}>
                <Grid container direction='row' spacing={3}>
                    <Grid item>
                        {usuario && usuario.fileName ?
                            <img
                                src={usuario.fotoUrl}
                                alt=''
                                className={classes.fotoComponent}
                            /> :
                            <AccountCircle className={classes.accountCircleIcon} />
                        }

                    </Grid>
                    <Grid item>
                        <Grid container direction='column' alignItems='center' spacing={1}>
                            <Grid item>
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    ref={hiddenFileInput}
                                    onChange={handleChangeImage}
                                    multiple={false}
                                />
                                <Button
                                    disabled={isLoadingAction}
                                    onClick={handleAttachFile}
                                >
                                    Alterar Foto
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={isLoadingAction}
                                    color='secondary'
                                    onClick={handleRemoveImage}
                                >
                                    Remover Foto
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Configuracao;