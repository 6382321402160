import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ComunicadoUsuarioForm from './ComunicadoUsuarioForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ComunicadoUsuarioFindDialog from './ComunicadoUsuarioFindDialog';
import { page, pageAndAlunoCodigo, pageAndAlunoCodigoAndDataAteAndDataDe, pageAndAlunoCodigoAndDataParaAteAndDataParaDe, save } from './api-comunicado-usuario';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import Container from '../../UI/Container';
import { useEffect } from 'react';
import ComunicadoUsuarioList from './ComunicadoUsuarioList';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RESPONSAVEL } from '../../Usuario/Perfil';
import TextFieldDate from '../../UI/TextFieldDate';
import { dateFormatMoment } from '../../../util/DateUtil';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    divBorderFiltro: {
        border: '1px solid black',
        padding: '20px',
        margin: '20px',
        borderRadius: '20px',
    }
});

export default function ComunicadoUsuarioContainer() {
    const classes = useStyles();
    const [comunicadoUsuario, setComunicadoUsuario] = useState(null);
    const [listCU, setListCU] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0

    })
    const [openSearch, setOpenSearch] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const todosKey = "todos";
    const [filtro, setFiltro] = useState(todosKey);
    const porAlunoKey = "porAluno";
    const porAlunoEDataHoraEnvioKey = "porAlunoEDataHoraEnvio";
    const porAlunoEDataHoraParaKey = "porAlunoEDataHoraPara";
    const user = useSelector(state => state.usuario);
    // seconds * minutes * hours * milliseconds = 1 day * 20 days 
    const [dataEnvioDe, setDataEnvioDe] = useState(new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 20)));
    const [dataEnvioAte, setDataEnvioAte] = useState(new Date());
    const [dataParaDe, setDataParaDe] = useState(new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 5)));
    const [dataParaAte, setDataParaAte] = useState(new Date(new Date().getTime() + (60 * 60 * 24 * 1000 * 5)));

    useEffect(() => {
        if (user.arAlunoSelecionado) {
            fetchData(0);

        } else {
            fetchFiltroTodos(0);
        }
        // eslint-disable-next-line
    }, [filtro, user.arAlunoSelecionado]);


    const fetchData = (newPage) => {
        if (filtro === todosKey) {
            fetchFiltroTodos(newPage);
        } else if (filtro === porAlunoKey) {
            setIsLoading(true);
            pageAndAlunoCodigo(newPage).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListCU(data);
                }
                setIsLoading(false);
            })
        } else if (filtro === porAlunoEDataHoraEnvioKey) {
            if (dataEnvioDe === null) {
                errorMessage("Data De não preenchida.");
                return;
            }
            if (dataEnvioAte === null) {
                errorMessage("Data Até não preenchida");
                return;
            }
            if (dataEnvioDe.getTime() >= dataEnvioAte.getTime()) {
                errorMessage("Data De não pode ser maior ou igual a Data Até.");
                return;
            }

            setIsLoading(true);
            pageAndAlunoCodigoAndDataAteAndDataDe(newPage, dataEnvioAte, dataEnvioDe).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListCU(data);
                }
                setIsLoading(false);
            })
        } else if (filtro === porAlunoEDataHoraParaKey) {
            if (dataParaDe === null) {
                errorMessage("Data De não preenchida.");
                return;
            }
            if (dataParaAte === null) {
                errorMessage("Data Até não preenchida");
                return;
            }
            if (dataParaDe.getTime() >= dataParaAte.getTime()) {
                errorMessage("Data De não pode ser maior ou igual a Data Até.");
                return;
            }

            setIsLoading(true);
            pageAndAlunoCodigoAndDataParaAteAndDataParaDe(newPage, dataEnvioAte, dataEnvioDe).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListCU(data);
                }
                setIsLoading(false);
            })
        }
    }

    const fetchFiltroTodos = (newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setListCU(data);
            }
            setIsLoading(false);
        })
    }

    const handleChangePage = (event, newPage) => {
        fetchData(newPage);
    };

    const handleRowClick = (comunicadoUsuario) => {
        if (!comunicadoUsuario.visualizado) {
            let persistent = {
                ...comunicadoUsuario,
                visualizado: true
            }
            save(persistent);
        }

        setComunicadoUsuario(comunicadoUsuario);
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurar = () => {
        handleMenuClose();
        setComunicadoUsuario(null)
        setOpenSearch(true);
    }

    const handleConfirmRead = () => {
        let persistent = {
            ...comunicadoUsuario,
            leituraConfirmada: true
        }
        save(persistent).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                listCU.map((cu) => {
                    if (cu.codigo === data.codigo) {
                        return data;
                    } else {
                        return cu;
                    }
                })
                setComunicadoUsuario(data)
            }
        })

    }

    const handleChangeFiltro = (event) => {
        setFiltro(event.target.value);
    }

    const handleDataDeChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataEnvioDe(data)
    }


    const handleDataAteChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataEnvioAte(data)
    }

    const handleDataParaDeChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataParaDe(data)
    }


    const handleDataParaAteChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setDataParaAte(data)
    }



    return (
        <Container component={Paper}>
            <ComunicadoUsuarioFindDialog
                openSearchComunicado={openSearch}
                setOpenSearchComunicado={setOpenSearch}
                setComunicadoUsuario={setComunicadoUsuario}
            />

            <Grid container spacing={3} direction='column'>
                <Grid item>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <Typography variant="h5">
                                Comunicados
                            </Typography>
                        </Grid>



                        {comunicadoUsuario && comunicadoUsuario.codigo ?
                            ''
                            :
                            <Grid style={{ flexBasis: '5%' }} item xs={3}>
                                <MenuIcon
                                    className={classes.cursorLink}
                                    onClick={(event) => handleMenuClick(event)}
                                    color="secondary"

                                />
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={() => handleProcurar()}>
                                        Procurar Comunicado
                                    </MenuItem>
                                </Menu>

                            </Grid>
                        }

                    </Grid>
                </Grid>
                {user.perfil === RESPONSAVEL &&
                    <Grid item xs={8}>
                        <div className={classes.divBorderFiltro}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Filtro</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={filtro} onChange={handleChangeFiltro}>
                                    <FormControlLabel value={todosKey} control={<Radio />} label="Todos" />
                                    <FormControlLabel value={porAlunoKey} control={<Radio />} label="Por Aluno" />
                                    <FormControlLabel value={porAlunoEDataHoraEnvioKey} control={<Radio />} label="Por Aluno e Data Hora Envio" />
                                    <FormControlLabel value={porAlunoEDataHoraParaKey} control={<Radio />} label="Por Aluno e Data Hora Para" />
                                </RadioGroup>
                            </FormControl>
                            {filtro === porAlunoEDataHoraEnvioKey &&
                                <Grid container >
                                    <Grid item xs={5}>
                                        <TextFieldDate
                                            label="De"
                                            value={dataEnvioDe ? dateFormatMoment(dataEnvioDe) : null}
                                            onChange={handleDataDeChange}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextFieldDate
                                            label="Até"
                                            value={dataEnvioAte ? dateFormatMoment(dataEnvioAte) : null}
                                            onChange={handleDataAteChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => fetch(0)}
                                            color="primary">
                                            Aplicar Datas
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            {filtro === porAlunoEDataHoraParaKey &&
                                <Grid container >
                                    <Grid item xs={5}>
                                        <TextFieldDate
                                            label="De"
                                            value={dataParaDe ? dateFormatMoment(dataParaDe) : null}
                                            onChange={handleDataParaDeChange}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextFieldDate
                                            label="Até"
                                            value={dataParaAte ? dateFormatMoment(dataParaAte) : null}
                                            onChange={handleDataParaAteChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => fetch(0)}
                                            color="primary">
                                            Aplicar Datas
                                        </Button>
                                    </Grid>
                                </Grid>
                            }


                        </div>
                    </Grid>
                }


                {comunicadoUsuario ?
                    <Grid item>
                        <ComunicadoUsuarioForm
                            handleConfirmRead={handleConfirmRead}
                            comunicadoUsuario={comunicadoUsuario}
                            setComunicadoUsuario={setComunicadoUsuario}
                        />
                    </Grid>
                    :
                    <Grid item>
                        <ComunicadoUsuarioList
                            listCU={listCU}
                            isLoading={isLoading}
                            handleChangePage={handleChangePage}
                            handleRowClick={handleRowClick}
                        />
                    </Grid>
                }
            </Grid>
        </Container>
    );
}