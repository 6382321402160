import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TDPTableSelectRedux from '../../TDP/TDPTableSelectRedux';
import TurmaDisciplinaProfessorProfileRedux from '../../TDP/TurmaDisciplinaProfessorProfileRedux';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AAAlunoTable from './AAAlunoTable';

const useStyles = makeStyles({
    paper: {

        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
});

export default function AAAlunoContainer() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h5">
                        Atividades
                    </Typography>
                </Grid>
            </Grid>
            {tdp ?
                <>
                    <TurmaDisciplinaProfessorProfileRedux />

                    <AAAlunoTable />

                </> :
                <TDPTableSelectRedux />
            }
        </Container>
    );
}