import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByEventoCodigo, getEngajamentoInfo } from './api-evento-usuario';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector } from 'react-redux';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Loading from '../../UI/Loading';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { TablePagination } from '@material-ui/core';
import TablePaginationActions from '../../../shared/components/Table/Actions';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    tableRow: {
        cursor: 'pointer'
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontSize: 'large',
        fontWeight: '700'
    }
});

export default function EngajamentoTable() {
    const classes = useStyles();
    const eventoRedux = useSelector(state => state.evento);
    const [eventoUsuarios, setEventoUsuarios] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    });
    const [isLoading, setIsLoading] = useState(true);
    const [engajamentoEvento, setEngajamentoEvento] = useState(null)

    useEffect(() => {
        fetchData(0);
        // eslint-disable-next-line
    }, [eventoRedux.codigo]);

    useEffect(() => {
        getEngajamentoInfo(eventoRedux.codigo).then(data => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setEngajamentoEvento(data);
            }
        })
    }, [eventoRedux.codigo, eventoUsuarios])


    const handleChangePage = (event, newPage) => {
        fetchData(newPage);
    };

    const fetchData = (page) => {
        setIsLoading(true);
        findByEventoCodigo(eventoRedux.codigo, page).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setEventoUsuarios(data);
            }
            setIsLoading(false);
        })
    }

    return (
        <div>
            <Container component={Paper}>
                <div >
                    <Typography className={classes.title} variant="h6">
                        Engajamento
                    </Typography>
                    {isLoading ? <Loading description="Carregando Engajamento..." /> :
                        <>
                            {engajamentoEvento ?
                                <>
                                    <p>Total: {engajamentoEvento.total}</p>
                                    <p>Visualizado: {engajamentoEvento.visualizado}</p>
                                    <p>Não Visualizado: {engajamentoEvento.naoVisualizado}</p>
                                    <p>Sim: {engajamentoEvento.sim}</p>
                                    <p>Não: {engajamentoEvento.nao}</p>
                                    <p>Talvez: {engajamentoEvento.talvez}</p>
                                    <p>Sem Resposata: {engajamentoEvento.semResposta}</p>
                                </>
                                : ''}
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.rowHeader}>Nome</TableCell>
                                        <TableCell className={classes.rowHeader}>Perfil</TableCell>
                                        <TableCell className={classes.rowHeader}>Visualizado</TableCell>
                                        <TableCell className={classes.rowHeader}>Resposta</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {eventoUsuarios.content.map((eventoUsuario, index) => (
                                        <TableRow
                                            key={eventoUsuario.codigo}
                                            className={classes.tableRow}
                                        >
                                            <TableCell>{eventoUsuario.usuario.nome}</TableCell>
                                            <TableCell>{eventoUsuario.usuario.perfil}</TableCell>
                                            <TableCell>
                                                {eventoUsuario.visualizado ?
                                                    <CheckCircle style={{ color: green[500] }} /> :
                                                    <Cancel style={{ color: red[500] }} />}
                                            </TableCell>
                                            <TableCell>
                                                {eventoUsuario.resposta ?
                                                    eventoUsuario.resposta :
                                                    ''}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                rowsPerPage={eventoUsuarios.size ? eventoUsuarios.size : 0}
                                component="div"
                                count={eventoUsuarios.totalElements}
                                page={eventoUsuarios.number}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                            />
                        </>
                    }

                </div>
            </Container>

        </div>
    );
}