import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AlunoTextFieldSelect from '../../Aluno/UI/AlunoTextFieldSelect';
import Container from '../../UI/Container';
import LancamentoTable from '../LancamentoTable';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router';
import LancamentoForm from '../LancamentoForm';
import { findByAlunoCodigo } from '../api-lancamento';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer',
        flexBasis: '5%'
    }
});

const FinanceiroInstituicaoContainer = () => {
    const classes = useStyles();
    const [aluno, setAluno] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const [lancamento, setLancamento] = useState(null);
    const [allLancamentos, setAllLancamentos] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({
        pendentes: true,
        todos: false
    });


    useEffect(() => {
        if (filter.pendentes) {
            setLancamentos(allLancamentos.filter((l) => !l.quitado && new Date(l.dataVencimento) < new Date()))
        } else {
            setLancamentos(allLancamentos)
        }

    }, [allLancamentos, filter.pendentes]);

    useEffect(() => {
        if (aluno && aluno.codigo) {
            setIsLoading(true);
            findByAlunoCodigo(aluno.codigo).then((data) => {
                if (data.error) {
                    if (data.message === 'User not Authorized.') {
                        errorMessage("Usuário sem autorização");
                    } else {
                        errorMessage(data);
                    }

                } else {
                    setAllLancamentos(data);
                }
                setIsLoading(false);
            })
        }

    }, [aluno]);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleGoToHistLanc = () => {
        history.push('/historico-lancamento')
    }

    const handleGoToSmsReminder = () => {
        history.push('/sms-reminder-lancamento')
    }

    const handleGoToSmsPendencia = () => {
        history.push('/sms-pendencia')
    }

    const addLancamentos = (l) => {
        setLancamentos([
            ...lancamentos,
            l
        ])
    }

    const updateLancamentos = (l) => {
        setLancamentos((prevState) => prevState.map((ps) => {
            if (ps.codigo === l.data) {
                return l;
            } else {
                return ps;
            }
        }))
    }

    const removeLancamentos = (codigo) => {
        setLancamentos((prevState) => prevState.filter((ps) => ps.codigo !== codigo));
    }

    return (
        <Container>
            <Grid container justifyContent="space-between">
                <Typography className={classes.title} variant="h6">
                    Financeiro
                </Typography>
                <MenuIcon
                    fontSize='small'
                    className={classes.cursorLink}
                    onClick={(event) => handleMenuClick(event)}
                    color="secondary"

                />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem
                        onClick={handleGoToHistLanc}
                    >
                        Histórico Lancamento
                    </MenuItem>
                    <MenuItem
                        onClick={handleGoToSmsReminder}
                    >
                        Sms Reminder
                    </MenuItem>
                    <MenuItem
                        onClick={handleGoToSmsPendencia}
                    >
                        Sms Pendência
                    </MenuItem>
                </Menu>
            </Grid>
            {!lancamento &&
                <AlunoTextFieldSelect
                    aluno={aluno}
                    setAluno={setAluno}
                />}
            {aluno && !lancamento ?
                <LancamentoTable
                    aluno={aluno}
                    setLancamento={setLancamento}
                    setFilter={setFilter}
                    isLoading={isLoading}
                    filter={filter}
                    lancamentos={lancamentos}
                /> : ''
            }
            {lancamento ?
                <LancamentoForm
                    aluno={aluno}
                    lancamento={lancamento}
                    setLancamento={setLancamento}
                    addLancamentos={addLancamentos}
                    updateLancamentos={updateLancamentos}
                    removeLancamentos={removeLancamentos}
                /> : ''}



        </Container>
    );
}

export default FinanceiroInstituicaoContainer;