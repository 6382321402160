import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { findByCodLivro, remove } from './api-capitulo';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LivroProfile from '../Livro/LivroProfile';
import { useDispatch, useSelector } from 'react-redux';
import { removeCapitulos, setCapitulo, setCapitulos } from './actions/actions';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    container: {
        marginTop: '20px',
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px',
        paddingBottom: '10px',
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function CapituloTable() {
    const classes = useStyles();
    const capitulos = useSelector(state => state.capitulos);
    const livroAutor = useSelector(state => state.livroAutor);
    const alunoLivro = useSelector(state => state.alunoLivro);
    const dispatch = useDispatch();

    useEffect(() => {
        if (livroAutor) {
            findByCodLivro(livroAutor.livro.codLivro).then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    dispatch(setCapitulos(data));
                }
            }).catch(err => {
                console.log(err);
            })
        }

    }, [livroAutor, dispatch]);

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este capítulo?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        const codCapitulo = capitulos[index].codCapitulo;
                        remove(codCapitulo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                dispatch(removeCapitulos(codCapitulo));
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleSelectCapitulo = (capitulo) => {
        dispatch(setCapitulo(capitulo));
    }

    return (
        <div>
            <LivroProfile livro={livroAutor ? livroAutor.livro : alunoLivro.livro} />
            <Container component={Paper} className={classes.container}>


                <Typography className={classes.title} variant="h6">
                    Capítulos
                </Typography>
                {livroAutor ?
                    <Link to={{
                        pathname: '/livro-capitulo'
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSelectCapitulo({
                                eNovo: true,
                                livro: livroAutor.livro
                            })}
                        >
                            Novo Capítulo
                        </Button>
                    </Link> : ''
                }

                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            {livroAutor ?
                                <TableCell>Ações</TableCell> : ''
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {capitulos ? capitulos.map((capitulo, index) => (
                            <TableRow
                                key={capitulo.codCapitulo}
                            >
                                <TableCell
                                    component={Link}
                                    to={{
                                        pathname: '/livro-capitulo'
                                    }}
                                    onClick={() => handleSelectCapitulo(capitulo)}
                                >{capitulo.descricao}</TableCell>
                                {livroAutor ?
                                    <TableCell>
                                        <DeleteIcon
                                            className={classes.cursorLink}
                                            onClick={() => { handleDelete(index) }}
                                            color="secondary"
                                            fontSize='small'
                                        />
                                    </TableCell> : ''
                                }

                            </TableRow>
                        )) : ''}
                    </TableBody>
                </Table>
            </Container>

        </div>
    );
}