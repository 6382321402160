import {
    ADD_TDPS, SET_TDPS, SET_TDP, UPDATE_TDPS
} from './actionTypes'

export function addTDPS (tdp) {
    return { type: ADD_TDPS, tdp }
}

export function setTDPS(tdps){
    return {type : SET_TDPS, tdps}
}

export function setTDP(tdp){
    return {type: SET_TDP, tdp}
}

export function updateTDPS(tdp){
    return {type : UPDATE_TDPS, tdp}
}