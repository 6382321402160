import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-cronograma-aula';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addCronogramasAula, updateCronogramasAula, setCronogramaAula as setCronogramaAulaRedux } from './actions/actions';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    },
    fieldGrid: {
        margin: '10px'
    }
}));

export default function CronogramaAulaForm() {
    const classes = useStyles();
    const [cronogramaAula, setCronogramaAula] = useState(useSelector(state => state.cronogramaAula));
    const dispatch = useDispatch();
    const [loadingSave, setLoadingSave] = useState(false);

    const handleChange = (event) => {
        setCronogramaAula({
            ...cronogramaAula,
            [event.target.name]: event.target.value
        })
    }

    const handleHorarioInicio = (data) => {
        console.log(data);
        setCronogramaAula((prevState) => ({
            ...prevState,
            horarioInicio: data
        }));
    }

    const handleHorarioFim = (data) => {
        setCronogramaAula((prevState) => ({
            ...prevState,
            horarioFim: data
        }));
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = cronogramaAula.eNovo;
        setLoadingSave(true);
        save(cronogramaAula).then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addCronogramasAula(data));
                } else {
                    dispatch(updateCronogramasAula(data));
                }
                dispatch(setCronogramaAulaRedux(data));
                setCronogramaAula(data);
            }
            setLoadingSave(false);

        })

    }




    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CloseIcon
                        fontSize='small'
                        onClick={() => dispatch(setCronogramaAulaRedux(null))} className={classes.closeIcon} />
                    <form onSubmit={clickSubmit}>
                        <Grid className={classes.fieldGrid}>
                            <TextField
                                variant="outlined"
                                required
                                size="small"
                                onChange={handleChange}
                                label="Descrição"
                                name="descricao"
                                value={cronogramaAula && cronogramaAula.descricao ?
                                    cronogramaAula.descricao : ''}
                            />

                        </Grid>
                        <Grid className={classes.fieldGrid}>
                            <TextField
                                variant="outlined"
                                required
                                size="small"
                                onChange={handleChange}
                                label="Ordem Aula"
                                name="ordemAula"
                                value={cronogramaAula && cronogramaAula.ordemAula ?
                                    cronogramaAula.ordemAula : ''}
                            />

                        </Grid>
                        <Grid className={classes.fieldGrid}>
                            <KeyboardTimePicker
                                required
                                ampm={false}
                                size="small"
                                disableToolbar
                                variant="outlined"
                                format="HH:mm"
                                margin="normal"
                                label="Horário Início"
                                value={cronogramaAula && cronogramaAula.horarioInicio ? cronogramaAula.horarioInicio : null}
                                onChange={handleHorarioInicio}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid className={classes.fieldGrid}>
                            <KeyboardTimePicker
                                required
                                ampm={false}
                                size="small"
                                disableToolbar
                                variant="outlined"
                                format="HH:mm"
                                margin="normal"
                                label="Horário Início"
                                value={cronogramaAula && cronogramaAula.horarioFim ? cronogramaAula.horarioFim : null}
                                onChange={handleHorarioFim}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>

                        <Button

                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={loadingSave}
                        >
                            {loadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                            Salvar
                        </Button>
                    </form>
                </MuiPickersUtilsProvider>
            </Container>

        </div>
    );
}