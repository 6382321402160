import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    container: {
        margin: '30px',
    }
})

const PrivatePolicyApp = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h2>POLÍTICA DE PRIVACIDADE</h2>
            <p>Este aplicativo é mantido e operado por Colesan Online.</p>
            <p>Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que
                utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses
                dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral
                de Proteção de Dados Pessoais - LGPD).</p>
            <p>Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos
                esta política de privacidade, que contém informações importantes sobre:</p>
            <ul>
                <li>Quem deve utilizar nosso aplicativo</li>
                <li>Quais dados coletamos e o que fazemos com eles;</li>
                <li>Seus direitos em relação aos seus dados pessoais; e</li>
                <li>Como entrar em contato conosco.</li>
            </ul>
            <p><strong>1. Dados que coletamos e motivos da coleta</strong></p>
            <p>Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de
                acordo com o disposto nesta seção.</p>
            <p><i>1. Dados sensíveis</i></p>
            <p><strong>Não</strong> serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles
                definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim,
                <strong>não</strong> haverá coleta de dados sobre origem racial ou étnica, convicção religiosa,
                opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico
                ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico,
                quando vinculado a uma pessoa natural.</p>
            <p><i>2. Coleta de dados não previstos expressamente</i></p>
            <p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política
                de Privacidade poderão ser coletados, desde que sejam fornecidos com o
                consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento
                em outra base legal prevista em lei.</p>
            <p>Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes
                serão informadas aos usuários do aplicativo.</p>
            <p><strong>2. Compartilhamento de dados pessoais com terceiros</strong></p>
            <p>Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é
                possível que o façamos para cumprir alguma determinação legal ou regulatória, ou,
                ainda, para cumprir alguma ordem expedida por autoridade pública.</p>
            <p><strong>3. Por quanto tempo seus dados pessoais serão armazenados</strong></p>
            <p>Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por
                período de tempo que corresponda ao necessário para atingir as finalidades
                elencadas neste documento e que considere os direitos de seus titulares, os direitos
                do controlador do aplicativo e as disposições legais ou regulatórias aplicáveis.</p>
            <p>Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são
                removidos de nossas bases de dados ou anonimizados, salvo nos casos em que
                houver a possibilidade ou a necessidade de armazenamento em virtude de
                disposição legal ou regulatória.</p>
            <p><strong>4. Bases legais para o tratamento de dados pessoais</strong></p>
            <p>Cada operação de tratamento de dados pessoais precisa ter um fundamento
                jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a
                autorize, prevista na Lei Geral de Proteção de Dados Pessoais.</p>
            <p>Todas as Nossas atividades de tratamento de dados pessoais possuem uma base
                legal que as fundamenta, dentre as permitidas pela legislação. Mais informações
                sobre as bases legais que utilizamos para operações de tratamento de dados
                pessoais específicas podem ser obtidas a partir de nossos canais de contato,
                informados ao final desta Política.</p>
            <p><strong>5. Direitos do usuário</strong></p>
            <p>O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção
                de Dados Pessoais:</p>
            <ul>
                <li>confirmação da existência de tratamento;</li>
                <li>acesso aos dados;</li>
                <li>correção de dados incompletos, inexatos ou desatualizados;</li>
                <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos
                    ou tratados em desconformidade com o disposto na lei;</li>
                <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante
                    requisição expressa, de acordo com a regulamentação da autoridade nacional,
                    observados os segredos comercial e industrial;</li>
                <li>eliminação dos dados pessoais tratados com o consentimento do titular, exceto3/4
                    nos casos previstos em lei;</li>
                <li>informação das entidades públicas e privadas com as quais o controlador
                    realizou uso compartilhado de dados;</li>
                <li>informação sobre a possibilidade de não fornecer consentimento e sobre as
                    consequências da negativa;</li>
                <li>revogação do consentimento.</li>
            </ul>
            <p>É importante destacar que, nos termos da LGPD, não existe um direito de
                eliminação de dados tratados com fundamento em bases legais distintas do
                consentimento, a menos que os dados seja desnecessários, excessivos ou tratados
                em desconformidade com o previsto na lei.</p>
            <p><i>1. Como o titular pode exercer seus direitos</i></p>
            <p>Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular
                dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras
                informações que possam auxiliar em sua correta identificação, a fim de resguardar
                nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for
                absolutamente necessário, e o requerente receberá todas as informações
                relacionadas</p>
            <p><strong>6. Medidas de segurança no tratamento de dados pessoais</strong></p>
            <p>Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais
                de acessos não autorizados e de situações de destruição, perda, extravio ou
                alteração desses dados.</p>
            <p>As medidas que utilizamos levam em consideração a natureza dos dados, o contexto
                e a finalidade do tratamento, os riscos que uma eventual violação geraria para os
                direitos e liberdades do usuário, e os padrões atualmente empregados no mercado
                por empresas semelhantes à nossa.</p>
            <p>Entre as medidas de segurança adotadas por nós, destacamos as seguintes:</p>
            <ul>
                <li>Armazenamento de senhas utilizando hashes criptográficos;</li>
                <li>Restrições de Acessos a bancos de dados.</li>
            </ul>
            <p>Ainda que adote tudo o que está ao seu alcance para evitar incidentes de
                segurança, é possível que ocorra algum problema motivado exclusivamente por um
                terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de
                culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere
                seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados
                pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação
                excepcional como essas, sobre as quais não temos nenhum tipo de controle.</p>
            <p>De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa
                gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os
                afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em
                conformidade com o disposto na Lei Geral de Proteção de Dados.</p>
            <p><strong>7. Reclamação a uma autoridade de controle</strong></p>
            <p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares
                de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar
                reclamação à Autoridade Nacional de Proteção de Dados.</p>
            <p><strong>8. Alterações nesta política</strong></p>
            <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em:
                19/02/2022.</p>
            <p>Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas,
                especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo,
                seja pela disponibilização de novas funcionalidades, seja pela supressão ou
                modificação daquelas já existentes</p>
            <p>Sempre que houver uma modificação, nossos usuários serão notificados acerca da
                mudança.</p>
            <p><strong>9. Como entrar em contato conosco</strong></p>
            <p>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os
                dados pessoais que tratamos, entre em contato com nosso Encarregado de
                Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:</p>
            <p><i>E-mail:</i> rafaelmiranda178@gmail.com</p>
            <p>Endereço postal: Rua Miguel Ferreira de Melo, 200 - Jardim Santo André - São
                Paulo - SP - CEP: 08390-000</p>
        </div>
    );
}

export default PrivatePolicyApp;