import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByComunicado, save, remove } from './api-comunicado-aluno';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import AlunoFindDialog from '../../Aluno/AlunoFindDialog';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    title: {
        paddingTop: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function ComunicadoAlunoTable({ comunicado }) {
    const classes = useStyles();
    const [comunicadoAlunos, setComunicadoAlunos] = useState([]);
    const [aluno, setAluno] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (aluno !== null) {
            const newComunicadoAluno = {
                comunicado: comunicado,
                aluno: aluno
            }
            save(newComunicadoAluno).then((data) => {
                if (data) {
                    if (data.error) {
                        toast.error(`Aluno ${aluno} já cadastrada.`, {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    } else {
                        setComunicadoAlunos(prevState => ([
                            ...prevState,
                            data
                        ]));
                    }

                }

            });
            setAluno(null)
        }
    }, [aluno, comunicado]);

    useEffect(() => {
        findByComunicado(comunicado.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setComunicadoAlunos(data);
            }
        })
    }, [comunicado.codigo]);


    const handleDelete = (index) => {
        if (!comunicado.enviado) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja deletar este aluno do comunicado?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            remove(comunicadoAlunos[index].codigo).then((data) => {
                                if (data && data.error) {
                                    console.log(data.error);
                                } else {
                                    setComunicadoAlunos(
                                        comunicadoAlunos.filter((ct) => ct.codigo !== comunicadoAlunos[index].codigo)
                                    );

                                }
                            });
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }

    };

    return (
        <Container component={Paper}>
            <AlunoFindDialog
                openSearch={open}
                setOpenSearch={setOpen}
                setAluno={setAluno}
            />
            <Typography className={classes.title} variant="h6">
                Comunicado Aluno
            </Typography>
            <Button
                onClick={() => {
                    setOpen(true);
                }}
                variant="contained"
                className={classes.novoRegistro}
                disabled={comunicado.enviado}
                color="primary"
            >
                Adicionar Aluno
            </Button>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Aluno</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {comunicadoAlunos.map((ca, index) => (
                        <TableRow key={ca.codigo}>
                            <TableCell>{ca.aluno.nome}</TableCell>
                            <TableCell>
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}