import {
    ADD_ATIVIDADES, 
    UPDATE_ATIVIDADES, 
    SET_ATIVIDADES, 
    REMOVE_ATIVIDADES,
    SET_ATIVIDADE, 
    SET_ATIVIDADE_NULL
} from './actionTypes'

export function addAtividades (atividade) {
    return { type: ADD_ATIVIDADES, atividade }
}

export function updateAtividades(atividade){
    return {type : UPDATE_ATIVIDADES, atividade}
}

export function setAtividades(atividades){
    return {type : SET_ATIVIDADES, atividades}
}

export function removeAtividades(codAtividade){
    return {type : REMOVE_ATIVIDADES, codAtividade}
}

export function setAtividade(atividade){
    return {type : SET_ATIVIDADE, atividade}
}

export function setAtividadeNull(){
    return {type : SET_ATIVIDADE_NULL}
}