import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setTDP } from '../TDP/actions/actions';
import { setAtividade } from '../Atividade/actions/actions';
import { setPresenca } from '../Presenca/actions/actions';

const useStyles = makeStyles({
    card: {
        width: 'fit-content'
    },
});

export default function TurmaDisciplinaProfessorProfile() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const dispatch = useDispatch();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Turma
                        </Typography>
                        <Typography variant="body1" >
                            {tdp.turma.descricao}
                        </Typography>
                    </div>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Disciplina
                        </Typography>
                        <Typography variant="body1" >
                            {tdp.disciplina.descricao}
                        </Typography>
                    </div>
                    <div style={{
                        marginRight: '30px'
                    }}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Professor
                        </Typography>
                        <Typography variant="body1" >
                            {tdp.usuario ? tdp.usuario.nome : 'Preencher'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Livro
                        </Typography>
                        <Typography variant="body1" >
                            {tdp.livro ? tdp.livro.descricao : 'Preencher'}
                        </Typography>
                    </div>
                </div>
                <CardActions>
                    <Button
                        onClick={() => {
                            dispatch(setTDP(null));
                            dispatch(setAtividade(null));
                            dispatch(setPresenca(null));
                        }}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >Trocar Turma/Disciplina</Button>
                </CardActions>

            </CardContent>
        </Card>
    );
}