import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import { findByTdpCodigoAndCurrentUser } from '../api-observacao-aluno';
import CssBaseline from '@material-ui/core/CssBaseline';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../../util/DateUtil';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    submit: {
        marginRight: '8px',
    },
    loading: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    rowHeader: {
        backgroundColor: '#b284d2',
        color: 'white',
        fontSize: 'large',
        fontWeight: '700'
    }
});


export default function ObservacaoAlunoTable() {
    const classes = useStyles();
    const [listaOA, setListaOA] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const tdp = useSelector(state => state.tdp);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        findByTdpCodigoAndCurrentUser(tdp.codigo, 0).then((data) => {
            if (data) {
                setListaOA(data);
                setLoading(false);
            }
        })
    }, [tdp.codigo]);

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        findByTdpCodigoAndCurrentUser(tdp.codigo, newPage).then((data) => {
            if (data) {
                setListaOA(data);
            }
            setLoading(false);
        });
    };

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>


                {loading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                        Carregando...
                    </div>
                    :
                    <div style={{ paddingTop: '32px' }}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>Data</TableCell>
                                    <TableCell className={classes.rowHeader}>Conteúdo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaOA.content.map((oa) => (
                                    <TableRow key={oa.codigo}>
                                        <TableCell>{oa.data ? dateFormat(oa.data) : ''}</TableCell>
                                        <TableCell>{oa.conteudo ? oa.conteudo : ''}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            rowsPerPage={listaOA.size ? listaOA.size : 0}
                            component="div"
                            count={listaOA.totalElements}
                            page={listaOA.number}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                        />
                    </div>
                }



            </Container>

        </div>
    );
}