import {
    ADD_USER, REMOVE_USER, SET_DISCIPLINAS, SET_DISCIPLINA, 
    UPDATE_DISCIPLINAS, INSERT_DISCIPLINAS, REMOVE_DISCIPLINAS
} from './actionTypes'

export function addUser (user) {
    return { type: ADD_USER, user }
}

export function removeUser () {
    return { type: REMOVE_USER }
}

export function setDisciplinas(disciplinas){
    return {type : SET_DISCIPLINAS, disciplinas}
}

export function setDisciplina(disciplina){
    return {type : SET_DISCIPLINA, disciplina}
}

export function updateDisciplinas(disciplina){
    return {type : UPDATE_DISCIPLINAS, disciplina}
}

export function insertDisciplinas(disciplina){
    return {type : INSERT_DISCIPLINAS, disciplina}
}

export function removeDisciplinas(codDisciplina){
    return {type : REMOVE_DISCIPLINAS, codDisciplina}
}