import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LivroIntituicaoTable from './Instituicao/LivroInstituicaoTable';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { openLivro, setLivro } from './actions/actions';
import AlunoLivroList from "../AlunoLivro/AlunoLivroList";
import LivroProfessorTable from "./Professor/LivroProfessorTable";
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { setLivrosAutor } from './Autor/actions/actions';
import { initState as initStateLivrosAutor } from './Autor/actions/reducers';
import { ADMIN, COORDENADOR, PROFESSOR } from '../Usuario/Perfil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function LivroContainer() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const usuario = useSelector(state => state.usuario);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurarLivro = () => {
        handleMenuClose();
        dispatch(setLivro(null));
        dispatch(openLivro());
    }

    const handleEditBook = () => {
        dispatch(setLivrosAutor(initStateLivrosAutor))
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Livros
                    </Typography>
                </Grid>
                {usuario && (usuario.perfil === ADMIN ||
                    usuario.perfil === COORDENADOR ||
                    usuario.perfil === PROFESSOR) ?
                    <Grid style={{ flexBasis: '5%' }} item xs={3}>
                        <MenuIcon
                            fontSize='small'
                            className={classes.cursorLink}
                            onClick={(event) => handleMenuClick(event)}
                            color="secondary"
                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleProcurarLivro()}>
                                <SearchIcon
                                    fontSize='small'
                                    color="secondary"
                                    style={{ marginRight: '10px' }} />
                                Procurar Livro
                            </MenuItem>
                            <MenuItem
                                onClick={handleEditBook}
                                component={Link}
                                to={{
                                    pathname: '/livro-autor',
                                }}
                            >
                                <EditIcon
                                    fontSize='small'
                                    color="secondary"
                                    style={{ marginRight: '10px' }} />
                                Editar Livro
                            </MenuItem>
                        </Menu>
                    </Grid>
                    :
                    ''
                }

            </Grid>

            <Grid >
                {usuario && (usuario.perfil === 'ADMIN' || usuario.perfil === 'COORDENADOR') ?
                    <>
                        <LivroIntituicaoTable />
                    </>
                    : ''
                }
                {usuario && usuario.perfil === 'PROFESSOR' ?
                    <LivroProfessorTable /> : ''
                }
                {usuario && usuario.perfil === 'ALUNO' ?
                    <AlunoLivroList /> : ''
                }
            </Grid>

        </Container>
    );
}