import {
  SET_NOTAS_BIMESTRE,
  SET_NOTA_BIMESTRE,
  UPDATE_NOTAS_BIMESTRE,
  REMOVE_NOTAS_BIMESTRE
} from './actionTypes';
import { PURGE } from "redux-persist";

export function notaBimestre(state = null, action) {
  switch (action.type) {
    case SET_NOTA_BIMESTRE:
      return action.notaBimestre
    case PURGE:
      return null;
    default:
      return state
  }
}

export function notasBimestre(state = [], action) {
  switch (action.type) {
    case SET_NOTAS_BIMESTRE:
      return action.notasBimestre


    case UPDATE_NOTAS_BIMESTRE:
      return state.map((nb) => {
        if (nb.codigo === action.notaBimestre.codigo) {
          return action.notaBimestre;
        } else {
          return nb;
        }
      });

    case REMOVE_NOTAS_BIMESTRE:
      return state.filter((nb) => nb.codigo !== action.codNotaBimestre);

    case PURGE:
      return [];

    default:
      return state
  }
}
