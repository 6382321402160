import { Grid } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import Loading from "../../UI/Loading";
import Select from "../../UI/Select";
import { findByPerfil } from "../../Usuario/api-usuario-perfil";
import { PROFESSOR } from "../../Usuario/Perfil";
import { findBySelectedYearAndTdpUsuarioId } from "../api-cronograma";
import CronogramaProfessorTable from "./CronogramaProfessorTable";

const CronogramaProfessorContainer = () => {
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState(null);
  const [isLoadingProfessores, setIsLoadingProfessores] = useState(false);
  const [cronogramas, setCronogramas] = useState([]);
  const [isLoadingCronogramas, setIsLoadingCronogramas] = useState(false);

  useEffect(() => {
    setIsLoadingProfessores(true);
    findByPerfil(PROFESSOR).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setProfessores(
          data.map((d) => ({
            description: d.usuario.nome,
            value: d.usuario.id,
          }))
        );
      }
      setIsLoadingProfessores(false);
    });
  }, []);

  useEffect(() => {
    if (professor && professor.value) {
      setIsLoadingCronogramas(true);
      findBySelectedYearAndTdpUsuarioId(professor.value).then((data) => {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCronogramas(data);
        }
        setIsLoadingCronogramas(false);
      });
    }
  }, [professor]);

  const onChange = (event) => {
    setProfessor(professores.find((p) => p.value === event.target.value));
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={3}>
        {isLoadingProfessores ? (
          <Loading description="Carregando Professores..." />
        ) : (
          <Select
            label={"Professor"}
            value={professor ? professor.value : ""}
            list={professores}
            onChange={onChange}
          />
        )}
      </Grid>
      <Grid item>
        {professor ? (
          <>
            {isLoadingCronogramas ? (
              <Loading description="Carregando Cronogramas" />
            ) : (
              <CronogramaProfessorTable cronogramas={cronogramas} />
            )}
          </>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default CronogramaProfessorContainer;
