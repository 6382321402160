import React, { useState } from 'react';
import LivroForm from "./LivroForm";
import LivroAutorTableAdd from './Autor/LivroAutorTableAdd';
import LivroInstituicaoTableAdd from './Instituicao/LivroInstituicaoTableAdd';

const LivroFormContainer = ({ history, location }) => {
    const [livro, setLivro] = useState(location.state.livro)

    return (
        <>
            <LivroForm
                history={history}
                livro={livro}
                setLivro={setLivro}
            />
            <div style={{ marginTop: '20px' }}>
                <LivroAutorTableAdd livro={livro} />
            </div>
            <div style={{ marginTop: '20px' }}>
                <LivroInstituicaoTableAdd livro={livro} />
            </div>
        </>
    );
}

export default LivroFormContainer;