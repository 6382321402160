import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { findByTurma } from "../AlunoTurma/api-aluno-turma";
import TurmaComboBox from '../Turma/UI/TurmaComboBox';
import AlunoTurmaComboBox from '../AlunoTurma/UI/AlunoTurmaComboBox';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        margin: '20px'
    }
}));

export default function RelatorioAlunoForm({ turmas }) {
    const classes = useStyles();
    const [relatorioAluno, setRelatorioAluno] = useState({
        numeroBimestre: 1
    });
    const [turma, setTurma] = useState();
    const [alunosTurma, setAlunosTurma] = useState([]);

    useEffect(() => {
        if (turma) {
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunosTurma(data);
                }
            });
        }

    }, [turma]);

    const handleTurmaChange = (event) => {
        setTurma(turmas.find(t => t.codigo === event.target.value));
    }

    const handleAlunoTurmaChange = (event) => {
        setRelatorioAluno({
            ...relatorioAluno,
            alunoTurma: alunosTurma.find((at) => at.codigo === event.target.value)
        });
    }

    return (
        <Container component={Paper} >
            <Typography className={classes.title} variant="body1">
                Por Aluno
            </Typography>
            <form>
                <TurmaComboBox
                    turmas={turmas}
                    turma={turma}
                    handleTurmaChange={handleTurmaChange}
                />
                {turma ?
                    <AlunoTurmaComboBox
                        alunosTurma={alunosTurma}
                        alunoTurma={relatorioAluno.alunoTurma}
                        handleAlunoTurmaChange={handleAlunoTurmaChange}
                    /> : ''
                }
                <Button
                    component={Link}
                    to={{
                        pathname: '/confere-registro-aula-falta',
                        state: {
                            alunoTurma: relatorioAluno.alunoTurma ? relatorioAluno.alunoTurma : null
                        }
                    }}
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Confere Registro Aulas e Faltas
                </Button>
                <Button
                    component={Link}
                    to={{
                        pathname: '/relatorio-frequencia-web',
                        state: {
                            alunoTurma: relatorioAluno.alunoTurma ? relatorioAluno.alunoTurma : null
                        }
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}>
                    Frequência
                </Button>


            </form>
        </Container>
    );
}