import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-observacao-turma';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { addObservacoesTurma, updateObservacoesTurma, setObservacaoTurma as setObservacaoTurmaRedux } from './actions/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { ptBR } from "date-fns/locale";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
}));

export default function ObservacaoTurmaForm() {
    const classes = useStyles();
    const observacaoTurmaRedux = useSelector(state => state.observacaoTurma);
    const tdp = useSelector(state => state.tdp);
    const [observacaoTurma, setObservacaoTurma] = useState({
        tdp: tdp
    });
    const dispatch = useDispatch();


    useEffect(() => {
        setObservacaoTurma(observacaoTurmaRedux);
    }, [observacaoTurmaRedux])



    const handleChange = (event) => {
        setObservacaoTurma({
            ...observacaoTurma,
            [event.target.name]: event.target.value
        })
    }

    const handleDataChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setObservacaoTurma({
            ...observacaoTurma,
            data: data
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        const codObservacaoTurma = observacaoTurma.codigo;
        save(observacaoTurma).then(data => {
            if (data) {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (codObservacaoTurma) {
                    dispatch(updateObservacoesTurma(data));
                } else {
                    dispatch(addObservacoesTurma(data));
                }
                setObservacaoTurma(data);
            }
        });
    }


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <Container className={classes.paper} component={Paper} maxWidth="sm">
                            <CloseIcon
                                onClick={() => dispatch(setObservacaoTurmaRedux(null))}
                                className={classes.closeIcon}
                                fontSize='small'
                            />
                            <form onSubmit={clickSubmit}>
                                <KeyboardDatePicker
                                    fullWidth
                                    required
                                    disableToolbar
                                    variant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data"
                                    value={observacaoTurma && observacaoTurma.data ? observacaoTurma.data : null}
                                    onChange={handleDataChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    rows="4"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    label="Conteúdo"
                                    name="conteudo"
                                    onChange={handleChange}
                                    value={observacaoTurma && observacaoTurma.conteudo ? observacaoTurma.conteudo : ''}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                    Salvar
                                </Button>
                            </form>
                        </Container>
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>

        </div>
    );
}