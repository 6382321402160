import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setCronogramaAula, closeCronogramaAula } from './actions/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { timeFormat } from '../../../util/DateUtil';
import { findAll } from './api-cronograma-aula';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: '100%',
    },
    title: {
        marginTop: '10px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    }
}));


export default function CronogramaAulaFindDialogRedux() {
    const classes = useStyles();
    const [cronogramasAula, setCronogramasAula] = useState([]);
    const dispatch = useDispatch();
    const openSearch = useSelector(state => state.openSearchCronogramaAula);

    useEffect(() => {
        findAll().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setCronogramasAula(data);
            }
        })
    }, []);


    const handleSelectedCronogramaAula = (cronogramaAula) => {
        dispatch(setCronogramaAula(cronogramaAula));
        dispatch(closeCronogramaAula());
    }

    const handleClose = () => {
        dispatch(closeCronogramaAula());
    };

    return (
        <div>

            <Dialog open={openSearch} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Procurar Cronograma Aula</DialogTitle>
                <DialogContent>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Ordem Aula</TableCell>
                                <TableCell>Horário</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cronogramasAula.map((ca) => (
                                <TableRow key={ca.codigo}>
                                    <TableCell>{ca.descricao}</TableCell>
                                    <TableCell>{ca.ordemAula}</TableCell>
                                    <TableCell>{timeFormat(ca.horarioInicio)} - {timeFormat(ca.horarioFim)}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => handleSelectedCronogramaAula(ca)}
                                            variant="contained"
                                            color="secondary">
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}