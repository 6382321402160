import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import { errorToast, successDefault } from "../../util/ToastUtil";
import Loading from "../UI/Loading";
import { page, remove, save } from "./api-local";
import LocalForm from "./LocalForm";
import LocalList from "./LocalList";

const useStyles = makeStyles({
  container: {
    marginTop: "20px",
  },
});

const LocalContainer = () => {
  const classes = useStyles();

  const [locais, setLocais] = useState({
    content: [],
    number: 0,
    size: 0,
    totalElements: 0,
  });
  const [local, setLocal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    page(0).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        setLocais(data);
      }
      setIsLoading(false);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setIsLoading(true);
    page(newPage).then((data) => {
      if (data.error) {
        errorMessage(data.error);
      } else {
        setLocais(data);
      }
      setIsLoading(false);
    });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    if (!local.descricao) {
      errorToast("Digite a Descrição.");
      return;
    }
    setIsLoadingAction(true);
    save(local).then((data) => {
      if (data.error) {
        errorMessage(data.message);
      } else {
        if (local.eNovo) {
          setLocais({
            ...locais,
            content: [...locais.content, local],
          });
        } else {
          setLocais((prevState) => ({
            ...prevState,
            content: prevState.content.map((ps) =>
              ps.codigo === local.codigo ? local : ps
            ),
          }));
        }
        setLocal(data);
        successDefault();
      }
      setIsLoadingAction(false);
    });
  };

  const handleRemove = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja remover este registro?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingAction(true);
            remove(local.codigo).then((data) => {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setLocais((prevState) => ({
                  ...prevState,
                  content: prevState.content.filter(
                    (ps) => ps.codigo !== local.codigo
                  ),
                }));
                setLocal(null);
                setIsLoadingAction(false);
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Container className={classes.container} component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6">Local</Typography>
        </Grid>
        {local ? (
          <Grid item>
            <LocalForm
              setLocal={setLocal}
              local={local}
              clickSubmit={clickSubmit}
              handleRemove={handleRemove}
              isLoadingAction={isLoadingAction}
            />
          </Grid>
        ) : (
          <Grid item>
            {isLoading ? (
              <Loading description="Carregando Locais..." />
            ) : (
              <LocalList
                locais={locais}
                setLocal={setLocal}
                handleChangePage={handleChangePage}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default LocalContainer;
