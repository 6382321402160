import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '../UI/Container';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { findYears } from '../Turma/api-turma';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
}));

export default function RltAnoLetivoForm() {
    const classes = useStyles();
    const [anoLetivo, setAnoLetivo] = useState(null);
    const [anosLetivos, setAnosLetivos] = useState([]);

    useEffect(() => {
        findYears().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                if (data.length > 0) {
                    setAnosLetivos(data);
                    setAnoLetivo(data[data.length - 1]);
                }

            }
        })
    }, []);

    const handleAnoLetivoChange = (event) => {
        setAnoLetivo(event.target.value);
    }



    return (
        <div>
            <Container >
                <Typography className={classes.title} variant="body1">
                    Por Ano Letivo
                </Typography>
                <form>
                    <FormControl required fullWidth >
                        <InputLabel id="ano-letivo-label">Ano Letivo</InputLabel>
                        <Select
                            labelId="ano-letivo-label"
                            id="ano-letivo-select"
                            value={anoLetivo ? anoLetivo : ''}
                            onChange={handleAnoLetivoChange}
                        >
                            {anosLetivos.map(a => (
                                <MenuItem key={a} value={a}>{a}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        component={Link}
                        to={{
                            pathname: '/aluno-sem-responsavel-financeiro',
                            state: {
                                anoLetivo: anoLetivo
                            }
                        }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Aluno sem Responsável Financeiro
                    </Button>
                    <Button
                        component={Link}
                        to={{
                            pathname: '/responsavel-financeiro-sem-celular',
                            state: {
                                anoLetivo: anoLetivo
                            }
                        }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Responsável Financeiro sem Celular
                    </Button>
                    <Button
                        component={Link}
                        to={{
                            pathname: '/responsavel-financeiro-nunca-acessou-sistema',
                            state: {
                                anoLetivo: anoLetivo
                            }
                        }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Responsável Financeiro nunca Acessaram Sistema
                    </Button>



                </form>
            </Container>
        </div>
    );
}