import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../../util/FetchUtil';

const controller = 'integral-rotina/';

const findByIntegralPresencaCodigo = (codIntegralPresenca) => {
  const url = SERVER_URL + controller + codIntegralPresenca;
  return fetchGetAuth(url);
}

const save = (rotina) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, rotina);
}

const findByPresencaCurrentUser = (codPresenca) => {
  const url = SERVER_URL + controller + "query?presenca-codigo=" + codPresenca;
  return fetchGetAuth(url);
}

export {
  findByIntegralPresencaCodigo,
  save,
  findByPresencaCurrentUser
}