import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import {
  uploadImagem as uploadImagemInCapitulo,
  remove as removeInCapitulo,
  save as saveInCapitulo
} from '../api-conteudo';
import {
  save as saveInSecao,
  remove as removeInSecao,
  uploadImagem as uploadImagemInSecao
} from '../../../Avaliacao/Conteudo/api-avaliacao-conteudo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import 'suneditor/dist/css/suneditor.min.css';
import { confirmAlert } from 'react-confirm-alert';
import { removeConteudos, addConteudos, updateConteudos } from "../actions/actions";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 650,
    marginTop: "20px",
    padding: '30px'
  },
  remove: {
    margin: theme.spacing(3, 3, 3),
  },
  submit: {
    margin: theme.spacing(3, 3, 3),
  },
  closeIcon: {
    marginBottom: '20px',
    cursor: 'pointer'
  },
  title: {
    paddingBottom: '20px',
  },
  buttonsBox: {
    margin: '20px',
    backgroundColor: 'lavender',
    padding: '30px',
    borderRadius: '25px',
    display: 'inline-block'
  },
  loading: {
    marginRight: '10px',
    marginLeft: '10px'
  },
}));

export default function ImagemForm({
  conteudoSelected,
  setConteudoSelected,
  setComponent
}) {
  const classes = useStyles();
  const [conteudo, setConteudo] = useState({
    ...conteudoSelected
  });
  const [imagemFile, setImagemFile] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);



  const handleUploadImage = (event) => {
    event.preventDefault();

    if (imagemFile === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append('imagemFile', imagemFile);
    setIsLoading(true);
    if (conteudo.secao) {
      uploadImagemInSecao(conteudo.secao.codigo, fileFormData).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });
          handleClose();
          dispatch(addConteudos(data));
        }
        setIsLoading(false);
      })
    } else if (conteudo.capitulo) {
      uploadImagemInCapitulo(conteudo.capitulo.codCapitulo, fileFormData).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });
          handleClose();
          dispatch(addConteudos(data));
        }
        setIsLoading(false);
      })
    }


  }

  const handleSaveLegenda = (event) => {
    event.preventDefault();
    if (conteudo.secao) {
      saveInSecao(conteudo).then((data) => {
        setIsLoading(false);
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });

          dispatch(updateConteudos(data));
        }
      })
    } else {
      saveInCapitulo(conteudo).then((data) => {
        setIsLoading(false);
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success("Salvo com sucesso.", {
            position: toast.POSITION.BOTTOM_LEFT
          });

          dispatch(updateConteudos(data));
        }
      })
    }


  }

  const handleImagemFile = event => {
    setImagemFile(event.target.files[0]);
  }

  const handleClose = () => {
    setConteudoSelected(null);
    setComponent(null);
  }

  const handleRemove = () => {
    confirmAlert({
      title: 'Alerta',
      message: 'Deseja deletar este conteúdo?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            if (conteudo.secao) {
              removeInSecao(conteudo.codigo).then((data) => {
                if (data) {
                  dispatch(removeConteudos(conteudo.codigo));
                  toast.success("Conteúdo deletado com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                  });
                }
              })
            } else {
              removeInCapitulo(conteudo.codigo).then((data) => {
                if (data) {
                  dispatch(removeConteudos(conteudo.codigo));
                  toast.success("Conteúdo deletado com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                  });
                }
              })
            }

          }
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  }

  const handleChange = (event) => {
    setConteudo({
      ...conteudo,
      imagem: {
        ...conteudo.imagem,
        [event.target.name]: event.target.value
      }
    })
  }

  return (
    <div>
      <CssBaseline />
      <ToastContainer autoClose={3000} />

      <Container className={classes.paper} maxWidth="xl" component={Paper} >
        {conteudo.eNovo ?
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleClose}
            fontSize='small'
          /> : ''}

        <Grid item >
          <form>

            <Typography className={classes.title} variant="h6">
              Imagem
            </Typography>

            {conteudo.eNovo ?
              <div style={{ display: 'flex' }}>
                Upload Imagem <input type="file" onChange={handleImagemFile} />
              </div>
              :
              <>
                <img
                  alt=''
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '20px'
                  }}
                  width={conteudo.imagem.largura ? conteudo.imagem.largura : 300}
                  src={conteudo.imagem.imagemUrl}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows="4"
                  inputProps={{ maxLength: 500 }}
                  fullWidth
                  label="Legenda"
                  name="legenda"
                  onChange={handleChange}
                  value={conteudo && conteudo.imagem.legenda ? conteudo.imagem.legenda : ''}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Largura"
                  name="largura"
                  onChange={handleChange}
                  value={conteudo && conteudo.imagem.largura ? conteudo.imagem.largura : ''}
                />
              </>
            }

            <div className={classes.buttonsBox}>
              {conteudo.eNovo ?
                <Button
                  onClick={handleUploadImage}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  className={classes.submit}>
                  {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                  Upload Imagem
                </Button> : ''}
              {!conteudo.eNovo ?
                <>
                  <Button
                    onClick={handleSaveLegenda}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    className={classes.submit}>
                    Salvar
                  </Button>
                  <Button
                    onClick={handleRemove}
                    variant="contained"
                    color="secondary"
                    className={classes.remove}
                  >
                    Remover
                  </Button>

                </>
                : ''}

            </div>
          </form>
        </Grid>
      </Container>
    </div>
  );
}