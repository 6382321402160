import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth, fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'progress-log';

const findByServico = (servico) => {
  const url = SERVER_URL + controller + '/servico/' + servico;
  return fetchGetAuth(url);
}

const cancel = (progressLog) => {
  const url = SERVER_URL + controller + '/cancel';
  return fetchPostAuth(url, progressLog);
}

export {
  findByServico,
  cancel
}