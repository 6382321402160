import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import MuralCard from '../MuralCard';
import { page } from './api-mural-usuario';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        flex: '1 1 100%',
        marginBottom: '20px',
        marginTop: '20px'
    },
})

const MuralUsuarioContainer = () => {
    const classes = useStyles();
    const [pageMuralUsuario, setPageMuralUsuario] = useState({
        content: [],
        size: 0,
        number: -1,
        totalPages: null
    })

    useEffect(() => {
        page(pageMuralUsuario.number + 1).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                if (!data.empty) {
                    setPageMuralUsuario(prevState => ({
                        ...data,
                        content: [
                            ...data.content,
                            ...prevState.content
                        ]
                    }))
                }

            }
        })

    }, [pageMuralUsuario.number]);


    return (
        <>
            {pageMuralUsuario.content.length > 0 ?
                <Container className={classes.paper} component={Paper}>
                    <Typography className={classes.title} variant="h5">
                        Mural de Fotos
                    </Typography>
                    {pageMuralUsuario.content.map(muralUsuario => (
                        <div key={muralUsuario.codigo}>
                            <MuralCard
                                mural={muralUsuario.mural}
                            />
                        </div>

                    ))}
                </Container> : ''
            }

        </>
    )
}

export default MuralUsuarioContainer;