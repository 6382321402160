import React, { useState } from "react";
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import { ALUNO, RESPONSAVEL } from '../../../Usuario/Perfil';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  boxOD: {
    padding: '20px',
    borderRadius: '20px',
    borderWidth: 'thick',
    borderColor: 'chocolate',
    borderStyle: 'solid',
    margin: '20px',
  },
  titleOD: {
    fontSize: 'x-large',
    fontWeight: 'bold',
    fontFamily: 'serif',
    color: 'brown',
  }
}));

const Texto = (props) => {
  const [conteudo] = useState(props.conteudo)
  const usuario = useSelector(state => state.usuario);
  const classes = useStyles();

  return (
    <>
      {conteudo.texto.orientacaoDidatica &&
        usuario &&
        usuario.perfil !== ALUNO &&
        usuario.perfil !== RESPONSAVEL ?
        <div className={conteudo.texto.orientacaoDidatica ? classes.boxOD : ''} >
          <p className={classes.titleOD}>Orientação Didática</p>
          {parse(conteudo.texto.descricao)}
        </div> :
        !conteudo.texto.orientacaoDidatica ? parse(conteudo.texto.descricao) : ''
      }
    </>
  );
}

export default Texto;

