import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    buttonWithImage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer'
    },
    text: {
        textAlign: 'center',
        fontSize: 20
    },
    image: {
        width: '60px',
        height: '60px',
        resizeMode: 'contain',
        marginBottom: '10px'
    },
})

const ButtonImageLabel = ({ description, image, onClick }) => {
    const classes = useStyles();

    return (
        <div
            className={classes.buttonWithImage}
            onClick={onClick}
        >
            <img className={classes.image} src={image} alt='' />
            <div className={classes.text}>{description}</div>
        </div>

    );
}

export default ButtonImageLabel;