import {
    SET_CRONOGRAMA,
    SET_CRONOGRAMAS,
    ADD_CRONOGRAMAS,
    UPDATE_CRONOGRAMAS,
    REMOVE_CRONOGRAMAS,
} from './actionTypes'

export function setCronograma(cronograma) {
    return { type: SET_CRONOGRAMA, cronograma }
}

export function setCronogramas(cronogramas) {
    return { type: SET_CRONOGRAMAS, cronogramas }
}

export function addCronogramas(cronograma) {
    return { type: ADD_CRONOGRAMAS, cronograma }
}

export function updateCronogramas(cronograma) {
    return { type: UPDATE_CRONOGRAMAS, cronograma }
}

export function removeCronogramas(codigo) {
    return { type: REMOVE_CRONOGRAMAS, codigo }
}
