import {
    OPEN_SIMPLE_DIALOG, CLOSE_SIMPLE_DIALOG
} from './actionTypes'


export function open(){
    return {type: OPEN_SIMPLE_DIALOG}
}

export function close(){
    return {type: CLOSE_SIMPLE_DIALOG}
}

