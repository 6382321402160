import React from 'react';
import { Container as ContainerMaterial } from '@material-ui/core';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    }
});

const Container = ({ children }) => {
    const classes = useStyles();
    return (
        <ContainerMaterial
            maxWidth={false}
            className={classes.paper}
            component={Paper}
        >
            {children}
        </ContainerMaterial>
    )
}

export default Container;