import React, { useState, useEffect } from 'react';
import Adesao from "./Engajamento/Adesao";
import Acesso from "./Engajamento/Acesso";
import AlunoResponsavelAtivo from './AlunoResponsavel/AlunoResponsavelAtivo';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { currentUser } from './Usuario/api-usuario';
import { setUsuario as setUsuarioRedux } from './Usuario/actions/actions';
import { useDispatch } from 'react-redux';
import MuralUsuarioContainer from './Mural/MuralUsuario/MuralUsuarioContainer';
import { ADMIN, COORDENADOR, perfilText, PROFESSOR, SECRETARIA } from './Usuario/Perfil';
import { errorMessage } from '../util/ConfirmAlertUtil';
import { Button, Divider, Grid } from '@material-ui/core';
import TarefaContainer from './Tarefa/TarefaContainer';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        marginBottom: '20px'
    },
    bold: {
        fontWeight: 'bold'
    },
    componentExternal: {
        marginBottom: '20px'
    }
});

export default function Home() {
    const classes = useStyles();
    const [usuario, setUsuario] = useState();
    const dispatch = useDispatch();
    const adesaoAcessoKey = 'adesao-acesso';
    const tarefaKey = 'tarefa';
    const [menu, setMenu] = useState(tarefaKey);

    useEffect(() => {
        currentUser().then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setUsuario(data);
                    dispatch(setUsuarioRedux(data));
                    document.title = data.instituicao.descricao
                }
            }

        })
    }, [dispatch])


    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.bold} variant="body1">
                    Seja Bem Vindo
                </Typography>
                <Typography variant="body1">
                    <span className={classes.bold}>Usuário:</span> {usuario ? usuario.nome : ''}
                </Typography>
                <Typography variant="body1">
                    <span className={classes.bold}>Perfil:</span> {usuario ? perfilText(usuario.perfil) : ''}
                </Typography>
            </Container>

            <Container className={classes.paper} component={Paper}>

                {usuario &&
                    (usuario.perfil === ADMIN ||
                        usuario.perfil === COORDENADOR ||
                        usuario.perfil === SECRETARIA ||
                        usuario.perfil === PROFESSOR) ?
                    <Grid container spacing={3} direction='column'>
                        <Grid item>
                            <Grid container spacing={3} direction='row'>
                                <Grid item>
                                    <Button
                                        onClick={() => setMenu(tarefaKey)}
                                        variant='outlined'
                                    >
                                        Tarefa
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => setMenu(adesaoAcessoKey)}
                                        variant='outlined'
                                    >
                                        Adesão/Acesso
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        {menu === adesaoAcessoKey ?
                            <>
                                <Grid item>
                                    <Adesao />
                                </Grid>
                                <Grid item>
                                    <Acesso />
                                </Grid>
                            </>
                            :
                            <Grid item>
                                <TarefaContainer />
                            </Grid>

                        }


                    </Grid> :
                    ''}


            </Container>
            {usuario && usuario.perfil === 'RESPONSAVEL' ?
                <>
                    <div className={classes.componentExternal}>
                        <MuralUsuarioContainer />
                    </div>
                    <AlunoResponsavelAtivo />
                </> :
                ''}
        </div>
    );
}