export const UPDATE_EVENTOS = 'UPDATE_EVENTOS'
export const ADD_EVENTOS = 'ADD_EVENTOS'
export const SET_EVENTOS = 'SET_EVENTOS'
export const REMOVE_EVENTOS = 'REMOVE_EVENTOS'
export const SET_EVENTO = 'SET_EVENTO'
export const OPEN_SEARCH_EVENTO = 'OPEN_SEARCH_EVENTO'
export const CLOSE_SEARCH_EVENTO = 'CLOSE_SEARCH_EVENTO'
export const SET_QUANT_APROVACAO_EVENTO = 'SET_QUANT_APROVACAO_EVENTO'
export const DECREASE_QUANT_APROVACAO_EVENTO = 'DECREASE_QUANT_APROVACAO_EVENTO'
export const INCREASE_QUANT_APROVACAO_EVENTO = 'INCREASE_QUANT_APROVACAO_EVENTO'
