import React, { } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog as DialogMaterial } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

export default function Dialog({ openDialog, setOpenDialog, title, description }) {

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <DialogMaterial open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>

                <Typography>
                    {description}
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </DialogMaterial>
    );
}