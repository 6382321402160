import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import EventoForm from './EventoForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import EventoTable from "./EventoTable";
import EventoTurmaTable from "../EventoTurma/EventoTurmaTable";
import TurmaFindDialogRedux from "../../Turma/TurmaSelectDialogRedux";
import EngajamentoTable from "../EventoUsuario/EngajamentoTable";
import EventoAlunoTable from "../EventoAluno/EventoAlunoTable";
import AlunoFindDialogRedux from "../../Aluno/AlunoFindDialogRedux";

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function ComunicadoContainer() {
    const classes = useStyles();
    const evento = useSelector(state => state.evento);

    return (
        <div>
            <TurmaFindDialogRedux />
            <AlunoFindDialogRedux />
            <Container className={classes.paper} component={Paper}>

                <Typography className={classes.title} variant="h6">
                    Eventos
                </Typography>

                <Grid style={{ marginBottom: '20px' }} container justify="space-around">


                    {evento ?
                        <Grid item xs={7}>
                            <EventoForm />
                        </Grid>
                        : ''}


                    <Grid item xs={4}>
                        {evento && evento.codigo && evento.enviarPor === 'Turma' ?
                            <EventoTurmaTable />
                            : ''}
                        {evento && evento.codigo && evento.enviarPor === 'Aluno' ?
                            <EventoAlunoTable />
                            : ''}
                    </Grid>



                </Grid>
                <Grid>
                    {evento ?
                        ''
                        : <EventoTable />}
                </Grid>
                <Grid>
                    {evento && evento.codigo && evento.enviado ?
                        <EngajamentoTable />
                        : ''}
                </Grid>
            </Container>
        </div>
    );
}