import { makeStyles } from '@material-ui/core';
import React from 'react';
import ContainerWithGoBack from './ContainerWithGoBack';

const useStyles = makeStyles({
    divContent: {
        flex: 1,
        marginTop: '20px',
        marginBottom: '20px',

    },
    divImageFullScreen: {
        width: '45%',
        resizeMode: 'contain',
    },

});

const ImageUriFullScreen = (props) => {
    const classes = useStyles();
    const uri = props.location.state.uri;

    return (
        <ContainerWithGoBack>
            <div className={classes.divContent}>
                <img
                    className={classes.divImageFullScreen}
                    alt=""
                    src={uri}
                />
            </div>
        </ContainerWithGoBack>
    );
}

export default ImageUriFullScreen;