import {
  SET_SERIE,
  ADD_SERIES,
  UPDATE_SERIES,
  REMOVE_SERIES,
  SET_SERIES
} from "./actionTypes";
import { PURGE } from "redux-persist";

export function serie(state = null, action) {
  switch (action.type) {
    case SET_SERIE:
      return action.serie

    case PURGE:
      return null;

    default:
      return state
  }
}

export function series(state = [], action) {
  switch (action.type) {

    case ADD_SERIES:

      return [
        ...state,
        action.serie
      ]

    case UPDATE_SERIES:
      let content = state.map((serie) => {
        if (serie.codSerie === action.serie.codSerie) {
          return action.serie;
        } else {
          return serie;
        }
      });
      return content;

    case SET_SERIES:
      return action.series

    case REMOVE_SERIES:
      return state.filter((serie) => serie.codSerie !== action.codSerie);

    case PURGE:
      return [];

    default:
      return state
  }
}
