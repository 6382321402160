import React, {  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '../UI/Container';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
}));

export default function RltAvancado() {
    const classes = useStyles();

    return (
        <div>
            <Container >
                <Typography className={classes.title} variant="body1">
                    Avançado
                </Typography>
                <form>
                    <Button
                        component={Link}
                        to={{
                            pathname: '/relatorio-frequencia-por-aluno-mes-ano-letivo',
                        }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Frequência por Aluno, Mês e Ano Letivo
                    </Button>
                </form>
            </Container>
        </div>
    );
}