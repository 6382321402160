import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuth } from '../../util/FetchUtil';
import session from '../../SessionHandler/session-helper';

const controller = 'presenca-capitulo/';

const findUnregisteredCapitulos = (codPresenca) => {
  const url = SERVER_URL + controller + 'unregistered-capitulos/' + codPresenca;
  return fetchGetAuth(url);
}

const findByPresenca = (codPresenca) => {
  const url = SERVER_URL + controller + 'presenca/' + codPresenca;
  return fetchGetAuth(url);
}

const remove = (codigo) => {
  const url = SERVER_URL + controller + 'delete/' + codigo;
  return fetchRemoveAuth(url);
}

const save = (presencaCapitulo) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, presencaCapitulo);
}

const findPCP = (codTdp) => {
  const url = SERVER_URL + controller + 'presenca-capitulo-planejamento/' + codTdp;
  return fetchGetAuth(url);
}

const findPCPTodos = () => {
  const url = SERVER_URL + controller + 'presenca-capitulo-planejamento-todos/' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

export {
  findUnregisteredCapitulos,
  save,
  remove, 
  findByPresenca, 
  findPCP, 
  findPCPTodos
}