import React, { useEffect, useState } from 'react';
import { page, deleteById, save } from './api-mural';
import Button from '../UI/Button';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import MuralForm from './MuralForm';
import MuralTable from './MuralTable';
import { confirmAlert } from 'react-confirm-alert';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import Loading from '../UI/Loading';

const useStyles = makeStyles({
    container: {
        padding: '20px',
        backgroundColor: '#fff'
    }
})

const MuralContainer = () => {
    const classes = useStyles();
    const [pageMural, setPageMural] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    })
    const [mural, setMural] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        page(0).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setPageMural(data);
            }
            setIsLoading(false);
        })
    }, []);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setPageMural(data);
            }
            setIsLoading(false);
        });
    };


    const handleClose = () => {
        setMural(null);
    }

    const handleRemove = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este mural?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleteById(mural.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data.message);
                            } else {
                                const result = pageMural.content.filter(m => m.codigo !== mural.codigo);
                                setPageMural({
                                    ...pageMural,
                                    content: [
                                        ...result
                                    ]
                                })
                                setMural(null);

                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingAction(true);
        const eNovo = mural.eNovo;
        save(mural).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setMural(data);
                if (eNovo) {
                    setPageMural({
                        ...pageMural,
                        content: [
                            data,
                            ...pageMural.content
                        ]
                    })
                } else {
                    const result = pageMural.content.map(m => {
                        if (m.codigo === data.codigo) {
                            return data;
                        } else {
                            return m
                        }
                    })
                    setPageMural({
                        ...pageMural,
                        content: [
                            ...result
                        ]
                    })
                }

            }
            setIsLoadingAction(false);
        })
    }


    return (
        <div
            className={classes.container}
        >
            {isLoading ? <Loading description="Carregando Mural..." /> :
                <>
                    <Button
                        onClick={() => {
                            setMural({
                                eNovo: true
                            })
                        }}
                    >
                        Novo Mural
                    </Button>
                    {mural ?
                        <MuralForm
                            handleClose={handleClose}
                            handleRemove={handleRemove}
                            mural={mural}
                            setMural={setMural}
                            clickSubmit={clickSubmit}
                            isLoadingAction={isLoadingAction}
                        /> :
                        <MuralTable
                            pageMural={pageMural}
                            handleChangePage={handleChangePage}
                            setMural={setMural}
                        />
                    }
                </>
            }


        </div>
    );
}

export default MuralContainer;