import {
    UPDATE_ATIVIDADE_ALUNOS, 
    SET_ATIVIDADE_ALUNOS, 
    SET_ATIVIDADE_ALUNO, 
    ADD_ATIVIDADE_ALUNOS, 
    REMOVE_ATIVIDADE_ALUNOS
} from './actionTypes'

export function updateAtividadeAlunos(atividadeAluno){
    return {type : UPDATE_ATIVIDADE_ALUNOS, atividadeAluno}
}

export function setAtividadeAlunos(atividadeAlunos){
    return {type : SET_ATIVIDADE_ALUNOS, atividadeAlunos}
}

export function setAtividadeAluno(atividadeAluno){
    return {type : SET_ATIVIDADE_ALUNO, atividadeAluno}
}

export function addAtividadeAlunos(atividadeAluno){
    return {type : ADD_ATIVIDADE_ALUNOS, atividadeAluno}
}

export function removeAtividadeAlunos(codAtividadeAluno){
    return {type : REMOVE_ATIVIDADE_ALUNOS, codAtividadeAluno}
}