import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-presenca';
import { toast } from 'react-toastify';
import PresencaCapituloTable from '../PresencaCapitulo/PresencaCapituloTable';
import Grid from '@material-ui/core/Grid';
import { dateFormatMoment, timeFormat } from '../../util/DateUtil';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldDate from '../UI/TextFieldDate';
import PresencaAlunoTableSwitch from '../PresencaAluno/PresencaAlunoTableSwitch';
import Select from '../UI/Select';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { findByTdpCodigoAndData } from '../Cronograma/api-cronograma';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function PresencaForm({ presenca, setPresenca, setPresencas, addPresencas }) {
    const classes = useStyles();
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [listCronograma, setListCronograma] = useState([]);
    const [listValuesCronograma, setListValuesCronograma] = useState([]);
    const [isLoadingCronograma, setIsLoadingCronograma] = useState(false);


    const handleChange = (event) => {
        setPresenca({
            ...presenca,
            [event.target.name]: event.target.value
        })
    }

    const handleDataChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setPresenca({
            ...presenca,
            data: data
        })
    }

    const updatePresencas = (data) => {
        setPresencas((prevState) => ({
            ...prevState,
            content: prevState.content.map((ps) => {
                if (ps.codigo === data.codigo) {
                    return data;
                } else {
                    return ps;
                }
            })
        }))
    }



    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const eNovo = presenca.eNovo;
        save(presenca).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        addPresencas(data);
                    } else {
                        updatePresencas(data);
                    }
                    setPresenca(null);
                }

            }
            setIsLoadingSave(false);

        })

    }

    const onChangeCronograma = (event) => {
        setPresenca((prevState) => ({
            ...prevState,
            cronograma: listCronograma.find((c) => c.codigo === event.target.value)
        }))
    }

    const handleLoadCronogramas = () => {
        if (presenca.data) {
            setIsLoadingCronograma(true);
            findByTdpCodigoAndData(presenca.tdp.codigo, presenca.data).then((data) => {

                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setListCronograma(data);
                        setListValuesCronograma(
                            data.map((d) => { return { description: getCronogramaDescription(d), value: d.codigo } })
                        );
                    }
                } else {
                    errorMessage("No data");
                }
                setIsLoadingCronograma(false);
            });
        } else {
            errorMessage("Digite a data para Carregar os Cronogramas.");
        }

    }

    const getCronogramaDescription = (cronogramaDescription) => {
        return "Aula " + cronogramaDescription.cronogramaAula.ordemAula + " : " + timeFormat(cronogramaDescription.cronogramaAula.horarioInicio) + " - " + timeFormat(cronogramaDescription.cronogramaAula.horarioFim);
    }

    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <div
                    className={classes.closeIcon}
                >
                    <CloseIcon
                        onClick={() => setPresenca(null)}
                        fontSize='small'
                    />
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Container className={classes.paper} component={Paper} maxWidth="sm">

                            <form onSubmit={clickSubmit}>
                                <Grid container direction='column'>
                                    <Grid item>
                                        <TextFieldDate
                                            required
                                            label="Data"
                                            value={presenca && presenca.data ? dateFormatMoment(presenca.data) : null}
                                            onChange={handleDataChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        Cronograma: {presenca.cronograma && getCronogramaDescription(presenca.cronograma)}
                                    </Grid>
                                    <Grid item>
                                        {listValuesCronograma.length > 0 &&
                                            <Select
                                                label="Cronograma"
                                                value={presenca.cronograma && presenca.cronograma.codigo ? presenca.cronograma.codigo : ''}
                                                list={listValuesCronograma}
                                                onChange={onChangeCronograma}
                                            />
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={handleLoadCronogramas}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={isLoadingCronograma}
                                            className={classes.submit}>
                                            {isLoadingCronograma && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                            Carregar Cronogramas
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            minRows="4"
                                            inputProps={{ maxLength: 500 }}
                                            fullWidth
                                            label="Conteúdo"
                                            name="conteudo"
                                            onChange={handleChange}
                                            value={presenca && presenca.conteudo ? presenca.conteudo : ''}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={isLoadingSave}
                                            color="primary"
                                            className={classes.submit}>
                                            {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        {presenca.codigo ? <PresencaCapituloTable presenca={presenca} /> : ''}
                    </Grid>
                    <Grid item xs={8}>
                        <PresencaAlunoTableSwitch presenca={presenca} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}