import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByCurrentUser } from '../../TDP/api-tdp';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setTDP } from "../../TDP/actions/actions";
import { errorMessage } from '../../../util/ConfirmAlertUtil';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

export default function LivroProfessorTable() {
    const classes = useStyles();
    const [tdps, setTdps] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        findByCurrentUser().then((data) => {
            if (data.error) {
                if (data.message === 'No book registered for this teacher') {
                    errorMessage('Nenhum livro cadastrado para este professor.');
                } else {
                    errorMessage(data.message);
                }
            } else {
                setTdps(data);
            }
        })
    }, [dispatch]);

    const handleTdpSelected = (tdp) => {
        dispatch(setTDP(tdp));
    }

    return (
        <Container component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Turma</TableCell>
                        <TableCell>Livro</TableCell>
                        <TableCell>Disciplina</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tdps.map((tdp) => (
                        <TableRow
                            className={classes.cursorLink}
                            onClick={() => handleTdpSelected(tdp)}
                            key={tdp.codigo}
                            component={Link}
                            to={{
                                pathname: '/conteudos/' + tdp.livro.codLivro,
                            }}
                        >
                            <TableCell>{tdp.turma.descricao}</TableCell>
                            <TableCell>{tdp.livro && tdp.livro.descricao ? tdp.livro.descricao : ''}</TableCell>
                            <TableCell>{tdp.disciplina ? tdp.disciplina.descricao : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}