import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'atividade/';

const page = (codTdp, pageNumber) => {
  const url = SERVER_URL + controller + 'page/tdp/' + codTdp + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const findByCodigo = (codAluno) => {
  const url = SERVER_URL + controller + 'codigo/' + codAluno;
  return fetchGetAuth(url);
}

const remove = (codAtividade) => {
  const url = SERVER_URL + controller + 'delete/' + codAtividade;
  return fetchRemoveAuth(url);
}

const save = (atividade) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, atividade);
}

export {
  page,
  save,
  remove, 
  findByCodigo
}