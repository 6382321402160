import React from 'react';
import { dateFormatMoment } from '../../../util/DateUtil';
import TextFieldDate from '../../UI/TextFieldDate';
import TextField from '../../UI/TextField';
import Form from '../../UI/Form';

const CronogramaQuantAulaDiaForm = ({ cqad, setCqad, clickSubmit, handleRemove, isLoadingAction }) => {
    const onChangeDate = (data) => {
        setCqad((prevState) => ({
            ...prevState,
            data: data
        }))
    }

    const onChange = (event) => {
        setCqad((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <>
            <Form
                clickSubmit={clickSubmit}
                handleClose={() => setCqad(null)}
                handleRemove={handleRemove}
                isLoadingAction={isLoadingAction}
            >
                <TextFieldDate
                    onChange={onChangeDate}
                    value={cqad.data ? dateFormatMoment(cqad.data) : null}
                    label="Data"
                />
                <TextField
                    label="Quantidade Aulas"
                    onChange={onChange}
                    name="quantAulas"
                    value={cqad.quantAulas}
                />


            </Form>
        </>
    )
}

export default CronogramaQuantAulaDiaForm;