import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'mural-foto';

export const findByMuralCodigo = (codMural, page) => {
  const url = SERVER_URL + controller + '/' + codMural + '?page=' + page;
  return fetchGetAuth(url);
}

export const save = (muralFoto) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, muralFoto);
}

export const deleteById = (codMuralFoto) => {
  const url = SERVER_URL + controller + "/" + codMuralFoto;
  return fetchRemoveAuthReturn(url);
}
