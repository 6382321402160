import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BimestreProfileInfo from './BimestreProfileInfo';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '100%',
    },
});

export default function BimestreProfileWithoutChange({ bimestre }) {
    const classes = useStyles();


    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <BimestreProfileInfo bimestre={bimestre} />

            </CardContent>
        </Card>
    );
}