import { makeStyles } from '@material-ui/core';
import React from 'react';
import Linkify from 'react-linkify';

const useStyles = makeStyles({
    divMensagem: {
        fontSize: 12,
        whiteSpace: 'pre-line'
    },
})

const Mensagem = ({ sm }) => {
    const classes = useStyles();


    return (
        <>
            {sm.mensagem &&
                <div className={classes.divMensagem}>
                    <Linkify
                        properties={{
                            target: '_blank',
                            style: { color: '#177c8a' },
                        }}
                    >
                        {sm.mensagem}
                    </Linkify>
                </div>}
        </>
    )
}

export default Mensagem;