import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getPresencaAlunoResumo } from '../api-presenca-aluno';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    paper: {
        marginTop: '60px'
    },
    title: {
        padding: '20px'
    },
    loading: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    rowTitle: {
        fontSize: 'large',
        fontWeight: '700'
    }
});


export default function PresencaTable() {
    const classes = useStyles();
    const [paResumo, setPAResumo] = useState(null);
    const tdp = useSelector(state => state.tdp);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPresencaAlunoResumo(tdp.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setPAResumo(data);
                setLoading(false);
            }
        })
    }, [tdp.codigo]);

    return (
        <div>
            <CssBaseline />
            <Grid item xs={5} className={classes.paper} component={Paper}>
                {loading ?
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                        Carregando...
                    </div>
                    :
                    <>
                        <Table size="small" aria-label="a dense table">
                            <TableBody >
                                <TableRow>
                                    <TableCell className={classes.rowTitle} colSpan={2}>1º Bimestre</TableCell>
                                    <TableCell className={classes.rowTitle} colSpan={2}>3º Bimestre</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.rowTitle}>Total Aulas</TableCell>
                                    <TableCell>{paResumo.quantAulas1}</TableCell>
                                    <TableCell className={classes.rowTitle}>Total Aulas</TableCell>
                                    <TableCell>{paResumo.quantAulas3}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.rowTitle}>Total Faltas</TableCell>
                                    <TableCell>{paResumo.quantFaltas1}</TableCell>
                                    <TableCell className={classes.rowTitle}>Total Faltas</TableCell>
                                    <TableCell>{paResumo.quantFaltas3}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.rowTitle} colSpan={2}>2º Bimestre</TableCell>
                                    <TableCell className={classes.rowTitle} colSpan={2}>4º Bimestre</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.rowTitle}>Total Aulas</TableCell>
                                    <TableCell>{paResumo.quantAulas2}</TableCell>
                                    <TableCell className={classes.rowTitle}>Total Aulas</TableCell>
                                    <TableCell>{paResumo.quantAulas4}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.rowTitle}>Total Faltas</TableCell>
                                    <TableCell>{paResumo.quantFaltas2}</TableCell>
                                    <TableCell className={classes.rowTitle}>Total Faltas</TableCell>
                                    <TableCell>{paResumo.quantFaltas4}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.rowTitle} colSpan={2}>Frequência</TableCell>
                                    <TableCell colSpan={2}>{paResumo.frequencia}%</TableCell>
                                </TableRow>
                            </TableBody>


                        </Table>

                    </>
                }


            </Grid>

        </div>
    );
}