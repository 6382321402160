import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { findAll, remove } from './api-serie';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSerie, setSeries, removeSeries } from './actions/actions';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginBottom: '20px',
        marginTop: '20px'

    },
    actionIcon: {
        cursor: 'pointer',
        marginRight: '10px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function SerieTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const series = useSelector(state => state.series);

    useEffect(() => {
        findAll().then((data) => {
            if (data) {
                dispatch(setSeries(data));
            }
        })
    }, [dispatch]);

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta Série?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(series[index].codSerie).then((data) => {
                            if (data) {
                                dispatch(removeSeries(series[index].codSerie));
                            } else {
                                handleDeleteError();
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteError = () => {
        confirmAlert({
            title: 'Erro',
            message: 'A Série não pode ser removido pois possui turmas cadastradas. Remova todas as turmas cadastradas com esta Série.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleEdit = (serie) => {
        dispatch(setSerie(serie));
    }


    return (
        <div>
            <Button
                variant="contained"
                onClick={() => dispatch(setSerie({ eNovo: true }))}
                color="primary"
                className={classes.novoRegistro}>
                Nova Série
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {series && series.map((serie, index) => (
                        <TableRow key={index}>
                            <TableCell>{serie.descricao}</TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    onClick={() => handleEdit(serie)}
                                    color="secondary"
                                    className={classes.actionIcon}
                                />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.actionIcon}
                                    onClick={() => { handleDelete(index) }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}