import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Container from '../../UI/Container';
import Loading from '../../UI/Loading';
import { findByCardapioAtivo } from '../Semana/api-cardapio-semana';
import { dayOfWeekName } from '../../../util/DateUtil';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    diaSemana: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    nenhumRegistroDiv: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const CardapioUsuarioContainer = () => {
    const classes = useStyles();
    const [listaCS, setListaCS] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        findByCardapioAtivo(true).then((data) => {
            console.log(data);
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaCS(data);
            }
            setIsLoading(false);
        })
    }, []);

    return (
        <Container>

            <Typography className={classes.title} variant="h4">
                Cardápio
            </Typography>

            {isLoading ? <Loading description='Carregando Cardápio...' /> :
                <>
                    {listaCS.length > 0 ?
                        <>
                            {listaCS.map((cs) => (
                                <>
                                    <Typography className={classes.diaSemana} variant="h5">
                                        {cs.numeroSemana}ª Semana
                                    </Typography>
                                    <Typography className={classes.diaSemana} variant="h5">
                                        {dayOfWeekName(cs.diaSemana)}
                                    </Typography>
                                    <div>
                                        <Typography variant="h6">
                                            <strong>Lanche Manhã:</strong> {cs.lancheManha}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="h6">
                                            <strong>Almoço:</strong> {cs.almoco}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="h6">
                                            <strong>Lanche Tarde:</strong> {cs.lancheTarde}
                                        </Typography>
                                    </div>
                                </>
                            ))}
                        </>
                        :
                        <Grid>
                            <Typography className={classes.nenhumRegistroDiv} variant="h6">
                                Nenhum Registro Cadastrado
                            </Typography>
                        </Grid>
                    }
                </>
            }


        </Container>
    );
}

export default CardapioUsuarioContainer;