import {
    SET_HISTORICO_LANCAMENTO,
    SET_HISTORICOS_LANCAMENTOS,
    ADD_HISTORICOS_LANCAMENTOS,
    UPDATE_HISTORICOS_LANCAMENTOS,
    REMOVE_HISTORICOS_LANCAMENTOS,
    OPEN_HISTORICO_LANCAMENTO,
    CLOSE_HISTORICO_LANCAMENTO
} from './actionTypes'

export function setHistoricoLancamento(historicoLancamento) {
    return { type: SET_HISTORICO_LANCAMENTO, historicoLancamento }
}

export function setHistoricosLancamentos(historicosLancamentos) {
    return { type: SET_HISTORICOS_LANCAMENTOS, historicosLancamentos }
}

export function addHistoricosLancamentos(historicoLancamento) {
    return { type: ADD_HISTORICOS_LANCAMENTOS, historicoLancamento }
}

export function updateHistoricosLancamentos(historicoLancamento) {
    return { type: UPDATE_HISTORICOS_LANCAMENTOS, historicoLancamento }
}

export function removeHistoricosLancamentos(codigo) {
    return { type: REMOVE_HISTORICOS_LANCAMENTOS, codigo }
}

export function openHistoricoLancamento() {
    return { type: OPEN_HISTORICO_LANCAMENTO }
}

export function closeHistoricoLancamento() {
    return { type: CLOSE_HISTORICO_LANCAMENTO }
}