import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        marginTop: '20px'
    },
    novoRegistro: {
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function DisciplinaTurmaTableSelect({ tdps, onTdpSelected }) {
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.title} variant="body1">
                Selecione uma turma - disciplina - professor
            </Typography>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Turma</TableCell>
                        <TableCell>Disciplina</TableCell>
                        <TableCell>Professor</TableCell>
                        <TableCell>Livro</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tdps.length > 0 && tdps.map((tdp) => (
                        <TableRow key={tdp.codigo}>
                            <TableCell>{tdp.turma.descricao}</TableCell>
                            <TableCell>{tdp.disciplina.descricao}</TableCell>
                            <TableCell>{tdp.usuario ? tdp.usuario.nome : ''}</TableCell>
                            <TableCell>{tdp.livro ? tdp.livro.descricao : ''}</TableCell>
                            <TableCell>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onTdpSelected(tdp)}
                                    className={classes.submit}>
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
}