import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { cancel } from "./api-progress-log";
import { useSelector, useDispatch } from "react-redux";
import { setProgressLog } from "./actions/actions";

const useStyles = makeStyles(() => ({
    paper: {
        padding: '20px'
    },
    messagesBox: {
        padding: '20px',
        border: 'solid 1px',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '20px',
        borderRadius: '25px'
    }
}));

export default function ProgressLogForm() {
    const classes = useStyles();
    const progressLog = useSelector(state => state.progressLog);
    const dispatch = useDispatch();

    const handleCancel = () => {
        cancel(progressLog);
        dispatch(setProgressLog(null));
    }

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper} >
                <Typography className={classes.title} variant="h5">
                    Progresso
                </Typography>
                <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <LinearProgress variant="determinate" value={progressLog.porcentagem} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`${Math.round(
                            progressLog.porcentagem,
                        )}%`}</Typography>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    onClick={handleCancel}
                    color="secondary"
                    className={classes.submit}>
                    Cancelar
                </Button>
                <div className={classes.messagesBox} >
                    <Typography style={{whiteSpace: 'pre-line'}}>
                        {progressLog.mensagem}
                    </Typography>
                </div>


            </Container>
        </div>
    );

}