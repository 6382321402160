import {
    ADD_LISTA_AVALIACAO_ALUNO, 
    UPDATE_LISTA_AVALIACAO_ALUNO, 
    SET_LISTA_AVALIACAO_ALUNO, 
    REMOVE_LISTA_AVALIACAO_ALUNO,
    SET_AVALIACAO_ALUNO, 
    SET_LISTA_INIT
} from './actionTypes'

export function add (avaliacaoAluno) {
    return { type: ADD_LISTA_AVALIACAO_ALUNO, avaliacaoAluno }
}


export function update(avaliacaoAluno){
    return {type : UPDATE_LISTA_AVALIACAO_ALUNO, avaliacaoAluno}
}

export function setLista(lista){
    return {type : SET_LISTA_AVALIACAO_ALUNO, lista}
}

export function setListaInit(){
    return {type : SET_LISTA_INIT}
}

export function remove(index){
    return {type : REMOVE_LISTA_AVALIACAO_ALUNO, index}
}

export function setAvaliacaoAluno(avaliacaoAluno){
    return {type : SET_AVALIACAO_ALUNO, avaliacaoAluno}
}
