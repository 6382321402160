import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth, fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'aluno-livro/';

const findByAlunoCodigoOrderByLivroDescricao = (codAluno) => {
  const url = SERVER_URL + controller + 'aluno/' + codAluno;
  return fetchGetAuth(url);
}

const findByCurrentUserOrderByLivroDescricao = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const create = (alunoLivro) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, alunoLivro);
}

const trocarHabilitado = (codAlunoLivro) => {
  const url = SERVER_URL + controller + 'trocar-habilitado/' + codAlunoLivro;
  return fetchGetAuth(url);
}

const findByLivroCodLivroAndAlunoCodigoAndAlunoInstituicaoCodigo = (codLivro, codAluno, codInstituicao) => {
  const url = SERVER_URL + controller + 'query?codLivro=' + codLivro + '&codAluno=' + codAluno + '&codInstituicao=' + codInstituicao;
  return fetchGetAuth(url);
}

const findByLivroCodLivroAndAlunoInstituicaoCodigo = (codLivro, codInstituicao, page) => {
  const url = SERVER_URL + controller + 'query?codLivro=' + codLivro + '&codInstituicao=' + codInstituicao + '&page=' + page;
  return fetchGetAuth(url);
}

const save = (alunoLivro) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, alunoLivro);
}

export const findByCurrentUserAndLivroCodLivro = (codLivro) => {
  const url = SERVER_URL + controller + 'current-user/' + codLivro;
  return fetchGetAuth(url);
}


export {
  findByAlunoCodigoOrderByLivroDescricao,
  findByCurrentUserOrderByLivroDescricao,
  create,
  trocarHabilitado,
  findByLivroCodLivroAndAlunoCodigoAndAlunoInstituicaoCodigo,
  findByLivroCodLivroAndAlunoInstituicaoCodigo,
  save
}