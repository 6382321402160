import React, { useState } from 'react';
import LateralMenuContent from '../LateralMenuContent';
import AlunoTurmaForm from '../../../../components/AlunoTurma/AlunoTurmaForm';

export default function AlunoTurmaFormMenu(props) {
    let alunoTurmaProp = {};
    if (props.location.state) {
        alunoTurmaProp = props.location.state.alunoTurma;
    }
    const [alunoTurma] = useState(alunoTurmaProp);
    return (
        <LateralMenuContent child={AlunoTurmaForm} alunoTurmaProp={alunoTurma} />
    );
}