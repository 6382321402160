import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import AvaliacaoForm from './AvaliacaoForm';
import AvaliacaoTable from './AvaliacaoTable';
import SimuladoTable from './SimuladoTable';
import { ADMIN, COORDENADOR, PROFESSOR, ALUNO } from '../Usuario/Perfil';
import { Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { setAvaliacao } from './actions/actions';
import { setSecoes } from './Secao/actions/actions';
import AvaliacaoAlunoTableGroup from './Aluno/AvaliacaoAlunoPorAlunoTableGroup';
import AvaliacaoAlunoTable from './Aluno/AvaliacaoAlunoTable';
import { setListaInit as setListaInitAA } from './Aluno/actions/actions';
import { setSecao } from './Secao/actions/actions';
import { setAvaliacaoAluno } from './Aluno/actions/actions';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px',
    },
    tabMenu: {
        marginBottom: '20px'
    }
});

export default function AvaliacaoContainer() {
    const classes = useStyles();
    const avaliacao = useSelector(state => state.avaliacao);
    const usuario = useSelector(state => state.usuario);
    const [menuShow, setMenuShow] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAvaliacao(null));
        dispatch(setAvaliacaoAluno(null));
        dispatch(setSecao(null));
        dispatch(setSecoes([]));
        dispatch(setListaInitAA());
    }, [dispatch]);

    const handleChange = (event, newValue) => {
        setMenuShow(newValue);
    };

    return (
        <div>
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h6">
                    Avaliação
                </Typography>


                {usuario && (usuario.perfil === ADMIN
                    || usuario.perfil === COORDENADOR
                    || usuario.perfil === PROFESSOR) ?
                    <>
                        {avaliacao ? <AvaliacaoForm /> :
                            <AvaliacaoTable />
                        }
                    </>
                    :
                    ''
                }

                {usuario.perfil === ALUNO ?
                    <>
                        <Grid className={classes.tabMenu} container justify="center">
                            <Grid item xs={6}>
                                <Paper className={classes.root}>
                                    <Tabs
                                        value={menuShow}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                    >
                                        <Tab label="Avaliação" />
                                        <Tab label="Simulado" />
                                        <Tab label="Resultado" />
                                    </Tabs>
                                </Paper>
                            </Grid>
                        </Grid>
                        {menuShow === 0 ?
                            <AvaliacaoAlunoTable /> :
                            <>
                                {menuShow === 1 ? <SimuladoTable /> : <AvaliacaoAlunoTableGroup />}
                            </>

                        }


                    </>
                    : ''}
            </Container>

        </div>
    );
}