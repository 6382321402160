import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import BimestreTableSelect from './BimestreTableSelect';

const BimestreDialogSelect = ({ openDialog, setOpenDialog, setBimestre, serie }) => {
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Selecione um Bimestre</DialogTitle>
                <DialogContent>

                    <BimestreTableSelect
                        setBimestre={setBimestre}
                        serie={serie}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BimestreDialogSelect;