import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import {
    rltConfereRegAulaFaltaWeb
}
    from "../api-relatorio";
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import Loading from '../../UI/Loading';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { findByTurma } from '../../TDP/api-tdp';
import { findByTurma as findAlunoTurmaByTurma } from '../../AlunoTurma/api-aluno-turma';
import { findByAnoLetivoParamESerieCodSerie } from '../../Bimestre/api-bimestre';
import { errorBg, successBg } from '../../UI/ColorUtil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px'

    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }
});

export default function RelatorioConfereRegAulaFaltaWeb(props) {
    const classes = useStyles();
    const [listConfere, setListConfere] = useState([]);
    const { turma, tdp, alunoTurma } = props.location.state;
    const [isLoading, setIsLoading] = useState(true);
    const [tdps, setTdps] = useState([]);
    const [bimestres, setBimestres] = useState([]);
    const [listAT, setListAT] = useState([]);


    useEffect(() => {
        let codTurma = null;
        let anoLetivo = null;
        let codSerie = null;
        if (turma) {
            codTurma = turma.codigo;
            anoLetivo = turma.anoLetivo;
            codSerie = turma.serie.codSerie;
            findByTurma(codTurma).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data);
                }
            })
        } else if (tdp) {
            codTurma = tdp.turma.codigo;
            anoLetivo = tdp.turma.anoLetivo;
            codSerie = tdp.turma.serie.codSerie;
            setTdps([tdp]);
        }


        if (turma && tdp) {
            findAlunoTurmaByTurma(codTurma).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListAT(data);
                }
            })
            findByAnoLetivoParamESerieCodSerie(anoLetivo, codSerie).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setBimestres(data);
                }
            })
        }



    }, [turma, tdp]);

    useEffect(() => {
        if (alunoTurma) {
            setListAT([alunoTurma]);
            findByTurma(alunoTurma.turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data);
                }
            })
            findByAnoLetivoParamESerieCodSerie(alunoTurma.turma.anoLetivo, alunoTurma.turma.serie.codSerie).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setBimestres(data);
                }
            })
        }
    }, [alunoTurma])

    useEffect(() => {
        if (bimestres.length > 0 &&
            listAT.length > 0 &&
            tdps.length > 0) {
            for (let i = 0; i < listAT.length; i++) {
                let at = listAT[i];
                for (let z = 0; z < tdps.length; z++) {
                    let tdp = tdps[z];
                    for (let y = 0; y < bimestres.length; y++) {
                        let bimestre = bimestres[y];
                        rltConfereRegAulaFaltaWeb(at.codigo, tdp.codigo, bimestre.codigo)
                            .then((data) => {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    setListConfere((prevState) => ([
                                        ...prevState,
                                        data
                                    ]));
                                }
                                if (i === listAT.length - 1 &&
                                    z === tdps.length - 1 &&
                                    y === bimestres.length - 1) {
                                    setIsLoading(false);
                                }
                            });
                    }
                }
            }
        }



    }, [bimestres, listAT, tdps]);

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container direction='column' spacing={3}>
                <Grid item>
                    <Button
                        onClick={() => props.history.goBack()}
                        variant="contained"
                        color="secondary"
                        className={classes.voltarButton}
                    >
                        <ArrowBackIcon style={{ marginRight: '10px' }} />
                        Voltar
                    </Button>
                </Grid>
                <Grid item>
                    <Typography className={classes.title} variant="h5">
                        Confere Registro Aulas Faltas
                    </Typography>
                </Grid>
                <Grid item>
                    {listConfere.length > 0 ?
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Aluno</TableCell>
                                    <TableCell>Turma</TableCell>
                                    <TableCell>Disciplina</TableCell>
                                    <TableCell>Bimestre</TableCell>
                                    <TableCell>Quant. Aulas(Contador/Registro)</TableCell>
                                    <TableCell>Quant. Faltas(Contador/Registro)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listConfere.map((confere, index) => (
                                    <TableRow
                                        style={{
                                            backgroundColor:
                                                confere.alunoFrequencia &&
                                                    confere.alunoFrequencia.quantidadeAulas === confere.quantAulas &&
                                                    confere.alunoFrequencia.quantidadeFaltas === confere.quantFaltas ?
                                                    successBg :
                                                    errorBg
                                        }}
                                        key={index}
                                    >
                                        <TableCell>{confere.alunoTurma.aluno.codigo} - {confere.alunoTurma.aluno.nome}</TableCell>
                                        <TableCell>{confere.tdp.turma.descricao}({confere.tdp.turma.curso.abreviacao})</TableCell>
                                        <TableCell>{confere.tdp.disciplina.descricao}</TableCell>
                                        <TableCell>{confere.bimestre.numeroBimestre}</TableCell>
                                        <TableCell>
                                            {confere.alunoFrequencia ?
                                                confere.alunoFrequencia.quantidadeAulas + "/" + confere.quantAulas :
                                                ''}
                                        </TableCell>
                                        <TableCell>{confere.alunoFrequencia ?
                                            confere.alunoFrequencia.quantidadeFaltas + "/" + confere.quantFaltas :
                                            ''}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        ''
                    }
                </Grid>
                <Grid item>
                    {isLoading ?
                        <Loading description='Carregando Relatório...' /> :
                        ''
                    }
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Nota: Esse relatório compara a Quantidade de Aulas e Faltas Registradas pelo
                        Professor com a Registrada pelo Contador do Sistema e mostra os registros que não
                        estão iguais.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}