import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ProfessorForm from './ProfessorForm';
import TurmaTableSelect from '../Turma/TurmaTableSelect';
import TurmaProfile from '../Turma/TurmaProfile';
import DisciplinaTurmaTableSelect from './DisciplinaTurmaTableSelect';
import { useEffect } from 'react';
import { findByTurma } from './api-tdp';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }

});

export default function DisciplinaTurmaForm() {
    const classes = useStyles();
    const [turma, setTurma] = useState(null);
    const [tdp, setTdp] = useState(null);
    const [tdps, setTdps] = useState([]);

    useEffect(() => {
        if (turma) {
            findByTurma(turma.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data)
                }
            }).catch(err => {
                console.log(err);
            });
        }

    }, [turma]);

    const onTurmaSelected = (t) => {
        setTurma(t);
    }

    const onTdpSelected = (tdpSelected) => {
        setTdp(tdpSelected);
    }

    const updateTdps = (tdpUpdated) => {
        tdps.map((tdpSelected) => {
            if (tdpUpdated.codigo === tdpSelected.codigo) {
                return tdpUpdated;
            } else {
                return tdpSelected;
            }
        })
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Typography className={classes.title} variant="h6">
                Professor Disciplina Turma
            </Typography>

            {turma ?
                <>
                    <TurmaProfile
                        turma={turma}
                        onTurmaSelected={onTurmaSelected}
                    />
                    <DisciplinaTurmaTableSelect
                        tdps={tdps}
                        onTdpSelected={onTdpSelected}
                    />
                    {tdp ?
                        <ProfessorForm
                            tdp={tdp}
                            setTdp={setTdp}
                            updateTdps={updateTdps}
                        /> : ''}
                </>
                :
                <TurmaTableSelect
                    onTurmaSelected={onTurmaSelected}
                />
            }

        </Container>
    );
}