import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findAll, remove } from './api-comunicado-categoria';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setCategorias, setCategoria, removeCategorias } from './actions/actions';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function ComunicadoCategoriaTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categorias = useSelector(state => state.comunicadoCategorias);

    useEffect(() => {
        findAll().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setCategorias(data));
            }
        }).catch(err => {
            console.log(err);
        })
    }, [dispatch]);


    const handleDelete = (categoria) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta categoria?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(categoria.codigo).then((data) => {
                            if (data) {
                                dispatch(removeCategorias(categoria.codigo));
                                dispatch(setCategoria(null));
                            } else {
                                console.log(data.error);
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <div>
            <CssBaseline />
            <Container component={Paper}>
                <Button
                    onClick={() => dispatch(setCategoria({ eNovo: true }))}
                    variant="contained"
                    className={classes.novoRegistro}
                    color="primary">
                    Nova Categoria
                </Button>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categorias.length > 0 ? categorias.map((categoria, index) => (
                            <TableRow key={categoria.codigo}>
                                <TableCell>{categoria.descricao}</TableCell>
                                <TableCell>
                                    <EditIcon
                                        className={classes.cursorLink}
                                        onClick={() => { dispatch(setCategoria(categoria)) }}
                                        color="secondary"
                                        style={{ marginRight: '10px' }} />
                                    <DeleteIcon className={classes.cursorLink} onClick={() => { handleDelete(categoria) }} color="secondary" />
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </Container>

        </div>
    );
}