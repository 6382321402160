import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import { Link, useParams } from 'react-router-dom';
import { findByCodLivro, findById, findFirstRecordByBook, findByIdAndIsPublic, findFirstRecordByBookAndIsPublic, findByLivroCodLivroAndIsPublic } from '../../../components/Capitulo/api-capitulo';
import Box from '@material-ui/core/Box';
import { ToastContainer } from 'react-toastify';
import ListItem from '@material-ui/core/ListItem';
import { useDispatch, useSelector } from 'react-redux';
import { setCapitulos, setCapitulo } from '../../../components/Capitulo/actions/actions';
import { findByLivroCodLivro } from '../../../components/Livro/Instituicao/api-livro-instituicao';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { setLivroInstituicao } from '../../../components/Livro/Instituicao/actions/actions';
import { ALUNO } from '../../../components/Usuario/Perfil';
import { findByCurrentUserAndLivroCodLivro } from '../../../components/AlunoLivro/api-aluno-livro';
import { setAlunoLivro } from '../../../components/AlunoLivro/actions/actions';
import { findByCodLivroAndIsPublic } from '../../../components/Livro/api-livro';

const drawerWidth = 150;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    iconMenu: {
        marginRight: '5px',
    },
    logo: {
        marginLeft: '10px',
        width: drawerWidth - 35,
    },
    capSelected: {
        backgroundColor: '#b5e7a4'
    }
}));

export default function LateralMenuSkeletonLivro(props) {
    const classes = useStyles();
    const { child: Child } = props;
    const capitulos = useSelector(state => state.capitulos);
    const capitulo = useSelector(state => state.capitulo);
    const usuario = useSelector(state => state.usuario);
    const livroInstituicao = useSelector(state => state.livroInstituicao);
    const alunoLivro = useSelector(state => state.alunoLivro);
    const [livro, setLivro] = useState(null)
    const dispatch = useDispatch();
    const { codLivro, codCapitulo } = useParams();

    useEffect(() => {
        if (codLivro) {
            if (usuario) {
                if (usuario.perfil === ALUNO) {
                    findByCurrentUserAndLivroCodLivro(codLivro).then((data) => {
                        if (data.error) {
                            errorMessage(data.message);
                        } else {
                            dispatch(setAlunoLivro(data));
                        }
                    })
                } else {
                    findByLivroCodLivro(codLivro).then((data) => {
                        if (data.error) {
                            errorMessage(data.message);
                        } else {
                            if (data.length > 0) {
                                dispatch(setLivroInstituicao(data[0]));
                            }
                        }
                    });
                }
            } else {
                findByCodLivroAndIsPublic(codLivro).then((data) => {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setLivro(data)
                    }
                })
            }


        }
    }, [codLivro, dispatch, usuario]);

    useEffect(() => {
        if (codCapitulo) {
            if (usuario) {
                findById(codCapitulo).then((data) => {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        dispatch(setCapitulo(data));
                    }
                })
            } else {
                findByIdAndIsPublic(codCapitulo).then((data) => {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        dispatch(setCapitulo(data));
                    }
                })
            }

        } else {
            if (codLivro) {
                if (usuario) {
                    findFirstRecordByBook(codLivro).then((data) => {
                        if (data.error) {
                            errorMessage(data.message);
                        } else {
                            dispatch(setCapitulo(data));
                        }
                    });
                } else {
                    findFirstRecordByBookAndIsPublic(codLivro).then((data) => {
                        if (data.error) {
                            errorMessage(data.message);
                        } else {
                            dispatch(setCapitulo(data));
                        }
                    });
                }

            }

        }
    }, [codCapitulo, dispatch, codLivro, usuario])

    useEffect(() => {
        if (alunoLivro) {
            findByCodLivro(alunoLivro.livro.codLivro).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setCapitulos(data));
                }
            })
        }
    }, [alunoLivro, dispatch]);

    useEffect(() => {
        if (livroInstituicao &&
            livroInstituicao.livro &&
            livroInstituicao.livro.codLivro) {
            findByCodLivro(livroInstituicao.livro.codLivro).then((data) => {
                if (data.error) {
                    errorMessage(data);
                } else {
                    dispatch(setCapitulos(data));
                }
            })
        }

    }, [dispatch, livroInstituicao]);

    useEffect(() => {
        if (livro && livro.codLivro) {
            findByLivroCodLivroAndIsPublic(livro.codLivro).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    dispatch(setCapitulos(data));
                }
            })
        }

    }, [dispatch, livro]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Box display="block" displayprint="none">
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    displayprint="none"
                >

                    <div className={classes.toolbar} />

                    <Link to='/home'>
                        <img
                            src={usuario && usuario.instituicao ? usuario.instituicao.urlLogo : ''}
                            className={classes.logo}
                            alt=''
                        />
                    </Link>
                    <List>
                        {capitulos.length > 0 && capitulos.map((c, index) => {
                            return (
                                <ListItem
                                    component={Link}
                                    to={{
                                        pathname: "/conteudos/" + codLivro + "/" + c.codCapitulo
                                    }}
                                    button
                                    key={index}
                                    className={capitulo &&
                                        c.codCapitulo === capitulo.codCapitulo ?
                                        classes.capSelected :
                                        ''
                                    }
                                >
                                    <span style={{ fontSize: 'smaller' }}>{c.descricao}</span>
                                </ListItem>
                            )
                        })}
                    </List>
                </Drawer>
            </Box>

            <main className={classes.content}>
                <ToastContainer autoClose={3000} />
                <div className={classes.toolbar} />
                <Child />
            </main>

        </div >
    );


}