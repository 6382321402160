import React from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Button from '../../UI/Button';
import { dateFormat } from '../../../util/DateUtil'
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const IntegralPresencaTable = ({ listaPresenca, setIntegralPresenca, handleChangePage }) => {
    const classes = useStyles();


    const handleSelecionarPresenca = (presenca) => {
        setIntegralPresenca(presenca);
    }

    return (
        <>
            {
                listaPresenca.content.length > 0 ?
                    <>
                        < Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.rowHeader}>Data</TableCell>
                                    <TableCell className={classes.rowHeader}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaPresenca.content.map((presenca) => (
                                    <TableRow key={presenca.codigo}>
                                        <TableCell>{dateFormat(presenca.data)}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSelecionarPresenca(presenca)}>Selecionar</Button>
                                        </TableCell>

                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            rowsPerPage={listaPresenca.size ? listaPresenca.size : 0}
                            component="div"
                            count={listaPresenca.totalElements}
                            page={listaPresenca.number}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                        />
                    </>

                    :
                    <Grid>
                        <Typography className={classes.title} variant="h6">
                            Nenhum Registro Cadastrado
                        </Typography>
                    </Grid>
            }

        </>
    );
}

export default IntegralPresencaTable;