import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { dateTimeFormat } from "../../../util/DateUtil";
import Button from '@material-ui/core/Button';
import parse from 'html-react-parser';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    send: {
        marginTop: '40px',
        backgroundColor: '#4caf50',
    },
    closeIcon: {
        cursor: 'pointer'
    },
}));

export default function ComunicadoUsuarioForm({
    handleConfirmRead,
    comunicadoUsuario,
    setComunicadoUsuario
}) {
    const classes = useStyles();

    return (
        <Grid container spacing={3} direction='column'>
            {console.log(comunicadoUsuario)}
            <Grid item>
                <CloseIcon
                    onClick={() => setComunicadoUsuario(null)}
                    className={classes.closeIcon} />
            </Grid>

            <Grid item>
                <Typography variant="h4">
                    {comunicadoUsuario && comunicadoUsuario.comunicado ? comunicadoUsuario.comunicado.titulo : ''}
                </Typography>
            </Grid>

            {comunicadoUsuario.comunicado.comunicadoBanner ?
                <Grid item>
                    <img style={{
                        maxHeight: '400px',
                    }} src={comunicadoUsuario.comunicado.comunicadoBanner.urlBanner} alt='' />
                </Grid> :
                ''
            }

            <Grid item>
                <Typography >
                    Data Hora Envio: {dateTimeFormat(comunicadoUsuario.createdAt)}
                </Typography>
            </Grid>

            <Grid item>
                {comunicadoUsuario.comunicado.descricao ? parse(comunicadoUsuario.comunicado.descricao) : ''}
            </Grid>

            <Grid item>
                <Typography >
                    Aluno: {comunicadoUsuario.aluno ? comunicadoUsuario.aluno.nome : '--'}
                </Typography>
            </Grid>
            {comunicadoUsuario.comunicado.dataHoraPara &&
                <Grid item>
                    <Typography>
                        Data Hora Para: {dateTimeFormat(comunicadoUsuario.comunicado.dataHoraPara)}
                    </Typography>
                </Grid>
            }


            <Grid item>
                <Button
                    disabled={comunicadoUsuario.leituraConfirmada || !comunicadoUsuario.usuario}
                    onClick={handleConfirmRead}
                    variant="contained"
                    className={classes.send}>
                    Confirmar Leitura
                </Button>
            </Grid>
        </Grid>
    );
}