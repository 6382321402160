import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { page, remove } from './api-evento';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector, useDispatch } from 'react-redux';
import { setEventos, setEvento, removeEventos } from './actions/actions';
import { dateTimeFormat } from "../../../util/DateUtil";
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function EventoTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const eventos = useSelector(state => state.eventos);

    useEffect(() => {
        page(0).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setEventos(data));
            }
        }).catch(err => {
            console.log(err);
        })
    }, [dispatch]);

    const handleChangePage = (event, newPage) => {
        page(newPage).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                dispatch(setEventos(data));
            }
        });
    };

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este evento?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(eventos.content[index].codigo).then((data) => {
                            if (data && data.error) {
                                console.log(data.error);
                            } else {
                                dispatch(removeEventos(index));
                                dispatch(setEvento(null));

                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <div>
            <Button
                onClick={() => dispatch(setEvento({ eNovo: true }))}
                variant="contained"
                className={classes.novoRegistro}
                color="primary">
                Novo Evento
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Título</TableCell>
                        <TableCell>Início</TableCell>
                        <TableCell>Fim</TableCell>
                        <TableCell>Enviar Por</TableCell>
                        <TableCell>Enviar Para</TableCell>
                        <TableCell>Aprovado</TableCell>
                        <TableCell>Enviado</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventos.content.map((evento, index) => (
                        <TableRow key={evento.codigo}>
                            <TableCell>{evento.titulo}</TableCell>
                            <TableCell>{dateTimeFormat(evento.dataHoraInicio)}</TableCell>
                            <TableCell>{dateTimeFormat(evento.dataHoraFim)}</TableCell>
                            <TableCell>{evento.enviarPor}</TableCell>
                            <TableCell>{evento.enviarPara}</TableCell>
                            <TableCell>
                                {evento.aprovado ?
                                    <CheckCircle style={{ color: green[500] }} /> :
                                    <Cancel style={{ color: red[500] }} />}
                            </TableCell>
                            <TableCell>
                                {evento.enviado ?
                                    <CheckCircle style={{ color: green[500] }} /> :
                                    <Cancel style={{ color: red[500] }} />}
                            </TableCell>
                            <TableCell>
                                <EditIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { dispatch(setEvento(evento)) }}
                                    color="secondary"
                                    style={{ marginRight: '10px' }} />
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={eventos.size ? eventos.size : 0}
                component="div"
                count={eventos.totalElements}
                page={eventos.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}