import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-avaliacao-aluno';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAvaliacaoAluno as setAvaliacaoAlunoRedux,
    add,
    update
} from './actions/actions';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: "20px"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
}));

export default function AtividadeForm() {
    const classes = useStyles();
    const avaliacaoAlunoRedux = useSelector(state => state.avaliacaoAluno);
    const [avaliacaoAluno, setAvaliacaoAluno] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setAvaliacaoAluno(avaliacaoAlunoRedux);
    }, [avaliacaoAlunoRedux])


    const handleChange = (event) => {
        setAvaliacaoAluno({
            ...avaliacaoAluno,
            [event.target.name]: event.target.value
        })
    }


    const clickSubmit = (event) => {
        event.preventDefault();
        const codAvaliacaoAluno = avaliacaoAluno.codigo;
        save(avaliacaoAluno).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (codAvaliacaoAluno) {
                    dispatch(update(data));
                } else {
                    dispatch(add(data));
                }
                dispatch(setAvaliacaoAlunoRedux(data));
                setAvaliacaoAluno(data);
            }

        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <CloseIcon
                        onClick={() => dispatch(setAvaliacaoAlunoRedux(null))}
                        className={classes.closeIcon}
                        fontSize='small'
                    />
                </Grid>
            </Grid>
            <form onSubmit={clickSubmit}>
                <div>
                    <TextField
                        size='small'
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Aluno"
                        name="aluno"
                        value={avaliacaoAluno && avaliacaoAluno.aluno ? avaliacaoAluno.aluno.nome : ''}
                    />
                    <TextField
                        size='small'
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Título"
                        name="titulo"
                        value={avaliacaoAluno && avaliacaoAluno.avaliacao ? avaliacaoAluno.avaliacao.titulo : ''}
                    />
                    <>
                        <p style={{ marginTop: '5px', marginBottom: '5px' }}>Quant. Acertos:</p>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            style={{ marginTop: '5px' }}
                            type="number"
                            name="quantAcertos"
                            onChange={handleChange}
                            value={avaliacaoAluno && avaliacaoAluno.quantAcertos ? avaliacaoAluno.quantAcertos : ''}
                        />
                    </>
                    <>
                        <p style={{ marginTop: '5px', marginBottom: '5px' }}>Nota:</p>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            style={{ marginTop: '5px' }}
                            name="nota"
                            onChange={handleChange}
                            value={avaliacaoAluno && avaliacaoAluno.nota ? avaliacaoAluno.nota : ''}
                        />
                    </>


                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}>
                    Salvar
                </Button>
            </form>
        </Container>
    );
}