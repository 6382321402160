import { makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AlunoProfile from '../../Aluno/AlunoProfile';
import Container from '../../UI/Container';
import LancamentoTableAluno from './LancamentoTableAluno';
import FinanceiroMenu from './FinanceiroMenu';
import { findByAlunoCodigoAndPendente, findByAlunoCodigoAndAVencer, findByAlunoCodigoAndAnoLetivo } from '../api-lancamento';
import { initMessage, handleFincanceiroError, typeError, typeSuccess } from '../util/exceptionFinanceiro';
import Loading from '../../UI/Loading';
import HistoricoAnoLetivoSelect from './HistoricoAnoLetivoSelect';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer',
        flexBasis: '5%'
    },
    successView: {
        backgroundColor: '#77cee2',
        marginTop: 10,
        width: '100%'
    },
    errorView: {
        backgroundColor: '#841414',
        marginTop: 10,
        width: '100%'
    },
    textSuccess: {
        padding: 10,
    },
    textError: {
        padding: 10,
        color: 'white',
    },
    textTitle: {
        fontWeight: 'bold',
    },
});

const pendenteKey = 'pendente';
const aVencerKey = 'aVencer';
const historicoKey = 'historico';

export {
    pendenteKey,
    aVencerKey,
    historicoKey
}

const FinanceiroResponsavelContainer = () => {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const [lancamentos, setLancamentos] = useState([]);
    const [message, setMessage] = useState(initMessage);
    const [isLoading, setIsLoading] = useState(false);
    const [anoLetivo, setAnoLetivo] = useState(new Date().getFullYear());
    const [isHistorico, setIsHistorico] = useState(false);

    const handleParcelaPendente = () => {
        setIsHistorico(false);
        setIsLoading(true);
        setMessage(initMessage);
        findByAlunoCodigoAndPendente(usuario.arAlunoSelecionado.codigo).then((data) => {
            if (data.error) {
                handleFincanceiroError(data, setMessage);

            } else {
                setLancamentos(data);
                if (data.length === 0) {
                    setMessage({
                        type: typeSuccess,
                        description: 'Todas as Parcelas estão em dia!'
                    })
                } else {
                    setMessage({
                        type: typeError,
                        description: 'Consta(m) a(s) seguinte(s) parcela(s) em aberto.'
                    })
                }
            }
            setIsLoading(false);
        })
    }

    const handleParcelaVencer = () => {
        setIsHistorico(false);
        setIsLoading(true);
        setMessage(initMessage);
        findByAlunoCodigoAndAVencer(usuario.arAlunoSelecionado.codigo).then((data) => {
            if (data.error) {
                handleFincanceiroError(data, setMessage);
            } else {

                setLancamentos(data);
                if (data.length === 0) {
                    setMessage({
                        type: typeSuccess,
                        description: 'Nenhuma parcela(s) a Vencer!'
                    })
                }
            }
            setIsLoading(false);
        })
    }

    const handleHistorico = () => {
        setIsHistorico(true);
        setIsLoading(true);
        setMessage(initMessage);
        if (anoLetivo && usuario.arAlunoSelecionado.codigo) {
            findByAlunoCodigoAndAnoLetivo(usuario.arAlunoSelecionado.codigo, anoLetivo).then((data) => {
                if (data.error) {
                    handleFincanceiroError(data, setMessage);
                } else {
                    setLancamentos(data);
                    if (data.length === 0) {
                        setMessage({
                            type: typeSuccess,
                            description: 'Nenhuma parcela(s) cadastrada no Ano!'
                        })
                    }
                }
                setIsLoading(false);
            })
        }
    }

    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Financeiro
            </Typography>

            {usuario.arAlunoSelecionado &&
                <>
                    <AlunoProfile aluno={usuario.arAlunoSelecionado} />
                    <FinanceiroMenu
                        handleParcelaPendente={handleParcelaPendente}
                        handleParcelaVencer={handleParcelaVencer}
                        handleHistorico={handleHistorico}
                    />
                    {isLoading ? <Loading description="Carregando Lançamentos..." /> :
                        <>
                            {isHistorico ?
                                <>
                                    {anoLetivo ?
                                        <>
                                            <Button
                                                onClick={() => setAnoLetivo(null)}
                                            >
                                                Trocar Ano Letivo
                                            </Button>
                                        </>
                                        :
                                        <HistoricoAnoLetivoSelect setAnoLetivo={setAnoLetivo} />

                                    }
                                </> : ''
                            }
                            {message && message.type ?
                                <div className={message.type === typeSuccess ? classes.successView : classes.errorView}>
                                    <p
                                        className={message.type === typeSuccess ? classes.textSuccess : classes.textError}
                                    >
                                        {message.description}
                                    </p>
                                </div>
                                : ''
                            }
                            {isHistorico ?
                                <>
                                    {anoLetivo ? <LancamentoTableAluno lancamentos={lancamentos} /> : ''}
                                </> :
                                <LancamentoTableAluno lancamentos={lancamentos} />
                            }


                        </>

                    }

                </>}
        </Container>
    );
}

export default FinanceiroResponsavelContainer;