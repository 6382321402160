import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { findBySala, save, attachFile, downloadFile } from "./api-sala-mensagem";
import Grid from '@material-ui/core/Grid';
import { dateTimeFormat } from "../../../util/DateUtil";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import RefreshIcon from '@material-ui/icons/Refresh';
import Picker from 'emoji-picker-react';
import { useSelector } from 'react-redux';
import Loading from '../../UI/Loading';
import { ADMIN } from '../../Usuario/Perfil';
import SMMenuOptions from './SMMenuOptions';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Mensagem from './Mensagem';
import PictureAttach from './PictureAttach';
import AttachFile from './AttachFile';
import FormSendMessage from './FormSendMessage';
import { createAndDownloadBlobFile } from '../../../util/DownloadUtil';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    container: {
        marginTop: '8px',
        marginBottom: '8px',
    },
    boxMessages: {
        display: 'flex',
        flexDirection: 'column-reverse',
        backgroundColor: "azure",
        padding: '20px',
        borderRadius: '20px',
    },
    boxCurrentUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: '10px',
    },
    boxOtherUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '10px',
    },
    itemCurrentUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '5',
        marginBottom: '5',
        backgroundColor: '#98FB98',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 25,
        paddingRight: 25,
        borderRadius: 50,
    },
    itemOtherUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginVertical: 5,
        backgroundColor: '#fff',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 25,
        paddingRight: 25,
        borderRadius: 50,
    },
    loading: {
        marginTop: '20px',
    },

    sendBtn: {
        marginTop: '20px',
        marginBottom: '15px',
        marginLeft: '10px',
    },
    menuIcon: {
        cursor: 'pointer',
    },
    fotoComponent: {
        maxHeight: '100px',
        borderRadius: '100%',
    },
});

export default function SalaMensagem(props) {
    const classes = useStyles();
    const [salaParticipante, setSalaParticipante] = useState(props.location.state.salaParticipante ? props.location.state.salaParticipante : { sala: props.location.state.sala });
    const [nameRoom] = useState(props.location.state.nameRoom);
    const isAll = useSelector(state => state.isAllMensagemTable);
    const [isLoading, setIsLoading] = useState(true);
    const [listSMSelect, setListSMSelect] = useState([]);
    const [salaMensagens, setSalaMensagens] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const [mensagem, setMensagem] = useState('');
    const user = useSelector(state => state.usuario);
    const [isSending, setIsSending] = useState(false);
    const [isAttachingFile, setIsAttachingFile] = useState(false);
    const hiddenFileInput = useRef(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const messagesEndRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState();

    useEffect(() => {
        if (salaParticipante.sala.codigo) {
            setIsLoading(true);
            findBySala(salaParticipante.sala.codigo, 0).then((data) => {
                if (data) {
                    setSalaMensagens(data);
                    setIsLoading(false);
                }
            })
        }

    }, [salaParticipante.sala.codigo]);

    useEffect(() => {
        const findNewMessages = setInterval(() => {
            findBySala(salaParticipante.sala.codigo, 0).then((data) => {
                if (data) {
                    if (salaMensagens.content.length > 0) {

                        let index = data.content.findIndex(d => d.codigo === salaMensagens.content[0].codigo);
                        if (index === 0) {
                            return;
                        }
                        for (
                            let i = index - 1;
                            i >= 0;
                            i--
                        ) {
                            setSalaMensagens(prevState => ({
                                ...prevState,
                                content: [
                                    data.content[i],
                                    ...prevState.content
                                ]
                            }))
                        }
                    }
                }
            })
        }, 10000);
        return () => clearInterval(findNewMessages);

    }, [salaMensagens.content, salaParticipante.sala.codigo]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        setMensagem(event.target.value);
    }

    const handleSend = (event) => {
        event.preventDefault();
        if (mensagem.length === 0) {
            return;
        }
        setIsSending(true);
        let salaMensagem = {
            sala: salaParticipante.sala,
            mensagem: mensagem
        }
        save(salaMensagem).then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setSalaMensagens({
                    ...salaMensagens,
                    content: [
                        data,
                        ...salaMensagens.content
                    ]
                })
                setMensagem('');
                setSalaParticipante({
                    ...salaParticipante,
                    quantNaoLidasOtherUser: salaParticipante.quantNaoLidasOtherUser + 1
                })
            }
            setIsSending(false);
        })
    }

    const handleEmojiShow = () => {
        setShowEmojiPicker(!showEmojiPicker);

        setTimeout(() => messagesEndRef.current.scrollIntoView({ behavior: "smooth" }), 5);


    }

    const handleMoreMesssages = () => {
        findBySala(salaParticipante.sala.codigo, salaMensagens.number + 1).then((data) => {
            if (data) {
                setSalaMensagens({
                    ...data,
                    content: [
                        ...salaMensagens.content,
                        ...data.content,
                    ]
                });
            }
            setIsLoading(false);
        })
    }

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }


    const handleChangeFile = event => {
        const file = event.target.files[0];

        if (file === null) {
            return;
        }

        const fileFormData = new FormData();
        fileFormData.append('file', file);
        setIsAttachingFile(true);
        attachFile(salaParticipante.sala.codigo, fileFormData).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setSalaMensagens({
                    ...salaMensagens,
                    content: [
                        data,
                        ...salaMensagens.content
                    ]
                })
                setMensagem('');
            }
            setIsAttachingFile(false);
        })

    };

    const onEmojiClick = (event, emojiObject) => {
        setMensagem((prevState) => prevState + emojiObject.emoji);
    };

    const handleSMSelect = (sm) => {
        if (user.perfil === ADMIN) {
            const exist = listSMSelect.find(smSelect => smSelect.codigo === sm.codigo);
            if (!exist) {
                setListSMSelect([
                    ...listSMSelect,
                    sm
                ]);
            }
        }

    }

    const handleDownloadAttach = (sm) => {
        downloadFile(sm.codigo, sm.fileName, sm.fileType).then((data) => {
            console.log(data);
            if (data.error) {
                errorMessage(data.message);
            } else {
                createAndDownloadBlobFile(data, sm.fileName);

            }
        })
    }


    return (
        <Container className={classes.container} >
            <Grid container direction='column' spacing={1}>
                <Grid item>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Grid
                                container
                                alignItems='center'
                                direction='row'
                                spacing={3}
                            >
                                <Grid item>
                                    <Button
                                        component={Link}
                                        to={{
                                            pathname: '/mensagem'
                                        }}
                                        variant="outlined"
                                    >
                                        <ArrowBackIcon style={{ marginRight: '10px' }} />
                                    </Button>
                                </Grid>
                                {salaParticipante && !salaParticipante.sala.isGroup ?
                                    <>
                                        {salaParticipante.usuario && salaParticipante.usuario.fileName ?
                                            <Grid item>
                                                <img
                                                    src={salaParticipante.usuario.fotoUrl}
                                                    className={classes.fotoComponent}
                                                    alt=''
                                                />
                                            </Grid> : ''
                                        }
                                    </>
                                    :
                                    ''
                                }
                                <Grid item>
                                    <Typography variant="h6">
                                        {nameRoom}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>

                            {
                                user && user.perfil && (user.perfil === 'ADMIN' ||
                                    user.perfil === 'COORDERNADOR' ||
                                    user.perfil === 'PROFESSOR')
                                    ?
                                    <>
                                        <MenuIcon
                                            className={classes.menuIcon}
                                            onClick={(event) => handleMenuClick(event)}
                                            color="secondary"

                                        />
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem
                                                component={Link}
                                                to={{
                                                    pathname: '/group-list-participants',
                                                    state: {
                                                        sala: salaParticipante.sala
                                                    }
                                                }}
                                            >
                                                Lista Participantes
                                            </MenuItem>
                                        </Menu>
                                    </>

                                    : ''
                            }


                        </Grid>

                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container alignItems='center' direction='row' spacing={3}>

                    </Grid>
                </Grid>
                <Grid item>
                    {isLoading ?
                        <Loading description='Carregando Mensagens...' />
                        :
                        <>

                            <SMMenuOptions
                                listSMSelect={listSMSelect}
                                setListSMSelect={setListSMSelect}
                                setSalaMensagens={setSalaMensagens}
                            />
                            <div className={classes.boxMessages} >
                                {salaMensagens.content.length > 0 && salaMensagens.content[0].usuario.id === user.id ?
                                    <>
                                        {salaParticipante.quantNaoLidasOtherUser === 0 ? 'Visualizado' : 'Não Visualizado'}
                                    </> : ''
                                }

                                {user ?
                                    salaMensagens.content.map((sm, i) => (
                                        <div
                                            className={sm.usuario.id === user.id ? classes.boxCurrentUser : classes.boxOtherUser}
                                            key={i}
                                            onClick={() => handleSMSelect(sm)}
                                        >


                                            <div
                                                className={sm.usuario.id === user.id ? classes.itemCurrentUser : classes.itemOtherUser}
                                            >

                                                {(salaParticipante.sala.isGroup || isAll) && sm.usuario.id !== user.id ?
                                                    <div style={{ fontSize: 12 }}>{sm.usuario.nome}</div> :
                                                    ''
                                                }
                                                <Mensagem
                                                    sm={sm}
                                                />

                                                <PictureAttach
                                                    sm={sm}
                                                />
                                                <AttachFile
                                                    sm={sm}
                                                    handleDownloadAttach={handleDownloadAttach}
                                                />

                                                <div style={{ fontSize: 8 }}>{dateTimeFormat(sm.createdAt)}</div>
                                                {listSMSelect.length > 0 && listSMSelect.find(smSelect => smSelect.codigo === sm.codigo) && <CheckCircle style={{ color: green[500] }} />}

                                            </div>
                                        </div>
                                    ))
                                    : ''
                                }
                                {(salaMensagens.totalPages - 1) > salaMensagens.pageable.pageNumber ?
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginBottom: '20px'
                                    }} >
                                        <RefreshIcon
                                            onClick={handleMoreMesssages}
                                            fontSize="small"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div> : ''
                                }

                            </div>
                            {showEmojiPicker && <Picker pickerStyle={{ width: '100%' }} onEmojiClick={onEmojiClick} />}

                            <FormSendMessage
                                handleSend={handleSend}
                                handleEmojiShow={handleEmojiShow}
                                mensagem={mensagem}
                                handleChange={handleChange}
                                hiddenFileInput={hiddenFileInput}
                                handleChangeFile={handleChangeFile}
                                isAttachingFile={isAttachingFile}
                                handleAttachFile={handleAttachFile}
                                isSending={isSending}
                            />
                            <div ref={messagesEndRef} />
                        </>
                    }
                </Grid>
            </Grid>
        </Container>
    );
}