import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { findByPerfil } from '../Usuario/api-usuario-perfil';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';
import { save } from './api-tdp';
import { PROFESSOR } from "../Usuario/Perfil";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function UsuarioForm({ tdp, setTdp, updateTdps }) {
    const classes = useStyles();
    const [professoresPerfil, setProfessoresPerfil] = useState([]);

    useEffect(() => {
        findByPerfil(PROFESSOR).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setProfessoresPerfil(data);
            }
        }).catch((err => {
            console.log(err)
        }));

    }, []);

    const handleProfessorChange = (event) => {
        const professorPerfil = professoresPerfil.find((professorPerfil) => {
            return professorPerfil.usuario.id === event.target.value;
        });
        setTdp({
            ...tdp,
            usuario: professorPerfil.usuario
        });
    }

    const saveProfessor = (event) => {
        event.preventDefault();
        save(tdp).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                updateTdps(data);
                setTdp(null)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <Container className={classes.paper} component={Paper} maxWidth="sm">
            <Typography className={classes.title} variant="body1">
                Alterar Professor
            </Typography>
            <form onSubmit={saveProfessor}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    autoFocus
                    label="Turma"
                    name="turma"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={tdp.turma ? tdp.turma.descricao : ''}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    autoFocus
                    label="Disciplina"
                    name="disciplina"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={tdp.disciplina ? tdp.disciplina.descricao : ''}
                />
                <FormControl required fullWidth className={classes.formControl}>
                    <InputLabel id="professor-label">Professor</InputLabel>
                    <Select
                        labelId="professor-label"
                        id="professor-select"
                        value={tdp.usuario ? tdp.usuario.id : ''}
                        onChange={handleProfessorChange}
                    >
                        {professoresPerfil.map((professorPerfil) => (
                            <MenuItem key={professorPerfil.usuario.id} value={professorPerfil.usuario.id}>{professorPerfil.usuario.nome}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}>
                    Salvar
                </Button>
            </form>
        </Container>
    );
}