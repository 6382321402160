import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ConteudoTableForm from "./ConteudoTableForm";
import ConteudoTable from "./ConteudoTable";
import TextForm from "./componentsForm/TextoForm";
import ExerEssayForm from "./componentsForm/ExerEssayForm";
import ExerAlternativeForm from "./componentsForm/ExerAlternativeForm";
import VideoForm from "./componentsForm/VideoForm";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImagemForm from './componentsForm/ImagemForm';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
    },
    panelAddComponent: {
        border: 'solid 1px',
        padding: '20px',
        borderRadius: '10px'
    },
    btnComponent: {
        marginRight: '8px'
    },
    tabMenu: {
        marginTop: '20px'
    }
});

export default function ConteudoContainer() {
    const classes = useStyles();
    const [component, setComponent] = useState();
    const [conteudo, setConteudo] = useState();
    const livroAutor = useSelector(state => state.livroAutor);
    const [menuShow, setMenuShow] = useState(livroAutor ? 0 : 1);
    const capitulo = useSelector(state => state.capitulo);


    const handleComponentSelected = (value) => {
        setComponent(value);
    }

    const handleChange = (event, newValue) => {
        setMenuShow(newValue);
    };

    useEffect(() => {
        switch (component) {
            case 'text':
                setConteudo(
                    <TextForm
                        conteudoSelected={{
                            capitulo: capitulo,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;

            case 'exerEssay':
                setConteudo(
                    <ExerEssayForm
                        conteudoSelected={{
                            capitulo: capitulo,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;

            case 'exerAlternative':
                setConteudo(
                    <ExerAlternativeForm
                        conteudoSelected={{
                            capitulo: capitulo,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;
            case 'video':
                setConteudo(
                    <VideoForm
                        conteudoSelected={{
                            capitulo: capitulo,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;
            case 'image':
                setConteudo(
                    <ImagemForm
                        conteudoSelected={{
                            capitulo: capitulo,
                            componente: component,
                            eNovo: true
                        }}
                        setConteudoSelected={setConteudo}
                        setComponent={setComponent}
                    />)
                break;

            default:
                setConteudo(null);
        }
    }, [component, capitulo]);

    return (
        <div>
            {livroAutor ?
                <Grid className={classes.tabMenu} container justify="center">
                    <Grid item xs={6}>
                        <Paper className={classes.root}>
                            <Tabs
                                value={menuShow}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Editor" />
                                <Tab label="Pré-Visualização" />
                            </Tabs>
                        </Paper>
                    </Grid>
                </Grid> : ''
            }



            {menuShow === 0 ?
                <Container component={Paper} maxWidth="xl" className={classes.paper}>
                    <div className={classes.panelAddComponent}>
                        <Grid container justify='center' style={{ marginBottom: '20px' }}>
                            <Typography className={classes.title} variant="h6">
                                Adicionar Componente
                            </Typography>
                        </Grid>
                        <Grid container justify='center'>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('text')}
                                className={classes.btnComponent}>
                                Texto
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('exerEssay')}
                                className={classes.btnComponent}
                            >
                                Exercício Dissertativo
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('exerAlternative')}
                                className={classes.btnComponent}
                            >
                                Exercício Alternativa
                            </Button>
                            {capitulo && !capitulo.livro.isPublic &&
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleComponentSelected('video')}
                                    className={classes.btnComponent}
                                >
                                    Vídeo
                                </Button>
                            }

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleComponentSelected('image')}
                                className={classes.btnComponent}
                            >
                                Imagem
                            </Button>
                        </Grid>
                    </div>
                </Container>
                : ''}



            {conteudo ? conteudo :

                <>
                    {menuShow === 0 ? <ConteudoTableForm capitulo={capitulo} /> : ''}

                    {menuShow === 1 ?
                        <ConteudoTable /> : ''}
                </>

            }



        </div >
    );
}