import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { dateFormat } from '../../util/DateUtil';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        width: 'fit-content'
    },
});

export default function PresencaProfile({ presenca }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Turma
                        </Typography>
                        <Typography variant="body1">
                            {presenca ? presenca.tdp.turma.descricao : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Disciplina
                        </Typography>
                        <Typography variant="body1">
                            {presenca ? presenca.tdp.disciplina.descricao : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Data
                        </Typography>
                        <Typography variant="body1">
                            {presenca ? dateFormat(presenca.data) : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <CardActions>
                    <Button
                        component={Link}
                        to="/presenca"
                        color="secondary"
                        size="small"
                        variant="contained"
                    >
                        Trocar Presenca
                    </Button>
                </CardActions>

            </CardContent>
        </Card>
    );
}