import { SERVER_URL } from '../../constants';
import session from '../../SessionHandler/session-helper';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuth } from '../../util/FetchUtil';

const controller = 'tdp/';

const findByCurrentUser = () => {
    const url = SERVER_URL + controller + "current-user/" + session.getAnoLetivo();
    return fetchGetAuth(url);
}

const findByProfessores = () => {
    const url = SERVER_URL + controller + "professores/" + session.getAnoLetivo();
    return fetchGetAuth(url);
}

const findByTurma = (codTurma) => {
    const url = SERVER_URL + controller + codTurma;
    return fetchGetAuth(url);
}

export const findByDisciplina = (codDisciplina) => {
    const url = SERVER_URL + controller + 'query?codDisciplina=' + codDisciplina + '&ano-letivo=' + session.getAnoLetivo();
    return fetchGetAuth(url);
}

export const findByUsuario = (codUsuario) => {
    const url = SERVER_URL + controller + 'query?codUsuario=' + codUsuario + '&ano-letivo=' + session.getAnoLetivo();
    return fetchGetAuth(url);
}

const findByAnoLetivo = () => {
    const url = SERVER_URL + controller + 'ano-letivo/' + session.getAnoLetivo();
    return fetchGetAuth(url);
  }

const findUnregisteredDisciplinas = (codTurma) => {
    const url = SERVER_URL + controller + "unregistered-disciplinas/" + codTurma;
    return fetchGetAuth(url);
}

const save = (tdp) => {
    const url = SERVER_URL + controller + 'save';
    return fetchPostAuth(url, tdp);
}

const remove = (codTdp) => {
    const url = SERVER_URL + controller + codTdp;
    return fetchRemoveAuth(url);
} 

export {
    findUnregisteredDisciplinas,
    save, 
    findByTurma, 
    findByAnoLetivo, 
    findByCurrentUser, 
    remove, 
    findByProfessores
}