import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { findBySerie, findAll } from '../Livro/api-livro';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function LivroForm({ tdp, setTdp, saveLivro }) {
    const classes = useStyles();
    const [livros, setLivros] = useState([]);
    const [isLivrosAll, setIsLivrosAll] = useState(false);

    useEffect(() => {
        findBySerie(tdp.turma.serie.codSerie).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setLivros(data);
            }
        }).catch((err => {
            console.log(err)
        }));

    }, [tdp.turma.serie.codSerie]);


    useEffect(() => {
        if (isLivrosAll) {
            findAll().then((data) => {
                if (data.error) {
                    console.log(data);
                } else {
                    setLivros(data);
                }
            })
        }

    }, [isLivrosAll]);


    const handleSwitchChange = () => event => {
        setIsLivrosAll(event.target.checked);
    };

    const handleLivroChange = (event) => {
        const livro = livros.find((livro) => {
            return livro.codLivro === event.target.value;
        });
        setTdp({
            ...tdp,
            livro: livro
        });
    }

    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper} maxWidth="sm">
                <Typography className={classes.title} variant="body1">
                    Alterar Livro
                </Typography>
                <form onSubmit={saveLivro}>
                    <TextField
                        variant="outlined"
                        size='small'
                        margin="normal"
                        fullWidth
                        autoFocus
                        label="Turma"
                        name="turma"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={tdp.turma.descricao || ''}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoFocus
                        size='small'
                        label="Disciplina"
                        name="disciplina"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={tdp.disciplina.descricao || ''}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isLivrosAll ? true : false}
                                onChange={handleSwitchChange('ativo')}
                                value={isLivrosAll}
                            />
                        }
                        label="Todos os livros"
                    />
                    <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel id="livro-label">Livro</InputLabel>
                        <Select
                            labelId="livro-label"
                            id="livro-select"
                            value={tdp.livro ? tdp.livro.codLivro : ''}
                            onChange={handleLivroChange}
                        >
                            {livros.map((livro, index) => (
                                <MenuItem key={index} value={livro.codLivro}>{livro.descricao}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </form>
            </Container>
        </div>
    );
}