import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { dateTimeFormat } from "../../../util/DateUtil";

const useStyles = makeStyles({
  text: {
    textAlign: "center",
  },
});

const CatracaRegistroCheckTable = ({ catracaRegistroCheck }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Typography variant="body1">
          Aluno: {catracaRegistroCheck.alunoTurma.aluno.codAlunoEscola} -{" "}
          {catracaRegistroCheck.alunoTurma.aluno.nome}
        </Typography>
      </Grid>
      {catracaRegistroCheck.listCR.length > 0 ? (
        <Grid item>
          <Grid container spacing={3} direction="row">
            {catracaRegistroCheck.listCR.map((catracaRegistro) => (
              <>
                <Grid item xs={3}>
                  <Card variant="outlined">
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={12}>
                        <Typography className={classes.text} variant="h5">
                          {catracaRegistro.entradaSaida}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.text}
                          variant="subtitle1"
                        >
                          {dateTimeFormat(catracaRegistro.dataHora)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.text} variant="body1">
                          {catracaRegistro.entradaSaida === "ENTRADA" &&
                            "Entrada Liberada"}
                          {catracaRegistro.saiuSozinho &&
                            "Autorizado(a) a Sair Sozinho(a)"}
                          {catracaRegistro.usuarioRetirou &&
                            "Acompanhado(a) com " +
                              catracaRegistro.usuarioRetirou.nome +
                              "."}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="body1">Sem Registro</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CatracaRegistroCheckTable;
