import auth from '../auth/auth-helper';
import { store } from '../configureStore';
import { setLogout } from '../auth/actions/actions';

export const fetchPutAuth = (url, body) => {
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth.isAuthenticated()
        },
        body: JSON.stringify(body)
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err);
    });
}

export const fetchPostAuth = (url, body) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': auth.isAuthenticated()
        },
        body: JSON.stringify(body)
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err);
    });
}

export const fetchPostAuthFile = (url, body) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': auth.isAuthenticated(),
        },
        body: body
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err);
    });
}

export const fetchRemoveAuth = (url) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth.isAuthenticated()
        }
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err); 
    });
}

export const fetchRemoveAuthReturn = (url) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth.isAuthenticated()
        }
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err);
    });
}

const errorHandler = (err) => {
    if (err.message === "Unexpected token < in JSON at position 0") {
        store.dispatch(setLogout(true));
    } else {
        console.log(err);
    }
}

export const fetchGetAuth = (url) => {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth.isAuthenticated()
        }
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err);
    });
}

export const fetchGet = (url) => {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        return response.json();
    }).catch((err) => {
        errorHandler(err);
    });
}

export const fetchGetAuthBlob = (url) => {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth.isAuthenticated()
        }
    }).then((response) => {
        console.log(response);
        return response.blob();
    }).catch((err) => {
        errorHandler(err);
    });
}