import {
    SET_SERIE, 
    SET_SERIES,
    ADD_SERIES, 
    UPDATE_SERIES, 
    REMOVE_SERIES
} from './actionTypes'

export function setSerie(serie){
    return {type: SET_SERIE, serie}
}

export function setSeries(series){
    return {type: SET_SERIES, series}
}

export function addSeries (serie) {
    return { type: ADD_SERIES, serie }
}

export function updateSeries(serie){
    return {type : UPDATE_SERIES, serie}
}

export function removeSeries(codSerie){
    return {type : REMOVE_SERIES, codSerie}
}