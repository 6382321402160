import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LivroProfessorTable from './LivroProfessorTable';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const LivroProfessorContainer = () => {
    const classes = useStyles();

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Livros Professor
                    </Typography>
                </Grid>


            </Grid>

            <Grid >
                <LivroProfessorTable />
            </Grid>

        </Container>
    );
}

export default LivroProfessorContainer;