import { Grid, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { dateTimeFormat } from '../../util/DateUtil';
import { ADMIN, COORDENADOR, PROFESSOR } from '../Usuario/Perfil';
import BadgeQuantNaoLidas from './MensagemTable/BadgeQuantNaoLidas';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserIcon from '../../shared/assets/img/User.png';
import Divider from '@material-ui/core/Divider';
import Button from '../UI/Button';

const useStyles = makeStyles({
    fotoComponent: {
        // width: '80px',
        maxHeight: '80px',
        borderRadius: '100%',
        marginRight: '20px'
    },
    mensUserComp: {
        cursor: 'pointer',
    }
})

const MensagemUser = ({ sp, getNameRoom }) => {
    const classes = useStyles();
    const user = useSelector(state => state.usuario);
    const [anchorTableEl, setAnchorTableEl] = useState(null);
    const [salaSelected, setSalaSelected] = useState(null);
    const history = useHistory();

    const handleMenuTableClick = (event, sala) => {
        setAnchorTableEl(event.currentTarget);
        setSalaSelected(sala);
    };

    const handleMenuTableClose = () => {
        setAnchorTableEl(null);
    };

    const handleSalaMensagem = () => {
        history.push('/sala-mensagem', {
            salaParticipante: sp,
            nameRoom: getNameRoom(sp)
        })
    }

    return (
        <Grid container spacing={3} >
            <Grid
                item
                xs={12}
                spacing={2}
            >
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                >
                    <Grid item xs={2} >
                        {sp.sala.isGroup ?
                            <img
                                src={UserIcon}
                                alt=''
                                className={classes.fotoComponent}
                            /> :
                            <>
                                {sp.usuario.fileName ?
                                    <img
                                        className={classes.fotoComponent}
                                        src={sp.usuario.fotoUrl}
                                        alt=''
                                    /> :
                                    <img
                                        src={UserIcon}
                                        alt=''
                                        className={classes.fotoComponent}
                                    />
                                }
                            </>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        className={classes.mensUserComp}
                        onClick={handleSalaMensagem}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                {sp.sala.isGroup ?
                                    'Grupo: ' + sp.sala.descricao :
                                    <>
                                        {sp.usuario.nome}
                                    </>
                                }

                                <BadgeQuantNaoLidas quantNaoLidas={sp.quantNaoLidas} />
                            </Grid>
                            <Grid item xs={12}>
                                {dateTimeFormat(sp.sala.dataHoraUtlimaMensagem)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            onClick={handleSalaMensagem}
                            color='secondary'
                        >
                            Ir para sala
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        {sp.sala.isGroup &&
                            user && user.perfil && (user.perfil === ADMIN ||
                                user.perfil === COORDENADOR ||
                                user.perfil === PROFESSOR)
                            ?
                            <>
                                <MoreVertIcon
                                    id={"menu" + sp.sala.codigo}
                                    fontSize='small'
                                    className={classes.mensUserComp}
                                    onClick={(event) => handleMenuTableClick(event, sp.sala)}
                                    color="secondary"

                                />
                                <Menu
                                    id={"simple-menu" + sp.sala.codigo}
                                    anchorEl={anchorTableEl}
                                    keepMounted
                                    open={Boolean(anchorTableEl)}
                                    onClose={handleMenuTableClose}
                                >
                                    <MenuItem
                                        component={Link}
                                        to={{
                                            pathname: '/group-form',
                                            state: {
                                                sala: salaSelected
                                            }
                                        }}
                                    >
                                        Atualizar Grupo
                                    </MenuItem>
                                    <MenuItem
                                        component={Link}
                                        to={{
                                            pathname: '/group-list-participants',
                                            state: {
                                                sala: salaSelected
                                            }
                                        }}
                                    >
                                        Lista Participantes
                                    </MenuItem>
                                </Menu>
                            </>
                            : ''}
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
}

export default MensagemUser;