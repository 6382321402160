import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByTdpNumeroBimestre } from './api-conceito-aluno';
import { findByAnoLetivoESerieCodSerie } from "../Bimestre/api-bimestre";
import { useSelector, useDispatch } from 'react-redux';
import { setConceitoAluno, setConceitoAlunos } from './actions/actions';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Loading from '../UI/Loading';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        paddingBottom: '20px'
    },
    title: {
        paddingTop: '20px;',
        paddingBottom: '20px;'
    },
    rowNotSelected: {
        cursor: 'pointer'
    },
    rowSelected: {
        cursor: 'pointer',
        backgroundColor: '#b5e7a4',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function ConceitoAlunoTable() {
    const classes = useStyles();
    const tdp = useSelector(state => state.tdp);
    const conceitoAlunos = useSelector(state => state.conceitoAlunos);
    const conceitoAluno = useSelector(state => state.conceitoAluno);
    const dispatch = useDispatch();
    const [numeroBimestre, setNumeroBimestre] = useState(1);
    const [bimestresSerie, setBimestresSerie] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findByTdpNumeroBimestre(tdp.codigo, numeroBimestre).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                dispatch(setConceitoAlunos(data));
                if (data.length > 0) {
                    dispatch(setConceitoAluno(data[0]));
                }
            }
            setIsLoading(false);
        });
    }, [tdp, numeroBimestre, dispatch]);

    useEffect(() => {
        findByAnoLetivoESerieCodSerie(tdp.turma.serie.codSerie).then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                setBimestresSerie(data);
                if (data.length > 0) {
                    setNumeroBimestre(data[0].numeroBimestre);
                }
            }
        })
    }, [tdp]);

    const handleBimestreChange = (event) => {
        setNumeroBimestre(event.target.value);
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <Grid container className={classes.title} justify="space-between">
                <Grid item xs={5}>
                    <Typography variant="h5">
                        Conceito Aluno
                    </Typography>
                </Grid>
            </Grid>

            {bimestresSerie.length > 1 ?
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <FormControl >
                            <InputLabel id="bimestre-label">Bimestre</InputLabel>
                            <Select
                                labelId="bimestre-label"
                                id="bimestre-select"
                                value={numeroBimestre}
                                onChange={handleBimestreChange}
                            >
                                {bimestresSerie.map((bs) => (
                                    <MenuItem value={bs.numeroBimestre}>{bs.numeroBimestre}º Bimestre</MenuItem>
                                ))}
                                <MenuItem value={5}>Conceito Final</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                </Grid> : ''
            }

            {isLoading ? <Loading description="Carregando Conceito Aluno..." /> :
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Aluno</TableCell>
                            <TableCell>Conceito</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {conceitoAlunos ? conceitoAlunos.map((ca) => (
                            <TableRow
                                className={conceitoAluno && conceitoAluno.codigo === ca.codigo ? classes.rowSelected : classes.rowNotSelected}
                                onClick={() => dispatch(setConceitoAluno(ca))} key={ca.codigo}
                            >
                                <TableCell>{ca.alunoTurma.aluno.nome}</TableCell>
                                <TableCell>{ca && ca.conceito ? ca.conceito.descricao : ''}</TableCell>
                            </TableRow>
                        )) : ''}
                    </TableBody>
                </Table>
            }

        </Container>
    );
}