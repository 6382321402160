import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { findByUsuario, remove, save } from './api-aluno-responsavel';
import { changeARAlunoAdmin } from '../Usuario/api-usuario';
import 'react-toastify/dist/ReactToastify.css';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import AlunoFindDialog from "../Aluno/AlunoFindDialog";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { findAll } from "../Parentesco/api-parentesco";
import { Switch } from '@material-ui/core';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '10px',
        marginTop: '20px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cursorLink: {
        cursor: 'pointer'
    },
    messageCapitulo: {
        background: '#a5d6a7',
        padding: '20px'
    }
}));

export default function AlunoResponsavelTableAdd({ usuario, setUsuario }) {
    const classes = useStyles();
    const [alunosResponsavel, setAlunosResponsavel] = useState([]);
    const [parentescos, setParentescos] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);

    useEffect(() => {
        findAll().then((data) => {
            if (data.error) {
                errorMessage(data.message)
            } else {
                setParentescos(data);
            }
        })
    }, []);



    useEffect(() => {
        if (usuario) {
            findByUsuario(usuario.id).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setAlunosResponsavel(data);
                }
            })
        }


    }, [usuario]);

    const handleSetAluno = (aluno) => {
        const alunoResponsavel = {
            aluno: aluno,
            usuario: usuario,
            eNovo: true
        };

        handleSave(alunoResponsavel);
    }


    const handleParentescoChange = (event, arChange) => {
        const parentesco = parentescos.find((parentesco) => {
            return parentesco.codigo === event.target.value
        });
        arChange.parentesco = parentesco;
        handleSave(arChange);

    }

    const handleSave = (arChange) => {
        save(arChange).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                if (arChange.eNovo) {
                    setAlunosResponsavel((prevState) => ([
                        data,
                        ...prevState
                    ]));
                } else {
                    setAlunosResponsavel(
                        alunosResponsavel.map(ar => {
                            if (ar.codigo === arChange.codigo) {
                                return arChange;
                            } else {
                                return ar;
                            }
                        })
                    );
                }

            }
        })
    }

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja remover este registro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(alunosResponsavel[index].codigo).then((data) => {
                            if (data && data.error) {
                                console.log(data);
                            } else {
                                setAlunosResponsavel(alunosResponsavel.filter(ar => ar.codigo !== alunosResponsavel[index].codigo))
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };


    const handleAdicionarAluno = () => {
        setOpenSearch(true);
    }

    const handleFinanceiroChange = (ar) => {
        const alunoResponsavel = {
            ...ar,
            financeiro: !ar.financeiro
        }
        handleSave(alunoResponsavel);
    }

    const handleAtivarAluno = (arChange) => {
        changeARAlunoAdmin(arChange.usuario.id, arChange.aluno.codigo).then((data) => {
            if (data.error) {
                errorMessage(data);
            } else {
                setUsuario(data);
            }
        })
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <AlunoFindDialog
                openSearch={openSearch}
                setOpenSearch={setOpenSearch}
                setAluno={handleSetAluno}
            />

            <Typography className={classes.title} variant="body1">
                Selecione os Alunos que este Usuário é responsável.
            </Typography>
            <Button
                onClick={handleAdicionarAluno}
                variant="contained"
                color="primary"
                className={classes.submit}>
                Adicionar
            </Button>

            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Cód. Aluno</TableCell>
                        <TableCell>Nome Aluno</TableCell>
                        <TableCell>Parentesco</TableCell>
                        <TableCell>Excluir</TableCell>
                        <TableCell>Responsável Financeiro</TableCell>
                        <TableCell>Aluno Ativo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alunosResponsavel.length > 0 ? alunosResponsavel.map((ar, index) => (
                        <TableRow key={ar.codigo}>
                            <TableCell>{ar.aluno.codAlunoEscola}</TableCell>
                            <TableCell>{ar.aluno.nome}</TableCell>
                            <TableCell>
                                <Select
                                    labelId="parentesco-label"
                                    id="parentesco-select"
                                    value={ar.parentesco ?
                                        ar.parentesco.codigo : ''}
                                    onChange={(event) => handleParentescoChange(event, ar)}
                                >
                                    {parentescos.map((parentesco, index) => (
                                        <MenuItem key={index} value={parentesco.codigo}>
                                            {parentesco.descricao}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </TableCell>
                            <TableCell>
                                <DeleteIcon
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    style={{ marginRight: '10px' }}
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell>
                                <Switch
                                    checked={ar && ar.financeiro ? true : false}
                                    onChange={() => handleFinanceiroChange(ar)}
                                    value={usuario && usuario.ativo ? true : false}
                                />
                            </TableCell>
                            <TableCell>
                                {usuario.arAlunoSelecionado && usuario.arAlunoSelecionado.codigo === ar.aluno.codigo ?
                                    'Ativo' :
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleAtivarAluno(ar)}
                                    >
                                        Ativar
                                    </Button>
                                }
                            </TableCell>
                        </TableRow>
                    )) : null}
                </TableBody>
            </Table>
        </Container>
    );
}