import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Cancel, CheckCircle } from "@material-ui/icons";
import React from "react";
import TablePaginationActions from "../../shared/components/Table/Actions";
import { dateTimeFormat } from "../../util/DateUtil";
import { forUserKey, toUserKey } from "./TarefaContainer";

const TarefaTable = ({ tarefas, handleChangePage, setTarefa, menu }) => {
  return (
    <>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={tarefas.size ? tarefas.size : 0}
        component="div"
        count={tarefas.totalElements}
        page={tarefas.number}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={({ from, to, count }) =>
          from +
          "-" +
          to +
          " de " +
          (count !== -1 ? count + " registros" : +" mais de " + to)
        }
      />
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            {menu === toUserKey ? (
              <TableCell>Atribuído pelo Usuário</TableCell>
            ) : (
              ""
            )}
            {menu === forUserKey ? (
              <TableCell>Atribuído para o Usuário</TableCell>
            ) : (
              ""
            )}

            <TableCell>Data Hora</TableCell>
            <TableCell>Possui Prazo</TableCell>
            <TableCell>Data Hora Prazo</TableCell>
            <TableCell>Finalizado</TableCell>
            <TableCell>Data Hora Finalizado</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tarefas.content.map((tarefa) => (
            <TableRow key={tarefa.codigo}>
              <TableCell>
                {tarefa.descricao
                  ? tarefa.descricao.length > 50
                    ? tarefa.descricao.substring(0, 50)
                    : tarefa.descricao
                  : ""}
              </TableCell>
              {menu === toUserKey ? (
                <TableCell>{tarefa.usuarioPor.nome}</TableCell>
              ) : (
                ""
              )}
              {menu === forUserKey ? (
                <TableCell>{tarefa.usuarioPara.nome}</TableCell>
              ) : (
                ""
              )}

              <TableCell>{dateTimeFormat(tarefa.dataHora)}</TableCell>
              <TableCell>
                {tarefa.possuiPrazo ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Cancel style={{ color: red[500] }} />
                )}
              </TableCell>
              <TableCell>
                {tarefa.dataHoraPrazo
                  ? dateTimeFormat(tarefa.dataHoraPrazo)
                  : ""}
              </TableCell>
              <TableCell>
                {tarefa.finalizado ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Cancel style={{ color: red[500] }} />
                )}
              </TableCell>
              <TableCell>
                {tarefa.dataHoraFinalizado
                  ? dateTimeFormat(tarefa.dataHoraFinalizado)
                  : ""}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  fontSize="small"
                  onClick={() => setTarefa(tarefa)}
                >
                  Selecionar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={tarefas.size ? tarefas.size : 0}
        component="div"
        count={tarefas.totalElements}
        page={tarefas.number}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={({ from, to, count }) =>
          from +
          "-" +
          to +
          " de " +
          (count !== -1 ? count + " registros" : +" mais de " + to)
        }
      />
    </>
  );
};

export default TarefaTable;
