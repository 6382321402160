import { Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { findAll } from './api-cardapio';
import Container from '../UI/Container';
import Loading from '../UI/Loading';
import CardapioTable from './CardapioTable';
import CardapioForm from './CardapioForm';
import Button from '../UI/Button';
import CardapioSemanaContainer from './Semana/CardapioSemanaContainer';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    cardapioSemanaContainer: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    btnMenuIntegral: {
        marginTop: '10px',
        marginBottom: '10px'
    }
})

const CardapioContainer = () => {
    const classes = useStyles();
    const [cardapios, setCardapios] = useState([]);
    const [cardapio, setCardapio] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();


    useEffect(() => {
        findAll().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setCardapios(data);
            }
            setIsLoading(false);
        })
    }, []);

    const handleNovoRegistro = () => {
        setCardapio({
            eNovo: true
        })
    }

    const handleMenuIntegral = () => {
        history.push("/integral");
    }

    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Cardápio
            </Typography>

            <div className={classes.btnMenuIntegral}>
                <Button
                    onClick={handleMenuIntegral}
                >
                    Menu Integral
                </Button>
            </div>

            <Button onClick={handleNovoRegistro}>Novo Registro</Button>
            {isLoading ? <Loading description='Carregando Cardápios...' /> :

                <>
                    {cardapio ?
                        <>
                            <CardapioForm
                                cardapio={cardapio}
                                setCardapio={setCardapio}
                                setCardapios={setCardapios}
                            />
                            <div className={classes.cardapioSemanaContainer}>
                                <CardapioSemanaContainer cardapio={cardapio} />
                            </div>
                        </> :

                        <CardapioTable
                            cardapios={cardapios}
                            setCardapio={setCardapio}
                            setCardapios={setCardapios}
                        />
                    }
                </>

            }


        </Container>
    )
}

export default CardapioContainer;