import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';


export default function RelatorioBoletimEletivaWeb({ boletimEscolar }) {

    return (
        <>
            <Typography
                style={{
                    textAlign: 'center',
                    textDecoration: 'underline'
                }}
                variant="h6">
                Projetos (PBL)
            </Typography>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Disciplinas</TableCell>
                        <TableCell>Conceito Final</TableCell>
                        <TableCell>Faltas</TableCell>
                        <TableCell>AC</TableCell>
                        <TableCell>Carga Horária</TableCell>
                        <TableCell>Frequência(%)</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {boletimEscolar.bprojetos.map((bep, index) => (
                        <TableRow key={index}>
                            <TableCell>{bep.tdp.disciplina.descricao}</TableCell>
                            <TableCell>{bep.conceitoFinal && bep.conceitoFinal.conceito ? bep.conceitoFinal.conceito.descricao : '--'}</TableCell>
                            <TableCell>{bep.totalFaltas ? bep.totalFaltas : '--'}</TableCell>
                            <TableCell>{bep.ausenciaCompensada ? bep.ausenciaCompensada : '--'}</TableCell>
                            <TableCell>{bep.tdp.cargaHoraria ? bep.tdp.cargaHoraria : '--'}</TableCell>
                            <TableCell>{bep.frequencia}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}