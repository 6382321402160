import {
  SET_CRONOGRAMA_AULA,
  SET_CRONOGRAMAS_AULA,
  ADD_CRONOGRAMAS_AULA,
  UPDATE_CRONOGRAMAS_AULA,
  REMOVE_CRONOGRAMAS_AULA,
  OPEN_CRONOGRAMA_AULA,
  CLOSE_CRONOGRAMA_AULA
} from './actionTypes';
import { PURGE } from "redux-persist";

export function cronogramaAula(state = null, action) {
  switch (action.type) {
    case SET_CRONOGRAMA_AULA:
      return action.cronogramaAula

    case PURGE:
      return null;

    default:
      return state
  }
}

export function cronogramasAula(state = [], action) {
  switch (action.type) {
    case SET_CRONOGRAMAS_AULA:
      return action.cronogramasAula

    case ADD_CRONOGRAMAS_AULA:

      return [
        ...state,
        action.cronogramaAula
      ]

    case UPDATE_CRONOGRAMAS_AULA:
      let content = state.map((cronogramaAula) => {
        if (cronogramaAula.codigo === action.cronogramaAula.codigo) {
          return action.cronogramaAula;
        } else {
          return cronogramaAula;
        }
      });
      return content;

    case REMOVE_CRONOGRAMAS_AULA:
      return state.filter((cronogramaAula) => cronogramaAula.codigo !== action.codigo);

    case PURGE:
      return [];

    default:
      return state
  }
}

export function openSearchCronogramaAula(state = false, action) {
  switch (action.type) {
    case OPEN_CRONOGRAMA_AULA:
      return true;
    case CLOSE_CRONOGRAMA_AULA:
      return false;
    case PURGE:
      return false;
    default:
      return false;
  }
}