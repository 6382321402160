import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { quantidadeBimestre } from '../Planejamento/api-planejamento';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    adicionarDisciplina: {
        paddingBottom: '10px',
    },
    formControl: {
        marginBottom: '10px'
    }, 
    loading :{
        marginRight: '20px',
        marginLeft: '20px'
    }
});

export default function PlanejamentoConsultaQuant() {
    const classes = useStyles();
    const [listaPlanQuant, setListaPlanQuant] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        quantidadeBimestre().then((data) => {
            if (data) {
                setListaPlanQuant(data);
                setLoading(false);
            }
        })

    }, []);



    return (
        <div>
            <CssBaseline />
            <Container className={classes.paper} component={Paper}>
                <Typography className={classes.title} variant="h5">
                    Planejamento Consulta Quantidade
                </Typography>
                {loading ?
                    <>
                        <CircularProgress className={classes.loading} color="primary" />
                        Carregando... 
                    </>
                    :
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Turma</TableCell>
                                <TableCell>Disciplina</TableCell>
                                <TableCell>Professor</TableCell>
                                <TableCell>1º Bimestre</TableCell>
                                <TableCell>2º Bimestre</TableCell>
                                <TableCell>3º Bimestre</TableCell>
                                <TableCell>4º Bimestre</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaPlanQuant.length > 0 ? listaPlanQuant.map((planQuant, index) => (
                                <TableRow key={index}>
                                    <TableCell>{planQuant.tdp.turma.descricao}</TableCell>
                                    <TableCell>{planQuant.tdp.disciplina.descricao}</TableCell>
                                    <TableCell>{planQuant.tdp.usuario ? planQuant.tdp.usuario.nome : ''}</TableCell>
                                    <TableCell style={{ backgroundColor: planQuant.quant1Bim === 0 ? '#ef9a9a' : '' }}>{planQuant.quant1Bim}</TableCell>
                                    <TableCell style={{ backgroundColor: planQuant.quant2Bim === 0 ? '#ef9a9a' : '' }}>
                                        {planQuant.quant2Bim}
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: planQuant.quant3Bim === 0 ? '#ef9a9a' : '' }}>{planQuant.quant3Bim}</TableCell>
                                    <TableCell style={{ backgroundColor: planQuant.quant4Bim === 0 ? '#ef9a9a' : '' }}>{planQuant.quant4Bim}</TableCell>
                                </TableRow>
                            )) : ''}
                        </TableBody>
                    </Table>
                }



            </Container>

        </div>
    );
}