import { SERVER_URL } from '../../../constants';
import session from '../../../SessionHandler/session-helper';

import {
    fetchGetAuth,
    fetchPostAuth,
    fetchRemoveAuthReturn
} from '../../../util/FetchUtil';

const controller = 'dia-nao-letivo/';

export const findByAnoLetivo = () => {
    const url = SERVER_URL + controller + session.getAnoLetivo();
    return fetchGetAuth(url);
}

export const save = (diaNaoLetivo) => {
    const url = SERVER_URL + controller;
    return fetchPostAuth(url, diaNaoLetivo);
}

export const deleteById = (codigo) => {
    const url = SERVER_URL + controller + codigo;
    return fetchRemoveAuthReturn(url);
}