import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TurmaDisciplinaProfessorProfileInfo from './TurmaDisciplinaProfessorProfileInfo';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '100%',
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function TurmaDisciplinaProfessorProfile({ tdp, onTdpSelected }) {
    const classes = useStyles();

    const handleTrocaTdp = () => {
        onTdpSelected(null)
    }

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <TurmaDisciplinaProfessorProfileInfo
                    tdp={tdp} />
                <CardActions>
                    <Button
                        onClick={handleTrocaTdp}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >Trocar Turma/Disciplina</Button>
                </CardActions>

            </CardContent>
        </Card>
    );
}