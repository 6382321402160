import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import PresencaAlunoTable from './PresencaAlunoTable';
import PresencaAlunoForm from './PresencaAlunoForm';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import { useEffect } from 'react';
import { faltou, findByPresenca, presente } from './api-presenca-aluno';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import PresencaProfile from '../Presenca/PresencaProfile';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
});

export default function PresencaAlunoContainer(props) {
    const classes = useStyles();
    const { presenca } = props.location.state;
    const [presencaAluno, setPresencaAluno] = useState(null);
    const [presencaAlunos, setPresencaAlunos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (presenca) {
            setIsLoading(true);
            findByPresenca(presenca.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPresencaAlunos(data);
                    setPresencaAluno(data[0]);
                }
                setIsLoading(false);
            })
        }

    }, [presenca]);

    const nextPA = () => {
        const index = presencaAlunos.map(pa => pa.codigo).indexOf(presencaAluno.codigo);
        const size = presencaAlunos.length;
        if (index + 1 === size) {
            setPresencaAluno(presencaAlunos[0])
        } else {
            setPresencaAluno(presencaAlunos[index + 1])
        }
    }

    const handleFaltou = () => {
        if (presencaAluno.legendaDiario.codigo !== 2) {
            faltou(presencaAluno.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    updatePresencaAlunos(data)
                }
            }).catch(err => console.log(err));
        }
        nextPA();
    }

    const handlePresente = () => {
        if (presencaAluno.legendaDiario.codigo !== 1) {
            presente(presencaAluno.codigo).then((data) => {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    updatePresencaAlunos(data)
                }
            }).catch(err => console.log(err));
        }
        nextPA();

    }

    const updatePresencaAlunos = (pa) => {
        setPresencaAlunos((prevState) => prevState.map((ps) => {
            if (ps.codigo === pa.codigo) {
                return pa;
            } else {
                return ps;
            }
        }))
    }

    return (
        <Container className={classes.paper} component={Paper}>
            <PresencaProfile
                presenca={presenca}
            />
            <Typography className={classes.title} variant="h5">
                Presença Alunos
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <PresencaAlunoTable
                        isLoading={isLoading}
                        presencaAlunos={presencaAlunos}
                        presencaAluno={presencaAluno}
                        setPresencaAluno={setPresencaAluno}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PresencaAlunoForm
                        presencaAluno={presencaAluno}
                        handleFaltou={handleFaltou}
                        handlePresente={handlePresente}
                    />
                </Grid>
            </Grid>

        </Container>
    );
}