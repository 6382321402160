import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AlunoResponsavelCard from '../AlunoResponsavel/AlunoResponsavelCard';

const useStyles = makeStyles({
    gridAlunoCard: {
        cursor: 'pointer'
    },
    typographyAlunos: {
        marginTop: '20px',
        marginBottom: '20px',
    }
});

const MensagemFindTurmaAlunoTurmaList = ({ alunosResponsaveis, handleSelectedUser }) => {
    const classes = useStyles();

    const handleSelectedAR = (ar) => {
        handleSelectedUser(ar.usuario);
    }

    return (
        <>
            <Typography
                variant="h5"
                className={classes.typographyAlunos}
            >
                Responsáveis
            </Typography>
            {alunosResponsaveis && alunosResponsaveis.length > 0 ?
                <>
                    {alunosResponsaveis.map((ar) => (
                        <Grid
                            item
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            xs={12}
                            key={ar.codigo}
                            onClick={() => handleSelectedAR(ar)}
                            className={classes.gridAlunoCard}
                        >
                            <AlunoResponsavelCard alunoResponsavel={ar} />
                        </Grid>
                    ))
                    }
                </> :
                null
            }
        </>
    );
}

export default MensagemFindTurmaAlunoTurmaList;