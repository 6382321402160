export const PROFESSOR = 'PROFESSOR';
export const RESPONSAVEL = 'RESPONSAVEL';
export const ALUNO = 'ALUNO';
export const ADMIN = 'ADMIN';
export const COORDENADOR = 'COORDENADOR';
export const SECRETARIA = 'SECRETARIA';

export const perfilText = (perfil) => {
    if (perfil === PROFESSOR) {
        return 'Professor';
    } else if (perfil === RESPONSAVEL) {
        return 'Responsável';
    } else if (perfil === ALUNO) {
        return 'Aluno';
    } else if (perfil === ADMIN) {
        return 'Admin';
    } else if (perfil === COORDENADOR) {
        return 'Coordenador';
    } else if (perfil === SECRETARIA) {
        return 'Secretaria';
    } else {
        return '';
    }
}