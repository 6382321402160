import {
  UPDATE_ATIVIDADE_ALUNOS,
  SET_ATIVIDADE_ALUNOS,
  SET_ATIVIDADE_ALUNO,
  ADD_ATIVIDADE_ALUNOS,
  REMOVE_ATIVIDADE_ALUNOS
} from './actionTypes';
import { PURGE } from "redux-persist";

export function atividadeAluno(state = null, action) {
  switch (action.type) {
    case SET_ATIVIDADE_ALUNO:
      return action.atividadeAluno;

    case PURGE:
      return null;
    default:
      return state;
  }
}

export function atividadeAlunos(state = [], action) {

  switch (action.type) {

    case REMOVE_ATIVIDADE_ALUNOS:
      return state.filter((aa) => aa.codigo !== action.codAtividadeAluno)

    case ADD_ATIVIDADE_ALUNOS:
      return [
        action.atividadeAluno,
        ...state
      ]

    case UPDATE_ATIVIDADE_ALUNOS:
      return state.map((aa) => {
        if (aa.codigo === action.atividadeAluno.codigo) {
          return action.atividadeAluno;
        } else {
          return aa;
        }
      });

    case SET_ATIVIDADE_ALUNOS:
      return action.atividadeAlunos

    case PURGE:
      return [];

    default:
      return state
  }
}
