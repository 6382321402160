import React from "react";
import Button from "../UI/Button";
import { Grid, Paper, Typography } from "@material-ui/core";
import Container from "../UI/Container";
import { Link } from "react-router-dom";

const CatracaRegistroMenu = () => {
  return (
    <Container component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Monitor de Acesso</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3} direction="row">
            <Grid item>
              <Button
                component={Link}
                to={{
                  pathname: "/monitor-acesso",
                }}
              >
                Monitor de Acesso por Aluno
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to={{
                  pathname: "/monitor-acesso-check",
                }}
              >
                Monitor de Acesso - confere todos alunos turma
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CatracaRegistroMenu;
