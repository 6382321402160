import React, { useState, useEffect } from 'react';

import { findByAnoLetivo } from './api-turma';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import TurmaTableSelectView from './TurmaTableSelectView';




export default function TurmaTableSelect({ onTurmaSelected }) {

    const [turmas, setTurmas] = useState([]);

    useEffect(() => {
        findByAnoLetivo().then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTurmas(data);
                }
            }
        });
    }, []);

    return (
        <>
            <TurmaTableSelectView turmas={turmas} onTurmaSelected={onTurmaSelected} />
        </>
    );
}