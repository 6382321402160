import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth, 
  fetchRemoveAuth
} from '../../util/FetchUtil';

const controller = 'atividade-aluno/';

const findByAtividade = (codigo) => {
  const url = SERVER_URL + controller + 'atividade/' + codigo;
  return fetchGetAuth(url);
}

const save = (atividadeAluno) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, atividadeAluno);
}

const findByTdpCodigoAndCurrentUser = (codTDP, pageNumber) => {
  const url = SERVER_URL + controller + "aluno/tdp/" + codTDP + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const remove = (codAtividadeAluno) => {
  const url = SERVER_URL + controller + codAtividadeAluno;
  return fetchRemoveAuth(url);
}

export {
  findByAtividade,
  save, 
  findByTdpCodigoAndCurrentUser, 
  remove
}