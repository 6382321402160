import React, { useEffect, useState } from "react";
import Container from "../../UI/Container";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PresencaDisciplinaTable from "./PresencaDisciplinaTable";
import { findByTdpDisciplinaCodDisciplina, remove } from "../api-presenca";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import Loading from "../../UI/Loading";
import { findByAnoLetivo } from "../../Disciplina/api-disciplina";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import DisciplinaProfile from "../../Disciplina/DisciplinaProfile";
import DisciplinaTableSelectView from "../../Disciplina/DisciplinaTableSelectView";
import PresencaDisciplinaForm from "./PresencaDisciplinaForm";

const useStyles = makeStyles({
  paper: {
    padding: "20px",
  },
  title: {
    marginBottom: "20px",
  },
});

const PresencaDisciplinaContainer = () => {
  const classes = useStyles();
  const [disciplina, setDisciplina] = useState(null);
  const [presencas, setPresencas] = useState({
    content: [],
    size: 0,
    totalElements: 0,
    number: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [presenca, setPresenca] = useState(null);

  const [disciplinas, setDisciplinas] = useState([]);
  const [isLoadingDisciplinas, setIsLoadingDisciplinas] = useState(false);

  useEffect(() => {
    setIsLoadingDisciplinas(true);
    findByAnoLetivo().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setDisciplinas(data);
        }
      }
      setIsLoadingDisciplinas(false);
    });
  }, []);

  useEffect(() => {
    if (disciplina && disciplina.codDisciplina) {
      setIsLoading(true);
      findByTdpDisciplinaCodDisciplina(disciplina.codDisciplina, 0).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setPresencas(data);
          }
        }
        setIsLoading(false);
      });
    }
  }, [disciplina]);

  const handleTrocaDisciplina = () => {
    setDisciplina(null);
  };

  const handleChangePage = (event, newPage) => {
    if (disciplina && disciplina.codigo) {
      setIsLoading(true);
      findByTdpDisciplinaCodDisciplina(disciplina.codDisciplina, newPage).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setPresencas(data);
          }
        }
        setIsLoading(false);
      });
    }
  };

  const removePresencas = (codPresenca) => {
    setPresencas((prevState) => ({
      ...prevState,
      content: prevState.content.filter((ps) => ps.codigo !== codPresenca),
    }));
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta presença?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  removePresencas(codigo);
                  setPresenca(null);
                  toast.success("Registro excluído com Sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                }
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Container className={classes.paper} component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography className={classes.title} variant="h6">
            Presença por Disciplina
          </Typography>
        </Grid>
        {disciplina ? (
          <>
            <Grid item>
              <DisciplinaProfile
                disciplina={disciplina}
                handleTrocaDisciplina={handleTrocaDisciplina}
              />
            </Grid>
            <Grid item>
              {isLoading ? (
                <Loading description="Carregando Presenças..." />
              ) : (
                <>
                  {presenca ? (
                    <PresencaDisciplinaForm
                      presenca={presenca}
                      setPresenca={setPresenca}
                      setPresencas={setPresencas}
                      disciplina={disciplina}
                    />
                  ) : (
                    <PresencaDisciplinaTable
                      presencas={presencas}
                      handleChangePage={handleChangePage}
                      isLoadingRemove={isLoadingRemove}
                      handleDelete={handleDelete}
                      setPresenca={setPresenca}
                    />
                  )}
                </>
              )}
            </Grid>
          </>
        ) : (
          <Grid item>
            {isLoadingDisciplinas ? (
              <Loading description="Carregando disciplinas..." />
            ) : (
              <DisciplinaTableSelectView
                disciplinas={disciplinas}
                onDisciplinaSelected={setDisciplina}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PresencaDisciplinaContainer;
