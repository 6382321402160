import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../UI/Button';
import Loading from '../../UI/Loading';
import { ADMIN, COORDENADOR, PROFESSOR } from '../../Usuario/Perfil';

const useStyles = makeStyles({
    container: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    image: {
        height: '200px',
        cursor: 'pointer',
    },
    divImage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonSelect: {
        marginTop: '20px',
    }
})

const MuralFotoList = ({
    setMuralFoto,
    muralFotos
}) => {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const history = useHistory();

    return (
        <Grid
            className={classes.container}
            container
            direction='row'
            alignContent='flex-start'
            spacing={3}
        >
            {muralFotos.content.map((mf) => (
                <Grid
                    key={mf.codigo}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                >
                    {
                        mf.foto ?
                            <div className={classes.divImage}>
                                <img
                                    onClick={() => history.push('image-full-screen', { uri: mf.foto })}
                                    className={classes.image}
                                    src={mf.foto}
                                    alt=''
                                />
                                {usuario.perfil === ADMIN ||
                                    usuario.perfil === COORDENADOR ||
                                    usuario.perfil === PROFESSOR ?
                                    <Button
                                        onClick={() => setMuralFoto(mf)}
                                        className={classes.buttonSelect}
                                    >
                                        Selecionar
                                    </Button> : ''
                                }
                            </div>
                            :
                            <p>Nenhuma Imagem Selecionado</p>
                    }
                </Grid>
            ))}
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                justifyContent='center'
                alignContent='center'
                alignItems='center'
            >
                {muralFotos.number === -1 ||
                    muralFotos.number + 1 < muralFotos.totalPages ?
                    <Loading description="Carregando..." /> :
                    ''}
            </Grid>
        </Grid >
    );
}

export default MuralFotoList;