import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '../../UI/Container';
import IntegralProfessorTable from './IntegralProfessorTable';
import Loading from '../../UI/Loading';
import { findByTurmaAnoLetivo } from './api-integral-professor';
import Button from '../../UI/Button';
import IntegralProfessorForm from './IntegralProfessorForm';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
})

const IntegralProfessorContainer = () => {
    const classes = useStyles();
    const [listaIP, setListaIP] = useState([]);
    const [integralProfessor, setIntegralProfessor] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        findByTurmaAnoLetivo().then((data) => {
            if (!data || data.error) {
                console.log(data);
            } else {
                setListaIP(data);
            }
            setIsLoading(false);
        })
    }, [])

    const handleNovoRegistro = () => {
        setIntegralProfessor({
            eNovo: true
        })
    }

    return (
        <Container>
            <Typography className={classes.title} variant="h6">
                Integral Professor
            </Typography>
            <Button onClick={handleNovoRegistro}>Novo Registro</Button>

            {isLoading ?
                <Loading description='Carregando Integral Professor...' /> :
                <>
                    {integralProfessor ?
                        <IntegralProfessorForm
                            integralProfessor={integralProfessor}
                            setIntegralProfessor={setIntegralProfessor}
                            listaIP={listaIP}
                            setListaIP={setListaIP}
                        /> :
                        <IntegralProfessorTable
                            listaIP={listaIP}
                            setIntegralProfessor={setIntegralProfessor}
                        />
                    }
                </>
            }






        </Container>
    )
}

export default IntegralProfessorContainer;