import { SERVER_URL } from '../../constants';
import session from '../../SessionHandler/session-helper';
import { fetchGetAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'aluno-frequencia/';

const findByTdpCodigoAndNumeroBimestre = (codTdp, numeroBimestre) => {
    const url = SERVER_URL + controller + codTdp + '/' + numeroBimestre;
    return fetchGetAuth(url);
}

const saveAusenciaCompensada = (ausenciaCompensada) => {
    const url = SERVER_URL + controller + 'save-ausencia-compensada';
    return fetchPostAuth(url, ausenciaCompensada);
}

const insertAlunoFrequenciaByAlunoTurma = (codAlunoTurma) => {
    const url = SERVER_URL + controller + 'insert-by-aluno-turma/' + codAlunoTurma;
    return fetchGetAuth(url);
}

export const createUpdate = () => {
    const url = SERVER_URL + controller + 'create-all/' + session.getAnoLetivo();
    return fetchGetAuth(url);
}

export const createUpdatePorBimestre = (numeroBimestre) => {
    const url = SERVER_URL + controller + 'create-por-bimestre/' + session.getAnoLetivo() + '/' + numeroBimestre;
    return fetchGetAuth(url);
}
export const createUpdatePorBimestreTurma = (numeroBimestre, codTurma) => {
    const url = SERVER_URL + controller + 'create-por-bimestre-turma/' + numeroBimestre + '/' + codTurma ;
    return fetchGetAuth(url);
}

export const createUpdateByCodTurma = (codTurma) => {
    const url = SERVER_URL + controller + 'create-by-turma/' + codTurma;
    return fetchGetAuth(url);
}

export const createUpdateByCodTdp = (codTdp) => {
    const url = SERVER_URL + controller + 'create-by-tdp/' + codTdp;
    return fetchGetAuth(url);
}

export const createUpdateByCodAlunoTurma = (codAlunoTurma) => {
    const url = SERVER_URL + controller + 'create-by-aluno-turma/' + codAlunoTurma;
    return fetchGetAuth(url);
}

export {
    findByTdpCodigoAndNumeroBimestre,
    saveAusenciaCompensada,
    insertAlunoFrequenciaByAlunoTurma
}