import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import DiaNaoLetivoTable from './DiaNaoLetivoTable';
import { deleteById, findByAnoLetivo, save } from './api-dia-nao-letivo';
import DiaNaoLetivoForm from './DiaNaoLetivoForm';
import { confirmAlert } from 'react-confirm-alert';



const DiaNaoLetivoContainer = () => {

    const [diaNaoLetivo, setDiaNaoLetivo] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [listDiaNaoLetivo, setListDiaNaoLetivo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findByAnoLetivo().then((data) => {
            console.log(data);
            if (data.error) {
                errorMessage(data.message);
            } else {
                setListDiaNaoLetivo(data);
            }
            setIsLoading(false);
        })
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoadingAction(true);
        save(diaNaoLetivo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                if (diaNaoLetivo.eNovo) {
                    setListDiaNaoLetivo((prevState) => ([
                        ...prevState,
                        data
                    ]))
                } else {
                    setListDiaNaoLetivo((prevState) => prevState.map((ps) => {
                        if (ps.codigo === diaNaoLetivo.codigo) {
                            return data;
                        } else {
                            return ps;
                        }
                    }))
                }
                setDiaNaoLetivo(data);
            }
            setIsLoadingAction(false);
        })
    }

    const onRemove = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este registro?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        deleteById(diaNaoLetivo.codigo).then((data) => {
                            if (data.error) {
                                errorMessage(data);
                            } else {
                                setListDiaNaoLetivo((prevState) => prevState.filter((ps) => ps.codigo !== diaNaoLetivo.codigo));
                                setDiaNaoLetivo(null);
                            }
                        })
                        setIsLoadingAction(false);
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    return (
        <Grid
            container
            justifyContent='center'
        >
            {diaNaoLetivo ?
                <Grid item
                    xs={12} sm={12}
                    md={6} lg={5} xl={4}
                >
                    <DiaNaoLetivoForm
                        diaNaoLetivo={diaNaoLetivo}
                        setDiaNaoLetivo={setDiaNaoLetivo}
                        onSubmit={onSubmit}
                        onRemove={onRemove}
                        isLoadingAction={isLoadingAction}
                    />
                </Grid> :
                <Grid
                    item
                    xs={12}
                >
                    <DiaNaoLetivoTable
                        listDiaNaoLetivo={listDiaNaoLetivo}
                        setDiaNaoLetivo={setDiaNaoLetivo}
                        isLoading={isLoading}
                    />
                </Grid>
            }

        </Grid>
    );
}

export default DiaNaoLetivoContainer;