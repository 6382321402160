import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { dateTimeFormat } from "../../../util/DateUtil";
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function ComunicadoTable({ comunicados, handleChangePage, setComunicado }) {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);

    return (
        <div>
            <Button
                onClick={() => setComunicado({ eNovo: true })}
                variant="contained"
                className={classes.novoRegistro}
                color="primary">
                Novo Comunicado
            </Button>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Enviar Por</TableCell>
                        <TableCell>Enviar Para</TableCell>
                        <TableCell>Categoria</TableCell>
                        <TableCell>Título</TableCell>
                        <TableCell>Criado Por</TableCell>
                        {usuario.instituicao.comunicadoAprovacao ?
                            <TableCell>Aprovado</TableCell> : null}
                        <TableCell>Enviado</TableCell>
                        <TableCell>Data Hora Para</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {comunicados.content.map((comunicado) => (
                        <TableRow key={comunicado.codigo}>
                            <TableCell>{dateTimeFormat(comunicado.createdAt)}</TableCell>
                            <TableCell>
                                {comunicado.enviarPor}
                            </TableCell>
                            <TableCell>{comunicado.enviarPara}</TableCell>
                            <TableCell>{comunicado.categoria ? comunicado.categoria.descricao : ''}</TableCell>
                            <TableCell>{comunicado.titulo}</TableCell>
                            <TableCell>{comunicado.usuario && comunicado.usuario.nome ? comunicado.usuario.nome : ''}</TableCell>
                            {usuario.instituicao.comunicadoAprovacao ?
                                <TableCell>
                                    {comunicado.aprovado ?
                                        <CheckCircle style={{ color: green[500] }} /> :
                                        <Cancel style={{ color: red[500] }} />}
                                </TableCell> : null}
                            <TableCell>
                                {comunicado.enviado ?
                                    <CheckCircle style={{ color: green[500] }} /> :
                                    <Cancel style={{ color: red[500] }} />}
                            </TableCell>
                            <TableCell>
                                {comunicado.dataHoraPara ? dateTimeFormat(comunicado.dataHoraPara) : ''}
                            </TableCell>
                            <TableCell>
                                <Button
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { setComunicado(comunicado) }}
                                    style={{ marginRight: '10px' }}
                                    variant='outlined'
                                >
                                    Selecionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={comunicados.size ? comunicados.size : 0}
                component="div"
                count={comunicados.totalElements}
                page={comunicados.number}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
            />

        </div>
    );
}