import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const IntegralRotinaTable = ({ listaIR, setIntegralRotina }) => {
    const classes = useStyles();

    const handleSelecionarIR = (ir) => {
        setIntegralRotina(ir);
    }

    return (
        <>
            {listaIR.length > 0 ?
                < Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.rowHeader}>Aluno</TableCell>
                            <TableCell className={classes.rowHeader}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listaIR.map((ir) => (
                            <TableRow key={ir.codigo}>
                                <TableCell>{ir.alunoTurma.aluno.nome}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleSelecionarIR(ir)}>Selecionar</Button>
                                </TableCell>

                            </TableRow>

                        ))}
                    </TableBody>
                </Table> :
                <Grid>
                    <Typography className={classes.title} variant="h6">
                        Nenhum Registro Cadastrado
                    </Typography>
                </Grid>
            }
        </>
    );
}

export default IntegralRotinaTable;