import React, { useEffect, useState } from 'react';
import Container from '../../UI/Container';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import {
    setHistoricoLancamento as setHistoricoLancamentoRedux,
    addHistoricosLancamentos,
    updateHistoricosLancamentos
} from './actions/actions'
import TextField from '../../UI/TextField';
import Button from '../../UI/Button';
import { toast } from 'react-toastify';
import { save } from './api-historico-lancamento';
import { confirmAlert } from 'react-confirm-alert';


const useStyles = makeStyles(theme => ({
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
}));

const HistoricoLancamentoForm = () => {
    const dispatch = useDispatch();
    const historicoLancamentoRedux = useSelector(state => state.historicoLancamento);
    const [historicoLancamento, setHistoricoLancamento] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        setHistoricoLancamento(historicoLancamentoRedux);
    }, [historicoLancamentoRedux])

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = historicoLancamento.eNovo;
        save(historicoLancamento).then((data) => {
            if (data.error) {
                if (data.message === 'Codigo Historico already exists') {
                    confirmAlert({
                        title: 'Erro',
                        message: 'Código já cadastrado.',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => { }
                            }
                        ]
                    });
                } else {
                    console.log(data);
                }

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    dispatch(addHistoricosLancamentos(data));
                } else {
                    dispatch(updateHistoricosLancamentos(data));
                }
                dispatch(setHistoricoLancamentoRedux(data));
                setHistoricoLancamento(data);
            }
        })
    }

    const handleChange = (event) => {
        setHistoricoLancamento({
            ...historicoLancamento,
            [event.target.name]: event.target.value
        })
    }

    return (
        <Container>
            <CloseIcon
                onClick={() => dispatch(setHistoricoLancamentoRedux(null))}
                className={classes.closeIcon}
                fontSize='small'
            />
            <Typography variant="h6">
                Cadastro Histórico Lançamento
            </Typography>
            <form onSubmit={clickSubmit}>
                <TextField
                    label="Código"
                    name="codHistoricoEscola"
                    required
                    autoFocus
                    onChange={handleChange}
                    value={historicoLancamento && historicoLancamento.codHistoricoEscola ? historicoLancamento.codHistoricoEscola : ''}
                />
                <TextField
                    label="Descrição"
                    fullWidth
                    required
                    name="descricao"
                    onChange={handleChange}
                    value={historicoLancamento && historicoLancamento.descricao ? historicoLancamento.descricao : ''}
                />
                <Button type="submit">
                    Salvar
                </Button>
            </form>
        </Container>
    );
}

export default HistoricoLancamentoForm;