import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth,
  fetchPostAuth, 
  fetchPostAuthFile
} from '../../util/FetchUtil';

const controller = 'aluno/';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

const findByCodigo = (codAlunoEscola) => {
  const url = SERVER_URL + controller + 'codigo/' + codAlunoEscola;
  return fetchGetAuth(url);
}

const findByNome = (nome) => {
  const url = SERVER_URL + controller + 'nome/' + nome;
  return fetchGetAuth(url);
}

const remove = (codAluno) => {
  const url = SERVER_URL + controller + 'delete/' + codAluno;
  return fetchRemoveAuth(url);
}

const save = (aluno) => {
  const url = SERVER_URL + controller + 'save';
  return fetchPostAuth(url, aluno);
}

const uploadPhoto = (codAluno, fileForm) => {
  const url = SERVER_URL + controller + "upload-photo/" + codAluno;
  return fetchPostAuthFile(url, fileForm);
}

const deleteFile = (codAluno) => {
  const url = SERVER_URL + controller + "delete-photo/" + codAluno;
  return fetchRemoveAuth(url);
}

export {
  page,
  save,
  remove,
  findByCodigo,
  findByNome, 
  uploadPhoto, 
  deleteFile
}