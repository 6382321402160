import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../../UI/Button';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        whiteSpace: 'nowrap',
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
    title: {
        marginBottom: '20px',
        marginTop: '20px',
        backgroundColor: '#90EE90',
        borderRadius: '10px',
        padding: '20px'
    },
})

const IntegralProfessorTable = ({ listaIP, setIntegralProfessor }) => {
    const classes = useStyles();


    const handleSelecionarIP = (ip) => {
        setIntegralProfessor(ip);
    }

    return (
        <>
            {listaIP.length > 0 ?
                < Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.rowHeader}>Turma</TableCell>
                            <TableCell className={classes.rowHeader}>Professor</TableCell>
                            <TableCell className={classes.rowHeader}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listaIP.map((integralProfessor) => (
                            <TableRow key={integralProfessor.codigo}>
                                <TableCell>{integralProfessor.turma.descricao}</TableCell>
                                <TableCell>{integralProfessor.usuario.nome}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleSelecionarIP(integralProfessor)}>Selecionar</Button>
                                </TableCell>

                            </TableRow>

                        ))}
                    </TableBody>
                </Table> :
                <Grid>
                    <Typography className={classes.title} variant="h6">
                        Nenhum Registro Cadastrado
                    </Typography>
                </Grid>
            }
        </>
    );
}

export default IntegralProfessorTable;