import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-cronograma';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addCronogramas, updateCronogramas, setCronograma as setCronogramaRedux } from './actions/actions';
import { openCronogramaAula } from './Aula/actions/actions';
import { timeFormat } from '../../util/DateUtil';
import CronogramaAulaFindDialogRedux from './Aula/CronogramaAulaFindDialogRedux';
import { findByTurma } from '../TDP/api-tdp';
import { confirmAlert } from 'react-confirm-alert';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { findAll } from '../Local/api-local';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    },
    fieldGrid: {
        margin: '10px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function CronogramaForm({ cronogramaData }) {
    const classes = useStyles();
    const cronogramaAula = useSelector(state => state.cronogramaAula);
    const turma = useSelector(state => state.turma);
    const [cronograma, setCronograma] = useState(useSelector(state => state.cronograma));
    const dispatch = useDispatch();
    const [loadingSave, setLoadingSave] = useState(false);
    const [tdps, setTdps] = useState([]);
    const [locais, setLocais] = useState([]);

    useEffect(() => {
        if (cronogramaAula) {
            setCronograma((prevState) => ({
                ...prevState,
                cronogramaAula: cronogramaAula
            }))
        }

    }, [cronogramaAula])

    useEffect(() => {
        findAll().then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setLocais(data);
                }
            }
        })
    }, []);

    useEffect(() => {
        findByTurma(turma.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setTdps(data);
                }
            }
        })
    }, [turma.codigo]);

    const handleError = (message) => {
        confirmAlert({
            title: 'Erro',
            message: message,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const clickSubmit = (event) => {
        event.preventDefault();

        const eNovo = cronograma.eNovo;
        if (cronograma.diaSemana === null) {
            handleError('Selecione o Dia da Semana.');
            return;
        } else if (cronograma.cronogramaAula === undefined || cronograma.cronogramaAula === null) {
            handleError("Busque a Aula para este Cronograma.");
            return;
        } else if (cronograma.tdp === null) {
            handleError("Selecione a Turma/Disciplina para este Cronograma.");
            return;
        }

        setLoadingSave(true);
        const persist = {
            ...cronograma,
            cronogramaData: cronogramaData
        }
        save(persist).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        dispatch(addCronogramas(data));
                    } else {
                        dispatch(updateCronogramas(data));
                    }
                    dispatch(setCronogramaRedux(data));
                    setCronograma(data);
                }
                setLoadingSave(false);
            }


        })

    }

    const handleChangeTdp = (event) => {
        const tdpSelected = tdps.find((tdp) => tdp.codigo === event.target.value);
        setCronograma((prevState) => ({
            ...prevState,
            tdp: tdpSelected
        }));

    }

    const handleChangeLocal = (event) => {
        const localSelected = locais.find((local) => local.codigo === event.target.value);
        setCronograma((prevState) => ({
            ...prevState,
            local: localSelected
        }));

    }

    const handleAdicionarCronogramaAula = () => {
        dispatch(openCronogramaAula());
    }

    const handleChangeDayWeek = (event) => {
        setCronograma({
            ...cronograma,
            diaSemana: event.target.value
        });
    };


    return (
        <div>
            <CronogramaAulaFindDialogRedux />
            <Container className={classes.paper} component={Paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CloseIcon
                        fontSize='small'
                        onClick={() => dispatch(setCronogramaRedux(null))} className={classes.closeIcon} />
                    <form onSubmit={clickSubmit}>
                        <Grid className={classes.fieldGrid}>
                            <FormControl required className={classes.formControl}>
                                <InputLabel id="demo-simple-select-required-label">Dia da Semana</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={cronograma && cronograma.diaSemana ? cronograma.diaSemana : ''}
                                    onChange={handleChangeDayWeek}
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value={1}>Segunda</MenuItem>
                                    <MenuItem value={2}>Terça</MenuItem>
                                    <MenuItem value={3}>Quarta</MenuItem>
                                    <MenuItem value={4}>Quinta</MenuItem>
                                    <MenuItem value={5}>Sexta</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className={classes.fieldGrid}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="Cronograma Aula"
                                name="cronogramaAula"
                                InputProps={{
                                    readOnly: true,
                                }}
                                autoFocus
                                value={cronograma && cronograma.cronogramaAula ?
                                    '(' +
                                    cronograma.cronogramaAula.ordemAula +
                                    ') ' +
                                    timeFormat(cronograma.cronogramaAula.horarioInicio) +
                                    ' - ' +
                                    timeFormat(cronograma.cronogramaAula.horarioFim)
                                    : ''}
                            />
                            <Button
                                style={{ marginLeft: '10px' }}
                                variant="contained"
                                onClick={handleAdicionarCronogramaAula}
                                color="primary">
                                Buscar Cronograma Aula
                            </Button>

                        </Grid>
                        {tdps.length > 0 ?
                            <Grid className={classes.fieldGrid}>
                                <FormControl required className={classes.formControl}>
                                    <InputLabel id="label-tdp">Disciplina/Professor</InputLabel>
                                    <Select
                                        labelId="label-tdp"
                                        id="select-tdp"
                                        value={cronograma && cronograma.tdp.codigo ? cronograma.tdp.codigo : ''}
                                        onChange={handleChangeTdp}
                                        className={classes.selectEmpty}
                                    >
                                        {tdps.map((tdp) => (
                                            <MenuItem key={tdp.codigo} value={tdp.codigo}>
                                                {tdp.disciplina.descricao + (tdp.usuario ? ' - ' + tdp.usuario.nome : '')}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            : ''}
                        {locais.length > 0 ?
                            <Grid className={classes.fieldGrid}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="label-tdp">Local</InputLabel>
                                    <Select
                                        labelId="label-tdp"
                                        id="select-tdp"
                                        value={cronograma && cronograma.local ? cronograma.local.codigo : ''}
                                        onChange={handleChangeLocal}
                                        className={classes.selectEmpty}
                                    >
                                        {locais.map((local) => (
                                            <MenuItem key={local.codigo} value={local.codigo}>
                                                {local.descricao}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            : ''}




                        <Button

                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={loadingSave}
                        >
                            {loadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                            Salvar
                        </Button>
                    </form>
                </MuiPickersUtilsProvider>
            </Container>

        </div>
    );
}