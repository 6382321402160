import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { findByAtividade, remove } from './api-atividade-aluno';
import { useSelector, useDispatch } from 'react-redux';
import { setAtividadeAlunos, setAtividadeAluno, removeAtividadeAlunos } from './actions/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { errorMessage } from "../../util/ConfirmAlertUtil";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Loading from '../UI/Loading';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px',
        padding: '10px'
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    rowNotSelected: {
        cursor: 'pointer'
    },
    rowSelected: {
        cursor: 'pointer',
        backgroundColor: '#b5e7a4'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function PresencaTable() {
    const classes = useStyles();
    const atividade = useSelector(state => state.atividade);
    const atividadeAluno = useSelector(state => state.atividadeAluno);
    const atividadeAlunos = useSelector(state => state.atividadeAlunos);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (atividade) {
            setIsLoading(true);
            findByAtividade(atividade.codigo).then((data) => {
                console.log(data);
                if (data.length === 0) {
                    errorMessage("Nenhum Aluno Cadastrado para esta Atividade.");
                } else {
                    dispatch(setAtividadeAlunos(data));
                    dispatch(setAtividadeAluno(data[0]));
                }
                setIsLoading(false);
            })
        }

    }, [atividade, dispatch]);


    const handleDelete = (aa) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja remover a atividade do aluno?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(aa.codigo).then((data) => {
                            if (data) {
                                toast.success("O aluno foi removido desta atividade.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                                dispatch(removeAtividadeAlunos(aa.codigo));
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });

    }

    return (
        <Container className={classes.paper} component={Paper}>

            {isLoading ? <Loading description="Carregando Atividades Aluno..." /> :
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Número Chamada</TableCell>
                            <TableCell>Nome</TableCell>
                            {atividade && atividade.semNota ? '' : <TableCell>Nota</TableCell>}
                            {atividade && atividade.semNota ? <TableCell>Visto</TableCell> : ''}

                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {atividadeAlunos.map((aa, index) => (
                            <TableRow
                                className={atividadeAluno && atividadeAluno.codigo === aa.codigo ? classes.rowSelected : classes.rowNotSelected}
                                onClick={() => dispatch(setAtividadeAluno(aa))}
                                key={aa.codigo}
                            >
                                <TableCell>{aa.alunoTurma.numeroChamada}</TableCell>
                                <TableCell>{aa.aluno.nome}</TableCell>
                                {atividade.semNota ? '' : <TableCell>{aa.nota}</TableCell>}
                                {atividade.semNota ? <TableCell>{aa.vistoProfessor ? <DoneOutlineIcon /> : ''}</TableCell> : ''}
                                <TableCell>
                                    <DeleteIcon
                                        fontSize='small'
                                        className={classes.cursorLink} onClick={() => { handleDelete(aa) }} color="secondary" />
                                    {aa.atividade.entrega ? <Button
                                        component={Link}
                                        to={{
                                            pathname: '/atividade-aluno-anexo',
                                            state: {
                                                atividadeAluno: aa
                                            }
                                        }}
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        className={classes.submit}>
                                        Anexos
                                    </Button> : ''}

                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }


        </Container>

    );
}