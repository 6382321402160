import { SERVER_URL } from '../../constants';

import {
  fetchGetAuth,
  fetchPostAuth,
} from '../../util/FetchUtil';

const controller = 'instituicao/';

const findByHostname = (hostname) => {
  return fetch(SERVER_URL + controller + "hostname", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'no-cors'
    },
    body: hostname
  })
    .then((response) => {
      return response.json();
    });
}

const save = (evento) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, evento);
}

const findByDescricao = (descricao) => {
  const url = SERVER_URL + controller + 'query?descricao=' + descricao
  return fetchGetAuth(url);
}

export {
  findByHostname,
  save,
  findByDescricao
}