import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function PresencaAlunoForm({
    presencaAluno,
    handleFaltou,
    handlePresente
}) {
    const classes = useStyles();

    return (
        <Container className={classes.paper} component={Paper} >

            <Typography className={classes.title} variant="h5">
                Nome: {presencaAluno ? presencaAluno.aluno.nome : ''}
            </Typography>
            <Typography className={classes.title} variant="h6">
                Número Chamada: {presencaAluno ? presencaAluno.alunoTurma.numeroChamada : ''}
            </Typography>



            <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handlePresente}
            >
                Presente
            </Button>
            <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleFaltou}
            >
                Falta
            </Button>
        </Container>
    );
}