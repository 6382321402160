import {
  SET_PROGRESS_LOG,
} from './actionTypes';

export function progressLog(state = null, action) {
  switch (action.type) {
    case SET_PROGRESS_LOG:
      return action.progressLog

    default:
      return state
  }
}