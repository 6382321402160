import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core';
import MensagemFind from './MensagemFind';
import MensagemFindGroup from './MensagemFindGroup';
import Button from '../UI/Button';
import MensagemFindTurma from './MensagemFindTurma';
import { useSelector } from 'react-redux';
import { ADMIN, COORDENADOR, PROFESSOR, SECRETARIA } from '../Usuario/Perfil';

const useStyles = makeStyles({
    container: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    title: {
        marginTop: '20px'
    },
    divSelect: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '20px',
        marginTop: '20px',
    },
});

const MensagemFindContainer = () => {
    const turmaKey = 'turma';
    const usuarioKey = 'usuario';
    const grupoKey = 'grupo';

    const classes = useStyles();
    const [typeSelect, setTypeSelect] = useState(usuarioKey);
    const usuario = useSelector(state => state.usuario);



    return (
        <Container className={classes.container} component={Paper}>
            <Button
                component={Link}
                to={{
                    pathname: '/mensagem'
                }}
                variant="contained"
                color="secondary"
                className={classes.voltarButton}
            >
                <ArrowBackIcon style={{ marginRight: '10px' }} />
                Voltar
            </Button>
            <Typography className={classes.title} variant="h5">
                Buscar Usuário/Sala
            </Typography>
            {usuario && usuario.perfil && (usuario.perfil === ADMIN ||
                usuario.perfil === COORDENADOR ||
                usuario.perfil === SECRETARIA ||
                usuario.perfil === PROFESSOR) ?
                <div className={classes.divSelect}>
                    <Button
                        onClick={() => setTypeSelect(turmaKey)}
                    >
                        Turma
                    </Button>
                    <Button
                        onClick={() => setTypeSelect(usuarioKey)}
                    >
                        Usuário
                    </Button>
                    <Button
                        onClick={() => setTypeSelect(grupoKey)}
                    >
                        Grupo
                    </Button>

                </div> : ''
            }
            {console.log(typeSelect)}
            {typeSelect && typeSelect === turmaKey ?
                <MensagemFindTurma /> : ''}
            {typeSelect && typeSelect === usuarioKey ?
                <MensagemFind /> : ''}
            {typeSelect && typeSelect === grupoKey ?
                <MensagemFindGroup /> : ''}
        </Container>
    );
}

export default MensagemFindContainer;