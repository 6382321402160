import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { findAvaliacaoByUser } from './api-avaliacao-aluno'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setUsuario } from '../../Usuario/actions/actions';
import { dateTimeFormat } from '../../../util/DateUtil';
import { setAvaliacaoAluno } from './actions/actions';
import { setAvaliacao } from '../actions/actions';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        marginTop: '20px'
    },
    table: {
        minWidth: 650,
    },
    title: {
        paddingTop: '20px;',
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    cursorLink: {
        cursor: 'pointer',
    },
    loading: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
});


export default function AvaliacaoAlunoTable() {
    const classes = useStyles();
    const [avaliacoesAluno, setAvaliacoesAluno] = useState([]);
    const history = useHistory();
    const usuario = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        findAvaliacaoByUser().then((data) => {
            if (data.error) {
                console.log(data);
            } else {
                setAvaliacoesAluno(data);
                setLoading(false);
            }
        })
    }, []);

    const handleSelecionarAvaliacao = (aa) => {
        dispatch(setAvaliacaoAluno(aa));
        dispatch(setUsuario(usuario));
        dispatch(setAvaliacao(aa.avaliacao));
        history.push({
            pathname: '/avaliacao-secao',
            state: {
                usuario: usuario,
                avaliacaoAlunoSelected: aa
            }
        })
    }

    return (
        <div>
            {loading ?
                <div className={classes.loading}>
                    <CircularProgress color="primary" />
                    Carregando...
                </div>
                :
                <>
                    {
                        avaliacoesAluno.length > 0 ?
                            <>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Título</TableCell>
                                            <TableCell>Conteúdo</TableCell>
                                            <TableCell>Data Hora Início</TableCell>
                                            <TableCell>Data Hora Fim</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {avaliacoesAluno.map((aa) => (
                                            <TableRow key={aa.codigo}>
                                                <TableCell>{aa.avaliacao.titulo}</TableCell>
                                                <TableCell>{aa.avaliacao.conteudo}</TableCell>
                                                <TableCell>{aa.avaliacao.dataHoraInicio ? dateTimeFormat(aa.avaliacao.dataHoraInicio) : '--'}</TableCell>
                                                <TableCell>{aa.avaliacao.dataHoraFim ? dateTimeFormat(aa.avaliacao.dataHoraFim) : '--'}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size='small'
                                                        onClick={() => handleSelecionarAvaliacao(aa)}
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        className={classes.submit}>
                                                        Selecionar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </> :
                            <Typography variant='body1'>
                                Nenhuma prova liberada no momento.
                            </Typography>
                    }
                </>
            }


        </div>
    );
}