import {
  ADD_SECOES,
  UPDATE_SECOES,
  SET_SECOES,
  REMOVE_SECOES,
  SET_SECAO
} from './actionTypes';
import { PURGE } from "redux-persist";

export function secao(state = null, action) {
  switch (action.type) {
    case SET_SECAO:
      return action.secao

    case PURGE:
      return null;

    default:
      return state
  }
}

export function secoes(state = [], action) {
  let persist = Object.assign({}, state);
  switch (action.type) {

    case ADD_SECOES:
      return [
        ...state,
        action.secao,
      ]

    case UPDATE_SECOES:
      let content = state.map((secao) => {
        if (secao.codigo === action.secao.codigo) {
          return action.secao;
        } else {
          return secao;
        }
      });
      persist = content;
      return persist;

    case SET_SECOES:
      return action.secoes

    case REMOVE_SECOES:
      const index = state.findIndex((s) => s.codigo === action.codSecao)
      state.splice(index, 1);
      return state;

    case PURGE:
      return [];

    default:
      return state
  }
}
