import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchRemoveAuth, 
  fetchPostAuth
} from '../../../util/FetchUtil';

const controller = 'comunicado-categoria/';

const findAll = () => {
  const url = SERVER_URL + controller;
  return fetchGetAuth(url);
}

const remove = (codCategoria) => {
  const url = SERVER_URL + controller + codCategoria;
  return fetchRemoveAuth(url);
}

const save = (categoria) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, categoria);
}

export {
  findAll,
  remove, 
  save
}