import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '../../UI/Button';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import UsuarioFindDialog from '../../Usuario/UsuarioFindDialog';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import AbaMenu from './AbaMenu';
import TextFieldUsuario from '../../Usuario/UI/TextFieldUsuario';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 3),
        textTransform: 'none',
    },
    buscarBtn: {
        margin: theme.spacing(3, 3, 3),
        textTransform: 'none',
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    menuIcon: {
        marginTop: '15px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    divAlunoFoto: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    gridAbaMenu: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));

const AlunoForm = ({
    aluno,
    setAluno,
    clickSubmit,
    handleDelete,
    handleDeleteUsuario,
}) => {
    const classes = useStyles();
    const [openSearchUsuario, setOpenSearchUsuario] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSelectedUsuario = (userAdding) => {
        setAluno({
            ...aluno,
            usuarioAluno: userAdding
        });
        setOpenSearchUsuario(false);
    }

    const closeUsuarioSearch = () => {
        setOpenSearchUsuario(false);
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAlunoLivro = () => {
        handleMenuClose();
    }

    const handleChange = (event) => {
        setAluno({
            ...aluno,
            [event.target.name]: event.target.value
        })
    }

    const handleBuscarUsuario = () => {
        setOpenSearchUsuario(true);
    }





    return (
        <>
            <UsuarioFindDialog
                openSearchUsuario={openSearchUsuario}
                handleSelectedUsuario={handleSelectedUsuario}
                closeUsuarioSearch={closeUsuarioSearch}
            />
            <Container className={classes.paper} component={Paper} >
                <Grid container justifyContent="space-between">
                    <Grid item xs={3}>
                        <CloseIcon
                            onClick={() => setAluno(null)}
                            className={classes.closeIcon}
                            fontSize='small'
                        />
                    </Grid>
                    <Grid style={{ flexBasis: '5%' }} item xs={3}>
                        <MenuIcon
                            className={classes.menuIcon}
                            onClick={(event) => handleMenuClick(event)}
                            color="secondary"
                            fontSize='small'
                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                component={Link}
                                to={{
                                    pathname: '/aluno-livro',
                                    state: {
                                        aluno: aluno
                                    }
                                }}
                                onClick={() => handleAlunoLivro()}
                            >
                                Aluno Livro
                            </MenuItem>
                        </Menu>
                    </Grid>

                </Grid>

                <Typography variant="h6">
                    Cadastro Aluno
                </Typography>
                <Grid item >
                    <form onSubmit={clickSubmit}>
                        <div style={{
                            display: 'flex',

                        }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="normal"
                                label="Código"
                                name="codAlunoEscola"
                                autoFocus
                                onChange={handleChange}
                                value={aluno && aluno.codAlunoEscola ? aluno.codAlunoEscola : ''}
                            />
                            <TextField
                                style={{
                                    marginLeft: '8px'
                                }}
                                size="small"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                required
                                inputProps={{ maxLength: 60 }}
                                label="Nome"
                                name="nome"
                                onChange={handleChange}
                                value={aluno && aluno.nome ? aluno.nome : ''}
                            />
                        </div>
                        <TextFieldUsuario
                            label='Usuário Aluno'
                            value={aluno && aluno.usuarioAluno ? aluno.usuarioAluno.email : ''}
                            handleBuscarUsuario={handleBuscarUsuario}
                            handleDeleteUsuario={handleDeleteUsuario}
                        />

                        <Grid container direction='row' spacing={3}>
                            <Grid item>
                                <Button
                                    type="submit"
                                >
                                    Salvar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleDelete}
                                >
                                    Remover
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <AbaMenu
                        aluno={aluno}
                    />
                </Grid>
            </Container>
        </ >
    );
}

export default AlunoForm;