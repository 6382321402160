import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Button from "../UI/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    marginBottom: "10px",
  },
});

const DisciplinaTableSelectView = ({ disciplinas, onDisciplinaSelected }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title} variant="body1">
        Selecione uma disciplina
      </Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {disciplinas.map((disciplina) => (
            <TableRow key={disciplina.codigo}>
              <TableCell>{disciplina.descricao}</TableCell>
              <TableCell>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => onDisciplinaSelected(disciplina)}
                  className={classes.submit}
                >
                  Selecionar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DisciplinaTableSelectView;
